import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "dashboard",
  actions: {
    get: {
      url: `${API_URL}/admin/dashboard`,
      method: "GET",
      transformResponse: ({ body }) => {
        return { body };
      },
    },
  },
});

setAuthHeader(getAuthData().accessToken);
