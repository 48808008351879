import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, DatePicker, Input, Row } from "antd";
import moment from "moment";
import "./styles.less";
import { IModalInputs } from "../../../../DesktopView/components/JobsList/components/ConfirmModal/ConfirmModal.types";
import { MobileModalWrapper } from "src/components/AdminPanel/Mobile/MobileModalWrapper/index";
import type { RangePickerProps } from "antd/es/date-picker";
import { CheckCircleTwoTone } from "@ant-design/icons";

export const ConfirmActionModal = ({ openModal, handleModalSubmit, setOpenModal }: IModalInputs) => {
  const [postponeDate, setPostponeDate] = useState(moment());
  const [orderIdConfirm, setorderIdConfirm] = useState<string>();

  const intl = useIntl();
  const isCancelGranted = () => {
    return orderIdConfirm && Number(orderIdConfirm) === openModal?.id;
  };
  const postponeDisabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };
  const onClose = () => {
    setOpenModal((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const isConfirmDisabled = () => {
    if (openModal?.type === "postpone" && !postponeDate) return true;
    if (openModal?.type === "cancelCompleted" && !isCancelGranted()) return true;
    return false;
  };

  const renderModalContent = (type: string) => {
    switch (type) {
      case "postpone":
        return (
          <Row className="options__row">
            <label className="options__row--label"> Postponed delivery date </label>
            <DatePicker
              value={postponeDate}
              disabledDate={postponeDisabledDate}
              allowClear={false}
              onChange={(date, dateString) => {
                setPostponeDate(date!);
              }}
              format={"DD.MM.YYYY"}
            />
          </Row>
        );
      case "cancelCompleted":
        return (
          <Row className="options__row">
            <label className="options__row--label"> Please confirm the order ID </label>
            <Input
              style={{ width: "50%", borderColor: isCancelGranted() ? "#27ae60" : "" }}
              value={orderIdConfirm}
              status={!isCancelGranted() ? "warning" : ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setorderIdConfirm(e.target.value)}
              suffix={isCancelGranted() ? <CheckCircleTwoTone twoToneColor="#27ae60" /> : null}
            />
          </Row>
        );
    }
  };

  return (
    <MobileModalWrapper
      visible={openModal?.open}
      title={
        <>
          {"Postpone"} {intl.formatMessage({ id: "order", defaultMessage: "order" })}
        </>
      }
      onClose={onClose}
      afterClose={() => {
        setOpenModal({
          open: false,
          id: undefined,
          job: undefined,
        });
      }}
      footer={null}
    >
      <div className="mobile-confirm-modal-body">
        <div className="mobile-confirm-modal-body__title">
          {intl.formatMessage(
            {
              id: "modal.areYouSureWantTo",
              defaultMessage: "Are you sure you want to",
            },
            {
              operation: `${intl.formatMessage({
                id: `tabPane.actions.${openModal.type ?? "postpone"}`,
                defaultMessage: "Operate changes",
              })}`,
              concern: `${intl.formatMessage({ id: "order", defaultMessage: "order" })}`,
            },
          )}
        </div>
        {renderModalContent(openModal.type!)}
        <Button
          onClick={() => handleModalSubmit(openModal?.job!, openModal?.id!, postponeDate.format("DD.MM.YYYY"))}
          className="mobile-btn mobile-btn--yellow"
          disabled={isConfirmDisabled()}
        >
          <FormattedMessage id="tabPane.actions.confirm" defaultMessage="Confirm" />
        </Button>
        <Button onClick={onClose} className="mobile-btn mobile-btn--white">
          <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
        </Button>
      </div>
    </MobileModalWrapper>
  );
};
