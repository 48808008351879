import React, { useEffect, useCallback } from "react";
import { notification, Popover, Table } from "antd";
import moment from "moment";

import { StatusPopover } from "./components/StatusPopover";

export const DeliveryTable = ({
  location,
  data,
  pagination,
  selection,
  fetchDriversPoints,
  approveDriversPoints,
  rejectDriversPoints,
  getQueryParams,
  onChangePagination,
  handleToggle,
  waitDriversPoints,
}) => {
  const fetchData = useCallback(() => {
    const queryParams = getQueryParams();
    const queryWithPage = queryParams?.page ? queryParams : { page: 1, limit: 10 };

    fetchDriversPoints(
      {},
      {
        query: queryWithPage,
      },
    );
  }, [fetchDriversPoints, getQueryParams]);

  useEffect(() => {
    fetchData();
  }, [fetchData, location.search]);

  const onApproveStatus = (requestId) => async () => {
    try {
      await approveDriversPoints({ requestId });
      await fetchData();
    } catch (e) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Something went wrong.",
      });
    }
  };

  const onRejectStatus = (requestId) => async () => {
    try {
      await rejectDriversPoints({ requestId });
      await fetchData();
    } catch (e) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Something went wrong.",
      });
    }
  };

  const onWaitStatus = (requestId) => async () => {
    try {
      await waitDriversPoints({ requestId });
      await fetchData();
    } catch (e) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Something went wrong.",
      });
    }
  };

  const columns = [
    {
      title: "Driver name",
      dataIndex: "name",
      key: "driverName",
    },
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Job ID",
      dataIndex: "jobId",
      key: "jobId",
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "Customer Phone",
      dataIndex: "customer_phone",
      key: "customer_phone",
    },
    {
      title: "Reason",
      dataIndex: "reasonObject",
      key: "reason",
      render: ({ reason, description }) => {
        return (
          <>
            {description ? (
              <Popover content={<div>{description}</div>} title={null}>
                <span>{reason}</span>
              </Popover>
            ) : (
              <div>{reason}</div>
            )}
          </>
        );
      },
    },
    {
      title: "Driver notes",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "85px",
      render: (status, record) => {
        return (
          <>
            {status === "pending" ? (
              <StatusPopover
                record={record}
                onApproveStatus={onApproveStatus}
                onRejectStatus={onRejectStatus}
                onWaitStatus={onWaitStatus}
              />
            ) : status !== "waiting" ? (
              <span>{status}</span>
            ) : (
              <StatusPopover
                record={record}
                isWaiting
                onApproveStatus={onApproveStatus}
                onRejectStatus={onRejectStatus}
                onWaitStatus={onWaitStatus}
              />
            )}
          </>
        );
      },
    },
  ];

  const dataSource = data?.map(
    ({
      requests_id,
      customer_phone,
      customer_name,
      description,
      driver_name,
      job_id,
      order_id,
      reason,
      status,
      time,
    }) => {
      return {
        id: requests_id,
        name: driver_name,
        orderId: order_id,
        jobId: job_id,
        status,
        description,
        time: moment(time).format("DD.MM.YYYY HH:mm"),
        reasonObject: {
          reason,
          description,
        },
        customer_phone,
        customer_name,
      };
    },
  );

  const currentQueryParams = getQueryParams();

  const paginationConfig = {
    pageSize: Number(currentQueryParams?.limit) || 10,
    total: pagination?.totalCount || 50,
    current: Number(currentQueryParams?.page) || 1,
    pageSizeOptions: [10, 20, 30, 50],
    showSizeChanger: true,
    position: ["bottomRight"],
  };

  // const rowSelection = {
  //   onChange: handleToggle,
  // };

  return (
    <Table
      columns={columns}
      rowKey={(record) => record.id}
      // rowSelection={{
      //   selectedRowKeys: selection,
      //   type: "checkbox",
      //   ...rowSelection,
      // }}
      dataSource={dataSource}
      pagination={paginationConfig}
      onChange={onChangePagination}
    />
  );
};
