import React from "react";
import { useHistory } from "react-router-dom";
import FacebookLogin from "react-facebook-login";

import { FACEBOOK_CLIENT_ID } from "../../../../constants/config";
import { ReactComponent as FacebookLogo } from "../../../../assets/newLanding/auth/Facebook.svg";
import { authWithSocial } from "../../../../helpers/authWithSocialAccount";
import { redirectOnLogin } from "../../../../helpers/authStorage";
import "./styles.less";
import errorLogger from "../../../../utils/errorLogging/error-logger";

const FacebookButton = ({ authUrl, isDriver }) => {
  const { handleSuccess, handleFailure } = authWithSocial(`${authUrl}/facebook-authentication`);

  const history = useHistory();

  const onSuccess = async (response) => {
    try {
      const data = await handleSuccess(response, "accessToken");
      redirectOnLogin(isDriver, history)({ body: data });
    } catch (e) {
      errorLogger.report(` Auth : Failed to auth with Facebook ${e}`);
    }
  };

  return (
    <>
      <FacebookLogin
        appId={FACEBOOK_CLIENT_ID}
        fields="name,email,picture"
        callback={onSuccess}
        onFailure={handleFailure}
        textButton="Facebook"
        cssClass="facebook-auth-btn"
        icon={<FacebookLogo />}
      />
    </>
  );
};

export default FacebookButton;
