import React from "react";
import { Layout } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { useHooks } from "./hooks/useHooks";
import { actions as retailerActions } from "../../redux/resources/retailer";
import RetailerCabinetHeader from "../../components/OrdersWidget/OrdersWidgetHeader";
import InputGroup from "./components/InputGroup";
import Header from "./components/Header";

import "./retailerManagment.less";

const { Content } = Layout;

const RetailerManagment = ({ isMobile, confirmSelfRetailer }) => {
  const { intl, confirmSelf } = useHooks({
    confirmSelfRetailer,
    isMobile,
  });
  return (
    <div className="retailer-user-managment-page-confirmation">
      <Layout>
        <RetailerCabinetHeader />
        <Content className="retailerCabinetContent">
          <Header />
          <InputGroup intl={intl} confirmSelf={confirmSelf} />
        </Content>
      </Layout>
    </div>
  );
};

const MSTP = (state) => {
  return {
    isMobile: state.admin.isMobile,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(retailerActions, dispatch),
}))(RetailerManagment);
