import React, { useState, useEffect } from "react";
import { Table, Select, Form, Input, Modal, Button, Tag } from "antd";
import { API_URL } from "src/constants/config";
import AddUser from "./AddUser";
import moment from "moment";

import "./index.css";
const SuperUsers = () => {
  const [usersData, setUsersData] = useState([]);
  const [companies, setCompanies] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [confirmAction, setConfirmAction] = useState(false);
  const [actionToPerform, setActionToPerform] = useState("");

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const { Option } = Select;

  const fetchData = async () => {
    try {
      const authToken = localStorage.getItem("authTokenSuper");
      const response = await fetch(`${API_URL}/superadmin/allAdminUsers`, {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setUsersData(data);
        setFilteredData(data);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const filterData = (company, country) => {
    let filtered = usersData;

    if (company) {
      filtered = filtered.filter((user) => user.companyId === company);
    }

    setFilteredData(filtered);
  };
  const filterBySearch = (data, query) => {
    if (!query) {
      return data;
    }

    return data.filter(
      (user) =>
        user.id.toString().includes(query) ||
        user.email.toLowerCase().includes(query.toLowerCase()) ||
        user.userName.toLowerCase().includes(query.toLowerCase()),
    );
  };

  const fetchCompanies = async () => {
    try {
      const authToken = localStorage.getItem("authTokenSuper");
      const response = await fetch(`${API_URL}/superadmin/allcompanies`, {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
      const data = await response.json();

      setCompanies(data);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const handleRowClick = (record) => {
    setSelectedUser(record);
    setIsModalVisible(true);
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
  };

  const closeModal = () => {
    setSelectedUser(null);
    setIsModalVisible(false);
    setConfirmAction(false);
    setActionToPerform("");
  };
  const handleAction = (action) => {
    setActionToPerform(action);
    setConfirmAction(true);
  };

  const performAction = async () => {
    if (actionToPerform === "delete" && selectedUser) {
      try {
        const authToken = localStorage.getItem("authTokenSuper");
        const response = await fetch(`${API_URL}/superadmin/admin/${selectedUser.id}`, {
          method: "DELETE",
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        });

        if (response.ok) {
          console.log(`Driver with ID ${selectedUser.id} deleted successfully`);
          fetchData();
        } else {
          throw new Error("Failed to delete the driver");
        }
      } catch (error) {
        console.error("Error deleting driver:", error);
      }
    }

    closeModal();
  };

  useEffect(() => {
    fetchData();
    fetchCompanies();
  }, []);

  useEffect(() => {
    const filteredBySearch = filterBySearch(usersData, searchQuery);
    setFilteredData(filteredBySearch);
  }, [searchQuery, usersData]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        const isOnline = record.last_login
          ? moment().diff(moment(record.last_login), 'minutes') <= 1
          : false;
  
        return (
          <div>
            {record.last_login ? (
              <Tag color={isOnline ? "green" : "red"}>
                {isOnline ? "Online" : "Offline"}
              </Tag>
            ) : (
              <Tag color={"red"}>Offline</Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Access Type",
      dataIndex: "accessType",
      key: "accessType",
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Company",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      key: "last_login",
      render: (last_login) => {
        if (last_login) {
          const formattedTime = moment(last_login).format("YYYY-MM-DD || hh:mm A");
          const isMorning = moment(last_login).format("A") === "AM";
          const emoji = isMorning ? "🌤️" : "🌙";
  
          return (
            <div>
              <span>{formattedTime} {emoji}</span>
            </div>
          );
        } else {
          return "Long time ago";
        }
      },
    }
  ];

  return (
    <div className="allDriversSettings">
      
      <div className="filters">
        <Select
          defaultValue="Company Name"
          className="companiesList"
          onChange={(value) => {
            setSelectedCompany(value);
            filterData(value, selectedCompany);
          }}
        >
          {companies?.map((company) => (
            <Option key={company.id} value={company.id}>
              {company.name}
            </Option>
          ))}
        </Select>

        <Input placeholder="Id, Email, Name ...." className="searchBox" onChange={handleSearch} value={searchQuery} />
        {searchQuery && <Button onClick={handleClearSearch}>Clear</Button>}
        <AddUser onUserCreated={fetchData} />
      </div>
      
      <Table
        dataSource={filteredData}
        columns={columns}
        scroll={{ x: "max-content" }}
        responsive={true}
        className="superAdminTables"
        rowKey={(record) => record?.id}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />
      <Modal
        title="User Information"
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="cancel" onClick={closeModal}>
            Cancel
          </Button>,
          <Button key="confirmAction" type="primary" onClick={performAction} disabled={!confirmAction}>
            Confirm
          </Button>,
        ]}
      >
        {selectedUser && (
          <div>
            <p>ID: {selectedUser.id}</p>
            <p>Company: {selectedUser.email}</p>
            <p>Access Type: {selectedUser.accessType}</p>
            <p>User Name: {selectedUser.userName}</p>
            <p>Company Name: {selectedUser.companyName}</p>
            <p>Company Id: {selectedUser.companyId}</p>
          </div>
        )}
        {confirmAction && <p>Are you sure you want to {actionToPerform}?</p>}
        {!confirmAction && (
          <div>
            <Button onClick={() => handleAction("delete")}>Delete</Button>
            <Button onClick={() => handleAction("suspend")}>Suspend</Button>
            <Button onClick={() => handleAction("activate")}>Activate</Button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default SuperUsers;
