import React,{useState} from "react";
import { Button, Popconfirm } from "antd";

import { MobileTable } from "../../../../../components/AdminPanel/Mobile/MobileTable";
import { MobileTableExpand } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableExpand";
import { MobileTableRows } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableRows";
import { MobileTableCollapseRows } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableCollapseRows";
import { MobileSelect } from "../../../../../components/AdminPanel/Mobile/MobileSelect";
import { MobilePagination } from "../../../../../components/AdminPanel/Mobile/MobilePagination";
import { NO_INFO } from "../../../../../components/AdminPanel/Mobile/helpers";

export const RetailersGroupsTable = ({
  query,
  groups,
  retailers,
  paginationConfig,
  selectedGroupRetailers,
  selectedRetailer,
  setQuery,
  loadSelectedGroupRetailers,
  deleteGroupById,
  selectRetailer,
  removeGroupMember,
}) => {
  const [expandedId, setExpandedId] = useState(-1);
  const handleSetExpandedId = (id) => {
    setExpandedId(id);
  };
  const onExpandGroup = (groupId) => (bool) => {
    if (bool) {
      loadSelectedGroupRetailers(groupId);
    }
  };

  const onPageChange = async (page, limit) => {
    await setQuery({ ...query, page, limit });
  };

  const retailersOptions = retailers?.map(({ id, name }) => ({ text: name, value: id }));

  return (
    <>
      <MobileTable>
        {groups?.map((group) => (
          <div className="table-item" key={group.id}>
            <MobileTableRows rows={[{ label: "Group", value: `Group-${group.id}` }]} />
            <div className="delete-group">
              <Popconfirm
                title="Are you sure to delete this group?"
                onConfirm={deleteGroupById(group.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button className="mobile-btn mobile-btn--purple">Delete Group</Button>
              </Popconfirm>
            </div>
            <MobileTableExpand setIsPanelCollapsed={onExpandGroup(group.id)} id={group.id} expanded={expandedId===group.id} handleSetExpandedId={handleSetExpandedId}>
              <MobileSelect
                placeholder="Add retailer"
                options={retailersOptions}
                value={selectedRetailer}
                onSelect={selectRetailer}
              />
              {!!selectedGroupRetailers?.length && <div className="mobile-expand-title">RETAILERS LIST</div>}
              {selectedGroupRetailers?.map(({ id, name }) => (
                <>
                  <MobileTableCollapseRows rows={[{ label: "Name", value: name || NO_INFO }]} />
                  <Button onClick={removeGroupMember(id)} className="mobile-btn mobile-btn--purple remove-retailer-btn">
                    Remove
                  </Button>
                </>
              ))}
            </MobileTableExpand>
          </div>
        ))}
      </MobileTable>
      <MobilePagination {...paginationConfig} onPageChange={onPageChange} />
    </>
  );
};
