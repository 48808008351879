
// @ts-nocheck 
import React, { FC, useEffect } from "react";
import { CheckOutlined, CloseOutlined, SyncOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Button, InputNumber, TimePicker, Switch, Typography, Tabs } from "antd";
import { Moment } from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "src/redux";
import { CFRScreenEnum, ICFRSettingsProps } from "../../../types/CloudFleetRouting";
import { actions as jobsMapActions } from "../../../redux/resources/jobsMap";
import { useCFRSettings } from "./useCFRSettings";
import { isRetailer } from "src/helpers/authStorage";
import "./cfr-settings.less";

const { Title } = Typography;
const isObjectEmpty = (obj: any) => !(obj && Object.keys(obj).length > 0);

const CFRSettings: FC<any> = ({
  // props
  selectedOrders,
  selectedRetailer,
  onScreenChange,
  // state
  isCFRRunning,
  isCFRInstance,
  estimatedTime,
  //actions
  fetchJobsMaps,
  cfrStateJobsMap,
  cfrJobsMap,
}) => {
  const { generateJobsByCfr, handleChangeCfrParams, cfrParams, isCFRLoading, CFRResponse } = useCFRSettings({
    fetchJobsMaps,
    cfrStateJobsMap,
    cfrJobsMap,
    isCFRInstance,
    isCFRRunning,
    selectedRetailer,
    estimatedTime,
    selectedOrders,
  });

  useEffect(() => {
    if (CFRResponse && Object.keys(CFRResponse).length > 0) {
      if (CFRResponse?.message || CFRResponse?.createdJobs?.length === 0) {
        onScreenChange(CFRScreenEnum.CFR_ERROR_SCREEN);
      } else if (
        CFRResponse?.createdJobs?.length > 0 &&
        CFRResponse?.skippedOrdersInZones === 0 &&
        CFRResponse?.ordersOutsideOfZones === 0
      ) {
        onScreenChange(CFRScreenEnum.CFR_SUCCESS_SCREEN);
      } else {
        onScreenChange(CFRScreenEnum.CFR_PARTIAL_SUCCESS_SCREEN);
      }
    }
  }, [CFRResponse]);

  const onClickCancel = () => {
    onScreenChange(CFRScreenEnum.ORDERS_SCREEN);
  };

  return (
    <div className="cfr-settings-container">
      <div className="cfr-settings-header">
        <div className="header-left">
          <Title level={5} className="header-title">
            Optimize Route
          </Title>
        </div>
      </div>
      <Tabs defaultActiveKey="basic-settings-tab" size="large">
        <Tabs.TabPane tab="Basic" key="basic-settings-tab">
          <div className="cfr-settings-wrapper">
            {!isRetailer && (
              <div className="cfr-settings-row">
                <div className="cfr-setting">
                  <div className="cfr-zone-switch">
                    <label>{cfrParams.withZones ? "Consider zones" : "Ignore zones"}</label>
                    <Switch
                      data-testid="cfr-params-withZone"
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      defaultChecked={cfrParams.withZones as boolean}
                      onChange={(checked: Boolean, _) => {
                        handleChangeCfrParams(checked, "withZones");
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="cfr-settings-row">
              <div className="cfr-setting">
                <label>Timeout</label>
                <InputNumber
                  data-testid="cfr-params-timeout"
                  className="params-input-with-addon"
                  addonAfter="Sec."
                  onChange={(value) => {
                    handleChangeCfrParams(value, "timeout");
                  }}
                  value={cfrParams.timeout}
                />
              </div>
              <div className="cfr-setting">
                <label>Travel Limit</label>
                <InputNumber
                  data-testid="cfr-params-travel-limit"
                  className="params-input-with-addon"
                  addonAfter="Hrs"
                  onChange={(value) => {
                    handleChangeCfrParams(value, "travelLimit");
                  }}
                  value={cfrParams.travelLimit}
                />
              </div>
            </div>
            <div className="cfr-settings-row">
              <div className="cfr-setting">
                <label>Pickup Window</label>
                <TimePicker.RangePicker
                  placeholder={["Pickup start", "Pickup end"]}
                  value={[cfrParams.pickUpStartTime as Moment, cfrParams.pickUpEndTime as Moment]}
                  format="HH:mm"
                  minuteStep={10}
                  onChange={(values, format) => {
                    handleChangeCfrParams(format, "pickupRange");
                  }}
                />
              </div>
              <div className="cfr-setting">
                <label>Delivery Window</label>
                <TimePicker.RangePicker
                  placeholder={["Delivery start", "Delivery end"]}
                  value={[cfrParams.deliveryStartTime as Moment, cfrParams.deliveryEndTime as Moment]}
                  format="HH:mm"
                  minuteStep={10}
                  onChange={(values, format) => {
                    handleChangeCfrParams(format, "deliveryRange");
                  }}
                />
              </div>
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Advance" key="advance-setting-tab">
          <div className="cfr-settings-wrapper">
            <div className="cfr-settings-row">
              <div className="cfr-setting">
                <label>Avg. Delivery Time</label>
                <InputNumber
                  data-testid="cfr-params-delivery-time"
                  className="params-input-with-addon"
                  addonAfter="Mn"
                  onChange={(value) => {
                    handleChangeCfrParams(value, "averageDeliveryTime");
                  }}
                  value={cfrParams.averageDeliveryTime}
                />
              </div>
              <div className="cfr-setting">
                <label>Avg. Pickup Time</label>
                <InputNumber
                  data-testid="cfr-params-avg-pickup-time"
                  className="params-input-with-addon"
                  addonAfter="Sec."
                  onChange={(value) => {
                    handleChangeCfrParams(value, "averagePickUpTime");
                  }}
                  value={cfrParams.averagePickUpTime}
                />
              </div>
            </div>
            <div className="cfr-settings-row">
              <div className="cfr-setting">
                <label>Driver Cost/Hrs</label>
                <InputNumber
                  data-testid="cfr-params-driver-cost-hr"
                  className="params-input"
                  onChange={(value) => {
                    handleChangeCfrParams(value, "driverCostPerHour");
                  }}
                  value={cfrParams.driverCostPerHour}
                />
              </div>
              <div className="cfr-setting">
                <label>Driver Cost/Km</label>
                <InputNumber
                  data-testid="cfr-params-driverCost"
                  className="params-input"
                  onChange={(value) => {
                    handleChangeCfrParams(value, "driverCostPerKm");
                  }}
                  value={cfrParams.driverCostPerKm}
                />
              </div>
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>
      <div className="cfr-action-wrapper">
        <Button onClick={onClickCancel} disabled={isCFRInstance?.isRunning || isCFRLoading}>
          Cancel
        </Button>
        <Button
          data-testid="cfr-config-runBtn"
          type="primary"
          className="run-cfr-btn"
          icon={isCFRInstance?.isRunning || isCFRLoading ? <SyncOutlined spin /> : <CaretRightOutlined />}
          onClick={generateJobsByCfr}
          disabled={isCFRInstance?.isRunning || isCFRLoading}
        >
          Run CFR
        </Button>
      </div>
    </div>
  );
};

const MSTP = (state: RootState, props: ICFRSettingsProps) => {
  const { selectedOrders, selectedRetailer, onScreenChange } = props;

  return {
    onScreenChange,
    selectedOrders,
    selectedRetailer,
    isCFRInstance: state.jobsMap.CFRInstance,
    estimatedTime: state.jobsMap.CFRInstance?.estimatedTime,
    cfrResults: state.jobsMap.cfrResults,
  };
};

export default connect(MSTP, (dispatch) => {
  const { fetchJobsMaps, cfrStateJobsMap, adminCfrJobsMap, retailerCfrJobsMap } = bindActionCreators(
    jobsMapActions,
    dispatch,
  );

  return {
    fetchJobsMaps,
    cfrStateJobsMap,
    cfrJobsMap: isRetailer ? retailerCfrJobsMap : adminCfrJobsMap,
  };
})(CFRSettings);
