import { isArray } from "lodash";
import React, { ReactElement } from "react";

// small utility to format error messages coming from BE
const structureErrorResponse: (message: string | string[]) => string | ReactElement = (message: string | string[]) => {
  if (isArray(message)) {
    return (
      <div>
        {message.map((msg: string) => (
          <p>{msg}</p>
        ))}
      </div>
    );
  } else return message;
};

export { structureErrorResponse };
