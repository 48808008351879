import React, { useState, useEffect } from "react";
import { Divider, Button } from "antd";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { actions as zoneManagementActions } from "../../redux/resources/zoneManagement";
import { useHooks } from "./hooks/useHooks";

import "./zoneManagement.less";

import CustomDivider from "../../components/Divider";
import { getUserTenant } from "../../helpers/authStorage";
import Map from "./components/Map";
import { ReactComponent as WarehouseIcon } from "./components/assets/Warehouse.svg";
import WarehouseDrawer from "./components/WarehouseDrawer";

const ZoneManagement = ({
  isMobile,
  getZones,
  postZones,
  patchZones,
  delZones,
  putWarehouseZones,
  postWarehouseZones,
  delWarehouseZones,
  getWarehouseZones,
  zoneWarehouseZones,
  updateZoneStateZones,
  updateZoneNameZones,
}) => {
  const zones = useSelector(({ zones }) => zones.item || []);
  const {
    // zones,
    warehouses,
    loadZones,
    handleAddZones,
    handleUpdateZones,
    handleDeleteZones,
    loadWarehouses,
    handleAddWarehouse,
    handleDeleteWarehouse,
    handleUpdateWarehouse,
    handleZoneWarehouseChange,
    handleUpdateZoneName,
  } = useHooks({
    getZones,
    postZones,
    patchZones,
    delZones,
    putWarehouseZones,
    postWarehouseZones,
    delWarehouseZones,
    getWarehouseZones,
    zoneWarehouseZones,
    updateZoneStateZones,
    updateZoneNameZones,
  });
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedZoneIndex, setSelectedZoneIndex] = useState(-1);

  const handleSetSelectedZoneIndex = (index) => (event) => setSelectedZoneIndex(index);

  const handleSetDrawerVisible = (value) => {
    setDrawerVisible(value);
    // setSelectedZoneIndex(-1)
  };

  useEffect(() => {
    loadZones();
    loadWarehouses();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="pageWrapper">
        <h2 className="pageTitle">Zone Management</h2>
        <CustomDivider />
        <Divider />
        <Button onClick={() => handleSetDrawerVisible(true)}>Warehouse management</Button>
        <Divider />
        <Map
          drawerVisible={drawerVisible}
          handleAddZones={handleAddZones}
          handleUpdateZones={handleUpdateZones}
          handleDeleteZones={handleDeleteZones}
          zones={zones}
          warehouses={warehouses}
          selectedZoneIndex={selectedZoneIndex}
          setSelectedZoneIndex={handleSetSelectedZoneIndex}
          handleZoneWarehouseChange={handleZoneWarehouseChange}
          handleUpdateZoneName={handleUpdateZoneName}
          getUserTenant={getUserTenant}
        />
        <WarehouseDrawer
          data={warehouses}
          isMobile={isMobile}
          drawerVisible={drawerVisible}
          setDrawerVisible={handleSetDrawerVisible}
          handleAddWarehouse={handleAddWarehouse}
          handleDeleteWarehouse={handleDeleteWarehouse}
          handleUpdateWarehouse={handleUpdateWarehouse}
        />
      </div>
    </>
  );
};

export const WarehouseIconWrap = (props) => (
  <div className="warehouseMarkerContainer">
    <WarehouseIcon {...props} />
    <span
      style={{
        wordWrap: "normal",
        textAlign: "center",
        whiteSpace: "nowrap",
        color: "#01cdfb",
        textStroke: "1px black",
        background: "black",
        borderRadius: "5px",
        padding: "0px 3px",
        fontSize: props.isMobile ? "large" : "x-small",
      }}
    >
      {props.text}
    </span>
  </div>
);

const MSTP = (state) => ({
  isMobile: state.admin.isMobile,
});

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(zoneManagementActions, dispatch),
}))(ZoneManagement);
