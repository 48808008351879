import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions as driversManagerActions } from "../../redux/resources/driversManager";
import { DesktopView } from "./DesktopView";
import { MobileView } from "./MobileView";
import { useHooks } from "./hooks/useHooks";

import "./style.less";

const DriversManager = ({
  isMobile,
  drivers,
  driver,
  driverStats,
  driverAdditionalStats,
  paginationData,
  getCurrentJobDriversManager,
  getJobsDriversManager,
  loadOneDriversManager,
  loadAllDriversManager,
  loadFilteredDriversManager,
  loadDriverStatsDriversManager,
  loadDriverStatsJobCompletionSectionDriversManager,
  loadDriverStatsCashSectionDriversManager,
  loadDriverStatsKMSectionDriversManager,
  changeStatusDriversManager,
  changeCompanyDriversManager,
  assignJobDriversManager,
  cancelAssignJobDriversManager,
  trustDriverDriversManager,
  deleteDriverDriversManager,
  createOneDriversManager,
}) => {
  const [query, setQuery] = useState({ page: 1, limit: localStorage.getItem("driversOnPage") || 5, status: "" });
  const [loading, setLoading] = useState(false);
  const [selectedRetailer, setSelectedRetailer] = useState(null);

  const {
    getAllData,
    getFilteredData,
    getJobsAndRetailers,
    onChangeTable,
    onChangeSearchFilter,
    handleSearchDriver,
    handleAssignJob,
    handleCancelAssignJob,
    handleCompanyChange,
    handleDriverDelete,
    handleCreateDriver,
  } = useHooks({
    driver,
    query,
    setQuery,
    setLoading,
    setSelectedRetailer,
    getJobsDriversManager,
    getCurrentJobDriversManager,
    loadOneDriversManager,
    loadAllDriversManager,
    loadFilteredDriversManager,
    changeCompanyDriversManager,
    assignJobDriversManager,
    cancelAssignJobDriversManager,
    deleteDriverDriversManager,
    createOneDriversManager,
  });

  useEffect(() => {
    query.status ? getFilteredData() : getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const paginationConfig = {
    pageSize: query.limit,
    total: paginationData?.totalCount || 5,
    current: query.page,
    pageSizeOptions: isMobile ? [5, 10, 20] : [10, 20, 30, 50],
    disabled: paginationData?.totalPages === 1,
    position: ["bottomRight"],
  };

  return (
    <>
      {isMobile ? (
        <MobileView
          loading={loading}
          query={query}
          driver={driver}
          drivers={drivers}
          driverAdditionalStats={driverAdditionalStats}
          selectedRetailer={selectedRetailer}
          paginationConfig={paginationConfig}
          getJobsAndRetailers={getJobsAndRetailers}
          setQuery={setQuery}
          onChangeSearchFilter={onChangeSearchFilter}
          changeStatusDriversManager={changeStatusDriversManager}
          handleSearchDriver={handleSearchDriver}
          handleAssignJob={handleAssignJob}
          handleCancelAssignJob={handleCancelAssignJob}
          handleCompanyChange={handleCompanyChange}
          loadOneDriversManager={loadOneDriversManager}
          loadDriverStatsJobCompletionSectionDriversManager={loadDriverStatsJobCompletionSectionDriversManager}
          loadDriverStatsCashSectionDriversManager={loadDriverStatsCashSectionDriversManager}
          loadDriverStatsKMSectionDriversManager={loadDriverStatsKMSectionDriversManager}
          loadDriverStatsDriversManager={loadDriverStatsDriversManager}
          trustDriverDriversManager={trustDriverDriversManager}
        />
      ) : (
        <DesktopView
          loading={loading}
          drivers={drivers}
          driver={driver}
          driverStats={driverStats}
          driverAdditionalStats={driverAdditionalStats}
          selectedRetailer={selectedRetailer}
          paginationConfig={paginationConfig}
          getJobsAndRetailers={getJobsAndRetailers}
          changeStatusDriversManager={changeStatusDriversManager}
          loadOneDriversManager={loadOneDriversManager}
          loadDriverStatsDriversManager={loadDriverStatsDriversManager}
          loadDriverStatsJobCompletionSectionDriversManager={loadDriverStatsJobCompletionSectionDriversManager}
          loadDriverStatsCashSectionDriversManager={loadDriverStatsCashSectionDriversManager}
          loadDriverStatsKMSectionDriversManager={loadDriverStatsKMSectionDriversManager}
          trustDriverDriversManager={trustDriverDriversManager}
          onChangeTable={onChangeTable}
          onChangeSearchFilter={onChangeSearchFilter}
          handleSearchDriver={handleSearchDriver}
          handleAssignJob={handleAssignJob}
          handleCancelAssignJob={handleCancelAssignJob}
          handleCompanyChange={handleCompanyChange}
          handleDriverDelete={handleDriverDelete}
          handleCreateDriver={handleCreateDriver}
        />
      )}
    </>
  );
};

export default connect(
  (state) => ({
    isMobile: state.admin.isMobile,
    drivers: state.driversManager.items,
    driver: state.driversManager.item,
    driverStats: state.driversManager.stats,
    driverAdditionalStats: state.driversManager.additionalStats,
    paginationData: state.driversManager.pagination,
  }),
  (dispatch) => ({
    ...bindActionCreators(driversManagerActions, dispatch),
  }),
)(DriversManager);
