import React from "react";

import { DesktopView } from "./DesktopView";
import { useHooks } from "./hooks/useHooks";
import { MobileView } from "./MobileView";

import "./style.less";

const RetailersGroups = () => {
  const {
    intl,
    query,
    loading,
    isMobile,
    retailers,
    paginationConfig,
    groups,
    selectedGroupRetailers,
    expandedGroup,
    selectedRetailer,
    setQuery,
    createNewGroup,
    setExpandedGroup,
    loadSelectedGroupRetailers,
    deleteGroupById,
    selectRetailer,
    removeGroupMember,
  } = useHooks();

  return (
    <>
      {isMobile ? (
        <MobileView
          intl={intl}
          query={query}
          loading={loading}
          retailers={retailers}
          paginationConfig={paginationConfig}
          groups={groups}
          selectedGroupRetailers={selectedGroupRetailers}
          selectedRetailer={selectedRetailer}
          setQuery={setQuery}
          createNewGroup={createNewGroup}
          loadSelectedGroupRetailers={loadSelectedGroupRetailers}
          deleteGroupById={deleteGroupById}
          selectRetailer={selectRetailer}
          removeGroupMember={removeGroupMember}
        />
      ) : (
        <DesktopView
          intl={intl}
          query={query}
          loading={loading}
          retailers={retailers}
          pagination={paginationConfig}
          groups={groups}
          selectedGroupRetailers={selectedGroupRetailers}
          expandedGroup={expandedGroup}
          selectedRetailer={selectedRetailer}
          setQuery={setQuery}
          createNewGroup={createNewGroup}
          setExpandedGroup={setExpandedGroup}
          loadSelectedGroupRetailers={loadSelectedGroupRetailers}
          deleteGroupById={deleteGroupById}
          selectRetailer={selectRetailer}
          removeGroupMember={removeGroupMember}
        />
      )}
    </>
  );
};

export default RetailersGroups;
