import React from "react";

export default function DoubleDashboardBox({ children }) {
  const style = {
    backgroundColor: "white",
    height: "139px",
    gridColumn: "2 span",
  };

  return (
    <div className="DoubleDashboardBox" style={style}>
      {children}
    </div>
  );
}
