import React, { useState } from "react";
import { Collapse, Empty, Rate } from "antd";
import moment from "moment";

import { MobileTable } from "../../../../../components/AdminPanel/Mobile/MobileTable";
import { MobileTableExpand } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableExpand";
import { MobileTableRows } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableRows";
import { ReactComponent as OpenCollapse } from "../../../../../assets/adminPanel/mobile-collapse.svg";
import { ReactComponent as OpenedCollapse } from "../../../../../assets/adminPanel/mobile-opened-collase.svg";
import { MobileTableCollapseRows } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableCollapseRows";
import { NO_INFO, transformStatus } from "../../../../../components/AdminPanel/Mobile/helpers";
import { MobileTabs } from "../../../../../components/AdminPanel/Mobile/MobileTabs";
import { OrderGoogleMap } from "../../../../../components/OrderGoogleMap";

const { Panel } = Collapse;

export const JobsTable = ({ data, centerCoordinates, setSelectedOrder }) => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [innerCollapsed, setInnerCollapsed] = useState(null);

  const collapseHeader = (orderId) => (
    <div className="expand-inner-collapse__panel-header">
      <div className="left">Order ID</div>
      <div className="right">
        <div>{orderId}</div>
        {innerCollapsed === orderId ? <OpenedCollapse /> : <OpenCollapse />}
      </div>
    </div>
  );

  const onInnerCollapse = (orderId) => {
    setInnerCollapsed(Number(orderId));
    setSelectedOrder(
      data.find((job) => job.jobId === selectedJob).orders.find((order) => order.id === Number(orderId)),
    );
  };

  const getOrderStatus = (status) => {
    switch (status) {
      case "completed":
        return <span className="badge-success">Completed</span>;
      case "processing":
        return <span className="badge-pending">Processing</span>;
      case "canceled":
        return <span className="badge-declined">Canceled</span>;
      case "canceled_after_pickup":
        return <span className="badge-declined">Canceled after pickup</span>;
      default:
        return <span className="badge-pending">{transformStatus(status)}</span>;
    }
  };

  return (
    <MobileTable>
      {data?.map((job) => (
        <div className="table-item" key={job.jobId}>
          <MobileTableRows
            rows={[
              { label: "Job ID", value: job.jobId },
              { label: "Date of Completion", value: job.dateOfCompetition || NO_INFO },
              { label: "Driver Name", value: job.driverName || NO_INFO },
            ]}
          />
          <MobileTableExpand
            expanded={selectedJob === job.jobId}
            handleSetExpandedId={() => setSelectedJob(job.jobId)}
            setIsPanelCollapsed={(bool) => {
              if (bool) {
                setSelectedJob(job.jobId);
              } else {
                setSelectedJob(null);
              }
            }}
          >
            {job.orders.length ? (
              <Collapse
                onChange={onInnerCollapse}
                accordion
                expandIconPosition="right"
                className="expand-inner-collapse"
              >
                {job.orders.map(
                  (order) =>
                    order && (
                      <Panel header={collapseHeader(order.id)} key={order.id}>
                        <MobileTabs
                          leftTabTitle="Order Info"
                          rightTabTitle="More"
                          leftTab={
                            <MobileTableCollapseRows
                              rows={[
                                { label: "Order ID", value: <span className="link">{order.id}</span> },
                                { label: "Tracking ID", value: order.trackingId },
                                { label: "Cash on Delivery", value: order.cashAmount || NO_INFO },
                                {
                                  label: "Rating",
                                  value: (
                                    <Rate
                                      className="rate"
                                      allowHalf
                                      defaultValue={order.feedback?.rating || 0}
                                      disabled
                                    />
                                  ),
                                },
                                {
                                  label: "Feedback Message",
                                  value: order.feedback?.body || <span className="no-info">No message left</span>,
                                },
                                { label: "Status", value: getOrderStatus(order.status) },
                                { label: "Notes", value: order.notes || NO_INFO },
                                { label: "Estimated", value: order.deliveryTime || NO_INFO },
                                {
                                  label: "Real Time",
                                  value: order.realDeliveryTimeUTC
                                    ? moment(order.realDeliveryTimeUTC).format("DD.MM.YYYY HH:mm")
                                    : NO_INFO,
                                },
                              ]}
                            />
                          }
                          rightTab={
                            <>
                              {centerCoordinates?.lat && centerCoordinates?.long && (
                                <div className="mobile-map-wrapper">
                                  <OrderGoogleMap orderInfo={order} centerCoordinates={centerCoordinates} />
                                </div>
                              )}
                              <MobileTableCollapseRows
                                title="RETAILER"
                                rows={[
                                  { label: "Name", value: order.retailer?.name || NO_INFO },
                                  { label: "Office Address", value: order.retailer?.email || NO_INFO },
                                  { label: "Phone", value: order.retailer?.phoneNumber || NO_INFO },
                                  { label: "Support Number", value: order.retailer?.supportNumber || NO_INFO },
                                ]}
                              />
                              <MobileTableCollapseRows
                                title="CUSTOMER"
                                rows={[
                                  { label: "Name", value: order.customerInfo?.name || NO_INFO },
                                  { label: "Email", value: order.car?.customerInfo?.email || NO_INFO },
                                  { label: "Phone", value: order.customerInfo?.phoneNumber || NO_INFO },
                                  {
                                    label: "Lat and lng",
                                    value: order.deliveryPoint?.lat ? (
                                      <>
                                        <div>lat: {order.deliveryPoint.lat}</div>
                                        <div>long: {order.deliveryPoint.long}</div>
                                      </>
                                    ) : (
                                      NO_INFO
                                    ),
                                  },
                                  ...[
                                    {
                                      label: "City",
                                      value: order.customerInfo?.initialAddress?.city,
                                    },
                                    {
                                      label: "Zone",
                                      value: order.customerInfo?.initialAddress?.zone,
                                    },
                                    {
                                      label: "Street",
                                      value: order.customerInfo?.initialAddress?.street,
                                    },
                                    {
                                      label: "Customer Address",
                                      value: order.customerInfo?.initialAddress?.address,
                                    },
                                    {
                                      label: "Building",
                                      value: order.customerInfo?.initialAddress?.building,
                                    },
                                  ].filter(({ value }) => value),
                                  { label: "Amount Cash On Delivery", value: order.cashAmount || NO_INFO },
                                ]}
                              />
                            </>
                          }
                        />
                      </Panel>
                    ),
                )}
              </Collapse>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </MobileTableExpand>
        </div>
      ))}
    </MobileTable>
  );
};
