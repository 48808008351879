import React, { useEffect, useState } from "react";
import { Divider, Checkbox } from "antd";

import { MobileLoader } from "../../../components/AdminPanel/Mobile/MobileLoader";
import { MobileTabs } from "../../../components/AdminPanel/Mobile/MobileTabs";
import { MobileTitle } from "../../../components/AdminPanel/Mobile/MobileTitle";
import { BulkActions } from "../components/actionPanel";
import { MobileConfirmModal } from "./components/MobileConfirmModal";
import { MobileDeliveryTable } from "./components/MobileDeliveryTable";
import { MobileLocationsTable } from "./components/MobileLocationsTable";
import { MobileRetailerChangesTable } from "./components/MobileRetailerChangesTable";
import { useHooks } from "./hooks";

import "./styles.less";
import { MobileRetailerSMSTable } from "./components/MobileRetailerSMSTable";
import { useParams } from "react-router-dom";

export const MobileView = ({
  defaultUrl,
  loading,
  selection,
  locationTableData,
  locationTablePagination,
  deliveryTableData,
  deliveryTablePagination,
  fetchLocationsChangeAdminCustomers,
  checkRecordAdminCustomers,
  archiveRecordAdminCustomers,
  fetchDriversPoints,
  approveDriversPoints,
  rejectDriversPoints,
  waitDriversPoints,
  handleToggleOne,
  handleToggleMany,
  isSelected,
  deleteSelection,
  clearSelection,
  isAllOrdersChecked,
  setAllOrdersChecked,
  retailerChangesTableData,
  retailerChangesTablePagination,
  fetchRetailerChangeNotifications,
  fetchRetailerChangeNumberNotifications,
  approveRetailerChangeNotifications,
  rejectRetailerChangeNotifications,
  getOneRetailerData,

  getSMSChangesNotifications,
  approveSMSChangesNotifications,
  rejectSMSChangesNotifications,
  retailerSMSTableData,
  retailerSMSTablePagination,
  onRevokeSMSRights,
  getSMSChangesGroupNotifications,
}) => {
  const {
    location,
    modalVisible,
    locationPaginationConfig,
    deliveryPaginationConfig,
    retailerChangesPaginationConfig,
    retailerSMSPaginationConfig,
    fetchData,
    refreshQuery,
    onChangePagination,
    onConfirmModal,
    onOpenModal,
    onCloseModal,
    tabName,
    setTabName,
    statusFilter,
    setStatusFilter,
  } = useHooks({
    clearSelection,
    defaultUrl,
    locationTablePagination,
    deliveryTablePagination,
    retailerChangesTablePagination,
    retailerSMSTablePagination,
    fetchLocationsChangeAdminCustomers,
    fetchDriversPoints,
    checkRecordAdminCustomers,
    archiveRecordAdminCustomers,
    approveDriversPoints,
    rejectDriversPoints,
    waitDriversPoints,
    approveRetailerChangeNotifications,
    rejectRetailerChangeNotifications,
    fetchRetailerChangeNotifications,
    getSMSChangesNotifications,
    approveSMSChangesNotifications,
    rejectSMSChangesNotifications,
  });
  const { activeTab = "1" } = useParams();
  const [tab, setTab] = useState("1");

  useEffect(() => {
    if (activeTab) {
      setTab(activeTab)
    }
  }, [activeTab]);

  const toggleTabs = (e) => {
    setTabName((prev) => {
      switch (e) {
        case "1":
          return "delivery";
        case "2":
          return "locations";
        case "3":
          return "bad_locations";
        case "4":
          return "retailer_changes";
        case "5":
          return "sms_changes";
        default:
          return "";
      }
    });
    setAllOrdersChecked(false);
  };

  const getList = (name) => {
    switch (name) {
      case "locations":
        return locationTableData;
      case "bad_locations":
        return locationTableData;
      case "delivery":
        return deliveryTableData;
      case "retailer_changes":
        return retailerChangesTableData;
      case "sms_changes":
        return retailerSMSTableData;
      default:
        return [];
    }
  };

  const handleSelectAll = (e) => {
    handleToggleMany(e, getList(tabName));
    setAllOrdersChecked((prev) => !prev);
  };

  const handleTabChange = (e) => {
    setTab(e);
    refreshQuery(e);
    clearSelection();
    toggleTabs(e);
  };

  return (
    <div className="mobile-page-wrapper mobile-page-wrapper--notifications">
      {loading && <MobileLoader />}
      <MobileTitle title="Notifications" />
      {!["delivery", "retailer_changes", "sms_changes"].includes(tabName) && (
        <BulkActions
          selection={selection}
          onDelete={deleteSelection(tabName)}
          children={
            <Checkbox checked={isAllOrdersChecked} onChange={handleSelectAll}>
              Select All
            </Checkbox>
          }
        />
      )}
      <Divider />
      <MobileTabs
        leftTabTitle="Locations change"
        rightTabTitle="Move to next delivery"
        centralTabTitle="Bad locations"
        centralSecondTabTitle="Retailer Changes"
        centralThirdTabTitle="SMS Changes"
        onChange={handleTabChange}
        defaultActiveKey={activeTab}
        activeKey={tab}
        leftTab={
          <MobileLocationsTable
            data={locationTableData}
            location={location}
            paginationConfig={locationPaginationConfig}
            fetchLocationsChangeAdminCustomers={fetchLocationsChangeAdminCustomers}
            fetchData={fetchData}
            onOpenModal={onOpenModal}
            onChangePagination={onChangePagination}
            isSelected={isSelected}
            handleToggleOne={handleToggleOne}
            handleToggleMany={handleToggleMany}
            clearSelection={clearSelection}
          />
        }
        rightTab={
          <MobileDeliveryTable
            data={deliveryTableData}
            location={location}
            paginationConfig={deliveryPaginationConfig}
            fetchDriversPoints={fetchDriversPoints}
            fetchData={fetchData}
            onOpenModal={onOpenModal}
            onChangePagination={onChangePagination}
            isSelected={isSelected}
            handleToggleOne={handleToggleOne}
            handleToggleMany={handleToggleMany}
            clearSelection={clearSelection}
          />
        }
        centralTab={
          <MobileLocationsTable
            isBadLocation
            data={locationTableData}
            location={location}
            paginationConfig={locationPaginationConfig}
            fetchLocationsChangeAdminCustomers={fetchLocationsChangeAdminCustomers}
            fetchData={fetchData}
            onOpenModal={onOpenModal}
            onChangePagination={onChangePagination}
            isSelected={isSelected}
            handleToggleOne={handleToggleOne}
            handleToggleMany={handleToggleMany}
            clearSelection={clearSelection}
          />
        }
        centralSecondTab={
          <MobileRetailerChangesTable
            data={retailerChangesTableData}
            location={location}
            paginationConfig={retailerChangesPaginationConfig}
            fetchRetailerChangeNotifications={fetchRetailerChangeNotifications}
            fetchData={fetchData}
            onOpenModal={onOpenModal}
            onChangePagination={onChangePagination}
            isSelected={isSelected}
            handleToggleOne={handleToggleOne}
            handleToggleMany={handleToggleMany}
            clearSelection={clearSelection}
            getOneRetailerData={getOneRetailerData}
            approveRetailerChangeNotifications={approveRetailerChangeNotifications}
            rejectRetailerChangeNotifications={rejectRetailerChangeNotifications}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
          />
        }
        centralThirdTab={
          <MobileRetailerSMSTable
            data={retailerSMSTableData}
            location={location}
            paginationConfig={retailerSMSPaginationConfig}
            getSMSChangesNotifications={getSMSChangesNotifications}
            fetchData={fetchData}
            onOpenModal={onOpenModal}
            onChangePagination={onChangePagination}
            isSelected={isSelected}
            handleToggleOne={handleToggleOne}
            handleToggleMany={handleToggleMany}
            clearSelection={clearSelection}
            getOneRetailerData={getOneRetailerData}
            approveSMSChangesNotifications={approveSMSChangesNotifications}
            rejectSMSChangesNotifications={approveSMSChangesNotifications}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            onRevokeSMSRights={onRevokeSMSRights}
            getSMSChangesGroupNotifications={getSMSChangesGroupNotifications}
          />
        }
      />
      <MobileConfirmModal visible={modalVisible} onConfirm={onConfirmModal} onClose={onCloseModal} />
    </div>
  );
};
