import React from "react";
import CustomDivider from "../../components/Divider";

export default function ProhibitedPage() {
  return (
    <div
      className="pageWrapper"
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "30%",
      }}
    >
      <h2 className="pageTitle">Sorry</h2>
      <CustomDivider />
      You have no access to this page...
      <a href="/admin">Return Home</a>
    </div>
  );
}
