/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from "react";
import { assignIn, omit } from "lodash";

import { useIntl } from "react-intl";
import { notification, message } from "antd";
import { getQueryParam, updatePageUrl } from "../../helpers/general";

import moment from "moment";

export const useHooks = ({
  getSelfInfoRetailer,
  fetchRetailerOrders,
  history,
  location,
  fetchExtendedStatsRetailerOrder,
  saveUploadedRetailerOrder,
  sendStoreToStoreRetailerOrder,
  getOwnSMSGroupsRetailer,
  allowedSMS,
  createRetailerTicket,
}) => {
  const [isOrdersSaving, setIsOrdersSaving] = useState(false);
  const [filteredLocationShared, setFilteredLocationShared] = useState(null);
  const [stats, setStats] = useState({});
  const [selfStoresSelection, setSelfStoresSelection] = useState([]);
  const [selfTemplateSelection, setSelfTemplateSelection] = useState([]);

  const intl = useIntl();

  useEffect(() => {
    getSelfInfoRetailer().catch((err) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSelfInfoRetailer]);
  const getOrdersManagmentQueryParams = useCallback(() => getQueryParam(location.search), [location.search]);

  const fetchAllOrders = useCallback(
    (otherQueries) => {
      const queryParams = getOrdersManagmentQueryParams();
      const queryWithPage = queryParams.page ? queryParams : { ...queryParams, page: 1, limit: 10 };
      const withOtherQueries = otherQueries ? assignIn(queryWithPage, otherQueries) : queryWithPage;

      fetchRetailerOrders(
        {},
        {
          query: withOtherQueries,
        },
      ).catch((error) =>
        notification.error({
          message: "Error",
          description: error.body && error.body.message && error.body.message,
        }),
      );
    },
    [fetchRetailerOrders, getOrdersManagmentQueryParams],
  );

  const fetchRetailerStats = () => {
    fetchExtendedStatsRetailerOrder(
      {},
      {
        query: {
          from: moment().startOf("day").toISOString(true).substring(0, 23),
          to: moment().endOf("day").toISOString(true).substring(0, 23),
        },
      },
    ).then(setStats, () =>
      setStats({
        dailyTotal: "No info",
        totalDelivered: "No info",
        totalTransit: "No info",
        totalDeliveryCash: "No info",
      }),
    );
  };

  useEffect(() => {
    fetchRetailerStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchExtendedStatsRetailerOrder]);

  const updateOrdersManagmentPageUrl = (newQueryParams, queriesToRemove) => {
    const previousQueryParams = omit(getOrdersManagmentQueryParams(), Object.keys(queriesToRemove));
    updatePageUrl(newQueryParams, previousQueryParams, history, "retailer_cabinet/home");
  };

  const changeQueryParams = (pagination, filters) => {
    const search = omit(pagination, ["pageSizeOptions", "position", "showSizeChanger", "total", "pageSize", "current"]);
    const isLocationShared = filters?.locationShared?.[0] ? filters?.locationShared?.[0] : null;
    setFilteredLocationShared(isLocationShared);
    updateOrdersManagmentPageUrl(
      {
        ...search,
        ...(filters?.locationShared && { locationShared: isLocationShared }),
        page: pagination.current,
        limit: pagination.pageSize,
      },
      {
        ...(!filters?.locationShared && { locationShared: true }),
      },
    );
  };

  const saveRetailerOrders = (response, type = null, templateId) => {
    setIsOrdersSaving(true);
    if (type !== "store") {
      saveUploadedRetailerOrder(response, {
        query: {
          ...(templateId !== "None selected" && { messagingTemplateGroupId: templateId }),
          withWarehouses: false,
        },
      })
        //the excutions of next line was moved to orders Management
        //.then(() => fetchRetailerOrders({}, { query: { status: withoutCompleted } }))
        .then(() => {
          setIsOrdersSaving(false);
          message.success(`${response.length} orders uploaded successfully.`);
        })
        .catch((error) => {
          setIsOrdersSaving(false);
          //fetchRetailerOrders({}, { query: { status: withoutCompleted } });
          return console.error("error", error);
        });
    } else {
      sendStoreToStoreRetailerOrder(response, {
        query: { ...(templateId !== "None selected" && { messagingTemplateGroupId: templateId }) },
      })
        //.then(() => fetchRetailerOrders({}, { query: { status: withoutCompleted } }))
        .then(() => {
          setIsOrdersSaving(false);
          message.success(`${response.length} orders uploaded successfully.`);
        })
        .catch((error) => {
          setIsOrdersSaving(false);
          //fetchRetailerOrders({}, { query: { status: withoutCompleted } });
          message.error("Invalid orders");
          return console.error("error", error);
        });
    }
  };

  const fetchStoreSelection = () => {
    getSelfInfoRetailer()
      .then((res) => {
        setSelfStoresSelection(res.body?.pickupPoints.filter((e) => e?.lat && e?.long));
      })
      .catch((err) => {
        //TODO
      });
    if (allowedSMS) {
      // only request templates when allowed
      getOwnSMSGroupsRetailer().then(
        (res) => {
          setSelfTemplateSelection(res.body);
        },
        (e) => {},
      );
    }
  };

  const createTicket = (ticket) => {
    createRetailerTicket(ticket)
      .then(() => {
        notification.success({
          message: "Success",
          description: "Ticket created successfully.",
        });
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error?.body?.message || "OOPS! Unable to create ticket",
        });
      });
  };
  const currentQueryParams = getOrdersManagmentQueryParams();

  return {
    intl,
    fetchAllOrders,
    getOrdersManagmentQueryParams,
    fetchStoreSelection,
    selfStoresSelection,
    selfTemplateSelection,
    stats,
    isOrdersSaving,
    saveRetailerOrders,
    changeQueryParams,
    filteredLocationShared,
    currentQueryParams,
    createTicket,
    fetchRetailerStats,
  };
};
