import React, { useEffect, useState } from "react";
import { actions as logsActions } from "../../redux/resources/logs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import "./style.less";

import LogsButton from "./LogsButton";
import LogsArea from "./LogsArea";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

function LogsWrapper({ fetchLogs, isMobile }) {
  const [areLogsOpen, toggleLogs] = useState(false);
  const [logs, setLogs] = useState([]);
  const [logsPagination, setLogsPagination] = useState({});
  const [dateRange, setDateRange] = useState([moment().startOf("year"), moment()]);

  const updateLogs = (search, property, from, to) => {
    const tokens = JSON.parse(localStorage.getItem("authData"));
    if (tokens.access === "admin") {
      fetchLogs(
        {},
        {
          query: {
            ...(search && {
              search,
              property,
            }),
            from: dateRange[0].toISOString(true).substring(0, 23),
            to: dateRange[1].toISOString(true).substring(0, 23),
          },
        },
      ).then((res) => {
        if (res.body) {
          setLogs(res.body.items);
          setLogsPagination({ ...res.body.meta, next: res.body.links.next });
        }
      });
    }
  };
  const loadMoreLogs = (search, property, from, to) => {
    const tokens = JSON.parse(localStorage.getItem("authData"));
    if (tokens.access === "admin") {
      fetchLogs(
        {},
        {
          query: {
            page: logsPagination.currentPage + 1,
            ...(search && {
              search,
              property,
            }),
            from: dateRange[0].toISOString(true).substring(0, 23),
            to: dateRange[1].toISOString(true).substring(0, 23),
          },
        },
      ).then((res) => {
        if (res.body) {
          setLogs((old) => [...old, ...res.body.items]);
          setLogsPagination({ ...res.body.meta, next: res.body.links.next });
        }
      });
    }
  };

  useEffect(() => {
    updateLogs();
    // eslint-disable-next-line
  }, [areLogsOpen]);

  return (
    <div className={areLogsOpen ? "LogsWrapper opened" : "LogsWrapper"}>
      {areLogsOpen ? (
        <LogsArea
          dateRange={dateRange}
          setDateRange={setDateRange}
          logsArray={logs}
          logsPagination={logsPagination}
          isMobile={isMobile}
          updateLogs={updateLogs}
          loadMoreLogs={loadMoreLogs}
        >
          <LogsButton areLogsOpen toggleLogs={toggleLogs}>
            <ArrowRightOutlined />
          </LogsButton>
        </LogsArea>
      ) : (
        <LogsButton toggleLogs={toggleLogs}>
          <ArrowLeftOutlined />
        </LogsButton>
      )}
    </div>
  );
}

const MSTP = (state) => {
  return {
    isMobile: state.admin.isMobile,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(logsActions, dispatch),
}))(LogsWrapper);
