import React from "react";
import { DatePicker, Table } from "antd";

import { reportTableRows } from "../../../system-report-config";

const NO_DATA_PLACEHOLDER = "No data";

const SystemReportTable = ({
  isMobile,
  onChangeTargetMonth,
  onChangeComparedMonth,
  targetMonthData = null,
  comparedMonthData = null,
}) => {
  const compareValues = (targetVal, comparedVal) => {
    let str;

    if ([targetVal, comparedVal].some((el) => el === NO_DATA_PLACEHOLDER)) {
      str = NO_DATA_PLACEHOLDER;
    } else if ([targetVal, comparedVal].some((el) => el === 0)) {
      str = `${0}%`;
    } else {
      str = `${Math.floor(((comparedVal - targetVal) / targetVal) * 100 * 100) / 100}%`;
    }

    return str;
  };

  const columns = isMobile
    ? [
        {
          title: "Target",
          dataIndex: "targetValue",
          key: "targetValue",
          width: "42%",
        },
        {
          title: "Compared",
          dataIndex: "comparedValue",
          key: "comparedValue",
          width: "42%",
        },
        {
          title: "Comparison",
          dataIndex: "comparison",
          key: "comparison",
          width: "16%",
        },
      ]
    : [
        {
          title: <DatePicker picker="month" onChange={onChangeTargetMonth} format="MM.YYYY" />,
          dataIndex: "targetValue",
          key: "targetValue",
        },
        {
          title: <DatePicker picker="month" onChange={onChangeComparedMonth} format="MM.YYYY" />,
          dataIndex: "comparedValue",
          key: "comparedValue",
        },
        {
          title: "Comparison",
          dataIndex: "comparison",
          key: "comparison",
        },
      ];

  const data = reportTableRows.map(({ key }) => {
    const targetNumericVal = Number(targetMonthData?.[key]);
    const comparedNumericVal = Number(comparedMonthData?.[key]);

    const targetValue = Number.isNaN(targetNumericVal) ? NO_DATA_PLACEHOLDER : targetNumericVal;
    const comparedValue = Number.isNaN(comparedNumericVal) ? NO_DATA_PLACEHOLDER : comparedNumericVal;

    return {
      targetValue,
      comparedValue,
      comparison: compareValues(targetValue, comparedValue),
    };
  });

  return (
    <div className="report-table">
      <div className="report-table__rows">
        {reportTableRows.map(({ title, key }) => (
          <div key={key}>{title}</div>
        ))}
      </div>
      <div className="report-table__table">
        <Table columns={columns} dataSource={data} pagination={false} size={isMobile ? "small" : "large"} />
      </div>
    </div>
  );
};

export default SystemReportTable;
