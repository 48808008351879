/* global google */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { getRandomColor } from "src/helpers/general";
import { customFetch } from "../../../utils/customFetch";
import { ROUTES_API_KEY } from "../../../constants/config";
import { notification } from "antd";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { getPointsByType, getPolyline } from "src/helpers/maps/maps.helpers";
import { PointTypeEnum } from "src/entities";

interface DeliveryPoint {
  address: string;
  duration: { text: string; value: number };
  id: number;
  isWarehouse: boolean;
  lat: number;
  long: number;
  visited: boolean;
}

interface RouteResponseType {
  distanceMeters: number;
  duration: string;
  polyline: {
    encodedPolyline: string;
  };
  legs: {
    startLocation: {
      latLng: {
        latitude: number;
        longitude: number;
      };
    };
    endLocation: {
      latLng: {
        latitude: number;
        longitude: number;
      };
    };
  }[];
  viewport: {
    high: {
      latitude: number;
      longitude: number;
    };
    low: {
      latitude: number;
      longitude: number;
    };
  };
}
export const useMapDirectionConfig = () => {
  // add marker

  // render routes ( i.e. polylins on map)
  const displayRoutes = (
    routes: RouteResponseType[],
    map: google.maps.Map,
    maps: typeof google.maps,
    addMarker: (position: google.maps.LatLngLiteral, i: number, color: string) => google.maps.Marker,
  ) => {
    if (routes?.length && maps.geometry) {
      const routeColor = getRandomColor();
      const route = routes[0];
      // decode the path
      var decodedPath = maps.geometry.encoding.decodePath(route.polyline?.encodedPolyline);
      const Path = new google.maps.Polyline({
        path: decodedPath,
        geodesic: true,
        strokeColor: routeColor,
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });

      Path.setMap(map);

      // markers
      addMarker(
        {
          lat: route?.legs[0].startLocation.latLng.latitude,
          lng: route?.legs[0].startLocation.latLng.longitude,
        },
        0,
        routeColor,
      );

      const markers = route.legs?.map((leg, i) => {
        return addMarker(
          {
            lat: leg.endLocation.latLng.latitude,
            lng: leg.endLocation.latLng.longitude,
          },
          i,
          routeColor,
        );
      });

      new MarkerClusterer({ map: map, markers: markers });
      const viewPort = routes[0].viewport;
      const Bounds = new google.maps.LatLngBounds(
        {
          lat: viewPort.low.latitude,
          lng: viewPort.low.longitude,
        },
        {
          lat: viewPort.high.latitude,
          lng: viewPort.high.longitude,
        },
      );
      map.fitBounds(Bounds);
    }
  };

  const calculateAndDisplayRoute = async (items: any[], map: google.maps.Map, maps: typeof google.maps) => {
    const Routes: Map<number, any> = new Map<number, any>();
    const shouldUseWayoptimizer = (waypoints: google.maps.DirectionsWaypoint[]) => waypoints && waypoints.length > 25;
    const addMarker = (position: google.maps.LatLngLiteral, i: number, color: string) => {
      return new maps.Marker({
        icon: `http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${i}|FF0000|FFFFFF`,

        position,
        map,
      });
    };
    // to handle waypoints issue
    const getOptimizedRoute = async (
      jobId: number,
      origin: string | google.maps.LatLng | google.maps.Place | google.maps.LatLngLiteral,
      destination: string | google.maps.LatLng | google.maps.Place | google.maps.LatLngLiteral,
      waypoints: google.maps.DirectionsWaypoint[],
    ) => {
      try {
        if (!origin || !destination) {
          notification.warn({ message: `Job n° ${jobId} doesn't have a pickup or delivery point !` });
          return;
        }
        const useOptimizer = shouldUseWayoptimizer(waypoints);
        const response = await customFetch(`https://routespreferred.googleapis.com/v1alpha:computeRoutes`, {
          method: "POST",
          cache: "no-cache",
          headers: {
            "X-Server-Timeout": 15, // set time out to 15 seconds
            "X-Goog-Api-Key": ROUTES_API_KEY,
            "X-Goog-FieldMask":
              "routes.optimizedIntermediateWaypointIndex,routes.legs,routes.distanceMeters,routes.viewport,routes.polyline.encodedPolyline",
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: JSON.stringify({
            origin: origin,
            destination: destination,
            intermediates: waypoints,
            travelMode: "DRIVE",
            optimizeWaypointOrder: useOptimizer,
            routingPreference: "TRAFFIC_AWARE",
          }),
        });
        if (response.status === 200) {
          const results: {
            routes: RouteResponseType[];
          } = await response.json();

          if (results?.routes?.length) {
            Routes.set(jobId, results?.routes);
            displayRoutes(results?.routes, map, maps, addMarker);
          }
        }
      } catch (error: any) {
        try {
          const res = await error.json();
          notification.error({
            message: "Error from Google maps",
            description: `Compute Routes request failed due to ${res.error?.message}`,
          });
        } catch {
          notification.error({
            message: "Unknown Error",
            description: `An error has occured while displaying routes: ${error}`,
          });
        }
      }
    };

    // TODO : this function is not used anymore
    const getRoute = (
      origin: string | google.maps.LatLng | google.maps.Place | google.maps.LatLngLiteral,
      destination: string | google.maps.LatLng | google.maps.Place | google.maps.LatLngLiteral,
      waypoints: google.maps.DirectionsWaypoint[],
    ) => {
      let delayFactor = 0;
      const directionsService = new maps.DirectionsService();

      directionsService.route(
        {
          origin,
          destination,
          waypoints,

          travelMode: google.maps.TravelMode.DRIVING,
          optimizeWaypoints: true, // added for routing optimization
          unitSystem: google.maps.UnitSystem.METRIC,
        },
        (response: google.maps.DirectionsResult | null, status: google.maps.DirectionsStatus) => {
          if (status === google.maps.DirectionsStatus.OK) {
            let markerCounter = 1;
            const routeColor = getRandomColor();
            const directionsDisplay = new maps.DirectionsRenderer({
              suppressMarkers: true,
              polylineOptions: {
                strokeColor: routeColor, // random color for paths
                strokeOpacity: 1.0,
                strokeWeight: 3,
              },
            });
            directionsDisplay.setMap(map);
            directionsDisplay.setDirections(response);
          } else if (status === google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
            delayFactor++;
            setTimeout(function () {
              getRoute(origin, destination, waypoints);
            }, delayFactor * 1000);
          } else {
            notification.error({
              message: "Error from Google maps",
              description: `Directions request failed due to ${status}`,
            });
          }
        },
      );
    };

    items?.forEach(async (job) => {
      const pickupPoint = getPointsByType(job, PointTypeEnum.PICKUP)?.map((item: DeliveryPoint) => {
        return {
          location: {
            latLng: {
              latitude: item.lat,
              longitude: item.long,
            },
          },
          vehicleStopover: true,
        };
      });

      let waypoints = getPointsByType(job, PointTypeEnum.DELIVERY)?.map((item: DeliveryPoint) => {
        return {
          location: {
            latLng: {
              latitude: item.lat,
              longitude: item.long,
            },
          },
          vehicleStopover: true,
        };
      });

      const origin = pickupPoint?.shift();
      const destination = origin;
      const polyline = getPolyline(job);
      if (polyline !== undefined && maps.geometry) {
        // we use polyline from Job entity
        const routeColor = getRandomColor();

        // decode the path
        var decodedPath = maps.geometry.encoding.decodePath(polyline);
        const Path = new maps.Polyline({
          path: decodedPath,
          geodesic: true,
          strokeColor: routeColor,
          strokeOpacity: 1.0,
          strokeWeight: 3,
        });

        Path.setMap(map);

        let markerCounter = 1;
        addMarker(
          {
            lat: waypoints[0].location.latLng.latitude,
            lng: waypoints[0].location.latLng.longitude,
          },
          markerCounter++,
          routeColor,
        );
        for (let i = 1; i < waypoints.length; i++) {
          addMarker(
            {
              lat: waypoints[i].location.latLng.latitude,
              lng: waypoints[i].location.latLng.longitude,
            },
            markerCounter++,
            routeColor,
          );
        }
      } else {
        // EXPIREMENTAL LMFS :
        await getOptimizedRoute(job.id, origin, destination, waypoints);
        // getRoute(origin, destination, waypoints); will cause 25 waypoints issue
      }
    });
  };

  return {
    calculateAndDisplayRoute,
  };
};
