import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, Button, notification } from "antd";
import { API_URL } from "src/constants/config";
 
const { Option } = Select;

const AddUser = ({ onUserCreated }) => {
  const [showModal, setShowModal] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [countries, setCountries] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState(0);

  const [form] = Form.useForm();

  
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const authToken = localStorage.getItem("authTokenSuper");

        const response = await fetch(`${API_URL}/superadmin/allcompanies`, {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        });
        const data = await response.json();
        setCompanies(data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const authToken = localStorage.getItem("authTokenSuper");

        const response = await fetch(`${API_URL}/superadmin/company_tenants/${selectedCompany}`, {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        });
        const data = await response.json();
        setCountries(data?.tenants || []);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    fetchCountries();
  }, [selectedCompany]);

  const handleConfirm = async () => {
    try {
      const values = await form.validateFields();

      const queryParams = new URLSearchParams({
        email: values.email,
        password: values.password,
        accessType: values.accessType,
        username: values.username,
        companyId: values.companyName,
        tenantIds: values.country,
      }).toString();

      const createUserUrl = `${API_URL}/superadmin/createUser?${queryParams}`;
      const authToken = localStorage.getItem("authTokenSuper");

      const response = await fetch(createUserUrl, {
        method: "POST",
        "Content-Type": "application/json",
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();
      setShowModal(false);  

      notification.success({
        message: "User Created",
        description: "The user has been successfully created.",
        placement: 'top'
      });

      
      if (onUserCreated) {
        onUserCreated();  
      }
      console.log("Success:", data);
    } catch (error) {
      console.error("Error:", error);
      notification.error({
        message: "Error",
        description: "There was an error creating the user. Please try again.",
        placement: 'top'

      });
    }
  };

  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
    setCountries([]);
    form.resetFields(["country"]);
  };

  const AdminUsers = [
    "admin",
    "customer_service",
    "accounts_team",
    "operations_team",
    "driver_manager",
    "warehouse_manager",
  ];

  return (
    <div>
      <Button type="primary" onClick={() => setShowModal(true)}>
        + Add User
      </Button>
      <Modal
        title="Add Driver"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setShowModal(false)}>
            Cancel
          </Button>,
          <Button key="confirm" type="primary" onClick={handleConfirm}>
            Confirm
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" initialValues={{ remember: true }} onFinish={handleConfirm}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please enter email" },
              { type: "email", message: "Please enter a valid email" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="User Name" name="username" rules={[{ required: true, message: "Please enter User Name" }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Password" name="password" rules={[{ required: true, message: "Please enter password" }]}>
            <Input type="password" />
          </Form.Item>

          <Form.Item
            label="Company Name"
            name="companyName"
            rules={[{ required: true, message: "Please select company name" }]}
          >
            <Select onChange={handleCompanyChange}>
              {companies?.map((company) => (
                <Option key={company.id} value={company.id}>
                  {company.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Access Type" name="accessType">
            <Select>
              {AdminUsers?.map((accessType) => (
                <Option key={accessType} value={accessType}>
                  {accessType}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Country" name="country">
            <Select mode="tags">
              {countries?.map((country) => (
                <Option key={country} value={country.id}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddUser;
