import React, { useEffect, useState } from "react";
import { Table, Button, Card, Row, Col } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { API_URL } from "src/constants/config";
import moment from "moment";

const Invoices = () => {
  const [ordersStats, setOrdersStats] = useState({});
  const [invoicesData, setInvoicesData] = useState([]);

  console.log(ordersStats?.totalPackagesCount?.totalPackagesCountForLastMonth);
  const fetchOrdersStats = async () => {
    const getCompanyId = JSON.parse(localStorage.getItem("authData"));
    const data = await fetch(`${API_URL}/superadmin/total-packages/${getCompanyId.companyId}`);
    const result = await data.json();
    setOrdersStats(result);
  };
  const fetchInvoices = async () => {
    const getCompanyId = JSON.parse(localStorage.getItem("authData"));
    const data = await fetch(`${API_URL}/superadmin/invoice/${getCompanyId.companyId}`);
    const result = await data.json();
    setInvoicesData(result);
  };
  useEffect(() => {
    fetchOrdersStats();
    fetchInvoices();
  }, []);

  const paginationConfig = {};

  const onChange = (pagination, filters, sorter) => {};

  const loading = false;

  const columns = [
    { title: "Invoice ID", dataIndex: "invoice_id", key: "invoice_id" },
    { title: "Invoice Date", dataIndex: "invoice_date", key: "invoice_date", render: (invoice_date) => moment(invoice_date).format("YYYY-MM-DD"), },
    { title: "Billing Period", dataIndex: "billing_period", key: "billing_period" },
    { title: "Total Amount", dataIndex: "total_amount", key: "total_amount",  render: (total_amount) => <span>{total_amount} $</span>},
    { title: "Paid At", dataIndex: "paid_at", key: "paid_at" },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Action",
      key: "action",
      render: (text, record) =>
        record.status === "Paid" ? (
          <span>
            <CheckCircleFilled style={{ color: "green" }} /> Paid
          </span>
        ) : (
          <Button onClick={() => handlePayNow(record)} disabled={record.status === "Paid"}>
            Pay Now
          </Button>
        ),
    },
  ];

  const handlePayNow = (record) => {
    const companyName = "yourCompany";
    const invoiceId = record.id;

    const whatsappNumber = "+962779985433";

    const message = `Hello, I am from ${companyName} and I want to pay the invoice with number ${invoiceId}`;

    window.location.href = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
  };
  return (
    <div>
      {/* <Row gutter={16} style={{marginBottom:'4%'}}>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Card title="Total orders">
            <p>Total Orders Created: {ordersStats?.totalPackagesCount?.totalPackagesCount}</p>
          </Card>
        </Col>

        <Col xs={24} sm={12} md={8} lg={8}>
          <Card title="This Month">
            <p>Total Orders Created: {ordersStats?.totalPackagesCount?.totalPackagesCountForThisMonth}</p>
          </Card>
        </Col>

        <Col xs={24} sm={12} md={8} lg={8}>
          <Card title="Last Month">
            <p>Total Orders Created: {ordersStats?.totalPackagesCount?.totalPackagesCountForLastMonth}</p>
          </Card>
        </Col>
      </Row> */}
      {invoicesData.length === 0 ? (
        <>
          <h1 className="display-4 text-center">No Invoices Yet</h1>
        </>
      ) : (
        <Table
          columns={columns}
          dataSource={invoicesData}
          pagination={paginationConfig}
          onChange={onChange}
          loading={loading}
        />
      )}
    </div>
  );
};

export default Invoices;
