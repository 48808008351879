import React, { useState, useEffect } from "react";
import { Table, Tag, Modal, Button } from "antd";
import { API_URL } from "src/constants/config";
import { FiEdit2 } from "react-icons/fi";
import AddDriver from "./AddDriver";
import "./index.css";
const SuperDrivers = () => {
  const [driversData, setDriversData] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmAction, setConfirmAction] = useState(false);
  const [actionToPerform, setActionToPerform] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  /**Edit Email **/
  const [editedEmail, setEditedEmail] = useState("");
  const [isEditingEmail, setIsEditingEmail] = useState(false);

  /**Edit Name **/
  const [editedName, setEditedName] = useState("");
  const [isEditingName, setIsEditingName] = useState(false);

  const fetchData = async () => {
    const authToken = localStorage.getItem("authTokenSuper");

    try {
      const response = await fetch(`${API_URL}/superadmin/alldrivers?page=${currentPage}`, {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setDriversData(data);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "orange";
      case "accepted":
        return "green";
      case "declined":
        return "red";
      case "has_issue":
        return "yellow";
      default:
        return "default";
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleEditEmail = () => {
    setEditedEmail(selectedDriver.email);
    setIsEditingEmail(true);
  };

  const handleEditName = () => {
    setEditedName(selectedDriver.email);
    setIsEditingName(true);
  };
  const handleSaveEmail = async () => {
    try {
      const response = await fetch(`${API_URL}/superadmin/drivers/${selectedDriver.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: editedEmail }),
      });

      if (response.ok) {
        console.log(`Driver with ID ${selectedDriver.id}'s email updated successfully`);
        fetchData();
      } else {
        throw new Error("Failed to update the email");
      }
    } catch (error) {
      console.error("Error updating email:", error);
    }

    setIsEditingEmail(false);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Name",
      dataIndex: "drivername",
      key: "drivername",
    },
    {
      title: "Phone Number ",
      dataIndex: "driverPhone",
      key: "driverPhone",
    },
    {
      title: "Nationality",
      dataIndex: "driverNationality",
      key: "driverNationality",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Trusted Driver",
      dataIndex: "trustedDriver",
      key: "trustedDriver",
      render: (trustedDriver) => (
        <span>
          {trustedDriver ? (
            <span role="img" aria-label="true">
              ✅
            </span>
          ) : (
            <span role="img" aria-label="false">
              ❌
            </span>
          )}
        </span>
      ),
    },
    {
      title: "Average Rating",
      dataIndex: "averageRating",
      key: "averageRating",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <Tag color={getStatusColor(status)}>{status}</Tag>,
    },
  ];

  const handleRowClick = (record) => {
    setSelectedDriver(record);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedDriver(null);
    setConfirmAction(false);
    setActionToPerform("");
  };

  const handleAction = (action) => {
    setActionToPerform(action);
    setConfirmAction(true);
  };

  const performAction = async () => {
    if (actionToPerform === "delete" && selectedDriver) {
      try {
        const response = await fetch(`${API_URL}/superadmin/drivers/${selectedDriver.id}`, {
          method: "DELETE",
        });

        if (response.ok) {
          console.log(`Driver with ID ${selectedDriver.id} deleted successfully`);
          fetchData();
        } else {
          throw new Error("Failed to delete the driver");
        }
      } catch (error) {
        console.error("Error deleting driver:", error);
      }
    }

    closeModal();
  };

  return (
    <div className="allDriversSettings">
      {/* <AddDriver className="addDriverButton" /> */}
      <h2>Drivers</h2>
      <Table
        dataSource={driversData?.drivers}
        columns={columns}
        scroll={{ x: "max-content" }}
        responsive={true}
        className="superAdminTables"
        rowKey={(record) => record?.id} 
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        pagination={{
          total: driversData?.totalDrivers,
          current: currentPage,
          pageSize: 10,
          onChange: handlePageChange,
        }}
      />

      <Modal
        title="Driver Information"
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button key="cancel" onClick={closeModal}>
            Cancel
          </Button>,
          <Button key="confirmAction" type="primary" onClick={performAction} disabled={!confirmAction}>
            Confirm
          </Button>,
        ]}
      >
        {selectedDriver && (
          <div>
            <p>ID: {selectedDriver.id}</p>

            {isEditingName ? (
              <div className="editDriverEmail">
                <input type="text" value={editedName} onChange={(e) => setEditedName(e.target.value)} />
                <Button onClick={handleSaveEmail}>Save</Button>
              </div>
            ) : (
              <div className="editDriverEmail">
                <p>Name: {selectedDriver.drivername}</p>
                <FiEdit2 size={10} className="editmarkerDriver" onClick={handleEditName} />
              </div>
            )}

            {isEditingEmail ? (
              <div className="editDriverEmail">
                <input type="text" value={editedEmail} onChange={(e) => setEditedEmail(e.target.value)} />
                <Button onClick={handleSaveEmail}>Save</Button>
              </div>
            ) : (
              <div className="editDriverEmail">
                <p>Email: {selectedDriver.email}</p>
                <FiEdit2 size={10} className="editmarkerDriver" onClick={handleEditEmail} />
              </div>
            )}

            <p>Status: {selectedDriver.status}</p>
            <p>Company: {selectedDriver.company}</p>
            <p>Average: {selectedDriver.averageRating}</p>
          </div>
        )}
        {confirmAction && <p>Are you sure you want to {actionToPerform}?</p>}
        {!confirmAction && (
          <div>
            <Button onClick={() => handleAction("delete")}>Delete</Button>
            <Button onClick={() => handleAction("suspend")}>Suspend</Button>
            <Button onClick={() => handleAction("activate")}>Activate</Button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default SuperDrivers;
