import React, { useEffect, useState } from "react";
import { Button, Input, Modal, message, Statistic } from "antd";
import { useDispatch } from "react-redux";

import { CopyOutlined } from "@ant-design/icons";
import { actions as customerActions } from "../../../../../../redux/resources/customer";
import { paymentResultRedirectLink, isLocalhost, isStage } from "../../../../../../constants/config";

import "./styles.less";

const { Countdown } = Statistic;
const getDeadline = () => {
  return Date.now() + 1000 * 60;
};

function PayModal({ isMobile, order, onClosePayModal }) {
  // const intl = useIntl();
  const [link, setLink] = useState("");
  const [id, setId] = useState("");

  const [smsTimeout, setSMSTimeout] = useState(false);

  const { generatePaymentLinkCustomer, generatePaymentLinkSMSCustomer } = customerActions;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!order?.payment?.link) {
      dispatch(generatePaymentLinkCustomer({ orderId: order.id, redirectUrl: paymentResultRedirectLink })).then(
        ({ body }) => {
          setLink(body.link);
          setId(body.id);
        },
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    onClosePayModal(order.id);
  };

  const copyToClipboard = () => {
    message.info(<>Copied link to clipboard</>);
    navigator.clipboard.writeText(order?.payment?.link || link);
  };

  const smsButtonShown = isLocalhost || isStage;

  return (
    <>
      <Modal className="payModal" visible title={null} footer={null} onCancel={handleClose}>
        <span
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "flex-start",
            gap: 20,
            marginBottom: 20,
          }}
        >
          <h3>Payment</h3> Order #{order.id}
        </span>
        <span style={{ color: "rgba(34, 34, 34, 0.3)" }}>Payment link:</span>
        <Input.Group compact>
          <Input style={{ width: "calc(100% - 200px)" }} value={order?.payment?.link || link} />
          <div>
            <Button icon={<CopyOutlined />} onClick={copyToClipboard} />
          </div>
        </Input.Group>
        <div>
          <a href={order?.payment?.link || link} style={{ maxWidth: 192, flexGrow: 1 }}>
            <Button type="primary" style={{ marginTop: 15 }} className="payModal-payButton">
              Pay - {order.cashAmount} QAR
            </Button>
          </a>
          {/* <a href={`sms:?&body=Hi! Your payment link: ${link}`}> */}
          {smsButtonShown && (
            <Button
              type="primary"
              style={{ marginTop: 15 }}
              onClick={() => {
                setSMSTimeout(true); // launch the time on click

                dispatch(generatePaymentLinkSMSCustomer({ paymentId: order?.payment?.id || id })).then(
                  () => {
                    message.success("We have sent you an SMS with payment link");
                  },
                  () => {
                    message.error("SMS not sent");
                    setSMSTimeout(false); // if not successful , stop timer and let the user try again
                  },
                );
              }}
              disabled={smsTimeout}
              className="payModal-payButton-sms"
            >
              {smsTimeout ? (
                <Countdown
                  title={false}
                  value={getDeadline()}
                  onFinish={() => setSMSTimeout(false)}
                  format="mm:ss"
                  valueStyle={{ fontWeight: "500 !important" }}
                />
              ) : (
                "Send link via SMS"
              )}
            </Button>
          )}
          {/* </a> */}
        </div>
      </Modal>
    </>
  );
}

export default PayModal;
