import React, { SetStateAction } from "react";
import { Button, DatePicker, Modal, Select, Row, Radio } from "antd";
import { FormattedMessage } from "react-intl";

import "./reportsModal.less";
import {
  rangeDateOptions,
  others,
  optionsCompletedOrders,
  optionsPostponedOrders,
  optionsCanceledOrders,
  optionsFailedOrders,
} from "../../../../components/OrdersWidget/OrdersWidgetHome/constants/helper";

const { Option, OptGroup } = Select;

function RetailerReportsModal({
  isOpen,
  onClose,
  date,
  onCloseModal,
  isLoading,
  handleFetchReport,
  setRangeDateType,
  rangeDateType,

  dlDatepickerControl,
  handleChangeReportDate,
  datePickerDL,
  setDlDatepickerControl,
  setDatePickerDL,

  selectedStatus,
  setSelectedStatus,
}: {
  isOpen: boolean;
  onClose: any;
  date: string;
  onCloseModal: () => React.Dispatch<SetStateAction<boolean>>;
  setRangeDateType: React.Dispatch<SetStateAction<string>>;
  rangeDateType: string;
  isLoading: boolean;
  handleFetchReport: any;

  dlDatepickerControl: any;
  handleChangeReportDate: any;
  datePickerDL: any;
  setDlDatepickerControl: any;
  setDatePickerDL: any;
  selectedStatus: string;
  setSelectedStatus: React.Dispatch<SetStateAction<string>>;
}) {
  const getChoices = (group: any) => {
    const choices: JSX.Element[] = [];
    group.forEach((op: { value: string; label: string }) => {
      choices.push(
        <Option value={op.value} key={op.value}>
          {op.label}
        </Option>,
      );
    });

    return choices;
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={onCloseModal}
      footer={[
        <Button key="Cancel" onClick={onCloseModal} loading={isLoading}>
          <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!date}
          onClick={() => {
            if (date) handleFetchReport(date);
          }}
          loading={isLoading}
        >
          <FormattedMessage id="generate" defaultMessage="Generate" />
        </Button>,
      ]}
    >
      <div>
        <p>
          <FormattedMessage id="chooseDatesToGenerate" defaultMessage="Choose dates to generate report" />
        </p>
        <Row className="filtersOptions__row">
          <label className="filtersOptions__row--label"> Dates </label>

          <div>
            <Select value={rangeDateType} onChange={(value) => setRangeDateType(value)}>
              {getChoices(rangeDateOptions)}
            </Select>
            <DatePicker
              onOpenChange={setDlDatepickerControl}
              open={dlDatepickerControl}
              onChange={handleChangeReportDate}
              picker={datePickerDL}
              renderExtraFooter={() => (
                <Radio.Group
                  value={datePickerDL}
                  onChange={(e) => {
                    setDlDatepickerControl(true);
                    setDatePickerDL(e.target.value);
                  }}
                >
                  <Radio.Button value="day">Day</Radio.Button>
                  <Radio.Button value="week">Week</Radio.Button>
                  <Radio.Button value="month">Month</Radio.Button>
                  <Radio.Button value="year">Year</Radio.Button>
                </Radio.Group>
              )}
            />{" "}
          </div>
        </Row>

        <Row className="filtersOptions__row">
          <label className="filtersOptions__row--label"> Orders status </label>
          <Select
            className="filtersOptions__row--status"
            value={selectedStatus}
            onChange={(values) => setSelectedStatus(values)}
          >
            <Option value={"all"} key={"all"}>
              All status
            </Option>
            {getChoices(others)}
            <OptGroup label="Completed Orders" key="completed">
              {getChoices(optionsCompletedOrders)}
            </OptGroup>
            <OptGroup label="Postponed Orders" key="postponed">
              {getChoices(optionsPostponedOrders)}
            </OptGroup>
            <OptGroup label="Canceled Orders" key="canceled">
              {getChoices(optionsCanceledOrders)}
            </OptGroup>
            <OptGroup label="Failed Orders" key="failed">
              {getChoices(optionsFailedOrders)}
            </OptGroup>
          </Select>
        </Row>
      </div>
    </Modal>
  );
}

export default RetailerReportsModal;
