import { Spin } from "antd";
import React from "react";

export default function SpinModal({ children }) {
  return (
    <div className="spin-modal">
      <Spin />
      {children}
    </div>
  );
}
