import React from "react";
import { Layout, Row, Col, Divider } from "antd";
import PaymentResult from "./components/paymentResult";
import useHooks from "./hooks/useHooks";

import { ReactComponent as BeeboltFullLogo } from "../../assets/beeboltFullLogo.svg";
import "./styles.less";

const PaymentsResultPage = () => {
  const { paymentResult } = useHooks();

  return (
    <Layout style={{ background: 'white'}}>
      <Layout.Content className="page-container">
        <Row>
          <Col md={8} xs={6}>
            <div className="flex-column">
              <BeeboltFullLogo width="80px" />
            </div>
          </Col>
          <Col md={8} xs={6} className="flex-column">
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col md={8} xs={12}>
            <div className="logo-text-container logo-text">
              Dliv Transport
              <br />
              and Trading
            </div>
          </Col>
        </Row>
        <main className="flex-column payment-result-container">
          <PaymentResult data={paymentResult} />
        </main>
      </Layout.Content>
    </Layout>
  );
};

export default PaymentsResultPage;
