import { notification } from "antd";
import { useCallback, useEffect, useState } from "react";
import { IConfigureJobsInput, IConfigureJobsReturn } from "../types/configureModal.types";
import { ActionTypeValues, IActionType } from "../types/previewActions.types";
import { getJobStatusQuery } from "../../../helpers/formatStatus";
import { allowedMoveToJobStatus } from "src/constants/jobsStatus";
import { IPaginationState } from "../types/ordersTable.types";
const useConfigureJobs: (props: IConfigureJobsInput) => IConfigureJobsReturn = ({
  selectedJobId,
  configureJobDeliveryJob,
  changeOrderPositionDeliveryJob,
  changePickupPoistionDeliveryJob,
  allJobsIds,
  setSelectedOrdersDeliveryJob,
  setPreviewOrdersDeliveryJob,
  moveOrdersDeliveryJob,
  addOrdersDeliveryJob,
  removeOrdersDeliveryJob,
  resetConfigureJobDeliveryJob,
  fetchAdminOrders,
  getJobsByStatusAdminOrder,
  getOneDeliveryJob,
  getPolylineDeliveryJob,
  freeOrders,
  mainJob,
  fetchDeliveryJobs,
  getRoutesDeliveryJob,
}) => {
  const [showMaps, setShowMaps] = useState<{ isOn: boolean; key: number }>({ isOn: true, key: 1 });
  const [currentPreviewAction, setCurrentPreviewAction] = useState<IActionType>({ isOn: false });
  const [selectedIdsToAdd, setSelectedIdsToAdd] = useState<number[]>([]);
  const [loadingJobs, setLoadingJobs] = useState<boolean>(false);
  const ordersStatuses = ["new", "postponed", "postponed_after_pickup"].join("&status=");

  const fetchJobsByStatus = async (status: string[]) => {
    try {
      await getJobsByStatusAdminOrder({}, { query: getJobStatusQuery(status) });
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An error has occured while fetch Incomplete Jobs",
      });
    }
  };

  const getJobs = useCallback(() => {
    fetchJobsByStatus(allowedMoveToJobStatus);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedJobId) {
      fetchMainJob(selectedJobId);
      getJobs();
    }
    // eslint-disable-next-line
  }, [selectedJobId]);

  const fetchMainJob = async (id: number) => {
    try {
      setLoadingJobs(true);
      await fetchDeliveryJobs({}, { query: { withError: false } });

      await getRoutesDeliveryJob({ jobId: id });
      await configureJobDeliveryJob({ jobId: id, isMain: true });
    } catch {
      notification.error({
        message: "Error",
        description: "A error has occured while fetching the job details !",
      });
    } finally {
      setLoadingJobs(false);
    }
  };

  const updatePreviewItems = useCallback(() => {
    if (selectedIdsToAdd && currentPreviewAction.isOn && currentPreviewAction.type === "add") {
      let previewItems = freeOrders.filter((order) => selectedIdsToAdd.includes(order.id));
      setPreviewOrdersDeliveryJob({ isMain: true, previewItems });
      setCurrentPreviewAction({ ...currentPreviewAction, orderIds: selectedIdsToAdd });
    }
    // eslint-disable-next-line
  }, [freeOrders, selectedIdsToAdd, currentPreviewAction]);

  useEffect(() => {
    updatePreviewItems();
    // eslint-disable-next-line
  }, [selectedIdsToAdd]);
  // const onCloseModalHandler = () => {
  //   setConfigureModalOptions({ open: false, JobId: undefined });
  // };

  const handleChooseJob = async (id: number) => {
    //await getOneDeliveryJob({ id: id });
    await getRoutesDeliveryJob({ jobId: Number(id) });
    configureJobDeliveryJob({ jobId: Number(id), isMain: false });
  };

  const availableJobs = (selectedId: number) => allJobsIds?.filter((job) => job.jobId !== selectedId);

  const onSelectOrders = (keys: any[], jobId: number, isMain: boolean = false, isAllOrders: boolean = false) => {
    setSelectedOrdersDeliveryJob({ id: jobId, ids: keys, isMain });

    if (currentPreviewAction.isOn) {
      // update preview data
      if (currentPreviewAction.type === "move") setPreviewOrdersDeliveryJob({ isMain: !isMain });
      setCurrentPreviewAction({
        ...currentPreviewAction,
        orderIds: keys,
        isAllOrders,
      });
    }
  };

  const cleanUpJobs = async (jobId: number, otherJobId?: number) => {
    setLoadingJobs(true);
    try {
      const isAllOrdersMoved = currentPreviewAction.isAllOrders ?? false;
      // hide preview , allow other actions
      setCurrentPreviewAction({ isOn: false, jobId: undefined, type: undefined, isAllOrders: undefined });

      // get jobs again
      if (!isAllOrdersMoved) {
        await getRoutesDeliveryJob({ jobId });
        await getPolylineDeliveryJob({ jobId });
      }
      //await getOneDeliveryJob({ id: jobId });

      // we fetch second job if there is a jobID and if it is not empty
      if (otherJobId && !isAllOrdersMoved) {
        await getRoutesDeliveryJob({ jobId: otherJobId });
        await getPolylineDeliveryJob({ jobId: otherJobId });
      }

      //await getOneDeliveryJob({ id: otherJobId });

      if (isAllOrdersMoved) {
        // if all orders are moved or removed , job will be deleted
        // hence we nee to refresh all jobs list
        fetchJobsByStatus(allowedMoveToJobStatus);
        const { body } = await fetchDeliveryJobs();
        // get ids of new jobs list
        const ids = body.map((job: any) => job.id);

        // if we moved  or deleted all orders from one job, it means the job will be deleted
        // so we only fetch data ( jobRoutes / orders and polyline ) for remaining job
        if (ids.includes(jobId)) {
          await getRoutesDeliveryJob({ jobId });
          await getPolylineDeliveryJob({ jobId });
        } else if (ids.includes(otherJobId)) {
          await getRoutesDeliveryJob({ jobId: otherJobId });
          await getPolylineDeliveryJob({ jobId: otherJobId });
        }
      }

      // update data for selected jobs
      resetConfigureJobDeliveryJob({});
      setSelectedIdsToAdd([]);
    } catch (e) {
      notification.error({
        message: "Error",
        description: "A error has occured while fetching the updates !",
      });

      // update data for selected jobs
      resetConfigureJobDeliveryJob({});
      setSelectedIdsToAdd([]);
    } finally {
      setLoadingJobs(false);
      setShowMaps({ ...showMaps, key: showMaps.key + 1 });
    }
  };
  const moveOrders = async (
    jobId: number,
    orderId: number[],
    destinationJobId: number,
    isMain: boolean = true,
    isAllOrders: boolean = false,
  ) => {
    setCurrentPreviewAction({
      isOn: true,
      jobId: destinationJobId,
      type: ActionTypeValues.MOVE,
      originalJobId: jobId,
      orderIds: orderId,
      isAllOrders: isAllOrders,
    });
    setPreviewOrdersDeliveryJob({ isMain: !isMain });
  };

  const changePickupOrder = async (pointsIds: number[]) => {
    try {
      await changePickupPoistionDeliveryJob(
        { jobId: selectedJobId },
        { query: { save: true }, body: { pointsOrder: pointsIds } },
      );
      notification.success({
        message: "Order of pick up points has been updated.",
      });
      await getOneDeliveryJob({ id: selectedJobId });
      await getRoutesDeliveryJob({ jobId: selectedJobId });
    } catch (e: any) {
      notification.error({
        message: e?.body?.message || "Can't change order of pick up points.",
      });
    } finally {
      resetConfigureJobDeliveryJob({});
      setShowMaps({ ...showMaps, key: showMaps.key + 1 });
    }
  };
  const addOrders = async (jobId: number, pagination: IPaginationState) => {
    // 1. fetch list of available orders
    await fetchFreeOrders(pagination);

    // 2. set preview mode
    if (!currentPreviewAction.isOn) {
      setCurrentPreviewAction({
        isOn: true,
        type: ActionTypeValues.ADD,
        jobId: jobId,
        originalJobId: jobId,
      });
    }
  };

  const deleteOrders = async (jobId: number, orders: number[], isAllOrders: boolean = false) => {
    // 2. set preview mode
    setCurrentPreviewAction({
      isOn: true,
      type: ActionTypeValues.REMOVE,
      jobId: jobId,
      originalJobId: jobId,
      orderIds: orders,
      isAllOrders,
    });
  };

  const handleReorderJobOrders = async (pointsOrder: number[], deliveryIds: number[], jobId: number) => {
    try {
      await changeOrderPositionDeliveryJob(
        { jobId: jobId },
        { query: { save: true }, body: { deliveryIds, pointsOrder } },
      );

      notification.success({
        message: "Order of delivery points has been updated.",
      });
      //await getOneDeliveryJob({ id: jobId });
      await getRoutesDeliveryJob({ jobId: jobId });
      await getPolylineDeliveryJob({ jobId });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to change order of order in job",
      });
    } finally {
      resetConfigureJobDeliveryJob({});
      setShowMaps({ ...showMaps, key: showMaps.key + 1 });
    }
  };
  //
  const fetchFreeOrders = async (pagination: IPaginationState) => {
    const { restrictedId, restrictedType } = mainJob;
    const restrictedQuery = restrictedId && restrictedType ? { restrictedId, restrictedType } : {};

    await fetchAdminOrders(
      {},
      {
        query: {
          fetchAll: true,
          withoutJobs: true,
          status: ordersStatuses,
          canBeAssigned: true,
          ...restrictedQuery,
          page: pagination.current,
          limit: pagination.pageSize,
        },
      },
    );
  };

  const confirmMoveOrders = async (jobId: number, orderId: number[], destinationJobId: number) => {
    try {
      await moveOrdersDeliveryJob({ jobId: destinationJobId, ids: orderId });

      notification.success({
        message: `Selected orders were successfully moved to Job # ${destinationJobId}`,
      });
    } catch (e) {
      notification.error({
        message: "Moving Orders to new job has failed !",
      });
    } finally {
      await cleanUpJobs(destinationJobId, jobId);
    }
  };

  const confirmAddOrders = async (jobId: number, orderId: number[], destinationJobId: number) => {
    try {
      await addOrdersDeliveryJob({ jobId: destinationJobId, ordersIds: orderId });

      notification.success({
        message: `Selected orders were successfully added to Job # ${destinationJobId}`,
      });
    } catch (e: any) {
      notification.error({
        message: e.message ?? "Moving Orders to new job has failed !",
      });
    } finally {
      await cleanUpJobs(destinationJobId);
    }
  };

  const confirmRemoveOrders = async (jobId: number, ordersIds: number[]) => {
    try {
      await removeOrdersDeliveryJob({ jobId, ordersIds });
      notification.success({
        message: `Selected orders were successfully deleted from Job # ${jobId}`,
      });
    } catch (e) {
      notification.error({
        message: "Deleting these orders  has failed !",
      });
    } finally {
      await cleanUpJobs(jobId);
    }
  };
  const cancelCurrentAction = () => {
    setCurrentPreviewAction({ isOn: false, jobId: undefined, type: undefined });
    setPreviewOrdersDeliveryJob({ isMain: true, cancel: true });
    setPreviewOrdersDeliveryJob({ isMain: false, cancel: true });
  };

  const handleSelectOrdersToAdd = (keys: any[]) => {
    setSelectedIdsToAdd(keys);
  };

  return {
    handleChooseJob,
    loadingJobs,
    availableJobs,
    showMaps,
    setShowMaps,
    onSelectOrders,
    moveOrders,
    confirmRemoveOrders,
    deleteOrders,
    currentPreviewAction,
    confirmMoveOrders,
    confirmAddOrders,

    cancelCurrentAction,
    addOrders,
    selectedIdsToAdd,
    handleSelectOrdersToAdd,
    handleReorderJobOrders,
    changePickupOrder,
  };
};

export default useConfigureJobs;
