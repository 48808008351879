import { notification } from "antd";
import { Moment } from "moment";
import { useState } from "react";
import { ItableHandlerInput, ItableHandlerReturn } from "./types";

const useTableHandler: (props: ItableHandlerInput) => ItableHandlerReturn = ({
  intl,
  onCancelOrder,
  onDeleteOrder,
  onDeleteOrders,
  onPostponeOrder,
  onCreateReturn,
  onCompleteOrder,
  onRemoveLocation,
  onSaveOrders,

  restoreAdminOrder,
  restoreFailedAdminOrder,
  returnToShipper,
  fetchAccordingToTab,
  fetchOrderHistory,
  onDeleteLocal,
  onSetSelectedTab,
  onSetDownloadedOrders,
  onSaveOrdersDone,
  ordersList,
  retailer,
  old,
  createTicket,
  onCancelCompletedOrder,
  retailerId,
}) => {
  const [openModal, setOpenModal] = useState({ id: "", type: "", open: false });
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);

  const handleModalSubmit = async (
    type: string,
    id: number,
    openForAll: boolean,
    pointId: number,
    isAdminOrders: boolean,
    templateId: number | string,
    otherParams?: {
      postponeDate: Moment;
      ticket?: {
        label: string;
        description: string;
      };
    },
  ) => {
    switch (type) {
      case "cancel":
        if (onCancelOrder) {
          try {
            await onCancelOrder({ orderId: id });
            await fetchAccordingToTab();
          } catch (error: any) {
            notification.error({
              message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
              description:
                error?.body?.message ||
                `${intl.formatMessage({ id: "error.cancelOrder", defaultMessage: "Failed to cancel order." })}`,
            });
          }
        }
        break;
      case "postpone":
        if (onPostponeOrder) {
          try {
            if (!otherParams?.postponeDate) {
              notification.warn({ message: "The postpone date is not set !" });
              return;
            }
            await onPostponeOrder({ orderId: id, postponedTime: otherParams?.postponeDate.format("DD.MM.YYYY") });
            await fetchAccordingToTab();
          } catch (error: any) {
            notification.error({
              message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
              description:
                error?.body?.message ||
                `${intl.formatMessage({ id: "error.postponeOrder", defaultMessage: "Failed to postpone order." })}`,
            });
          }
        }
        break;

      case "returnToShipper":
        if (returnToShipper) {
          await returnToShipper({ orderId: id });
          await fetchAccordingToTab();
        }
        break;
      case "journeyView":
        await fetchOrderHistory({ orderId: id });
        setHistoryDrawerOpen(true);
        break;
      case "createTicket":
        createTicket({
          orderId: id,
          labels: [otherParams?.ticket?.label],
          description: otherParams?.ticket?.description,
        });
        break;
      case "removeLocation":
        try {
          await onRemoveLocation({ orderId: id });
          await fetchAccordingToTab();
          notification.success({
            message: `${intl.formatMessage({
              id: "success.removeLocation",
              defaultMessage: "Location removed successfully!",
            })}`,
          });
        } catch (error: any) {
          notification.error({
            message: "Error",
            description:
              error?.body?.message ||
              `${intl.formatMessage({ id: "error.removeLocation", defaultMessage: "Failed to remove location." })}`,
          });
        }
        break;
      case "completeOrder":
        try {
          await onCompleteOrder({ orderId: id });
          await fetchAccordingToTab();
          notification.success({
            message: `${intl.formatMessage({
              id: "success.completeOrder",
              defaultMessage: "Order completed successfully!",
            })}`,
          });
        } catch (error: any) {
          notification.error({
            message: "Error",
            description:
              error?.body?.message ||
              `${intl.formatMessage({ id: "error.completeOrder", defaultMessage: "Failed to complete order." })}`,
          });
        }
        break;
      case "cancelCompleted":
        try {
          await onCancelCompletedOrder({ orderId: id });
          await fetchAccordingToTab();
          notification.success({
            message: `${intl.formatMessage({
              id: "success.cancelOrder",
              defaultMessage: "Order was canceled successfully!",
            })}`,
          });
        } catch (error: any) {
          notification.error({
            message: "Error",
            description:
              error?.body?.message ||
              `${intl.formatMessage({ id: "error.cancelOrder", defaultMessage: "Failed to cancel this order." })}`,
          });
        }
        break;
      case "restore":
        try {
          await restoreAdminOrder({ orderId: id });
          await fetchAccordingToTab();
          notification.success({
            message: `${intl.formatMessage({
              id: "success.restoreOrder",
              defaultMessage: "Order restored successfully!",
            })}`,
          });
        } catch (error: any) {
          notification.error({
            message: "Error",
            description:
              error?.body?.message ||
              `${intl.formatMessage({ id: "error.restoreOrder", defaultMessage: "Failed to restore order." })}`,
          });
        }
        break;
      case "restoreFailed":
        try {
          await restoreFailedAdminOrder({ orderId: id });
          await fetchAccordingToTab();
          notification.success({
            message: `${intl.formatMessage({
              id: "success.restoreOrder",
              defaultMessage: "Order restored successfully!",
            })}`,
          });
        } catch (error: any) {
          notification.error({
            message: "Error",
            description:
              error?.body?.message ||
              `${intl.formatMessage({ id: "error.restoreOrder", defaultMessage: "Failed to restore order." })}`,
          });
        }
        break;
      case "delete":
        try {
          const res = await onDeleteOrder({ orderId: id });
          if (res.status === "resolved") {
            notification.success({
              message: `${intl.formatMessage({
                id: "success.deletedOrder",
                defaultMessage: "Order deleted successfully",
              })}`,
            });
          } else {
            notification.error({
              message: `${intl.formatMessage({ id: "error.deletionOrder", defaultMessage: "Order deletion failed" })}`,
            });
          }
          await fetchAccordingToTab();
        } catch (error: any) {
          notification.error({
            message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
            description:
              error?.body?.message ||
              `${intl.formatMessage({ id: "error.deleteOrder", defaultMessage: "Failed to delete order." })}`,
          });
        }
        break;
      case "deleteMany":
        if (old) {
          try {
            await onDeleteOrders({ ordersIds: selectedOrders });
            await fetchAccordingToTab();
            setSelectedOrders([]);
          } catch (error: any) {
            notification.error({
              message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
              description:
                error?.body?.message ||
                `${intl.formatMessage({ id: "error.deleteOrders", defaultMessage: "Failed to delete orders." })}`,
            });
          }
        } else {
          onDeleteLocal(...selectedOrders);
          setSelectedOrders([]);
        }
        break;
      case "saveOrders":
        // change point
        onSaveOrders(
          isAdminOrders
            ? ordersList
            : ordersList.map((order: any) => ({
                ...order,
                pickupPoint: { id: pointId },
                ...(templateId !== "None selected" &&
                  retailer?.isCustomMessagingTemplatesAllowed && { messagingTemplateGroupId: templateId }),
              })),
          undefined,
          templateId,
          retailerId,
        );
        onSetSelectedTab(1);
        onSetDownloadedOrders([]);
        onSaveOrdersDone();
        notification.success({
          message: `${intl.formatMessage({
            id: "success.uploadedOrders",
            defaultMessage:
              "Your orders have been uploaded successfully to Dliv, you can track the status of your order on the current tab of this page",
          })}`,
        });
        break;
      case "return":
        try {
          const query = typeof openForAll === "boolean" ? { openForAll } : {};
          await onCreateReturn({ orderId: id }, { query });
          await fetchAccordingToTab();
          notification.success({
            message: `${intl.formatMessage({
              id: "success.returnCreation",
              defaultMessage: "Return successfully created!",
            })}`,
          });
        } catch (error: any) {
          notification.error({
            message: "Error",
            description:
              error?.body?.message ||
              `${intl.formatMessage({ id: "error.returnCreation", defaultMessage: "Failed to create return." })}`,
          });
        }
        break;
      default:
        notification.warn({ message: "Unknown action, No requests are performed !" });
    }

    setOpenModal((prevState) => ({
      ...prevState,
      open: false,
    }));
    setTimeout(() => {
      setOpenModal((prevState) => ({
        ...prevState,
        id: "",
        type: "",
      }));
    }, 500);
  };

  const handleDeleteManyOrders = (selectedOrders: any) => {
    setOpenModal({ type: "deleteMany", id: selectedOrders, open: true });
  };
  return {
    handleDeleteManyOrders,
    handleModalSubmit,
    setOpenModal,
    openModal,
    selectedOrders,
    setSelectedOrders,
    historyDrawerOpen,
    setHistoryDrawerOpen,
  };
};

export default useTableHandler;
