import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import getDefaultCenterFromDegrees from "../../helpers/getDefaultCenterFromDegrees";
import { useFilters, usePagination, useStatisticsTable } from "./hooks";
import { DesktopView } from "./DesktopView";
import { MobileView } from "./MobileView";

import "./driversStats.less";

const searchFilter = [
  { text: "Driver Name", value: "driverName" },
  { text: "Tracking ID", value: "trackingId" },
  { text: "Job ID", value: "jobId" },
  { text: "Customer Phone Number", value: "customerPhoneNumber" },
  { text: "Driver Rating", value: "driverRating" },
  { text: "Order Rating", value: "orderRating" },
];

const DriversStats = () => {
  const statistics = useSelector(({ statistics }) => statistics.items);
  const isFetchingStatistics = useSelector(({ statistics }) => statistics.isFetching);
  const pagination = useSelector(({ statistics }) => statistics.pagination);
  const isMobile = useSelector(({ admin }) => admin.isMobile);

  const { data, columns, expandedRowRender, selectedOrder, setSelectedOrder, handleCloseDrawer } =
    useStatisticsTable(statistics);
  const { driversStatisticsPagination, onChangePagination, getDriversStatisticsQueryParams } = usePagination({
    pagination,
    isMobile,
  });
  const {
    dates,
    setInputValue,
    handleSetDates,
    disabledTomorrowAndFuture,
    selectedFilter,
    changeFilter,
    onRatingChange,
  } = useFilters(getDriversStatisticsQueryParams);

  const centerCoordinates = useMemo(() => {
    const coordinatesArray = [
      [Number(selectedOrder?.deliveryPoint?.lat), Number(selectedOrder?.deliveryPoint?.long)],
      [Number(selectedOrder?.pickupPoint?.lat), Number(selectedOrder?.pickupPoint?.long)],
    ];
    return getDefaultCenterFromDegrees(coordinatesArray);
  }, [selectedOrder]);

  return (
    <>
      {isMobile ? (
        <MobileView
          loading={isFetchingStatistics}
          data={data}
          dates={dates}
          disabledDate={disabledTomorrowAndFuture}
          searchFilter={searchFilter}
          selectedFilterValue={selectedFilter}
          paginationConfig={driversStatisticsPagination}
          centerCoordinates={centerCoordinates}
          setSelectedOrder={setSelectedOrder}
          onChangeSearchFilter={changeFilter}
          onRatingChange={onRatingChange}
          onSearchDriver={setInputValue}
          onDatesChange={handleSetDates}
          onChangePagination={onChangePagination}
        />
      ) : (
        <DesktopView
          data={data}
          dates={dates}
          columns={columns}
          searchFilter={searchFilter}
          isFetchingStatistics={isFetchingStatistics}
          selectedOrder={selectedOrder}
          selectedFilter={selectedFilter}
          expandedRowRender={expandedRowRender}
          driversStatisticsPagination={driversStatisticsPagination}
          disabledTomorrowAndFuture={disabledTomorrowAndFuture}
          centerCoordinates={centerCoordinates}
          onChangePagination={onChangePagination}
          changeFilter={changeFilter}
          onRatingChange={onRatingChange}
          setInputValue={setInputValue}
          handleSetDates={handleSetDates}
          handleCloseDrawer={handleCloseDrawer}
        />
      )}
    </>
  );
};

export default DriversStats;
