import React, { FC, useState } from "react";
import { Form, Button, Select, InputNumber } from "antd";
import { CreateSettingFormBody, CreateSettingProps, PriceSettingTenant } from "../types";
import "../styles/components.less";

const { Option } = Select;

const CreateSetting: FC<CreateSettingProps> = ({ handleAddSetting, tenants }) => {
  const [form] = Form.useForm();
  const [selectedTenant, setSelectedTenant] = useState<PriceSettingTenant>();

  const onFinish = async (values: CreateSettingFormBody) => {
    handleAddSetting(values);
    form.resetFields();
  };

  const onChangeTenant = (tenant: string) => {
    const tenantSetting = tenants?.find((tenantItem: PriceSettingTenant) => tenantItem.tenant === tenant);
    setSelectedTenant(tenantSetting);
  };

  return (
    <>
      <Form
        style={{ width: "100%" }}
        form={form}
        layout="vertical"
        name="register"
        onFinish={onFinish}
        initialValues={{}}
        scrollToFirstError
      >
        <Form.Item
          name="tenant"
          label="Tenant"
          rules={[
            {
              required: true,
              message: "Please input tenant!",
            },
          ]}
        >
          <Select placeholder="select tenant" onChange={onChangeTenant}>
            {tenants.map((e: PriceSettingTenant) => (
              <Option key={e.tenant} value={e.tenant}>
                {e.tenant}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="initialDeliveryFee"
          label="Initial Delivery Fee"
          rules={[
            {
              required: true,
              message: "Please input your initial delivery fee!",
            },
            {
              type: "number",
              min: 0,
              message: "Please input a valid amount!",
            },
          ]}
          hasFeedback
        >
          <InputNumber
            className="number-input-style"
            placeholder="Enter Initial Fee"
            addonAfter={selectedTenant?.currency || "$"}
          />
        </Form.Item>

        <Form.Item
          name="maxKmLimit"
          label="Max Km Limit"
          rules={[
            {
              required: true,
              message: "Please input Max Km limit!",
            },
            {
              type: "number",
              min: 0,
              message: "Please input a valid number!",
            },
          ]}
          hasFeedback
        >
          <InputNumber className="number-input-style" placeholder="Enter max km limit" addonAfter="Km" />
        </Form.Item>

        <Form.Item
          name="additionalCostPerKm"
          label="Additional Cost Per Km"
          rules={[
            {
              required: true,
              message: "Please input additional cost per Km!",
            },
            {
              type: "number",
              min: 0,
              message: "Please input a valid amount!",
            },
          ]}
          hasFeedback
        >
          <InputNumber
            className="number-input-style"
            placeholder="Enter Additional Cost"
            addonAfter={selectedTenant?.currency || "$"}
          />
        </Form.Item>
        <Form.Item>
          <Button className="save-setting-btn" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CreateSetting;
