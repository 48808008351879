import React from "react";

const DriverVideoCard = ({ videoUrl }) => (
  <>
    <h3 style={{ fontWeight: "bold" }}>Driver video card:</h3>
    <video width="550" height="350" controls className="videoStyle">
      <source src={videoUrl} />
    </video>
  </>
);

export default DriverVideoCard;
