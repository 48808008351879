import React from "react";
import { MobileSelect } from "../MobileSelect";

import "./styles.less";

export const MobileSelectWrapper = (props) => {
  return (
    <>
      <div className="mobile-select-wrapper__label">{props.label}</div>
      <MobileSelect {...props} />
    </>
  );
};
