import { Col, List, Row } from "antd";
import React, { FC, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TrackingShipmentMap, { ProviderType } from "src/components/TrackingShipmentMap";
import { RootState } from "src/redux";
import { CustomCard } from "src/utils/common";
import { actions as tasksActions } from "../../redux/resources/tasks";
import { IFleetTracking } from "./types";
import { useHooks } from "./useHook";
import "./styles.less";
import { JobList } from "./components/JobsList";
import TaskEntity from "src/entities/task.entity";

const FleetTrackingPage: FC<IFleetTracking & any> = ({ fetchJobs, jobs, getTasks }) => {
  // state
  const [selectedJob, setSelectedJob] = useState<{ job: any; task : any; type: ProviderType }>({
    task : undefined,
    job: undefined,
    type: ProviderType.VEHICLE_TRACKER,
  });

  // hooks
  const { isJobsFetching, getJobTasks } = useHooks({ fetchJobs, getTasks });

  // handlers
  const onJobSelected = (job: any, type: ProviderType, task : TaskEntity) => {
    setSelectedJob({ job: job, type: type, task : task });
    getJobTasks(job);
  };
  return (
    <div className="page-wrapper">
      <CustomCard title="Fleet Tracking">
        <Row justify="space-between">
          <Col span={9}>
            <JobList isLoading={isJobsFetching} jobs={jobs} selectedJob={selectedJob} onJobSelected={onJobSelected} />
          </Col>
          <Col span={14}>
            {selectedJob.job && <TrackingShipmentMap job={selectedJob.job} providerType={selectedJob.type} task={selectedJob.task}/>}
          </Col>
        </Row>
      </CustomCard>
    </div>
  );
};

const MSTP = (state: RootState) => {
  return {
    jobs: state.tasks.jobs,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(tasksActions, dispatch),
}))(FleetTrackingPage);
