import React from "react";
import { Divider } from "antd";
import { FormattedMessage } from "react-intl";

import CustomDivider from "../../../../../components/Divider";

const Header = () => {
  return (
    <>
      <h2 className="pageTitle">
        <FormattedMessage id="page.userManagment.title" defaultMessage="User Managment" />
      </h2>
      <CustomDivider />
      <Divider />
    </>
  );
};

export default Header;
