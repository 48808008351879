import React, { FC } from "react";

import line from "../../assets/line.png";

const Divider: FC<{ classname?: string }> = ({ classname }) => (
  <div>
    <img src={line} alt="Divider" width="80px" className={classname} />
  </div>
);

export default Divider;
