import { notification } from "antd";
import { AsyncActionCreator } from "redux-rest-resource";
import IRetailer from "src/entities/retailer.entity";
import { structureErrorResponse } from "src/helpers/handleErrorResponse";

const useRetailerDetail = (
  deleteOneAdminRetailers: AsyncActionCreator,
  updateStatusAdminRetailers: AsyncActionCreator,
  updateOneAdminRetailers: AsyncActionCreator,
  fetchOneAdminRetailers: AsyncActionCreator,
) => {
  const deleteRetailer = async (retailerId: number) => {
    try {
      await deleteOneAdminRetailers({ retailerId }, {});
      notification.success({
        message: "Success",
        description: "The retailer has been successfully deleted",
      });
    } catch (error: any) {
      const description = error?.body?.message
        ? structureErrorResponse(error?.body?.message)
        : "Unexpected error has occured while deleting this retailer !";
      notification.error({
        message: "Error",
        description: description,
      });
    }
  };

  const updateRetailerStatus = async (retailerId: number, status: string) => {
    try {
      await updateStatusAdminRetailers({ retailerId }, { body: { status } });
      notification.success({
        message: "Success",
        description: `The retailer's status has been successfully changed to ${status}`,
      });
    } catch (error: any) {
      const description = error?.body?.message
        ? structureErrorResponse(error?.body?.message)
        : "Unexpected error has occured while updating status of this retailer !";

      notification.error({
        message: "Error",
        description: description,
      });
    }
  };

  const updateRetailer = async (retailerId: number, values: IRetailer) => {
    try {
      await updateOneAdminRetailers({ retailerId }, { body: { ...values } });
      notification.success({
        message: "Success",
        description: `The retailer's profile has been successfully updated`,
      });
    } catch (error: any) {
      const description = error?.body?.message
        ? structureErrorResponse(error?.body?.message)
        : "Unexpected error has occured while updating profile of current retailer !";

      notification.error({
        message: "Error",
        description: description,
      });
    }
  };

  const fetchRetailerProfile = async (retailerId: number) => {
    try {
      await fetchOneAdminRetailers({ retailerId });
    } catch (error: any) {
      console.log(error);
      const description = error?.body?.message
        ? structureErrorResponse(error?.body?.message)
        : "Unexpected error has occured while fetching profile of current retailer !";

      notification.error({
        message: "Error",
        description: description,
      });
    }
  };
  return {
    deleteRetailer,
    updateRetailer,
    updateRetailerStatus,
    fetchRetailerProfile,
  };
};

export default useRetailerDetail;
