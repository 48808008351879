import React, { FC } from "react";
import { bindActionCreators } from "redux";
import { actions as retailerTicketActions } from "../../redux/resources/retailerTicket";
import { actions as adminTicketActions } from "../../redux/resources/adminTicket";
import { actions as adminOrderActions } from "../../redux/resources/adminOrders";
import { actions as retailerOrderActions } from "../../redux/resources/retailerOrders";
import { actions as deliveryJobActions } from "../../redux/resources/deliveryJobs";
import { actions as retailerJobActions } from "../../redux/resources/retailerJob";
import { actions as deliveryJobDetailsActions } from "../../redux/resources/deliveryJobDetails";
import { connect } from "react-redux";
import CreateTicketModal from "./CreateTicketModal";
import TicketDetailsModal from "./TicketDetailsModal";
import { userRole } from "src/helpers/authStorage";
import { ICurrentViewActions, ITicketMSTP, TicketViewEnum, UsersRolesEnum } from "./type";
import "./ticket.less";

const computeCurrentViewActions = (props: any): ICurrentViewActions => {
  let currentViewActions;

  if (userRole === UsersRolesEnum.RETAILER) {
    currentViewActions = {
      updateOrderTicketState: props.updateTicketStateRetailerOrder,
      updateOrderNewTicketState: props.updateNewTicketStateRetailerOrder,
      getMessages: props.getMessagesRetailerTicket,
      sendMessage: props.sendMessageRetailerTicket,
      updateTicket: props.updateRetailerTicket,
      createTicket: props.createRetailerTicket,
    };

    switch (props.currentView) {
      case TicketViewEnum.JOBS:
        return (currentViewActions = {
          ...currentViewActions,
          updateOrderTicketState: props.updateTicketStateRetailerJob,
          updateOrderNewTicketState: props.updateNewTicketStateRetailerJob,
        });
      case TicketViewEnum.CONFIGURE_WITH_JOB:
        return (currentViewActions = {
          ...currentViewActions,
          updateOrderTicketState: props.updateConfigJobTicketStateRetailerJob,
          updateOrderNewTicketState: props.updateConfigJobNewTicketStateRetailerJob,
        });
      default:
        return currentViewActions;
    }
  } else {
    currentViewActions = {
      updateOrderTicketState: props.updateTicketStateAdminOrder,
      updateOrderNewTicketState: props.updateNewTicketStateAdminOrder,
      getMessages: props.getMessagesAdminTicket,
      sendMessage: props.sendMessageAdminTicket,
      updateTicket: props.updateAdminTicket,
      createTicket: props.createAdminTicket,
    };

    switch (props.currentView) {
      case TicketViewEnum.JOB_DETAILS:
        return (currentViewActions = {
          ...currentViewActions,
          updateOrderTicketState: props.updateTicketStateDeliveryJobDetails,
          updateOrderNewTicketState: props.updateNewTicketStateDeliveryJobDetails,
        });
      case TicketViewEnum.JOBS:
        return (currentViewActions = {
          ...currentViewActions,
          updateOrderTicketState: props.updateTicketStateDeliveryJob,
          updateOrderNewTicketState: props.updateNewTicketStateDeliveryJob,
        });
      case TicketViewEnum.CONFIGURE_WITH_JOB:
        return (currentViewActions = {
          ...currentViewActions,
          updateOrderTicketState: props.updateConfigJobTicketStateDeliveryJob,
          updateOrderNewTicketState: props.updateConfigJobNewTicketStateDeliveryJob,
        });
      default:
        return currentViewActions;
    }
  }
};

const Ticket: FC<any> = (props) => {
  const { orderId, jobId = null, ticketDetails, users, currentView, buttonType } = props;
  const { updateOrderTicketState, updateOrderNewTicketState, getMessages, sendMessage, updateTicket, createTicket } =
    computeCurrentViewActions(props);

  return ticketDetails ? (
    <TicketDetailsModal
      buttonType={buttonType}
      currentView={currentView}
      orderId={orderId}
      jobId={jobId}
      users={users}
      currentRole={userRole}
      ticketDetails={ticketDetails}
      getMessages={getMessages}
      sendMessage={sendMessage}
      updateTicket={updateTicket}
      updateOrderTicketState={updateOrderTicketState}
    />
  ) : (
    <CreateTicketModal
      buttonType={buttonType}
      orderId={orderId}
      jobId={jobId}
      createTicket={createTicket}
      updateOrderNewTicketState={updateOrderNewTicketState}
    />
  );
};

export default connect(
  (state: any, ownProps: ITicketMSTP) => ({
    buttonType: ownProps.buttonType,
    currentView: ownProps.currentView,
    jobId: ownProps.jobId,
    orderId: ownProps.orderId,
    ticketDetails: ownProps.ticketDetails,
    users: ownProps.users,
  }),
  (dispatch) => ({
    ...bindActionCreators(deliveryJobActions, dispatch),
    ...bindActionCreators(retailerJobActions, dispatch),
    ...bindActionCreators(adminTicketActions, dispatch),
    ...bindActionCreators(adminOrderActions, dispatch),
    ...bindActionCreators(retailerTicketActions, dispatch),
    ...bindActionCreators(retailerOrderActions, dispatch),
    ...bindActionCreators(deliveryJobDetailsActions, dispatch),
  }),
)(Ticket);
