import React, { FC } from "react";
import Retailer from "../Retailer";
import CustomerDetails from "../CustomerDetails";
import OrderAdditionalInfo from "../OrderAdditionalInformation";
import { OrderGoogleMap } from "../../../../OrderGoogleMap";
import { IJobOrderMapProps } from "../../../../../types/JobManagement";

const OrderMap: FC<IJobOrderMapProps> = ({
  orderInfo,
  centerCoordinates,
  updateOrderCash,
  setExpandedRowKeys,
  selectedJobId,
}) => (
  <div style={{ height: "500px", width: "100%" }}>
    {orderInfo?.deliveryPoint && orderInfo?.pickupPoint && (
      <OrderGoogleMap orderInfo={orderInfo} centerCoordinates={centerCoordinates} />
    )}
    {orderInfo && (
      <>
        <Retailer orderInfo={orderInfo} />
        <CustomerDetails
          orderInfo={orderInfo}
          updateOrderCash={updateOrderCash}
          setExpandedRowKeys={setExpandedRowKeys}
          selectedJobId={selectedJobId}
        />
        {orderInfo.additionalInfo && !!Object.keys(orderInfo.additionalInfo).length && (
          <OrderAdditionalInfo additionalInfo={orderInfo.additionalInfo} />
        )}
      </>
    )}
  </div>
);

export default OrderMap;
