import React from "react";
import { Table } from "antd";

import { getStatusBage } from "../../../../helpers/general";

import "../../../JobsManagement/DesktopView/components/JobsList/jobList.less";

const JobsList = ({ deliveryJobs, jobsPaginationConfig }) => {
  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "Customer",
        key: "customerName",
        render: ({ customerName }) => {
          return <p className="orderLink">{customerName}</p>;
        },
      },
      { title: "Tracking ID", dataIndex: "trackingID", key: "trackingID" },
      { title: "Delivery time", dataIndex: "deliveryTime", key: "deliveryTime" },
      {
        title: "Status",
        key: "status",
        render: ({ status }) => {
          return getStatusBage(status.split("_").join(" "));
        },
      },
      { title: "Retailer name", dataIndex: "retailerName", key: "retailerName" },
    ];

    const changedData = record?.orders?.map((order) => {
      return {
        ...order,
        customerName: order.customerInfo.name,
        trackingID: order.trackingId,
        retailerName: order.retailer.name || "Retailer name",
        configure: order.status,
        key: order.code,
      };
    });
    const data = changedData || [];
    return (
      record.orders && (
        <div>
          <Table
            columns={columns}
            className="ordersTablewrapper"
            dataSource={data}
            pagination={false}
            rowKey={(record) => record.trackingID}
          />
        </div>
      )
    );
  };

  const data = deliveryJobs.map((job) => {
    return { ...job, jobId: job.id, key: job.id };
  });

  const columns = [
    {
      title: "Driver",
      dataIndex: "driver",
      key: "driver",
      render: (driver) => {
        return driver?.profile?.firstName ? `${driver?.profile?.firstName} ${driver?.profile?.lastName}` : "Unassigned";
      },
    },
    { title: "ID", dataIndex: "jobId", key: "jobId" },
    { title: "Packages count", dataIndex: "packagesCount", key: "packagesCount" },
    { title: "Pickup points", dataIndex: "pickupPointsCount", key: "pickupPointsCount" },
    { title: "Delivery points", dataIndex: "deliveryPointsCount", key: "deliveryPointsCount" },
    { title: "Overall delivery time", dataIndex: "totalTime", key: "totalTime" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => getStatusBage(status.split("_").join(" ")),
    },
  ];

  return (
    <Table
      columns={columns}
      expandable={{ expandedRowRender }}
      pagination={jobsPaginationConfig}
      dataSource={data}
      rowKey={(record) => record.jobId}
    />
  );
};
export default JobsList;
