import { Breadcrumb, Col, Row, Spin } from "antd";
import React, { FC, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { bindActionCreators } from "redux";
import { mapCenters } from "../../constants/config";
import { getUserTenant, userRole } from "../../helpers/authStorage";
import { RootState } from "../../redux";
import { OrdersTable } from "./components/OrdersTable";
import useConfigureJobs from "./hooks/useConfigureJobs";
import { IConfigureModalInput } from "./types/configureModal.types";

import { actions as deliveryJobActions } from "../../redux/resources/deliveryJobs";
import { actions as adminOrdersActions } from "../../redux/resources/adminOrders";

import { actions as retailerJobActions } from "../../redux/resources/retailerJob";
import { actions as retailerOrdersActions } from "../../redux/resources/retailerOrders";

import { Link, useHistory, useParams } from "react-router-dom";
import { CustomCard } from "../../utils/common";
import "./styles/configureJobsModal.less";
import SingleJobHandler from "./components/SingleJobHandler";
import { Footer } from "antd/lib/layout/layout";
import PreviewOrdersAtion from "./components/PreviewOrdersAction";
import { IOrder } from "src/entities";
import { BASE_ROUTE } from "src/routes";

const JOB_MANAGEMENT_URL = userRole === "retailer" ? "/retailer_cabinet/jobs" : "/admin/jobs";

const JobsConfigurePage: FC<IConfigureModalInput & any> = ({
  // setConfigureModalOptions,
  freeOrders,
  getJobsByStatusAdminOrder,
  freeOrdersPagination,

  // actions
  fetchDeliveryJobs,
  getOneDeliveryJob,
  getPolylineDeliveryJob,
  getRoutesDeliveryJob,
  fetchAdminOrders,
  addOrdersDeliveryJob,
  removeOrdersDeliveryJob,
  moveOrdersDeliveryJob,
  configureJobDeliveryJob,
  setSelectedOrdersDeliveryJob,
  setPreviewOrdersDeliveryJob,
  resetConfigureJobDeliveryJob,
  changeOrderPositionDeliveryJob,
  changePickupPoistionDeliveryJob,
  resetFlagDeliveryJob,
  incompleteJobs,
}) => {
  const { id: jobId } = useParams<{ id?: string }>();
  const history = useHistory();
  const currentTenant = getUserTenant();

  const JobsToConfigure: {
    mainJob: {
      job: any;
      previewOrdersToAdd: IOrder[];
      selectedIds: number[];
    }; //TODO add type job
    compareToJob: {
      job: any;
      previewOrdersToAdd: IOrder[];
      selectedIds: number[];
    }; //TODO add type job
  } = useSelector((state: RootState) => {
    return userRole === "retailer" ? state?.retailerJob?.jobsToConfigure : state?.deliveryJobs?.jobsToConfigure;
  });

  const {
    handleChooseJob,
    availableJobs,
    showMaps,
    onSelectOrders,
    moveOrders,
    cancelCurrentAction,
    confirmMoveOrders,
    confirmAddOrders,
    confirmRemoveOrders,
    deleteOrders,
    currentPreviewAction,
    setShowMaps,
    addOrders,
    selectedIdsToAdd,
    handleSelectOrdersToAdd,
    handleReorderJobOrders,
    loadingJobs,
    changePickupOrder,
  } = useConfigureJobs({
    selectedJobId: Number(jobId),
    configureJobDeliveryJob,
    changePickupPoistionDeliveryJob,
    allJobsIds: incompleteJobs ?? [],
    setSelectedOrdersDeliveryJob,
    moveOrdersDeliveryJob,
    addOrdersDeliveryJob,
    setPreviewOrdersDeliveryJob,
    fetchDeliveryJobs,
    resetConfigureJobDeliveryJob,
    removeOrdersDeliveryJob,
    fetchAdminOrders,
    getJobsByStatusAdminOrder,
    freeOrders,
    getOneDeliveryJob,
    getRoutesDeliveryJob,
    getPolylineDeliveryJob,
    mainJob: JobsToConfigure?.mainJob?.job,
    changeOrderPositionDeliveryJob,
  });

  return (
    <>
      <Spin tip={"Loading jobs updates ..."} spinning={loadingJobs}>
        <div className="jobs_wrapper">
          <Row>
            <Breadcrumb style={{ margin: "10px 15px 20px" }}>
              <Breadcrumb.Item>
                <Link to={`/${BASE_ROUTE}/jobs`}>Job Management</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Configure Jobs</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
          <Row>
            <Col lg={12} sm={24} className="jobs_wrapper--main">
              {JobsToConfigure && (
                <SingleJobHandler
                  isMain={true}
                  setShowMaps={setShowMaps}
                  jobToConfigure={JobsToConfigure.mainJob}
                  showMaps={showMaps}
                  availableJobs={availableJobs}
                  currentTenant={(currentTenant as keyof typeof mapCenters) ?? "Qatar"}
                  currentPreviewAction={currentPreviewAction}
                  cancelCurrentAction={cancelCurrentAction}
                  confirmMoveOrders={confirmMoveOrders}
                  confirmAddOrders={confirmAddOrders}
                  confirmRemoveOrders={confirmRemoveOrders}
                  deleteOrders={deleteOrders}
                  destinationJob={JobsToConfigure.compareToJob?.job?.id}
                  onSelectOrders={onSelectOrders}
                  moveOrders={moveOrders}
                  addOrders={addOrders}
                  freeOrders={freeOrders}
                  handleReorderJobOrders={handleReorderJobOrders}
                  changePickupOrder={changePickupOrder}
                />
              )}
            </Col>

            <Col lg={12} sm={24} className="jobs_wrapper--compare">
              {currentPreviewAction.isOn && currentPreviewAction.type === "add" ? (
                <CustomCard subtitle="Orders without jobs">
                  <OrdersTable
                    isOrdersWithoutJob={true}
                    isMain={false}
                    isActionPreview={true}
                    destinationJob={currentPreviewAction.jobId!}
                    JobInfo={{
                      previewOrdersToAdd: [],
                      selectedIds: selectedIdsToAdd,
                      job: {
                        id: 0,
                        deliveryOrders: freeOrders,
                        jobRoutes: [],
                        status: "",
                      },
                    }}
                    onSelectOrders={handleSelectOrdersToAdd}
                    handleReorderJobOrders={handleReorderJobOrders}
                    deleteOrders={deleteOrders}
                    moveOrders={moveOrders}
                    addOrders={addOrders}
                    pagination={freeOrdersPagination}
                  />
                </CustomCard>
              ) : (
                JobsToConfigure && (
                  <SingleJobHandler
                    isMain={false}
                    allJobsIds={incompleteJobs}
                    handleChooseJob={handleChooseJob}
                    jobToConfigure={JobsToConfigure.compareToJob}
                    showMaps={showMaps}
                    availableJobs={availableJobs}
                    currentTenant={(currentTenant as keyof typeof mapCenters) ?? "Qatar"}
                    currentPreviewAction={currentPreviewAction}
                    cancelCurrentAction={cancelCurrentAction}
                    confirmMoveOrders={confirmMoveOrders}
                    destinationJob={JobsToConfigure.mainJob?.job?.id}
                    onSelectOrders={onSelectOrders}
                    confirmAddOrders={confirmAddOrders}
                    confirmRemoveOrders={confirmRemoveOrders}
                    moveOrders={moveOrders}
                    addOrders={addOrders}
                    deleteOrders={deleteOrders}
                    handleReorderJobOrders={handleReorderJobOrders}
                    changePickupOrder={changePickupOrder}
                  />
                )
              )}
            </Col>
          </Row>
        </div>
      </Spin>
      <Footer className="footer" hidden={!currentPreviewAction.isOn}>
        <PreviewOrdersAtion
          currentPreviewAction={currentPreviewAction}
          cancelCurrentAction={cancelCurrentAction}
          confirmMoveOrders={confirmMoveOrders}
          confirmAddOrders={confirmAddOrders}
          confirmRemoveOrders={confirmRemoveOrders}
          freeOrders={freeOrders}
        />
      </Footer>
    </>
  );
};
const MSTP = (state: RootState) => {
  const jobIds = userRole === "retailer" ? state.retailerOrders.jobIds : state.adminOrders.jobIds;

  return userRole === "retailer"
    ? {
        deliveryJobs: state.retailerJob.items,
        isFetchingDeliveryJobs: state.retailerJob.isFetching,
        isMobile: state.admin.isMobile,
        freeOrders: state.retailerOrders?.items[0]?.items,
        freeOrdersPagination: state.retailerOrders?.items[0]?.meta,
        incompleteJobs: jobIds,
        forceBackToMainPage: state.retailerJob.forceBackToMainPage,
      }
    : {
        deliveryJobs: state.deliveryJobs.items,
        isFetchingDeliveryJobs: state.deliveryJobs.isFetching,
        isMobile: state.admin.isMobile,
        freeOrders: state.adminOrders?.items[0]?.items,
        freeOrdersPagination: state.adminOrders?.items[0]?.meta,
        incompleteJobs: jobIds,
        forceBackToMainPage: state.deliveryJobs.forceBackToMainPage,
      };
};

export default connect(MSTP, (dispatch) => {
  const {
    fetchDeliveryJobs,
    getOneDeliveryJob,
    getPolylineDeliveryJob,
    getRoutesDeliveryJob,
    addOrdersDeliveryJob,
    removeOrdersDeliveryJob,
    moveOrdersDeliveryJob,
    configureJobDeliveryJob,
    setSelectedOrdersDeliveryJob,
    setPreviewOrdersDeliveryJob,
    resetConfigureJobDeliveryJob,
    changeOrderPositionDeliveryJob,
    changePickupPoistionDeliveryJob,
    resetFlagDeliveryJob,
  } = bindActionCreators(deliveryJobActions, dispatch);

  const {
    fetchRetailerJobs,
    getOneRetailerJob,
    getPolylineRetailerJob,
    getRoutesRetailerJob,
    addOrdersRetailerJob,
    removeOrdersRetailerJob,
    moveOrdersRetailerJob,
    configureJobRetailerJob,
    setSelectedOrdersRetailerJob,
    setPreviewOrdersRetailerJob,
    resetConfigureJobRetailerJob,
    changeOrderPositionRetailerJob,
    changePickupPoistionRetailerJob,
    resetFlagRetailerJob,
    getJobsByStatusRetailerJob,
  } = bindActionCreators(retailerJobActions, dispatch);

  const { fetchAdminOrders, getJobsByStatusAdminOrder } = bindActionCreators(adminOrdersActions, dispatch);
  const { fetchRetailerOrders } = bindActionCreators(retailerOrdersActions, dispatch);

  return userRole === "retailer"
    ? {
        fetchDeliveryJobs: fetchRetailerJobs,
        getOneDeliveryJob: getOneRetailerJob,
        getPolylineDeliveryJob: getPolylineRetailerJob,
        getRoutesDeliveryJob: getRoutesRetailerJob,
        addOrdersDeliveryJob: addOrdersRetailerJob,
        removeOrdersDeliveryJob: removeOrdersRetailerJob,
        moveOrdersDeliveryJob: moveOrdersRetailerJob,
        configureJobDeliveryJob: configureJobRetailerJob,
        setSelectedOrdersDeliveryJob: setSelectedOrdersRetailerJob,
        setPreviewOrdersDeliveryJob: setPreviewOrdersRetailerJob,
        resetConfigureJobDeliveryJob: resetConfigureJobRetailerJob,
        changeOrderPositionDeliveryJob: changeOrderPositionRetailerJob,
        changePickupPoistionDeliveryJob: changePickupPoistionRetailerJob,
        resetFlagDeliveryJob: resetFlagRetailerJob,
        fetchAdminOrders: fetchRetailerOrders,
        getJobsByStatusAdminOrder: getJobsByStatusRetailerJob,
      }
    : {
        // Admin Job
        fetchDeliveryJobs,
        getOneDeliveryJob,
        getPolylineDeliveryJob,
        getRoutesDeliveryJob,
        addOrdersDeliveryJob,
        removeOrdersDeliveryJob,
        moveOrdersDeliveryJob,
        configureJobDeliveryJob,
        setSelectedOrdersDeliveryJob,
        setPreviewOrdersDeliveryJob,
        resetConfigureJobDeliveryJob,
        changeOrderPositionDeliveryJob,
        changePickupPoistionDeliveryJob,
        resetFlagDeliveryJob,
        fetchAdminOrders,
        getJobsByStatusAdminOrder,
      };
})(JobsConfigurePage);
