import React, { lazy, Suspense, useState,memo } from "react";
import { Spin } from "antd";
import { Route, Switch } from "react-router-dom";

import { Layout, GetStartedModal } from "./components";
import TermsConditionsPage from "./pages/TermsConditionsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";

const Main = lazy(() => import("./pages/MainPage" /* webpackChunkName: "main-page" */));
const Booths = lazy(() => import("./pages/BoothsPage" /* webpackChunkName: "booths-page" */));
const AboutUs = lazy(() => import("./pages/AboutUsPage" /* webpackChunkName: "about-us-page" */));
const BoothDetails = lazy(() => import("./pages/BoothDetailsPage" /* webpackChunkName: "booth-details-page" */));
const StoreToStore = lazy(() => import("./pages/StoreToStorePage" /* webpackChunkName: "store-to-store-page" */));
const ClientReturns = lazy(() => import("./pages/ClientReturnsPage" /* webpackChunkName: "client-returns-page" */));
const SameDayDelivery = lazy(() => import("./pages/SameDayDeliveryPage" /* webpackChunkName: "same-day-page" */));

const Landing = ({ isMobile }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleToggleModal = () => setIsModalVisible((prevState) => !prevState);

  return (
    <Layout isMobile={isMobile}>
      <Suspense fallback={<Spin size="large" style={{ marginTop: "20%" }} />}>
        <Switch>
          <Route exact path="/">
            <Main isMobile={isMobile} onToggleGetStartedModal={handleToggleModal} />
          </Route>

          <Route exact path="/booths" component={Booths} />

          <Route path="/booths/:boothId" component={BoothDetails} />

          <Route path="/store-to-store">
            <StoreToStore isMobile={isMobile} onToggleGetStartedModal={handleToggleModal} />
          </Route>

          <Route path="/client-returns">
            <ClientReturns isMobile={isMobile} onToggleGetStartedModal={handleToggleModal} />
          </Route>

          <Route path="/same-day-delivery">
            <SameDayDelivery isMobile={isMobile} onToggleGetStartedModal={handleToggleModal} />
          </Route>

          <Route path="/about-us">
            <AboutUs isMobile={isMobile} />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicyPage isMobile={isMobile} />
          </Route>
          <Route path="/terms-conditions">
            <TermsConditionsPage isMobile={isMobile} />
          </Route>
          <Route path="/about-us">
            <AboutUs isMobile={isMobile} />
          </Route>
        </Switch>
      </Suspense>

      {isModalVisible && (
        <GetStartedModal isMobile={isMobile} isVisible={isModalVisible} onToggleModal={handleToggleModal} />
      )}
    </Layout>
  );
};

export default memo(Landing);
