import React from "react";
import { Divider, Layout } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { useHooks } from "./hooks/useHooks";
import { actions as retailerActions } from "../../../redux/resources/retailer";

import RetailerCabinetHeader from "../../../components/OrdersWidget/OrdersWidgetHeader";
import InputGroup from "./components/InputGroup";
import Header from "./components/Header";
import UsersTable from "./components/UsersTable";

import "./retailerManagment.less";

const { Content } = Layout;

const RetailerManagment = ({
  isMobile,
  getUsersRetailer,
  createUserRetailer,
  deleteUserRetailer,
  confirmSelfRetailer,
  isWidget = true,
  showTitle = true,
}) => {
  const { intl, createUser, deleteUser, getUsers, users, loading, paginationConfig, onChangeTable } = useHooks({
    getUsersRetailer,
    createUserRetailer,
    deleteUserRetailer,
    confirmSelfRetailer,
    isMobile,
  });
  return (
    <div className="retailer-user-managment-page">
      <Layout>
        {!isWidget && <RetailerCabinetHeader />}
        <Content className="retailerCabinetContent retailerSettingsContent" style={{ paddingTop: 0 }}>
          {(!isWidget || showTitle) && <Header />}
          <InputGroup intl={intl} createUser={createUser} />
          <Divider />
          <UsersTable
            intl={intl}
            users={users}
            deleteUser={deleteUser}
            getUsers={getUsers}
            loading={loading}
            paginationConfig={paginationConfig}
            onChangeTable={onChangeTable}
          />
        </Content>
      </Layout>
    </div>
  );
};

const MSTP = (state) => {
  return {
    isMobile: state.admin.isMobile,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(retailerActions, dispatch),
}))(RetailerManagment);
