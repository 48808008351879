import { notification } from "antd";
import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Manager } from "socket.io-client";
import { API_URL } from "../../../constants/config";
import { getAuthData, getUserTenant } from "../../../helpers/authStorage";
import { getQueryParam, updatePageUrl } from "../../../helpers/general";
import { actions as paymentsActions } from "../../../redux/resources/payments";
import { FormattedMessage, useIntl } from "react-intl";
import { validOrderID } from "src/constants/searchRegex";
import { PaymentStatus } from "src/constants/jobsStatus";

const DEFAULT_URL = "admin/payments";

const useHooks = () => {
  const [query, setQuery] = useState({ page: 1, limit: localStorage.getItem("paymentOnPage") || 5, status: "" });
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [paymentSearchQuery, setPaymentSearchQuery] = useState("orderId");
  const [searchInputErrors, setSearchInputErrors] = useState({ type: "", err: "" });
  const pagination = useSelector(({ payments }) => payments.pagination);
  const [payments, setPayments] = useState([]);
  const searchFilter = [
    { text: <FormattedMessage id="tabPane.orderID" defaultMessage="Order Id" />, value: "orderId" },
    { text: <FormattedMessage id="status" defaultMessage="Status" />, value: "status" },
  ];
  const dispatch = useDispatch();
  const paymentStatuses = Object.values(PaymentStatus);
  const paginationConfig = {
    pageSize: query.limit,
    total: pagination?.totalCount || 5,
    current: query.page,
    pageSizeOptions: [10, 20, 30, 50],
    disabled: pagination?.totalPages === 1,
    position: ["bottomRight"],
  };

  const { fetchPayments } = paymentsActions;

  const intl = new useIntl();

  const fetchData = async () => {
    setLoading(true);
    await dispatch(
      fetchPayments(
        {},
        {
          query: {
            page: query.page,
            limit: query.limit,
          },
        },
      ),
    ).then(({ items }) => {
      console.log("items", items);
      setLoading(false);
      setPayments(items);
    });
  };

  const fetchSearchedData = useCallback(async () => {
    try {
      if (searchedText === "") {
        fetchData();
      } else {
        setLoading(true);
        await dispatch(
          fetchPayments(
            {},
            {
              query: {
                [paymentSearchQuery]: searchedText,
                page: query.page,
                limit: query.limit,
              },
            },
          ),
        ).then(({ items }) => {
          setLoading(false);
          setPayments(items);
        });
      }
    } catch (e) {
      setLoading(false);
    }
  }, [searchedText, paymentSearchQuery, query]);

  // useMemo(
  //   () => {
  //     fetchSearchedData();
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [searchedText],
  // );

  const onFetchPayments = () => {
    setLoading(true);
    fetchData();
  };

  const onChangePagination = (page, limit) => {
    const previousQueryParams = getQueryParam();
    const newQueryParams = { page, limit };
    updatePageUrl(newQueryParams, previousQueryParams, history, DEFAULT_URL);
  };

  const socketRef = useRef();

  const token = encodeURIComponent(getAuthData().accessToken);

  const handleUpdate = useCallback((incomingMessage) => {
    console.log("incoming", incomingMessage);
    onFetchPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExeption = (data) => {
    notification.error({
      message: "Error",
      description: "Error with websockets",
    });
  };

  const handleDisconnect = () => {
    console.log("Disconnected");
  };

  const handleSearchPayment = async (e) => {
    setQuery((old) => ({ ...old, page: 1 }));
    if (e.target.value) {
      setLoading(true);
      setSearchedText(e.target.value);
    } else {
      setSearchedText("");
    }
    setLoading(false);
  };

  const handleConnect = () => {
    console.log("connected");
  };

  useEffect(() => {
    const timeout = setTimeout(() => fetchSearchedData(), 300);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, searchedText, paymentSearchQuery, fetchSearchedData]);

  useEffect(() => {
    const manager = new Manager(`${API_URL}/`, {
      reconnectionDelayMax: 10000,
      transports: ["websocket"],
      path: "/socket.io",
      query: {
        token,
        tenant: getUserTenant(),
      },
    });

    socketRef.current = manager.socket("/admin-notifications");
    socketRef.current.on("connect", handleConnect);
    socketRef.current.on("payment-updated", handleUpdate);
    socketRef.current.on("exception", handleExeption);
    socketRef.current.on("disconnect", handleDisconnect);

    return () => {
      socketRef.current.off("payment-updated", handleUpdate);
      socketRef.current.off("exception", handleExeption);
      socketRef.current.off("disconnect", handleDisconnect);
      socketRef.current.off("connect", handleConnect);
    };
    // eslint-disable-next-line
  }, []);

  /* validate the search value format after each change */
  const handleChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    let message = "";

    switch (paymentSearchQuery) {
      case "orderId":
        message = validOrderID.test(value)
          ? ""
          : ` ${value} : ${intl.formatMessage(
              {
                id: "input.searchOrder.error",
                defaultMessage: "is not a valid Order ID",
              },

              { searchOption: `${intl.formatMessage({ id: "tabPane.orderID", defaultMessage: "Order ID" })}` },
            )}`;
        break;
      case "status":
        message = paymentStatuses.includes(value)
          ? ""
          : ` ${value} : ${intl.formatMessage(
              {
                id: "input.searchPayment.error",
                defaultMessage: "is not a valid Payment status",
              },

              {
                searchOption: `${intl.formatMessage({
                  id: "tabPane.paymentStatus",
                  defaultMessage: "Payment Status",
                })}`,
              },
            )}`;
        break;
      default:
        break;
    }

    setSearchInputErrors({ name: paymentSearchQuery, err: message });
  };

  return {
    data: payments,
    paginationConfig,
    onChangePagination,
    setPaymentSearchQuery,
    searchFilter,
    loading,
    handleSearchPayment,
    query,
    setQuery,
    searchedText,
    setSearchedText,
    searchInputErrors,
    handleChange,
  };
};

export default useHooks;
