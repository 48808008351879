import { useCallback, useEffect, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { assignIn, omit } from "lodash";
import { notification } from "antd";

import { getQueryParam, updatePageUrl } from "../../../helpers/general";
import { actions as driversMapActions } from "../../../redux/resources/driversMap";

export const useHooks = () => {
  const [allDriverSelected, setAllDriversSelected] = useState(false);
  const [showJobs, setShowJobs] = useState(false);
  const drivers = useSelector(({ driversMap }) => driversMap.items);
  const selectedDriver = useSelector(({ driversMap }) =>
    allDriverSelected && drivers ? "all" : driversMap?.selectedDriver,
  );

  const locations = useSelector(({ driversMap }) =>
    allDriverSelected && drivers
      ? driversMap.items.map(({ location, id }) => ({
          ...location,
          id,
        }))
      : driversMap.locations,
  );
  const job = useSelector(({ driversMap }) => driversMap.item);
  const pagination = useSelector(({ driversMap }) => driversMap.pagination);
  const isMobile = useSelector(({ admin }) => admin.isMobile);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const openInfoNotification = (message) => {
    notification.info({
      message: message.title,
      description: message.description,
    });
  };

  const {
    getLocationDriversMap,
    getAllDriversMap,
    getAllMobileDriversMap,
    clearDataDriversMap,
    getCurrentJobDriversMap,
  } = driversMapActions;

  const fetchSelectedDriver = () => {
    if (selectedDriver && selectedDriver !== "all") {
      dispatch(getLocationDriversMap(selectedDriver));
    }
  };

  const getQueryParams = useCallback(() => getQueryParam(location.search), [location.search]);

  const fetchAllDrivers = useCallback(
    (otherQueries) => {
      if (isMobile) {
        return dispatch(getAllMobileDriversMap());
      }

      const queryParams = getQueryParams();
      const queryWithPage = queryParams.page ? queryParams : { ...queryParams, page: 1, limit: 25 };
      const withOtherQueries = otherQueries ? assignIn(queryWithPage, otherQueries, queryParams) : queryWithPage;
      dispatch(
        getAllDriversMap(
          {},
          {
            query: withOtherQueries,
          },
        ),
      );
    },
    [isMobile, getAllDriversMap, getAllMobileDriversMap, dispatch, getQueryParams],
  );

  useEffect(() => {
    fetchSelectedDriver();
    /*   let interval;
    if (selectedDriver) {
      interval = setInterval(() => {
        fetchSelectedDriver();
      }, 10000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
   */ // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDriver]);

  useEffect(() => {
    fetchAllDrivers();
    const interval = setInterval(() => {
      fetchAllDrivers();
    }, 20000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAllDrivers]);

  useLayoutEffect(() => {
    document.body.className = "body-fixed";

    return () => {
      document.body.className = "";
    };
  }, []);

  const updateCurrentPageUrl = (newQueryParams, queriesToRemove) => {
    const previousQueryParams = omit(getQueryParams(), Object.keys(queriesToRemove));
    updatePageUrl(newQueryParams, previousQueryParams, history, "admin/drivers_map");
  };

  const changeQueryParams = (pagination) => {
    updateCurrentPageUrl(
      {
        page: pagination?.current,
        limit: pagination?.pageSize,
      },
      {},
    );
  };

  const currentQueryParams = getQueryParams();

  const paginationData = {
    pageSize: 25,
    total: pagination?.totalItems || 0,
    current: Number(currentQueryParams?.page) || 1,
    pageSizeOptions: [10, 20, 30, 50],
    showSizeChanger: false,
    position: ["bottomRight"],
  };

  const selectDriver = (id) => {
    setShowJobs(false);
    dispatch(clearDataDriversMap());
    if (id === "all") {
      setAllDriversSelected(true);
      return;
    }
    setAllDriversSelected(false);
    dispatch(getLocationDriversMap(id)).then((res) => {
      !res.locations.length && openInfoNotification({ title: "Disabled", description: "This driver is disable" });
    });
  };

  const markerClick = (id) => {
    const driverId = Number.isNaN(+id) ? selectedDriver : id;
    dispatch(getCurrentJobDriversMap(driverId)).catch((e) => {
      openInfoNotification({ title: "Without a job", description: e.body.message });
    });
    setShowJobs(true);
  };

  return {
    isMobile,
    job,
    drivers,
    selectedDriver,
    allDriverSelected,
    locations,
    paginationData,
    selectDriver,
    markerClick,
    showJobs,
    changeQueryParams,
  };
};
