import React from "react";
import { Dropdown, Tooltip } from "antd";
import { MoreOutlined, DeleteOutlined } from "@ant-design/icons";

import DropdownMenu from "./DropdownMenu";

function ActionBtns({ id, onSetModal, old, onDeleteLocal, isAdminOrders, providedActions, isReturn, newStyle }) {
  return old ? (
    <>
      <Tooltip title={!providedActions?.length ? `No further action to perform on order n° ${id}` : ""}>
        <Dropdown
          className={"order-dropdown-button"}
          trigger={["click"]}
          destroyPopupOnHide
          overlay={
            <DropdownMenu
              id={id}
              onSetModal={onSetModal}
              isAdminOrders={isAdminOrders}
              providedItems={providedActions}
              isReturn={isReturn}
            />
          }
          placement="bottom"
          arrow
        >
          <MoreOutlined />
        </Dropdown>
      </Tooltip>
    </>
  ) : (
    <DeleteOutlined onClick={() => onDeleteLocal(id)} />
  );
}

export default ActionBtns;
