import { Button, Descriptions, Modal } from 'antd';
import React, { FC } from 'react';
import { IOrderDetailsProps } from './type';

export const OrderDetails: FC<IOrderDetailsProps> = ({ order, isModalOpen, handleCancel }) => {
  return <>
    <Modal
      title="Order Details"
      visible={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}> Close </Button>
      ]}
    >
      {
        order &&
        <Descriptions bordered column={1} size="small">
          <Descriptions.Item label="Order Id">{order?.id}</Descriptions.Item>
          <Descriptions.Item label="Tracking Id">{order?.trackingId}</Descriptions.Item>
          <Descriptions.Item label="Phone Number">{order?.customer?.phoneNumber}</Descriptions.Item>
          <Descriptions.Item label="Zone">{order?.deliveryPoint?.initialAddress?.zone}</Descriptions.Item>
          <Descriptions.Item label="Address">
            <p>{order?.deliveryPoint?.initialAddress?.building}</p>
            <p>{order?.deliveryPoint?.initialAddress?.street}</p>
            <p>{order?.deliveryPoint?.initialAddress?.city}</p>
          </Descriptions.Item>
          <Descriptions.Item label="COD Amount">{order?.cashAmount}</Descriptions.Item>
        </Descriptions>
      }
    </Modal>
  </>

}