export const getQuery = (value: any, key: string) => (value ? { [key]: value } : {});
export const getRetailerQueryPart = (selectedRetailer: number | null) => getQuery(selectedRetailer, "restrictedId");
export const statusQuery = "ready&status=incomplete&status=new";

const enum StepStatus {
  WAIT = "wait",
  PROCESS = "process",
  DONE = "finish",
  ERROR = "error",
}
// display state for steps header
export const getStatus = (step: number, current: number, data: any) => {
  if (step !== 2 && current === step) return StepStatus.PROCESS;
  switch (step) {
    case 0:
      return data.selectedOrders?.length ? StepStatus.DONE : StepStatus.WAIT;
    case 1:
      return !data.isDriversNeeded || data.selectedDrivers?.length ? StepStatus.DONE : StepStatus.WAIT;
    case 2:
      return !data.canRunCFR
        ? StepStatus.ERROR
        : data.isCFRInstance
        ? StepStatus.PROCESS
        : data.results
        ? StepStatus.DONE
        : StepStatus.WAIT;
    case 3:
      return StepStatus.WAIT;
    default:
      return StepStatus.PROCESS;
  }
};
