import React, { useState } from "react";
import { Radio, message, Button } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { API_URL } from "../../../../constants/config";
import { getAuthData, getUserTenant, updateRefreshToken } from "../../../../helpers/authStorage";
import arrowLeft from "../../../../assets/arrow-left.png";
import UploadDocumentsActions from "./components/UploadDocumentsActions";
import UploadDocumentDragger from "../../../../components/DataEntry/UploadDocumentDragger";
import DriverRegistrationFinished from "../DriverRegistrationFinished";

import "./uploadDocumentsForm.less";

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
  fontSize: "14px",
  color: "rgba(34, 34, 34, 0.5)",
  marginBottom: "15px",
};

const UploadDocumentsForm = ({ onNextStep, onPrevStep, uploadedImage, setUploadedImage }) => {
  const [activeUploadDocument, setactiveUploadDocument] = useState("nationalId");

  const [isRegistrationFinished, setIsRegistrationFinished] = useState(false);

  const intl = useIntl();

  const uploadDocumentsSteps = [
    {
      title: `${intl.formatMessage({
        id: "steps.nationalId",
        defaultMessage: "National ID",
      })}`,
      value: "nationalId",
      next: "drivingLicense",
    },
    {
      title: `${intl.formatMessage({
        id: "steps.drivingLicense",
        defaultMessage: "Driving License",
      })}`,
      value: "drivingLicense",
      next: "policeClearanceCertificate",
    },
    {
      title: `${intl.formatMessage({
        id: "steps.policeClearanceCertificate",
        defaultMessage: "Police Clearance Certificate",
      })}`,
      value: "policeClearanceCertificate",
      next: "carRegistrationDocuments",
    },
    {
      title: `${intl.formatMessage({
        id: "steps.carRegisterDocuments",
        defaultMessage: "Car registration documents",
      })}`,
      value: "carRegistrationDocuments",
      next: "nationalId",
    },
  ];

  const onChange = (e) => {
    setactiveUploadDocument(e.target.value);
  };

  const onGoToNextFile = (activeDocumentName) => {
    const nextActiveDocument = uploadDocumentsSteps.find((step) => step.value === activeDocumentName);
    setactiveUploadDocument(nextActiveDocument.next);
  };

  const onCancelUploadTheDocument = (activeDocumentName) => {
    const updatedImages = { ...uploadedImage, [activeDocumentName]: "" };
    setUploadedImage(updatedImages);
  };

  const isDocumentsUploaded = () => {
    const isDocumentUploaded = uploadDocumentsSteps.find((documentInfo) => !uploadedImage[documentInfo.value]);
    return !!isDocumentUploaded;
  };

  const draggerProps = {
    name: activeUploadDocument,
    multiple: false,
    method: "put",
    headers: {
      Authorization: `Bearer ${getAuthData().accessToken}`,
      Tenant: getUserTenant(),
      // the token is a variable which holds the token
    },
    action: `${API_URL}/drivers/self/documents`, // (file)=>saveDriverDocument(file),
    showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        const updatedImages = { ...uploadedImage, [activeUploadDocument]: info.file.originFileObj };
        setUploadedImage(updatedImages);
        message.success(
          `${info.file.name} ${intl.formatMessage({
            id: "page.step.documentUpload.uploadSuccess",
            defaultMessage: "file uploaded successfully.",
          })}`,
        );
      } else if (status === "error") {
        const updatedImages = { ...uploadedImage, [activeUploadDocument]: "" };
        setUploadedImage(updatedImages);
        if (info.file.response.statusCode === 401) {
          return updateRefreshToken().then(() =>
            message.error(
              `${info.file.name} ${info.file.name} ${intl.formatMessage({
                id: "page.step.documentUpload.uploadFailed",
                defaultMessage: "file upload failed.",
              })} ${info.file.name} ${intl.formatMessage({
                id: "page.step.documentUpload.tryToUpload",
                defaultMessage: "Please try to upload file one more time",
              })}`,
            ),
          );
        }
        message.error(
          `${info.file.name} ${intl.formatMessage({
            id: "page.step.documentUpload.uploadFailed",
            defaultMessage: "file upload failed.",
          })}`,
        );
      }
    },
  };

  if (!isRegistrationFinished) {
    return (
      <>
        <h3 className="driverInfoTitle">
          <FormattedMessage id="page.step.driver.documentUpload.title" defaultMessage="Document Upload" />
        </h3>
        <p className="driverInfoDescription">
          <FormattedMessage
            id="page.step.driver.documentUpload.subtitle"
            defaultMessage="You can upload a picture or a PDF. Please make sure all documents are clear to avoid any delays relating to your
    application."
          />
        </p>
        <div className="uploadStepsWrapper">
          <div className="uploadDocumentsWrapper">
            <UploadDocumentDragger draggerProps={draggerProps} uploadedImage={uploadedImage[activeUploadDocument]} />
            {uploadedImage[activeUploadDocument] && (
              <UploadDocumentsActions
                onNext={() => onGoToNextFile(activeUploadDocument)}
                onCancel={() => onCancelUploadTheDocument(activeUploadDocument)}
              />
            )}
          </div>
          <Radio.Group onChange={onChange} value={activeUploadDocument}>
            {uploadDocumentsSteps.map((step) => (
              <Radio style={radioStyle} value={step.value}>
                <span className={activeUploadDocument === step.value && "activeStep"}>{step.title}</span>
              </Radio>
            ))}
          </Radio.Group>
        </div>
        <div className="stepAction">
          <img src={arrowLeft} alt="Left arrow icon" />
          <Button className="previousStepButton" type="text" onClick={() => onPrevStep()}>
            {`${intl.formatMessage({ id: "steps.previousStep", defaultMessage: "Previous Step" })}`}
          </Button>
          <Button
            type="primary"
            disabled={isDocumentsUploaded()}
            onClick={() => setIsRegistrationFinished(true)}
            className="nextStepButton"
          >
            {`${intl.formatMessage({ id: "steps.next", defaultMessage: "Next" })}`}
          </Button>
        </div>
      </>
    );
  } else {
    return <DriverRegistrationFinished />;
  }
};

export default UploadDocumentsForm;
