export interface ICombineJobsRouting {
  //   handleDeleteJobsFromMain: (ids: number[]) => void;
  //   availableJobs: (id: number) => {
  //     jobId: number;
  //   }[];
  //   handleMoveOrder: (orderId: number, jobId: number, originalJobId: number) => Promise<void>;
  //   isConfirmModalOpen: {
  //     open: boolean;
  //     order?: number | undefined;
  //     jobId?: number | undefined;
  //     originalJobId?: number | undefined;
  //   };
  //   setConfirmModalOpen: React.Dispatch<
  //     React.SetStateAction<{
  //       open: boolean;
  //       order?: number | undefined;
  //       jobId?: number | undefined;
  //       originalJobId?: number | undefined;
  //     }>
  //   >;
  //   isMovingOrdersLoading: boolean;
  //   setSelectedRetailer: React.Dispatch<React.SetStateAction<number | null>>;
  //   selectedRetailer: number | null;
  //   retailers: any[];
  //   compare: { show: "one" | "many"; isOn?: boolean };
  //   setCompare: React.Dispatch<React.SetStateAction<{ show: "one" | "many"; isOn?: boolean }>>;
  //   setSelectedJob: React.Dispatch<React.SetStateAction<any>>;
  //   setSelectedJobID?: React.Dispatch<React.SetStateAction<number[]>>;
  //   selectedJobIds: number[];
  //   selectedJobs: any[];
}

export enum JobStatusEnum {
  ALL = "all",
  NEW = "new",
  INCOMPLETE = "incomplete",
  READY = "ready",
  ASSIGNED_TO_DRIVER = "assigned_to_driver",
  PICKING_UP_PACKAGES = "picking_up_packages",
  PICKUP_COMPLETED = "pickup_completed",
  IN_TRANSIT = "in_transit",
  COMPLETED = "completed",
}

export interface IJobsListProps {
  selectedJobs: any[];
  items: any[];
  selectedStatus: JobStatusEnum;
  onJobSelect: (ids: any[]) => void;
}

export interface ConfirmCombineJobModalProps {
  isModalOpen: boolean;
  selectedJobs: any[];
  handleOk: () => void;
  handleCancel: () => void;
  removeJob: (id: number, index: number) => void;
}
