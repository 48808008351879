import React , { FC } from "react";
import { List, Skeleton, Typography } from 'antd';
import { NotificationAvatar } from "./NotificationAvatar";
import "./style.less"
import { transformNotificationItemDescription } from "../helpers/transform";
import { NotificationTitle } from "./NotificationTitle";
import { NotificationListItemProps } from "../types";
import "./style.less";


const { Item } = List;
const { Text } = Typography;

export const NotificationListItem: FC<NotificationListItemProps> = ({notification , readNotifications}) => {
    return <Item
            className={`notification-body ${!notification?.IsRead ? 'notification-body-not-read' : ''}`}
             >
                <Skeleton avatar title={false} loading={notification?.loading} active>
                    {
                        notification?.type && 
                        <Item.Meta
                            avatar={<NotificationAvatar authorName={notification?.authorName} />}
                            title={<NotificationTitle id={notification?.id} IsRead={notification?.IsRead} title={notification?.authorName} readNotifications={readNotifications} />}
                            description={<Text type="secondary">{transformNotificationItemDescription(notification?.type || '')}<Text code>{`N°=${notification?.targetId || ''}`}</Text></Text>}
                        />
                    }
                </Skeleton>
            </Item>;
}