import { notification } from "antd";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { HttpError } from "redux-rest-resource";
import { ICFRParameters, TUseCFRSetting } from "../../../types/CloudFleetRouting";
import MessageOutput from "../CFRResults/MessageOutput";
import { getRetailerQueryPart, statusQuery } from "../helper";
import { isRetailer } from "../../../helpers/authStorage";

const CFR_PARAM_CONSTANT = {
  withZones: false,
  driverLimit: 30, //package
  travelLimit: 7, //hours
  deliveryTime: 7, //hours
  deliveryStartTime: moment().add(1, "hours"),
  deliveryEndTime: moment().add(8, "hours"),
  pickUpStartTime: moment(),
  pickUpEndTime: moment().add(1, "hours"),
  driverCostPerHour: 100,
  driverCostPerKm: 10,
  averagePickUpTime: 30, //seconds
  averageDeliveryTime: 5, // minutes
  timeout: 30, // seconds
  numberOfDrivers: undefined,
};

const TIME_FORMAT = "HH:mm";

export interface ICFRProgressBar {
  total: number;
  step: number;
  percent: number;
}

type TCFRKey = keyof ICFRParameters;

const generateCfrQueryPart = (cfrParams: ICFRParameters): ICFRParameters => {
  return {
    ...cfrParams,
    ...{
      deliveryStartTime: cfrParams.deliveryStartTime.valueOf(),
      deliveryEndTime: cfrParams.deliveryEndTime.valueOf(), //time range
      pickUpStartTime: cfrParams.pickUpStartTime.valueOf(), //time range
      pickUpEndTime: cfrParams.pickUpEndTime.valueOf(),
    },
  };
};

const jobNotification = (cfrResults: any) => {
  if (cfrResults && Object.keys(cfrResults).length > 0) {
    if (cfrResults?.message || cfrResults?.createdJobs?.length === 0) {
      notification.error({
        message: "Error in Job Creation",
        description: cfrResults?.message,
      });
    } else if (
      cfrResults?.createdJobs?.length > 0 &&
      cfrResults?.skippedOrdersInZones === 0 &&
      cfrResults?.ordersOutsideOfZones === 0
    ) {
      notification.success({
        message: "Job Created Successfuly",
        description: <MessageOutput cfrResults={cfrResults} />,
      });
    } else {
      notification.warning({
        message: "Job Creation Partial Successfuly",
        description: <MessageOutput cfrResults={cfrResults} />,
      });
    }
  }
};

export const useCFRSettings: TUseCFRSetting = ({
  fetchJobsMaps,
  cfrStateJobsMap,
  cfrJobsMap,
  selectedRetailer,
  selectedOrders,
}) => {
  const [cfrParams, setCFRParams] = useState<ICFRParameters>(CFR_PARAM_CONSTANT);
  const [isCFRLoading, setIsCFRLoading] = useState<boolean>(false);
  const [CFRResponse, setCFRResponse] = useState<any>(null);

  const getCFRStatus = async () => {
    try {
      await cfrStateJobsMap();
    } catch {
      notification.info({
        message: "Error",
        description: "Oops! Unable to fetch CFR state",
      });
    }
  };

  const handleChangeCfrParams = (value: string | number | boolean | any, key: TCFRKey) => {
    let currentParams = cfrParams;

    if (key === ("pickupRange" as TCFRKey)) {
      currentParams["pickUpStartTime"] = moment(value[0], TIME_FORMAT);
      currentParams["pickUpEndTime"] = moment(value[1], TIME_FORMAT);
    } else if (key === ("deliveryRange" as TCFRKey)) {
      currentParams["deliveryStartTime"] = moment(value[0], TIME_FORMAT);
      currentParams["deliveryEndTime"] = moment(value[1], TIME_FORMAT);
    } else {
      currentParams[key] = value;
    }

    setCFRParams({ ...cfrParams, ...currentParams });
  };

  const fetchJobs = async (restrictedQueryPart: any) => {
    try {
      await fetchJobsMaps({}, { query: { status: statusQuery, ...restrictedQueryPart } });
    } catch {
      notification.error({
        message: "Error",
        description: "Oops! Error while fetching jobs",
      });
    }
  };

  const generateJobsByCfr = async () => {
    try {
      setIsCFRLoading(true);
      const restrictedQueryPart = getRetailerQueryPart(selectedRetailer);
      const cfrQueryPart = generateCfrQueryPart(cfrParams);

      notification.info({
        message: "Message",
        description: "Work started, this might take some time, please wait",
      });
      let response;
      if (isRetailer) {
        response = await cfrJobsMap(
          {},
          {
            body: {
              ordersIds: selectedOrders ?? [],
              ...restrictedQueryPart,
              ...cfrQueryPart,
              // driversIds: selectedDrivers ?? [],
            },
          },
        );
      } else {
        response = await cfrJobsMap(
          {},
          {
            query: { ...restrictedQueryPart, ...cfrQueryPart },
            body: {
              ordersIds: selectedOrders ?? [],
              // driversIds: selectedDrivers ?? [],
            },
          },
        );
      }

      if (response.body) {
        let cfrResults = response.body;
        setCFRResponse(cfrResults);
        jobNotification(cfrResults);
      }
    } catch (error) {
      if (error instanceof HttpError) {
        notification.error({
          message: "Error",
          description: error?.message ? error.message : "Error while running algorithm",
        });
      } else {
        notification.error({
          message: "Error",
          description: "Error while running algorithm",
        });
      }
    } finally {
      setIsCFRLoading(false);
    }
  };

  return {
    generateJobsByCfr,
    handleChangeCfrParams,
    getCFRStatus,
    CFRResponse,
    cfrParams,
    isCFRLoading,
  };
};
