import React, { FC } from "react";
import { Row, Tooltip, Typography } from 'antd';
import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import "./style.less";
import { NotificationTitleProps } from "../types";

const { Text } = Typography;

export const NotificationTitle: FC<NotificationTitleProps> = ({ IsRead, title , readNotifications, id }) => {
    const setNotificationAsRead = () => {
        readNotifications(`${id}`);
    }
    return (
        <Row justify="space-between" align="top">
            <Text strong>{title}</Text>
            <>
            {
                IsRead ?
                    <CheckCircleFilled className="read-with-success-icon" />
                    : 
                    <Tooltip placement="bottom" title="Mark as read">
                        <CheckCircleOutlined onClick={setNotificationAsRead} />
                    </Tooltip>
            }
            </>
        </Row>
    );
}