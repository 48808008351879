/* eslint-disable react-hooks/exhaustive-deps */
import { useIntl } from "react-intl";
import { notification } from "antd";
import { useLocation, useHistory } from "react-router-dom";

export const useHooks = ({ isMobile, confirmSelfRetailer }) => {
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();
  const confirmationToken = new URLSearchParams(location.search).get("confirmationToken");

  const confirmSelf = (data) => {
    confirmSelfRetailer(data, { query: { confirmationToken } }).then(
      () => {
        notification.success({
          message: "Success",
          description: `${intl.formatMessage({ id: "success.registered", defaultMessage: "Registered" })}`,
        });
        history.push({ pathname: "/retailer_login" });
      },
      (err) => {
        notification.error({
          message: "Error",
          description: `${intl.formatMessage({ id: "error.inviteExpired", defaultMessage: "Invite expired" })}`,
        });
        history.push({ pathname: "/retailer_login" });
      },
    );
  };

  return {
    intl,
    confirmSelf,
  };
};
