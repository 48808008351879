import React from "react";
import { Collapse } from "antd";

import "./styles.less";

export const MobileCollapseList = ({ children, activeKey, onCollapse }) => (
  <Collapse
    activeKey={activeKey}
    accordion
    onChange={onCollapse}
    expandIconPosition="right"
    className="mobile-collapse-list"
  >
    {children}
  </Collapse>
);
