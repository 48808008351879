import { defaultHeaders, defaultGlobals } from "redux-rest-resource";
import { refreshToken } from "./changeUserToken";
import { getAuthData, TENANT_STORAGE_KEY } from "../../helpers/authStorage";

const setResourceToken = (token) => {
  Object.assign(defaultHeaders, {
    Authorization: `Bearer ${token}`,
    Tenant: localStorage.getItem(TENANT_STORAGE_KEY),
  });
}; // function which add  to defaultHeaders Authorization bearer

window.setResourceToken = setResourceToken;
setResourceToken(getAuthData().accessToken);
// run setResourceToken with access_token

Object.assign(defaultGlobals, {
  fetch: (...args) =>
    fetch.apply(this, args).then((errorResponse) => {
      if (errorResponse.status === 401) {
        return refreshToken().then(() => fetch.apply(this, args));
      }
      return errorResponse;
    }),
});

export default setResourceToken;
