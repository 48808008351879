import { List } from "antd";
import React, { FC } from "react";
import JobCard from "./JobCard";
import "../styles.less";
import { ITrackingJobsList } from "../types";

export const JobList: FC<ITrackingJobsList> = ({ jobs, selectedJob, onJobSelected, isLoading }) => {
  return (
    <List
      pagination={{
        position: "bottom",
        size: "small",
      }}
      className="jobs-list-wrapper"
      dataSource={jobs}
      renderItem={(job: any, index: number) => {
        return (
          <JobCard
            key={job.id}
            job={job}
            onCardClick={onJobSelected}
            isSelectedJob={selectedJob.job && job.id === selectedJob.job.id}
          />
        );
      }}
      loading={isLoading}
      rowKey={(job) => job.id}
    />
  );
};
