import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";

import { ReactComponent as BeeboltFullLogo } from "../../../../assets/beeboltFullLogo.svg";
import { ReactComponent as BeeBoltLogo } from "../../../../assets/beeBoltLogo.svg";
import "./trackingInfoHeader.less";
import { FormattedMessage } from "react-intl";
import { SelectLanguage } from "../../../../components/SelectLanguage";

const { Header } = Layout;

const TrackingInfoHeader = ({ location }) => {
  const [currentMenu, setCurrentMenu] = useState("");
  // eslint-disable-next-line
  const [isHome, setIsHome] = useState(true);
  const [token, setToken] = useState("");

  useEffect(() => {
    const pathname = window.location.pathname.split("/");

    const selected = pathname[2];

    if (selected !== currentMenu) {
      setCurrentMenu(selected);
    }
  }, [currentMenu]);

  const handleClick = (e) => {
    setCurrentMenu(e.key);
  };

  useEffect(() => {
    const pathname = window.location.pathname.split("/");
    const selected = pathname[2];
    setToken(location?.search);
    if (selected === "faq") {
      setIsHome(false);
    } else {
      setIsHome(true);
    }
  }, [location]);

  const menu = (
    <Menu onClick={handleClick} selectedKeys={[currentMenu]} mode="horizontal" disabledOverflow>
      {/* <Menu.Item key="lang">
     
      </Menu.Item> */}
      <Menu.Item key="home">
        <Link to={`/tracking_info/home${token}`} className="menuItemStyle">
          <FormattedMessage id="page.home.header.home" defaultMessage="Home" />
        </Link>
      </Menu.Item>
      <Menu.Item key="faq">
        <Link to={`/tracking_info/faq${token}`} className="menuItemStyle">
          FAQ
        </Link>
      </Menu.Item>

      {/* <Menu.Item key="drivers">
        <Link to={`/tracking_info/drivers${location.search}`} className="menuItemStyle">
          For drivers
        </Link>
      </Menu.Item>
      <Menu.Item key="customers">
        <Link to={`/tracking_info/customers${location.search}`} className="menuItemStyle">
          For customers
        </Link>
      </Menu.Item>
      <Menu.Item key="packages">
        <Link to={`/tracking_info/packages${location.search}`} className="menuItemStyle">
          Tracking Packages
        </Link>
      </Menu.Item> */}
    </Menu>
  );

  return (
    <Header className="trackingInfoHeader">
      <div className="fullScreenHeader">
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <Link to="/">
            <BeeboltFullLogo width="100px" />
          </Link>
          <SelectLanguage />
        </div>
        {menu}
      </div>
      <div className="smallScreenHeader">
        <BeeBoltLogo width="100px"/>
        {isHome ? (
          <Link to={`/tracking_info/faq${token}`} className="menuItemStyle">
            FAQ
          </Link>
        ) : (
          <Link to={`/tracking_info/home${token}`} className="menuItemStyle">
            Home
          </Link>
        )}
      </div>
    </Header>
  );
};

export default TrackingInfoHeader;
