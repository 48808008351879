import React, { FC, useState } from "react";
import { CustomCard } from "src/utils/common";
import RetailersList from "./components/RetailersList";
import { actions as adminRetailersActions } from "../../redux/resources/adminRetailers";
import { connect, RootStateOrAny } from "react-redux";
import { bindActionCreators } from "redux";
import useHook from "./hooks/useHook";
import { IStoreManagement, ModalModeEnum } from "./types/store-management";
import AddRetailerModal from "./components/AddRetailerModal";
import { notification } from "antd";

const StoreManagement: FC<IStoreManagement> = ({
  fetchAdminRetailers,
  addOneAdminRetailers,
  retailers,
  pagination,
  isFetching,
}) => {
  // state
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  // hooks
  const { getRetailers, addRetailer } = useHook({ fetchAdminRetailers, addOneAdminRetailers });

  // methods
  const onDiscardAddModal = () => {
    setModalOpen(false);
  };
  const onOpenAddModal = () => {
    setModalOpen(true);
  };

  const onConfirmRetailerForm = async (values: any) => {
    try {
      await addRetailer(values);
      onDiscardAddModal();
      await getRetailers({}, { page: 1, limit: 10 });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.message ?? "Unexpected error has occured !",
      });
    }
  };

  return (
    <CustomCard withPaddings>
      <RetailersList
        data={retailers}
        getRetailers={getRetailers}
        pagination={pagination}
        isLoading={isFetching}
        onAddRetailer={onOpenAddModal}
      />
      <AddRetailerModal
        isOpen={isModalOpen}
        onDiscard={onDiscardAddModal}
        onConfirmRetailerForm={onConfirmRetailerForm}
        mode={ModalModeEnum.CREATE}
      />
    </CustomCard>
  );
};

const MSTP = (state: RootStateOrAny) => {
  return {
    isMobile: state.admin.isMobile,
    retailers: state.adminRetailers.items,
    pagination: state.adminRetailers.pagination,
    isFetching: state.adminRetailers.isFetching,
  };
};

export default connect(MSTP, (dispatch) => {
  const { fetchAdminRetailers, addOneAdminRetailers } = bindActionCreators(adminRetailersActions, dispatch);

  return {
    fetchAdminRetailers,
    addOneAdminRetailers,
  };
})(StoreManagement);
