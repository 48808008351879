import React, { useEffect } from "react";
import { Form, Input, Typography, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import GetStartedButton from "../GetStartedButton";
import { ReactComponent as EmailIcon } from "../../../../assets/newLanding/auth/emailIcon.svg";
import { ReactComponent as PasswordIcon } from "../../../../assets/newLanding/auth/passwordIcon.svg";
import styles from "./signUpForm.module.less";

const SignUpForm = ({ onSubmit, isMobile }) => {
  const [form] = Form.useForm();

  const intl = useIntl();

  useEffect(() => {
    form.isFieldsTouched() && form.validateFields();
    // eslint-disable-next-line
  }, [intl.locale]);

  return (
    <>
      <Typography.Title level={2} className={styles.signUpTitle}>
        <FormattedMessage id="page.signup.letsGetStarted" defaultMessage="Let's Get Started" /> <br />
        <Typography.Text className={styles.signUpSubtitle}>
          <FormattedMessage id="page.signup.itsFree" defaultMessage="It's Free" />
        </Typography.Text>
      </Typography.Title>

      <Form
        scrollToFirstError
        initialValues={{ tenantName: "Qatar" }}
        form={form}
        name="register"
        onFinish={onSubmit}
        className={styles.signUpForm}
        style={
          isMobile ? { flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" } : {}
        }
      >
        {isMobile ? (
          <div>
            {" "}
            <Form.Item
              name="email"
              className={styles.formItem}
              rules={[
                {
                  type: "email",
                  message: `${intl.formatMessage({ id: "error.invalidEmail", defaultMessage: "Email is not valid!" })}`,
                },
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: "error.inputEmail",
                    defaultMessage: "Please input your email!",
                  })}`,
                },
              ]}
            >
              <Input
                prefix={<EmailIcon />}
                placeholder={`${intl.formatMessage({ id: "input.email1", defaultMessage: "Email" })}`}
                className={styles.formInput}
              />
            </Form.Item>
            <Form.Item
              name="password"
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: "error.invalidPasword",
                    defaultMessage: "Please input your password!",
                  })}`,
                },
                {
                  pattern: /^.{6,}$/,
                  message: "Password should be at least 6 characters long",
                },
              ]}
            >
              <Input.Password
                prefix={<PasswordIcon />}
                placeholder={`${intl.formatMessage({ id: "input.password", defaultMessage: "Password" })}`}
                className={styles.formInput}
              />
            </Form.Item>
            <Form.Item
              name="passwordConfirmation"
              dependencies={["password"]}
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: "error.confirmPassword",
                    defaultMessage: "Please confirm your password!",
                  })}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    return !value || getFieldValue("password") === value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            `${intl.formatMessage({
                              id: "error.notMatchPasswords",
                              defaultMessage: "The two passwords that you entered do not match!",
                            })}`,
                          ),
                        );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<PasswordIcon />}
                placeholder={`${intl.formatMessage({
                  id: "input.confirmPassword",
                  defaultMessage: "Confirm password",
                })}`}
                className={styles.formInput}
              />
            </Form.Item>
            <Form.Item name="tenantName">
              <Select
                options={[
                  { value: "Qatar", label: "Qatar" },
                  { value: "Jordan", label: "Jordan" },
                  { value: "Saudi Arabia", label: "Saudi Arabia" },
                ]}
              />
            </Form.Item>
          </div>
        ) : (
          <>
            {" "}
            <Form.Item
              name="email"
              className={styles.formItem}
              rules={[
                {
                  type: "email",
                  message: `${intl.formatMessage({ id: "error.invalidEmail", defaultMessage: "Email is not valid!" })}`,
                },
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: "error.inputEmail",
                    defaultMessage: "Please input your email!",
                  })}`,
                },
              ]}
            >
              <Input
                prefix={<EmailIcon />}
                placeholder={`${intl.formatMessage({ id: "input.email1", defaultMessage: "Email" })}`}
                className={styles.formInput}
              />
            </Form.Item>
            <Form.Item
              name="password"
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: "error.invalidPasword",
                    defaultMessage: "Please input your password!",
                  })}`,
                },
                {
                  pattern: /^.{6,}$/,
                  message: "Password should be at least 6 characters long",
                },
              ]}
            >
              <Input.Password
                prefix={<PasswordIcon />}
                placeholder={`${intl.formatMessage({ id: "input.password", defaultMessage: "Password" })}`}
                className={styles.formInput}
              />
            </Form.Item>
            <Form.Item
              name="passwordConfirmation"
              dependencies={["password"]}
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: "error.confirmPassword",
                    defaultMessage: "Please confirm your password!",
                  })}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    return !value || getFieldValue("password") === value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            `${intl.formatMessage({
                              id: "error.notMatchPasswords",
                              defaultMessage: "The two passwords that you entered do not match!",
                            })}`,
                          ),
                        );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<PasswordIcon />}
                placeholder={`${intl.formatMessage({
                  id: "input.confirmPassword",
                  defaultMessage: "Confirm password",
                })}`}
                className={styles.formInput}
              />
            </Form.Item>
            <Form.Item name="tenantName">
              <Select
                options={[
                  { value: "Qatar", label: "Qatar" },
                  { value: "Jordan", label: "Jordan" },
                  { value: "Saudi Arabia", label: "Saudi Arabia" },
                ]}
              />
            </Form.Item>
          </>
        )}

        <Form.Item noStyle>
          <GetStartedButton htmlType="submit" className={styles.signUpButton} style={isMobile ? { width: "100%" } : {}}>
            <FormattedMessage id="page.login.signup" defaultMessage="Sign Up" />
          </GetStartedButton>
        </Form.Item>
      </Form>
    </>
  );
};

export default SignUpForm;
