import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData, userRole } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";
import { UsersRolesEnum } from "src/components/Ticket/type";

const isRetailer = userRole === UsersRolesEnum.RETAILER;
const API_ROLE = isRetailer ? "retailers" : "admin";

export const { types, actions, rootReducer } = createResource({
  name: "jobsForCombine",
  actions: {
    combine: {
      method: "POST",
      url: `${API_URL}/${API_ROLE}/jobs/combine`,
    },
    split: {
      method: "POST",
      url: `${API_URL}/${API_ROLE}/jobs/:jobId/split`,
    },
    clear: {
      isPure: true,
      reduce: (state, action) => {
        return {
          ...state,
          item: [],
        };
      },
    },
  },
});

setAuthHeader(getAuthData().accessToken);
