import { useState } from "react";

export const useHooks = ({
  deleteRecordAdminCustomers,
  deletePointDriversPoints,
  fetchDriversPoints,
  fetchLocationsChangeAdminCustomers,
  changeRetailerSMSrightsAdminRetailers,
}) => {
  const [selection, setSelection] = useState([]);
  const [isAllOrdersChecked, setAllOrdersChecked] = useState(false);
  // const fetchAll = () => {
  //   fetchDriversPoints();
  //   fetchLocationsChangeAdminCustomers();
  // };

  const deleteSelection = (tab) => (event) => {
    switch (tab) {
      case "locations":
        deleteRecordAdminCustomers({ ids: selection }).then(() => {
          fetchLocationsChangeAdminCustomers();
        });
        setSelection([]);
        setAllOrdersChecked(false);
        break;
      case "bad_locations":
        deleteRecordAdminCustomers({ ids: selection }).then(() => {
          fetchLocationsChangeAdminCustomers();
        });
        setSelection([]);
        setAllOrdersChecked(false);
        break;
      case "delivery":
        deletePointDriversPoints({ ids: selection }).then(() => {
          fetchDriversPoints();
        });
        setSelection([]);
        setAllOrdersChecked(false);
        break;
      default:
        setAllOrdersChecked(false);
        break;
    }
  };

  const handleToggleOne = (orderId) => (e) => {
    e.stopPropagation();

    if (selection.includes(orderId)) {
      setSelection(selection.filter((id) => id !== orderId));
    } else {
      setSelection([...selection, orderId]);
    }
  };
  const handleToggleMany = (e, list) => {
    if (e.target.checked) {
      setSelection(
        list.map(({ id, requests_id }) => {
          if (requests_id) {
            return requests_id;
          }
          return id;
        }),
      );
    } else {
      setSelection([]);
    }
  };

  const handleToggle = (notificationIds) => {
    setSelection(notificationIds);
  };

  const clearSelection = () => {
    setSelection([]);
  };

  const isSelected = (id) => {
    return selection.includes(id);
  };

  const onRevokeSMSRights = async (retailerId) => {
    await changeRetailerSMSrightsAdminRetailers({ retailerId, customMessagingTemplatesAllowed: false });
  };

  return {
    isSelected,
    selection,
    clearSelection,
    deleteSelection,
    handleToggle,
    handleToggleOne,
    handleToggleMany,
    isAllOrdersChecked,
    setAllOrdersChecked,
    onRevokeSMSRights,
  };
};
