import React, { FC } from "react";
import { Select, Button, notification } from "antd";
import { ClearOutlined, PrinterOutlined } from "@ant-design/icons";
import { MobileTitle } from "../../AdminPanel/Mobile/MobileTitle";
import { JobsTable } from "./components/JobsTable";
import { MobileLoader } from "../../AdminPanel/Mobile/MobileLoader";
import { useSockets } from "../hooks/useSockets";
import { useMobile } from "../hooks/useMobile";
import { MobileInputSearch } from "../../AdminPanel/Mobile/MobileInputSearch";
import { MobileCashAmountModal } from "./components/MobileCashAmountModal";
import { ConfirmActionModal } from "./components/OrdersCollapse/ConfirmModal";

import { IJobMobileViewMSTP } from "src/types/JobManagement";
import { connect, RootStateOrAny } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as deliveryJobActions } from "../../../redux/resources/deliveryJobs";
import { actions as retailerJobActions } from "../../../redux/resources/retailerJob";
import { actions as retailerOrderActions } from "../../../redux/resources/retailerJob";
import { actions as adminOrderActions } from "../../../redux/resources/adminOrders";
import { userRole } from "src/helpers/authStorage";
import { UsersRolesEnum } from "src/components/Ticket/type";

const isAdmin = !(userRole === UsersRolesEnum.RETAILER);

const MobileView: FC<any> = ({
  deliveryJobs,
  isFetchingDeliveryJobs,
  badDeliveryJobCount,
  centerCoordinates,
  selectedOrder,
  setSelectedOrder,
  handlePublishJob,
  downloadJobOrdersBarcodes,
  updateOrderCash,
  withErrors,
  setWithErrors,
  cleanAllJobs,
  printAllJobs,
  selectedJobs,
  onJobsSelected,
  // action
  fetchDeliveryJobs,
  getOneDeliveryJob,
  getRoutesDeliveryJob,
  postponeOrderDeliveryJob,
  cancelCompletedAdminOrder,
  deleteOrderDeliveryJob,
  forceNextOrderDeliveryJob,
  changeOrderPositionDeliveryJob,
  assignJobDeliveryJob,
  cancelAssignJobDeliveryJob,
  fetchAvailableDriversDeliveryJob,
  cancelOrderDeliveryJob,
}) => {
  const {
    loading,
    selectedJobId,
    innerCollapsed,
    cashAmountModalVisible,
    onJobSearch,
    onExpandJob,
    onOrderCollapse,
    onForceNextOrder,
    onDeleteOrder,
    onMoveRow,
    getPublishStatus,
    onOpenCashAmountModal,
    onCloseCashAmountModal,
    isJobChecked,
    onSelectJob,
    confirmOrdersAction,
    setConfirmOrdersAction,
    operationLoading,
    handlePostponeOrder,
    handleCancelCompleted,
  } = useMobile({
    deliveryJobs,
    selectedOrder,
    selectedJobs,
    handlePublishJob,
    setSelectedOrder,
    onJobsSelected,
    withErrors,

    fetchDeliveryJobs,
    getOneDeliveryJob,
    getRoutesDeliveryJob,
    postponeOrderDeliveryJob,
    cancelCompletedAdminOrder,
    deleteOrderDeliveryJob,
    forceNextOrderDeliveryJob,
    changeOrderPositionDeliveryJob,
  });

  useSockets({ withErrors, fetchDeliveryJobs, getRoutesDeliveryJob, expandedRowKeys: selectedJobId });

  return (
    <div className="mobile-page-wrapper mobile-page-wrapper--jobs-managment">
      {(isFetchingDeliveryJobs || loading) && <MobileLoader />}
      <MobileTitle title="Jobs Management" />
      <div style={{ width: "100%" }}>
        <Button
          className="mobile-btn mobile-btn--purple"
          style={{ marginBottom: "5px" }}
          icon={<ClearOutlined />}
          onClick={() => {
            selectedJobs?.length ? cleanAllJobs() : notification.warn({ message: "Please select jobs to clean !" });
          }}
        >
          Clean jobs
        </Button>
        <Button
          className="mobile-btn mobile-btn--blue"
          style={{ marginBottom: "5px" }}
          icon={<PrinterOutlined />}
          onClick={printAllJobs}
        >
          Print jobs
        </Button>
      </div>
      <Select value={withErrors} style={{ width: "100%" }} onChange={setWithErrors}>
        <Select.Option value={false}>Valid jobs</Select.Option>
        <Select.Option value={true}>Invalid jobs</Select.Option>
      </Select>
      <div style={{ margin: "5px auto", textAlign: "center", fontWeight: "bold" }}>
        Invalid jobs: {badDeliveryJobCount || "0"}
      </div>
      <MobileInputSearch placeholder="Search jobs by order tracking id" onSearch={onJobSearch} />
      <JobsTable
        deliveryJobs={deliveryJobs}
        innerCollapsed={innerCollapsed}
        centerCoordinates={centerCoordinates}
        fetchDeliveryJobs={fetchDeliveryJobs}
        onExpandJob={onExpandJob}
        onOrderCollapse={onOrderCollapse}
        getPublishStatus={getPublishStatus}
        onMoveRow={onMoveRow}
        onOpenCashAmountModal={onOpenCashAmountModal}
        onForceNextOrder={onForceNextOrder}
        onDeleteOrder={onDeleteOrder}
        downloadJobOrdersBarcodes={downloadJobOrdersBarcodes}
        postponeOrderDeliveryJob={setConfirmOrdersAction}
        getOneDeliveryJob={getOneDeliveryJob}
        isJobChecked={isJobChecked}
        onSelectJob={onSelectJob}
        assignJobDeliveryJob={assignJobDeliveryJob}
        cancelAssignJobDeliveryJob={cancelAssignJobDeliveryJob}
        fetchAvailableDriversDeliveryJob={fetchAvailableDriversDeliveryJob}
        cancelOrderDeliveryJob={cancelOrderDeliveryJob}
      />

      <MobileCashAmountModal
        visible={cashAmountModalVisible}
        updateOrderCash={updateOrderCash}
        closeModal={onCloseCashAmountModal}
        selectedJobId={selectedJobId}
      />

      <ConfirmActionModal
        openModal={confirmOrdersAction}
        handleModalSubmit={confirmOrdersAction.type === "postpone" ? handlePostponeOrder : handleCancelCompleted}
        setOpenModal={setConfirmOrdersAction}
        loading={operationLoading}
      />
    </div>
  );
};

export default connect(
  (state: RootStateOrAny, ownProps: IJobMobileViewMSTP) => {
    const {
      badDeliveryJobCount,
      centerCoordinates,
      selectedOrder,
      setSelectedOrder,
      handlePublishJob,
      downloadJobOrdersBarcodes,
      updateOrderCash,
      withErrors,
      setWithErrors,
      cleanAllJobs,
      printAllJobs,
      selectedJobs,
      onJobsSelected,
    } = ownProps;

    return {
      badDeliveryJobCount,
      centerCoordinates,
      selectedOrder,
      setSelectedOrder,
      handlePublishJob,
      downloadJobOrdersBarcodes,
      updateOrderCash,
      withErrors,
      setWithErrors,
      cleanAllJobs,
      printAllJobs,
      selectedJobs,
      onJobsSelected,

      deliveryJobs: isAdmin ? state.deliveryJobs.items : state.retailerJob.items,
      isFetchingDeliveryJobs: isAdmin ? state.deliveryJobs.isFetching : state.retailerJob.isFetching,
    };
  },
  (dispatch: any, ownProps: IJobMobileViewMSTP) => {
    const {
      fetchDeliveryJobs,
      deleteOrderDeliveryJob,
      getOneDeliveryJob,
      getRoutesDeliveryJob,
      changeOrderPositionDeliveryJob,
      forceNextOrderDeliveryJob,
      assignJobDeliveryJob,
      cancelAssignJobDeliveryJob,
      fetchAvailableDriversDeliveryJob,
      postponeOrderDeliveryJob,
      cancelOrderDeliveryJob,
    } = bindActionCreators(deliveryJobActions, dispatch);

    const {
      fetchRetailerJobs,
      deleteOrderRetailerJob,
      getOneRetailerJob,
      getRoutesRetailerJob,
      changeOrderPositionRetailerJob,
      forceNextOrderRetailerJob,
      assignJobRetailerJob,
      cancelAssignJobRetailerJob,
      fetchAvailableDriversRetailerJob,
      postponeOrderRetailerJob,
      cancelOrderRetailerJob,
    } = bindActionCreators(retailerJobActions, dispatch);

    const { cancelCompletedAdminOrder } = bindActionCreators(retailerOrderActions, dispatch);
    const { cancelCompletedRetailerOrder } = bindActionCreators(adminOrderActions, dispatch);

    return isAdmin
      ? {
          // Admin Job
          fetchDeliveryJobs,
          getOneDeliveryJob,
          getRoutesDeliveryJob,
          postponeOrderDeliveryJob,
          deleteOrderDeliveryJob,
          forceNextOrderDeliveryJob,
          changeOrderPositionDeliveryJob,
          assignJobDeliveryJob,
          cancelAssignJobDeliveryJob,
          fetchAvailableDriversDeliveryJob,
          cancelOrderDeliveryJob,
          cancelCompletedAdminOrder,
        }
      : {
          // Retailer Jobs
          fetchDeliveryJobs: fetchRetailerJobs,
          getOneDeliveryJob: getOneRetailerJob,
          getRoutesDeliveryJob: getRoutesRetailerJob,
          postponeOrderDeliveryJob: postponeOrderRetailerJob,
          deleteOrderDeliveryJob: deleteOrderRetailerJob,
          forceNextOrderDeliveryJob: forceNextOrderRetailerJob,
          changeOrderPositionDeliveryJob: changeOrderPositionRetailerJob,
          assignJobDeliveryJob: assignJobRetailerJob,
          cancelAssignJobDeliveryJob: cancelAssignJobRetailerJob,
          fetchAvailableDriversDeliveryJob: fetchAvailableDriversRetailerJob,
          cancelOrderDeliveryJob: cancelOrderRetailerJob,
          cancelCompletedAdminOrder: cancelCompletedRetailerOrder,
        };
  },
)(MobileView);
