import { Breadcrumb } from "antd";
import React, { FC } from "react";
import { RootStateOrAny, connect } from "react-redux";
import { Link } from "react-router-dom";
import CombineJobsRouting from "src/components/CombineJobs/CombineJobsRouting";
import { isRetailer } from "src/helpers/authStorage";
import { BASE_ROUTE } from "src/routes";

const CombineJobs: FC<any> = () => {
  return <CombineJobsRouting />;
};

const MSTP = (state: RootStateOrAny) => {
  return {};
};

export default connect(MSTP, (dispatch) => ({}))(CombineJobs);
