import React, { useState } from "react";
import { Drawer, List, Divider, Input, Button } from "antd";
import GoogleMapReact from "google-map-react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Geocode from "react-geocode";

import { API_KEY } from "../../../../constants/config";
import PointMarker from "../../../RetailerSettings/RetailerPoints/components/PointMarker";
import { ReactComponent as WarehouseCircleIcon } from "../assets/WarehouseCircle.svg";
import { WarehouseIconWrap } from "../..";
import errorLogger from "../../../../utils/errorLogging/error-logger";

Geocode.setApiKey(API_KEY);

function WarehouseDrawer({
  data,
  isMobile,
  drawerVisible,
  setDrawerVisible,
  handleAddWarehouse,
  handleDeleteWarehouse,
  handleUpdateWarehouse,
}) {
  const [newWarehouse, setNewWarehouse] = useState({ lat: 0, long: 0 });
  const [currentZoom, setCurrentZoom] = useState(0);

  const handleNameChange = (value) => {
    setNewWarehouse((prev) => ({ ...prev, name: value }));
  };

  const handleAddressChange = (value) => {
    setNewWarehouse((prev) => ({ ...prev, address: value }));
  };

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(newWarehouse.address);
    const latLng = await getLatLng(result[0]);
    setNewWarehouse((prev) => {
      return { ...prev, address: result[0].formatted_address, lat: latLng.lat, long: latLng.lng };
    });
  };

  const handleMapSelect = (loc) => {
    Geocode.fromLatLng(loc.lat, loc.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setNewWarehouse((prev) => {
          return { ...prev, address: address, lat: loc.lat, long: loc.lng };
        });
      },
      (error) => {
        errorLogger.report(`Map error ${error}`);
      },
    );
  };

  return (
    <Drawer width={"80vw"} placement="right" closable onClose={() => setDrawerVisible(false)} visible={drawerVisible}>
      <h2>Warehouse Management</h2>
      <Divider />
      <div className="mapWrapper">
        <GoogleMapReact
          onClick={({ x, y, lat, lng, event }) => {
            handleMapSelect({ lat, lng });
          }}
          bootstrapURLKeys={{ key: API_KEY }}
          defaultZoom={0}
          zoom={currentZoom}
          center={{
            lat: newWarehouse.lat,
            lng: newWarehouse.long,
          }}
          yesIWantToUseGoogleMapApiInternals
        >
          <PointMarker lat={newWarehouse.lat} lng={newWarehouse.long} />
          {data?.length > 0
            ? data.map((e, i) => {
                return (
                  <WarehouseIconWrap
                    key={e.id}
                    text={e.name}
                    // onContextMenu={() => handleDeleteWarehouse(e)}
                    lat={e.lat}
                    lng={e.long}
                    isMobile={isMobile}
                  />
                );
              })
            : null}
        </GoogleMapReact>
      </div>
      <Divider />
      <Input.Group compact className="">
        <Input value={newWarehouse.name} placeholder="Name" onChange={(e) => handleNameChange(e.target.value)} />
        <Input.Search
          placeholder="Address"
          enterButton
          value={newWarehouse.address}
          onSearch={handleSelect}
          onEnter={handleSelect}
          onChange={(e) => handleAddressChange(e.target.value)}
        />
      </Input.Group>
      <Button danger onClick={() => setNewWarehouse({ lat: 0, long: 0 })}>
        Clear
      </Button>
      <Button onClick={() => handleAddWarehouse(newWarehouse)}>Save</Button>

      <Divider />
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                onClick={() => {
                  handleMapSelect({ lat: parseFloat(item.lat), lng: parseFloat(item.long) });
                  setCurrentZoom(0);
                }}
              >
                Show
              </Button>,
              <Button
                danger
                onClick={() => {
                  handleDeleteWarehouse(item);
                }}
              >
                Delete
              </Button>,
            ]}
          >
            <List.Item.Meta avatar={<WarehouseCircleIcon />} title={item.name} description={item.address} />
          </List.Item>
        )}
      />
    </Drawer>
  );
}

export default WarehouseDrawer;
// GoogleApiWrapper({
//   apiKey: API_KEY,
// })(WarehouseDrawer);
