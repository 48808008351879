import React, { useEffect } from "react";
import { Form, Input } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import StepActions from "../../../../components/DataEntry/StepActions";
import { getDataLocalStorageAndSetData, getPhoneInputCountryCode } from "../../../../helpers/general";
import "./mainPointOfContact.less";

const MainPointOfContact = ({ saveInformationRetailer, currentStep, onPrevStep, stepsLength, retailerInfo }) => {
  const [form] = Form.useForm();
  const intl = useIntl();

  useEffect(() => {
    const retailerMainPointInfo = JSON.parse(localStorage.getItem("retailerMainPointInfo"));
    getDataLocalStorageAndSetData(retailerMainPointInfo, "firstName", form);
    getDataLocalStorageAndSetData(retailerMainPointInfo, "lastName", form);
    getDataLocalStorageAndSetData(retailerMainPointInfo, "phoneNumber", form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    saveInformationRetailer({ pointOfContact: { ...values } });
  };

  const handleChangePhone = (value) => {
    form.setFieldsValue({
      phoneNumber: value,
    });
  };

  return (
    <>
      <h3 className="driverInfoTitle">
        <FormattedMessage
          id="page.step.mainPoint.title"
          defaultMessage="Main point of contact in charge of deliveries"
        />
      </h3>
      <p className="driverInfoDescription">
        <FormattedMessage
          id="page.step.mainPoint.subtitle"
          defaultMessage="This will be the person, from your end, overseeing the deliveries. They will be communicating with Dliv, and handling customer inquires relating to your products we deliver."
        />
      </p>
      <Form
        form={form}
        name="retailerPointOfContact"
        layout="vertical"
        onFinish={onFinish}
        hideRequiredMark
        className="formStyle"
        onValuesChange={(changedValues, allValues) => {
          localStorage.setItem("retailerMainPointInfo", JSON.stringify(allValues));
        }}
      >
        <Form.Item
          className="personalInfoItem"
          name="firstName"
          label={`${intl.formatMessage({ id: "input.firstName", defaultMessage: "First Name" })}`}
          key="firstName"
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({ id: "error.inputName", defaultMessage: "Input name!" })}`,
            },
            {
              max: 255,
              message: `${intl.formatMessage({
                id: "error.maxLength",
                defaultMessage: "Maximum length is 255 characters",
              })}`,
            },
          ]}
          initialValue={retailerInfo?.pointOfContact ? retailerInfo.pointOfContact.firstName : ""}
        >
          <Input placeholder={`${intl.formatMessage({ id: "input.name", defaultMessage: "Name" })}`} />
        </Form.Item>
        <Form.Item
          className="personalInfoItem"
          name="lastName"
          label={`${intl.formatMessage({ id: "input.lastName", defaultMessage: "Last Name" })}`}
          key="lastName"
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({ id: "error.inputName", defaultMessage: "Input name!" })}`,
            },
            {
              max: 255,
              message: `${intl.formatMessage({
                id: "error.maxLength",
                defaultMessage: "Maximum length is 255 characters",
              })}`,
            },
          ]}
          initialValue={retailerInfo?.pointOfContact ? retailerInfo.pointOfContact.lastName : ""}
        >
          <Input placeholder={`${intl.formatMessage({ id: "input.surname", defaultMessage: "Surname" })}`} />
        </Form.Item>
        <Form.Item
          className="personalInfoItem"
          name="phoneNumber"
          label={`${intl.formatMessage({ id: "phoneNumber", defaultMessage: "Phone number" })}`}
          key="phone_number"
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({ id: "error.inputPhone", defaultMessage: "Input phone number!" })}`,
            },
            {
              min: 4,
              message: `${intl.formatMessage({
                id: "error.minLength",
                defaultMessage: "Minimum length is 4 charters",
              })}`,
            },
            {
              max: 255,
              message: `${intl.formatMessage({
                id: "error.maxLength",
                defaultMessage: "Maximum length is 255 characters",
              })}`,
            },
          ]}
          initialValue={retailerInfo?.pointOfContact ? retailerInfo.pointOfContact.phoneNumber : ""}
        >
          <ReactPhoneInput
            defaultMask={".... .... ...."}
            country={getPhoneInputCountryCode()}
            placeholder={`${intl.formatMessage({ id: "input.phone", defaultMessage: "Phone" })}`}
            value={form.getFieldValue("phoneNumber")}
            onChange={handleChangePhone}
          />
        </Form.Item>
        <StepActions currentStep={currentStep} onPrevStep={onPrevStep} stepsLength={stepsLength} />
      </Form>
    </>
  );
};

export default MainPointOfContact;
