import React from "react";
import { Button, Table } from "antd";

const UsersTable = ({ intl, deleteUser, paginationConfig, getUsers, users, loading, onChangeTable }) => {
  const columns = [
    {
      title: `${intl.formatMessage({ id: "name", defaultMessage: "Username" })}`,
      dataIndex: "name",
      key: "name",
    },
    {
      title:  `${intl.formatMessage({ id: "email", defaultMessage: "Email" })}`,
      dataIndex: "email",
      key: "email",
    },
    {
      title:  `${intl.formatMessage({ id: "tabPane.actions.delete", defaultMessage: "Delete" })}`,
      render: (record) => (
        <Button danger onClick={() => deleteUser(record.id)}>
          {`${intl.formatMessage({ id: "tabPane.actions.delete", defaultMessage: "Delete" })}`}
        </Button>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      className="users-table"
      rowKey={(record) => record.id}
      dataSource={users}
      loading={loading}
      onChange={onChangeTable}
      pagination={paginationConfig}
    />
  );
};

export default UsersTable;


