import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "activityLogs",
  url: `${API_URL}/retailers/activity`,

  actions: {
    fetch: {
      transformResponse: (res) => ({ ...res, body: res.body }),
    },
    getOrderHistory: {
      method: "GET",
      url: `${API_URL}/retailers/activity/:orderId`,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
