import axios from "axios";
import { defaultHeaders } from "redux-rest-resource";
import { API_URL, BEEBOLT_COMPANY_ID } from "../constants/config";
import { UsersRolesEnum } from "../types/authStorage";

const AUTH_STORAGE_KEY = "authData";
export const TENANT_STORAGE_KEY = "tenantSelected";

export const ACCESS_TYPE = "";

export const getAuthData = () => JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY) || "{}");

export const userRole = getAuthData().access;

export const saveAuthData = (tokens, role) => {
  const { accessToken, refreshToken, companyId } = tokens;
  let access = tokens.role;
  localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify({ accessToken, refreshToken, role, access, companyId }));
};

export const getUserTenant = () => {
  return localStorage.getItem(TENANT_STORAGE_KEY);
};
export const setUserTenant = (value) => {
  localStorage.setItem(TENANT_STORAGE_KEY, value);
  const { accessToken } = getAuthData();
  setAuthHeader(accessToken, false, value);
};

export const clearAuthData = () => localStorage.removeItem(AUTH_STORAGE_KEY);

export const setAuthHeader = (accessToken, defaultHeadersArg, tenant) => {
  Object.assign(defaultHeaders, {
    Authorization: `Bearer ${accessToken}`,
    Tenant: tenant || getUserTenant(),
  });
};

const refreshTokenPath = () => {
  const userRole = getAuthData().role;
  if (userRole === "driver") {
    return `${API_URL}/auth/drivers/refresh-access-token`;
  }
  if (userRole === "retailer") {
    return `${API_URL}/auth/retailers/refresh-access-token`;
  }
  if (userRole === "customer") {
    return `${API_URL}/customers/auth/refresh-access-token`;
  }
  if (userRole === "admin") {
    return `${API_URL}/customers/auth/refresh-access-token`;
  }

  // userRole ==='driver' ? `${API_URL}/auth/drivers/refresh-access-token` : `${API_URL}/auth/retailer/refresh-access-token`;
};

export const updateRefreshToken = () => {
  const refreshKey = getAuthData().refreshToken;
  const userRole = getAuthData().role;
  const url = refreshTokenPath();
  return axios
    .post(url, {
      refreshToken: refreshKey,
    })
    .then((res) => {
      saveAuthData(res.data.tokens, userRole);
      Object.assign(defaultHeaders, {
        Authorization: `Bearer ${res.data.tokens.accessToken}`,
        Tenant: getUserTenant(),
      });
    });
};

export const isLogin = () => {
  const { accessToken, role } = getAuthData();
  return !!accessToken && role === "admin" && role.length;
};

export const isMainCompany = () => {
  const { companyId } = getAuthData();
  return !companyId || companyId === BEEBOLT_COMPANY_ID;
};
export const redirectOnLogin = (isDriver, history) => (res) => {
  if (isDriver && res.body.driver.status !== "accepted") {
    localStorage.removeItem("driverPersonalInfo");
    localStorage.removeItem("driverCarInfo");
    localStorage.removeItem("DriverRegistrationStep");

    return (document.location.href = "/driver_registration");
  }

  if (isDriver && res.body.driver.status === "accepted") {
    return (document.location.href = "/driver_cabinet");
  }

  if (res.body.retailer.status === "accepted") {
    return (document.location.href = "/retailer_cabinet/home");
  }

  localStorage.removeItem("addressAndInstructionsInfo");
  localStorage.removeItem("customerSupportInfo");
  localStorage.removeItem("retailerMainPointInfo");
  localStorage.removeItem("retailerInfo");
  localStorage.removeItem("RetailerRegistrationStep");

  return history.push("/retailer_registration");
};

export const isRetailer = userRole === UsersRolesEnum.RETAILER;
export const API_ROLE = isRetailer ? "retailers" : "admin";
