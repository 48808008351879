import { createResource } from "redux-rest-resource";
import { setAuthHeader, clearAuthData, getAuthData, saveAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";
import setResourceToken from "./config";

const withSaveTokens = (res) => {
  saveAuthData(res.body.tokens, "retailer");
  setResourceToken(res.body.tokens.accessToken);
  return {
    res,
    body: res.body,
  };
};

export const { types, actions, rootReducer } = createResource({
  name: "retailer",
  url: `${API_URL}/auth/retailers/registration`,

  actions: {
    signup: {
      // headers: { tenant: localStorage.getItem(TENANT_STORAGE_KEY) },
      method: "POST",
    },
    confirmEmail: {
      method: "GET",
      url: `${API_URL}/auth/retailers/registration/confirm-email`,
      transformResponse: withSaveTokens,
    },
    getSelfInfo: {
      method: "GET",
      url: `${API_URL}/retailers/self`,
      reduce: (state, action) => {
        if (action.status !== "resolved") {
          return state;
        }
        return {
          ...state,
          apiToken: action.body.apiToken,
          retailerData: action.body,
        };
      },
    },
    putSelfInfo: {
      method: "PUT",
      url: `${API_URL}/retailers/self`,
      reduce: (state, action) => {
        const updatedRetailer = { ...state.items, ...action.context };
        return { ...state, items: updatedRetailer };
      },
    },
    getPickUpPoints: {
      method: "GET",
      url: `${API_URL}/retailers/self/pickup-points`,
      transformResponse: ({ body }) => ({ body }),
      // reduce: (state, action) => {
      //   const updatedRetailer = { ...state.items, ...action.context };
      //   return { ...state, items: updatedRetailer };
      // },
    },

    pickUpPoints: {
      method: "POST",
      url: `${API_URL}/retailers/self/pickup-points`,
      reduce: (state, action) => {
        const updatedRetailer = { ...state.items, ...action.context };
        return { ...state, items: updatedRetailer };
      },
    },

    deletePickUpPoint: {
      method: "DELETE",
      url: `${API_URL}/retailers/self/pickup-points/:id`,
    },

    updatePickUpPoint: {
      method: "PUT",
      url: `${API_URL}/retailers/self/pickup-points/:id`,
    },

    login: {
      method: "POST",
      url: `${API_URL}/auth/retailers/login`,
      // headers: { tenant: localStorage.getItem(TENANT_STORAGE_KEY) },
      transformResponse: (res) => {
        return withSaveTokens(res);
      },
      reduce: (state, action) => {
        if (action.res?.code === 200) {
          return {
            ...state,
            items: action.body.retailer,
          };
        }
        return state;
      },
    },

    logout: {
      isPure: true,
      reduce: (state) => {
        clearAuthData();
        setAuthHeader(null);

        return {
          ...state,
          item: null,
        };
      },
    },

    forgotPassword: {
      method: "POST",
      url: `${API_URL}/auth/retailers/forgot-password`,
    },

    recoverPassword: {
      method: "POST",
      url: `${API_URL}/auth/retailers/recover-password`,
    },
    getUsers: {
      method: "GET",
      url: `${API_URL}/retailers/users`,
    },
    createUser: {
      method: "POST",
      url: `${API_URL}/retailers/users`,
    },
    deleteUser: {
      method: "DELETE",
      url: `${API_URL}/retailers/users/:id`,
    },
    confirmSelf: {
      method: "POST",
      url: `${API_URL}/auth/retailers/subusers/confirm-email`,
    },

    getLastPendingUpdateSelf: {
      method: "GET",
      url: `${API_URL}/retailers/self/info/last-pending-update`,
    },
    getUpdatesSelf: {
      method: "GET",
      url: `${API_URL}/retailers/self/info/updates`,
    },
    updateSelf: {
      // headers: { "Content-Type": "multipart/form-data" },
      method: "PUT",
      url: `${API_URL}/retailers/self/info`,
    },
    cancelUpdateSelf: {
      method: "DELETE",
      url: `${API_URL}/retailers/self/info/updates/:id`,
    },

    getOwnSMSGroups: {
      method: "GET",
      url: `${API_URL}/retailers/self/messaging/templates`,
    },
    createOwnSMSGroup: {
      method: "POST",
      url: `${API_URL}/retailers/self/messaging/templates`,
    },
    getOwnSMSTemplates: {
      method: "GET",
      url: `${API_URL}/retailers/self/messaging/templates/:groupId`,
    },
    saveOneSMSTemplate: {
      method: "PUT",
      url: `${API_URL}/retailers/self/messaging/templates/:groupId/message/:messageType`,
    },
    saveSMSTemplateGroup: {
      method: "PUT",
      url: `${API_URL}/retailers/self/messaging/templates/:groupId`,
    },
    deleteSMSTemplateGroup: {
      method: "DELETE",
      url: `${API_URL}/retailers/self/messaging/templates/:groupId`,
    },
    subscribeFirebase: {
      method: "POST",
      url: `${API_URL}/retailers/notifications/subscribe`,
    },
    unsubscribeFirebase: {
      method: "POST",
      url: `${API_URL}/retailers/notifications/unsubscribe `,
    },
    testNotifications: {
      method: "GET",
      url: `${API_URL}/retailers/notifications `,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
