import React from "react";
import { Badge } from "antd";
import { omit, isEmpty } from "lodash";
import { getUserTenant } from "./authStorage";
import randomColor from "randomcolor";

export const getQueryParam = (locationSearch) => {
  if (!locationSearch) {
    return {};
  }
  return locationSearch
    .substr(1)
    .split("&")
    .reduce((queryObject, keyValuePair) => {
      const [key, value] = keyValuePair.split("=");
      queryObject[key] = value;
      return queryObject;
    }, {});
};

const getQuery = (queryParams) => {
  return Object.keys(queryParams)
    .filter(
      (key) =>
        (queryParams[key] && (queryParams[key].length === undefined || queryParams[key].length > 0)) ||
        queryParams[key] === 0,
    )
    .map((key) => `${key}=${queryParams[key] instanceof Array ? queryParams[key].join(",") : queryParams[key]}`)
    .join("&");
};

export const updatePageUrl = (newQueryParams, previousQueryParams, history, url) => {
  const queryParams = {
    ...previousQueryParams,
    ...newQueryParams,
  };
  const query = getQuery(queryParams);
  const checkNewQuery = omit(newQueryParams, ["page", "limit", "locationShared", "retailerName", "type"]);

  if (isEmpty(checkNewQuery) && newQueryParams.page === undefined) {
    history.push(`/${url}`);
  } else {
    history.push(`/${url}?${query}`);
  }
};

export const getStatusBage = (data) => {
  if (data === "accepted" || data === "ready") {
    return (
      <span>
        <Badge status="success" />
        {data}
      </span>
    );
  }
  if (data === "declined") {
    return (
      <span>
        <Badge status="error" />
        {data}
      </span>
    );
  }
  if (data === "pending") {
    return (
      <span>
        <Badge status="processing" />
        {data}
      </span>
    );
  }
  if (data === "incomplete") {
    return (
      <span>
        <Badge status="processing" />
        {data}
      </span>
    );
  }
  if (data === "processing") {
    return (
      <span>
        <Badge status="processing" />
        {data}
      </span>
    );
  }
  return <span>{data}</span>;
};

export const replaceAt = (str, index, replacement) => {
  if (index >= str.length) return this.valueOf();
  const chars = str.split("");
  chars[index] = replacement;
  return chars.join("");
};

export const arrayMove = (arr, oldIndex, newIndex) => {
  while (oldIndex < 0) {
    oldIndex += arr.length;
  }
  while (newIndex < 0) {
    newIndex += arr.length;
  }
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    // eslint-disable-next-line no-plusplus
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr; // for testing purposes
};

export const getDataLocalStorageAndSetData = (source, field, store) => {
  const fieldName = store.getFieldValue(field);
  if (!fieldName && source?.[field]) {
    store.setFieldsValue({
      [field]: source[field],
    });
  }
};

export const getPhoneInputCountryCode = () => {
  const tenant = getUserTenant();
  if (tenant) {
    return tenant.toLowerCase().slice(0, 2);
  }
  return "qa";
};

export const getRandomColor = () => randomColor();
