import React from "react";
import { Modal } from "antd";

import { ReactComponent as CloseModal } from "../../../../assets/adminPanel/close-note-modal.svg";

import "./styles.less";

export const MobileModalWrapper = ({ children, visible, title, footer, onClose, afterClose }) => {
  return (
    <Modal
      visible={visible}
      closeIcon={<CloseModal />}
      onCancel={onClose}
      title={title}
      footer={footer}
      afterClose={afterClose}
      className="mobile-modal"
    >
      {children}
    </Modal>
  );
};
