import React from "react";
import "./style.less";
import { Button, Table, Row, Input ,Select} from "antd";
import { UserAddOutlined } from '@ant-design/icons';
import tableColumns from "./columns";
import ROLES from "../../../constants/roles";
import { CustomCard } from "src/utils/common";
import { EmptyTableIndicator } from "src/components";
import { DesktopViewProps } from "../types";

const { Option } = Select;

const DesktopView : React.FC<DesktopViewProps> = ({
  userList,
  handleFetchUsers,
  handleDeleteUser,
  totalPages,
  handleChangeRole,
  tenants,
  onOpenCreateUserModal,
  searchedValue,
  setSearchedValue,
  handleSearchKeyPress,
  searchFilter,
  setSearchFilter,
}) => {
  const selectSearchFilter = (
    <Select defaultValue={searchFilter} className="select-before" onChange={setSearchFilter}>
      <Option value="email">Email</Option>
      <Option value="userName">Name</Option>
    </Select>
  );

  const onSearchInputChange = (e :  React.ChangeEvent<HTMLInputElement>) => {
    setSearchedValue(e.target.value);
  }


  return (
    <>
      <div className="page-wrapper-users users-page-custom-class">
        <CustomCard
          title="Users Management"
          toolbar={
            <Row>
              <Input
                placeholder="Search"
                addonBefore={selectSearchFilter}
                onPressEnter={handleSearchKeyPress}
                className="search-user"
                value={searchedValue || ''}
                onChange={onSearchInputChange}
              />

              <Button icon={<UserAddOutlined />} type="primary" onClick={onOpenCreateUserModal}>
                Add New User
              </Button>
            </Row>
          }
        > 
          {
            !!userList?.length &&
            <div className="scrollable-table">
              <Table
                className="table-style"
                rowKey={(user) => user.id}
                columns={tableColumns({
                  roles : Object.keys(ROLES),
                  changeRole :  handleChangeRole,
                  handleDeleteUser,
                  tenants
                })}
                scroll={{ x:true }}
                dataSource={userList}
                rowClassName="user-table-row-style"
                pagination={{ onChange: (num:number) => handleFetchUsers(num), total: totalPages }}
                locale={{
                  emptyText: <EmptyTableIndicator title="No users found" description="Please add users to expand your team" />
                }}
              />
            </div>
          }
        </CustomCard>
      </div>
    </>
  );
}

export default DesktopView;
