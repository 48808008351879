import React from "react";

const MobileMenu = (props) => (
  <svg {...props} width="24" height="26" viewBox="0 0 26 26" fill="current" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 23H31" stroke="#E9E9E9" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1 9H31" stroke="#E9E9E9" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1 16H31" stroke="#E9E9E9" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export default MobileMenu;
