import React from "react";
import { useHistory } from "react-router-dom";
import { Card } from "antd";

const StatsCard = ({ title, stats, path }) => {
  const history = useHistory();
  return (
    
    <div style={{ width: '20%', margin: '1%', float: 'left' }}>
    <Card
      style={{
        borderRadius: "10px",
        backgroundColor: "#e6f4ff",
      }}
      title={title}
      onClick={() => history.push(path)}
    >
      <h2>{stats}</h2>
    </Card>
    </div>
  );
};

export default React.memo(StatsCard);
