import { DatePicker, Input, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
// import { isArray } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";

import "./style.less";

const { Option } = Select;

export default function LogsArea({
  children,
  logsArray,
  updateLogs,
  isMobile,
  loadMoreLogs,
  logsPagination,
  dateRange,
  setDateRange,
}) {
  const [isStretched, setStretched] = useState(isMobile);
  const [searchQuery, setSearchQuery] = useState("");
  const [queryType, setQueryType] = useState("action");

  const logs = logsArray.map((e) => {
    if (e.userType === "super_admin") {
      e.name = "SuperAdmin";
    } else {
      e.name =
        e.user?.userName?.concat("(", e.userType, ")") ||
        e.user?.email?.concat("(", e.userType, ")") ||
        e.user?.id?.concat("(", e.userType, ")") ||
        e.userType;
    }
    return e;
  });

  const handleUpdate = () => {
    updateLogs(searchQuery, queryType, dateRange[0], dateRange[1]);
  };
  const handleUpdateDefault = () => {
    setSearchQuery("");
    setQueryType("action");
    setDateRange([moment().startOf("year"), moment()]);
    updateLogs();
  };
  const handleResize = () => {
    setStretched((previous) => !previous);
  };

  useEffect(() => {
    updateLogs(searchQuery, queryType, dateRange[0], dateRange[1]);
    // eslint-disable-next-line
  }, [dateRange[0], dateRange[1]]);

  const today = new Date().toISOString().split("T")[0];
  const yesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);

  const todayLogs = logs.filter((obj) => {
    const date = new Date(obj.createdAt).toISOString().split("T")[0];
    return today === date;
  });

  const yesterdayLogs = logs.filter((obj) => {
    const date = new Date(obj.createdAt).toISOString().split("T")[0];
    return date === yesterday.toISOString().split("T")[0];
  });

  const previousLogs = logs.filter((obj) => {
    const date = new Date(obj.createdAt).toISOString().split("T")[0];
    return date !== today && date !== yesterday;
  });

  return (
    <div className={isStretched ? "LogsArea stretched" : "LogsArea"}>
      <div className="LogsArea__ButtonPanel">
        <button className="LogsButton" onClick={searchQuery ? handleUpdateDefault : handleUpdate}>
          {searchQuery ? "Clear" : "Update"}
        </button>
        <button className="LogsButton" onClick={handleResize}>
          Resize
        </button>
        {children}
      </div>
      <div id="LogsTextArea" className="LogsArea__Textfield">
        <InfiniteScroll
          scrollableTarget="LogsTextArea"
          dataLength={logs.length}
          next={() => loadMoreLogs(searchQuery, queryType, dateRange[0], dateRange[1])}
          hasMore={logsPagination.currentPage !== logsPagination.totalPages}
          loader={<h4>Loading...</h4>}
          style={{ height: "100%", overflow: "initial" }}
        >
          <Input.Group style={{ display: "flex", flexWrap: isStretched ? "nowrap" : "wrap" }} compact>
            <Select
              value={queryType}
              style={{ maxWidth: "50%", width: isStretched ? "auto" : "50%", minWidth: "100px" }}
              onChange={setQueryType}
            >
              <Option value="action">Action</Option>
              <Option value="target">Action target</Option>
              <Option value="user_type">User role</Option>
              <Option value="user">User</Option>
            </Select>
            <Input.Search
              style={{ order: isStretched ? 0 : -1, width: isStretched ? "auto" : "100%" }}
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onSearch={() => {
                updateLogs(searchQuery, queryType, dateRange[0], dateRange[1]);
              }}
            />
            <DatePicker.RangePicker showTime style={{ maxWidth: "50%" }} value={dateRange} onChange={setDateRange} />
          </Input.Group>

          <LogArray
            title="Today"
            sortedLogs={todayLogs}
            loadMoreLogs={() => loadMoreLogs(searchQuery, queryType, dateRange[0], dateRange[1])}
            logsPagination={logsPagination}
          />
          <LogArray
            title="Yesterday"
            sortedLogs={yesterdayLogs}
            loadMoreLogs={() => loadMoreLogs(searchQuery, queryType, dateRange[0], dateRange[1])}
            logsPagination={logsPagination}
          />
          <LogArray
            title="Old"
            sortedLogs={previousLogs}
            loadMoreLogs={() => loadMoreLogs(searchQuery, queryType, dateRange[0], dateRange[1])}
            logsPagination={logsPagination}
            showFullDate
          />
        </InfiniteScroll>
      </div>
    </div>
  );
}

const getTimezoneTime = (dateInput) => {
  const date = new Date(dateInput);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
};

const LogArray = ({ title, sortedLogs, showFullDate }) => {
  return (
    <>
      <b className="logDateTitle">{title}</b>
      {sortedLogs.length > 0 ? (
        sortedLogs.map((e) => (
          <div
            key={e.id}
            className="logsLine"
            style={{ backgroundColor: e.isError ? "rgba(254,0,0,0.3)" : "transparent" }}
          >
            <span>{e.name}</span> &gt; <span>{e.target}</span>
            &gt; <span>{e.action}</span>{" "}
            {e.isError ? (
              <>
                &gt; <span>{"Error: " + e.error}</span>
              </>
            ) : null}{" "}
            &gt;
            <span>
              {(showFullDate ? new Date(getTimezoneTime(e.createdAt)).toISOString().split("T")[0] + " " : "") +
                "Time: " +
                new Date(getTimezoneTime(e.createdAt)).toISOString().split("T")[1].split(".")[0]}
            </span>
          </div>
        ))
      ) : (
        <div className="logsLine" style={{ backgroundColor: "transparent" }}>
          <span>No logs</span>
        </div>
      )}
    </>
  );
};
