import React from "react";
import GoogleMapReact, { fitBounds } from "google-map-react";
import { map, filter } from "lodash";

import { API_KEY } from "../../constants/config";
import pdfData from "../../mock/pdf.json";
import "./reportPdf.less";

const getBounds = (coordinates) => {
  const latArr = coordinates.map(({ lat }) => lat);
  const lngArr = coordinates.map(({ lng }) => lng);

  const minLat = Math.min(...latArr);
  const minLng = Math.min(...lngArr);
  const maxLat = Math.max(...latArr);
  const maxLng = Math.max(...lngArr);

  return {
    nw: {
      lat: maxLat,
      lng: minLng,
    },
    se: {
      lat: minLat,
      lng: maxLng,
    },
  };
};

function ReportPdf() {
  const { date, mapData, mapType } = window?.reportData || pdfData;

  const filteredCoordinates = filter(mapData, ({ lat, long }) => lat && long);

  const coordinates = map(filteredCoordinates, ({ lat, long }, id) => ({
    lat: +lat,
    lng: +long,
    id,
  }));

  const { center, zoom } = fitBounds(getBounds(coordinates), {
    width: 680,
    height: 744,
  });

  const mapProps =
    mapType === "heatmap"
      ? {
          heatmapLibrary: true,
          heatmap: {
            positions: coordinates,
            options: {
              opacity: 0.6,
              radius: 40,
            },
          },
        }
      : {
          children: coordinates?.map(({ id, lat, lng }) => {
            return <span className="reportPdf__Map__marker" lat={lat} lng={lng} key={id} />;
          }),
        };

  return (
    <div className="reportPdf">
      <header className="reportPdf__header">
        <span className="reportPdf__header__title">Report date</span>
        <span className="reportPdf__header__date">{date}</span>
      </header>
      <main className="reportPdf__content">
        <div className="reportPdf__Map" style={{}}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: API_KEY }}
            defaultZoom={zoom}
            distanceToMouse={() => {}}
            defaultCenter={center}
            {...mapProps}
          />
        </div>
      </main>
    </div>
  );
}

export default ReportPdf;
