import React from "react";

import { MobileTableCollapseRows } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableCollapseRows";
import ImageFile from "../../../../../components/DriverDocuments/ImageFile";
import PdfFile from "../../../../../components/DriverDocuments/PdfFile";

export const DriverDocuments = ({ driverInfo }) => {
  const extension = (url) => {
    return url.split(".").pop().toLowerCase();
  };

  const file = (url) => {
    if (extension(url) !== "pdf") {
      return <ImageFile url={url} />;
    }
    return <PdfFile url={url} />;
  };

  const DownloadBtn = ({ path }) => (
    <a href={`${path}?download`} download>
      <div className="mobile-btn mobile-btn--blue">Download</div>
    </a>
  );

  const { carRegistrationDocuments, drivingLicense, nationalId, policeClearanceCertificate } = driverInfo.documents;

  return (
    <>
      {carRegistrationDocuments && (
        <>
          <MobileTableCollapseRows title="CAR REGISTRATION DOCUMENTS" rows={[]} />
          <div className="documentWrapper">
            {file(carRegistrationDocuments.url)}
            <DownloadBtn path={carRegistrationDocuments.url} />
          </div>
        </>
      )}
      {drivingLicense && (
        <>
          <MobileTableCollapseRows title="DRIVING LICENSE" rows={[]} />
          <div className="documentWrapper">
            {file(drivingLicense.url)}
            <DownloadBtn path={drivingLicense.url} />
          </div>
        </>
      )}
      {nationalId && (
        <>
          <MobileTableCollapseRows title="NATIONAL ID" rows={[]} />
          <div className="documentWrapper">
            {file(nationalId.url)}
            <DownloadBtn path={nationalId.url} />
          </div>
        </>
      )}
      {policeClearanceCertificate && (
        <>
          <MobileTableCollapseRows title="POLICE CLEARENCE CERTIFICATE" rows={[]} />
          <div className="documentWrapper">
            {file(policeClearanceCertificate.url)}
            <DownloadBtn path={policeClearanceCertificate.url} />
          </div>
        </>
      )}
    </>
  );
};
