import React, { useEffect } from "react";

import { MobileSelect } from "../../../components/AdminPanel/Mobile/MobileSelect";
import { MobileTitle } from "../../../components/AdminPanel/Mobile/MobileTitle";
import Map from "../components/Map";

export const MobileView = ({
  job,
  drivers,
  selectedDriver,
  allDriverSelected,
  locations,
  selectDriver,
  markerClick,
  showJobs,
}) => {
  const options = drivers?.map(({ id, profile }) => ({
    text: `${profile?.firstName || ""} ${profile?.lastName || ""}`,
    value: id,
  }));
  // eslint-disable-next-line
  options?.unshift({ text: "All drivers", value: "all" });

  useEffect(() => {
    !selectedDriver && selectDriver("all");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mobile-page-wrapper mobile-page-wrapper--driver-map">
      <div className="nav-wrapper">
        <MobileTitle title="Drivers Map" />
        <MobileSelect
          options={options}
          value={selectedDriver || "all"}
          onSelect={selectDriver}
          getPopupContainer={(trigger) => trigger.parentNode}
        />
      </div>
      <Map
        allDriverSelected={allDriverSelected}
        driverLocations={locations}
        onMarkerClick={markerClick}
        job={job}
        showJobs={showJobs}
      />
    </div>
  );
};
