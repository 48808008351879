import React from "react";
import { Badge, Button, Dropdown, Layout, Menu, Space } from "antd";
import { Link } from "react-router-dom";
import { ReactComponent as BeeboltWhiteLogo } from "../../../assets/adminPanel/beeboltWhite.svg";
import { ReactComponent as MenuLogo } from "../../../assets/adminPanel/menuMobileAdminPanel.svg";
import "./styles.less";
import LogsWrapper from "../../../components/Logs";
import { checkMenuItem } from "..";
import { getRole } from "../../../routes";
import TenantSelector from "../../../components/DataEntry/TenantSelector";
import { BellOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import NotificationList from "src/components/NotificationList";
import useHeaderNotifications from "../hooks/useHeaderNotifications";

const { Header, Content } = Layout;

export const MobileLayout = ({ children, logout, notifications, isOutsiderCompany }) => {
  const {notificationsCount} = useHeaderNotifications();
  const settingsMenu = (
    <Menu mode="vertical">
      {/* <Menu.Item key="admin-preferences" icon={<SettingOutlined />}>
        <Link to="/admin/preferences">Settings</Link>
      </Menu.Item> */}
      <Menu.Item key="admin-logout" onClick={logout()} icon={<UserOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const menuItems = [
    {
      key: "dashboard",

      label: <Link to="/admin/dashboard">Dashboard</Link>,
    },
    {
      key: "test_algorithm",
      label: (
        <Badge
          className="mobile-menu-badge"
          size="small"
          count={isOutsiderCompany ? 0 : notifications?.ordersUnassigned}
          showZero={false}
        >
          <Link to="/admin/cloud_fleet_routing">Cloud Fleet Routing</Link>
        </Badge>
      ),
    },
    {
      key: "delivery_manager",

      label: (
        <Badge
          className="mobile-menu-badge"
          size="small"
          count={isOutsiderCompany ? 0 : notifications?.jobsUnassigned}
          showZero={false}
        >
          <Link to="/admin/jobs">Job Management</Link>
        </Badge>
      ),
    },
    {
      key: "orders_managment",
      label: <Link to="/admin/orders_managment">Order Management</Link>,
    },
    {
      key: "drivers_manager",

      label: <Link to="/admin/drivers_manager">Driver Management</Link>,
    },
    {
      key: "zones_manager",

      label: <Link to="/admin/zones_manager">Zone creation</Link>,
    },
    {
      key: "drivers_stats",

      label: <Link to="/admin/drivers_stats">Drivers Stats</Link>,
    },
    {
      key: "payments",

      label: <Link to="/admin/payments">Payments</Link>,
    },
    {
      key: "retailers",

      label: <Link to="/admin/retailers">Retailers</Link>,
    },
    {
      key: "retailers_groups",

      label: <Link to="/admin/retailers_groups">Retailers Groups</Link>,
    },
    {
      key: "accounting",

      label: <Link to="/admin/accounting">Accounting</Link>,
    },
    {
      key: "system_report",

      label: <Link to="/admin/system_report">System Report</Link>,
    },
    {
      key: "environment_stats",

      label: <Link to="/admin/environment_stats">Environment stats</Link>,
    },
    {
      key: "delivery_reports",

      label: <Link to="/admin/delivery_reports">Delivery Reports</Link>,
    },
    {
      key: "tickets",

      label: (
        <Badge
          className="mobile-menu-badge"
          size="small"
          count={isOutsiderCompany ? 0 : notifications?.unreadTicketMessages}
          showZero={false}
        >
          <Link to="/admin/tickets">Tickets</Link>
        </Badge>
      ),
    },
    {
      key: "drivers_map",

      label: <Link to="/admin/drivers_map">Driver Map</Link>,
    },
    // {
    //   key: "notifications",

    //   label: (
    //     <Badge
    //       className="mobile-menu-badge"
    //       size="small"
    //       count={isOutsiderCompany ? 0 : notifications?.notificationsCount}
    //       showZero={false}
    //     >
    //       <Link to="/admin/notifications">Notifications</Link>
    //     </Badge>
    //   ),
    // },
    {
      key: "reports",

      label: <Link to="/admin/reports">Reports</Link>,
    },
    {
      key: "user_management",

      label: <Link to="/admin/user_management">User Management</Link>,
    },
    {
      key: "preferences",

      label: <Link to="/admin/preferences">Settings</Link>,
    },
    // {
    //   key: "price_management",

    //   label: <Link to="/admin/price-settings">Price Management</Link>,
    // },
  ];
  const renderMenuItems = (array, role) => {
    return array.map((item) => {
      return checkMenuItem(role[item.key], item, isOutsiderCompany, { showAlways: item.showAlways ?? false });
    });
  };

  const menu = <Menu mode="vertical" items={renderMenuItems(menuItems, getRole())}></Menu>;

  return (
    <Layout className="mobile-admin-panel-layout">
      <Header className="mobile-admin-panel-layout__header">
        <div className="mobile-header">
          <Dropdown
            overlay={menu}
            placement="bottomLeft"
            overlayClassName="mobile-menu-overlay"
            arrow={false}
            trigger={["click"]}
          >
            <MenuLogo />
          </Dropdown>
          <BeeboltWhiteLogo width="70px" height="20px" />
          <Space size="large">
            <TenantSelector isAdmin isMobile />
            {/* <Dropdown overlay={<NotificationList />} placement="bottomLeft" overlayStyle={{backgroundColor:"white",padding:10}}>
              <Badge count={isOutsiderCompany ? 0 : notificationsCount} showZero={false}>
                <Button shape="circle" icon={<BellOutlined />} hidden={isOutsiderCompany} />
              </Badge>
            </Dropdown> */}
            <Dropdown overlay={settingsMenu} placement="bottomRight" trigger={["click"]}>
              <Button shape="circle" icon={<UserOutlined />} />
            </Dropdown>
          </Space>
        </div>
      </Header>
      <Layout>
        <Content>
          {children}
          <LogsWrapper />
        </Content>
      </Layout>
    </Layout>
  );
};
