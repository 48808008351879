import React from "react";
import { useIntl } from "react-intl";
import { Form, Input } from "antd";

const { Item } = Form;
const FORBIDDEN_VALUES = [null, undefined, ""];

export default function InitialAddressForm({
  selectedSharedLocation,
  form,
  disabled,
  setCustomInitialAddress,
  setIsLoading,
}) {
  const fields = [
    {
      name: ["city"],
      value: selectedSharedLocation.deliveryPoint.initialAddress?.city || "",
    },
    {
      name: ["zone"],
      value: selectedSharedLocation.deliveryPoint.initialAddress?.zone || "",
    },
    {
      name: ["address"],
      value: selectedSharedLocation.deliveryPoint.initialAddress?.address || "",
    },
    {
      name: ["street"],
      value: selectedSharedLocation.deliveryPoint.initialAddress?.street || "",
    },
    {
      name: ["building"],
      value: selectedSharedLocation.deliveryPoint.initialAddress?.building || "",
    },
  ];

  const validateForm = async () => {
    const { lat, long } = form.getFieldsValue(true);
    if (FORBIDDEN_VALUES.includes(lat) || FORBIDDEN_VALUES.includes(long)) {
      return;
    }
  };

  const intl = useIntl();
  return (
    <Form form={form} fields={fields} layout="horizontal" name="initialAddress" onValuesChange={validateForm}>
      <Item
        label={`${intl.formatMessage({
          id: "map.orderInfo.customer.initialAddress.address",
          defaultMessage: "Address",
        })}`}
        name="address"
        // rules={[
        //   {
        //     required: true,
        //     message: `${intl.formatMessage({
        //       id: "error.requiredAddress",
        //       defaultMessage: "Address is required",
        //     })}`,
        //   },
        // ]}
        validateTrigger="onBlur"
      >
        <Input
          disabled={disabled}
          size="large"
          placeholder={`${intl.formatMessage({
            id: "map.orderInfo.customer.initialAddress.address",
            defaultMessage: "Address",
          })}`}
          onChange={() => {}}
        />
      </Item>
      {/*<Item
        label={`${intl.formatMessage({
          id: "map.orderInfo.customer.initialAddress.city",
          defaultMessage: "City",
        })}`}
        name="city"
        validateTrigger="onBlur"
      >
        <Input
          disabled={disabled}
          size="large"
          placeholder={`${intl.formatMessage({
            id: "map.orderInfo.customer.initialAddress.city",
            defaultMessage: "City",
          })}`}
          onChange={() => {}}
        />
      </Item>*/}

      <Item
        label={`${intl.formatMessage({
          id: "map.orderInfo.customer.initialAddress.zone",
          defaultMessage: "Zone",
        })}`}
        name="zone"
        validateTrigger="onBlur"
      >
        <Input
          disabled={disabled}
          size="large"
          placeholder={`${intl.formatMessage({
            id: "map.orderInfo.customer.initialAddress.zone",
            defaultMessage: "Zone",
          })}`}
          onChange={() => {}}
        />
      </Item>
      <Item
        label={`${intl.formatMessage({
          id: "map.orderInfo.customer.initialAddress.street",
          defaultMessage: "Street",
        })}`}
        name="street"
        validateTrigger="onBlur"
      >
        <Input
          disabled={disabled}
          size="large"
          placeholder={`${intl.formatMessage({
            id: "map.orderInfo.customer.initialAddress.street",
            defaultMessage: "Street",
          })}`}
          onChange={() => {}}
        />
      </Item>
      <Item
        label={`${intl.formatMessage({
          id: "map.orderInfo.customer.initialAddress.building",
          defaultMessage: "Building",
        })}`}
        name="building"
        validateTrigger="onBlur"
      >
        <Input
          disabled={disabled}
          size="large"
          placeholder={`${intl.formatMessage({
            id: "map.orderInfo.customer.initialAddress.building",
            defaultMessage: "Building",
          })}`}
          onChange={() => {}}
        />
      </Item>
    </Form>
  );
}
