import React from "react";
import { useHistory } from "react-router-dom";

import { Button, Typography } from "antd";

import styles from "./loginButton.module.less";

const LoginButton = () => {
  const history = useHistory();

  return (
    <Button onClick={() => history.push("/retailer_login")} size="large" type="link" className={styles.button}>
      <Typography.Text className={styles.loginText} style={{color:"white"}}>Retailer?</Typography.Text>
      <Typography.Text className={styles.logIn}>Log In</Typography.Text>
    </Button>
  );
};

export default LoginButton;
