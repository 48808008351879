import { Col, Row, Segmented, Select, Typography } from "antd";
import GoogleMapReact from "google-map-react";
import React, { FC, memo, useEffect, useState } from "react";
import TaskEntity from "src/entities/task.entity";
import { mapCenters, ROUTES_API_KEY } from "src/constants/config";
import { getUserTenant } from "src/helpers/authStorage";
import { v4 } from "uuid";
import { useFleetUpdates } from "./useFleetUpdates";

interface TrackingShipmentMapPrps {
  job: any;
  providerType: ProviderType;
  task: TaskEntity;
}

export enum ProviderType {
  TASKS_TRACKER,
  VEHICLE_TRACKER,
  FLEET_TRACKER,
}

enum TaskState {
  ALL = "ALL",
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}
type mapCentersKey = keyof typeof mapCenters;

const displayTitle = (providerType: ProviderType, job: any, task: any) => {
  if (providerType === ProviderType.TASKS_TRACKER) return `Tracking ${task?.type} task n° ${task?.pointOrder}`;
  else if (providerType === ProviderType.VEHICLE_TRACKER) return `Tracking job n° ${job?.id} `;
  else return "";
};

const { Option } = Select;
const TrackingShipmentMap: FC<TrackingShipmentMapPrps> = ({ job, providerType, task }) => {
  // state
  const [customLocation, setCustomLocation] = useState<{ lat: number; long: number }>({ lat: 0, long: 0 });
  const [taskState, setTaskState] = useState<TaskState>(TaskState.ALL);
  const [threshold, setThreshold] = useState<number>(1);

  // TODO find a better way to handle maps re-render
  const [keyMap, setKeyMap] = useState<string>(() => `map-${job?.id}-${job?.driverId}`);

  // hooks
  const { initTrackingMap, removeListeners } = useFleetUpdates({
    trackingId: task?.trackingId,
    vehicleId: `${job.id}-${job.driverId}`,
    jobId: job.id,
    center: customLocation,
    polyline: job?.polyline,
  });

  useEffect(() => {
    const tenant: mapCentersKey = (getUserTenant() ?? "Qatar") as mapCentersKey;
    if (tenant) {
      // case of retailer, tenant is not set
      setCustomLocation(mapCenters[tenant]);
    }
  }, [getUserTenant]);

  useEffect(() => {
    setKeyMap(`map-${providerType}-${job.id}-${job.driverId}`);
  }, [job, providerType]);

  // handlers

  const handleValueChange = (value: TaskState) => {
    removeListeners();
    setTaskState(value);
    const uuid = v4();
    setKeyMap(`map-${providerType}-${job.id}-${job.driverId}-${uuid}`);
  };

  const handleThresholdChange = (value: any) => {
    setThreshold(value);
    const uuid = v4();
    setKeyMap(`map-${providerType}-${job.id}-${job.driverId}-${uuid}`);
  };

  return (
    <Col>
      <Row justify="space-between">
        <Typography.Title level={5}>{displayTitle(providerType, job, task)}</Typography.Title>
        {providerType === ProviderType.VEHICLE_TRACKER && (
          <Segmented
            value={taskState}
            onChange={(value) => handleValueChange(value.toString() as TaskState)}
            options={[TaskState.ALL, TaskState.OPEN, TaskState.CLOSED]}
          />
        )}
        {providerType === ProviderType.FLEET_TRACKER && (
          <Segmented
            value={threshold}
            onChange={(value) => handleThresholdChange(value)}
            options={[
              { label: "Last hour", value: 1 },
              { label: "Last 6 hrs", value: 6 },
              { label: "Last 12 hrs", value: 12 },
              { label: "Last day", value: 24 },
            ]}
          />
        )}
      </Row>
      <div className="mapWrapper">
        <GoogleMapReact
          key={keyMap}
          bootstrapURLKeys={{
            key: ROUTES_API_KEY,
            version: "beta",
            libraries: ["journeySharing", "geometry"],
          }}
          defaultCenter={{ lat: 42.86032, lng: 19.53049 }}
          zoom={10}
          defaultZoom={0}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps, ref }) =>
            initTrackingMap(map, maps, ref as Element, { state: taskState, providerType, threshold: threshold })
          }
          shouldUnregisterMapOnUnmount={true}
        ></GoogleMapReact>
      </div>
    </Col>
  );
};

export default memo(TrackingShipmentMap);
