import { notification } from "antd";
import { TUseCFRWidget } from "../../../types/CloudFleetRouting";

export const useCFRWidget: TUseCFRWidget = ({ cfrStateJobsMap }) => {
  const getCFRStatus = async () => {
    try {
      await cfrStateJobsMap();
    } catch {
      notification.info({
        message: "Error",
        description: "Oops! Unable to fetch CFR state",
      });
    }
  };

  return {
    getCFRStatus,
  };
};
