import React from "react";

import Honeycomb from "../../../../assets/newLanding/Honeycomb";
import styles from "./boothHoneycomb.module.less";

const BoothHoneycomb = () => (
  <div className={styles.honeycombWrap}>
    <Honeycomb className={styles.honeycomb1} />
    <Honeycomb className={styles.honeycomb2} />
    <Honeycomb className={styles.honeycomb3} />

    <Honeycomb className={styles.honeycomb4} />
    <Honeycomb className={styles.honeycomb5} />
  </div>
);

export default BoothHoneycomb;
