import React, { useState } from "react";
import { Drawer, Divider, Select, Input, Button, Popover } from "antd";
import { FormattedMessage } from "react-intl";

const { Option } = Select;

export default function EmailDrawer({
  data,
  setData,
  sendEmails,
  drawerVisible,
  createEmail,
  setDrawerVisible,
  handleDelete,
  intl,
}) {
  const template = {
    options: {
      daily: false,
      weekly: false,
      monthly: false,
    },
  };
  const [newEmail, setNewEmail] = useState(template);

  const SelectAfter = (index) => {
    const values = [];
    if (index !== "new") {
      if (data[index]?.options?.daily) {
        values.push("daily");
      }
      if (data[index]?.options?.weekly) {
        values.push("weekly");
      }
      if (data[index]?.options?.monthly) {
        values.push("monthly");
      }
    } else {
      if (newEmail?.options?.daily) {
        values.push("daily");
      }
      if (newEmail?.options?.weekly) {
        values.push("weekly");
      }
      if (newEmail?.options?.monthly) {
        values.push("monthly");
      }
    }

    return (
      <Select
        placeholder="Please select"
        allowClear
        mode="multiple"
        onChange={handleOptionsChange(index)}
        value={values}
        className="retailerInsights__mail__select-after"
        style={{ width: "100%" }}
      >
        <Option value="daily">
          <FormattedMessage id="page.home.insights.mailing.daily" defaultMessage="Daily" />
        </Option>
        <Option value="weekly">
          <FormattedMessage id="page.home.insights.mailing.weekly" defaultMessage="Weekly" />
        </Option>
        <Option value="monthly">
          <FormattedMessage id="page.home.insights.mailing.monthly" defaultMessage="Monthly" />
        </Option>
      </Select>
    );
  };

  const EmailBox = ({ handleEmailChange, handleDelete, e, i }) => {
    const [isDisabled, setDisabled] = useState(false);
    return (
      <div className="retailerInsights__mail__emailBox">
        <Input.Group compact className="retailerInsights__mail__input">
          <Input disabled={isDisabled} value={e.email} onChange={handleEmailChange(i)} />
          <Popover
            disabled={isDisabled}
            content={SelectAfter(i)}
            title={`${intl.formatMessage({
              id: "page.home.insights.mailing.setmailing",
              defaultMessage: "Set mailing:",
            })}`}
            trigger="click"
          >
            <Button disabled={isDisabled} style={{ width: "25%" }}>
              ...
            </Button>
          </Popover>
        </Input.Group>
        <Button danger disabled={isDisabled} onClick={handleDelete(e.id, setDisabled)}>
          {`${intl.formatMessage({ id: "modal.delete", defaultMessage: "Delete" })}`}
        </Button>
      </div>
    );
  };

  const handleEmailChange = (index) => (rawEvent) => {
    const value = rawEvent.target.value.toLowerCase();
    if (index !== "new") {
      setData((old) => {
        const newData = [...old];
        newData[index] = { ...old[index], email: value };
        return newData;
      });
    } else {
      setNewEmail((old) => {
        const newData = { ...old, email: value };
        return newData;
      });
    }
  };

  const handleOptionsChange = (index) => (value) => {
    if (index !== "new") {
      setData((old) => {
        const newData = [...old];
        newData[index] = {
          ...old[index],
          options: {
            daily: value.includes("daily"),
            weekly: value.includes("weekly"),
            monthly: value.includes("monthly"),
          },
        };
        return newData;
      });
    } else {
      setNewEmail((old) => {
        const newData = {
          ...old,
          options: {
            daily: value.includes("daily"),
            weekly: value.includes("weekly"),
            monthly: value.includes("monthly"),
          },
        };
        return newData;
      });
    }
  };
  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <Drawer width={"600px"} placement="right" closable onClose={closeDrawer} visible={drawerVisible}>
      <h2>{`${intl.formatMessage({ id: "page.home.insights.setEmails", defaultMessage: "Set automatic emails" })}`}</h2>
      <Divider />
      <Input.Group compact className="retailerInsights__mail__input">
        <Input value={newEmail.email} onChange={handleEmailChange("new")} />
        <Popover
          content={SelectAfter("new")}
          title={`${intl.formatMessage({
            id: "page.home.insights.mailing.setmailing",
            defaultMessage: "Set mailing:",
          })}`}
          trigger="click"
        >
          <Button style={{ width: "25%" }}>...</Button>
        </Popover>
      </Input.Group>
      <div className="retailerInsights__mail__buttons">
        <Button onClick={() => createEmail(newEmail)}>{`${intl.formatMessage({
          id: "modal.save",
          defaultMessage: "Save",
        })}`}</Button>
        <Button
          danger
          onClick={() => {
            setNewEmail(template);
          }}
        >
          {`${intl.formatMessage({ id: "modal.clear", defaultMessage: "Clear" })}`}
        </Button>
      </div>
      <Divider />
      {data?.length > 0
        ? data.map((e, i) => {
            return (
              <EmailBox
                e={e}
                i={i}
                key={e.id}
                handleOptionsChange={handleOptionsChange}
                handleEmailChange={handleEmailChange}
                handleDelete={handleDelete}
              />
            );
          })
        : null}
      <Divider />
      <div className="retailerInsights__mail__buttons">
        {/* <Button disabled> {`${intl.formatMessage({ id: "modal.clear", defaultMessage: "Clear" })}`}</Button> */}
        <Button onClick={() => sendEmails()}>
          {" "}
          {`${intl.formatMessage({ id: "modal.save", defaultMessage: "Save" })}`}
        </Button>
      </div>
    </Drawer>
  );
}
