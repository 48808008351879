import React from "react";
import classnames from "classnames";

import { getMobileBadgeStatus, NO_INFO } from "../../../../../../components/AdminPanel/Mobile/helpers";
import { MobileTableCollapseRows } from "../../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableCollapseRows";
import { MobileTabs } from "../../../../../../components/AdminPanel/Mobile/MobileTabs";
import { OrderGoogleMap } from "../../../../../../components/OrderGoogleMap";
import additionalInfoFields from "../../../../config/additionalInfoFields";
import initialAddressFields from "../../../../config/initialAddressFields";
import { CollapseHeader } from "../Header";
import { ButtonsBlock } from "../ButtonsBlock";

const Panel = ({ children }) => <div className="mobile-custom-collapse__panel">{children}</div>;

export const CollapsePanel = ({
  order,
  innerCollapsed,
  centerCoordinates,
  onOrderCollapse,
  onOpenCashAmountModal,
  onForceNextOrder,
  onDeleteOrder,
  postponeOrderDeliveryJob,
  cancelOrderDeliveryJob,
  getOneDeliveryJob,
  jobId,
}) => {
  const isActive = innerCollapsed === order.id;
  return (
    <Panel>
      <CollapseHeader onClick={() => onOrderCollapse(order.id)} name={order.customerInfo?.name} isActive={isActive} />
      <div className={classnames("panel-content", isActive && "panel-content--active")}>
        <div className="panel-content__body">
          <MobileTabs
            leftTabTitle="Order Info"
            rightTabTitle="More"
            leftTab={
              <>
                <MobileTableCollapseRows
                  rows={[
                    { label: "Delivery time", value: order.deliveryTime || NO_INFO },
                    { label: "Status", value: getMobileBadgeStatus(order.status) },
                    { label: "Retailer name", value: order.retailer?.name || NO_INFO },
                    { label: "Notes", value: order.notes || NO_INFO },
                  ]}
                />
                <ButtonsBlock
                  order={order}
                  onForceNextOrder={onForceNextOrder}
                  onDeleteOrder={onDeleteOrder}
                  onOpenCashAmountModal={onOpenCashAmountModal}
                  postponeOrderDeliveryJob={postponeOrderDeliveryJob}
                  cancelOrderDeliveryJob={cancelOrderDeliveryJob}
                  getOneDeliveryJob={getOneDeliveryJob}
                  jobId={jobId}
                />
              </>
            }
            rightTab={
              <>
                {!!centerCoordinates?.lat && !!centerCoordinates?.long && (
                  <div className="mobile-map-wrapper">
                    <OrderGoogleMap orderInfo={order} centerCoordinates={centerCoordinates} />
                  </div>
                )}
                <MobileTableCollapseRows
                  title="RETAILER"
                  rows={[
                    { label: "Name", value: order.retailer?.name || NO_INFO },
                    { label: "Office Address", value: order.retailer?.email || NO_INFO },
                    { label: "Phone Number", value: order.retailer?.phoneNumber || NO_INFO },
                    { label: "Support Number", value: order.retailer?.supportNumber || NO_INFO },
                  ]}
                />
                <MobileTableCollapseRows
                  title="CUSTOMER"
                  rows={[
                    { label: "Name", value: order.customerInfo?.name || NO_INFO },
                    { label: "Email", value: order.car?.customerInfo?.email || NO_INFO },
                    { label: "Phone Number", value: order.customer?.phoneNumber || NO_INFO },
                    {
                      label: "Lat and lng",
                      value: order.deliveryPoint?.lat ? (
                        <>
                          <div>lat: {order.deliveryPoint.lat}</div>
                          <div>long: {order.deliveryPoint.long}</div>
                        </>
                      ) : (
                        NO_INFO
                      ),
                    },
                    ...initialAddressFields(order).filter(({ value }) => value),
                    { label: "Amount Cash On Delivery", value: order.cashAmount || NO_INFO },
                  ]}
                />
                {order.additionalInfo && !!Object.keys(order.additionalInfo).length && (
                  <MobileTableCollapseRows
                    title="ORDER DETAILS"
                    rows={additionalInfoFields(order.additionalInfo).filter(({ value }) => value)}
                  />
                )}
                <ButtonsBlock
                  order={order}
                  onForceNextOrder={onForceNextOrder}
                  onDeleteOrder={onDeleteOrder}
                  onOpenCashAmountModal={onOpenCashAmountModal}
                  postponeOrderDeliveryJob={postponeOrderDeliveryJob}
                  cancelOrderDeliveryJob={cancelOrderDeliveryJob}
                />
              </>
            }
          />
        </div>
      </div>
    </Panel>
  );
};
