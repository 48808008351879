import { createResource } from "redux-rest-resource";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "notifications",
  actions: {
    fetch: {
      url: `${API_URL}/admin/jobs/count-unassigned`,
      reduce: (state, action) => {
        if (action.status !== "resolved") {
          return state;
        }
        const newNotifications = {
          ...state,
          jobsUnassigned: Number(action.body.unassignedJobs.count),
          ordersUnassigned: Number(action.body.unassignedOrders.count),
          notificationsCount: Number(action.body.uncheckedNotifications?.count) || 0,
        };
        return { ...newNotifications };
      },
    },
    set: {
      isPure: true,
      reduce: (state, { context }) => {
        return {
          ...state,
          jobsUnassigned: context.jobsUnassigned,
          ordersUnassigned: context.ordersUnassigned,
          notificationsCount:
            Number(context?.nextPointRequests) +
            Number(context?.customerChangedLocation) +
            Number(context?.retailerTemplates) +
            Number(context?.retailerUpdates) +
            Number(context?.unreadTicketMessages),
          nextPointRequests: Number(context?.nextPointRequests),
          customerChangedLocation: Number(context?.customerChangedLocation),
          retailerTemplates: Number(context?.retailerTemplates),
          retailerUpdates: Number(context?.retailerUpdates),
          unreadTicketMessages: Number(context?.unreadTicketMessages),
        };
      },
    },
    fetchRetailerChange: {
      method: "GET",
      url: `${API_URL}/admin/retailer/update-requests`,
      transformResponse: ({ body }) => {
        return {
          items: body.items,
          pagination: {
            currentPageNumber: body.meta.currentPage,
            numItemsPerPage: body.meta.itemsPerPage,
            totalCount: body.meta.totalItems,
          },
        };
      },
      reduce: (state, { items, pagination, status }) => ({
        ...state,
        retailerChanges: { items, pagination, loading: status === "pending" },
      }),
    },
    fetchRetailer: {
      method: "GET",
      url: `${API_URL}/admin/retailer/update-requests/count-pending`,
      reduce: (state, { context }) => {
        return {
          ...state,
        };
      },
    },
    fetchSMS: {
      method: "GET",
      url: `${API_URL}/admin/retailer/messaging/templates/count-pending`,
    },
    approveRetailerChange: {
      method: "POST",
      url: `${API_URL}/admin/retailer/update-requests/:id/approve`,
    },
    rejectRetailerChange: {
      method: "POST",
      url: `${API_URL}/admin/retailer/update-requests/:id/decline`,
    },
    getOneRetailerData: {
      method: "GET",
      url: `${API_URL}/admin/retailers/:id`,
    },
    getSMSChanges: {
      method: "GET",
      url: `${API_URL}/admin/retailer/messaging/templates`,
      transformResponse: ({ body }) => {
        return {
          items: body.items,
          pagination: {
            currentPageNumber: body.meta.currentPage,
            numItemsPerPage: body.meta.itemsPerPage,
            totalCount: body.meta.totalItems,
          },
        };
      },
      reduce: (state, { items, pagination, status }) => ({
        ...state,
        retailerSMS: { items, pagination, loading: status === "pending" },
      }),
    },
    approveSMSChanges: {
      method: "POST",
      url: `${API_URL}/admin/retailer/messaging/templates/:groupId/approve`,
    },
    rejectSMSChanges: {
      method: "POST",
      url: `${API_URL}/admin/retailer/messaging/templates/:groupId/reject`,
    },
    getSMSChangesGroup: {
      method: "GET",
      url: `${API_URL}/admin/retailer/messaging/templates/:groupId`,
    },
    getCompany: {
      method: 'GET',
      url : `${API_URL}/company/notifications`
    },
    readCompany: {
      method: 'PATCH',
      url : `${API_URL}/company/notifications/:id`
    },
    getUnreadCompany : {
      method: 'GET',
      url : `${API_URL}/company/notifications/count-unread`
    }
  },
});
