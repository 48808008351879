export interface ActivityLogsDataModel {
  key: string;
  date: string | Date | number;
  order: number;
  custumer: string;
  user: {
    name: string;
    role: "admin" | "retailer" | "driver";
  };
  changes: string;
  tags: string[];
  degree: "info" | "important" | "critical";
}

export function fromJSON(item: any): ActivityLogsDataModel {
  return {
    key: `${item?.id}`,

    date: item?.createdAt,
    order: item?.order?.id ?? item.orderId,
    custumer: item?.order?.customerInfo?.name ?? item?.name,
    user: {
      name: "",
      role: "retailer",
    },
    changes: item?.status,
    tags: [],
    degree: "info",
  };
}
