import { createResource } from "redux-rest-resource";
import { setAuthHeader, clearAuthData, getAuthData, saveAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";
import setResourceToken from "./config";

const withSaveTokens = (res) => {
  saveAuthData(res.body, "admin");
  setResourceToken(res.body.accessToken);
  return {
    res,
    body: res.body,
  };
};

export const { types, actions, rootReducer } = createResource({
  name: "admin",

  actions: {
    login: {
      method: "POST",
      url: `${API_URL}/admin/auth/login`,
      transformResponse: withSaveTokens,
    },

    signup: {
      method: "POST",
      url: `${API_URL}/admin/auth/signup`,
      transformResponse: withSaveTokens,
    },

    logout: {
      isPure: true,
      reduce: (state) => {
        clearAuthData();
        setAuthHeader(null);

        return {
          ...state,
          item: null,
        };
      },
    },

    setIsMobile: {
      isPure: true,
      reduce: (state, action) => ({
        ...state,
        isMobile: action.context,
      }),
    },
    subscribeToPush: {
      method: "POST",
      url: `${API_URL}/admin/notifications/subscribe`,
    },
    unsubscribeFromPush: {
      method: "POST",
      url: `${API_URL}/admin/notifications/unsubscribe`,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
