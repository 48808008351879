import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions as adminCustomersActions } from "../../redux/resources/adminCustomers";
import { actions as driversPointsActions } from "../../redux/resources/driversPoints";
import { actions as notificationsActions } from "../../redux/resources/notifications";
import { actions as adminRetailersActions } from "../../redux/resources/adminRetailers";
import { MobileView } from "./MobileView";
import { DesktopView } from "./DesktopView";

import { useHooks } from "./hooks/useHooks";

const DEFAULT_URL = "admin/notifications";

const Notifications = ({
  notifications,
  isMobile,
  loading,
  locationTableData,
  locationTablePagination,
  deliveryTableData,
  deliveryTablePagination,
  fetchLocationsChangeAdminCustomers,
  checkRecordAdminCustomers,
  archiveRecordAdminCustomers,
  deleteRecordAdminCustomers,
  fetchDriversPoints,
  approveDriversPoints,
  rejectDriversPoints,
  deletePointDriversPoints,
  getBadAdminCustomers,
  waitDriversPoints,

  retailerChangesTableData,
  retailerChangesTablePagination,
  fetchRetailerChangeNotifications,
  fetchRetailerChangeNumberNotifications,
  approveRetailerChangeNotifications,
  rejectRetailerChangeNotifications,
  getOneRetailerDataNotifications,

  getSMSChangesNotifications,
  approveSMSChangesNotifications,
  rejectSMSChangesNotifications,
  retailerSMSTableData,
  retailerSMSTablePagination,
  getSMSChangesGroupNotifications,
  changeRetailerSMSrightsAdminRetailers,
}) => {
  const {
    isSelected,
    selection,
    clearSelection,
    deleteSelection,
    handleToggle,
    handleToggleOne,
    handleToggleMany,
    isAllOrdersChecked,
    setAllOrdersChecked,
    onRevokeSMSRights,
  } = useHooks({
    deleteRecordAdminCustomers,
    deletePointDriversPoints,
    getBadAdminCustomers,
    fetchDriversPoints,
    fetchLocationsChangeAdminCustomers,
    changeRetailerSMSrightsAdminRetailers,
  });

  return (
    <>
      {isMobile ? (
        <MobileView
          defaultUrl={DEFAULT_URL}
          loading={loading}
          selection={selection}
          locationTableData={locationTableData}
          locationTablePagination={locationTablePagination}
          deliveryTableData={deliveryTableData}
          deliveryTablePagination={deliveryTablePagination}
          fetchLocationsChangeAdminCustomers={fetchLocationsChangeAdminCustomers}
          checkRecordAdminCustomers={checkRecordAdminCustomers}
          archiveRecordAdminCustomers={archiveRecordAdminCustomers}
          fetchDriversPoints={fetchDriversPoints}
          approveDriversPoints={approveDriversPoints}
          rejectDriversPoints={rejectDriversPoints}
          waitDriversPoints={waitDriversPoints}
          isSelected={isSelected}
          handleToggleOne={handleToggleOne}
          handleToggleMany={handleToggleMany}
          deleteSelection={deleteSelection}
          clearSelection={clearSelection}
          isAllOrdersChecked={isAllOrdersChecked}
          setAllOrdersChecked={setAllOrdersChecked}
          retailerChangesTableData={retailerChangesTableData}
          retailerChangesTablePagination={retailerChangesTablePagination}
          fetchRetailerChangeNotifications={fetchRetailerChangeNotifications}
          fetchRetailerChangeNumberNotifications={fetchRetailerChangeNumberNotifications}
          approveRetailerChangeNotifications={approveRetailerChangeNotifications}
          rejectRetailerChangeNotifications={rejectRetailerChangeNotifications}
          getOneRetailerData={getOneRetailerDataNotifications}
          getSMSChangesNotifications={getSMSChangesNotifications}
          approveSMSChangesNotifications={approveSMSChangesNotifications}
          rejectSMSChangesNotifications={rejectSMSChangesNotifications}
          retailerSMSTableData={retailerSMSTableData}
          retailerSMSTablePagination={retailerSMSTablePagination}
          getSMSChangesGroupNotifications={getSMSChangesGroupNotifications}
          onRevokeSMSRights={onRevokeSMSRights}
        />
      ) : (
        <DesktopView
          notifications={notifications}
          defaultUrl={DEFAULT_URL}
          loading={loading}
          selection={selection}
          locationTableData={locationTableData}
          locationTablePagination={locationTablePagination}
          deliveryTableData={deliveryTableData}
          deliveryTablePagination={deliveryTablePagination}
          fetchLocationsChangeAdminCustomers={fetchLocationsChangeAdminCustomers}
          checkRecordAdminCustomers={checkRecordAdminCustomers}
          archiveRecordAdminCustomers={archiveRecordAdminCustomers}
          fetchDriversPoints={fetchDriversPoints}
          approveDriversPoints={approveDriversPoints}
          rejectDriversPoints={rejectDriversPoints}
          waitDriversPoints={waitDriversPoints}
          handleToggle={handleToggle}
          deleteSelection={deleteSelection}
          clearSelection={clearSelection}
          retailerChangesTableData={retailerChangesTableData}
          retailerChangesTablePagination={retailerChangesTablePagination}
          fetchRetailerChangeNotifications={fetchRetailerChangeNotifications}
          fetchRetailerChangeNumberNotifications={fetchRetailerChangeNumberNotifications}
          approveRetailerChangeNotifications={approveRetailerChangeNotifications}
          rejectRetailerChangeNotifications={rejectRetailerChangeNotifications}
          getOneRetailerData={getOneRetailerDataNotifications}
          getSMSChangesNotifications={getSMSChangesNotifications}
          approveSMSChangesNotifications={approveSMSChangesNotifications}
          rejectSMSChangesNotifications={rejectSMSChangesNotifications}
          retailerSMSTableData={retailerSMSTableData}
          retailerSMSTablePagination={retailerSMSTablePagination}
          getSMSChangesGroupNotifications={getSMSChangesGroupNotifications}
          onRevokeSMSRights={onRevokeSMSRights}
        />
      )}
    </>
  );
};

const MSTP = (state) => {
  return {
    isMobile: state.admin.isMobile,
    loading: state.adminCustomers.loading || state.driversPoints.loading,
    locationTableData: state.adminCustomers.items,
    locationTablePagination: state.adminCustomers.pagination,
    deliveryTableData: state.driversPoints.items,
    deliveryTablePagination: state.driversPoints.pagination,
    retailerChangesTableData: state.notifications.retailerChanges?.items,
    retailerChangesTablePagination: state.notifications.retailerChanges?.pagination,
    retailerSMSTableData: state.notifications.retailerSMS?.items,
    retailerSMSTablePagination: state.notifications.retailerSMS?.pagination,
    notifications: state.notifications,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(adminRetailersActions, dispatch),
  ...bindActionCreators(adminCustomersActions, dispatch),
  ...bindActionCreators(driversPointsActions, dispatch),
  ...bindActionCreators(notificationsActions, dispatch),
}))(Notifications);
