import React, { useState, useEffect, useCallback } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { CAN_BE_ASSIGNED_STATUSES } from "../../../../constants/query";
import { getAuthData } from "../../../../helpers/authStorage";
import { getJobStatusQuery } from "../../../../helpers/formatStatus";
import { allowedMoveToJobStatus } from "src/constants/jobsStatus";

const NOT_ALLOWED_TO_CHANGE_LOCATION = ["in_delivery", "arrived", "completed"];

export const useOrderTable = ({
  old,
  orders,
  retailers,
  filteredRetailer,
  filteredLocationShared,
  orderSearchQuery,
  selectedOrders,
  fetchAccordingToTab,
  handleSelectOrder,
  changeQueryParams,
  changeLocationAdminOrder,
  setSelectedOrders,
  setSearchedValue,
  setOrderSearchQuery,
  setOpenModal,
  setDownloadedOrders,
  getJobsByStatusAdminOrder,
  cancelCompletedAdminOrder,
}) => {
  const [collapsedOrder, setCollapsedOrder] = useState(null);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(null);
  const [orderList, setOrderList] = useState([]);
  const [isAllOrdersChecked, setIsAllOrdersChecked] = useState(false);
  const [isOrdersActionModalOpen, setOrdersActionModalOpen] = useState({ id: "", open: false });

  const intl = useIntl();
  const history = useHistory();

  useEffect(() => {
    if (old && orders?.[0]?.items) {
      setOrderList(orders[0].items);
    } else {
      setOrderList(orders);
    }
  }, [orders, old]);

  useEffect(() => {
    if (selectedOrders.length && selectedOrders.length === orderList?.length) {
      setIsAllOrdersChecked(true);
    } else {
      setIsAllOrdersChecked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrders]);

  const getRetailerNameFilter = () => {
    const arrUniq = [...new Map(retailers?.map((v) => [v.name, v])).values()];
    return arrUniq?.map(({ name }) => ({ text: name, value: name }));
  };

  const retailerNameFilter = getRetailerNameFilter();

  const onCollapseOrder = (orderId) => setCollapsedOrder(Number(orderId));

  const onHandleSelectOrder = (trackingId) => () => {
    handleSelectOrder(trackingId);
  };

  const onChangeSearchFilter = (value) => {
    changeQueryParams({});
    setSearchedValue("");
    setOrderSearchQuery(value);
  };

  const onChangeSearchValue = (e) => setSearchedValue(e.target.value);

  const searchRetailer = async (e) => {
    if (e.target.value) {
      changeQueryParams({ [orderSearchQuery]: e.target.value, page: 1 });
    } else {
      changeQueryParams({});
    }
  };

  const handleDeleteManyOrders = () => {
    setOpenModal({ type: "deleteMany", id: selectedOrders, open: true });
  };

  const onChangeRetailerNameFilter = (value) => {
    changeQueryParams(
      {},
      { locationShared: filteredLocationShared ? [filteredLocationShared] : [], retailerName: value ? [value] : [] },
    );

    const getQueryString = () => {
      let str;

      if (!value && !filteredLocationShared) {
        str = `/admin/orders_managment`;
      } else if (!value && filteredLocationShared) {
        str = `/admin/orders_managment?locationShared=${filteredLocationShared}`;
      } else if (value && !filteredLocationShared) {
        str = `/admin/orders_managment?retailerName=${value}`;
      } else {
        str = `/admin/orders_managment?locationShared=${filteredLocationShared}&retailerName=${value}`;
      }

      return str;
    };

    history.push(getQueryString());
  };

  const onChangeLocationSharedFilter = async (value) => {
    changeQueryParams(
      {},
      { locationShared: value ? [value] : [], retailerName: filteredRetailer ? [filteredRetailer] : [] },
    );

    const getQueryString = () => {
      let str;

      if (!value && !filteredRetailer) {
        str = `/admin/orders_managment`;
      } else if (!value && filteredRetailer) {
        str = `/admin/orders_managment?retailerName=${filteredRetailer}`;
      } else if (value && !filteredRetailer) {
        str = `/admin/orders_managment?locationShared=${value}`;
      } else {
        str = `/admin/orders_managment?locationShared=${value}&retailerName=${filteredRetailer}`;
      }

      return str;
    };

    history.push(getQueryString());
  };

  const onPageChange = (current, pageSize) => {
    changeQueryParams(
      { current, pageSize: Number(pageSize) },
      {
        locationShared: filteredLocationShared ? [filteredLocationShared] : null,
        retailerName: filteredRetailer ? [filteredRetailer] : null,
      },
    );
  };

  const isOrderChecked = (orderId) => selectedOrders.includes(orderId);

  const onSelectAllOrders = (e) => {
    if (e.target.checked) {
      setSelectedOrders(orderList.map(({ id }) => id));
    } else {
      setSelectedOrders([]);
    }
  };

  const onSelectOrder = (e, orderId) => {
    e.stopPropagation();

    if (selectedOrders.includes(orderId)) {
      setSelectedOrders(selectedOrders.filter((id) => id !== orderId));
    } else {
      setSelectedOrders([...selectedOrders, orderId]);
    }
  };

  const onLocationShare =
    ({ id, status, locationShared }) =>
      () => {
        if (!locationShared && !NOT_ALLOWED_TO_CHANGE_LOCATION.includes(status)) {
          setIsLocationModalOpen(id);
        }
      };

  const onActionBtnClick = (values) => () => {
    setOpenModal(values);
  };

  const addLocationToLocal = async ({ selectedId, lat, long, old, address }) => {
    if (!old) {
      setDownloadedOrders((prevState) => {
        const copied = [...prevState];
        const searchedOrder = copied.find(({ id }) => id === selectedId);
        searchedOrder.deliveryPoint.lat = lat;
        searchedOrder.deliveryPoint.long = long;
        return copied;
      });
    } else {
      await changeLocationAdminOrder({ orderId: selectedId, lat, long, address });
      fetchAccordingToTab();
    }
  };

  const modalTitle = {
    completeOrder: <FormattedMessage id="modal.completeOrder" defaultMessage="Complete order" />,
    removeLocation: <FormattedMessage id="modal.removeLocation" defaultMessage="Remove location" />,
    return: <FormattedMessage id="modal.return" defaultMessage="Return" />,
    delete: <FormattedMessage id="modal.delete" defaultMessage="Delete" />,
    cancel: <FormattedMessage id="modal.cancel" defaultMessage="Cancel" />,
    cancelCompleted: <FormattedMessage id="modal.cancel" defaultMessage="Cancel" />,
    postpone: <FormattedMessage id="modal.postpone" defaultMessage="Postpone" />,
    deleteMany: <FormattedMessage id="modal.delete" defaultMessage="Delete" />,
    saveOrders: <FormattedMessage id="modal.save" defaultMessage="Save" />,
  };

  const searchFilter = [
    { text: `${intl.formatMessage({ id: "tabPane.orderID", defaultMessage: "Order ID" })}`, value: "orderId" },
    {
      text: `${intl.formatMessage({ id: "tabPane.customerPhone", defaultMessage: "Customer Phone" })}`,
      value: "customerPhone",
    },
    {
      text: `${intl.formatMessage({ id: "tabPane.customerName", defaultMessage: "Customer Name" })}`,
      value: "customerName",
    },
    { text: `${intl.formatMessage({ id: "tabPane.address", defaultMessage: "Address" })}`, value: "address" },
  ];

  const locationSharedFilter = [
    {
      text: `${intl.formatMessage({ id: "yes", defaultMessage: "Yes" })}`,
      value: "true",
    },
    {
      text: `${intl.formatMessage({ id: "no", defaultMessage: "No" })}`,
      value: "false",
    },
  ];

  const completedFilter = [
    {
      text: `${intl.formatMessage({ id: "all", defaultMessage: "All" })}`,
      value: "All",
    },
    {
      text: `${intl.formatMessage({ id: "completed", defaultMessage: "Completed" })}`,
      value: "completed",
    },
    {
      text: `${intl.formatMessage({ id: "completed_manually_return", defaultMessage: "Completed manually return" })}`,
      value: "completed_manually_return",
      isMarquee: true,
    },
    {
      text: `${intl.formatMessage({ id: "return_completed", defaultMessage: "Return completed" })}`,
      value: "return_completed",
    },
  ];

  const canceledFilter = [
    {
      text: `${intl.formatMessage({ id: "all", defaultMessage: "All" })}`,
      value: "All",
    },
    {
      text: `${intl.formatMessage({ id: "canceled", defaultMessage: "Canceled" })}`,
      value: "canceled",
    },
    {
      text: `${intl.formatMessage({ id: "canceled_after_pickup", defaultMessage: "Canceled after pickup" })}`,
      value: "canceled_after_pickup",
      isMarquee: true,
    },
  ];
  const postponedFilter = [
    {
      text: `${intl.formatMessage({ id: "all", defaultMessage: "All" })}`,
      value: "All",
    },
    {
      text: `${intl.formatMessage({ id: "postponed", defaultMessage: "Postponed" })}`,
      value: "postponed",
    },
    {
      text: `${intl.formatMessage({ id: "postponed_after_pickup", defaultMessage: "Postponed after pickup" })}`,
      value: "postponed_after_pickup",
      isMarquee: true,
    },
  ];

  const fetchJobsByStatus = async (status) => {
    try {
      await getJobsByStatusAdminOrder({}, { query: getJobStatusQuery(status) });
    } catch (error) { }
  };
  const isRetailer = getAuthData().role === "retailer";

  const getJobs = useCallback(() => {
    fetchJobsByStatus(allowedMoveToJobStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOrdersActionModalOpen.open) {
      getJobs();
    }
  }, [isOrdersActionModalOpen, getJobs]);

  const filterOrders = (ids, orders) => {
    if (isOrdersActionModalOpen.id === "add") {
      const res = orders.filter(
        ({ id, jobId, status }) => ids.includes(id) && !jobId && CAN_BE_ASSIGNED_STATUSES.includes(status),
      );
      return res.map((el) => el.id);
    }
  };
  return {
    intl,
    isRetailer,
    orderList,
    modalTitle,
    searchFilter,
    retailerNameFilter,
    locationSharedFilter,
    completedFilter,
    postponedFilter,
    canceledFilter,
    collapsedOrder,
    isLocationModalOpen,
    isAllOrdersChecked,
    setIsLocationModalOpen,
    onCollapseOrder,
    onHandleSelectOrder,
    onChangeSearchFilter,
    onChangeSearchValue,
    isOrderChecked,
    onSelectOrder,
    onSelectAllOrders,
    onPageChange,
    onLocationShare,
    onActionBtnClick,
    onChangeRetailerNameFilter,
    onChangeLocationSharedFilter,
    searchRetailer,
    addLocationToLocal,
    handleDeleteManyOrders,

    isOrdersActionModalOpen,
    setOrdersActionModalOpen,
    fetchJobsByStatus,
    filterOrders,
  };
};
