import React, { useCallback } from "react";
import { EditPriceSetting, MobileViewProps } from "../../types";
import { Button, InputNumber, Row, Typography } from "antd";
import "../../styles/mobile-view.less";
import Divider from "src/components/Divider";
import { MobileTable } from "src/components/AdminPanel/Mobile/MobileTable";
import { MobileTableRows } from "src/components/AdminPanel/Mobile/MobileTable/components/MobileTableRows";
import { addLeadingZeros } from "src/helpers/formatUserData";
import { IPriceSetting } from "src/entities/price-setting";
import TableColumnDecorator from "src/components/TableColumnDecorator";
import EmptyListComponent from "src/pages/UserManagement/components/EmptyListComponent";
import SettingActions from "../../components/SettingActions";

const MobileView : React.FC<MobileViewProps> = ({
    companyPricesList,
    editedSetting,
    handleSavePriceSetting,
    onOpenCreateSettingModal,
    handleDeleteSetting,
    IsEditing,
    setEditedSetting,
  }) => {

    const onChange = useCallback((update: any) => {
      setEditedSetting((prevState: EditPriceSetting | null) => {
        if(prevState) {
          return {...prevState , ...update};
        }
        return prevState;
      });
    },[]);

    return (
      <>
        <div className="mobile-page-wrapper">
          <Row justify="space-between" align="middle">
            <Typography.Title level={5} className="page-title">Price Settings Management</Typography.Title>
            <Button onClick={onOpenCreateSettingModal} type="primary">Add New Setting</Button>
          </Row>
          <Divider />
          <div className="content-block-price-settings">
            {
              companyPricesList?.length ?
              <MobileTable>
                {companyPricesList?.map((setting:IPriceSetting) => (
                  <div className="table-item" key={setting.id}>
                    <MobileTableRows
                      rows={[
                        {
                          label: "Id",
                          value: `${addLeadingZeros(setting.id) || "No id"}`,
                        },
                        { label: "Tenant", value: setting.tenant.name },
                        { label: "Currency", value: setting.currency },
                        { label: "Initial Delivery Fee", value: ( () => {
                          if(IsEditing(setting.id)) {
                            return TableColumnDecorator(InputNumber , {
                              value: editedSetting?.initialDeliveryFee,
                              onChange: (value : number) => onChange({initialDeliveryFee: value})
                            });
                          }
                          else {
                            return `${setting.initialDeliveryFee || "0"}`;
                          }
                          }
                        )() },
                        { label: "Max Km Limit (Km)", value: ( () => {
                            if(IsEditing(setting.id)) {
                              return TableColumnDecorator(InputNumber , {
                                value: editedSetting?.maxKmLimit,
                                onChange: (value : number) => onChange({maxKmLimit: value})
                              });
                            }
                            else {
                              return `${setting.maxKmLimit || "0"}`;
                            }
                          }
                        )() },
                        { label: "Additional Cost Per Km", value: (() => {
                            if(IsEditing(setting.id)) {
                              return TableColumnDecorator(InputNumber , {
                                value: editedSetting?.additionalCostPerKm,
                                onChange: (value : number) => onChange({additionalCostPerKm: value})
                              });
                            }
                            else {
                              return `${setting.additionalCostPerKm || "0"}`;
                            }
                          }
                        )() },
                        {
                          label: "Actions",
                          value: (() => {
                            return TableColumnDecorator(SettingActions, {
                              id : setting.id,
                              data: {
                                maxKmLimit : setting.maxKmLimit,
                                initialDeliveryFee : setting.initialDeliveryFee,
                                additionalCostPerKm : setting.additionalCostPerKm
                              },
                              handleDeleteSetting,
                              IsEditing,
                              setEditedSetting,
                              editedSetting,
                              handleSavePriceSetting
                            });
                          })(),
                        },
                      ]}
                    />
                  </div>
                ))}
              </MobileTable> :
              <EmptyListComponent isFetching={companyPricesList?.length === 0} title="No settings found" description="Please add price settings" />
            }
          </div>
        </div>
      </>
      );
}

export default MobileView;