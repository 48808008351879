import { Popconfirm, Select } from "antd";
import React from "react";
import { AccessTypeProps } from "../types";
import { transformUserRole } from "src/helpers/formatUserData";

const { Option } = Select;

const AccessType : React.FC<AccessTypeProps> = ({ 
    id, 
    filteredRoles, 
    roleChoice, 
    handleRoleChange, 
    handleSelectRole, 
    accessType, 
    rolePopcofirmVisible }) => {
    return (
        (
            <Popconfirm
                title={`Are you sure to change role to ${transformUserRole(`${roleChoice}`)}?`}
                onConfirm={handleRoleChange(id)}
                okText="Yes"
                cancelText="No"
                visible={rolePopcofirmVisible == id}
            >
                <Select
                    onSelect={handleSelectRole(id)}
                    style={{ width: 150 }}
                    value={accessType}
                    filterOption={false}
                >
                    {filteredRoles.map((e: string) => (
                        <Option key={e} value={e}>
                            {transformUserRole(e)}
                        </Option>
                    ))}
                </Select>
            </Popconfirm>
        ) || "No role"
    );
}

export default AccessType;
