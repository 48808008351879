import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import GoogleMapReact from "google-map-react";
import { Drawer, Form, Button, notification } from "antd";

import { API_KEY } from "../../../../constants/config";
import Customer from "../../../markers/Customer";
import LocationInputForm from "./LocationInputForm";
import InitialAddressForm from "./InitialAddressForm";

// const initialAddressMessages = {
//   address: <FormattedMessage id="map.orderInfo.customer.initialAddress.address" defaultMessage="Address:" />,
//   building: <FormattedMessage id="map.orderInfo.customer.initialAddress.address.building" defaultMessage="Building:" />,
//   street: <FormattedMessage id="map.orderInfo.customer.initialAddress.address.street" defaultMessage="Street:" />,
//   zone: <FormattedMessage id="map.orderInfo.customer.initialAddress.address.zone" defaultMessage="Zone:" />,
//   city: <FormattedMessage id="map.orderInfo.customer.initialAddress.address.city" defaultMessage="City:" />,
// };

const additionalInfoMessages = {
  orderReference: (
    <FormattedMessage id="map.orderInfo.additionalInfo.orderReference" defaultMessage="Order reference:" />
  ),
  unit: <FormattedMessage id="map.orderInfo.additionalInfo.address.unit" defaultMessage="Unit:" />,
  goodsDesc: (
    <FormattedMessage id="map.orderInfo.additionalInfo.address.goodsDesc" defaultMessage="Goods description:" />
  ),
  remarks: <FormattedMessage id="map.orderInfo.additionalInfo.address.remarks" defaultMessage="Remarks:" />,
  piecesNumber: (
    <FormattedMessage id="map.orderInfo.additionalInfo.address.piecesNumber" defaultMessage="No. of pcs:" />
  ),
  shipperName: (
    <FormattedMessage id="map.orderInfo.additionalInfo.address.shipperName" defaultMessage="Shipper name:" />
  ),
};

function OrderMap({
  onClose,
  selectedSharedLocation,
  updateLocation,
  updateOrders,
  getMapData,
  disabled,
  editInitialAddressOrder,
}) {
  const [customLocation, setCustomLocation] = useState({
    lat: parseFloat(selectedSharedLocation?.deliveryPoint?.lat) || 0,
    long: parseFloat(selectedSharedLocation?.deliveryPoint?.long) || 0,
  });
  const [geolocationResponse, setGeolocationResponse] = useState({ address: null, isError: false });
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [initialAddressForm] = Form.useForm();
  const intl = useIntl();

  const resetFieldsOnClose = () => {
    form.resetFields();
    setGeolocationResponse({ address: null, isError: false });
    onClose();
  };

  const setCustomInitialAddress = () => {};

  const saveNewPosition = async () => {
    const { lat, long } = form.getFieldsValue(true);
    const { address, city, zone, building, street } = initialAddressForm.getFieldsValue(true);

    try {
      await editInitialAddressOrder({
        orderId: selectedSharedLocation.id,
        address: address.toString(),
        city: city.toString(),
        zone: zone.toString(),
        building: building.toString(),
        street: street.toString(),
      });
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Customer info is invalid!",
      });
    }
    const geocode = await getMapData({ lat, long }, "coords");
    if (geocode) {
      try {
        await updateLocation({
          selectedId: selectedSharedLocation.id,
          lat: parseFloat(lat),
          long: parseFloat(long),
          old: true,
          address: geocode.address,
        });
      } catch (e) {
        notification.error({
          message: "Error",
          description: "Can't change location of this order!",
        });
      }
      await updateOrders();
      onClose();
    } else {
      notification.error({
        message: "Error",
        description: "Location is invalid",
      });
    }
  };

  const lat = selectedSharedLocation?.deliveryPoint?.lat
    ? +selectedSharedLocation.deliveryPoint.lat
    : +selectedSharedLocation.deliveryPoint?.lat;
  const lng = selectedSharedLocation?.deliveryPoint?.long
    ? +selectedSharedLocation.deliveryPoint.long
    : +selectedSharedLocation.deliveryPoint?.long;
  const latLng = lat && lng;

  return (
    <Drawer width={640} placement="right" onClose={resetFieldsOnClose} visible={selectedSharedLocation}>
      <div>
        <p className="orderInfotitle" style={{ marginBottom: 24 }}>
          <FormattedMessage id="map.orderInfo" defaultMessage="Order info for tracking number" />
          <span>№{selectedSharedLocation.trackingId}</span>
        </p>
        <div style={{ height: "500px", width: "100%" }}>
          <GoogleMapReact
            options={{ clickableIcons: false, fullscreenControl: false }}
            bootstrapURLKeys={{ key: API_KEY }}
            center={{ lat: customLocation?.lat, lng: customLocation?.long }}
            defaultZoom={10}
            onClick={({ x, y, lat, lng, event }) => {
              if (!disabled) {
                setCustomLocation((old) => ({
                  ...old,
                  lat: parseFloat(lat),
                  long: parseFloat(lng),
                }));
              }
            }}
            distanceToMouse={() => {}}
          >
            <Customer orderInfo={selectedSharedLocation} lat={lat} lng={lng} />
            {customLocation?.lat !== lat || customLocation?.long !== lng ? (
              <Customer
                orderInfo={selectedSharedLocation}
                color="blue"
                lat={customLocation?.lat}
                lng={customLocation?.long}
              />
            ) : null}
          </GoogleMapReact>
        </div>
        {updateLocation && (
          <div>
            <h2>
              <FormattedMessage id="map.orderInfo.updateLocation.title" defaultMessage="Update order location:" />
            </h2>
            <p>
              <LocationInputForm
                disabled={disabled}
                form={form}
                customLocation={customLocation}
                setCustomLocation={setCustomLocation}
                setIsLoading={setIsLoading}
                setGeolocationResponse={setGeolocationResponse}
                getMapData={getMapData}
              />
              <Button
                onClick={saveNewPosition}
                disabled={
                  !customLocation?.lat || !customLocation?.long || geolocationResponse.isError || isLoading || disabled
                }
              >
                <FormattedMessage id="map.orderInfo.updateLocation.button" defaultMessage="Save" />
              </Button>
            </p>
          </div>
        )}
        <div>
          <h2>
            <FormattedMessage id="map.orderInfo.customer.title" defaultMessage="Customer:" />
          </h2>
          <p>
            <FormattedMessage id="map.orderInfo.customer.name" defaultMessage="Name:" />{" "}
            {selectedSharedLocation.customerInfo.name}
          </p>
          <p>
            <FormattedMessage id="map.orderInfo.customer.phoneNumber" defaultMessage="Phone number:" />{" "}
            {selectedSharedLocation.customer.phoneNumber}
          </p>
          <p>
            <FormattedMessage id="map.orderInfo.customer.location" defaultMessage="Lat and long:" />{" "}
            {latLng
              ? `lat: ${lat} long: ${lng}`
              : `${intl.formatMessage({
                  id: "map.orderInfo.customer.noLocation",
                  defaultMessage: "lat and long weren't found",
                })}`}
          </p>
          {selectedSharedLocation.deliveryPoint.initialAddress && (
            <>
              {/* Object.entries(selectedSharedLocation.deliveryPoint.initialAddress).map(
              ([key, val]) =>
                val && (
                  <p>
                    {initialAddressMessages[key]} {val}
                  </p>
                ),
            ) */}

              <InitialAddressForm
                form={initialAddressForm}
                disabled={disabled}
                setCustomInitialAddress={setCustomInitialAddress}
                setIsLoading={setIsLoading}
                selectedSharedLocation={selectedSharedLocation}
              />
            </>
          )}
          <p>
            <FormattedMessage id="map.orderInfo.customer.cash" defaultMessage="Amount Cash On Delivery:" />{" "}
            {selectedSharedLocation.cashAmount}
          </p>
          <h2>
            <FormattedMessage id="map.orderInfo.orderDetails.title" defaultMessage="Order details:" />
          </h2>
          {Object.entries(selectedSharedLocation.additionalInfo).map(
            ([key, val]) =>
              val && (
                <p>
                  {additionalInfoMessages[key]} {val}
                </p>
              ),
          )}
        </div>
      </div>
    </Drawer>
  );
}

export default OrderMap;
