import { useEffect, useRef, useCallback } from "react";
import { Manager } from "socket.io-client";
import { notification } from "antd";

import { getAuthData, getUserTenant } from "../../../helpers/authStorage";
import { API_URL } from "../../../constants/config";

export const useSockets = ({ fetchDeliveryJobs, getRoutesDeliveryJob, expandedRowKeys, withErrors }) => {
  const socketRef = useRef();

  const token = encodeURIComponent(getAuthData().accessToken);

  const onConnect = () => {
    console.log("connected");
  };

  const onJobUpdate = useCallback(
    async (jobs) => {
      const { jobId } = jobs[0].data;
      await fetchDeliveryJobs({}, { query: { withError: withErrors } });
      if (jobId === expandedRowKeys) {
        await getRoutesDeliveryJob({ jobId });
      }
    },
    [fetchDeliveryJobs, getRoutesDeliveryJob, expandedRowKeys, withErrors],
  );

  const onException = (data) => {
    notification.error({
      message: "Error",
      description: "Error with websockets",
    });
  };

  const onDisconnect = () => {
    console.log("Disconnected");
  };

  useEffect(() => {
    const manager = new Manager(`${API_URL}/`, {
      reconnectionDelayMax: 10000,
      transports: ["websocket"],
      path: "/socket.io",
      query: {
        token,
        tenant: getUserTenant(),
      },
    });

    socketRef.current = manager.socket("/admin-notifications");

    socketRef.current.on("connect", onConnect);
    socketRef.current.on("exception", onException);
    socketRef.current.on("disconnect", onDisconnect);

    return () => {
      socketRef.current.off("connect", onConnect);
      socketRef.current.off("exception", onException);
      socketRef.current.off("disconnect", onDisconnect);
    };
  }, [token]);

  useEffect(() => {
    socketRef.current.on("job-update", onJobUpdate);
    return () => {
      socketRef.current.off("job-update", onJobUpdate);
    };
  }, [onJobUpdate]);
};
