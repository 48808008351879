import React, { useState } from "react";
import { addLeadingZeros } from '../../../helpers/formatUserData';
import TableColumnDecorator from 'src/components/TableColumnDecorator';
import TenantsSelect from "../components/TenantsSelect";
import AccessType from "../components/AccessType";
import DeleteUser from "../components/DeleteUser";
import { ColumnsProps } from "../types";
import { IUser } from "src/entities";

export default ({roles , changeRole , handleDeleteUser, tenants } : ColumnsProps) => {
  const filteredRoles = roles.filter((e:string) => e !== "guest");
  const [roleChoice, setRoleChoice] = useState('');
  const [rolePopcofirmVisible, setRolePopcofirmVisible] = useState<number | string>("");

  const handleRoleChange = (id: number) => (e? : React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    changeRole(id, roleChoice);
    // @ts-ignore
    setRolePopcofirmVisible("");
  };
  const handleSelectRole = (id:number) => (value:string) => {
    setRoleChoice(value);
    setRolePopcofirmVisible(id);
  };

  return [
    {
      title: "User ID",
      key: "id",
      render: ({ id  } : IUser) => {
        if (id) {
          return `${addLeadingZeros(id) || "No id"}`;
        }
      },
    },
    {
      title: "Email",
      key: "email",
      render: ({ email = {} }) => {
        if (email) {
          return `${email || "No mail"}`;
        }
      },
    },
    {
      title: "Name",
      key: "userName",
      render: ({ userName } : IUser) => {
        if (userName) {
          return `${userName || "No name"}`;
        }
      },
    },
    {
      title: "Tenants",
      key: "tenant",
      render: ({ id, accessType } : IUser) => {
        const content = TableColumnDecorator(TenantsSelect, {
          defaultTenants : tenants,
          id,
          accessType
        });
        return content;
      },
    },
    {
      title: "Role",
      key: "accessType",
      render : ({ id ,  accessType } : IUser) => {
        const content = TableColumnDecorator(AccessType, {
          id,
          accessType,
          filteredRoles,
          roleChoice,
          handleRoleChange,
          handleSelectRole,
          rolePopcofirmVisible
        });
        return content;
      }
    },
    {
      title: "Actions",
      key: "id",
      render : ({ id } : IUser) => {
        const content = TableColumnDecorator(DeleteUser, {
          id,
          handleDeleteUser,
        });
        return content;
      }
    },
  ];
};
