import { useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { notification } from "antd";

import { getQueryParam, updatePageUrl } from "../../../../helpers/general";

export const useHooks = ({
  defaultUrl,
  locationTablePagination,
  deliveryTablePagination,
  retailerSMSTablePagination,
  fetchLocationsChangeAdminCustomers,
  fetchDriversPoints,
  checkRecordAdminCustomers,
  archiveRecordAdminCustomers,
  approveDriversPoints,
  rejectDriversPoints,
  waitDriversPoints,
  clearSelection,
  retailerChangesTablePagination,
  approveRetailerChangeNotifications,
  rejectRetailerChangeNotifications,
  fetchRetailerChangeNotifications,

  getSMSChangesNotifications,
  approveSMSChangesNotifications,
  rejectSMSChangesNotifications,
  changeRetailerSMSrightsAdminRetailers,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [tabName, setTabName] = useState("locations");
  const [statusFilter, setStatusFilter] = useState("");

  const getQueryParams = useCallback(() => getQueryParam(location.search), [location.search]);

  const fetchData = useCallback(
    (cb, isBadLocation, statusFilterArg) => {
      const queryParams = getQueryParams();
      const queryWithPage = queryParams?.page ? queryParams : { page: 1, limit: 5 };

      cb(
        {},
        {
          query: {
            ...queryWithPage,
            failed: tabName === "bad_locations",
            ...(statusFilterArg && { status: statusFilterArg }),
          },
        },
      );
    },
    [getQueryParams, tabName],
  );

  const onConfirmModal = async () => {
    try {
      switch (modalVisible?.type) {
        case "status":
          await checkRecordAdminCustomers({ recordId: modalVisible?.requestId });
          setModalVisible(false);
          await fetchData(fetchLocationsChangeAdminCustomers);
          break;
        case "archive":
          await archiveRecordAdminCustomers({ recordId: modalVisible?.requestId });
          setModalVisible(false);
          await fetchData(fetchLocationsChangeAdminCustomers);
          break;
        case "approve":
          if (tabName === "retailer_changes") {
            await approveRetailerChangeNotifications({ id: modalVisible?.requestId });
            await fetchData(fetchRetailerChangeNotifications, false, statusFilter);
          } else if (tabName === "sms_changes") {
            await approveSMSChangesNotifications({ id: modalVisible?.requestId });
            await fetchData(getSMSChangesNotifications, false, statusFilter);
          } else {
            await approveDriversPoints({ requestId: modalVisible?.requestId });
            await fetchData(fetchDriversPoints);
          }
          setModalVisible(false);
          break;
        case "reject":
          if (tabName === "retailer_changes") {
            await rejectRetailerChangeNotifications({ id: modalVisible?.requestId });
            await fetchData(fetchRetailerChangeNotifications, false, statusFilter);
          } else if (tabName === "sms_changes") {
            await rejectSMSChangesNotifications({ id: modalVisible?.requestId });
            await fetchData(getSMSChangesNotifications, false, statusFilter);
          } else {
            await rejectDriversPoints({ requestId: modalVisible?.requestId });
            await fetchData(fetchDriversPoints);
          }
          setModalVisible(false);
          break;
        case "wait":
          await waitDriversPoints({ requestId: modalVisible?.requestId });
          setModalVisible(false);
          await fetchData(fetchDriversPoints);
          break;
        default:
          break;
      }
    } catch (e) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Something went wrong.",
      });
      setModalVisible(false);
    }
  };

  const onChangePagination = (page, limit) => {
    const previousQueryParams = getQueryParams();
    const newQueryParams = { page, limit, failed: tabName === "bad_locations" };

    updatePageUrl(newQueryParams, previousQueryParams, history, defaultUrl);
    clearSelection();
  };

  const onOpenModal = (type) => () => {
    setModalVisible(type);
  };

  const onCloseModal = () => {
    setModalVisible(false);
  };

  const refreshQuery = (e) => {
    if (e === "1" || e === "3")
      fetchLocationsChangeAdminCustomers(
        {},
        {
          query: { failed: e === "3" },
        },
      );
    history.push(`/${defaultUrl}`);
  };

  const currentQueryParams = getQueryParams();

  const locationPaginationConfig = {
    pageSize: Number(currentQueryParams?.limit) || 5,
    total: locationTablePagination?.totalCount,
    current: Number(currentQueryParams?.page) || 1,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
    position: ["bottomRight"],
  };

  const deliveryPaginationConfig = {
    pageSize: Number(currentQueryParams?.limit) || 5,
    total: deliveryTablePagination?.totalCount,
    current: Number(currentQueryParams?.page) || 1,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
    position: ["bottomRight"],
  };

  const retailerChangesPaginationConfig = {
    pageSize: Number(currentQueryParams?.limit) || 5,
    total: retailerChangesTablePagination?.totalCount,
    current: Number(currentQueryParams?.page) || 1,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
    position: ["bottomRight"],
  };
  const retailerSMSPaginationConfig = {
    pageSize: Number(currentQueryParams?.limit) || 5,
    total: retailerSMSTablePagination?.totalCount,
    current: Number(currentQueryParams?.page) || 1,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
    position: ["bottomRight"],
  };

  return {
    location,
    modalVisible,
    locationPaginationConfig,
    deliveryPaginationConfig,
    retailerChangesPaginationConfig,
    retailerSMSPaginationConfig,
    fetchData,
    refreshQuery,
    onChangePagination,
    onConfirmModal,
    onOpenModal,
    onCloseModal,
    tabName,
    setTabName,
    statusFilter,
    setStatusFilter,
  };
};
