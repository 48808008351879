import React from "react";
import { Row, Col } from "antd";
import cn from "classnames";

import styles from "./layout.module.less";

const Section = ({ children, className, isMobile, ...props }) => (
  <section className={cn(styles.secondarySection, className)} {...props}>
    <Row justify="center" align="middle">
      <Col lg={{ span: 22 }} xl={{ span: 20 }}>
        {children}
      </Col>
    </Row>
  </section>
);

export default Section;
