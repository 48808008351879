import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "adminCustomers",
  url: `${API_URL}/admin/customer`,

  actions: {
    fetchLocationsChange: {
      method: "GET",
      url: `${API_URL}/admin/customer/location/changes`,
      transformResponse: ({ body }) => {
        return {
          items: body.items,
          pagination: {
            currentPageNumber: body.meta.currentPage,
            numItemsPerPage: body.meta.itemsPerPage,
            totalCount: body.meta.totalItems,
          },
        };
      },
      reduce: (state, { items, pagination, status }) => ({
        ...state,
        items,
        pagination,
        loading: status === "pending",
      }),
    },
    fetchCountUnchecked: {
      method: "GET",
      url: `${API_URL}/admin/customer/location/changes/count-unchecked`,
    },
    checkRecord: {
      method: "PATCH",
      url: `${API_URL}/admin/customer/location/changes/:recordId/check`,
      reduce: (state, action) => {
        return {
          ...state,
          items: [...state.items].map((item) => {
            if (item.id === action.context.recordId) {
              item.status = "checked";
            }

            return item;
          }),
        };
      },
    },
    archiveRecord: {
      method: "PATCH",
      url: `${API_URL}/admin/customer/location/changes/:recordId/archive`,
    },
    deleteRecord: {
      method: "DELETE",
      url: `${API_URL}/admin/customer/location/changes`,
    },
    getBad: {
      method: "GET",
      url: `${API_URL}/admin/jobs/failed`, // TODO bad location endpoint
    },
  },
});

setAuthHeader(getAuthData().accessToken);
