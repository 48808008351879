import { sortBy } from "lodash";
import { PointTypeEnum, IPoint, IOrder } from "src/entities";

//
export const getLatLong = (point: any, type: PointTypeEnum) => {
  if (type === PointTypeEnum.DELIVERY) {
    if (point.warehouse) {
      // delivery for reurned order
      return { lat: Number(point?.warehouse?.lat), long: Number(point?.warehouse?.long) };
    } else {
      // delivery for normal order
      return { lat: Number(point?.deliveryPoint?.lat), long: Number(point?.deliveryPoint?.long) };
    }
  } else {
    if (point.pickupPoint) {
      // pickup for normal order
      return { lat: Number(point?.pickupPoint?.lat), long: Number(point?.pickupPoint?.long) };
    } else {
      // pickup for returned order
      return {
        lat: Number(point?.deliveryPoint?.lat),
        long: Number(point?.deliveryPoint?.long),
        pickupPointId: point?.deliveryPointId,
      };
    }
  }
};

// exatract delivery or pickup points from a job with order
export const getPointsByType = (job: any, type: PointTypeEnum, withOrder: boolean = true) => {
  let points = job
    ? job.jobRoutes
        ?.filter((point: any) => point.pointType === type)
        .map((point: any) => ({
          ...point,
          ...getLatLong(point, type),
        }))
    : [];
  if (withOrder && points?.length) {
    let sortedPoints = sortBy(points, [(point) => point.pointOrder]);
    points = sortedPoints;
  }
  return points;
};

// get main pickup point
export const getPickupPoint = (items: any[]) => {
  const pickups = items && items[0] ? getPointsByType(items[0], PointTypeEnum.PICKUP) : [];
  const defaultPickUpPoint = pickups.length ? pickups[0].pickupPoint : items && items[0]?.orders[0].pickupPoint;
  return defaultPickUpPoint;
};

// get polyline
export const getPolyline: (job: { polyline: string }) => string | undefined = (job: { polyline: string }) => {
  if (job?.polyline) {
    return job?.polyline;
  }
  // TODO else run computeRoute from FE ?
  return undefined;
};

export const splitOrdersByLocation = (array: any[]) => {
  const arrayTrue = array.filter((e) => e.locationShared);
  const arrayFalse = array.filter((e) => !e.locationShared);
  return [arrayTrue, arrayFalse];
};

/** GET ORDERS FROM JOB ROUTES  **/
/* For normal orders we just extract orders from delivery points i.e. PointType.DELIVERY */
/* For returned orders, we extract orders from delivery points i.e. PointType.DELIVERY 
  then get their pickup from pickups list using customer ID */
export const getDeliveryOrders = (points: IPoint[]) => {
  const delOrders: IOrder[] = [];
  const deliveries = points.filter((el: IPoint) => el.pointType === PointTypeEnum.DELIVERY);
  const pickups = points.filter((el: IPoint) => el.pointType === PointTypeEnum.PICKUP);
  deliveries.forEach((point: IPoint) => {
    if (point.deliveryPoint && point.order) {
      // case of normal delivery order
      const { ...pointInfos } = point.deliveryPoint;
      delOrders.push({ ...point.order, ...{ deliveryPoint: pointInfos }, ...{ pointOrder: point.pointOrder } });
    } else if (point.warehouse && point.order) {
      // case of returned order
      // get delivery point i.e. warehouse
      const { ...pointInfos } = point.warehouse;

      // find appropriate pickup
      const pickup = pickups.find((pp) => pp?.deliveryPoint?.id === point.order?.deliveryPoint?.id)?.deliveryPoint;

      delOrders.push({
        ...point.order,
        ...{ deliveryPoint: pointInfos },
        ...{ pickupPoint: pickup },
        ...{ pointOrder: point.pointOrder },
      });
    }
  });
  return delOrders;
};
