import GoogleMapReact from "google-map-react";
import React, { FC } from "react";
import Customer from "src/components/markers/Customer";
import { API_KEY } from "src/constants/config";
import IPickup from "src/entities/pickup.entity";

const RetailerMap: FC<{ pickupPoints: IPickup[] }> = ({ pickupPoints }) => {
  const defaultPickup = pickupPoints.find((pickup: any) => pickup.main);
  return (
    <GoogleMapReact
      options={{ clickableIcons: false, fullscreenControl: false }}
      bootstrapURLKeys={{ key: API_KEY, libraries: ["places"] }}
      center={{ lat: Number(defaultPickup?.lat || "0"), lng: Number(defaultPickup?.long || "0") }}
      defaultZoom={10}
    >
      {pickupPoints.map((pickup: any) => (
        <Customer key={pickup?.id} lat={pickup.lat} lng={pickup.long} />
      ))}
    </GoogleMapReact>
  );
};

export default RetailerMap;
