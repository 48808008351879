/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useIntl } from "react-intl";
import { notification } from "antd";

export const useHooks = ({ isMobile, getUsersRetailer, createUserRetailer, deleteUserRetailer }) => {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  const [users, setUsers] = useState([]);
  const [paginationData, setPaginationData] = useState();

  const [query, setQuery] = useState({ page: 1, limit: localStorage.getItem("usersOnPage") || 5, status: "" });

  const paginationConfig = {
    pageSize: query.limit,
    total: paginationData?.totalCount || 5,
    current: query.page,
    pageSizeOptions: isMobile ? [5, 10, 20] : [10, 20, 30, 50],
    disabled: paginationData?.totalPages === 1,
    position: ["bottom"],
  };

  useEffect(() => {
    getUsers();
  }, []);

  const createUser = (rawUser) => {
    const user = { ...rawUser };
    user.email = user.email.toLowerCase();
    createUserRetailer(user).then(
      () => {
        notification.success({
          message: "Success",
          description: `${intl.formatMessage({ id: "success.inviteSent", defaultMessage: "Invite sent!" })}`,
        });
      },
      (err) => {
        notification.error({
          message: "Error",
          description: `${intl.formatMessage({ id: "error.invalidEmail", defaultMessage: "Error!" })}`,
        });
      },
    );
  };
  const deleteUser = (user) => {
    deleteUserRetailer(user).then(
      () => {
        notification.success({
          message: "Success",
        });
        getUsers();
      },
      () => {
        notification.error({
          message: "Error",
          description: `${intl.formatMessage({ id: "error", defaultMessage: "Error!" })}`,
        });
      },
    );
  };

  const getUsers = () => {
    setLoading(true);
    getUsersRetailer({}, { query: { page: query.page, limit: query.limit } }).then((res) => {
      setPaginationData(res.body.meta);
      setUsers(res.body.items);
    });
    setLoading(false);
  };

  const onChangeTable = async (pagination, filters) => {
    const { current, pageSize } = pagination;
    setQuery({
      ...query,
      page: current,
      limit: pageSize,
    });
    if (pageSize !== query.limit) {
      localStorage.setItem("usersOnPage", pageSize);
    }
  };

  return {
    intl,
    loading,
    users,
    createUser,
    deleteUser,
    getUsers,
    onChangeTable,
    paginationConfig,
  };
};
