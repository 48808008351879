import { Badge, Button, Modal, Select, Tag, Tooltip } from "antd";
import React, { FC, useState } from "react";
import CommentBox from "../CommentBox";
import { TicketStatusEnum, UsersRolesEnum } from "../type";
import { ITicketDetailsModalProps } from "./type";
import { useTicketDetails } from "./useTicketDetails";
import "./ticket-detail-modal.less";

const { Option } = Select;
const statusSelectStyles = { width: 100 };

const TicketDetailsModal: FC<ITicketDetailsModalProps> = ({
  ticketDetails,
  ticketDetails: { id: ticketId, status, labels, isReadRetailer, isReadAdmin },
  orderId,
  updateTicket,
  updateOrderTicketState,
  users,
  currentRole,
  sendMessage,
  getMessages,
  jobId,
  currentView,
  buttonType,
}) => {
  const [isTicketOpen, setIsTicketOpen] = useState<boolean>(false);
  const [ticketStatus, setTicketStatus] = useState<string>(status);
  const ticketUsers = {
    driver: users[UsersRolesEnum.DRIVER],
    retailer: users[UsersRolesEnum.RETAILER],
    super_admin: UsersRolesEnum.SUPER_ADMIN,
    operations_team: UsersRolesEnum.OPERATIONS_TEAM,
    driver_manager: UsersRolesEnum.DRIVER_MANAGER,
    customer_service: UsersRolesEnum.CUSTOMER_SERVICE,
    event: UsersRolesEnum.EVENT,
  };

  const isRetailer = currentRole === UsersRolesEnum.RETAILER;
  const isRead = isRetailer ? isReadRetailer : isReadAdmin;
  const { onTicketUpdate, onUpdateOrderTicketState } = useTicketDetails(updateTicket, updateOrderTicketState);

  const showTicket = () => {
    setIsTicketOpen(true);
  };

  const closeTicket = () => {
    setIsTicketOpen(false);
    onUpdateOrderTicketState({
      ticket: {
        ...ticketDetails,
        ...(isRetailer ? { isReadRetailer: true } : { isReadAdmin: true }),
      },
      orderId,
      jobId,
    });
  };

  const handleOnStatusChange = (status: string) => {
    onTicketUpdate({
      tickePayload: {
        ...ticketDetails,
        status,
      },
      orderId,
      jobId,
    });
    setTicketStatus(status);
  };

  return (
    <>
      <Button type={buttonType} onClick={showTicket}>
        View {!isRead && <Badge status="error" className="ticket-badge" />}
      </Button>

      {isTicketOpen && (
        <Modal
          title={`Ticket Id: ${ticketId} / Order Id: ${orderId}`}
          visible={isTicketOpen}
          footer={null}
          onOk={closeTicket}
          onCancel={closeTicket}
        >
          <div className="ticket-details-wrapper">
            <div className="ticket-detail-item">
              <strong>Label: </strong>
              {labels?.map((label: any) => (
                <Tooltip placement="top" title={label.toUpperCase()}>
                  <Tag color="geekblue" key={label} className="text-ellipsis">
                    {label.toUpperCase()}
                  </Tag>
                </Tooltip>
              ))}
            </div>
            <div className="ticket-detail-item">
              <strong>Status: </strong>
              <Select
                size="middle"
                value={ticketStatus}
                defaultValue={status}
                style={statusSelectStyles}
                onChange={handleOnStatusChange}
              >
                <Option value={TicketStatusEnum.CLOSE}>Close</Option>
                <Option value={TicketStatusEnum.OPEN}>Open</Option>
                <Option value={TicketStatusEnum.ON_HOLD}>On Hold</Option>
              </Select>
            </div>
          </div>
          <CommentBox
            ticketId={ticketId}
            users={ticketUsers}
            currentRole={currentRole}
            sendMessage={sendMessage}
            getMessages={getMessages}
          />
        </Modal>
      )}
    </>
  );
};

export default TicketDetailsModal;
