import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "points",
  actions: {
    get: {
      method: "GET",
      url: `${API_URL}/retailers/self/pickup-points`,
      transformResponse: ({ body }) => ({ body }),
    },
    send: {
      method: "POST",
      url: `${API_URL}/retailers/self/pickup-points`,
    },

    del: {
      method: "DELETE",
      url: `${API_URL}/retailers/self/pickup-points/:id`,
    },

    replace: {
      method: "PUT",
      url: `${API_URL}/retailers/self/pickup-points/:id`,
    },
    getRestrictions: {
      method: "GET",
      url: `${API_URL}/retailers/self/pickup-points/restrictions`,
      transformResponse: ({ body }) => body,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
