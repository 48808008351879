import React, { useState, useEffect } from 'react';
import { Menu, Row, Spin, Checkbox, notification } from 'antd';
import { useDispatch } from "react-redux";
import { actions as adminTenantsActions } from "src/redux/resources/adminTenants";
import { UserRoles } from '../constants';

export function useTenantSelect(id : number, accessType : string) {
    const [tenants, setTenants] = useState<string[]>([]);
    const [ isFetching, setIsFetching] = useState(false);
    const dispatch = useDispatch();
    const { getUserAdminTenants, addTenantUserAdminTenants, removeTenantUserAdminTenants } = adminTenantsActions;

    useEffect(() => {
        // Fetch tenants and set them in the state
        const fetchTenants = async () => {
            try {
                const { body: data } : any = await dispatch(getUserAdminTenants({ userId: id }));
                setTenants(data);
            } catch (error) {
                setTenants([]);
            }
        };
        fetchTenants();
    }, []);

    const onPressCheckBox = async (event:any, tenant:string) => {
        const targetValue = event.target.checked;
        try {
            setIsFetching(true);
            if (targetValue) {
                setTenants([...tenants, tenant]);
                await dispatch(addTenantUserAdminTenants({ userId : id, tenantName: tenant }));
                notification.success({
                    message: 'Tenant Added',
                    description: 'The tenant has been added successfully.'
                });
            }
            else {
                if(accessType === UserRoles.ADMIN) {
                    notification.error({
                        message: "Error",
                        description: "You cannot remove tenants from the admin."
                    });
                }
                else if(tenants?.length > 1) {
                    setTenants(tenants.filter((elem:string) => elem !== tenant));
                    await dispatch(removeTenantUserAdminTenants({ userId : id, tenant: tenant }));
                    notification.success({
                        message: 'Tenant Removed',
                        description: 'The tenant has been removed successfully.'
                    });
                }else {
                    notification.error({
                        message: "Error",
                        description: "You must keep at least one tenant."
                    });
                }
            }
            setIsFetching(false);
        } catch (error) {
            setIsFetching(false);   
        }
    };

    return {
        tenants,
        onPressCheckBox,
        isFetching
    };
}