import { IOrder } from "src/entities";

export interface IPreviewOrdersAction {
  freeOrders?: IOrder[];
  currentPreviewAction: IActionType;
  cancelCurrentAction: () => void;
  confirmAddOrders: (jobId: number, orderId: number[], destinationJobId: number) => Promise<void>;
  confirmMoveOrders: (jobId: number, orderId: number[], destinationJobId: number) => Promise<void>;
  confirmRemoveOrders: (jobId: number, orderId: number[], destinationJobId: number) => Promise<void>;
}

export interface IActionType {
  isOn: boolean;
  jobId?: number;
  originalJobId?: number;
  orderIds?: number[];
  type?: ActionTypeValues;
  isAllOrders?: boolean;
}

export enum ActionTypeValues {
  MOVE = "move",
  ADD = "add",
  REMOVE = "remove",
}
