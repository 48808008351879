import React, { useState, useEffect } from "react";
import { Table, Tag } from "antd";
import { API_URL } from "src/constants/config";
import moment from "moment";
import ReactCountryFlag from "react-country-flag";

const SuperOrders = () => {
  const [ordersData, setOrdersData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = async () => {
    try {
      const authToken = localStorage.getItem("authTokenSuper");

      const response = await fetch(`${API_URL}/superadmin/allorders?page=${currentPage}`, {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setOrdersData(data);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "completed":
        return "green";
      case "canceled":
        return "red";
      case "postponed":
        return "orange";
      case "processing":
        return "blue";
      case "new":
        return "#d48806";
      case "pickup_completed":
        return "#08979c";
      default:
        return "default";
    }
  };

  const getCountryFlag = (tenantId) => {
    switch (tenantId) {
      case "6d959aaa-cd6c-4302-ab0a-f6bd0133317e":
        return <ReactCountryFlag countryCode="QA" svg style={{ width: "2em", height: "2em" }} />; // Qatar flag
      case "e87625c5-7ce1-4748-ba23-7d0c57c05b0b":
        return <ReactCountryFlag countryCode="JO" svg style={{ width: "2em", height: "2em" }} />; // Jordan flag
      case "de41def3-47b7-46e8-b51b-8413eb974190":
        return <ReactCountryFlag countryCode="SA" svg style={{ width: "2em", height: "2em" }} />; // Saudi Arabia flag
      case "e1c5a591-8d0e-4a39-95b3-196666da9ecf":
        return <ReactCountryFlag countryCode="IQ" svg style={{ width: "2em", height: "2em" }} />; // Iraq flag
      case "b1c90d95-24d8-48e4-8c1c-9e9291cd4dea":
        return <ReactCountryFlag countryCode="AE" svg style={{ width: "2em", height: "2em" }} />; // UAE flag
      case "4cb7bd3a-cdb1-41c4-b07d-a54c25fbb133":
        return <ReactCountryFlag countryCode="OM" svg style={{ width: "2em", height: "2em" }} />; // Oman flag
      case "d7ad5f23-5a91-432d-abb3-eb5258f68930":
        return <ReactCountryFlag countryCode="KW" svg style={{ width: "2em", height: "2em" }} />; // Kuwait flag
      case "e0aa40ee-62f5-4626-82fc-2bcd16a8f98c":
        return <ReactCountryFlag countryCode="BH" svg style={{ width: "2em", height: "2em" }} />; // Bahrain flag
      case "236a82ea-041c-458c-afab-7ba903afaadc":
        return <ReactCountryFlag countryCode="TR" svg style={{ width: "2em", height: "2em" }} />; // Turkey flag
      default:
        return null;  
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const columns = [
    {
      title: "Order Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Packages Count",
      dataIndex: "packagesCount",
      key: "packagesCount",
    },
    {
      title: "Tenant",
      dataIndex: "tenantId",
      key: "tenantId",
      render: (tenantId) => (
        <span role="img" aria-label="flag">
          {getCountryFlag(tenantId)}
        </span>
      ),
    },
    {
      title: "Job Id",
      dataIndex: "jobId",
      key: "jobId",
    },
    {
      title: "Cash Amount",
      dataIndex: "cashAmount",
      key: "cashAmount",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("YYYY-MM-DD"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <Tag color={getStatusColor(status)}>{status}</Tag>,
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="allDriversSettings">
      <h2>Orders</h2>
      <Table
        dataSource={ordersData?.orders}
        columns={columns}
        scroll={{ x: "max-content" }}
        responsive={true}
        className="superAdminTables"
        pagination={{
          total: ordersData?.totalOrders,
          current: currentPage,
          pageSize: 10,
          onChange: handlePageChange,
        }}
        rowKey={(record) => record?.id} 

      />
    </div>
  );
};

export default SuperOrders;
