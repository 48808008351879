import { Col, Input, Row, Table, TablePaginationConfig, Select, Button, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { EmptyTableIndicator } from "src/components";
import IRetailer from "src/entities/retailer.entity";

// style
import "../../styles.less";
import { RetailersListProps } from "../../types/store-management";
import AddRetailerModal from "../AddRetailerModal";
import RetailerStatus from "../RetailerDetails/components/RetailerStatusTag";
import MobileViewRow from "./MobileViewRow";

// consts
const DEFAULT_PAGE_LIMIT = 10;
const searchFilter = [
  { text: <FormattedMessage id="email" defaultMessage="Email" />, value: "email" },
  { text: <FormattedMessage id="name" defaultMessage="Name" />, value: "name" },
];

const { Option } = Select;

const RetailersList: FC<RetailersListProps> = ({ data, isLoading, getRetailers, onAddRetailer, pagination }) => {
  // translation
  const intl = useIntl();
  const navigate = useHistory();

  // state
  const [retailerSearchQuery, setRetailerSearchQuery] = useState<{ field: string; value?: string }>({
    field: "email",
    value: undefined,
  });
  const [query, setQuery] = useState<{ page: number; limit: number; status: string }>({
    page: 1,
    limit: Number(localStorage.getItem("retailersOnPage")) || DEFAULT_PAGE_LIMIT,
    status: "",
  });

  const paginationConfig: TablePaginationConfig = {
    pageSize: query.limit,
    total: pagination?.totalCount || 5,
    current: query.page,
    pageSizeOptions: [10, 20, 30, 50],
    disabled: pagination?.totalPages === 1,
    position: ["bottomRight"],
  };

  // hooks

  useEffect(() => {
    getRetailers(retailerSearchQuery, query);
  }, [query]);

  // methods
  const handleSearchRetailer = (e: any) => {
    setRetailerSearchQuery({ ...retailerSearchQuery, value: e.target.value });
    setQuery((oldQuery) => ({ ...oldQuery, page: 1 }));
  };
  const onChangePagination = async (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => {
    const { current, pageSize } = pagination;
    setQuery({
      ...query,
      page: current ?? 0,
      limit: pageSize ?? DEFAULT_PAGE_LIMIT,
    });
    if (pageSize !== query.limit) {
      localStorage.setItem("retailersOnPage", (pageSize ?? DEFAULT_PAGE_LIMIT).toString());
    }
  };
  const selectSearchFilter = (
    <Select
      defaultValue={retailerSearchQuery.field}
      className="select-before"
      onChange={(value: string) => setRetailerSearchQuery({ ...retailerSearchQuery, field: value })}
    >
      {searchFilter.map(({ value, text }) => (
        <Option key={value} value={value}>
          {text}
        </Option>
      ))}
    </Select>
  );

  const columns: ColumnsType<IRetailer> = [
    {
      title: "Retailer",
      key: "retailer",
      responsive: ["xs"],
      render: (value, record, index) => {
        return (
          <MobileViewRow
            record={record}
            intl={intl}
            action={
              <Button
                className="mobile-btn mobile-btn--blue"
                onClick={() => navigate.push(`/admin/retailers/${value.id}/details`)}
              >
                View
              </Button>
            }
          />
        );
      },
    },
    {
      title: `${intl.formatMessage({ id: "tabPane.retailerName", defaultMessage: "Name" })}`,
      dataIndex: "name",
      key: "name",
      responsive: ["sm"],
    },

    {
      title: `${intl.formatMessage({ id: "input.officeAddress", defaultMessage: "Office address" })}`,
      dataIndex: "officeAddress",
      key: "officeAddress",
      responsive: ["sm"],
    },
    {
      title: `${intl.formatMessage({ id: "phoneNumber", defaultMessage: "Phone number" })}`,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      responsive: ["sm"],
    },

    {
      title: `${intl.formatMessage({ id: "status", defaultMessage: "Status" })}`,
      key: "status",
      render: (value, record, index) => {
        return <RetailerStatus status={value.status} toUpdate={false} />;
      },
      responsive: ["sm"],
    },
    {
      title: `${intl.formatMessage({ id: "actions", defaultMessage: "Actions" })}`,
      key: "action",
      render(value, record, index) {
        return (
          <Button type="link" onClick={() => navigate.push(`/admin/retailers/${value.id}/details`)}>
            View
          </Button>
        );
      },
      responsive: ["sm"],
    },
  ];

  return (
    <Col className="retailers-table__wrapper">
      <Row className="retailers-table__actions-bar">
        <Col className="retailers-table__actions-bar--leftside">
          <Row align="middle">
            <Typography className="main-title">
              <FormattedMessage id="retailers.title" defaultMessage="Retailers" />
            </Typography>
            <Input
              placeholder={`${intl.formatMessage({ id: "search", defaultMessage: "Search" })}`}
              addonBefore={selectSearchFilter}
              onPressEnter={handleSearchRetailer}
              className="searchDriver"
            />
          </Row>
        </Col>

        <Button type="primary" onClick={onAddRetailer}>
          <FormattedMessage id="retailers.modal.title.add" defaultMessage="Add new retailer" />
        </Button>
      </Row>
      <Table
        columns={columns}
        dataSource={data}
        rowClassName="retailers-table__row"
        rowKey={(item) => item.id}
        loading={isLoading}
        pagination={paginationConfig}
        onChange={onChangePagination}
        size="middle"
        locale={{
          emptyText: (
            <EmptyTableIndicator
              title="No retailers found"
              description="Please add a retailer to proceed with other actions."
            />
          ),
        }}
      />
    </Col>
  );
};

export default RetailersList;
