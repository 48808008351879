import { Form, Input, Modal } from "antd";
import React, { FC } from "react";
import ReactPhoneInput from "react-phone-input-2";
import { getPhoneInputCountryCode } from "src/helpers/general";
import { AddDriverModalType } from "./types";
import TenantSelector from "../../../../../components/DataEntry/TenantSelector";
import { TENANT_STORAGE_KEY } from "src/helpers/authStorage";

const AddDriverModal: FC<AddDriverModalType> = ({ visible, handleCloseModal, handleRegisterDriver }) => {
  const [form] = Form.useForm();
  const defaultTenant = localStorage.getItem(TENANT_STORAGE_KEY) ?? "Qatar";
  const handleCountryChange = (value: string) => {
    form.setFieldsValue({
      tenant: value,
    });
  };
  const onFormFinish = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        handleRegisterDriver(values);
        handleCloseModal();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  return (
    <Modal
      visible={visible}
      title={"Create Driver"}
      onCancel={handleCloseModal}
      okText="Create"
      okButtonProps={{ htmlType: "submit" }}
      onOk={() => onFormFinish()}
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ tenant: defaultTenant }}
        onFinish={onFormFinish}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        <Form.Item label="Email" name="email" rules={[{ required: true, message: "Please add your username!" }]}>
          <Input type="email" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please add a password!" }]}
          hasFeedback
        >
          <Input.Password type="password" />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="passwordConfirm"
          dependencies={["password"]}
          rules={[
            { required: true, message: "Please re-enter the password!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The two passwords that you entered do not match!"));
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password type="password" />
        </Form.Item>
        <Form.Item
          label="First name"
          name="firstName"
          rules={[
            { required: true, message: "Please add the first name!" },
            { min: 3, message: "First name must be at least 3 letters long ! " },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="Last name"
          name="lastName"
          rules={[
            { required: true, message: "Please add the last name!" },
            { min: 3, message: "Last name must be at least 3 letters long ! " },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label="Phone number"
          rules={[
            {
              required: true,
              message: "Please add a phone number!",
            },
            { min: 8, message: "Phone number must contain at least 8 digits " },
          ]}
        >
          <ReactPhoneInput defaultMask={".... .... ...."} country={getPhoneInputCountryCode()} placeholder={"Phone"} />
        </Form.Item>
        <Form.Item label="Country" name="tenant" rules={[{ required: true, message: "Please specify the country!" }]}>
          <TenantSelector
            isAdmin={false}
            isMobile={false}
            onChange={handleCountryChange}
            defaultTenant={form.getFieldValue("tenant") ?? defaultTenant}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddDriverModal;
