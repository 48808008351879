import { useState } from "react";
import { notification } from "antd";

export const useHooks = ({
  getZones,
  postZones,
  patchZones,
  delZones,
  putWarehouseZones,
  postWarehouseZones,
  delWarehouseZones,
  getWarehouseZones,
  zoneWarehouseZones,
  updateZoneStateZones,
  updateZoneNameZones,
}) => {
  const [zones, setZones] = useState([]);
  const [warehouses, setWarehouses] = useState([]);

  const loadZones = () => {
    getZones().then((zones) => {
      const newZones = zones.body;
      newZones.forEach((zone) => {
        zone.polygon = zone.form.polygon;
        zone.polygon.forEach((polygon) => {
          polygon.lng = polygon.long;
        });
      });
      setZones(newZones);
    });
  };
  const handleAddZones = (newZone) => {
    postZones(newZone).then(
      () => {
        loadZones();
      },
      (err) => {
        notification.error({
          message: "Error",
          description: "Can't create zone here!",
        });
      },
    );
  };
  const handleDeleteZones = (zone) => (event) => {
    delZones(zone).then(
      () => {
        loadZones();
      },
      (err) => {
        notification.error({
          message: "Error",
          description: "Can't delete zone!",
        });
      },
    );
  };
  const handleUpdateZones = (newZone) => {
    patchZones(newZone).then(
      () => {
        loadWarehouses();
      },
      (err) => {
        notification.error({
          message: "Error",
          description: "Can't update zone!",
        });
      },
    );
  };

  const handleZoneWarehouseChange = (zoneId) => (warehouseId) => {
    zoneWarehouseZones({ warehouseId, id: zoneId })
      .then(() => {
        loadZones();
      })
      .catch((err) => {
        notification.error({
          message: "Error",
          description: "Can't assign warehouse!",
        });
      });
  };

  const loadWarehouses = () => {
    getWarehouseZones().then((zones) => {
      const formattedWarehouses = zones.body.map((warehouse) => ({
        ...warehouse,
        lat: parseFloat(warehouse.lat),
        long: parseFloat(warehouse.long),
      }));
      setWarehouses(formattedWarehouses);
    });
  };
  const handleAddWarehouse = (newWarehouse) => {
    postWarehouseZones(newWarehouse).then(
      () => {
        loadWarehouses();
      },
      (err) => {
        notification.error({
          message: "Error",
          description: "Make sure you provided valid name and address!",
        });
      },
    );
  };
  const handleDeleteWarehouse = (warehouse) => {
    delWarehouseZones(warehouse).then(
      () => {
        loadWarehouses();
      },
      (err) => {
        notification.error({
          message: "Error",
          description: "Can't delete warehouse!",
        });
      },
    );
  };
  const handleUpdateWarehouse = (warehouse) => {
    putWarehouseZones(warehouse).then(
      () => {
        loadWarehouses();
      },
      (err) => {
        notification.error({
          message: "Error",
          description: "Can't update warehouse!",
        });
      },
    );
  };

  const handleUpdateZoneName = (zone) => {
    updateZoneNameZones(zone).then(
      ({ body: updatedZoneResp }) => {
        notification.success({
          message: "Success",
          description: "Zone name updated successfully",
        });
        handleUpdateZoneState(updatedZoneResp);
      },
      (err) => {
        notification.error({
          message: "Error",
          description: "Can't update warehouse!",
        });
      },
    );
  };

  // Pure Actions for State Manipulation
  const handleUpdateZoneState = (updatedZone) => {
    updateZoneStateZones(updatedZone).then(
      () => {},
      (err) => {
        notification.error({
          message: "Error",
          description: "Can't update warehouse!",
        });
      },
    );
  };

  return {
    zones,
    warehouses,
    loadZones,
    loadWarehouses,
    handleAddZones,
    handleUpdateZones,
    handleDeleteZones,
    handleAddWarehouse,
    handleDeleteWarehouse,
    handleUpdateWarehouse,
    handleZoneWarehouseChange,
    handleUpdateZoneState,
    handleUpdateZoneName,
  };
};
