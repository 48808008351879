export enum UsersRolesEnum {
  SUPER_ADMIN = "super_admin",
  ADMIN = "admin",
  OPERATIONS_TEAM = "operations_team",
  DRIVER_MANAGER = "driver_manager",
  DRIVER = "driver",
  RETAILER = "retailer",
  EVENT = "event",
  CUSTOMER_SERVICE = "customer_service",
}
