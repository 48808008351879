import React from "react";
import { Drawer } from "antd";
import GoogleMapReact from "google-map-react";
import { FormattedMessage } from "react-intl";

import { API_KEY } from "../../../../../constants/config";
import PickUpPoint from "../PickupPoint";

function OrderDetails({ visible, onClose, selectedRow }) {
  return (
    <Drawer width={640} placement="right" onClose={onClose} visible={visible}>
      <p className="orderInfotitle" style={{ marginBottom: 24 }}>
        <FormattedMessage id="map.orderInfo--2" defaultMessage="Order info" />
      </p>
      <div style={{ height: "500px", width: "100%" }}>
        {selectedRow?.pickupPoint?.lat && (
          <GoogleMapReact
            bootstrapURLKeys={{ key: API_KEY }}
            defaultCenter={{
              lat: +selectedRow.pickupPoint.lat,
              lng: +selectedRow.pickupPoint.long,
            }}
            defaultZoom={10}
            distanceToMouse={() => {}}
          >
            <PickUpPoint
              retailer={selectedRow.retailer}
              orderInfo={selectedRow.pickupPoint}
              lat={+selectedRow.pickupPoint.lat}
              lng={+selectedRow.pickupPoint.long}
            />
          </GoogleMapReact>
        )}
      </div>
    </Drawer>
  );
}

export default OrderDetails;
