import { Breadcrumb, Button } from "antd";
import React, { FC } from "react";
import { Link, useHistory } from "react-router-dom";
import AvailableOrdersTable from "src/components/CloudFleetRouting/AvailableOrdersTable";
import { BASE_ROUTE } from "src/routes";
import "./cloud-fleet-routing.less";

const CloudFleetRouting: FC<any> = () => {
  const navigate = useHistory();
  const navigateTo = () => navigate.push(`/admin/jobs/combine`);

  return (
    <div className="cfr-container">
      <AvailableOrdersTable />
    </div>
  );
};

export default CloudFleetRouting;
