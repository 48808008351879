import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";

const API_RESOURCE  = 'admin/company-rates';

export const { types, actions, rootReducer } = createResource({
  name: "adminPriceManagement",

  actions: {
    get: {
      method: "GET",
      url: `${API_URL}/${API_RESOURCE}`,
      transformResponse: ({ body }) => {
        return { body };
      },
    },
    createOne: {
      method: "POST",
      url: `${API_URL}/${API_RESOURCE}`,
    },
    updateOne: {
      method: "PUT",
      url: `${API_URL}/${API_RESOURCE}/:priceId`,
    },
    deleteOne: {
      method: "DELETE",
      url: `${API_URL}/${API_RESOURCE}/:priceId`,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
