export const COMPLETED_STATUSES = ["completed", "completed_manually_return"];

export const RETURN_TO_SHIPPER_STATUSES = ["return_to_shipper"];
export const POSTPONED_STATUSES = ["postponed", "postponed_after_pickup"];
export const CANCELED_STATUSES = ["canceled", "canceled_after_pickup"];
export const DELETED_STATUSES = ["deleted"];
export const ALL_STATUSES = [
  "new",
  "postponed",
  "pickup_completed",
  "in_transit",
  "in_delivery",
  "arrived",
  "completed",
  "canceled",
  "canceled_after_pickup",
  "postponed_after_pickup",
  "failed_to_deliver",
  "pickup_failed",
  "return_to_shipper",
  "deleted",
  "completed_manually_return",
];
export const CAN_BE_ASSIGNED_STATUSES = [...POSTPONED_STATUSES, "new"];
export const WITHOUT_COMPLETED_STATUSES = [
  "new",
  ...POSTPONED_STATUSES,
  "pickup_completed",
  "in_transit",
  "arrived",
  "failed_to_deliver",
  "in_delivery",
];

export const CAN_BE_ASSIGNED_TAB_QUERY = CAN_BE_ASSIGNED_STATUSES.join("&status=");
export const CURRENT_ORDERS_TAB_QUERY = WITHOUT_COMPLETED_STATUSES.join("&status=");
export const ALL_ORDERS_TAB_QUERY = ALL_STATUSES.join("&status=");
export const POSTPONED_TAB_QUERY = POSTPONED_STATUSES.join("&status=");
export const COMPLETED_TAB_QUERY = COMPLETED_STATUSES.join("&status=");
export const CANCELED_TAB_QUERY = CANCELED_STATUSES.join("&status=");
export const RETURN_TO_SHIPPER_TAB_QUERY = RETURN_TO_SHIPPER_STATUSES.join("&status=");
export const DELETED_TAB_QUERY = DELETED_STATUSES.join("&status=");
