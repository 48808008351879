import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData, saveAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";
import setResourceToken from "./config";

const withSaveTokens = (res) => {
  saveAuthData(res.body.tokens, "customer");
  setResourceToken(res.body.tokens.accessToken);
  return {
    res,
    body: res.body,
  };
};

export const { types, actions, rootReducer } = createResource({
  name: "customer",
  url: `${API_URL}/customers/self/deliveries`,

  actions: {
    logIn: {
      method: "POST",
      url: `${API_URL}/customers/auth/login`,
      transformResponse: withSaveTokens,
      reduce: (state, action) => {
        if (action.res?.body) return { ...state, user: action.res?.body };
        return state;
      },
    },
    fetch: {
      transformResponse: (res) => ({ ...res, body: res.body }),
      reduce: (state, action) => {
        if (action?.body) return { ...state, deliveries: action.body[0], allDeliveries: action.body };
        return state;
      },
    },
    fetchOrders: {
      url: `${API_URL}/customers/self/orders`,
      transformResponse: (res) => ({
        ...res,
        body: res.body, // keep this temporarly
        items: res.body.items,
        pagination: {
          currentPageNumber: res.body.meta.currentPage,
          numItemsPerPage: res.body.meta.itemsPerPage,
          totalCount: res.body.meta.totalItems,
        },
      }),
      reduce: (state, action) => {
        if (action?.body)
          return {
            ...state,
            orders: action.body.items,
            pagination: action.body.meta,
            prevLocation: action.body.prevLocation,
          };
        return state;
      },
    },

    shareLocation: {
      method: "PUT",
      url: `${API_URL}/customers/self/orders/:orderId/delivery-point`,
      reduce: (state, action) => {
        const updatedRetailer = { ...state.items, ...action.context };
        return { ...state, items: updatedRetailer };
      },
    },
    shareLocationBuilding: {
      method: "PATCH",
      url: `${API_URL}/customers/self/orders/:orderId/building`,
    },
    checkShareLocationAvailability: {
      method: "GET",
      url: `${API_URL}/customers/self/orders/:orderId/delivery-point/update-status`,
    },

    leaveFeedback: {
      method: "POST",
      url: `${API_URL}/customers/self/deliveries/:jobId/deliveryPoint/:deliveryPointId/feedback`,
    },
    getFAQRetailerData: {
      method: "GET",
      url: `${API_URL}/customers/support/retailers`,
    },
    generatePaymentLink: {
      method: "POST",
      url: `${API_URL}/payments/link`,
    },
    generatePayment: {
      method: "POST",
      url: `${API_URL}/payments/cash`,
    },
    generatePaymentLinkSMS: {
      method: "POST",
      url: `${API_URL}/payment-link-notifications`,
    },
    getPaymentResult: {
      method: "GET",
      url: `${API_URL}/payments/:uuid/verify`,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
