import { IPriceSetting } from "src/entities/price-setting";
import { addLeadingZeros } from "src/helpers/formatUserData";
import { ColumnProps, EditPriceSetting } from "../../types";
import TableColumnDecorator from "src/components/TableColumnDecorator";
import SettingActions from "../../components/SettingActions";
import { useCallback } from "react";
import { InputNumber } from "antd";

export default ({
  handleDeleteSetting,
  IsEditing,
  setEditedSetting,
  editedSetting,
  handleSavePriceSetting,
}: ColumnProps) => {
  const onChange = useCallback((update: any) => {
    setEditedSetting((prevState: EditPriceSetting | null) => {
      if (prevState) {
        return { ...prevState, ...update };
      }
      return prevState;
    });
  }, []);

  return [
    {
      title: "Setting ID",
      key: "id",
      render: ({ id }: IPriceSetting) => {
        if (id) {
          return `${addLeadingZeros(id) || "No id"}`;
        }
      },
    },
    {
      title: "Tenant",
      key: "tenant",
      render: ({ tenant }: IPriceSetting) => {
        if (tenant) {
          return `${tenant.name || "No tenant"}`;
        }
      },
    },
    {
      title: "Currency",
      key: "currency",
      render: ({ currency }: IPriceSetting) => {
        if (currency) {
          return `${currency || "No currency"}`;
        }
      },
    },
    {
      title: "Initial Delivery Fee",
      key: "initialDeliveryFee",
      render: ({ id, initialDeliveryFee }: IPriceSetting) => {
        if (IsEditing(id)) {
          return TableColumnDecorator(InputNumber, {
            value: editedSetting?.initialDeliveryFee,
            onChange: (value: number) => onChange({ initialDeliveryFee: value }),
          });
        } else {
          return `${initialDeliveryFee || "0"}`;
        }
      },
    },
    {
      title: "Max Km Limit (Km)",
      key: "maxKmLimit",
      render: ({ id, maxKmLimit }: IPriceSetting) => {
        if (IsEditing(id)) {
          return TableColumnDecorator(InputNumber, {
            value: editedSetting?.maxKmLimit,
            onChange: (value: number) => onChange({ maxKmLimit: value }),
          });
        } else {
          return `${maxKmLimit || "0"}`;
        }
      },
    },
    {
      title: "Additional Cost Per Km",
      key: "additionalCostPerKm",
      render: ({ id, additionalCostPerKm }: IPriceSetting) => {
        if (IsEditing(id)) {
          return TableColumnDecorator(InputNumber, {
            value: editedSetting?.additionalCostPerKm,
            onChange: (value: number) => onChange({ additionalCostPerKm: value }),
          });
        } else {
          return `${additionalCostPerKm || "0"}`;
        }
      },
    },
    {
      title: "Actions",
      key: "id",
      render: ({ id, maxKmLimit, initialDeliveryFee, additionalCostPerKm }: IPriceSetting) => {
        const content = TableColumnDecorator(SettingActions, {
          id,
          data: {
            maxKmLimit,
            initialDeliveryFee,
            additionalCostPerKm,
          },
          handleDeleteSetting,
          IsEditing,
          setEditedSetting,
          editedSetting,
          handleSavePriceSetting,
        });
        return content;
      },
    },
  ];
};
