// /* eslint-disable */
import React from "react";
import errorLogger from "./error-logger";
import { withRouter } from "react-router-dom";
import { ErrorPage } from "../common";

class ErrorBoundary extends React.Component {
  // this class will help us to catch errors in children components of the tree
  // check this : https://reactjs.org/docs/error-boundaries.html
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    const { location } = this.props;
    errorLogger.report(`Error Boundary Catched \n ${error} at ${location?.pathname}`);
    this.setState({ hasError: true });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      // we redirct the user the login page according to his role

      return <ErrorPage />;
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);
