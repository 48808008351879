import React, { FC } from "react";
import { actions as retailerJobActions } from "../../redux/resources/retailerJob";
import { bindActionCreators } from "redux";
import { connect, RootStateOrAny } from "react-redux";
import JobsManagement from "../../components/JobsManagement";

const RetailerJobManagement: FC<any> = ({
  isMobile,
  fetchRetailerJobs,
  getOneRetailerJob,
  getRoutesRetailerJob,
  updateCashRetailerJob,
  togglePublishRetailerJob,
  deleteIncompleteRetailerJob,
  getBadRetailerJob,
}) => {
  return (
    <JobsManagement
      isAdmin={false}
      isMobile={isMobile}
      fetchDeliveryJobs={fetchRetailerJobs}
      getOneDeliveryJob={getOneRetailerJob}
      getRoutesDeliveryJob={getRoutesRetailerJob}
      updateCashDeliveryJob={updateCashRetailerJob}
      togglePublishDeliveryJob={togglePublishRetailerJob}
      deleteIncompleteDeliveryJob={deleteIncompleteRetailerJob}
      getBadDeliveryJob={getBadRetailerJob}
    />
  );
};

const MSTP = (state: RootStateOrAny) => {
  return {
    isMobile: state.admin.isMobile,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(retailerJobActions, dispatch),
}))(RetailerJobManagement);
