import React, { useState } from "react";
import { Button, Image, Popconfirm } from "antd";

import { MobileTable } from "../../../../../components/AdminPanel/Mobile/MobileTable";
import { getDriverName, getMobileBadgeStatus, NO_INFO } from "../../../../../components/AdminPanel/Mobile/helpers";
import { MobileTableCollapseRows } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableCollapseRows";
import { MobileTableRows } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableRows";
import { MobileTableExpand } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableExpand";
import AttachmentScroll from "../../../components/AttachmentScroll";

export const DeliveriesTable = ({ reports, deleteReport }) => {
  const [expandedId, setExpandedId] = useState(-1);
  const getReportMessage = (message) => {
    if (!message || !message.trim()) {
      return NO_INFO;
    }

    if (message.length > 50) {
      return `${message.slice(0, 50)}...`;
    }

    return message;
  };

  const getReportDate = (createdAt) => {
    const date = new Date(createdAt);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  };

 
  const handleSetExpandedId = (id) => {
      setExpandedId(id);
  };
  return (
    <MobileTable>
      {reports?.map((report) => (
        <div className="table-item" key={report.id}>
          <MobileTableRows
            rows={[
              report.type !== "pickup_report"
                ? { label: "Report Message", value: getReportMessage(report.message) }
                : { label: "Order Id", value: report?.order?.id || NO_INFO },
              { label: "Status", value: getMobileBadgeStatus(report.status) },
              { label: "Created at", value: new Date(report.createdAt).toLocaleDateString() || NO_INFO },
              { label: "Driver", value: getDriverName(report.driver?.profile) },
              // report.type !== "delivery_report" ? { label: "Failure", value: report?.failureType } : null,
            ]}
          />
          <div className="delete-report">
            <Popconfirm
              title="Are you sure to delete this report?"
              onConfirm={deleteReport(report.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button className="mobile-btn mobile-btn--purple">Delete</Button>
            </Popconfirm>
          </div>
          <MobileTableExpand id={report.id} expanded={expandedId===report.id} handleSetExpandedId={handleSetExpandedId}>
            <MobileTableCollapseRows
              rows={[
                report.type !== "delivery_report" ? { label: "Reason", value: report?.reason } : null,
                { label: "Order ID", value: report.order?.id || NO_INFO },
                { label: "Tracking ID", value: report.order?.trackingId || NO_INFO },
                { label: "Driver Email", value: report.driver?.email || NO_INFO },
                {
                  label: "Driver Name",
                  value:
                    report.driver?.profile?.firstName || report.driver?.profile?.lastName
                      ? report.driver?.profile?.firstName + " " + report.driver?.profile?.lastName
                      : NO_INFO,
                },

                // { label: "Message", value: report.message || NO_INFO },
                { label: "Order Status", value: getMobileBadgeStatus(report.status) },
                report.type !== "delivery_report"
                  ? {
                      value:
                        report?.attachments?.length > 0 ? (
                          <AttachmentScroll>
                            {report?.attachments.map((e) => (
                              <>
                                <Image key={e.id} src={e.attachment.url} />
                              </>
                            ))}
                          </AttachmentScroll>
                        ) : (
                          "No attachments"
                        ),
                    }
                  : null,
                { label: "Report created at", value: getReportDate(report.createdAt) },
              ]}
            />
          </MobileTableExpand>
        </div>
      ))}
    </MobileTable>
  );
};
