import React from "react";
import moment from "moment";
import { DatePicker } from "antd";
import { NO_INFO } from "../../../../../components/AdminPanel/Mobile/helpers";
import { MobileTableCollapseRows } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableCollapseRows";

export default function AdditionalDriverStats({ driver,driverAdditionalStats, setDatePeriod, datePeriod }) {
  return (
    <MobileTableCollapseRows
      title="CHECK DRIVER"
      rows={[
        {
          label: "Orders completed",
          value:
            driverAdditionalStats?.ordersCompleted
            //  +
            //   "/" +
            //   driverAdditionalStats?.jobCompletionStats?.notCompletedOrders 
              || NO_INFO,
        },
        { label: "General cash collected", value: driverAdditionalStats?.cashStats?.cash || NO_INFO },
        { label: "Km traveled", value: Math.floor(driverAdditionalStats?.kmStats) || NO_INFO },
        {
          label: "From",
          value:
            (
              <DatePicker
                format={"YYYY-MM-DD"}
                className="DatePicker"
                defaultValue={datePeriod.from}
                value={datePeriod.from}
                onChange={(date) => {
                  setDatePeriod((prev) => {
                    if (date < prev.to) return { from: date, to: prev.to, driverId: driver.id };
                    else return prev;
                  });
                }}
                allowClear={false}
              />
            ) || NO_INFO,
        },
        {
          label: "To",
          value:
            (
              <DatePicker
                format={"YYYY-MM-DD"}
                className="DatePicker"
                defaultValue={moment(datePeriod.to)}
                value={moment(datePeriod.to)}
                onChange={(date) => {
                  setDatePeriod((prev) => {
                    if (date > prev.from) return { from: prev.from, to: date, driverId: driver.id };
                    else return prev;
                  });
                }}
                allowClear={false}
              />
            ) || NO_INFO,
        },
      ]}
    />
  );
}
