/* eslint-disable */
import React, { useEffect } from "react";
import { ReactComponent as BeeBoltLogo } from "../../assets/beeBoltLogo.svg";

import "./styles.less";

export default () => {
  let styles;

  useEffect(() => {
    styles = {
      top: window.scrollY + window.innerHeight / 2 - 40,
    };
  }, []);

  return (
    <div className="bee-loader">
      <BeeBoltLogo style={styles} id="beee" />
    </div>
  );
};
