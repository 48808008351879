import React, { createContext, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";

import { useLocation } from "react-router-dom";
import { localeEntries } from "../entries";

const getLocaleKey = () => localStorage.getItem("localeKey") || "en";
const setLocaleKey = (key) => localStorage.setItem("localeKey", key);

export const IntlContext = createContext({
  localeKey: getLocaleKey(),
  changeLocale: () => {},
});

export const IntlContextProvider = ({ children }) => {
  const localeKey = getLocaleKey();
  const [selectedLocale, setLocale] = useState(localeEntries[localeKey].locale);
  const [antdLocale, setAntdLocale] = useState(localeEntries[localeKey].antd);
  const [messages, setMessages] = useState(localeEntries[localeKey].messages);
  const location = useLocation();

  const changeLocale = (key) => {
    /* TODO :
     * the automatic RTL is not perfect , it might need more specifications using html / css properties
     */
    // document.getElementsByTagName("html")[0].setAttribute("dir", key === "en" ? "ltr" : "rtl");

    setLocaleKey(key);
    setLocale(localeEntries[key].locale);
    setAntdLocale(localeEntries[key].antd);
    setMessages(localeEntries[key].messages);
  };

  const contextValue = {
    localeKey,
    changeLocale,
  };

  useEffect(() => {
    if (location.pathname.includes("/admin/")) {
      changeLocale("en");
    }
  }, [location.pathname]);

  return (
    <IntlContext.Provider value={contextValue}>
      <IntlProvider locale={selectedLocale} messages={messages}>
        <ConfigProvider locale={antdLocale}>{children}</ConfigProvider>
      </IntlProvider>
    </IntlContext.Provider>
  );
};
