import React from "react";
import { Button, Divider, Popconfirm } from "antd";

import { BtnGroup } from "../../BtnGroup";

const ALLOWED_FORCE_NEXT = ["in_delivery", "arrived"];

export const ButtonsBlock = ({
  order,
  onOpenCashAmountModal,
  onForceNextOrder,
  onDeleteOrder,
  postponeOrderDeliveryJob,
  cancelOrderDeliveryJob,
  getOneDeliveryJob,
  jobId,
}) => {
  const isCompleted = order.status === "completed";
  return (
    <>
      <BtnGroup>
        <Popconfirm title="Are you sure to delete this order?" onConfirm={onDeleteOrder} okText="Yes" cancelText="No">
          <Button className="mobile-btn mobile-btn--purple">Delete</Button>
        </Popconfirm>
      </BtnGroup>
      <Divider />
      <Button
        hidden={!isCompleted}
        onClick={() => postponeOrderDeliveryJob({ id: order.id, job: jobId, open: true, type: "cancelCompleted" })}
        className="mobile-btn mobile-btn--purple mt-16"
      >
        Cancel Completed
      </Button>
      <BtnGroup>
        <Button
          hidden={isCompleted}
          className="mobile-btn mobile-btn--purple"
          onClick={() => cancelOrderDeliveryJob({ orderId: order.id }).then(() => getOneDeliveryJob(jobId))}
        >
          Cancel
        </Button>
        <Button
          hidden={isCompleted}
          onClick={() => postponeOrderDeliveryJob({ id: order.id, job: jobId, open: true, type: "postpone" })}
          className="mobile-btn mobile-btn--yellow"
        >
          Postpone
        </Button>
      </BtnGroup>
      {onOpenCashAmountModal && (
        <Button
          hidden={isCompleted}
          onClick={onOpenCashAmountModal({ orderId: order.id, cashAmount: order.cashAmount })}
          className="mobile-btn mobile-btn--white mt-16"
        >
          Edit Cash Amount
        </Button>
      )}
      {ALLOWED_FORCE_NEXT.includes(order.status) && (
        <Popconfirm
          hidden={isCompleted}
          title="Are you sure to move to next order?"
          onConfirm={onForceNextOrder}
          okText="Yes"
          cancelText="No"
        >
          <Button className="mobile-btn mobile-btn--gray">Move To Next Delivery</Button>
        </Popconfirm>
      )}
    </>
  );
};
