import { AsyncActionCreator } from "redux-rest-resource";
import { IJobEntity } from "./JobsEntity";
import { IOrder } from "./orderEntity";

// CONFIRM MODAL COMPONENT
export interface IJobDetailsConfirmModal {
  openModal: { open: boolean; id?: number; job?: number; type?: "postpone" | "cancelCompleted" };
  handleModalSubmit: (jobId: number, orderId: number, postponedTime: string) => Promise<void>;
  setOpenModal: React.Dispatch<
    React.SetStateAction<{ open: boolean; id?: number; job?: number; type?: "postpone" | "cancelCompleted" }>
  >;
  loading: boolean;
}

// ORDER LIST COMPONENT
export interface IOrderListProps {
  jobDetails: IJobEntity;
  onRouteChange: (isUpdating: boolean) => void;
  fetchDeliveryJobDetails: AsyncActionCreator;
  deleteOrderDeliveryJobDetails: AsyncActionCreator;
  getRoutesDeliveryJobDetails: AsyncActionCreator;
  postponeOrderDeliveryJobDetails: AsyncActionCreator;
  forceNextOrderDeliveryJobDetails: AsyncActionCreator;
  completeOrderDeliveryJobDetails: AsyncActionCreator;
  returnToShipperDeliveryJobDetails: AsyncActionCreator;
  cancelOrderDeliveryJobDetails: AsyncActionCreator;
  cancelCompletedDeliveryJobDetails: AsyncActionCreator;
  changeOrderPositionDeliveryJobDetails: AsyncActionCreator;
  updateCashDeliveryJobDetails: AsyncActionCreator;
  getPolylineDeliveryJobDetails: AsyncActionCreator;
}

export interface IJobDetailsPanelContent {
  order: IOrder;
  jobId: number;
  forceNextOrder: (jobId: number) => void;
  onUpdateCashAmount: (orderId: number, jobId: number, cashAmount: number) => Promise<void>;
}

export enum PointTypeEnum {
  PICKUP = "pickup",
  DELIVERY = "delivery",
}

export interface IOrdersListProps {}

export interface AccordionProps {
  key: string;
  isActive: boolean;
  title: JSX.Element | string;
  onToggle: (key: string) => void;
  children?: JSX.Element;
}

export interface IUseJobDetailsCompProps {
  fetchDeliveryJobDetails?: any;
  cleanDeliveryJobDetails?: any;
}

export type IUseJobDetailsComp = (props: IUseJobDetailsCompProps) => {
  fetchJobDetailsById: (id: number) => void;
  downloadJobOrderReciept: (id: number) => void;
  cleanJob: (id: number) => void;
};

// ORDER ACTION DROPDOWN
export interface IOrderActionsProps {
  jobId: number;
  orderId: number;
}

export interface IOrderActionsDropdownProps {
  deleteOrder: (props: IOrderActionsProps) => void;
  returnToShipper: (props: IOrderActionsProps) => void;
  postponeOrder: (props: IOrderActionsProps) => void;
  cancelOrder: (props: IOrderActionsProps) => void;
  completeOrder: (props: IOrderActionsProps) => void;
  cancelCompletedOrder: (props: IOrderActionsProps) => void;
  data: {
    jobId: number;
    orderId: number;
    status: string;
  };
}

// DRAGEABLE ORDER LIST COMPONENT
export interface IRenderListItemContentProps {
  isActive: boolean;
  key: string;
  order: IOrder;
}

export type TRenderListItemContent = (props: IRenderListItemContentProps) => JSX.Element;

export interface DragableOrderListProps {
  orders: IOrder[];
  renderOrderAccTitle: (order: IOrder, isActive: boolean) => JSX.Element;
  renderOrderAccBody: (order: IOrder, isActive: boolean) => JSX.Element;
  onOrderPostionChange: (pointsOrder: number[]) => void;
}

// Order Actions Hook

export interface IOrderActionsProps {
  jobId: number;
  orderId: number;
}
export interface IUseOrderOperationsProps {
  fetchDeliveryJobDetails: any;
  deleteOrderDeliveryJobDetails: any;
  getRoutesDeliveryJobDetails: any;
  postponeOrderDeliveryJobDetails: any;
  forceNextOrderDeliveryJobDetails: any;
  completeOrderDeliveryJobDetails: any;
  returnToShipperDeliveryJobDetails: any;
  cancelOrderDeliveryJobDetails: any;
  cancelCompletedDeliveryJobDetails: any;
  changeOrderPositionDeliveryJobDetails: any;
  getPolylineDeliveryJobDetails: any;
  updateCashDeliveryJobDetails: any;
  onRouteChange: (isUpdating: boolean) => void;
}

export type IUseOrderOperations = (props: IUseOrderOperationsProps) => {
  forceNextOrder: any;
  postponeOrder: any;
  completeOrder: any;
  returnToShipper: any;
  cancelOrder: any;
  deleteOrder: any;
  cancelCompletedOrder: any;
  setConfirmOrdersAction: any;
  setOperationLoading: any;
  operationLoading: any;
  confirmOrdersAction: any;
  fetchJobDetailsById: any;
  changeOrderPosition: (jobId: number, pointsOrder: number[]) => Promise<void>;
  onUpdateCashAmount: (orderId: number, jobId: number, cashAmount: number) => Promise<void>;
  updateDeliveryRoute: any;
};
