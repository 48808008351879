import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "adminTenants",

  actions: {
    get: {
      method: "GET",
      url: `${API_URL}/admin/tenants`,
    },
    getOwn: {
      method: "GET",
      url: `${API_URL}/admin/self/tenants`,
    },
    getUser: {
      method: "GET",
      url: `${API_URL}/admin/users/:userId/tenants`,
    },
    addTenantUser: {
      method: "POST",
      url: `${API_URL}/admin/users/:userId/tenants/:tenantName`,
    },
    removeTenantUser: {
      method: "DELETE",
      url: `${API_URL}/admin/users/:userId/tenants/:tenant`,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
