import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "reports",
  url: `${API_URL}/admin/jobs-reports`,

  actions: {
    fetchDrivers: {
      transformResponse: (res) => {
        return { ...res, items: res.body.items, pagination: res.body.meta };
      },
      reduce: (state, action) => {
        if (action.status !== "resolved") {
          return { ...state, driversReports: { ...state.driversReports, isFetching: true } };
        }
        return {
          ...state,
          driversReports: {
            ...state.driversReports,
            items: action.items,
            pagination: action.pagination,
            isFetching: false,
          },
        };
      },
    },
    fetchDelivery: {
      transformResponse: (res) => {
        return { ...res, items: res.body.items, pagination: res.body.meta };
      },
      reduce: (state, action) => {
        if (action.status !== "resolved") {
          return { ...state, deliveryReports: { ...state.deliveryReports, isFetching: true } };
        }
        return {
          ...state,
          deliveryReports: {
            ...state.deliveryReports,
            items: action.items,
            pagination: action.pagination,
            isFetching: false,
          },
        };
      },
    },
    fetch: {
      method: "GET",
      url: `${API_URL}/admin/reports`,
      transformResponse: ({ body }) => ({ body }),
    },
    deleteOne: {
      method: "DELETE",
      url: `${API_URL}/admin/jobs-reports/:reportId`,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
