import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";
import {
  Layout,
  Button,
  Divider,
  Drawer,
  List,
  Input,
  Spin,
  Radio,
  notification,
  Popconfirm,
  Popover,
  message,
} from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { DeleteOutlined } from "@ant-design/icons";
import "react-phone-input-2/lib/style.css";

import { actions as RetailerActions } from "../../../redux/resources/retailer";
import CustomDivider from "../../../components/Divider";

import "./style.less";

const RetailerSMS = ({
  retailer,
  isAdmin,
  getSelfInfoRetailer,
  cancelUpdateSelfRetailer,
  getLastPendingUpdateSelfRetailer,
  getOwnSMSTemplatesRetailer,
  getOwnSMSGroupsRetailer,
  createOwnSMSGroupRetailer,
  saveOneSMSTemplateRetailer,
  saveSMSTemplateGroupRetailer,
  deleteSMSTemplateGroupRetailer,
}) => {
  const { Content } = Layout;
  // const intl = useIntl();
  const [groups, setGroups] = useState([]);
  const [selectedGroupSource, setSelectedGroupSource] = useState({});
  const [selectedGroup, setSelectedGroup] = useState({});
  const [groupName, setGroupName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [lang, setLang] = useState("en");

  const intl = useIntl();

  const allowedSMS = retailer?.isCustomMessagingTemplatesAllowed;

  const fetchGroups = () => {
    if (allowedSMS) {
      setLoading(true);

      getOwnSMSGroupsRetailer().then((res) => {
        setGroups(res.body);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    getSelfInfoRetailer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // to avoid endless call requests

  useEffect(() => {
    if (isAdmin) {
    } else {
      fetchGroups();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAdmin) {
      // set loaded retailer for admin
    } else {
      //
    }

    // eslint-disable-next-line
  }, []);

  const getNewNameNum = () => {
    for (let i = 1; i < 11; i++) {
      let isAvailable = true;
      groups.forEach((e) => {
        if (e.name === "New Templates ".concat(i)) {
          isAvailable = false;
        }
      });
      if (isAvailable) {
        return i;
      }
    }
    return "";
  };

  const checkName = (loc) => {
    let res = 0;
    groups.forEach((e) => {
      if (e.name === loc.name && e.id !== loc.id) res++;
    });
    if (res > 0) return false;
    return true;
  };

  const createTemplateGroup = () => {
    if (allowedSMS) {
      createOwnSMSGroupRetailer({ name: "New Templates ".concat(getNewNameNum()) }).then(fetchGroups);
    }
  };
  const onGroupClick = (id, keepName) => () => {
    setLoading(true);

    getOwnSMSTemplatesRetailer({ groupId: id }).then((res) => {
      if (!keepName) setGroupName(res.body.name);
      const filteredData = {
        ...res.body,
        templates: res.body.templates?.filter((e) => e.messageType === "order_created"),
      };
      setSelectedGroupSource(filteredData);
      setSelectedGroup({ ...filteredData, templates: filteredData.templates.map((e) => ({ ...e, touched: false })) });
      setDrawerOpen(true);
      setLoading(false);
    });
  };

  const onChangeLang = (e) => {
    setLang(e.target.value);
  };
  const convertMessageType = (str) => {
    return str[0].toUpperCase().concat(str.slice(1)).replace(/_/g, " ");
  };
  const handleItemChange =
    (item, forced = false, isSave) =>
    (event) => {
      if (
        selectedGroup.templates.find((e) => e.messageType === item.messageType && e.language === item.language)
          .template !== event
      ) {
        setSelectedGroup((old) => ({
          ...old,
          templates: selectedGroup.templates.map((e) => {
            if (e.messageType === item.messageType && e.language === item.language) {
              return {
                ...e,
                template: event,
                touched: !forced,
              };
            }
            return e;
          }),
        }));
      } else if (
        selectedGroup.templates.find((e) => e.messageType === item.messageType && e.language === item.language)
          .template === event &&
        forced
      ) {
        setSelectedGroup((old) => ({
          ...old,
          templates: selectedGroup.templates.map((e) => {
            if (e.messageType === item.messageType && e.language === item.language) {
              return {
                ...e,
                template: event,
                touched: !forced,
              };
            }
            return e;
          }),
        }));
      }
    };
  const onSave = () => {
    selectedGroup.templates.forEach((e) => {
      if (e.touched) {
        onSaveOne(e.messageType, e.language, e.template);
      }
    });

    if (checkName({ id: selectedGroup.id, name: groupName })) {
      setLoading(true);
      saveSMSTemplateGroupRetailer({ name: groupName, groupId: selectedGroup.id }).then(
        (res) => {
          setGroupName(res.body.name);
          // setSelectedGroupSource(res.body);
          // setSelectedGroup({ ...res.body, templates: res.body.templates.map((e) => ({ ...e, touched: false })) });
          setLoading(false);
          setDrawerOpen(false);
          fetchGroups();
        },
        (e) => {
          notification.error({
            message: `${intl.formatMessage({
              id: "page.home.settings.sms.error.changeName",
              defaultMessage: "Could not change group name!",
            })}`,
            description: e.body && e.body.message && e.body.message,
          });
          setLoading(false);
        },
      );
    } else {
      notification.error({
        message: `${intl.formatMessage({
          id: "page.home.settings.sms.error.changeName",
          defaultMessage: "Could not change group name!",
        })}`,
        description: `${intl.formatMessage({
          id: "page.home.settings.sms.error.changeName.unique",
          defaultMessage: "Group name should be unique",
        })}`,
      });
      setLoading(false);
      fetchGroups();
    }
  };
  const onCancel = () => {
    setSelectedGroup({
      ...selectedGroupSource,
      templates: selectedGroupSource.templates.map((e) => ({ ...e, touched: false })),
    });
    setGroupName(selectedGroupSource.name);
  };

  const onCancelOne = (type, lang) => () => {
    setSelectedGroup({
      ...selectedGroup,
      templates: selectedGroup.templates.map((e) => {
        if (e.messageType === type && e.language === lang) {
          return selectedGroupSource.templates.find((e) => e.messageType === type && e.language === lang);
        }
        return e;
      }),
    });
  };

  const onSaveOne = (messageType, language, template) => {
    setLoading(true);
    saveOneSMSTemplateRetailer(
      { groupId: selectedGroup.id, messageType, language, template },
      { query: { language: language } },
    ).then(
      (res) => {
        // onGroupClick(selectedGroup.id, true)();
        handleItemChange({ messageType, language }, true, true)(template);
        setSelectedGroupSource((old) => ({
          ...old,
          templates: selectedGroupSource.templates.map((e) => {
            if (e.messageType === res.body.messageType && e.language === res.body.language) {
              return {
                ...e,
                template: res.body.template,
              };
            }
            return e;
          }),
        }));
        setLoading(false);
      },
      (e) => {
        notification.error({
          message: `${intl.formatMessage({
            id: "page.home.settings.sms.error.saveOne",
            defaultMessage: "Could not save template",
          })} '${convertMessageType(messageType)}(${language.toUpperCase()})'!`,
          description: e.body && e.body.message && e.body.message,
        });
        setLoading(false);
      },
    );
  };

  const onDeleteGroup = (id) => (e) => {
    e.stopPropagation();
    deleteSMSTemplateGroupRetailer({ groupId: id }).then(fetchGroups, (e) => {
      notification.error({
        message: `${intl.formatMessage({
          id: "page.home.settings.sms.error.delete",
          defaultMessage: "Could not delete group!",
        })}`,
        description: e.body && e.body.message && e.body.message,
      });
    });
  };

  const copyToClipboard = (text) => {
    message.info(
      <>
        Copied <span className="retailerSMS-highlight-var-valid">{text}</span> to clipboard
      </>,
    );
    navigator.clipboard.writeText(text);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "default":
        return "-gray";
      case "pending":
        return "-yellow";
      case "approved":
        return "-green";
      case "rejected":
        return "-red";
      default:
        return " ";
    }
  };

  return (
    <>
      <Content className="retailerCabinetContent retailerSettingsContent retailerSMS" style={{ paddingTop: 0 }}>
        <h2 className="pageTitle">
          <FormattedMessage id="page.home.settings.sms.pagetitle" defaultMessage="Template groups" />{" "}
          <Button
            disabled={!allowedSMS || groups.length >= 10}
            className="retailerSMS-title-button"
            onClick={createTemplateGroup}
            type="primary"
          >
            {`${intl.formatMessage({
              id: "page.home.settings.sms.createTemplate",
              defaultMessage: "Create Template",
            })}`}
          </Button>
        </h2>
        <CustomDivider />
        <Spin spinning={loading}>
          <Divider />
          <List
            size="large"
            className="retailerSMS-list"
            // header={<div>Header</div>}
            // footer={<div>Footer</div>}
            bordered
            dataSource={groups}
            renderItem={(item) => (
              <List.Item
                onClick={onGroupClick(item.id)}
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <span> {item.name}</span>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <span
                    className={"retailerSMS-list-item-status retailerSMS-list-item-status".concat(
                      getStatusColor(item.status),
                    )}
                  >
                    {item.status}
                  </span>
                  <Popconfirm
                    onClick={(e) => e.stopPropagation()}
                    title={`${intl.formatMessage({
                      id: "page.home.settings.sms.delete.confirm",
                      defaultMessage: "Are you sure to delete group?",
                    })}`}
                    onConfirm={onDeleteGroup(item.id)}
                    onCancel={(e) => e.stopPropagation()}
                    okText={`${intl.formatMessage({
                      id: "yes",
                      defaultMessage: "Yes",
                    })}`}
                    cancelText={`${intl.formatMessage({
                      id: "no",
                      defaultMessage: "No",
                    })}`}
                  >
                    <DeleteOutlined style={{ color: "red", fill: "red", outline: "red" }} />
                  </Popconfirm>
                </div>
              </List.Item>
            )}
          />
        </Spin>
      </Content>
      <Drawer
        width="80vw"
        className="retailerSMS-drawer"
        placement="right"
        closable
        onClose={() => setDrawerOpen(false)}
        visible={isDrawerOpen}
      >
        <Spin spinning={loading}>
          <List
            size="large"
            header={
              <div className="retailerSMS-drawer-title-wrap">
                <h3>
                  <Input
                    value={groupName}
                    danger
                    onChange={(e) => {
                      setGroupName(e.target.value);
                    }}
                    style={{
                      width: "30vw",
                      minWidth: "200px",
                      ...(!checkName({ id: selectedGroup.id, name: groupName }) && {
                        borderColor: "red",
                        boxShadow: "none",
                      }),
                    }}
                    // disabled={selectedGroup.status !== "pending"}
                  />
                  <Popover
                    content={
                      <>
                        <span className="retailerSMS-highlight-var-valid" onClick={() => copyToClipboard("{{name}}")}>
                          {"{{"}name{"}} "}
                        </span>
                        - <FormattedMessage id="tabPane.retailerName" defaultMessage="Retailer Name" />
                        <br />
                        <span className="retailerSMS-highlight-var-valid" onClick={() => copyToClipboard("{{cash}}")}>
                          {"{{"}cash{"}} "}
                        </span>
                        - <FormattedMessage id="shared.cashOnDelivery" defaultMessage="Cash On Delivery" />
                        <br />
                        <span className="retailerSMS-highlight-var-valid" onClick={() => copyToClipboard("{{link}}")}>
                          {"{{"}link{"}} "}
                        </span>
                        -{" "}
                        <FormattedMessage
                          id="page.home.settings.sms.drawer.tooltip.link"
                          defaultMessage="Customer Cabinet Link"
                        />
                        <br />
                        <span className="retailerSMS-highlight-var">
                          {"{{"}abc{"}} "}
                        </span>
                        -{" "}
                        <FormattedMessage
                          id="page.home.settings.sms.drawer.tooltip.invalid"
                          defaultMessage="Invalid Variable!"
                        />
                      </>
                    }
                    title={`${intl.formatMessage({
                      id: "page.home.settings.sms.drawer.tooltip.click",
                      defaultMessage: "Click variable to copy!",
                    })}`}
                  >
                    <Button>
                      <FormattedMessage
                        id="page.home.settings.sms.drawer.tooltip.variables"
                        defaultMessage="Variables"
                      />
                    </Button>
                  </Popover>
                  <Radio.Group
                    style={{ float: "right", marginRight: "3vw" }}
                    value={lang}
                    onChange={onChangeLang}
                    defaultValue="en"
                  >
                    <Radio.Button value="en">ENG</Radio.Button>
                    <Radio.Button disabled value="ar">
                      ARB
                    </Radio.Button>
                  </Radio.Group>
                </h3>

                <div className="retailerSMS-drawer-title">
                  <div className="retailerSMS-drawer-title-colname">
                    <FormattedMessage id="page.home.settings.sms.drawer.col.event" defaultMessage="Event name" />
                  </div>
                  <div className="retailerSMS-drawer-title-colname">
                    <FormattedMessage id="page.home.settings.sms.drawer.col.text" defaultMessage="SMS text" />
                  </div>
                </div>
              </div>
            }
            footer={
              <div className="retailerSMS-drawer-footer">
                <Button onClick={onCancel}>
                  <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
                </Button>
                <Button onClick={onSave} type="primary">
                  <FormattedMessage
                    id="page.home.settings.sms.drawer.actions.saveAll"
                    defaultMessage="Save Templates"
                  />
                </Button>
              </div>
            }
            dataSource={selectedGroup?.templates?.sort((a, b) => {
              if (a.messageType > b.messageType) return 1;
              if (a.messageType < b.messageType) return -1;
              return 0;
            })}
            renderItem={(item) => {
              if (item?.language === lang) {
                return (
                  <List.Item className="retailerSMS-drawer-list-item">
                    <span className="retailerSMS-drawer-list-item-message">
                      {convertMessageType(item.messageType)}({item.language?.toUpperCase()})
                    </span>
                    <div className="retailerSMS-drawer-list-item-template-wrapper">
                      <HighlightWithinTextarea
                        value={item.template}
                        placeholder=""
                        highlight={[
                          {
                            highlight: /{{((?!name}|cash}|link}).+)}}/g,
                            className: "retailerSMS-highlight-var",
                          },
                          {
                            highlight: /({{)(name|cash|link)(}})/g,
                            className: "retailerSMS-highlight-var-valid",
                          },
                        ]}
                        rows={4}
                        onChange={handleItemChange(item)}
                      />
                      {/* <TextArea
                        className="retailerSMS-drawer-list-item-template"
                        onChange={handleItemChange(item)}
                        rows={4}
                        value={item.template}
                      /> */}
                      {item.touched && (
                        <>
                          <div>
                            <Button
                              className="retailerSMS-drawer-list-item-template-btn"
                              onClick={onCancelOne(item.messageType, item.language)}
                              style={{ width: "50%" }}
                            >
                              <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
                            </Button>
                            <Button
                              className="retailerSMS-drawer-list-item-template-btn retailerSMS-drawer-list-item-template-btn-save"
                              type="primary"
                              style={{ width: "50%" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                onSaveOne(item.messageType, item.language, item.template);
                              }}
                            >
                              <FormattedMessage id="tabPane.actions.save" defaultMessage="Save" />
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </List.Item>
                );
              }
              return null;
            }}
          />
        </Spin>
      </Drawer>
    </>
  );
};

const MSTP = (state) => {
  return { retailer: state.retailer.retailerData };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(RetailerActions, dispatch),
}))(RetailerSMS);
