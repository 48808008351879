import React from "react";
import { Popover } from "antd";
import { v4 as uuidv4 } from "uuid";

import "./style.less";

const CustomerMarker = ({ info, jobId, color }) => {
  const content = (
    <ul className="infoList">
      {jobId && <li>JobId: {jobId}</li>}
      <hr />
      {info?.length > 0 &&
        info.map((info) => (
          <React.Fragment key={uuidv4()}>
            <li>OrderId: {info.id}</li>
            <li>Name: {info.customerInfo?.name}</li>
            <li>Address: {info.deliveryPoint?.address}</li>
            <li>Phone: {info.customerInfo?.phoneNumber}</li>
            <hr />
          </React.Fragment>
        ))}
    </ul>
  );

  return (
    <Popover content={content} title="Customer" trigger="click">
      <div className="customerMarker" style={{ backgroundColor: color }} />
    </Popover>
  );
};

export default CustomerMarker;
