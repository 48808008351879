import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "logs",
  url: `${API_URL}/admin/logs`,
  actions: {
    fetch: {
      method: "GET",
      transformResponse: ({ body }) => ({ body }),
    },
    // reduce: (state, action) => {
    //   if (action?.body) return { ...state, logs: action.body };
    //   return state;
    // },
  },
});

setAuthHeader(getAuthData().accessToken);
