import { notification } from "antd";
import { API_URL } from "../../constants/config";
import { getAuthData } from "../../helpers/authStorage";
import { refreshToken } from "../../redux/resources/changeUserToken";
import errorLogger from "../errorLogging/error-logger";

export function customFetch(resource, init) {
  return new Promise((resolve, reject) => {
    fetch(resource, init)
      .then((response) => {
        // response only can be ok in range of 2XX
        if (response.ok) {
          // you can call response.json() here too if you want to return json
          resolve(response);
        } else {
          switch (response.status) {
            case 401:
              if (response.url.includes(API_URL)) {
                refreshToken().then(() => {
                  fetch(resource, {
                    ...init,
                    headers: { ...init.headers, Authorization: `Bearer ${getAuthData().token}` },
                  }).then(
                    (res) => {
                      resolve(res);
                    },
                    (err) => {
                      reject(err);
                    },
                  );
                });
              }
              break;
            default:
              errorLogger.report(`Some error occured ${response?.status}`);
              break;
          }

          // here you also can throw custom error too
          reject(response);
        }
      })
      .catch((error) => {
        // it will be invoked mostly for network errors
        // do what ever you want to do with error here
        errorLogger.report(`API error ${error}`);
        reject(error);
      });
  });
}
