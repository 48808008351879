/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch, connect } from "react-redux";
import { Divider, Slider, Button, notification, Popconfirm, Drawer } from "antd";
import { bindActionCreators } from "redux";

import { useHooks } from "./hooks/useHooks";

import { actions as jobsMapActions } from "../../redux/resources/jobsMap";
import { actions as jobsForCombineActions } from "../../redux/resources/jobsForCombine";
import { actions as settingsActions } from "../../redux/resources/preferences";

import CustomDivider from "../../components/Divider";
import JobsCheckup from "./components/JobsCheckup";

import "./styles.less";
import { getUserTenant } from "../../helpers/authStorage";
import AreaOfOperation from "./components/AreaOfOperation";

function Preferences({ clearCombineJobsMap, clearJobsForCombine, deleteAllJobsJobsMap, checkCertitudeJobsMap }) {
  const [pointsRange, setPointsRange] = useState([10, 25]);
  const [areaOfOperationVisible, setAreaOfOperationVisible] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();

  const toggleAreaOfOperationDrawer = () => {
    setAreaOfOperationVisible((old) => !old);
  };

  const { clearAllJobs } = useHooks({
    clearCombineJobsMap,
    clearJobsForCombine,
    deleteAllJobsJobsMap,
  });
  const authData = JSON.parse(localStorage.getItem("authData"));
  const tenant = getUserTenant();
  const settings = useSelector((state) => state.preferences.settings);

  useEffect(() => {
    const { loadPreferences } = settingsActions;
    dispatch(loadPreferences());
  }, []);

  useEffect(() => {
    if (!areaOfOperationVisible) {
      const { loadPreferences } = settingsActions;
      dispatch(loadPreferences());
    }
  }, [areaOfOperationVisible]);

  useEffect(() => {
    if (settings) {
      const { minPoints, maxPoints } = settings;
      setPointsRange([minPoints, maxPoints]);
    }
  }, [settings]);

  const sliderValueChange = (value) => {
    setPointsRange(value);
  };

  const saveSettings = async () => {
    const { putPreferences, loadPreferences } = settingsActions;
    const [minPoints, maxPoints] = pointsRange;
    try {
      await dispatch(
        putPreferences({
          ...settings,
          minPoints,
          maxPoints,
        }),
      );

      notification.success({
        message: "Success",
        description: intl.formatMessage({
          id: "page.settings.success",
          defaultMessage: "Settings saved successfully!",
        }),
      });
    } catch (e) {
      await dispatch(loadPreferences());
      notification.error({
        message: "Error",
        description:
          e?.body?.message ||
          intl.formatMessage({
            id: "page.settings.error",
            defaultMessage: "Failed to save new settings!",
          }),
      });
    }
  };

  return (
    <main className="pageWrapper">
      <div>
        <h2 className="pageTitle">
          {intl.formatMessage({
            id: "page.settings.title",
            defaultMessage: "Settings",
          })}
        </h2>
        <CustomDivider />
      </div>
      {authData.access === "admin" && (
        <>
          {" "}
          <Divider />
          <Popconfirm
            placement="top"
            title="Are you you want to delete all jobs"
            onConfirm={clearAllJobs}
            okText="Yes"
            cancelText="No"
          >
            <Button className="clearDBBtn" type="primary">
              Clear DB
            </Button>
          </Popconfirm>
        </>
      )}
      <Divider orientation="left">
        {intl.formatMessage({
          id: "page.settings.packages",
          defaultMessage: "Packages range",
        })}
      </Divider>

      <div className="settings-row">
        <Slider range min={1} max={120} value={pointsRange} onChange={sliderValueChange} />
        <Button onClick={saveSettings}>
          {intl.formatMessage({
            id: "page.settings.save",
            defaultMessage: "Save",
          })}
        </Button>
      </div>
      <Divider orientation="left">Area of operation</Divider>
      <Button onClick={toggleAreaOfOperationDrawer}>Configure for {tenant}</Button>
      <Divider orientation="left">Job checkup</Divider>

      <JobsCheckup checkCertitudeJobsMap={checkCertitudeJobsMap} />
      <Drawer width="80vw" visible={areaOfOperationVisible} onClose={toggleAreaOfOperationDrawer}>
        {areaOfOperationVisible && <AreaOfOperation tenant={tenant} settings={settings} />}
      </Drawer>
    </main>
  );
}

const MSTP = (state) => ({});

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(jobsMapActions, dispatch),
  ...bindActionCreators(jobsForCombineActions, dispatch),
}))(Preferences);
