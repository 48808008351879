import { combineReducers } from "redux";
import { rootReducer as driver } from "./resources/driver";
import { rootReducer as retailer } from "./resources/retailer";
import { rootReducer as points } from "./resources/retailerPoints";
import { rootReducer as retailerInfo } from "./resources/retailerInformation";
import { rootReducer as admin } from "./resources/admin";
import { rootReducer as adminUserList } from "./resources/adminUserList";
import { rootReducer as jobsMap } from "./resources/jobsMap";
import { rootReducer as jobsForCombine } from "./resources/jobsForCombine";
import { rootReducer as deliveryJob } from "./resources/deliveryJobs";
import { rootReducer as driversManager } from "./resources/driversManager";
import { rootReducer as driversReports } from "./resources/driversReports";
import { rootReducer as deliveryReport } from "./resources/ordersReports";
import { rootReducer as customer } from "./resources/customer";
import { rootReducer as adminOrders } from "./resources/adminOrders";
import { rootReducer as retailerOrders } from "./resources/retailerOrders";
import { rootReducer as adminRetailers } from "./resources/adminRetailers";
import { rootReducer as driversMap } from "./resources/driversMap";
import { rootReducer as reports } from "./resources/reports";
import { rootReducer as statistics } from "./resources/statistics";
import { rootReducer as notifications } from "./resources/notifications";
import { rootReducer as systemReport } from "./resources/systemReport";
import { rootReducer as envStats } from "./resources/envStats";
import { rootReducer as preferences } from "./resources/preferences";
import { rootReducer as retailersGroups } from "./resources/retailersGroups";
import { rootReducer as adminCustomers } from "./resources/adminCustomers";
import { rootReducer as driversPoints } from "./resources/driversPoints";
import { rootReducer as dashboard } from "./resources/dashboard";
import { rootReducer as logs } from "./resources/logs";
import { rootReducer as accounting } from "./resources/accounting";
import { rootReducer as retailerInsights } from "./resources/retailerInsights";
import { rootReducer as zones } from "./resources/zoneManagement";
import { rootReducer as adminTenants } from "./resources/adminTenants";
import { rootReducer as adminTablesView } from "./resources/adminTablesView";
import { rootReducer as tenants } from "./resources/tenants";
import { rootReducer as payments } from "./resources/payments";
import { rootReducer as activityLogs } from "./resources/activityLogs";
import { rootReducer as retailerTicket } from "./resources/retailerTicket";
import { rootReducer as adminTicket } from "./resources/adminTicket";
import { rootReducer as retailerJob } from "./resources/retailerJob";
import { rootReducer as retailerJobsMap } from "./resources/retailerJobMap";
import { rootReducer as deliveryJobDetails } from "./resources/deliveryJobDetails";
import { rootReducer as tasks } from "./resources/tasks";
import { rootReducer as retailerPickups } from "./resources/retailerPickups";
import { rootReducer as adminPriceManagement } from "./resources/adminPriceManagement";

const reducers = combineReducers({
  activityLogs,
  adminTenants,
  adminTablesView,
  tenants,
  driver,
  dashboard,
  retailer,
  retailerInsights,
  zones,
  points,
  admin,
  adminUserList,
  jobsMap,
  jobsForCombine,
  deliveryJobs: deliveryJob,
  driversMap,
  driversManager,
  driversReports,
  deliveryReports: deliveryReport,
  customerInfo: customer,
  adminOrders,
  retailerOrders,
  retailerInfo,
  adminRetailers,
  reports,
  statistics,
  notifications,
  systemReport,
  envStats,
  preferences,
  retailersGroups,
  adminCustomers,
  driversPoints,
  logs,
  accounting,
  payments,
  retailerTicket,
  adminTicket,
  retailerJob,
  retailerJobsMap,
  deliveryJobDetails,
  tasks,
  retailerPickups,
  adminPriceManagement,
});

const rootReducers = (state, action) => {
  return reducers(state, action);
};

export default rootReducers;
