import { notification } from "antd";
import { structureErrorResponse } from "src/helpers/handleErrorResponse";
import { IuseRetailerPickups } from "../../types";

const useRetailerPickup = ({ fetchPickups, deleteOnePickup, addOnePickup, updateOnePickup }: IuseRetailerPickups) => {
  const getPickupsForRetailer = async (retailerId: number) => {
    try {
      await fetchPickups({ retailerId }, {});
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.message ?? "Unexpected error has occured while fetching pickups !",
      });
    }
  };

  const deletePickupForRetailer = async (retailerId: number, pointId: number) => {
    try {
      await deleteOnePickup({ retailerId, pickupPointId: pointId }, {});
      notification.success({
        message: "Success",
        description: "The pickup has been successfully deleted",
      });
    } catch (error: any) {
      const description = error?.body?.message
        ? structureErrorResponse(error?.body?.message)
        : "Unexpected error has occured while deleting pickups !";

      notification.error({
        message: "Error",
        description: description,
      });
    }
  };

  const addPickupForRetailer = async (retailerId: number, point: Object) => {
    try {
      await addOnePickup({ retailerId }, { body: { ...point } });
      notification.success({
        message: "Success",
        description: "The pickup has been successfully created",
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: "Unexpected error has occured while creating pickup !",
      });
    }
  };

  const updatePickupForRetailer = async (retailerId: number, point: any, originalPoint: any) => {
    let updates: any = {};

    for (let key in point) {
      if (point[key] && originalPoint[key] && point[key] !== originalPoint[key]) {
        updates[key] = point[key];
      } else if (point[key] && !originalPoint[key]) {
        updates[key] = point[key];
      }
    }
    try {
      await updateOnePickup({ retailerId, pickupPointId: point?.id }, { body: { ...updates } });
      notification.success({
        message: "Success",
        description: "The pickup has been successfully created",
      });
    } catch (error: any) {
      const description = error?.body?.message
        ? structureErrorResponse(error?.body?.message)
        : "Unexpected error has occured while updating this pickup point !";

      notification.error({
        message: "Error",
        description: description,
      });
    }
  };

  return {
    getPickupsForRetailer,
    deletePickupForRetailer,
    addPickupForRetailer,
    updatePickupForRetailer,
  };
};

export default useRetailerPickup;
