import { Modal, notification, Timeline } from "antd";
import React, { useState, useCallback, useRef, FC, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import { DragableOrderListProps, TRenderListItemContent } from "src/types/JobDetails";
import { IOrder } from "src/types/orderEntity";
import { Accordion } from "../OrdersList";
import "./dragable-order-list.less";

const { confirm } = Modal;

const ListItem = ({
  index,
  children,
  moveOrderListItemOnHover,
}: {
  index: any;
  children: JSX.Element;
  moveOrderListItemOnHover: any;
}) => {
  // useDrag - the list item is draggable
  const [{ isDragging }, dragRef] = useDrag({
    type: "item",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // useDrop - the list item is also a drop area
  const [{ isOver, dropClassName }, dropRef] = useDrop({
    accept: "item",

    drop: (item: any, monitor: any) => {
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      if (hoverBoundingRect) {
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;
        // if dragging down, continue only when hover is smaller than middle Y
        if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
        // if dragging up, continue only when hover is bigger than middle Y
        if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;
      }
      item.index = hoverIndex;
      moveOrderListItemOnHover(dragIndex, hoverIndex);
    },
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: "drop-hover",
      };
    },
  });

  // Join the 2 refs together into one (both draggable and can be dropped on)
  const ref = useRef<HTMLDivElement>(null);
  dragRef(dropRef(ref));

  // Make items being dragged transparent, so it's easier to see where we drop them
  const opacity = isDragging ? 0.4 : 1;
  return (
    <div ref={ref} style={{ opacity }} className={`${isOver ? dropClassName : ""}`}>
      {children}
    </div>
  );
};

const DragableOrderList: FC<DragableOrderListProps> = ({
  orders,
  renderOrderAccTitle,
  renderOrderAccBody,
  onOrderPostionChange,
}) => {
  const [orderList, setOrderList] = useState<IOrder[]>(orders);
  const [activeAcc, setActiveAcc] = useState<string>("");

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  const toggleAccordion = (key: string) => {
    if (key === activeAcc) {
      setActiveAcc("");
      return;
    }
    setActiveAcc(key);
  };

  const moveOrderListItemOnHover = useCallback(
    (dragIndex: any, hoverIndex: any) => {
      const originOrder = orderList[dragIndex];
      const swappedOrder = orderList[hoverIndex];

      // Swap places of dragItem and hoverItem in the pets array
      confirm({
        title: "Are you sure to change order of orders?",
        async onOk() {
          if (!originOrder || !swappedOrder) {
            notification.warning({ message: "One of the swapped orders is undefined! please retry..." });
            return;
          }

          const pointsOrder = [originOrder.pointOrder, swappedOrder.pointOrder];
          onOrderPostionChange(pointsOrder);
        },
        onCancel() {
          setOrderList(orders);
          return false;
        },
      });
    },
    [orderList],
  );

  const renderListItemContent: TRenderListItemContent = ({ isActive, key, order }) => {
    return (
      <Accordion
        isActive={isActive}
        key={key}
        onToggle={() => toggleAccordion(key)}
        title={renderOrderAccTitle(order, isActive)}
      >
        {renderOrderAccBody(order, isActive)}
      </Accordion>
    );
  };

  return (
    <>
      {orderList?.length > 0 &&
        orderList.map((order: IOrder, index: number) => {
          const key = `key-${order.id}`;
          const isActive = activeAcc === key;
          return (
            <Timeline.Item>
              <ListItem key={order.id} index={index} moveOrderListItemOnHover={moveOrderListItemOnHover}>
                {renderListItemContent({ isActive, key, order })}
              </ListItem>
            </Timeline.Item>
          );
        })}
    </>
  );
};

export default DragableOrderList;
