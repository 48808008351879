import React from "react";
import { DatePicker, Divider, Select } from "antd";

import ValueTypeSelect from "../../../components/Select";
import CustomDivider from "../../../components/Divider";
import { chartValueList } from "../system-report-config";
import SystemReportTable from "./components/Table";
import ReportConfig from "./components/ReportConifg";
import Chart from "../components/Chart";

const { RangePicker } = DatePicker;
const { Option } = Select;

export const DesktopView = ({
  driversList,
  retailersList,
  monthReportQuery,
  comapredMonthData,
  targetMonthData,
  chartReportQuery,
  chartData,
  chartDateRange,
  onSelect,
  onDatePick,
  setDateRange,
  setComparedMonth,
  setTargetMonth,
  setMonthReportQuery,
  setChartReportQuery,
  onChartValueSelect,
}) => {
  return (
    <main className="systemReportPage">
      <div>
        <h2 className="pageTitle">Month Сomparison</h2>
        <CustomDivider />
      </div>
      <Divider />
      <ReportConfig
        setQuery={setMonthReportQuery}
        onSelect={onSelect}
        driversList={driversList}
        retailersList={retailersList}
        query={monthReportQuery}
      />
      <SystemReportTable
        onChangeComparedMonth={onDatePick(setComparedMonth, "YYYY-MM-DD")}
        onChangeTargetMonth={onDatePick(setTargetMonth, "YYYY-MM-DD")}
        comparedMonthData={comapredMonthData}
        targetMonthData={targetMonthData}
      />
      <Divider />
      <ReportConfig
        setQuery={setChartReportQuery}
        onSelect={onSelect}
        driversList={driversList}
        retailersList={retailersList}
        query={chartReportQuery}
      />
      <div className="chart-options">
        <ValueTypeSelect
          list={chartValueList}
          defaultValue="cash"
          className="systemReportSelectActive"
          mapFunction={({ value, title }) => (
            <Option value={value} key={value}>
              {title}
            </Option>
          )}
          onChange={onChartValueSelect}
        />
        <RangePicker onChange={onDatePick(setDateRange, "YYYY-MM")} picker="month" format="MM.YYYY" />
      </div>
      <Chart data={chartData || []} isOneMonth={chartDateRange.from === chartDateRange.to} />
    </main>
  );
};
