import React, { useState } from "react";
import { Button, Popconfirm } from "antd";

import { ReactComponent as WarningCircle } from "../../../../../assets/adminPanel/collapse-warning-circle.svg";
import { ReactComponent as WorkingJob } from "../../../../../assets/adminPanel/working-driver-icon.svg";
import { MobileSelect } from "../../../../../components/AdminPanel/Mobile/MobileSelect";

import "./styles.less";

export const DriverJob = ({ currentJob, availableJobs, handleAssignJob, handleCancelAssignJob }) => {
  const currentJobId = currentJob?.id;
  const [selectedJob, setSelectedJob] = useState(null);

  if (currentJobId) {
    return (
      <div className="driver-job">
        <div className="working-job">
          <WorkingJob />
          <div className="working-job__text">
            Driver is working on job with ID <span>{currentJobId}</span>
          </div>
        </div>
        <Popconfirm
          title="Are you sure to unassign job from driver?"
          onConfirm={() => handleCancelAssignJob(currentJobId)}
        >
          <Button className="mobile-btn mobile-btn--red">Cancel</Button>
        </Popconfirm>
      </div>
    );
  }

  const jobsOptions = availableJobs.map((job) => ({ text: `Job ID ${job.id}`, value: job.id }));

  return (
    <div className="driver-job">
      {availableJobs.length ? (
        <div className="assign-job">
          <div className="no-current-job">
            <WarningCircle className="no-current-job__icon" />
            <div className="no-current-job__text">This driver has no current job.</div>
          </div>
          <MobileSelect options={jobsOptions} placeholder="Assign Job" onSelect={(id) => setSelectedJob(id)} />
          <Button className="mobile-btn mobile-btn--blue" onClick={() => handleAssignJob(selectedJob)}>
            Assign Job
          </Button>
        </div>
      ) : (
        <div className="no-current-job">
          <WarningCircle className="no-current-job__icon" />
          <div className="no-current-job__text">
            This driver has no current job. No available jobs to assign currently.
          </div>
        </div>
      )}
    </div>
  );
};
