import { createResource, defaultHeaders } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";
import { makeDefaultRequest } from "../../helpers/requestComponents";

export const { types, actions, rootReducer } = createResource({
  name: "preferences",

  actions: {
    load: makeDefaultRequest("GET", `${API_URL}/admin/settings`, "settings"),
    put: makeDefaultRequest("PUT", `${API_URL}/admin/settings`, "settings"),
  },
});

setAuthHeader(getAuthData().accessToken, defaultHeaders);
