import React from "react";
import { FormattedMessage } from "react-intl";
import { Badge, Select } from "antd";

const { Option } = Select;

export const NO_INFO = (
  <span className="no-info">
    <FormattedMessage id="noInfo" defaultMessage="No info" />
  </span>
);

export const transformStatus = (status) => {
  const str = status.split("_").join(" ");
  const stts = str.charAt(0).toUpperCase() + str.slice(1);
  return stts;
};

export const getMobileBadgeStatus = (status) => {
  if (["accepted", "ready", "completed", "arrived"].includes(status)) {
    return (
      <span className="badge-success">
        <Badge status="success" />
        {transformStatus(status)}
      </span>
    );
  }

  if (status === "declined") {
    return (
      <span className="badge-declined">
        <Badge status="error" />
        Declined
      </span>
    );
  }

  if (
    ["pending", "incomplete", "new", "picking_up_packages", "pickup_completed", "in_transit", "in_delivery"].includes(
      status,
    )
  ) {
    return (
      <span className="badge-pending">
        <Badge status="processing" />
        {transformStatus(status)}
      </span>
    );
  }

  if (status === "has_issue") {
    return (
      <span className="badge-issue">
        <Badge status="warning" />
        Has issues
      </span>
    );
  }

  return (
    <span className="badge-pending">
      <Badge status="processing" />
      {transformStatus(status)}
    </span>
  );
};

const DriverSelector = ({ availableDrivers, job, assignJobDriver, setSelectedJob }) => {
  return (
    <Select
      showSearch
      onMouseEnter={() => setSelectedJob(job)}
      onSelect={(id) => {
        assignJobDriver(job, id);
      }}
      style={{ width: 150 }}
      placeholder="Unassigned"
      optionFilterProp="children"
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      filterSort={(optionA, optionB) => optionA?.children.toLowerCase().localeCompare(optionB?.children.toLowerCase())}
    >
      {availableDrivers.map((e) => (
        <Option key={e.id} value={e.id}>
          {e.driver}
        </Option>
      ))}
    </Select>
  );
};

const DriverFakeSelector = ({ driver, name, job, cancelJobDriver }) => {
  return (
    <Select
      onSelect={(id) => {
        cancelJobDriver(job, id);
      }}
      style={{ width: 150 }}
      placeholder={name}
    >
      <Option value={driver.id}>Unassign</Option>
    </Select>
  );
};

export const getDriverNames = (availableDrivers, setSelectedJob, assignJobDriver, cancelJobDriver, job, driver) => {
  if (driver?.profile?.firstName && driver?.profile?.lastName) {
    return (
      <DriverFakeSelector
        name={`${driver.profile.firstName} ${driver.profile.lastName}`}
        job={job}
        driver={driver}
        cancelJobDriver={cancelJobDriver}
      />
    );
  }

  return (
    <DriverSelector
      availableDrivers={availableDrivers}
      job={job}
      setSelectedJob={setSelectedJob}
      assignJobDriver={assignJobDriver}
    />
  );
};

export const getDriverName = (profile) => {
  if (profile?.firstName && profile?.lastName) {
    return `${profile.firstName} ${profile.lastName}`;
  }
  return <div className="no-info">Unassigned</div>;
};
