import { Button, Modal, Tag } from "antd";
import React, { FC, useEffect, useState } from "react";
import { ConfirmCombineJobModalProps } from "src/types/CombineJobs";
import "../../CloudFleetRouting/CFRResults/cfr-results.less";
import "./confirm-combine-job-modal.less";

interface IJobsComulativeData {
  packageCount: number;
  pickupPoints: number;
  deliveryPoints: number;
}

const computeJobDetails = (selectedJobs: any[]): IJobsComulativeData => {
  let packageCount = 0;
  let pickupPoints = 0;
  let deliveryPoints = 0;

  selectedJobs.forEach((job) => {
    packageCount += job.packagesCount;
    pickupPoints += job.pickupPointsCount;
    deliveryPoints += job.deliveryPointsCount;
  });

  return {
    packageCount,
    pickupPoints,
    deliveryPoints,
  };
};

const ConfirmCombineJobModal: FC<ConfirmCombineJobModalProps> = ({
  isModalOpen,
  selectedJobs,
  handleOk,
  handleCancel,
  removeJob,
}) => {
  const [JobsComulativeData, setJobsComulativeData] = useState<IJobsComulativeData>(computeJobDetails(selectedJobs));

  useEffect(() => {
    setJobsComulativeData(computeJobDetails(selectedJobs));
  }, [selectedJobs]);

  const onRemoveJob = (e: React.MouseEvent<HTMLElement, MouseEvent>, id: number, index: number) => {
    e.preventDefault();
    removeJob(id, index);
  };

  return (
    <Modal title="Combine Job Preview" visible={isModalOpen} footer={null} onCancel={handleCancel}>
      <div className="messages-output">
        <div className="messages-output-row">
          <p>Delivery Point</p>
          <b>{JobsComulativeData.deliveryPoints} </b>
        </div>
        <div className="messages-output-row">
          <p>Package Count</p>
          <b>{JobsComulativeData.packageCount} </b>
        </div>
        <div className="messages-output-row">
          <p>Pickup Points</p>
          <b>{JobsComulativeData.pickupPoints} </b>
        </div>
      </div>
      <div className="selected-jobs-tag-wrapper">
        {selectedJobs.map((job, index) => {
          return (
            <Tag key={job.id} color="black" closable onClose={(e) => onRemoveJob(e, job.id, index)}>
              {job.id}
            </Tag>
          );
        })}
      </div>
      <Button
        data-testid="compare-jobs-confirm-btn"
        className="combine-btn"
        type="primary"
        disabled={selectedJobs.length < 2}
        onClick={handleOk}
      >
        Combine
      </Button>
    </Modal>
  );
};

export default ConfirmCombineJobModal;
