import React, { useEffect, useCallback, useState } from "react";
import { Button, Drawer, notification, List, Table, Radio, Input } from "antd";
// import moment from "moment";

import { StatusPopover } from "./components/StatusPopover";

const { TextArea } = Input;

export const RetailerSMSTable = ({
  location,
  data,
  pagination,
  selection,
  getQueryParams,
  onChangePagination,
  handleToggle,

  onRevokeSMSRights,
  getOneRetailerData,
  getSMSChangesGroupNotifications,
  getSMSChangesNotifications,
  approveSMSChangesNotifications,
  rejectSMSChangesNotifications,
}) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [lang, setLang] = useState("en");
  const [statusFilter, setStatusFilter] = useState("");

  const fetchData = useCallback(() => {
    const queryParams = getQueryParams();
    const queryWithPage = queryParams?.page ? queryParams : { page: 1, limit: 10 };

    getSMSChangesNotifications(
      {},
      {
        query: statusFilter ? { ...queryWithPage, status: statusFilter } : queryWithPage,
      },
    );
  }, [getSMSChangesNotifications, getQueryParams, statusFilter]);

  useEffect(() => {
    fetchData();
  }, [fetchData, location.search, statusFilter]);

  //   useEffect(() => {

  //   }, []);

  const onApproveStatus = (id) => async () => {
    try {
      await approveSMSChangesNotifications({ groupId: id });
      await fetchData();
      setDrawerOpen(false);
    } catch (e) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Something went wrong.",
      });
    }
  };

  const onRejectStatus = (id) => async () => {
    try {
      await rejectSMSChangesNotifications({ groupId: id });
      await fetchData();
      setDrawerOpen(false);
    } catch (e) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Something went wrong.",
      });
    }
  };

  //   const onWaitStatus = (requestId) => async () => {
  //     try {
  //       await waitDriversPoints({ requestId });
  //       await fetchData();
  //     } catch (e) {
  //       notification.error({
  //         message: "Error",
  //         description: e?.body?.message || "Something went wrong.",
  //       });
  //     }
  //   };

  const onOpenChanges = (id) => () => {
    getSMSChangesGroupNotifications({ groupId: id }).then((res) => {
      setDrawerOpen(true);
      setSelectedGroup(res.body);
    });
  };

  const columns = [
    {
      title: "Request ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Retailer ID",
      dataIndex: "retailerId",
      key: "retailerId",
    },
    {
      title: "Retailer name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Retailer email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Retailer phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Has rights",
      dataIndex: "isCustomMessagingTemplatesAllowed",
      key: "isCustomMessagingTemplatesAllowed",
      render: (isTrue) => (isTrue ? "True" : "False"),
    },
    {
      title: "Changes",
      dataIndex: "retailer",
      key: "retailer",
      render: (retailer, record) => {
        return record?.status === "pending" ? (
          <Button onClick={onOpenChanges(record.id)}>Review</Button>
        ) : (
          "request closed"
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "85px",
      render: (status, record) => {
        return (
          <>
            {/* eslint-disable-next-line */}
            {status === "pending" ? (
              <StatusPopover
                record={record}
                onApproveStatus={onApproveStatus}
                onRejectStatus={onRejectStatus}
                // onWaitStatus={onWaitStatus}
              />
            ) : status !== "waiting" ? (
              <span>{status}</span>
            ) : (
              <StatusPopover
                record={record}
                isWaiting
                onApproveStatus={onApproveStatus}
                onRejectStatus={onRejectStatus}
                // onWaitStatus={onWaitStatus}
              />
            )}
          </>
        );
      },
    },
  ];

  const dataSource = data?.map(({ id, retailer, status, time, updatePayload }) => {
    return {
      id,
      retailer,
      retailerId: retailer?.id,
      name: retailer?.name,
      email: retailer?.email,
      phoneNumber: retailer?.phoneNumber,
      updatePayload,
      status,
      isCustomMessagingTemplatesAllowed: retailer?.isCustomMessagingTemplatesAllowed,
      // time: moment(time).format("DD.MM.YYYY HH:mm"),
    };
  });

  const currentQueryParams = getQueryParams();

  const paginationConfig = {
    pageSize: Number(currentQueryParams?.limit) || 10,
    total: pagination?.totalCount || 10,
    current: Number(currentQueryParams?.page) || 1,
    pageSizeOptions: [10, 20, 30, 50],
    showSizeChanger: true,
    position: ["bottomRight"],
  };
  const onChangeLang = (e) => {
    setLang(e.target.value);
  };

  // const rowSelection = {
  //   onChange: handleToggle,
  // };

  const convertMessageType = (str) => {
    return str[0].toUpperCase().concat(str.slice(1)).replace(/_/g, " ");
  };

  const handleRightsRevoke = () => {
    onRevokeSMSRights(selectedGroup.retailerId).then((res) => {
      fetchData();
      setDrawerOpen(false);
    });
  };

  return (
    <>
      <Radio.Group value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
        <Radio.Button value=""> All</Radio.Button>
        <Radio.Button value="pending"> Pending</Radio.Button>
        <Radio.Button value="approved"> Approved</Radio.Button>
        <Radio.Button value="declined"> Declined</Radio.Button>
      </Radio.Group>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        // rowSelection={{
        //   selectedRowKeys: selection,
        //   type: "checkbox",
        //   ...rowSelection,
        // }}
        dataSource={dataSource}
        pagination={paginationConfig}
        onChange={onChangePagination}
      />
      <Drawer
        width="80vw"
        className="retailerSMS-drawer"
        placement="right"
        closable
        onClose={() => setDrawerOpen(false)}
        visible={isDrawerOpen}
      >
        <List
          size="large"
          header={
            <div className="retailerSMS-drawer-title-wrap">
              <h3>
                <Input
                  value={selectedGroup?.name || "No name"}
                  style={{ width: "30vw", minWidth: "200px" }}
                  // disabled={selectedGroup.status === "pending"}
                />
                <Radio.Group
                  style={{ float: "right", marginRight: "3vw" }}
                  value={lang}
                  onChange={onChangeLang}
                  defaultValue="en"
                >
                  <Radio.Button value="en">ENG</Radio.Button>
                  <Radio.Button disabled value="ar">
                    ARB
                  </Radio.Button>
                </Radio.Group>
              </h3>
              <div className="retailerSMS-drawer-title">
                <div className="retailerSMS-drawer-title-colname">Event name</div>{" "}
                <div className="retailerSMS-drawer-title-colname">SMS text</div>
              </div>
            </div>
          }
          footer={
            <div className="retailerSMS-drawer-footer">
              <StatusPopover
                record={selectedGroup}
                onApproveStatus={onApproveStatus}
                onRejectStatus={onRejectStatus}
                // onWaitStatus={onWaitStatus}
              />
              <Button danger onClick={handleRightsRevoke}>
                Revoke Retailer rights to use custom SMS Templates
              </Button>
            </div>
          }
          dataSource={selectedGroup?.templates?.filter((e) => e.messageType === "order_created")}
          renderItem={(item) => {
            if (item?.language === lang) {
              return (
                <List.Item className="retailerSMS-drawer-list-item">
                  <span className="retailerSMS-drawer-list-item-message">
                    {convertMessageType(item.messageType)}({item.language?.toUpperCase()})
                  </span>
                  <div className="retailerSMS-drawer-list-item-template-wrapper">
                    <TextArea className="retailerSMS-drawer-list-item-template" rows={4} value={item.template} />
                  </div>
                </List.Item>
              );
            }
            return null;
          }}
        />
      </Drawer>
    </>
  );
};
