export const formatGeneralStats = ({ jobsStats, ordersStats, retailers }) => {
  if (!jobsStats?.length && !ordersStats?.length) {
    return [];
  }

  return [
    {
      title: "Orders count",
      value: `${ordersStats?.total}(${ordersStats?.unassigned})`,
    },
    {
      title: "Jobs count",
      value: `${jobsStats?.total}(${jobsStats?.unassigned})`,
    },
    {
      title: "Completed jobs",
      value: jobsStats?.completed,
    },
    {
      title: "Approved retailers",
      value: retailers?.approved,
    },
  ];
};

export const formatChartData = (data) => {
  if (!data) return [];

  return [
    {
      key: 1,
      type: "canceled",
      value: +data.canceled,
      color: "#ff4d4f",
    },
    {
      key: 2,
      type: "completed",
      value: +data.completed,
      color: "#faad14",
    },
    {
      key: 3,
      type: "failed",
      value: +data.failed,
      color: "#ff4d4f",
    },
    {
      key: 4,
      type: "new",
      value: +data.new,
      color: "#52c41a",
    },
    {
      key: 5,
      type: "postponed",
      value: +data.postponed,
      color: "#1890ff",
    },
    {
      key: 6,
      type: "processing",
      value: +data.processing,
      color: "#52c41a",
    },
  ];
};

const countPercentage = (val, total) => (val === 0 ? 0 : Math.round((val / total) * 10000) / 100);

export const formatOrderStatsData = (data) => {
  if (!data) return [];

  return [
    {
      title: "Canceled orders",
      value: `${countPercentage(Number(data?.canceled) || 0, data?.total)}%`,
    },
    {
      title: "Completed orders",
      value: `${countPercentage(Number(data?.completed) || 0, data?.total)}%`,
    },
    {
      title: "Failed orders",
      value: `${countPercentage(Number(data?.failed) || 0, data?.total)}%`,
    },
    {
      title: "New orders",
      value: `${countPercentage(Number(data?.new) || 0, data?.total)}%`,
    },
    {
      title: "Postponed orders",
      value: `${countPercentage(Number(data?.postponed) || 0, data?.total)}%`,
    },
    {
      title: "Processing orders",
      value: `${countPercentage(Number(data?.processing) || 0, data?.total)}%`,
    },
  ];
};
