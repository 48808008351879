import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, Upload, Button, DatePicker } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { API_URL } from "src/constants/config";

const { Option } = Select;

const AddDriver = () => {
  const [showModal, setShowModal] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const authToken = localStorage.getItem("authTokenSuper");

        const response = await fetch(`${API_URL}/superadmin/allcompanies`, {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        });
        const data = await response.json();
        setCompanies(data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    const fetchCountries = async () => {
      const authToken = localStorage.getItem("authTokenSuper");

      try {
        const response = await fetch(`${API_URL}/superadmin/allcountries`, {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        });
        const data = await response.json();
        setCountries(data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
    fetchCountries();
  }, []);

  const handleConfirm = () => {
    form.validateFields().then((values) => {
      console.log("Received values:", values);
    });
  };

  return (
    <div>
      <Button type="primary" onClick={() => setShowModal(true)}>
        + Add Driver
      </Button>
      <Modal
        title="Add Driver"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setShowModal(false)}>
            Cancel
          </Button>,
          <Button key="confirm" type="primary" onClick={handleConfirm}>
            Confirm
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" initialValues={{ remember: true }} onFinish={handleConfirm}>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: "Please enter first name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: "Please enter last name" }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please enter email" },
              { type: "email", message: "Please enter a valid email" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: "Please enter password" },
              { type: "password", message: "Please enter a valid password" },
            ]}
          >
            <Input type="password" />
          </Form.Item>
          <Form.Item
            label="Company Name"
            name="companyName"
            rules={[{ required: true, message: "Please select company name" }]}
          >
            <Select>
              {companies?.map((company) => (
                <Option key={company.id} value={company.id}>
                  {company.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        
          <Form.Item label="Driver Image" name="driverImage">
            <Upload>
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>

        
          <Form.Item label="Country" name="country">
            <Select>
              {countries?.map((company) => (
                <Option key={company.id} value={company.id}>
                  {company.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Birth Date" name="birthDate">
            <DatePicker />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddDriver;
