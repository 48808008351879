import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "adminUserList",

  actions: {
    get: {
      method: "GET",
      url: `${API_URL}/admin/users`,
      transformResponse: ({ body }) => {
        return { body };
      },
    },
    createOne: {
      method: "POST",
      url: `${API_URL}/admin/users`,
    },
    updateOne: {
      method: "PUT",
      url: `${API_URL}/admin/users/:id`,
    },
    deleteOne: {
      method: "DELETE",
      url: `${API_URL}/admin/users/:id`,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
