import { ArrowDownOutlined, DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Tag } from "antd";
import React, { FC, useState } from "react";

const RetailerStatus: FC<any> = ({ status, updateRetailerStatus, toUpdate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleChangeStatus = (e: any) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleStatusUpdated = (status: any) => {
    updateRetailerStatus(status?.key);
    setIsOpen(false);
  };

  const items = [
    {
      key: "blocked",
      label: "Blocked",
    },
    {
      key: "pending",
      label: "Pending",
    },
    {
      key: "accepted",
      label: "Accepted",
    },
  ];

  let color = "orange";
  switch (status) {
    case "accepted":
      color = "green";
      break;
    case "blocked":
      color = "red";
      break;
    default:
      color = "orange";
  }

  return (
    <Dropdown
      overlay={<Menu data-testid="orders-actions-menu" items={items} onClick={handleStatusUpdated}></Menu>}
      visible={isOpen}
    >
      <Tag
        className="retailer-tag"
        color={color}
        closable={toUpdate}
        onClose={handleChangeStatus}
        closeIcon={<DownOutlined />}
      >
        {status}
      </Tag>
    </Dropdown>
  );
};

export default RetailerStatus;
