import React from "react";
import { Modal, Typography } from "antd";

import styles from "./privacyPolicyModal.module.less";

const PrivacyPolicyModal = ({ isModalVisible, onToggleModal }) => (
  <Modal
    centered
    width={640}
    footer={null}
    visible={isModalVisible}
    onCancel={onToggleModal}
    maskStyle={{ background: "rgba(22, 22, 22, 0.4)", backdropFilter: "blur(16px)" }}
    className={styles.modal}
  >
    <div className={styles.scrollAreaWrap}>
      <Typography.Title level={3} className={styles.modalTitle}>
        Privacy & Cookie Policy
      </Typography.Title>

      <p className={styles.modalText}>
        We are Dliv (“we”, “us”, “our”), and we are committed to protecting your privacy This Privacy and Cookie
        Policy applies to information collected and processed via our{" "}
        <span className={styles.insertion}>app.dliv.me</span> website. The Website is operated by Dliv Trading
        Company with a registered commercial registration of 147529, and head office located at: The Gate, Tower 2, 16th
        Floor, 63 Maysaloun Street 860, Doha, Qatar By visiting the Website, you are consenting to the practices
        described in this Privacy and Cookie Policy and our Terms and Conditions. This Privacy Policy provides
        information on how we collect and use personal data.
      </p>

      <Typography.Title level={4} className={styles.modalSubTitle}>
        A. What Information do we collect and how?
      </Typography.Title>
      <p className={styles.modalText}>
        The info given when you sign up for an account on the Website, or place an order for products, or complete any
        online or off-line form, participate in social media events/promotions with our Website, or communicate with us
        via any channel (email, phone call, sms, WhatsApp, social media, etc..). Such information may include:
      </p>
      <ul>
        <li className={styles.modalText}>Your personal info: Gender, Date of Birth, Nationality, nickname.</li>
        <li className={styles.modalText}>Your contact info: Full name, address, email, phone number.</li>
        <li className={styles.modalText}>Your preferences: preferred time of delivery.</li>
        <li className={styles.modalText}>
          Your Financial info: Your credit and/or debit card details and billing address. Please note whoever that
          financial details about your credit/debit cards are only held by our payment processors, who are legally
          authorized financial entities.
        </li>
        <li className={styles.modalText}>
          Website statistical and basic Analytics Info: your location, the URL you use to access the Website, browser or
          device type/unique fingerprint, your computer’s IP address and domain type, which pages you visited, search
          terms you entered, items you clicked on.
        </li>
        <li className={styles.modalText}>
          Mobile app's third-party libraries : The driver mobile app utilizes specifically Google Maps Service, for
          turn-by-turn navigation purposes and in doing so, it collects location information to facilitate shipment
          tracking, efficient routing, tasks assignment, and the provision of estimated time of arrival notifications to
          customers.
        </li>
        <li className={styles.modalText}>
          Information Received from Other Sources: By logging in to our mobile applications via Facebook or Twitter, or
          linking your account on our Website to your Facebook or Twitter accounts, you are giving us permission to
          obtain certain information and content from these accounts. The specific types of information that we may
          obtain depends on your settings for that account or website, and will be subject to their privacy policies.
        </li>
      </ul>

      <Typography.Title level={4} className={styles.modalSubTitle}>
        B. What do we do with the Information we collect?
      </Typography.Title>
      <p className={styles.modalText}>
        The aforementioned information is collected primarily to optimize the experience we give you. Below you may find
        a brief account of how we utilize the data we collect from you.
      </p>
      <table cellPadding="0" cellSpacing="0" className={styles.mainTable}>
        <thead>
          <tr className={styles.twoTableHeadRow}>
            <th>Data collected</th>
            <th>Objective</th>
          </tr>
        </thead>
        <tbody>
          <tr className={styles.twoTableBodyRow}>
            <td>Personal Info, Contact Info and Personal Preferences</td>
            <td>Register and manage your account on our website</td>
          </tr>
          <tr className={styles.twoTableBodyRow}>
            <td>Personal Info, Contact and Location Info and Product Details</td>
            <td>Execute successfully your placed order</td>
          </tr>
          <tr className={styles.twoTableBodyRow}>
            <td>Credit/Debit Card Details</td>
            <td>Collect Payment for your placed order</td>
          </tr>
          <tr className={styles.twoTableBodyRow}>
            <td>Device Info, Cookie Identifiers and Browsing Info</td>
            <td>Operate and Optimize our website and services</td>
          </tr>
          <tr className={styles.twoTableBodyRow}>
            <td>Contact Info, Personal Info, Cookie Identifiers</td>
            <td>Personalize our Communication to you</td>
          </tr>
        </tbody>
      </table>

      <Typography.Title level={4} className={styles.modalSubTitle}>
        C. Who do we share your data with?
      </Typography.Title>
      <p className={styles.modalText}>
        To ensure that the best service is provided to you on our website, we partner with service providers and hence
        we may share your information with those parties to be able to fulfil your order and continuously enhance the
        experience. Below you may find a brief account of the nature of service providers and type of data shared.
      </p>
      <table cellPadding="0" cellSpacing="0" className={styles.mainTable}>
        <thead>
          <tr className={styles.threeTableHeadRow}>
            <th>Data Shared</th>
            <th>Service Provider</th>
            <th>Service Provider</th>
          </tr>
        </thead>
        <tbody>
          <tr className={styles.threeTableBodyRow}>
            <td>Credit/Debit Card Details</td>
            <td>Authorized Payment Processors</td>
            <td>Collect accurately the payment</td>
          </tr>
          <tr className={styles.threeTableBodyRow}>
            <td>Order Info, contact Info and Location</td>
            <td>Courier Entities</td>
            <td>Deliver your order accurately and timely</td>
          </tr>
          <tr className={styles.threeTableBodyRow}>
            <td>All info</td>
            <td>Anti-Fraud Providers</td>
            <td>Keep us as retailer and you as customer secure</td>
          </tr>
          <tr className={styles.threeTableBodyRow}>
            <td>Device Info, Cookie Identifiers and Browsing Info</td>
            <td>IT Service Providers</td>
            <td>Support and maintain the website</td>
          </tr>
          <tr className={styles.threeTableBodyRow}>
            <td>Device Info, Cookie Identifiers and Browsing Info</td>
            <td>Search Engine Providers</td>
            <td>Optimize the website</td>
          </tr>
          <tr className={styles.threeTableBodyRow}>
            <td>Contact and Financial Info</td>
            <td>Legal, Auditing, Financial and Insurance Advisory Providers</td>
            <td>Comply with public legal Laws</td>
          </tr>
          <tr className={styles.threeTableBodyRow}>
            <td>All Info</td>
            <td>Government body</td>
            <td>Investigate any legal issue</td>
          </tr>
        </tbody>
      </table>

      <Typography.Title level={4} className={styles.modalSubTitle}>
        D. What type of Cookies does our website have?
      </Typography.Title>
      <p className={styles.modalText}>
        Cookies are small pieces of information sent by a web server to a web browser, which allows the server to
        uniquely identify the browser on each page and we use them to collect information and store your online
        preferences 5 segments of cookies are used as per the below:
      </p>
      <table cellPadding="0" cellSpacing="0" className={styles.mainTable}>
        <thead>
          <tr className={styles.twoTableHeadRow}>
            <th width="30%">Type of Cookies</th>
            <th>Objective of Usage</th>
          </tr>
        </thead>
        <tbody>
          <tr className={styles.twoTableBodyRow}>
            <td width="30%">Website Operational Cookies</td>
            <td>
              Enables you to browse around the website and use its features. Without these cookies, services you have
              asked for such as remembering your login details or shopping basket items cannot be provided.
            </td>
          </tr>
          <tr className={styles.twoTableBodyRow}>
            <td width="30%">Website Analytics Cookies</td>
            <td>
              These cookies collect info on modes of Website usage, which allows us to continuously upgrade your
              shopping experience on our website.
            </td>
          </tr>
          <tr className={styles.twoTableBodyRow}>
            <td width="30%">Advertising Cookies</td>
            <td>
              Assist in make advertising more relevant to you and your interests and are also used to limit the number
              of times you see an advertisement and measure the effectiveness of an advertising campaign.
            </td>
          </tr>
          <tr className={styles.twoTableBodyRow}>
            <td width="30%">Third Party Cookies</td>
            <td>Enables you to have faster and more secure experience (avoid fraud).</td>
          </tr>
        </tbody>
      </table>
      <p className={styles.modalSubtext}>
        By using our Website you agree that we and they can place these types of cookies on your device and access them
        whenever you visit the Website or other websites operated by us.
      </p>
      <p className={styles.modalText}>
        If you want to delete any cookies that are already on your computer, please refer to the help and support area
        on your internet browser for instructions on how to locate the file or directory that stores cookies. Please
        note that by deleting our cookies or disabling future cookies you may not be able to access certain areas or
        features of our Website.
      </p>

      <Typography.Title level={4} className={styles.modalSubTitle}>
        E. How do we secure your data?
      </Typography.Title>
      <p className={styles.modalText}>
        Your information and its security is critical for us. That is why we take different measures to ensure that they
        are safely stored on secure servers. Any payment transaction carried out on our Website is encrypted using MPGS
        3DSecure technology. It is your responsibility to keep any Website passwords or login codes issued to or chosen
        by you, confidential. All credit/debit cards details and personally identifiable information will NOT be stored,
        sold, shared, rented or leased to any third parties. We also require that you establish a unique username and
        password to access your account on our Website and not to keep details of your credit or debit card that would
        enable any third party to transact using that credit or debit card (such as your CVV number); We regularly
        monitor our servers and IT systems for possible vulnerabilities and attacks. However and in spite of these
        measures, the transmission of information via the Internet is never completely secure. So, any such transmission
        is at your own risk. Any changes we may make to this Privacy Policy in the future will be posted on this page
        and, where appropriate, notified to you by email. Please check back regularly to keep informed of updates or
        changes to this Privacy Policy.
      </p>
    </div>
  </Modal>
);

export default PrivacyPolicyModal;
