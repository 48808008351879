import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { notification, Rate } from "antd";
import moment from "moment";
import { filter } from "lodash";

import { MobileTable } from "../../../../../components/AdminPanel/Mobile/MobileTable";
import { MobileTableExpand } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableExpand";
import { MobileTableRows } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableRows";
import { DriverJob } from "../DriverJob";
import { replaceAt } from "../../../../../helpers/general";
import { MobileTableCollapseRows } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableCollapseRows";
import { MobileSelectWrapper } from "../../../../../components/AdminPanel/Mobile/MobileSelectWrapper";
import { getDriverName, getMobileBadgeStatus, NO_INFO } from "../../../../../components/AdminPanel/Mobile/helpers";
import { DriverDocuments } from "../DriverDocuments";
import AdditionalDriverStats from "../AdditionalDriverStats";

const TRUST_DRIVER_OPTIONS = [
  { text: "Yes", value: true },
  { text: "No", value: false },
];

export const DriversTable = ({
  driverInfo,
  drivers,
  driverAdditionalStats,
  selectedRetailer,
  statusOptions,
  getJobsAndRetailers,
  onDriverStatusChange,
  handleAssignJob,
  handleCancelAssignJob,
  handleCompanyChange,
  loadOneDriversManager,
  loadDriverStatsDriversManager,
  trustDriverDriversManager,
  loadDriverStatsJobCompletionSectionDriversManager,
  loadDriverStatsCashSectionDriversManager,
  loadDriverStatsKMSectionDriversManager,
}) => {
  const availableJobs = useSelector(({ driversManager }) => filter(driversManager.adminJobs, ["status", "ready"]));
  const adminRetailers = useSelector(({ adminRetailers }) => adminRetailers.list);
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(false);

  const [expandedId, setExpandedId] = useState(-1);
  const handleSetExpandedId = (id) => {
    setExpandedId(id);
  };

  const [datePeriod, setDatePeriod] = useState({
    from: moment().startOf("day"),
    to: moment(),
    driverId: "",
  });

  useEffect(() => {
    if (datePeriod.driverId !== "") {
      loadDriverStatsCashSectionDriversManager(datePeriod.driverId, {
        query: { from: datePeriod.from.toISOString(true).substring(0, 23), to: datePeriod.to.toISOString(true).substring(0, 23) },
      });
      loadDriverStatsKMSectionDriversManager(datePeriod.driverId, {
        query: { from: datePeriod.from.toISOString(true).substring(0, 23), to: datePeriod.to.toISOString(true).substring(0, 23) },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datePeriod.from, datePeriod.to]);

  const onTrustDriver = (driver) => async (isTrusted) => {
    try {
      await trustDriverDriversManager({ driverId: driver.id, trusted: isTrusted });
      notification.success({
        message: isTrusted
          ? `You trust ${driver.profile.firstName} ${driver.profile.lastName}`
          : `You don't trust ${driver.profile.firstName} ${driver.profile.lastName}`,
      });
    } catch (e) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Can`t change driver trust value.",
      });
    }
  };

  useEffect(() => {
    if (isPanelCollapsed) {
      getJobsAndRetailers(driverInfo?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPanelCollapsed]);

  const retailers = adminRetailers?.map(({ id, name }) => ({ text: name, value: id }));

  const retailersOptions = retailers && [{ text: "No company", value: null }, ...retailers];

  return (
    <MobileTable>
      {drivers?.map((driver) => (
        <div className="table-item" key={driver.id}>
          <MobileTableRows
            rows={[
              { label: "Name", value: getDriverName(driver.profile) },
              { label: "Car Model", value: driver.car?.model || NO_INFO },
              { label: "Phone", value: driver.profile?.phoneNumber || NO_INFO },
              {
                label: "Rating",
                value: <Rate className="rate" allowHalf defaultValue={driver?.averageRating || 0} disabled />,
              },
              { label: "Status", value: getMobileBadgeStatus(driver.status) },
            ]}
          />
          <MobileTableExpand
            id={driver.id}
            expanded={expandedId === driver.id}
            handleSetExpandedId={handleSetExpandedId}
            setIsPanelCollapsed={async (bool) => {
              await loadOneDriversManager(driver.id);
              await loadDriverStatsDriversManager(driver.id);
              await loadDriverStatsJobCompletionSectionDriversManager(driver.id);
              await loadDriverStatsCashSectionDriversManager(driver.id, {
                query: { from: moment().startOf("day").toISOString(true).substring(0, 23), to: moment().toISOString(true).substring(0, 23) },
              });
              await loadDriverStatsKMSectionDriversManager(driver.id, {
                query: { from: moment().startOf("day").toISOString(true).substring(0, 23), to: moment().toISOString(true).substring(0, 23) },
              });
              setIsPanelCollapsed(bool);
            }}
          >
            <DriverJob
              currentJob={driverInfo?.currentJob}
              availableJobs={availableJobs}
              handleAssignJob={handleAssignJob}
              handleCancelAssignJob={handleCancelAssignJob}
            />
            <MobileTableCollapseRows
              title="USER"
              rows={[
                { label: "ID", value: driver.id },
                { label: "Email", value: driver.email },
                { label: "Status", value: replaceAt(driver.status, driver.status.indexOf("_"), " ") },
                { label: "Created at", value: moment(driver.createdAt).format("DD.MM.YYYY HH:mm") },
              ]}
            />
            <MobileSelectWrapper
              label="Change status"
              options={statusOptions}
              defaultValue="accepted"
              onSelect={onDriverStatusChange}
            />
            <MobileSelectWrapper
              label="Choose company"
              options={retailersOptions}
              defaultValue={null}
              value={selectedRetailer}
              onSelect={handleCompanyChange}
            />
            <MobileSelectWrapper
              label="Trust driver"
              options={TRUST_DRIVER_OPTIONS}
              defaultValue={null}
              value={driver.trustedDriver}
              onSelect={onTrustDriver(driver)}
            />
            <MobileTableCollapseRows
              title="PROFILE"
              rows={[
                { label: "Name", value: getDriverName(driver.profile) },
                { label: "Phone", value: driver.profile?.phoneNumber || NO_INFO },
                { label: "Nationality", value: driver.profile?.nationality || NO_INFO },
                { label: "Address", value: driver.profile?.address || NO_INFO },
                { label: "Birth date", value: driver.profile?.birthDate || NO_INFO },
              ]}
            />
            <MobileTableCollapseRows
              title="CAR"
              rows={[
                { label: "Plate Number", value: driver.car?.plateNumber || NO_INFO },
                { label: "Manufactured at", value: driver.car?.manufacturedAt || NO_INFO },
                { label: "Model", value: driver.car?.model || NO_INFO },
                { label: "Color", value: driver.car?.color || NO_INFO },
                { label: "Type", value: driver.car?.type || NO_INFO },
                { label: "First Issue Date", value: driver.car?.firstIssueDate || NO_INFO },
              ]}
            />
            <AdditionalDriverStats
              driver={driver}
              driverAdditionalStats={driverAdditionalStats}
              setDatePeriod={setDatePeriod}
              datePeriod={datePeriod}
            />
            {driverInfo?.video?.url && (
              <div className="mobile-driver-video">
                <MobileTableCollapseRows title="DRIVER VIDEO CARD" rows={[]} />
                <video width="100%" height="220" controls>
                  <source src={driverInfo?.video?.url} />
                </video>
              </div>
            )}
            {driverInfo?.documents && <DriverDocuments driverInfo={driverInfo} />}
          </MobileTableExpand>
        </div>
      ))}
    </MobileTable>
  );
};
