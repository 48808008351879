import { useState } from 'react';
import { useDispatch } from "react-redux";
import { actions as adminTenantsActions } from "../../../redux/resources/adminTenants";
import { IUser } from 'src/entities';
import { CreateUserFormBody, UserActionsProps } from '../types';
import { notification } from 'antd';

export function useUserActions({
    getAdminUserList,
    createOneAdminUserList,
    updateOneAdminUserList,
    deleteOneAdminUserList
} : UserActionsProps) {
  const [userList, setUserList] = useState<IUser[]>([]);
  const [tenants, setTenants] = useState<string[]>([]);
  const [createUserModalVisible, setCreateUserModalVisible] = useState<boolean>(false);
  const [searchedValue, setSearchedValue] = useState<string | null>(null);
  const [usersPage, setUsersPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [searchFilter, setSearchFilter] = useState<string>("email");
  const { getOwnAdminTenants, addTenantUserAdminTenants } = adminTenantsActions;
  const dispatch = useDispatch();

  const handleSearchKeyPress = (event?: React.KeyboardEvent) => {
    if (event && event.key === 'Enter') {
      handleOnSearch();
    }
  };

  const handleOnSearch = () => {
    setUserList([]);
    handleFetchUsers(1);
  };

  const handleFetchTenants = async () => {
    try {
        // @ts-ignore
        const { body }: any = await dispatch(getOwnAdminTenants())
        setTenants(body);
    } catch (error) {
        notification.error({
            message:"Error",
            description:"Something went wrong! please try refreshing the page"
        })
    }
  }

  const handleFetchUsers = async (page = usersPage, keyword = searchedValue, limit = 10) => {
    if (page !== usersPage) setUsersPage(page);
    getAdminUserList({}, 
      { query: { page, limit,
        ...(keyword ? ((searchFilter === "email") ?
                       { email: keyword } : 
                       { username: keyword }) : {}) } })
                       .then((res: any) => {
      setTotalPages(res.body.meta.totalItems);
      setUserList(res.body.items);
    });
  };

  const setupUserTenants = async (userId: any, tenants: any) => {
    tenants.forEach(async (tenant: any) => {
      await dispatch(addTenantUserAdminTenants({ userId, tenantName: tenant }));
    });
  };

  const handleAdminInvite = async (values: CreateUserFormBody) => {
    try {
      setCreateUserModalVisible(false);
      let res = await createOneAdminUserList({
        userName: values.username,
        email: values.email.toLowerCase(),
        password: values.password,
        accessType: values.role,
      });

      await setupUserTenants(res.body.id, values.tenants);
      notification.success({
        message: "Succes",
        description: "User Created",
      });
      await handleFetchUsers();
      notification.success({
        message: "Succes",
        description: "Invite Sent!",
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message ?? "User Not Created",
      });
    }
  };

  const handleChangeRole = function (id: number, role: string) {
    updateOneAdminUserList({ id, accessType: role }).then(
      (res: any) => {
        notification.success({
          message: "Success",
          description: "Role changed",
        });
        handleFetchUsers();
      },
      () => {
        notification.error({
          message: "Error",
          description: "Role not changed!",
        });
      },
    );
  };

  const handleDeleteUser = (id: number) => {
    deleteOneAdminUserList(id).then(
      () => {
        notification.success({
          message: "Success",
          description: "User deleted",
        });
        handleFetchUsers();
      },
      () => {
        notification.error({
          message: "Error",
          description: "User not deleted!",
        });
      },
    );
  };

  const onOpenCreateUserModal = () => {
    setCreateUserModalVisible(true);
  }

  const onCloseCreateUserModal = () => {
    setCreateUserModalVisible(false);
  }



  return {
    // states
    usersPage,
    totalPages,
    userList,
    tenants,
    searchedValue,
    searchFilter,
    createUserModalVisible,
    // functions
    handleFetchUsers,
    handleFetchTenants,
    handleDeleteUser,
    handleChangeRole,
    handleAdminInvite,
    onOpenCreateUserModal,
    setSearchFilter,
    setSearchedValue,
    handleSearchKeyPress,
    onCloseCreateUserModal
  };
}