import React, { useEffect, useState } from "react";
import { notification, Popconfirm } from "antd";
import { debounce } from "lodash";
import { ICashAmountModalVisible, IJobUseMobile } from "src/types/JobManagement";

interface ICashAmountModal {
  orderId: number;
  cashAmount: number;
}

export const useMobile: IJobUseMobile = ({
  deliveryJobs,
  selectedOrder,
  setSelectedOrder,
  withErrors,
  selectedJobs,
  onJobsSelected,
  // action
  cancelCompletedAdminOrder,
  fetchDeliveryJobs,
  getOneDeliveryJob,
  getRoutesDeliveryJob,
  handlePublishJob,
  deleteOrderDeliveryJob,
  forceNextOrderDeliveryJob,
  changeOrderPositionDeliveryJob,
  postponeOrderDeliveryJob,
}) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [innerCollapsed, setInnerCollapsed] = useState(null);
  const [cashAmountModalVisible, setCashAmountModalVisible] = useState<ICashAmountModal | boolean>(false);

  const [confirmOrdersAction, setConfirmOrdersAction] = useState<ICashAmountModalVisible>({
    open: false,
    id: null,
    job: null,
    type: null,
  });
  const [operationLoading, setOperationLoading] = useState(false);
  const onJobSearch = (e: any) => {
    e.persist();

    debounce(
      () =>
        fetchDeliveryJobs({}, { query: { trackingId: e.target.value, withError: withErrors } }).then(() => {
          if (selectedJobId) {
            getOneDeliveryJob({ id: selectedJobId });
            getRoutesDeliveryJob({ jobId: selectedJobId });
          }
        }),
      800,
    )();
  };

  const getPublishStatus = ({ id, published, driver }: any) => {
    const disabled = !!driver;

    return published ? (
      <Popconfirm
        disabled={disabled}
        title={!disabled ? "Are you sure you want to unpublish this job?" : ""}
        onConfirm={handlePublishJob(id, false)}
        okText="Yes"
        cancelText="No"
        placement="topRight"
      >
        <div className={disabled ? "link link--disabled" : "link"}>Unpublish</div>
      </Popconfirm>
    ) : (
      <Popconfirm
        disabled={disabled}
        title={!disabled ? "Are you sure you want to publish?" : ""}
        onConfirm={handlePublishJob(id, true)}
        okText="Yes"
        cancelText="No"
        placement="topRight"
      >
        <div className={disabled ? "link link--disabled" : "link"}>Publish</div>
      </Popconfirm>
    );
  };

  useEffect(() => {
    if (selectedJobId) {
      const selectedJob = deliveryJobs?.find(({ id }: any) => id === selectedJobId);
      setOrders(selectedJob?.deliveryOrders?.map((order: any) => order));
    }
  }, [deliveryJobs, selectedJobId]);

  const onExpandJob = async (collapsed: any, job: any) => {
    if (collapsed) {
      setSelectedJobId(job.id);

      if (!job.deliveryOrders) {
        setLoading(true);
        await getRoutesDeliveryJob({ jobId: job.id });
        const selectedJob = deliveryJobs?.find(({ id }) => id === job.id);
        setOrders(selectedJob?.deliveryOrders?.map((order: any) => order));
        setLoading(false);
      }
    } else {
      setSelectedJobId(null);
    }
  };

  const onOrderCollapse = (orderId: any) => {
    if (innerCollapsed === orderId) {
      return setInnerCollapsed(null);
    }

    setInnerCollapsed(orderId);
    setSelectedOrder(
      deliveryJobs.find(({ id }: any) => id === selectedJobId)?.deliveryOrders.find(({ id }: any) => id === orderId),
    );
  };

  const onDeleteOrder = async () => {
    await deleteOrderDeliveryJob({ jobId: selectedJobId, orderId: selectedOrder.id });
  };

  const onForceNextOrder = async () => {
    await forceNextOrderDeliveryJob({ jobId: selectedJobId });
    await fetchDeliveryJobs({}, { query: { withError: withErrors } });
    await getRoutesDeliveryJob({ jobId: selectedJobId });
  };

  const onOpenCashAmountModal =
    ({ orderId, cashAmount }: any) =>
    () =>
      setCashAmountModalVisible({ orderId, cashAmount });
  const onCloseCashAmountModal = () => setCashAmountModalVisible(false);

  const selectedJob = deliveryJobs?.find(({ id }: any) => id === selectedJobId);

  const onMoveRow = async ({ oldIndex, newIndex }: any) => {
    setLoading(true);
    const arr = selectedJob.deliveryOrders || orders || [];
    const originOrder = arr[oldIndex];
    const swappedOrder = arr[newIndex];

    if (!originOrder || !swappedOrder) {
      notification.warn({ message: "One of the swapped orders is undefined ! please retry .." });
      return;
    }

    const pointsOrder = [originOrder, swappedOrder].map(({ pointOrder }) => pointOrder);
    const deliveryIds = [originOrder, swappedOrder].map(({ deliveryPoint: { id } }) => id);

    if (!deliveryIds || deliveryIds.length !== 2) {
      notification.warn({ message: "One of the swapped orders is missing a delivery point ! please retry .." });
      return;
    }

    try {
      await changeOrderPositionDeliveryJob(
        { jobId: selectedJobId },
        { query: { save: true }, body: { pointsOrder, deliveryIds } },
      );
      await fetchDeliveryJobs({}, { query: { withError: withErrors } });
      await getRoutesDeliveryJob({ jobId: selectedJobId });
    } catch (error) {
      notification.error({
        message: "Oops!",
        description: "You can't change order",
      });
    }

    setLoading(false);
  };

  const isJobChecked = (jobId: any) => selectedJobs.includes(jobId);

  const onSelectJob = (e: any, jobId: any) => {
    e.stopPropagation();

    if (selectedJobs.includes(jobId)) {
      onJobsSelected(selectedJobs.filter((id: any) => id !== jobId));
    } else {
      onJobsSelected([...selectedJobs, jobId]);
    }
  };

  const handlePostponeOrder = async (jobId: any, orderId: any, postponedTime: any) => {
    setOperationLoading(true);
    try {
      await postponeOrderDeliveryJob({ jobId, orderId, postponedTime });
      notification.success({
        message: "Message",
        description: `Order ${orderId ?? ""} was postponed successfully.`,
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to postpone order.",
      });
    } finally {
      setOperationLoading(false);
      setConfirmOrdersAction({ open: false, id: null, job: null, type: null });
    }
  };

  const handleCancelCompleted = async (jobId: any, orderId: any) => {
    setOperationLoading(true);
    try {
      await cancelCompletedAdminOrder({ orderId });
      await getRoutesDeliveryJob({ jobId });
      notification.success({
        message: "Message",
        description: `Order ${orderId ?? ""} was canceled successfully.`,
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to cancel order.",
      });
    } finally {
      setOperationLoading(false);
      setConfirmOrdersAction({ open: false, id: null, job: null, type: null });
    }
  };
  return {
    isJobChecked,
    onSelectJob,
    loading,
    selectedJobId,
    selectedJob,
    innerCollapsed,
    cashAmountModalVisible,
    setLoading,
    onJobSearch,
    onExpandJob,

    onOrderCollapse,
    onForceNextOrder,
    onDeleteOrder,
    onMoveRow,
    getPublishStatus,
    onOpenCashAmountModal,
    onCloseCashAmountModal,

    confirmOrdersAction,
    setConfirmOrdersAction,
    operationLoading,
    setOperationLoading,
    handlePostponeOrder,
    handleCancelCompleted,
  };
};
