import { Col, Collapse, Row, Typography } from "antd";
import React from "react";
import Section from "../../components/Layout/Section";
import styles from "./termsConditions.module.less";

const PrivacyPolicyPage = ({ isMobile }) => {
  const getHeader = (num, name) => {
    return (
      <div style={{ display: "flex", gap: 8 }}>
        <span>{num}.</span>
        <span>{name}</span>
      </div>
    );
  };
  return (
    <Section className={styles.termsConditionsPage}>
      <Row justify="center" style={isMobile ? { padding: "120px 28px 40px 28px" } : { padding: "120px 0 40px 0px" }}>
        <Col span={isMobile ? 24 : 12} style={{ marginBottom: 24 }}>
          <Typography.Text className={styles.headerSubtitle}>Privacy & Cookie Policy</Typography.Text>
        </Col>
        <Row justify="center">
          <Col span={isMobile ? 24 : 12}>
            <Typography.Text className={isMobile ? styles.headerMessageMobile : styles.headerMessage}>
              We are Dliv (“we”, “us”, “our”), and we are committed to protecting your privacy This Privacy and
              Cookie Policy applies to information collected and processed via our www.app.dliv.me website. The Website
              is operated by Dliv Trading Company with a registered commercial registration of 147529, and head
              office located at: The Gate, Tower 2, 16th Floor, 63 Maysaloun Street 860, Doha, Qatar By visiting the
              Website, you are consenting to the practices described in this Privacy and Cookie Policy and our Terms and
              Conditions. This Privacy Policy provides information on how we collect and use personal data.
            </Typography.Text>
          </Col>
        </Row>
      </Row>
      <Row justify="center" style={isMobile ? { padding: "40px 28px 40px 28px" } : { padding: "0px 0 71px 0" }}>
        <Col span={isMobile ? 24 : 12}>
          <Collapse defaultActiveKey={[]} expandIconPosition="end">
            <Collapse.Panel header={getHeader("A", "What Information do we collect and how?")} key="1">
              <p>
                The info given when you sign up for an account on the Website, or place an order for products, or
                complete any online or off-line form, participate in social media events/promotions with our Website, or
                communicate with us via any channel (email, phone call, sms, WhatsApp, social media, etc..). Such
                information may include:
              </p>
              <p>
                <ul style={{ paddingLeft: 20 }}>
                  <li> Your personal info: Gender, Date of Birth, Nationality, nickname.</li>
                  <li>Your contact info: Full name, address, email, phone number.</li>
                  <li>Your preferences: preferred time of delivery.</li>
                  <li>
                    Your Financial info: Your credit and/or debit card details and billing address. Please note whoever
                    that financial details about your credit/debit cards are only held by our payment processors, who
                    are legally authorized financial entities.
                  </li>
                  <li>
                    Website statistical and basic Analytics Info: your location, the URL you use to access the Website,
                    browser or device type/unique fingerprint, your computer’s IP address and domain type, which pages
                    you visited, search terms you entered, items you clicked on.
                  </li>
                  <li>
                    Mobile app's third-party libraries : The driver mobile app utilizes specifically Google Maps
                    Service, for turn-by-turn navigation purposes and in doing so, it collects location information to
                    facilitate shipment tracking, efficient routing, tasks assignment, and the provision of estimated
                    time of arrival notifications to customers.
                  </li>
                  <li>
                    Information Received from Other Sources: By logging in to our mobile applications via Facebook or
                    Twitter, or linking your account on our Website to your Facebook or Twitter accounts, you are giving
                    us permission to obtain certain information and content from these accounts. The specific types of
                    information that we may obtain depends on your settings for that account or website, and will be
                    subject to their privacy policies.
                  </li>
                </ul>
              </p>
            </Collapse.Panel>
            <Collapse.Panel header={getHeader("B", "What do we do with the Information we collect?")} key="2">
              <p>
                The aforementioned information is collected primarily to optimize the experience we give you. Below you
                may find a brief account of how we utilize the data we collect from you.
              </p>
              <p style={{ color: "#E9E9E9", fontWeight: 700 }}>
                ! Please read these terms carefully before accessing or using the Dliv platform. If you do not agree
                to be bound by these terms, you may not use or access the Dliv platform.
              </p>
              <Row className={styles.simpleRow}>
                <Col span={12} style={{ color: "#01cdfb", fontWeight: 300, fontSize: 16, lineHeight: "150%" }}>
                  Data collected
                </Col>
                <Col span={12} style={{ color: "#01cdfb", fontWeight: 300, fontSize: 16, lineHeight: "150%" }}>
                  Objective
                </Col>
              </Row>
              <Row className={styles.row}>
                <Col span={12} className={styles.tableMessage}>
                  Personal Info, Contact Info and Personal Preferences
                </Col>
                <Col span={12} className={styles.tableMessage}>
                  Register and manage your account on our website
                </Col>
              </Row>
              <Row className={styles.row}>
                <Col span={12} className={styles.tableMessage}>
                  Personal Info, Contact and Location Info and Product Details
                </Col>
                <Col span={12} className={styles.tableMessage}>
                  Execute successfully your placed order
                </Col>
              </Row>
              <Row className={styles.row}>
                <Col span={12} className={styles.tableMessage}>
                  Credit/Debit Card Details
                </Col>
                <Col span={12} className={styles.tableMessage}>
                  Collect Payment for your placed order
                </Col>
              </Row>
              <Row className={styles.row}>
                <Col span={12} className={styles.tableMessage}>
                  Device Info, Cookie Identifiers and Browsing Info
                </Col>
                <Col span={12} className={styles.tableMessage}>
                  Operate and Optimize our website and services
                </Col>
              </Row>
              <Row className={styles.row}>
                <Col span={12} className={styles.tableMessage}>
                  Contact Info, Personal Info, Cookie Identifiers
                </Col>
                <Col span={12} className={styles.tableMessage}>
                  Personalize our Communication to you
                </Col>
              </Row>
              <Row className={styles.row} />
            </Collapse.Panel>
            <Collapse.Panel header={getHeader("C", "Who do we share your data with?")} key="3">
              <p>
                To ensure that the best service is provided to you on our website, we partner with service providers and
                hence we may share your information with those parties to be able to fulfil your order and continuously
                enhance the experience. Below you may find a brief account of the nature of service providers and type
                of data shared.
              </p>
              <Row justify="center">
                <Row style={{ overflowX: "auto", paddingLeft: "8%" }} className={styles.hideScroll}>
                  <Row className={styles.simpleRowScroll} style={{ width: "100%" }}>
                    <Col
                      span={8}
                      style={{ color: "#01cdfb", fontWeight: 300, fontSize: 16, lineHeight: "150%", width: 164 }}
                    >
                      Data Shared
                    </Col>
                    <Col
                      span={8}
                      style={{ color: "#01cdfb", fontWeight: 300, fontSize: 16, lineHeight: "150%", width: 164 }}
                    >
                      Service Provider
                    </Col>
                    <Col
                      span={8}
                      style={{ color: "#01cdfb", fontWeight: 300, fontSize: 16, lineHeight: "150%", width: 164 }}
                    >
                      Objective of Usage
                    </Col>
                  </Row>
                  <Row className={styles.rowScroll}>
                    <Col span={8} className={styles.tableMessage}>
                      Credit/Debit Card Details
                    </Col>
                    <Col span={8} className={styles.tableMessage}>
                      Authorized Payment Processors
                    </Col>
                    <Col span={8} className={styles.tableMessage}>
                      Collect accurately the payment
                    </Col>
                  </Row>

                  <Row className={styles.rowScroll}>
                    <Col span={8} className={styles.tableMessage}>
                      Order Info, contact Info and Location
                    </Col>
                    <Col span={8} className={styles.tableMessage}>
                      Courier Entities
                    </Col>
                    <Col span={8} className={styles.tableMessage}>
                      Deliver your order accurately and timely
                    </Col>
                  </Row>

                  <Row className={styles.rowScroll}>
                    <Col span={8} className={styles.tableMessage}>
                      All info
                    </Col>
                    <Col span={8} className={styles.tableMessage}>
                      Anti-Fraud Providers
                    </Col>
                    <Col span={8} className={styles.tableMessage}>
                      Keep us as retailer and you as customer secure
                    </Col>
                  </Row>

                  <Row className={styles.rowScroll}>
                    <Col span={8} className={styles.tableMessage}>
                      Device Info, Cookie Identifiers and Browsing Info
                    </Col>
                    <Col span={8} className={styles.tableMessage}>
                      IT Service Providers
                    </Col>
                    <Col span={8} className={styles.tableMessage}>
                      Support and maintain the website
                    </Col>
                  </Row>
                  <Row className={styles.rowScroll}>
                    <Col span={8} className={styles.tableMessage}>
                      Device Info, Cookie Identifiers and Browsing Info
                    </Col>
                    <Col span={8} className={styles.tableMessage}>
                      Search Engine Providers
                    </Col>
                    <Col span={8} className={styles.tableMessage}>
                      Optimize the website
                    </Col>
                  </Row>
                  <Row className={styles.rowScroll}>
                    <Col span={8} className={styles.tableMessage}>
                      Contact and Financial Info
                    </Col>
                    <Col span={8} className={styles.tableMessage}>
                      Legal, Auditing, Financial and Insurance Advisory Providers
                    </Col>
                    <Col span={8} className={styles.tableMessage}>
                      Comply with public legal Laws
                    </Col>
                  </Row>
                  <Row className={styles.rowScroll}>
                    <Col span={8} className={styles.tableMessage}>
                      All Info
                    </Col>
                    <Col span={8} className={styles.tableMessage}>
                      Government body
                    </Col>
                    <Col span={8} className={styles.tableMessage}>
                      Investigate any legal issue
                    </Col>
                  </Row>

                  <Row className={styles.rowScroll} />
                </Row>
              </Row>
            </Collapse.Panel>
            <Collapse.Panel header={getHeader("D", "What type of Cookies does our website have?")} key="4">
              <p>
                Cookies are small pieces of information sent by a web server to a web browser, which allows the server
                to uniquely identify the browser on each page and we use them to collect information and store your
                online preferences 5 segments of cookies are used as per the below:
              </p>
              <Row className={styles.simpleRow}>
                <Col span={12} style={{ color: "#01cdfb", fontWeight: 300, fontSize: 16, lineHeight: "150%" }}>
                  Type of Cookies
                </Col>
                <Col span={12} style={{ color: "#01cdfb", fontWeight: 300, fontSize: 16, lineHeight: "150%" }}>
                  Objective of Usage
                </Col>
              </Row>
              <Row className={styles.row}>
                <Col span={12} className={styles.tableMessage}>
                  Website Operational Cookies
                </Col>
                <Col span={12} className={styles.tableMessage}>
                  Enables you to browse around the website and use its features. Without these cookies, services you
                  have asked for such as remembering your login details or shopping basket items cannot be provided.
                </Col>
              </Row>
              <Row className={styles.row}>
                <Col span={12} className={styles.tableMessage}>
                  Website Analytics Cookies
                </Col>
                <Col span={12} className={styles.tableMessage}>
                  These cookies collect info on modes of Website usage, which allows us to continuously upgrade your
                  shopping experience on our websites.
                </Col>
              </Row>
              <Row className={styles.row}>
                <Col span={12} className={styles.tableMessage}>
                  Advertising Cookies
                </Col>
                <Col span={12} className={styles.tableMessage}>
                  Assist in make advertising more relevant to you and your interests and are also used to limit the
                  number of times you see an advertisement and measure the effectiveness of an advertising campaign.
                </Col>
              </Row>
              <Row className={styles.row}>
                <Col span={12} className={styles.tableMessage}>
                  Third Party Cookies
                </Col>
                <Col span={12} className={styles.tableMessage}>
                  Enables you to have faster and more secure experience (avoid fraud).
                </Col>
              </Row>
              <Row className={styles.row} />
              <p style={{ color: "#E9E9E9", fontWeight: 700 }}>
                By using our Website you agree that we and they can place these types of cookies on your device and
                access them whenever you visit the Website or other websites operated by us.
              </p>
              <p>
                If you want to delete any cookies that are already on your computer, please refer to the help and
                support area on your internet browser for instructions on how to locate the file or directory that
                stores cookies. Please note that by deleting our cookies or disabling future cookies you may not be able
                to access certain areas or features of our Website.
              </p>
            </Collapse.Panel>
            <Collapse.Panel header={getHeader("E", "How do we secure your data?")} key="5">
              <p>
                Your information and its security is critical for us. That is why we take different measures to ensure
                that they are safely stored on secure servers. Any payment transaction carried out on our Website is
                encrypted using MPGS 3DSecure technology. It is your responsibility to keep any Website passwords or
                login codes issued to or chosen by you, confidential. All credit/debit cards details and personally
                identifiable information will NOT be stored, sold, shared, rented or leased to any third parties. We
                also require that you establish a unique username and password to access your account on our Website and
                not to keep details of your credit or debit card that would enable any third party to transact using
                that credit or debit card (such as your CVV number); We regularly monitor our servers and IT systems for
                possible vulnerabilities and attacks. However and in spite of these measures, the transmission of
                information via the Internet is never completely secure. So, any such transmission is at your own risk.
                Any changes we may make to this Privacy Policy in the future will be posted on this page and, where
                appropriate, notified to you by email. Please check back regularly to keep informed of updates or
                changes to this Privacy Policy.
              </p>
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
    </Section>
  );
};

export default PrivacyPolicyPage;
