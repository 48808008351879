import { Button, Form, Input, Modal, Space } from "antd";
import React, { FC, useState } from "react";
import { ICreateTicketModalProps } from "./types";
import { useCreatetTicket } from "./useCreateTicket";

const CreateTicketModal: FC<ICreateTicketModalProps> = ({
  buttonType,
  orderId,
  createTicket,
  updateOrderNewTicketState,
  jobId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { onCreateTicket } = useCreatetTicket(createTicket, updateOrderNewTicketState);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOnSubmit = ({ label }: any) => {
    onCreateTicket({ orderId, labels: [label], jobId });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button data-testid="create-ticket-modal-btn" type={buttonType} onClick={showModal}>
        Create
      </Button>
      <Modal
        data-testid="create-ticket-modal"
        title={`Create New Ticket (Order ID: ${orderId})`}
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form initialValues={{ label: "" }} onFinish={handleOnSubmit}>
          <Form.Item
            label="Ticket Label"
            name="label"
            rules={[{ required: true, message: "Please input your ticket lable!" }]}
          >
            <Space>
              <Input data-testid="create-ticket-input" className="create-ticket-input" />
              <Button data-testid="create-ticket-submit-btn" key="submit" type="primary" htmlType="submit">
                Create
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateTicketModal;
