import React from "react";
import { Image } from "antd";

import "../deliveryReport.less";

const AttachmentScroll = ({ children }) => (
  <div className="attachmentScroll">
    <Image.PreviewGroup>{children}</Image.PreviewGroup>
  </div>
);
export default AttachmentScroll;
