import React from "react";

const LinkedInIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="current" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.8734 18.3321C18.0436 18.3114 17.2336 18.3114 16.4039 18.3321C16.2261 18.3321 16.1866 18.2907 16.1866 18.1048C16.1866 16.5756 16.1866 15.0258 16.1866 13.4967C16.1866 13.1454 16.1668 12.7941 16.068 12.4635C15.7717 11.389 14.5863 10.9964 13.7368 11.7196C13.2824 12.0916 13.1046 12.6082 13.1046 13.2281C13.1046 14.6746 13.1046 16.121 13.1046 17.5675C13.1046 17.7535 13.0848 17.9395 13.1046 18.1461C13.1243 18.3114 13.0453 18.3527 12.907 18.3321C12.0575 18.3321 11.2277 18.3321 10.3782 18.3321C10.2202 18.3321 10.1807 18.2907 10.1807 18.1254C10.2004 16.8236 10.2004 15.5218 10.2004 14.1993C10.2004 12.5875 10.2004 10.9757 10.1807 9.3846C10.1807 9.19862 10.2202 9.15729 10.3782 9.15729C11.2277 9.15729 12.0575 9.15729 12.907 9.15729C13.0651 9.15729 13.1046 9.19862 13.1046 9.36393C13.1046 9.69456 13.1046 10.0252 13.1046 10.4178C13.1639 10.3558 13.1836 10.3351 13.2034 10.3145C13.9739 9.13663 15.0605 8.80601 16.3446 9.01265C17.8263 9.26061 18.7746 10.3351 19.0117 11.9882C19.071 12.3809 19.0907 12.7735 19.0907 13.1661C19.0907 14.8192 19.0907 16.4517 19.0907 18.1048C19.0907 18.2701 19.0512 18.3321 18.8734 18.3321Z" />
    <path d="M8.60058 13.7441C8.60058 15.1906 8.60058 16.6371 8.60058 18.0836C8.60058 18.2695 8.56107 18.3315 8.38326 18.3315C7.5535 18.3109 6.72374 18.3315 5.89397 18.3315C5.73592 18.3315 5.69641 18.2902 5.69641 18.1249C5.69641 15.2113 5.69641 12.277 5.69641 9.36338C5.69641 9.21873 5.73592 9.15674 5.89397 9.15674C6.74349 9.15674 7.59301 9.15674 8.44253 9.15674C8.62033 9.15674 8.64009 9.21873 8.64009 9.38404C8.60058 10.8305 8.60058 12.277 8.60058 13.7441Z" />
    <path d="M8.73874 6.7601C8.54117 7.58666 7.73117 8.06193 6.80263 7.89661C5.81481 7.7313 5.26164 6.71877 5.61725 5.7269C5.85433 5.10699 6.42726 4.73504 7.15824 4.7557C8.26459 4.73504 8.99557 5.66491 8.73874 6.7601Z" />
  </svg>
);

export default LinkedInIcon;
