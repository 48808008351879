import { Button, Modal, Spin } from "antd";
import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "src/redux";
import {
  CFRScreenEnum,
  ICFRWidgetProps,
  ResultScreenTypeEnum,
  TOnScreenChange,
} from "../../../types/CloudFleetRouting";
import CFRResults from "../CFRResults";
import CFRSettings from "../CFRSettings";
import { actions as jobsMapActions } from "../../../redux/resources/jobsMap";
import "./cfr-widget.less";
import { useCFRWidget } from "./useCFRWidget";

const isObjectEmpty = (obj: any) => !(obj && Object.keys(obj).length > 0);

const CFRWidget: FC<any> = ({ selectedOrders, selectedRetailer, onReset, cfrStateJobsMap }) => {
  const [activeScreen, setActiveScreen] = useState<CFRScreenEnum>(CFRScreenEnum.CFR_SETTINGS_SCREEN);
  const [isCFROpen, setIsCFROpen] = useState<any>();
  const { getCFRStatus } = useCFRWidget({ cfrStateJobsMap });

  useEffect(() => {
    if (isCFROpen) {
      getCFRStatus();
    }

    return () => {
      if (isCFROpen) {
        onReset(true);
        setActiveScreen(CFRScreenEnum.CFR_SETTINGS_SCREEN);
      }
    };
  }, [isCFROpen]);

  const onScreenChange: TOnScreenChange = (screen, screenData) => {
    if (screenData?.clearCFRState) {
      onReset(false);
    }
    if (screen === CFRScreenEnum.ORDERS_SCREEN) {
      onCFRModalClose();
      return;
    }
    setActiveScreen(screen);
  };

  const onCFRModalClose = () => {
    setIsCFROpen(false);
  };

  const renderScreens = () => {
    switch (activeScreen) {
      case CFRScreenEnum.CFR_SETTINGS_SCREEN:
        return (
          <CFRSettings
            selectedOrders={selectedOrders}
            selectedRetailer={selectedRetailer}
            onScreenChange={onScreenChange}
          />
        );
      case CFRScreenEnum.CFR_SUCCESS_SCREEN:
        return <CFRResults type={ResultScreenTypeEnum.SUCCESS} onScreenChange={onScreenChange} />;
      case CFRScreenEnum.CFR_ERROR_SCREEN:
        return <CFRResults type={ResultScreenTypeEnum.ERROR} onScreenChange={onScreenChange} />;
      case CFRScreenEnum.CFR_PARTIAL_SUCCESS_SCREEN:
        return <CFRResults type={ResultScreenTypeEnum.PARTIAL_SUCCESS} onScreenChange={onScreenChange} />;
      case CFRScreenEnum.CFR_STATUS_LOADING:
      case CFRScreenEnum.CFR_RUNNING:
        return (
          <div className="cfr-loading">
            <Spin size="large" />
          </div>
        );
      default:
        return <div>No screen selected</div>;
    }
  };

  return (
    <div>
      <Button type="primary" disabled={selectedOrders?.length < 1} onClick={() => setIsCFROpen(!isCFROpen)}>
        Create Job
      </Button>
      <Modal visible={isCFROpen} onOk={onCFRModalClose} onCancel={onCFRModalClose} footer={null} width="600px">
        <div className="screens-container">{renderScreens()}</div>
      </Modal>
    </div>
  );
};

const MSTP = (state: RootState, props: ICFRWidgetProps) => {
  const { selectedOrders, selectedRetailer, onReset } = props;

  return {
    selectedOrders,
    selectedRetailer,
    onReset,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(jobsMapActions, dispatch),
}))(CFRWidget);
