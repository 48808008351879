import React, { useEffect, useState } from "react";
import { Button, Radio, List, Image, Badge, Drawer, notification } from "antd";
import { RightSquareOutlined } from "@ant-design/icons";

import { MobileTable } from "../../../../../components/AdminPanel/Mobile/MobileTable";
import { MobileTableRows } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableRows";
import { BtnGroup } from "../BtnGroup";
import { NO_INFO } from "../../../../../components/AdminPanel/Mobile/helpers";
import { MobilePagination } from "../../../../../components/AdminPanel/Mobile/MobilePagination";
import { StatusPopover } from "../../../DesktopView/components/RetailerChangesTable/components/StatusPopover";

export const MobileRetailerChangesTable = ({
  data,
  location,
  paginationConfig,
  fetchRetailerChangeNotifications,
  fetchData,
  onOpenModal,
  onChangePagination,
  handleToggleOne,
  isSelected,
  getOneRetailerData,
  approveRetailerChangeNotifications,
  rejectRetailerChangeNotifications,
  statusFilter,
  setStatusFilter,
}) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [openedRecord, setOpenedRecord] = useState(false);

  const [updatesSummary, setUpdatesSummary] = useState([]);

  useEffect(() => {
    fetchData(fetchRetailerChangeNotifications, false, statusFilter);
  }, [fetchRetailerChangeNotifications, fetchData, location.search, statusFilter]);

  const onApproveStatus = (id) => async () => {
    try {
      await approveRetailerChangeNotifications({ id });
      await fetchData(fetchRetailerChangeNotifications, false, statusFilter);
    } catch (e) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Something went wrong.",
      });
    }
  };

  const onRejectStatus = (id) => async () => {
    try {
      await rejectRetailerChangeNotifications({ id });
      await fetchData();
    } catch (e) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Something went wrong.",
      });
    }
  };

  const onOpenChanges = (retailer, updates, record) => () => {
    getOneRetailerData(retailer).then((res) => {
      setDrawerOpen(true);
      setOpenedRecord(record);
      setUpdatesSummary(
        Object.keys(updates).map((key) => {
          if (key === "commercialRegistration") {
            return {
              index: key.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`),
              old: res.body[key],
              new: updates[key],
            }; // eslint-disable-next-line
          } else if (key === "pickupPoint") {
            return {
              index: key.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`),
              // eslint-disable-next-line
              old: res.body[key + "s"]?.filter((e) => e.id === updates[key].id)?.[0],
              new: updates[key],
            };
          } else
            return {
              index: key.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`),
              old: retailer[key],
              new: updates[key],
            };
        }),
      );
    });
  };

  const dataSource = data?.map(({ id, retailer, status, time, updatePayload }) => {
    return {
      id,
      retailer,
      retailerId: retailer?.id,
      name: retailer?.name,
      email: retailer?.email,
      phoneNumber: retailer?.phoneNumber,
      updatePayload,
      status,
      // time: moment(time).format("DD.MM.YYYY HH:mm"),
    };
  });

  return (
    <>
      <Radio.Group value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
        <Radio.Button value=""> All</Radio.Button>
        <Radio.Button value="pending"> Pending</Radio.Button>
        <Radio.Button value="approved"> Approved</Radio.Button>
        <Radio.Button value="declined"> Declined</Radio.Button>
      </Radio.Group>
      <MobileTable>
        {dataSource?.map(({ id, retailer, retailerId, name, email, phoneNumber, status, updatePayload }) => (
          <div className="table-item" key={id}>
            {/* <div style={{ margin: "10px" }} onClick={(e) => handleToggleOne(e, requests_id)}>
              <Checkbox checked={isSelected(requests_id)} />
            </div> */}
            <MobileTableRows
              rows={[
                { label: "Request ID", value: id || NO_INFO },
                { label: "Retailer ID", value: retailerId || NO_INFO },
                { label: "Retailer name", value: name || NO_INFO },
                // {
                //   label: "Reason",
                //   value: description ? (
                //     <Popover placement="topRight" content={<div>{description}</div>} title={null}>
                //       <span>{reason}</span>
                //     </Popover>
                //   ) : (
                //     <div>{reason}</div>
                //   ),
                // },
                { label: "Email", value: email },
                { label: "Phone number", value: phoneNumber },
                { label: "Status", value: status },
              ]}
            />
            {status === "pending" && (
              <>
                {/* <BtnGroup>
                  <Button
                    onClick={onOpenModal({ type: "wait", requestId: requests_id })}
                    className="mobile-btn mobile-btn--blue"
                  >
                    Wait
                  </Button>
                </BtnGroup> */}
                <BtnGroup isThree>
                  <Button
                    onClick={onOpenModal({ type: "approve", requestId: id })}
                    className="mobile-btn mobile-btn--blue"
                  >
                    Approve
                  </Button>
                  <Button
                    onClick={onOpenChanges(retailer, updatePayload, {
                      id,
                      retailer,
                      retailerId,
                      name,
                      email,
                      phoneNumber,
                      status,
                      updatePayload,
                    })}
                    className="mobile-btn mobile-btn--blue"
                  >
                    View
                  </Button>
                  <Button
                    onClick={onOpenModal({ type: "reject", requestId: id })}
                    className="mobile-btn mobile-btn--purple"
                  >
                    Reject
                  </Button>
                </BtnGroup>
              </>
            )}
            {status === "waiting" && (
              <BtnGroup three>
                <Button
                  onClick={onOpenModal({ type: "approve", requestId: id })}
                  className="mobile-btn mobile-btn--blue"
                >
                  Approve
                </Button>
                <Button
                  onClick={onOpenModal({ type: "reject", requestId: id })}
                  className="mobile-btn mobile-btn--purple"
                >
                  Reject
                </Button>
              </BtnGroup>
            )}
          </div>
        ))}
      </MobileTable>
      <MobilePagination onPageChange={onChangePagination} {...paginationConfig} />
      <Drawer width="80vw" placement="right" closable onClose={() => setDrawerOpen(false)} visible={isDrawerOpen}>
        <List
          header={<div>Review changes:</div>}
          footer={
            openedRecord?.status === "pending" && (
              <StatusPopover
                record={openedRecord}
                onApproveStatus={onApproveStatus}
                onRejectStatus={onRejectStatus}
                onAction={() => {
                  fetchRetailerChangeNotifications();
                  setDrawerOpen(false);
                }}
                // onWaitStatus={onWaitStatus}
              />
            )
          }
          bordered
          dataSource={updatesSummary}
          renderItem={(item) => {
            if (item.old instanceof Object || !item.old) {
              return Object.keys(item.new).map((key) => (
                <>
                  {!["id", "path", "createdAt"].includes(key) &&
                    item.old[key] !== item.new[key] &&
                    String(item.old[key]) !== String(item.new[key]) && (
                      <List.Item>
                        {key !== "url" ? (
                          <>
                            <strong> {key}:</strong> {item.old[key]}
                            <span style={{ margin: "0 10px", color: "#01cdfb" }}>
                              <RightSquareOutlined />
                            </span>
                            {item.new[key]}
                          </>
                        ) : (
                          <>
                            <List.Item.Meta
                              title={<strong>Changed document:</strong>}
                              description={
                                <>
                                  <div style={{ width: "40%" }}>
                                    <Badge.Ribbon text="Old">
                                      <Image src={item.old[key]} />
                                    </Badge.Ribbon>
                                  </div>
                                  <div style={{ width: "40%" }}>
                                    <Badge.Ribbon text="New">
                                      {" "}
                                      <Image src={item.new[key]} />
                                    </Badge.Ribbon>
                                  </div>
                                </>
                              }
                            />
                            {/* <strong style={{ width: "100%" }}> Changed document :</strong> */}
                          </>
                        )}
                      </List.Item>
                    )}
                </>
              ));
              // eslint-disable-next-line
            } else {
              // eslint-disable-next-line
              return (
                <>
                  {item.old !== item.new && (
                    <List.Item>
                      <strong> Changed {item.index} :</strong> <br />
                      {item.old}
                      <span style={{ margin: "0 10px", color: "#01cdfb" }}>
                        <RightSquareOutlined />
                      </span>
                      {item.new}
                    </List.Item>
                  )}
                </>
              );
            }
          }}
        />
      </Drawer>
    </>
  );
};
