import React, { useState } from "react";
import { message, Button, Checkbox } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { API_URL, PAGE_URL } from "../../../../constants/config";
import { getAuthData, getUserTenant, updateRefreshToken } from "../../../../helpers/authStorage";
import arrowLeft from "../../../../assets/arrow-left.png";
// import { refreshToken } from '../../redux/resources/changeUserToken';
import UploadDocumentDragger from "../../../../components/DataEntry/UploadDocumentDragger";
import RetailerRegistrationFinished from "../RetailerRegistrationFinished";
import TermsAndConditions from "../../../NewLanding/components/TermsConditionsModal";

import "./retailerDocumentsUpload.less";

const RetailerDocumentUpload = ({ onPrevStep, uploadedImage, setUploadedImage, retailerInfo }) => {
  const [isRetailerRegistrationFinished, setRetailerRegistrationFinished] = useState(false);
  const [isAcceptedTerms, setIsAcceptedTerms] = useState(false);
  const [isTermsAndConditionsVisible, setIsTermsAndConditionsVisible] = useState(false);
  const intl = useIntl();

  const finishRetailerRegistration = () => {
    setRetailerRegistrationFinished(true);
  };

  const onTermsChange = (e) => {
    setIsAcceptedTerms(e.target.checked);
  };

  const onTermsClick = (e) => {
    e.stopPropagation();
    setIsTermsAndConditionsVisible(true);
  };

  const draggerProps = {
    name: "commercialRegistration",
    multiple: false,
    method: "put",
    headers: {
      Authorization: `Bearer ${getAuthData().accessToken}`,
      Tenant: getUserTenant(),
    },
    action: `${API_URL}/retailers/self/commercialRegistration`, // (file)=>saveDriverDocument(file),
    showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        setUploadedImage(info.file.originFileObj);
      }
      if (status === "done") {
        setUploadedImage(info.file.originFileObj);
        message.success(
          `${info.file.name} ${intl.formatMessage({
            id: "page.step.documentUpload.uploadSuccess",
            defaultMessage: "file uploaded successfully.",
          })}`,
        );
      } else if (status === "error") {
        setUploadedImage("");
        if (info.file.response.statusCode === 401) {
          return updateRefreshToken().then(() =>
            message.error(
              `${info.file.name} ${intl.formatMessage({
                id: "page.step.documentUpload.uploadFailed",
                defaultMessage: "file upload failed.",
              })} ${intl.formatMessage({
                id: "page.step.documentUpload.tryToUpload",
                defaultMessage: "Please try to upload file one more time",
              })}`,
            ),
          );
        }
        message.error(
          `${info.file.name} ${intl.formatMessage({
            id: "page.step.documentUpload.uploadFailed",
            defaultMessage: "file upload failed.",
          })}`,
        );
      }
    },
  };

  return !isRetailerRegistrationFinished ? (
    <div>
      <h3 className="driverInfoTitle">
        <FormattedMessage id="page.step.documentUpload.title" defaultMessage="Copy of commercial registration" />
      </h3>
      <div className="uploadRetailerDocumetWrapper">
        <UploadDocumentDragger draggerProps={draggerProps} uploadedImage={uploadedImage} retailerInfo={retailerInfo} />
      </div>
      <button style={{ marginTop: 15 }} onClick={() => setUploadedImage("")}>remove document</button>
      <div className="commercial-checkbox">
        <Checkbox onChange={onTermsChange} />
        By checking this box, I confirm that I have read and understood{" "}
        <span className="termsConditionals" onClick={onTermsClick}>
          the terms and conditions
        </span>{" "}
        available at{" "}
        <a href={PAGE_URL} target="_blank" rel="noopener noreferrer" className="privacyPolicyLink">
         app.dliv.me
        </a>
      </div>
      <div className="stepAction">
        <>
          <img src={arrowLeft} alt="Left arrow icon" />
          <Button className="previousStepButton" type="text" onClick={() => onPrevStep()}>
            {intl.formatMessage({ id: "steps.previousStep", defaultMessage: "Previous Step" })}
          </Button>
        </>
        <Button
          type="primary"
          disabled={(!uploadedImage && !retailerInfo?.commercialRegistration?.url) || !isAcceptedTerms}
          onClick={() => finishRetailerRegistration()}
          className="nextStepButton"
        >
          {intl.formatMessage({ id: "steps.finish", defaultMessage: "> Finish" })}
        </Button>
      </div>
      <TermsAndConditions
        isTermsAndConditionsVisible={isTermsAndConditionsVisible}
        setIsTermsAndConditionsVisible={setIsTermsAndConditionsVisible}
      />
    </div>
  ) : (
    <RetailerRegistrationFinished />
  );
};

export default RetailerDocumentUpload;
