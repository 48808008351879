import React from "react";
import CustomDivider from "../../components/Divider";

export default function Admin404Page() {
  return (
    <div
      className="pageWrapper"
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "30%",
      }}
    >
      <h2 className="pageTitle">404</h2>
      <CustomDivider />
      Page not found
      <a href="/admin">Return Home</a>
    </div>
  );
}
