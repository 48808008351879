import React from "react";
import { Button } from "antd";
import classnames from "classnames";
import { useIntl } from "react-intl";

import { ReactComponent as ShareLocationIcon } from "../../../../assets/shareLocation.svg";
import { ReactComponent as SharearrowIcon } from "../../../../assets/sharearrow.svg";

import "./shareLocation.less";

const ShareLocation = ({ onOpenModal, clsName, isReturn, disabled, warnUser }) => {
  const intl = useIntl();
  return (
    <div className={classnames({ shareLocationWrapper: true, [clsName]: clsName })}>
      <p className="shareLocationText">
        {isReturn
          ? `${intl.formatMessage({
              id: "page.client.home.share.toPick",
              defaultMessage: "Please share your location in order for us to pick up your package",
            })}`
          : `${intl.formatMessage({
              id: "page.client.home.share.toDeliver",
              defaultMessage: "Please share your location in order for us to deliver your package",
            })}`}
      </p>
      <ShareLocationIcon className="shareLocationIconStyle" />
      <Button
        type="primary"
        id="shareLocation"
        onClick={disabled ? warnUser : onOpenModal}
        icon={<SharearrowIcon className="shareArrowIconStyle" />}
        className="shareLocationButtonStyle"
      >
        {`${intl.formatMessage({
          id: "shareLocation",
          defaultMessage: "Share location",
        })}`}
      </Button>
      <p id="location" />
    </div>
  );
};

export default ShareLocation;
