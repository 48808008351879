import React from "react";
import { Col, Row, Skeleton } from "antd";

const DetailsSkeleton = () => (
  <div>
    <Row>
      <Col span={11}>
        <Skeleton active />
        <Skeleton active />
      </Col>
      <Col span={11} offset={1}>
        <Skeleton active />
        <Skeleton active />
      </Col>
    </Row>
    <Row>
      <Col span={11}>
        <Skeleton active />
        <Skeleton active />
      </Col>
      <Col span={11} offset={1}>
        <Skeleton active />
        <Skeleton active />
      </Col>
    </Row>
  </div>
);

export default DetailsSkeleton;
