import React from "react";
import GoogleMapReact from "google-map-react";

import Customer from "../markers/Customer";
import { PickUpPoint } from "./PickUpPoint";
import { API_KEY } from "../../constants/config";

export const OrderGoogleMap = ({ orderInfo, centerCoordinates }) => (
  <GoogleMapReact
    bootstrapURLKeys={{ key: API_KEY }}
    defaultCenter={{
      lat: centerCoordinates?.lat,
      lng: centerCoordinates?.long,
    }}
    defaultZoom={10}
    distanceToMouse={() => {}}
  >
    <Customer
      orderInfo={orderInfo}
      lat={Number(orderInfo?.deliveryPoint?.lat)}
      lng={Number(orderInfo?.deliveryPoint?.long)}
    />
    <PickUpPoint 
      orderInfo={orderInfo} 
      lat={Number(orderInfo?.pickupPoint?.lat)}
      lng={Number(orderInfo?.pickupPoint?.long)} 
    />
  </GoogleMapReact>
);
