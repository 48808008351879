import { Button, Modal, notification, Row, Table, Tooltip } from "antd";
import { FC, useRef, useState } from "react";
import React from "react";
import { getStatusBage } from "../../../../helpers/general";
import {
  DraggableBodyRowProps,
  IOrdersTable,
  IOrdersTableAction,
  IPaginationState,
} from "../../types/ordersTable.types";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import "./ordersTable.style.less";
import { LockOutlined } from "@ant-design/icons";
import { IOrder } from "src/entities";
import Ticket from "src/components/Ticket";
import { TicketViewEnum } from "src/components/Ticket/type";

const type = "DragableBodyRow";
const DragableBodyRow = (props: DraggableBodyRowProps) => {
  const { index, moveRow, isPreview, className, style, ...restProps } = props;
  const ref = useRef<HTMLTableRowElement>(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index || isPreview) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item: any) => {
      if (!isPreview) moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

const mapOrders = (orders: IOrder[] | undefined, isPreview: boolean) => {
  return (
    orders?.map((order: IOrder, index: number) => {
      return {
        ...order,
        index: index + 1,
        customerName: order.customerInfo.name,
        retailerName: order.retailer?.name || "-",
        isPreview: isPreview,
        key: order.id,
      };
    }) ?? []
  );
};

export const OrdersTable: FC<IOrdersTable & IOrdersTableAction> = ({
  isOrdersWithoutJob = false,
  JobInfo,
  className,
  onSelectOrders,
  moveOrders,
  destinationJob,
  isMain,
  isActionPreview,
  addOrders,
  deleteOrders,
  handleReorderJobOrders,
  isJobModifcationAllowed,
  pagination = null,
}) => {
  const [paginationState, setPaginationState] = useState<IPaginationState>({
    pageSize: 25,
    current: 1,
  });

  let selectedOrders = JobInfo?.selectedIds;
  let previewCount = JobInfo?.previewOrdersToAdd?.length ?? 0;
  // draggable rows ( orders )
  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const columns: any[] = [
    {
      title: "N°",
      key: "index",
      render: (data: IOrder, _: any, i: number) => {
        if (data && !data.isPreview) return i + 1 - previewCount;
      },
      width: 35,
    },
    {
      title: "Customer",
      key: "customerName",
      render: (data: any) => {
        return <p className="orderLink">{data?.customerName}</p>;
      },
    },
    { title: "Order ID", dataIndex: "id", key: "id" },
    {
      title: "Status",
      key: "status",
      render: (data: IOrder) => {
        return getStatusBage(data?.status?.split("_").join(" "));
      },
    },
    { title: "Retailer name", dataIndex: "retailerName", key: "retailerName" },
    {
      title: "Return",
      key: "isReturn",
      render: (data: IOrder) => {
        return data.isReturn ? "YES" : "NO";
      },
      width: 60,
    },

    {
      title: "Ticket",
      key: "ticket",
      render: (data: any) => {
        return (
          <Ticket
            buttonType="link"
            jobId={isOrdersWithoutJob ? null : data?.jobId}
            orderId={data.id}
            ticketDetails={data?.ticket ?? null}
            users={{
              retailer: data?.retailerName,
            }}
            {...(!isOrdersWithoutJob && { currentView: TicketViewEnum.CONFIGURE_WITH_JOB })}
          />
        );
      },
    },
  ];

  let dataSource: IOrder[] = [];
  dataSource = mapOrders(JobInfo?.job?.deliveryOrders, false);
  let previewDataSource = mapOrders(JobInfo?.previewOrdersToAdd, true);

  if (dataSource && previewDataSource) {
    let jobOrders = dataSource || [];
    dataSource = [...previewDataSource, ...jobOrders];
  }

  // check if all orders are selected
  // this is useful for cleanup later
  const isAllOrdersSelected = (selectedOrders?: number[]) =>
    selectedOrders && selectedOrders.length === JobInfo?.job.deliveryOrders.length;

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    Modal.info({
      title: "Are you sure to change order of orders?",
      closable: true,
      okCancel: true,
      async onOk() {
        const originOrder = dataSource[dragIndex];
        const swappedOrder = dataSource[hoverIndex];

        if (!originOrder || !swappedOrder) {
          notification.warning({ message: "One of the swapped orders is undefined ! please retry .." });
          return;
        }
        const pointsOrder: number[] = [originOrder, swappedOrder].map((order) => order?.pointOrder!);

        await handleReorderJobOrders(pointsOrder, [], JobInfo?.job.id!);
      },

      onCancel() {
        return false;
      },
    });
  };

  const handlePaginationChange = (current: number, pageSize: number): void => {
    setPaginationState({ current, pageSize });
    addOrders(JobInfo?.job.id!, { current, pageSize });
  };

  return (
    <div data-testid="orders-table-wrapper">
      <Row hidden={isActionPreview} className="orderTable--toolbar">
        <Button
          hidden={!isMain}
          onClick={() => addOrders(JobInfo?.job.id!, paginationState)}
          disabled={!isJobModifcationAllowed}
        >
          Add orders
        </Button>
        <Button
          hidden={!isMain}
          disabled={!isJobModifcationAllowed || !selectedOrders || !selectedOrders.length}
          onClick={() => deleteOrders(JobInfo?.job.id!, selectedOrders!, isAllOrdersSelected(selectedOrders) || false)}
        >
          Delete orders
        </Button>
        <Button
          hidden={!destinationJob}
          disabled={!isJobModifcationAllowed || !selectedOrders || !selectedOrders.length}
          onClick={() =>
            moveOrders(
              JobInfo?.job.id!,
              selectedOrders!,
              destinationJob!,
              isMain || false,
              isAllOrdersSelected(selectedOrders) || false,
            )
          }
        >
          Move orders to job #{destinationJob}
        </Button>
      </Row>
      <DndProvider backend={HTML5Backend}>
        <Table
          components={isActionPreview || dataSource?.length >= 25 ? undefined : components}
          rowClassName={(record) => (record.isPreview ? "orderRow--preview" : "orderRow")}
          scroll={{ y: 300 }}
          className={className}
          columns={columns}
          size="small"
          dataSource={dataSource ?? []}
          pagination={
            pagination
              ? {
                  pageSize: pagination?.itemsPerPage,
                  total: pagination?.totalItems,
                  current: pagination?.currentPage,
                  showSizeChanger: true,
                  onChange: handlePaginationChange,
                }
              : false
          }
          onRow={
            isActionPreview
              ? (data: IOrder, index?: number) => {
                  let attr = {};
                  if (data.isPreview) {
                    attr = {
                      "data-testid": "preview-row",
                      index,
                    };
                  } else {
                    attr = {
                      "data-testid": "original-row",
                      index,
                    };
                  }
                  return attr as React.HTMLAttributes<any>;
                }
              : (data: IOrder, index?: number) => {
                  let attr = {};
                  if (data.isPreview || dataSource?.length >= 25) {
                    attr = {
                      "data-testid": "preview-row",
                      isPreview: true,
                      index,
                      moveRow: () => notification.info({ message: "This order is a preview, you can't move it !" }),
                    };
                  } else {
                    attr = {
                      "data-testid": "original-row",
                      isPreview: false,
                      index,
                      moveRow,
                    };
                  }
                  return attr as React.HTMLAttributes<any>;
                }
          }
          rowKey={(record) => record.id}
          rowSelection={{
            renderCell(checked, record, index, node) {
              if (record.isPreview) {
                return (
                  <Tooltip title="This is a preview order !">
                    <LockOutlined color="red" />
                  </Tooltip>
                );
              }
              return node;
            },
            selectedRowKeys: selectedOrders,
            onChange: (keys, _) => {
              onSelectOrders(keys, JobInfo?.job.id!, isMain ?? false, isAllOrdersSelected(keys as number[]));
            },
          }}
        />
      </DndProvider>
    </div>
  );
};
