import { useEffect, useCallback, useState } from "react";
import { omit, isEmpty } from "lodash";
import { notification } from "antd";
import moment from "moment";
import { useIntl } from "react-intl";

import { getQueryParam } from "../../../../../helpers/general";

export const useHooks = ({ location, isMobile, fetchReportsRetailerOrder }) => {
  const [paginationData, setPaginationData] = useState({});
  const [reports, setReports] = useState([]); //
  // const [isDeliveryReportFetching, setIsDeliveryReportFetching] = useState(false);

  const getDeliveryReportsQueryParams = useCallback(() => getQueryParam(location.search), [location.search]);
  const [deliverySearchQuery, setDeliverySearchQuery] = useState("driverName");
  const [searchedValue, setSearchedValue] = useState(undefined);
  const [reportStatusQuery, setReportStatusQuery] = useState("all");
  const reportTypeQuery = "pickup_report";

  const intl = useIntl();
  // const fetchReports = (a,b) =>{
  //   setIsDeliveryReportFetching(true)
  //  return fetchReportsRetailerOrder(a,b).then((res)=>{
  //     setReports(res.body.items)
  //     setPaginationData(res.body.meta)
  //     setIsDeliveryReportFetching(false)
  //   })
  // }

  const fetchAllDeliveryReports = useCallback(() => {
    // console.log([fetchReports, getDeliveryReportsQueryParams, reportStatusQuery, reportTypeQuery])

    const queryParams = getDeliveryReportsQueryParams();
    const queryWithPage = queryParams.page ? queryParams : { ...queryParams, page: 1, limit: 10 };
    const queryFilters = {
      [deliverySearchQuery]: searchedValue || "",
    };
    if (reportTypeQuery !== "all") {
      if (reportTypeQuery === "pickup_delivery_report") {
        // queryFilters.type = "pickup_report"
        // queryFilters.someKey = "someValue" //TODO add parameter
      } else {
        queryFilters.type = reportTypeQuery;
      }
    }
    if (reportStatusQuery !== "all") {
      queryFilters.status = reportStatusQuery;
    }

    fetchReportsRetailerOrder(
      {},
      {
        query: { ...queryFilters, ...queryWithPage },
      },
    )
      .then((res) => {
        setReports(res.body.items);
        setPaginationData(res.body.meta);
      })
      .catch((error) =>
        notification.error({
          message: "Error",
          description: error.body && error.body.message && error.body.message,
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchReportsRetailerOrder, getDeliveryReportsQueryParams, reportStatusQuery, reportTypeQuery]);

  useEffect(() => {
    fetchAllDeliveryReports();
  }, [fetchAllDeliveryReports]);

  useEffect(() => {
    updateDeliveryReportsPageUrl({});
    fetchAllDeliveryReports();
    // eslint-disable-next-line
  }, [reportStatusQuery, reportTypeQuery]);

  const updateDeliveryReportsPageUrl = () => {
    //updatePageUrl(newQueryParams, previousQueryParams, history, "retailer_cabinet/home");
  };

  const onChangePagination = (pagination) => {
    const search = omit(pagination, ["pageSizeOptions", "position", "showSizeChanger", "total", "pageSize", "current"]);
    if (!isEmpty(search)) {
      updateDeliveryReportsPageUrl({
        ...omit(pagination, ["pageSizeOptions", "position", "showSizeChanger", "total", "pageSize", "current"]),
        page: pagination.current,
        limit: pagination.pageSize,
      });
    } else {
      updateDeliveryReportsPageUrl({ page: pagination.current, limit: pagination.pageSize });
    }
  };

  const currentQueryParams = getDeliveryReportsQueryParams();

  const deleteReport = () => () => {
    // deleteOneReports({ reportId: id })
    //   .then(() => fetchAllDeliveryReports())
    //   .catch((error) =>
    //     notification.error({
    //       message: "Error",
    //       description: error?.body?.message,
    //     }),
    //   );
  };

  const handleSearchDelivery = async (e) => {
    if (e.target.value) {
      const queryFilters = {};
      if (reportTypeQuery !== "all") {
        queryFilters.type = reportTypeQuery;
      }
      if (reportStatusQuery !== "all") {
        queryFilters.status = reportStatusQuery;
      }
      onChangePagination({
        ...queryFilters,
        [deliverySearchQuery]: e.target.value,
        page: 1,
      });
    } else {
      onChangePagination({ [deliverySearchQuery]: "" });
    }
  };

  const onChangeSearchFilter = (value) => {
    onChangePagination({});
    setSearchedValue("");
    setDeliverySearchQuery(value);
  };

  const handleSetDates = (dates, momentDates) => {
    if (dates) {
      const fromDate = moment(momentDates[0]).toISOString(true).substring(0, 23);
      const toDate = moment(momentDates[1]).toISOString(true).substring(0, 23);
      // toDate.setUTCHours(23, 59, 59, 999);
      // toDate = toDate.toISOString();
      onChangePagination({ from: fromDate, to: toDate });
    } else {
      onChangePagination({ from: "", to: "" });
    }
  };

  const disabledTomorrowAndFuture = (current) => current > new Date();

  const searchFilter = [
    {
      text: `${intl.formatMessage({
        id: "driver",
        defaultMessage: "Driver",
      })}`,
      value: "driverName",
    },
    {
      text: `${intl.formatMessage({
        id: "tabPane.orderID",
        defaultMessage: "Order ID",
      })}`,
      value: "orderId",
    },
    {
      text: `${intl.formatMessage({
        id: "tabPane.trackingID",
        defaultMessage: "Tracking ID",
      })}`,
      value: "trackingId",
    },
  ];

  const paginationConfig = {
    pageSize: currentQueryParams?.limit || 10,
    total: paginationData?.totalItems || 50,
    current: currentQueryParams?.page || 1,
    pageSizeOptions: isMobile ? [5, 10, 20] : [10, 20, 30, 50],
    showSizeChanger: true,
    position: ["bottomRight"],
  };

  return {
    searchedValue,
    searchFilter,
    paginationConfig,
    disabledTomorrowAndFuture,
    setSearchedValue,
    onChangeSearchFilter,
    onChangePagination,
    handleSearchDelivery,
    handleSetDates,
    deleteReport,
    reportStatusQuery,
    setReportStatusQuery,
    reportTypeQuery,
    // setReportTypeQuery,
    paginationData,
    reports,
    isDeliveryReportFetching: false,
  };
};
