export interface ITicketMSTP {
  buttonType: "link" | "primary";
  currentView?: TicketViewEnum;
  jobId: number;
  orderId: number;
  ticketDetails: Record<any, any> | null;
  users: Record<any, any>;
}

export enum UsersRolesEnum {
  SUPER_ADMIN = "super_admin",
  ADMIN = "admin",
  OPERATIONS_TEAM = "operations_team",
  DRIVER_MANAGER = "driver_manager",
  DRIVER = "driver",
  RETAILER = "retailer",
  EVENT = "event",
  CUSTOMER_SERVICE = "customer_service",
}

export enum TicketStatusEnum {
  ON_HOLD = "on-hold",
  OPEN = "open",
  CLOSE = "close",
}

export enum TicketViewEnum {
  JOB_DETAILS = "job_details",
  JOBS = "jobs",
  CONFIGURE_WITH_JOB = "configure_with_job",
}

export interface ICurrentViewActions {
  updateOrderTicketState: any;
  updateOrderNewTicketState: any;
  getMessages: any;
  sendMessage: any;
  updateTicket: any;
  createTicket: any;
}
