import React, { useState } from "react";
import { Button, notification, Input, Drawer } from "antd";
import GoogleMapReact from "google-map-react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { GoogleApiWrapper } from "google-maps-react";
import { FormattedMessage, useIntl } from "react-intl";
import Geocode from "react-geocode";

import { API_KEY, mapCenters } from "../../../../constants/config";

import PointMarker from "./PointMarker";
import { ReactComponent as Target } from "../../../../assets/map/target.svg";
import { getUserTenant } from "../../../../helpers/authStorage";

Geocode.setApiKey(API_KEY);

function DrawerWrap({
  setDrawerVisible,
  selectedLocation = { lat: 0, long: 0 },
  setSelectedLocation,
  isDrawerVisible,
  handleLocationUpdate,
  handleLocationDelete,
  restrictions,
}) {
  const [currentZoom, setCurrentZoom] = useState(5);
  const [addressSearch, setAddressSearch] = useState("");
  const currentTenant = getUserTenant() ?? "Qatar";
  const defaultCenter = { lat: mapCenters[currentTenant].lat, lng: mapCenters[currentTenant].long };

  const intl = useIntl();

  const handleSelect = async (value) => {
    setAddressSearch(value);
    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);
    setCurrentZoom(10);
    setSelectedLocation((prev) => {
      return { ...prev, address: value, lat: latLng.lat, long: latLng.lng };
    });
  };

  const handleMapSelect = (loc) => {
    Geocode.fromLatLng(loc.lat, loc.lng).then(
      (response) => {
        const adress = response.results[0].formatted_address;
        setSelectedLocation((prev) => {
          return { ...prev, address: adress, lat: loc.lat, long: loc.lng };
        });
      },
      (error) => {
        console.error(error);
      },
    );
  };
  const handleSave = () => {
    if (selectedLocation.name !== "") {
      const location = { ...selectedLocation };
      location.lat = parseFloat(selectedLocation.lat);
      location.long = parseFloat(selectedLocation.long);
      handleLocationUpdate(location);
    } else {
      notification.error({
        message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
        description: "Add name please!",
      });
    }
  };

  const getCurrentLocation = (e) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        handleMapSelect({ lat: position.coords.latitude, lng: position.coords.longitude });
      });
    } else {
      notification.error({
        message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
        description: "Geolocation is not supported by this browser.",
      });
    }
  };

  return (
    <Drawer width="80vw" placement="right" closable onClose={() => setDrawerVisible(false)} visible={isDrawerVisible}>
      <PlacesAutocomplete
        value={addressSearch}
        onChange={(address) => setAddressSearch(address)}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Input.Search
              {...getInputProps({
                placeholder: `${intl.formatMessage({ id: "search", defaultMessage: "Search" })}`,
              })}
              value={addressSearch}
              className="autocomplete-search"
            />
            <div className="autocomplete-dropdown-container">
              {loading && (
                <div>
                  <FormattedMessage id="loading" defaultMessage="Loading..." />
                </div>
              )}
              {suggestions.map((suggestion) => {
                const style = { backgroundColor: suggestion.active ? "#fafafa" : "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                    key={suggestion.description}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>

      <div className="retailer-modal__map-wrapper">
        <GoogleMapReact
          options={{ clickableIcons: false, fullscreenControl: false }}
          onClick={({ x, y, lat, lng, event }) => {
            handleMapSelect({ lat, lng });
          }}
          defaultCenter={{ lat: selectedLocation.lat, lng: selectedLocation.long }}
          bootstrapURLKeys={{ key: API_KEY }}
          // defaultZoom={0}
          zoom={currentZoom}
          center={{
            lat: parseFloat(selectedLocation.lat) || defaultCenter.lat,
            lng: parseFloat(selectedLocation.long) || defaultCenter.lng,
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            if (restrictions?.areaRestriction) {
              new maps.Circle({
                strokeOpacity: 0.4,
                strokeWeight: 2,
                fillColor: "rgb(144, 238, 144)",
                fillOpacity: 0.2,
                map,
                center: { lat: Number(restrictions?.areaLat || "0"), lng: Number(restrictions?.areaLong || "0") },
                radius: restrictions?.areaRadius * 1000,
              });
            }
          }}
        >
          <PointMarker lat={selectedLocation.lat} lng={selectedLocation.long} />
        </GoogleMapReact>
      </div>
      <div className="pointGrid">
        <Input
          value={selectedLocation.name}
          placeholder={`${intl.formatMessage({
            id: "name",
            defaultMessage: "Name",
          })}`}
          onChange={(e) => {
            if ((/^[^0-9]+.*/.test(e.target.value) || !e.target.value) && String(e.target.value)?.length < 60) {
              setSelectedLocation((prev) => {
                return { ...prev, name: e.target.value };
              });
            }
          }}
        />
        <Input
          value={selectedLocation.address}
          placeholder={`${intl.formatMessage({
            id: "address",
            defaultMessage: "Address",
          })}`}
          onChange={(e) => {
            setSelectedLocation((prev) => {
              return { ...prev, address: e.target.value };
            });
          }}
        />

        <Input
          value={selectedLocation.lat || "0"}
          placeholder="Latitude"
          onChange={(e) => {
            if (e.target.value <= 90 && e.target.value >= -90) {
              setSelectedLocation((prev) => {
                return { ...prev, lat: e.target.value };
              });
            }
          }}
        />

        <Input
          value={selectedLocation.long || "0"}
          placeholder="Longtitude"
          onChange={(e) => {
            if (e.target.value <= 180 && e.target.value >= -180) {
              setSelectedLocation((prev) => {
                return { ...prev, long: e.target.value };
              });
            }
          }}
        />
        <Button
          type="primary"
          disabled={!parseFloat(selectedLocation.lat) || !parseFloat(selectedLocation.long)}
          onClick={handleSave}
        >
          <FormattedMessage id="tabPane.actions.save" defaultMessage="Save" />
        </Button>
        <Button danger onClick={() => handleLocationDelete(selectedLocation)}>
          <FormattedMessage id="tabPane.actions.delete" defaultMessage="Delete" />
        </Button>
        <div
          className="findMe"
          style={{ position: "relative", right: "10px", bottom: "228px" }}
          onClick={getCurrentLocation}
        >
          <Button>
            <Target />
          </Button>
        </div>
      </div>
    </Drawer>
  );
}
export default GoogleApiWrapper({
  apiKey: API_KEY,
})(DrawerWrap);
