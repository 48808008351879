import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory, useLocation } from "react-router-dom";

import { actions as reportActions } from "../../redux/resources/reports";
import { DesktopView } from "./DesktopView";
import { MobileView } from "./MobileView";
import { useHooks } from "./hooks/useHooks";

import "./deliveryReport.less";

const DeliveryReport = ({
  isMobile,
  fetchDeliveryReports,
  deleteOneReports,
  isDeliveryReportFetching,
  reports,
  paginationData,
}) => {
  const location = useLocation();
  const history = useHistory();
  const {
    searchedValue,
    searchFilter,
    paginationConfig,
    disabledTomorrowAndFuture,
    setSearchedValue,
    onChangeSearchFilter,
    onChangePagination,
    handleSearchDelivery,
    handleSetDates,
    deleteReport,
    reportStatusQuery,
    setReportStatusQuery,
    reportTypeQuery,
    setReportTypeQuery,
  } = useHooks({ location, history, isMobile, paginationData, fetchDeliveryReports, deleteOneReports });

  return (
    <>
      {isMobile ? (
        <MobileView
          isDeliveryReportFetching={isDeliveryReportFetching}
          reports={reports}
          searchFilter={searchFilter}
          paginationConfig={paginationConfig}
          disabledTomorrowAndFuture={disabledTomorrowAndFuture}
          onChangeSearchFilter={onChangeSearchFilter}
          onChangePagination={onChangePagination}
          handleSearchDelivery={handleSearchDelivery}
          handleSetDates={handleSetDates}
          deleteReport={deleteReport}
          reportStatusQuery={reportStatusQuery}
          setReportStatusQuery={setReportStatusQuery}
          reportTypeQuery={reportTypeQuery}
          setReportTypeQuery={setReportTypeQuery}
        />
      ) : (
        <DesktopView
          isDeliveryReportFetching={isDeliveryReportFetching}
          reports={reports}
          searchedValue={searchedValue}
          searchFilter={searchFilter}
          paginationConfig={paginationConfig}
          disabledTomorrowAndFuture={disabledTomorrowAndFuture}
          setSearchedValue={setSearchedValue}
          onChangeSearchFilter={onChangeSearchFilter}
          onChangePagination={onChangePagination}
          handleSearchDelivery={handleSearchDelivery}
          handleSetDates={handleSetDates}
          deleteReport={deleteReport}
          reportStatusQuery={reportStatusQuery}
          setReportStatusQuery={setReportStatusQuery}
          reportTypeQuery={reportTypeQuery}
          setReportTypeQuery={setReportTypeQuery}
        />
      )}
    </>
  );
};

const MSTP = (state) => {
  return {
    isMobile: state.admin.isMobile,
    reports: state.reports.deliveryReports?.items,
    isDeliveryReportFetching: state.reports.deliveryReports?.isFetching,
    paginationData: state.reports.deliveryReports?.pagination,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(reportActions, dispatch),
}))(DeliveryReport);
