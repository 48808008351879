import { createResource, defaultHeaders } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";
import { makeDefaultRequest } from "../../helpers/requestComponents";

export const { types, actions, rootReducer } = createResource({
  name: "retailersGroups",

  actions: {
    fetch: {
      method: "GET",
      url: `${API_URL}/admin/restricted-group`,
      transformResponse: (res) => ({ ...res, items: res.body.items, pagination: res.body.meta }),
      reduce: (state, action) => ({
        ...state,
        items: action.items,
        pagination: action.pagination,
      }),
    },
    fetchGroupRetailers: makeDefaultRequest(
      "GET",
      `${API_URL}/admin/restricted-group/:groupId/retailers`,
      "selectedGroupRetailers",
    ),
    fetchRetailers: makeDefaultRequest("GET", `${API_URL}/admin/restricted-group/retailers`, "retailers"),
    createNewGroup: {
      method: "POST",
      url: `${API_URL}/admin/restricted-group`,
    },
    deleteGroup: {
      method: "DELETE",
      url: `${API_URL}/admin/restricted-group/:groupId`,
    },
    addGroupMember: {
      method: "PUT",
      url: `${API_URL}/admin/restricted-group/:groupId/retailer/:retailerId`,
    },
    removeGroupMember: {
      method: "DELETE",
      url: `${API_URL}/admin/restricted-group/:groupId/retailer/:retailerId`,
    },
  },
});

setAuthHeader(getAuthData().accessToken, defaultHeaders);
