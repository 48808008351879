import React from "react";
import { SortableHandle } from "react-sortable-hoc";

import { ReactComponent as OpenCollapse } from "../../../../../../assets/adminPanel/mobile-collapse.svg";
import { ReactComponent as OpenedCollapse } from "../../../../../../assets/adminPanel/mobile-opened-collase.svg";
import { ReactComponent as DragIcon } from "../../../../../../assets/adminPanel/mobile-drag-icon.svg";

const DragHandler = SortableHandle(() => <DragIcon />);

export const CollapseHeader = ({ onClick, name, isActive }) => {
  return (
    <div onClick={onClick} className="panel-header">
      <div className="left">
        <DragHandler />
        <div>Customer</div>
      </div>
      <div className="right">
        <div>{name}</div>
        {isActive ? <OpenedCollapse /> : <OpenCollapse />}
      </div>
    </div>
  );
};
