import { BellOutlined, HomeFilled, LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { Dropdown, Badge, Button, Space, Popconfirm, Breadcrumb } from "antd";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import TenantSelector from "src/components/DataEntry/TenantSelector";
import { useBreadcrumbContext } from "../../BreadCrumpManager/BreadCrumpProvider";
import { IHeaderProps } from "./types";
import NotificationList from "src/components/NotificationList";
import useHeaderNotifications from "../hooks/useHeaderNotifications";

const Header: FC<IHeaderProps> = ({ notifications, onLogout, isOutsiderCompany }) => {
  const intl = useIntl();
  const { items } = useBreadcrumbContext();
  const {notificationsCount} = useHeaderNotifications();
  return (
    <header className="header-wrapper">
      <Space>
        <Breadcrumb>
          {items.map((item) => (
            <Breadcrumb.Item key={item.name}>
              {" "}
              <Link to={item.path} className="crumb-title">
                {item.name === "admin" ? <HomeFilled /> : item.name}
              </Link>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </Space>
      <Space size="middle" style={{ position: "relative" }}>
        <TenantSelector isAdmin isMobile={false} isCustom={false} onChange={undefined} defaultTenant={undefined} />
        {/* <Dropdown overlay={<NotificationList />} placement="bottomLeft" overlayStyle={{backgroundColor:"white",padding:10}}>
          <Badge count={isOutsiderCompany ? 0 : notificationsCount} showZero={false}>
            <Button shape="circle" icon={<BellOutlined />} hidden={isOutsiderCompany} />
          </Badge>
          </Dropdown> 
        <Link to="/admin/preferences">
          <Button shape="round" icon={<SettingOutlined />} />
        </Link>
        */}
        <Popconfirm
          title={`${intl.formatMessage({ id: "modal.logout", defaultMessage: "Are you sure log out?" })}`}
          onConfirm={onLogout()}
          okText={`${intl.formatMessage({ id: "yes", defaultMessage: "Yes" })}`}
          cancelText={`${intl.formatMessage({ id: "no", defaultMessage: "No" })}`}
          overlayClassName="logout-popover-wrapper"
          getPopupContainer={(triggerNode) => triggerNode}
        >
          <Button shape="round" icon={<LogoutOutlined />} />
        </Popconfirm>
      </Space>
    </header>
  );
};

export default Header;
