import React from "react";
import { Drawer } from "antd";

import Retailer from "../Retailer";
import CustomerDetails from "../CustomerDetails";
import { OrderGoogleMap } from "../../../../../components/OrderGoogleMap";

 
const OrderDetails = ({ onClose, centerCoordinates, selectedOrder: orderInfo }) => (
  <Drawer width={640} placement="right" onClose={onClose} visible={orderInfo}>
    <p className="orderInfotitle" style={{ marginBottom: 24 }}>
      Order info <span> № {orderInfo?.id}</span>
    </p>
    <div style={{ height: "500px", width: "100%" }}>
      {centerCoordinates?.lat && centerCoordinates?.long && (
        <OrderGoogleMap orderInfo={orderInfo} centerCoordinates={centerCoordinates} />
      )}
      {orderInfo && (
        <>
          <Retailer orderInfo={orderInfo} />
          <CustomerDetails orderInfo={orderInfo} />
        </>
      )}
    </div>
  </Drawer>
);

export default OrderDetails;
