import React from "react";

import { MobileView } from "./MobileView";
import { DesktopView } from "./DesktopView";
import { useHooks } from "./hooks/useHooks";

import "./style.less";

const DriversMap = () => {
  const {
    isMobile,
    job,
    drivers,
    selectedDriver,
    allDriverSelected,
    locations,
    paginationData,
    selectDriver,
    markerClick,
    showJobs,
    changeQueryParams,
  } = useHooks();

  return (
    <>
      {isMobile ? (
        <MobileView
          job={job}
          drivers={drivers}
          selectedDriver={selectedDriver}
          allDriverSelected={allDriverSelected}
          locations={locations}
          selectDriver={selectDriver}
          markerClick={markerClick}
          showJobs={showJobs}
        />
      ) : (
        <DesktopView
          job={job}
          drivers={drivers}
          selectedDriver={selectedDriver}
          allDriverSelected={allDriverSelected}
          locations={locations}
          paginationData={paginationData}
          selectDriver={selectDriver}
          markerClick={markerClick}
          showJobs={showJobs}
          changeQueryParams={changeQueryParams}
        />
      )}
    </>
  );
};

export default DriversMap;
