import { Row, Timeline } from "antd";
import React, { FC } from "react";
import { IntlShape } from "react-intl";
import { ActivityLogsDataModel } from "../entities/activity-logs.interface";
import { displayChanges, getDegreeIcon, getTimeLineColor } from "../helper/helper";
import "../retailerActivity.less";

const OrderHistory: FC<{ orderId?: number; history: ActivityLogsDataModel[]; intl: IntlShape }> = ({
  orderId,
  history,
  intl,
}) => {
  return (
    <>
      <Row className="order-history-drawer__header">
        <h3>History of Order #{history[0]?.order}</h3>
      </Row>

      <Timeline mode="left">
        {history.map((event: ActivityLogsDataModel) => {
          return (
            <Timeline.Item
              key={event.key}
              label={`${intl.formatDate(event.date, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })} ${intl.formatTime(event.date, {
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
              })}`}
              dot={getDegreeIcon(event.changes)}
              color={getTimeLineColor(event.changes)}
            >
              {displayChanges(event.changes)}
            </Timeline.Item>
          );
        })}
      </Timeline>
    </>
  );
};

export default OrderHistory;
