import React, { useEffect } from "react";
import { Form, Input } from "antd";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { FormattedMessage, useIntl } from "react-intl";
import StepActions from "../../../../components/DataEntry/StepActions";
import { getDataLocalStorageAndSetData, getPhoneInputCountryCode } from "../../../../helpers/general";
import TenantSelector from "../../../../components/DataEntry/TenantSelector";
import "./retailerInformation.less";

const RetailerInformation = ({
  onNextStep,
  saveInformationRetailer,
  currentStep,
  onPrevStep,
  stepsLength,
  retailerInfo,
}) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const tenant = retailerInfo && retailerInfo.tenantName ? retailerInfo.tenantName : "Qatar";

  useEffect(() => {
    const retailerInfo = JSON.parse(localStorage.getItem("retailerInfo"));

    getDataLocalStorageAndSetData(retailerInfo, "name", form);
    getDataLocalStorageAndSetData(retailerInfo, "officeAddress", form);
    getDataLocalStorageAndSetData(retailerInfo, "phoneNumber", form);
    getDataLocalStorageAndSetData(retailerInfo, "tenantName", form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    onNextStep();
    saveInformationRetailer(values);
  };

  const handleChangePhone = (value) => {
    form.setFieldsValue({
      phoneNumber: value,
    });
  };

  const handleCountryChange = (value) => {
    form.setFieldsValue({
      tenantName: value,
    });
  };

  return (
    <>
      <h3 className="driverInfoTitle">
        <FormattedMessage id="page.step.retailerInfo.title" defaultMessage="Retailer Information" />
      </h3>
      <p className="driverInfoDescription">
        <FormattedMessage
          id="page.step.retailerInfo.subtitle"
          defaultMessage="Please provide us with the name of your brand."
        />
      </p>
      <Form
        form={form}
        onValuesChange={(changedValues, allValues) => {
          localStorage.setItem("retailerInfo", JSON.stringify(allValues));
        }}
        name="retailerinfo"
        layout="vertical"
        onFinish={onFinish}
        hideRequiredMark
        className="formStyle"
      >
        <Form.Item
          className="personalInfoItem"
          name="name"
          label={`${intl.formatMessage({ id: "name", defaultMessage: "Name" })}`}
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({ id: "error.inputName", defaultMessage: "Input name!" })}`,
            },
            {
              max: 255,
              message: `${intl.formatMessage({
                id: "error.maxLength",
                defaultMessage: "Maximum length is 255 characters",
              })}`,
            },
          ]}
          initialValue={retailerInfo ? retailerInfo.name : ""}
        >
          <Input placeholder={`${intl.formatMessage({ id: "name", defaultMessage: "Name" })}`} />
        </Form.Item>
        <Form.Item
          className="personalInfoItem"
          name="officeAddress"
          label={`${intl.formatMessage({ id: "input.officeAddress", defaultMessage: "Office address" })}`}
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({
                id: "error.inputOfficeAddress",
                defaultMessage: "Input office address!",
              })}`,
            },
            {
              max: 255,
              message: `${intl.formatMessage({
                id: "error.maxLength",
                defaultMessage: "Maximum length is 255 characters",
              })}`,
            },
          ]}
          initialValue={retailerInfo ? retailerInfo.officeAddress : ""}
        >
          <Input placeholder={`${intl.formatMessage({ id: "input.input.address", defaultMessage: "Address" })}`} />
        </Form.Item>
        <Form.Item
          className="personalInfoItem"
          name="phoneNumber"
          label={`${intl.formatMessage({ id: "input.officePhone", defaultMessage: "Office phone number" })}`}
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({ id: "error.inputPhone", defaultMessage: "Input phone number!" })}`,
            },
            {
              min: 4,
              message: `${intl.formatMessage({
                id: "error.minLength",
                defaultMessage: "Minimum length is 4 charters",
              })}`,
            },
            {
              max: 255,
              message: `${intl.formatMessage({
                id: "error.maxLength",
                defaultMessage: "Maximum length is 255 characters",
              })}`,
            },
          ]}
          initialValue={retailerInfo ? retailerInfo.phoneNumber : ""}
        >
          <ReactPhoneInput
            defaultMask={".... .... ...."}
            country={getPhoneInputCountryCode()}
            placeholder={`${intl.formatMessage({ id: "input.phone", defaultMessage: "Phone" })}`}
            value={form.getFieldValue("phoneNumber")}
            onChange={handleChangePhone}
          />
        </Form.Item>
        <Form.Item
          className="personalInfoItem"
          name="tenantName"
          label={`${intl.formatMessage({ id: "input.country", defaultMessage: "Country" })}`}
          initialValue={tenant}
        >
          <TenantSelector isAdmin={false} onChange={handleCountryChange} defaultTenant={tenant} />
        </Form.Item>
        <StepActions currentStep={currentStep} onPrevStep={onPrevStep} stepsLength={stepsLength} />
      </Form>
    </>
  );
};

export default RetailerInformation;
