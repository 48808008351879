import React, { FC, useEffect, useState } from "react";

import { Button, notification, Checkbox, Row } from "antd";
import { useIntl } from "react-intl";

import { MobileTable } from "../../../../AdminPanel/Mobile/MobileTable";
import { getDriverNames, getMobileBadgeStatus, NO_INFO } from "../../../../AdminPanel/Mobile/helpers";
import { MobileTableRows } from "../../../../AdminPanel/Mobile/MobileTable/components/MobileTableRows";
import { MobileTableExpand } from "../../../../AdminPanel/Mobile/MobileTable/components/MobileTableExpand";
import { BtnGroup } from "../BtnGroup";
import { OrdersCollapse } from "../OrdersCollapse";
import { IJobMobileTableProps } from "src/types/JobManagement";

export const JobsTable: FC<IJobMobileTableProps> = ({
  deliveryJobs,
  innerCollapsed,
  centerCoordinates,
  downloadJobOrdersBarcodes,
  fetchDeliveryJobs,
  onMoveRow,
  onOpenCashAmountModal,
  onForceNextOrder,
  onDeleteOrder,
  onExpandJob,
  onOrderCollapse,
  getPublishStatus,
  assignJobDeliveryJob,
  cancelAssignJobDeliveryJob,
  fetchAvailableDriversDeliveryJob,
  postponeOrderDeliveryJob,
  cancelOrderDeliveryJob,
  getOneDeliveryJob,
  isJobChecked,
  onSelectJob,
}) => {
  const [availableDrivers, setAvailableDrivers] = useState([{ id: 0, name: "none" }]);
  const [selectedJob, setSelectedJob] = useState();
  const intl = useIntl();

  const [expandedId, setExpandedId] = useState(-1);
  const handleSetExpandedId = (id: any) => {
    setExpandedId(id);
  };

  useEffect(() => {
    updateAvailableDrivers();
    //eslint-disable-next-line
  }, [selectedJob]);

  const updateAvailableDrivers = (jobId = selectedJob) => {
    if (jobId) {
      fetchAvailableDriversDeliveryJob(jobId).then((res: any) => {
        setAvailableDrivers(res.body);
      });
    }
  };
  const assignJobDriver = (jobId: any, driver: any) => {
    assignJobDeliveryJob(jobId, { query: { driverId: driver } })
      .then(() => {
        fetchDeliveryJobs();
        updateAvailableDrivers(jobId);
      })
      .catch(() => {
        fetchDeliveryJobs();
        notification.error({
          message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
          description: "Driver hasn't shared his location!",
        });
      });
  };
  const cancelJobDriver = (jobId: any, driver: any) => {
    cancelAssignJobDeliveryJob(jobId, { query: { driverId: driver } }).then(() => {
      fetchDeliveryJobs();
      updateAvailableDrivers(jobId);
    });
  };

  return (
    <>
      <MobileTable>
        {deliveryJobs?.map((job: any) => (
          <div className="table-item" key={job.id}>
            <MobileTableRows
              rows={[
                {
                  label: "Driver",
                  value: getDriverNames(
                    availableDrivers,
                    setSelectedJob,
                    assignJobDriver,
                    cancelJobDriver,
                    job.id,
                    job.driver,
                  ),
                },
                {
                  label: "ID",
                  value: (
                    <Row style={{ alignItems: "baseline" }}>
                      <p>{job.id}</p>
                      <div className="left" style={{ marginLeft: "5px" }}>
                        <div onClick={(e) => onSelectJob(e, job.id)}>
                          <Checkbox checked={isJobChecked(job.id)} />
                        </div>
                      </div>
                    </Row>
                  ),
                },
                { label: "Packages count", value: job.packagesCount || NO_INFO },
                {
                  label: "Pickup points",
                  value: job.pickupPointsCount || NO_INFO,
                },
                { label: "Delivery points", value: job.deliveryPointsCount || NO_INFO },
                { label: "Overall delivery time", value: job.overallDeliveryTime },
                { label: "Status", value: getMobileBadgeStatus(job.status) },
                { label: "Published", value: getPublishStatus(job) },
              ]}
            />
            <MobileTableExpand
              setIsPanelCollapsed={(bool: any) => onExpandJob(bool, job)}
              id={job.id}
              expanded={expandedId === job.id}
              handleSetExpandedId={handleSetExpandedId}
            >
              <BtnGroup>
                <Button onClick={() => downloadJobOrdersBarcodes(job.id)} className="mobile-btn mobile-btn--white">
                  Download All
                </Button>
              </BtnGroup>
              <OrdersCollapse
                useDragHandle
                disableAutoscroll
                orders={job.deliveryOrders ?? []}
                innerCollapsed={innerCollapsed}
                centerCoordinates={centerCoordinates}
                onOrderCollapse={onOrderCollapse}
                onOpenCashAmountModal={onOpenCashAmountModal}
                onForceNextOrder={onForceNextOrder}
                onDeleteOrder={onDeleteOrder}
                postponeOrderDeliveryJob={postponeOrderDeliveryJob}
                cancelOrderDeliveryJob={cancelOrderDeliveryJob}
                getOneDeliveryJob={getOneDeliveryJob}
                jobId={job.id}
                onSortEnd={onMoveRow}
              />
            </MobileTableExpand>
          </div>
        ))}
      </MobileTable>
      {/* <MobilePagination {...paginationConfig} onPageChange={onPageChange} /> */}
    </>
  );
};
