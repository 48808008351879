import { Button, Dropdown, Menu, notification } from "antd";
import { AppstoreAddOutlined, MoreOutlined, RotateLeftOutlined } from "@ant-design/icons";
import React from "react";

const DropdownMenu = ({
  openAddOrders,
  openRemoveOrders,
  orders,
  jobs,
  isAdminOrders,
  fetchJobsByStatus,
}: {
  openRemoveOrders: () => React.Dispatch<React.SetStateAction<boolean>>;
  openAddOrders: () => React.Dispatch<React.SetStateAction<boolean>>;
  orders: any[];
  jobs: [{ id: number }];
  isAdminOrders: boolean;
  fetchJobsByStatus: any;
}) => {
  const adminItems = [
    {
      key: "add",
      label: "Add orders to job",
      icon: <AppstoreAddOutlined />,
    },
    {
      key: "remove",
      label: "Remove orders from jobs",
      icon: <RotateLeftOutlined />,
    },
  ];
  const handleMenuItemsAction = (item: { key: string }) => {
    switch (item.key) {
      case "add":
        orders?.length ? openAddOrders() : notification.warn({ message: "Please select orders without jobs first !" });

        break;
      /*      TODO once API ready
 case "remove":
        openRemoveOrders();
        break;
 */
      default:
        notification.info({ message: "This function is not implemented yet !" });
    }
  };
  return <Menu items={isAdminOrders ? adminItems : []} onClick={handleMenuItemsAction}></Menu>;
};

const OrdersBulkActionsDropdown = ({
  openAddOrders,
  openRemoveOrders,
  orders,
  jobs,
  isAdminOrders,
  fetchJobsByStatus,
}: {
  openRemoveOrders: () => React.Dispatch<React.SetStateAction<boolean>>;
  openAddOrders: () => React.Dispatch<React.SetStateAction<boolean>>;
  fetchJobsByStatus: any;

  orders: any;
  jobs: [{ id: number }];
  isAdminOrders: boolean;
}) => {
  return (
    <Dropdown
      trigger={["hover"]}
      overlay={
        <DropdownMenu
          isAdminOrders={isAdminOrders}
          openAddOrders={openAddOrders}
          openRemoveOrders={openRemoveOrders}
          orders={orders}
          jobs={jobs}
          fetchJobsByStatus={fetchJobsByStatus}
        />
      }
      placement="bottom"
      arrow
    >
      <Button
        icon={<MoreOutlined />}
        onClick={(e) => {
          e.preventDefault();
        }}
      />
    </Dropdown>
  );
};

export default OrdersBulkActionsDropdown;
