import React from "react";
import { FormattedMessage } from "react-intl";

import PageHeader from "../../../components/PageHeader";
import RetailersGroupTable from "./components/GroupsTable";

export const DesktopView = ({
  intl,
  query,
  loading,
  retailers,
  pagination,
  groups,
  selectedGroupRetailers,
  selectedRetailer,
  expandedGroup,
  setQuery,
  createNewGroup,
  setExpandedGroup,
  loadSelectedGroupRetailers,
  deleteGroupById,
  selectRetailer,
  removeGroupMember,
}) => {

  return (
    <div className="pageWrapper">
      <PageHeader>
        <FormattedMessage id="retailersGroups" defaultMessage="Retailers Groups" />
      </PageHeader>
      <RetailersGroupTable
        groups={groups}
        retailers={retailers}
        loading={loading}
        selectedGroupRetailers={selectedGroupRetailers}
        selectedRetailer={selectedRetailer}
        pagination={pagination}
        expandedGroup={expandedGroup}
        createNewGroup={createNewGroup}
        setQuery={setQuery}
        setExpandedGroup={setExpandedGroup}
        loadSelectedGroupRetailers={loadSelectedGroupRetailers}
        deleteGroupById={deleteGroupById}
        selectRetailer={selectRetailer}
        removeGroupMember={removeGroupMember}
      />
    </div>
  );
};
