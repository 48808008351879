import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Popover } from "antd";
import { EnvironmentFilled } from "@ant-design/icons";

import "./customer.less";

const Customer = ({ orderInfo = null, color = "red", lat, lng }) => {
  const intl = useIntl();

  const customer = orderInfo && (
    <div className="customerInfo">
      <p>
        <FormattedMessage id="name" defaultMessage="Name" />: <span>{orderInfo.customerInfo.name}</span>
      </p>
      <p>
        <FormattedMessage id="phoneNumber" defaultMessage="Phone number" />:{" "}
        <span>{orderInfo.customer.phoneNumber}</span>
      </p>
    </div>
  );

  return (
    <Popover
      content={customer}
      title={`${intl.formatMessage({
        id: "map.customer",
        defaultMessage: "Customer",
      })}`}
      trigger="click"
    >
      <div className="customer-marker-wrap">
        <EnvironmentFilled className="customer-marker" style={{ color }} />
      </div>
    </Popover>
  );
};

export default Customer;
