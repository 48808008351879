import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Spin, Tabs, Badge } from "antd";

import CustomDivider from "../../../components/Divider";
import { LocationsTable } from "./components/LocationsTable";
import { DeliveryTable } from "./components/DeliveryTable";
import { getQueryParam, updatePageUrl } from "../../../helpers/general";
import { BulkActions } from "../components/actionPanel";
import { RetailerChangesTable } from "./components/RetailerChangesTable";
import { RetailerSMSTable } from "./components/RetailerSMSTable";

const { TabPane } = Tabs;

export const DesktopView = ({
  notifications,
  defaultUrl,
  loading,
  selection,
  locationTableData,
  locationTablePagination,
  deliveryTableData,
  deliveryTablePagination,
  retailerChangesTableData,
  retailerChangesTablePagination,
  fetchLocationsChangeAdminCustomers,
  fetchCountUncheckedAdminCustomers,
  checkRecordAdminCustomers,
  archiveRecordAdminCustomers,
  fetchDriversPoints,
  approveDriversPoints,
  rejectDriversPoints,
  handleToggle,
  deleteSelection,
  clearSelection,
  waitDriversPoints,
  fetchRetailerChangeNotifications,
  fetchRetailerChangeNumberNotifications,
  approveRetailerChangeNotifications,
  rejectRetailerChangeNotifications,
  getOneRetailerData,
  getSMSChangesNotifications,
  approveSMSChangesNotifications,
  rejectSMSChangesNotifications,
  retailerSMSTableData,
  retailerSMSTablePagination,
  getSMSChangesGroupNotifications,
  onRevokeSMSRights,
}) => {
  const { activeTab = "1" } = useParams();
  const [tab, setTab] = useState("1");
  const location = useLocation();
  const history = useHistory();

  const getQueryParams = useCallback(() => getQueryParam(location.search), [location.search]);

  const onChangePagination = ({ current, pageSize, isFailed = false }) => {
    const previousQueryParams = getQueryParams();
    const newQueryParams = { page: current, limit: pageSize, failed: tab === "3" ? true : false };

    updatePageUrl(newQueryParams, previousQueryParams, history, defaultUrl);
  };

  useEffect(() => {
    if (activeTab) {
      setTab(activeTab)
    }
  }, [activeTab]);

  const refreshQuery = (e) => {
    setTab(e);
    if (e === "1" || e === "3")
      fetchLocationsChangeAdminCustomers(
        {},
        {
          query: { failed: e === "3" },
        },
      );
    else if (e === "4") {
      fetchRetailerChangeNotifications();
    } else if (e === "5") {
      fetchRetailerChangeNotifications(); ///////////
    }
    history.push(`/${defaultUrl}`);
    clearSelection();
  };

  return (
    <>
      {loading && <Spin size="large" className="spinnerStyle" />}
      <div className="pageWrapper">
        <h2 className="pageTitle">Notifications</h2>
        <CustomDivider />
        <Tabs defaultActiveKey={activeTab} activeKey={tab} onChange={refreshQuery}>
          <TabPane
            tab={
              <>
                Locations change
                <Badge offset={[4, -1]} count={notifications?.customerChangedLocation} />
              </>
            }
            key="1"
          >
            <BulkActions selection={selection} onDelete={deleteSelection("locations")} />

            <LocationsTable
              tab={tab}
              location={location}
              history={history}
              selection={selection}
              data={locationTableData}
              pagination={locationTablePagination}
              fetchLocationsChangeAdminCustomers={fetchLocationsChangeAdminCustomers}
              fetchCountUncheckedAdminCustomers={fetchCountUncheckedAdminCustomers}
              checkRecordAdminCustomers={checkRecordAdminCustomers}
              archiveRecordAdminCustomers={archiveRecordAdminCustomers}
              getQueryParams={getQueryParams}
              onChangePagination={onChangePagination}
              handleToggle={handleToggle}
            />
          </TabPane>
          <TabPane
            tab={
              <>
                Move to next delivery
                <Badge offset={[4, -1]} count={notifications?.nextPointRequests} />
              </>
            }
            key="2"
          >
            {/* <BulkActions selection={selection} onDelete={deleteSelection("delivery")} /> */}
            <DeliveryTable
              location={location}
              history={history}
              selection={selection}
              data={deliveryTableData}
              pagination={deliveryTablePagination}
              fetchDriversPoints={fetchDriversPoints}
              approveDriversPoints={approveDriversPoints}
              waitDriversPoints={waitDriversPoints}
              rejectDriversPoints={rejectDriversPoints}
              getQueryParams={getQueryParams}
              onChangePagination={onChangePagination}
              handleToggle={handleToggle}
            />
          </TabPane>
          <TabPane tab="Bad locations" key="3">
            <BulkActions selection={selection} onDelete={deleteSelection("bad_locations")} />
            <LocationsTable
              tab={tab}
              isBadLocation
              location={location}
              history={history}
              selection={selection}
              data={locationTableData}
              pagination={locationTablePagination}
              fetchLocationsChangeAdminCustomers={fetchLocationsChangeAdminCustomers}
              fetchCountUncheckedAdminCustomers={fetchCountUncheckedAdminCustomers}
              checkRecordAdminCustomers={checkRecordAdminCustomers}
              archiveRecordAdminCustomers={archiveRecordAdminCustomers}
              getQueryParams={getQueryParams}
              onChangePagination={onChangePagination}
              handleToggle={handleToggle}
            />
          </TabPane>
          <TabPane
            tab={
              <>
                Retailer changes
                <Badge offset={[4, -1]} count={notifications?.retailerUpdates} />
              </>
            }
            key="4"
          >
            {/* <BulkActions selection={selection} onDelete={deleteSelection("retailer_changes")} /> */}
            <RetailerChangesTable
              tab={tab}
              location={location}
              history={history}
              selection={selection}
              data={retailerChangesTableData}
              pagination={retailerChangesTablePagination}
              fetchRetailerChangeNotifications={fetchRetailerChangeNotifications}
              fetchRetailerChangeNumberNotifications={fetchRetailerChangeNumberNotifications}
              approveRetailerChangeNotifications={approveRetailerChangeNotifications}
              rejectRetailerChangeNotifications={rejectRetailerChangeNotifications}
              getQueryParams={getQueryParams}
              onChangePagination={onChangePagination}
              handleToggle={handleToggle}
              getOneRetailerData={getOneRetailerData}
            />
          </TabPane>
          <TabPane
            tab={
              <>
                SMS templates
                <Badge offset={[4, -1]} count={notifications?.retailerTemplates} />
              </>
            }
            key="5"
          >
            {/* <BulkActions selection={selection} onDelete={deleteSelection("retailer_changes")} /> */}
            <RetailerSMSTable
              tab={tab}
              location={location}
              history={history}
              selection={selection}
              data={retailerSMSTableData}
              pagination={retailerSMSTablePagination}
              getSMSChangesNotifications={getSMSChangesNotifications}
              approveSMSChangesNotifications={approveSMSChangesNotifications}
              rejectSMSChangesNotifications={rejectSMSChangesNotifications}
              getQueryParams={getQueryParams}
              onChangePagination={onChangePagination}
              handleToggle={handleToggle}
              getOneRetailerData={getOneRetailerData}
              getSMSChangesGroupNotifications={getSMSChangesGroupNotifications}
              onRevokeSMSRights={onRevokeSMSRights}
            />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};
