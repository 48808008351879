import React from "react";

import "./style.less";

export default function LogsButton({ areLogsOpen, toggleLogs, children }) {
  const handleClick = () => {
    toggleLogs((areLogsOpen) => !areLogsOpen);
  };

  return (
    <button className="LogsButton" onClick={handleClick}>
      {children}
      {areLogsOpen ? "Close Logs" : "Open Logs"}
    </button>
  );
}
