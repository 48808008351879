import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { IPath, useBreadcrumbContext } from "./BreadCrumpProvider";

const useBreadcrumbManager = () => {
  const { addAllPaths } = useBreadcrumbContext();
  const location = useLocation();

  useEffect(() => {
    // Get the current location path and split it by "/"
    const path = location.pathname;
    const parts = path.split("/");
    // const baseUrl = window.location.origin;

    // Create the breadcrumb items
    const items: IPath[] = parts
      .filter((part) => !Number(part))
      .map((part, index) => ({
        name: part,
        path: `${parts.slice(0, index + 1).join("/")}`,
      }));

    // Update the breadcrumbs context
    addAllPaths(items);
  }, [location]);
};

export default useBreadcrumbManager;
