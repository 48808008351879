import React from "react";
import { Image } from "antd";
import AttachmentScroll from "../../../../components/AttachmentScroll";

const DeliveryReportsDetails = ({ data }) => {
  const { order, driver, createdAt } = data;
  const d = new Date(createdAt);
  const datestring = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;

  return (
    <div>
      <ul>
        {data.type !== "pickup_report" && (
          <li>
            <strong>Order ID: </strong>
            {order?.id ? order.id : "No info"}
          </li>
        )}
        <li>
          <strong>Tracking ID:</strong>
          {order?.trackingId ? order.trackingId : "No info"}
        </li>
        <li>
          <strong>Driver email: </strong>
          {driver?.email ? driver.email : "No info"}
        </li>
        <li>
          <strong>Driver name: </strong>
          {driver?.profile?.firstName || driver?.profile?.lastName
            ? `${driver?.profile?.firstName} ${driver?.profile?.lastName}`
            : "No info"}
        </li>
        <li>
          <strong>Report created at: </strong>
          {datestring || "No info"}
        </li>
        <li>
          <strong>Order Status: </strong>
          {order?.status ? order.status : "No info"}
        </li>
        {data.type !== "delivery_report" ? (
          <>
            {/* <li>
              <strong>Failure: </strong>
              {data?.failureType || "No info"}
            </li> */}
            <li>
              <strong>Reason: </strong>
              {data?.reason || "No info"}
            </li>
            <li>
              <strong>Attachments: </strong>
              {data?.attachments?.length > 0 ? (
                <AttachmentScroll>
                  {data?.attachments.map((e) => (
                    <>
                      <Image style={{ maxHeight: "50vh" }} key={e.id} src={e.attachment.url} />
                    </>
                  ))}
                </AttachmentScroll>
              ) : (
                "No data"
              )}
            </li>
          </>
        ) : null}
      </ul>
    </div>
  );
};

export default DeliveryReportsDetails;
