import antdEn from "antd/es/locale//en_US";

import enMessages from "../locales/en_US.json";

export const retailerEnLang = {
  messages: {
    ...enMessages,
  },
  antd: antdEn,
  locale: "en-US",
};
