import React from "react";

const MobileCopy = (props) => (
  <svg {...props} width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="28" height="28" rx="7" fill="#161616" fill-opacity="0.4" />
    <path
      d="M20.8 11.6375H13.7125C12.8426 11.6375 12.1375 12.3427 12.1375 13.2125V20.3C12.1375 21.1699 12.8426 21.875 13.7125 21.875H20.8C21.6698 21.875 22.375 21.1699 22.375 20.3V13.2125C22.375 12.3427 21.6698 11.6375 20.8 11.6375Z"
      stroke="#2B2B2B"
      stroke-width="1.575"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.9875 16.3625H8.2C7.78228 16.3625 7.38168 16.1966 7.08631 15.9012C6.79094 15.6058 6.625 15.2052 6.625 14.7875V7.7C6.625 7.28228 6.79094 6.88168 7.08631 6.58631C7.38168 6.29094 7.78228 6.125 8.2 6.125H15.2875C15.7052 6.125 16.1058 6.29094 16.4012 6.58631C16.6966 6.88168 16.8625 7.28228 16.8625 7.7V8.4875"
      stroke="#2B2B2B"
      stroke-width="1.575"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default MobileCopy;
