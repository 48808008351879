import { TablePaginationConfig } from "antd";
import { useCallback, useEffect, useState } from "react";
import { IntlShape, useIntl } from "react-intl";
import { getQueryParam, updatePageUrl } from "src/helpers/general";
import { omit } from "lodash";
import { ActivityLogsDataModel, fromJSON } from "../entities/activity-logs.interface";

interface ReturnType {
  Intl: IntlShape;
  pagination: TablePaginationConfig;
  changeQueryParams: (pagination: TablePaginationConfig, filters: any) => void;
  currentQueryParams: any;
  openOrderHistory: (id: number) => (event: EventTarget) => void;
  orderHistoryData: ActivityLogsDataModel[];
  historyDrawer: boolean;
  setHistoryDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

interface InputType {
  getOrderHistoryActivityLogs: any;
  fetchActivityLogs: any;
  paginationData: any;
  history: any;
  location: any;
}

function useHooks(props: InputType): ReturnType {
  const { fetchActivityLogs, paginationData, history, location, getOrderHistoryActivityLogs } = props;

  // state
  const [historyDrawer, setHistoryDrawer] = useState(false);
  const [orderHistoryData, setOrderHistoryData] = useState<ActivityLogsDataModel[]>([]);

  /* change pagination query */

  // to get current parameters
  const getActivityQueryParams = useCallback(() => getQueryParam(location.search), [location.search]);
  const currentQueryParams = getActivityQueryParams();

  // to update the parameters
  const updateActivityPageUrl = (newQueryParams: any, queriesToRemove: any) => {
    const previousQueryParams = omit(getActivityQueryParams(), Object.keys(queriesToRemove));
    updatePageUrl(newQueryParams, previousQueryParams, history, "retailer_cabinet/activity_logs");
  };

  const changeQueryParams = (pagination: TablePaginationConfig, filters: any) => {
    const search = omit(pagination, ["pageSizeOptions", "position", "showSizeChanger", "total", "pageSize", "current"]);
    updateActivityPageUrl(
      {
        ...search,
        page: pagination.current,
        limit: pagination.pageSize,
      },
      {
        ...(!filters?.locationShared && { locationShared: true }),
      },
    );
  };

  // pagination configs
  const pagination: TablePaginationConfig = {
    pageSize: currentQueryParams ? currentQueryParams.limit : 10,
    total: paginationData ? paginationData.totalItems : 50,
    current: currentQueryParams?.page ? +currentQueryParams.page : 1,
    pageSizeOptions: [10, 20, 30, 50],
    showSizeChanger: true,
    position: ["bottomRight"],
  };

  // call backs
  const fetchActivityLogsWithQuery = useCallback(() => {
    const queryParams = getActivityQueryParams();
    const queryWithPage = queryParams.page ? queryParams : { ...queryParams, page: 1, limit: 10 };

    fetchActivityLogs(
      {},
      {
        query: queryWithPage,
      },
    ).catch((error: Error) => console.log(error));
  }, [fetchActivityLogs, getActivityQueryParams]);

  //
  const openOrderHistory = (id: number) => (event: any) => {
    getOrderHistoryActivityLogs({ orderId: id }).then((results: any) => {
      // store the data
      const data: ActivityLogsDataModel[] = [];
      results?.body.forEach((el: any) => {
        data.push(fromJSON(el));
      });

      // open the drawer
      setOrderHistoryData(data);
      setHistoryDrawer(true);
    });
  };

  useEffect(() => {
    fetchActivityLogsWithQuery();
  }, [fetchActivityLogsWithQuery]);

  const Intl = useIntl();
  return {
    Intl,
    pagination,
    changeQueryParams,
    currentQueryParams,
    openOrderHistory,
    orderHistoryData,
    historyDrawer,
    setHistoryDrawer,
  };
}

export default useHooks;
