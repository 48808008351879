import React, { useEffect, useState } from "react";
import { Select } from "antd";

import { useHistory } from "react-router-dom";
import { formatCountry } from "src/helpers/formatCountry";
import ReactCountryFlag from "react-country-flag";
import { API_URL } from "src/constants/config";
import LogsCards from "./LogsCards";
import StatsCard from "./StatsCard";
import CompanyLogs from "./CompanyLogs"

import "./index.css";

const { Option } = Select;
const SuperDashboard = () => {
  const [tenants, setTenants] = useState([]);

  const [selectedTenant, setSelectedTenant] = useState("Jordan");
  const [companies, setCompanies] = useState(0);
  const [retailers, setRetailers] = useState(0);
  const [drivers, setDrivers] = useState(0);

  const [orders, setOrders] = useState([]);
  const [canceledOrders, setCanceledOrders] = useState(0);
  const [deletedOrders, setDeletedOrders] = useState(0);
  useEffect(() => {
    const authToken = localStorage.getItem("authTokenSuper");

    fetch(`${API_URL}/superadmin/allcountries`, {
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setTenants(data);
      });

    const fetchCompanies = async () => {
      const tenant = tenants?.find((element) => element.name === selectedTenant);
      const tenantId = tenant ? tenant?.id : "44aa004d-812d-4055-b24a-4a3865918763";
      try {
        const authToken = localStorage.getItem("authTokenSuper");

        const response = await fetch(`${API_URL}/superadmin/allcompanies_count?tenantName=${tenantId}`, {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        });
        const data = await response.json();
        setCompanies(data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    const fetchRetailers = async () => {
      const authToken = localStorage.getItem("authTokenSuper");

      const tenant = tenants?.find((element) => element.name === selectedTenant);
      const tenantId = tenant ? tenant?.id : "44aa004d-812d-4055-b24a-4a3865918763";

      const driversUrl = `${API_URL}/superadmin/allretailers_count?tenantName=${tenantId}`;

      try {
        const response = await fetch(driversUrl, {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        });
        const data = await response.json();
        setRetailers(data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    const fetchDrivers = async () => {
      const authToken = localStorage.getItem("authTokenSuper");

      const tenant = tenants?.find((element) => element.name === selectedTenant);
      const tenantId = tenant ? tenant?.id : "44aa004d-812d-4055-b24a-4a3865918763";
      const driversUrl = `${API_URL}/superadmin/alldrivers_count?tenantName=${tenantId}`;

      try {
        const response = await fetch(driversUrl, {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        });
        const data = await response.json();
        setDrivers(data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    const fetchOrders = async () => {
      const authToken = localStorage.getItem("authTokenSuper");

      const tenant = tenants?.find((element) => element.name === selectedTenant);
      const tenantId = tenant ? tenant?.id : "44aa004d-812d-4055-b24a-4a3865918763";
      const ordersUrl = `${API_URL}/superadmin/allorders_count?tenantName=${tenantId}`;

      try {
        const response = await fetch(ordersUrl, {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        });
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    const fetchCanceledOrdersCount = async () => {
      const authToken = localStorage.getItem("authTokenSuper");

      const tenant = tenants?.find((element) => element.name === selectedTenant);
      const tenantId = tenant ? tenant?.id : "44aa004d-812d-4055-b24a-4a3865918763";

      const ordersUrl = `${API_URL}/superadmin/canceledorders_count/${tenantId}`;

      try {
        const response = await fetch(ordersUrl, {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        });
        const data = await response.json();
        setCanceledOrders(data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    const fetchDeletedOrdersCount = async () => {
      const authToken = localStorage.getItem("authTokenSuper");
      const tenant = tenants?.find((element) => element.name === selectedTenant);
      const tenantId = tenant ? tenant?.id : "44aa004d-812d-4055-b24a-4a3865918763";

      const ordersUrl = `${API_URL}/superadmin/deletedorders_count/${tenantId}`;

      try {
        const response = await fetch(ordersUrl, {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        });
        const data = await response.json();
        setDeletedOrders(data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
    fetchDrivers();
    fetchRetailers();
    fetchCompanies();
    fetchCanceledOrdersCount();
    fetchDeletedOrdersCount();
  }, [companies, selectedTenant]);

  const history = useHistory();
  return (
    <div className="mainDashBoard">
      <Select
        value={selectedTenant}
        defaultValue="44aa004d-812d-4055-b24a-4a3865918763"
        defaultActiveFirstOption="44aa004d-812d-4055-b24a-4a3865918763"
        onChange={(value) => setSelectedTenant(value)}
        style={{ width: "20%" }}
      >
        {tenants?.map((item) => (
          <Option value={item.name} key={item.id}>
            <>
              <ReactCountryFlag
                svg
                countryCode={formatCountry(item.name, true)}
                style={{
                  width: "2em",
                  height: "2em",
                  marginRight: 5,
                }}
              />
              <span>{item.name}</span>
            </>
          </Option>
        ))}
      </Select>

      <div className="cardSuperContainer">
        <StatsCard title="Companies" stats={companies} path="/super_admin/companies" />

        <StatsCard title="Retailers" stats={retailers} path="/super_admin/retailers" />

        <StatsCard title="Drivers" stats={drivers} path="/super_admin/drivers" />

        <StatsCard title="Orders" stats={orders} path="/super_admin/orders" />

        <StatsCard title="Countries" stats={tenants?.length} path="/super_admin/countries" />

        <StatsCard title="Deleted Orders" stats={deletedOrders} path="/super_admin/countries" />

        <StatsCard title="Canceled Orders" stats={canceledOrders} path="/super_admin/countries" />
      </div>
      <div style={{width:"100%", display:"flex", justifyContent:"flex-start", gap:"4%"}}>
      <LogsCards />
      <CompanyLogs />
      </div>
    </div>
  );
};

export default SuperDashboard;
