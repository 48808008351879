import React, { useState } from "react";
import { Modal, Button, Row, Timeline, Select, Col } from "antd";
import { FormattedMessage } from "react-intl";
import "./ordersModal.less";
const { Option } = Select;
const OrdersActionConfirmModal = ({
  isAdminOrders,
  onActionDone,
  onCancel,
  visible,
  orders,
  jobs,
  removeOrdersFromJob,
  addOrdersToJob,
  afterClose,
}: {
  isAdminOrders: boolean;
  onActionDone: any;
  onCancel: () => React.Dispatch<React.SetStateAction<boolean>>;
  afterClose: () => React.Dispatch<React.SetStateAction<[]>>;

  visible: { id: string; open: boolean };
  orders: number[];
  jobs: { jobId: number }[];

  removeOrdersFromJob: ({ jobId, ordersIds }: { jobId: number; ordersIds: number[] }) => Promise<void>;
  addOrdersToJob: ({ jobId, ordersIds }: { jobId: any; ordersIds: any }) => Promise<void>;
}) => {
  const [selectedJob, onSelectJob] = useState<number>();
  const renderMessage = (type: string) => {
    if (type === "remove") {
      return <p className="confirm_modal--main_question">Do you really want to remove these orders from jobs ?</p>;
    } else {
      return (
        <>
          <p className="confirm_modal--main_question">Do you really want to assign these orders to job ?</p>
          <Row>
            <label className="confirm_modal--label">Please select a job to assign these jobs</label>

            <Select
              className="confirm_modal--jobselect"
              defaultValue={selectedJob}
              onSelect={(value: number) => onSelectJob(value)}
              placeholder="Select Job Id"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (option!.children as unknown as number).toString().toLowerCase().includes(input.toLowerCase());
              }}
            >
              {jobs?.map(({ jobId }) => (
                <Option value={jobId} key={jobId}>
                  Job n° {jobId}
                </Option>
              ))}
            </Select>
          </Row>
        </>
      );
    }
  };
  return (
    <Modal
      width="60vw"
      key={"upload-orders-modal"}
      afterClose={afterClose}
      visible={visible.open}
      onCancel={onCancel}
      closable={false}
      className="upload-orders-modal"
      title={<FormattedMessage id="action.orders.confirm" defaultMessage="Action Confirmation" />}
      footer={[
        <Button onClick={onCancel} key="cancel">
          <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
        </Button>,

        <Button
          key="remove"
          onClick={() => {
            removeOrdersFromJob({ jobId: 1, ordersIds: orders });
            onActionDone();
          }}
          disabled={!orders?.length}
          hidden={visible.id === "remove" ? false : true}
        >
          <FormattedMessage id="tabPane.actions.confirm" defaultMessage="Confirm" />
        </Button>,
        <Button
          key="add"
          onClick={() => {
            addOrdersToJob({ jobId: selectedJob, ordersIds: orders });
            onActionDone();
          }}
          disabled={!orders?.length || !selectedJob}
          hidden={visible.id === "add" ? false : true}
        >
          <FormattedMessage id="tabPane.actions.confirm" defaultMessage="Confirm" />
        </Button>,
      ]}
    >
      {renderMessage(visible.id)}
      <br />
      <h3>Selected Orders</h3>
      <br />
      {orders?.length ? (
        <Timeline>
          {orders?.map((el) => (
            <Timeline.Item> Order n° {el}</Timeline.Item>
          ))}
        </Timeline>
      ) : (
        <Col>
          <p style={{ fontWeight: "500", color: "red" }}>Selected Orders can't be assigned to jobs !</p>
          <p style={{ fontWeight: "500", color: "gray", fontSize: "small" }}>
            Please filter by <b>'Can be assigned'</b> and select other orders !
          </p>
        </Col>
      )}
    </Modal>
  );
};

export default OrdersActionConfirmModal;
