import FacebookIcon from "../../../../assets/newLanding/footer/FacebookIcon";
import TwitterIcon from "../../../../assets/newLanding/footer/TwitterIcon";
import InstagramIcon from "../../../../assets/newLanding/footer/InstagramIcon";
import LinkedInIcon from "../../../../assets/newLanding/footer/LinkedInIcon";

const socialLinks = [
  { id: "facebook", link: "", icon: FacebookIcon, isActive: true },
  { id: "twitter", link: "", icon: TwitterIcon, isActive: true },
  { id: "instagram", link: "", icon: InstagramIcon, isActive: true },
  { id: "linkedin", link: "", icon: LinkedInIcon, isActive: true },
];

export default socialLinks;
