import React, { useLayoutEffect, useState } from "react";
import { Upload } from "antd";
import { FormattedMessage } from "react-intl";
import { CloseCircleOutlined } from "@ant-design/icons";

import uploadIcon from "../../../assets/uploadIcon.png";
import openCameraIcon from "../../../assets/openCamera.png";
import "./uploadDocumentDragger.less";

const useWindowSize = () => {
  const [windowWidth, setWindowWidth] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return windowWidth;
};

const { Dragger } = Upload;

const OpenCameraContent = () => (
  <div className="uploadDragger">
    <input type="file" name="image" accept="image/*" capture="environment" style={{ display: "none" }} />
    <img src={openCameraIcon} alt="Upload icon" />
    <p className="uploadText">
      <FormattedMessage id="openCamera" defaultMessage="Open Camera" />
      <span>
        <FormattedMessage id="toUpload" defaultMessage="to upload" />
      </span>
    </p>
  </div>
);

const UploadDocumentDragger = ({ draggerProps, uploadedImage, onDeleteVideo, retailerInfo }) => {
  const getUploadedImage = (uploadedImage) => {
    if (uploadedImage.type.includes("pdf")) {
      return (
        <>
          <iframe
            frameBorder="0"
            title="downloaded pdf"
            height="100%"
            width="auto"
            src={URL.createObjectURL(uploadedImage)}
          />
          {/* <PdfFile/> */}
        </>
      );
    }
    if (uploadedImage.type.includes("video")) {
      return (
        <div style={{ display: "flex" }}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video width="350" height="auto" controls className="videoStyle">
            <source src={URL.createObjectURL(uploadedImage)} type="video/mp4" />
          </video>
          <CloseCircleOutlined className="deleteVideoButton" onClick={onDeleteVideo} />
        </div>
      );
    }
    return (
      <img width="100%" src={URL.createObjectURL(uploadedImage)} className="uploadedDocument" alt="Uploaded document" />
    );
  };

  const windowWidth = useWindowSize();

  const oldImageUrl = retailerInfo?.commercialRegistration?.url;

  const displayImage = () => {
    if (uploadedImage) {
      return getUploadedImage(uploadedImage);
    }
    if (oldImageUrl.includes("pdf")) {
      return (
        <div className="pdfFileWrapper">
          <iframe frameBorder="0" title="downloaded pdf" height="100%" width="auto" src={oldImageUrl} />
        </div>
      );
    }
    return <img className="oldUploadedImg" src={oldImageUrl} alt="img" />;
  };

  return (
    <Dragger
      {...draggerProps}
      className="draggerStyle"
      disabled={uploadedImage && uploadedImage.type.includes("video")}
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {uploadedImage || oldImageUrl ? (
        displayImage()
      ) : windowWidth > 768 ? (
        <div className="uploadDragger">
          <img src={uploadIcon} alt="Upload icon" />
          <div className="uploadText">
            <p>
              <FormattedMessage id="drop" defaultMessage="Drop your files" />
            </p>
            <span>
              <FormattedMessage id="toUpload" defaultMessage="to upload" />
            </span>
            <span>
              <FormattedMessage id="or" defaultMessage="or" />
            </span>
            <p className="uploadHint">
              <FormattedMessage id="chooseFile" defaultMessage="Choose file" />
            </p>
          </div>
        </div>
      ) : (
        <OpenCameraContent />
      )}
    </Dragger>
  );
};

export default UploadDocumentDragger;
