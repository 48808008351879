import React from "react";

import { MobileView } from "./MobileView";
import { DesktopView } from "./DesktopView";
import { useHooks } from "./hooks/useHooks";

import "./style.less";

const SystemReportPage = () => {
  const {
    isMobile,
    driversList,
    retailersList,
    monthReportQuery,
    comapredMonthData,
    targetMonthData,
    chartReportQuery,
    chartData,
    chartDateRange,
    onSelect,
    onDatePick,
    setDateRange,
    setComparedMonth,
    setTargetMonth,
    setMonthReportQuery,
    setChartReportQuery,
    onChartValueSelect,
  } = useHooks();

  return (
    <>
      {isMobile ? (
        <MobileView
          isMobile={isMobile}
          driversList={driversList}
          retailersList={retailersList}
          monthReportQuery={monthReportQuery}
          comapredMonthData={comapredMonthData}
          targetMonthData={targetMonthData}
          chartReportQuery={chartReportQuery}
          chartData={chartData}
          chartDateRange={chartDateRange}
          onSelect={onSelect}
          onDatePick={onDatePick}
          setDateRange={setDateRange}
          setComparedMonth={setComparedMonth}
          setTargetMonth={setTargetMonth}
          setMonthReportQuery={setMonthReportQuery}
          setChartReportQuery={setChartReportQuery}
          onChartValueSelect={onChartValueSelect}
        />
      ) : (
        <DesktopView
          driversList={driversList}
          retailersList={retailersList}
          monthReportQuery={monthReportQuery}
          comapredMonthData={comapredMonthData}
          targetMonthData={targetMonthData}
          chartReportQuery={chartReportQuery}
          chartData={chartData}
          chartDateRange={chartDateRange}
          onSelect={onSelect}
          onDatePick={onDatePick}
          setDateRange={setDateRange}
          setComparedMonth={setComparedMonth}
          setTargetMonth={setTargetMonth}
          setMonthReportQuery={setMonthReportQuery}
          setChartReportQuery={setChartReportQuery}
          onChartValueSelect={onChartValueSelect}
        />
      )}
    </>
  );
};

export default SystemReportPage;
