import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form, Input, Typography, notification } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import SuccessAuth from "../SuccessAuth";
import GetStartedButton from "../GetStartedButton";
import { actions as retailerActions } from "../../../../redux/resources/retailer";
import { getUserTenant } from "../../../../helpers/authStorage";
import { ReactComponent as PasswordIcon } from "../../../../assets/newLanding/auth/passwordIcon.svg";
import styles from "./recoveryPasswordForm.module.less";
import MobileBottomToolbar from "../MobileBottomToolbar";

const RecoveryPasswordForm = ({ recoverPasswordRetailer, confirmationToken, isMobile }) => {
  const [sendMessage, setSendMessage] = useState(false);

  const [form] = Form.useForm();
  const intl = useIntl();

  useEffect(() => {
    form.isFieldsTouched() && form.validateFields();
    // eslint-disable-next-line
  }, [intl.locale]);

  const handleSubmit = (values) => {
    recoverPasswordRetailer({ ...values, confirmationToken, name: getUserTenant() })
      .then((response) => {
        notification.success({ message: "Success", description: response.body.message });

        setSendMessage(true);
      })
      .catch((error) => notification.error({ message: "Error", description: error.body.message.toString() }));
  };

  return !sendMessage || isMobile ? (
    <>
      {isMobile ? (
        <Typography.Title level={2} className={styles.forgotPasswordMainTitleMobile}>
          Create a new{" "}
          <span level={2} className={styles.forgotPasswordMainTitleYellowMobile}>
            Password
          </span>
        </Typography.Title>
      ) : (
        <Typography.Title level={2} className={styles.forgotPasswordTitle}>
          <FormattedMessage id="page.forgot.createPassword" defaultMessage="Create a new password:" />
        </Typography.Title>
      )}

      <Form
        form={form}
        name="forgotPassword"
        onFinish={handleSubmit}
        className={styles.forgotPasswordForm}
        style={
          isMobile ? { flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" } : {}
        }
      >
        {isMobile ? (
          <div>
            {" "}
            <Form.Item
              name="password"
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: "error.invalidPasword",
                    defaultMessage: "Please input your password!",
                  })}`,
                },
                {
                  pattern: /^.{6,}$/,
                  message: "Password should be at least 6 characters long",
                },
              ]}
            >
              <Input.Password
                prefix={<PasswordIcon />}
                placeholder={`${intl.formatMessage({ id: "input.newPassword", defaultMessage: "New Password" })}`}
                className={styles.formInputMobile}
              />
            </Form.Item>
            <Form.Item
              name="passwordConfirmation"
              dependencies={["password"]}
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: "error.confirmPassword",
                    defaultMessage: "Please confirm your password!",
                  })}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    return !value || getFieldValue("password") === value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            `${intl.formatMessage({
                              id: "error.notMatchPasswords",
                              defaultMessage: "The two passwords that you entered do not match!",
                            })}`,
                          ),
                        );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<PasswordIcon />}
                placeholder={`${intl.formatMessage({
                  id: "input.confirmNewPassword",
                  defaultMessage: "Confirm New Password",
                })}`}
                className={styles.formInputMobile}
              />
            </Form.Item>
          </div>
        ) : (
          <>
            {" "}
            <Form.Item
              name="password"
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: "error.invalidPasword",
                    defaultMessage: "Please input your password!",
                  })}`,
                },
                {
                  pattern: /^.{6,}$/,
                  message: "Password should be at least 6 characters long",
                },
              ]}
            >
              <Input.Password
                prefix={<PasswordIcon />}
                placeholder={`${intl.formatMessage({ id: "input.newPassword", defaultMessage: "New Password" })}`}
                className={styles.formInput}
              />
            </Form.Item>
            <Form.Item
              name="passwordConfirmation"
              dependencies={["password"]}
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: "error.confirmPassword",
                    defaultMessage: "Please confirm your password!",
                  })}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    return !value || getFieldValue("password") === value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            `${intl.formatMessage({
                              id: "error.notMatchPasswords",
                              defaultMessage: "The two passwords that you entered do not match!",
                            })}`,
                          ),
                        );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<PasswordIcon />}
                placeholder={`${intl.formatMessage({
                  id: "input.confirmNewPassword",
                  defaultMessage: "Confirm New Password",
                })}`}
                className={styles.formInput}
              />
            </Form.Item>
          </>
        )}

        <Form.Item noStyle>
          <GetStartedButton htmlType="submit" className={styles.submitButton} style={isMobile ? { width: "100%" } : {}}>
            <FormattedMessage id="tabPane.actions.confirm" defaultMessage="Confirm" />
          </GetStartedButton>
        </Form.Item>
      </Form>
      {isMobile && (
        <MobileBottomToolbar
          isVisible={sendMessage}
          onToggleModal={() => {
            window.location.href = "/";
          }}
        >
          <Typography.Title level={3} className={styles.modalTitle}>
            Success! <br />
            <Typography.Text className={styles.modalSubTitle}>Password was changed</Typography.Text>
          </Typography.Title>

          <Link to="/">
            <GetStartedButton
              htmlType="submit"
              className={styles.signUpButton}
              style={isMobile ? { marginTop: 16, width: "100%" } : {}}
            >
              <ArrowLeftOutlined style={{ paddingRight: 15 }} /> Back to Log In
            </GetStartedButton>
          </Link>
        </MobileBottomToolbar>
      )}
    </>
  ) : (
    <SuccessAuth
      title="page.forgot.passwordWasChange"
      subtitle="page.forgot.success"
      goToBtnLink="/retailer_login"
      goToBtnLabel="page.forgot.backToLogIn"
    />
  );
};

export default connect(null, (dispatch) => ({
  ...bindActionCreators(retailerActions, dispatch),
}))(RecoveryPasswordForm);
