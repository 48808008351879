import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";
import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "retailerJobsMap",
  actions: {
    cfr: {
      method: "POST",
      url: `${API_URL}/retailers/jobs/cfr`,
      transformResponse: (res) => res,
      reduce: (state, action) => {
        localStorage.setItem("cfrNotifSeen", false);
        return {
          ...state,
          cfrResults: {
            ...action.body,
          },
          CFRInstance: {
            isRunning: false,
          },
        };
      },
    },
    clearCFRState: {
      isPure: true,
      reduce: (state, action) => {
        return {
          ...state,
          CFRInstance: {
            isRunning: false,
            message: "",
          },
          cfrResults: undefined,
        };
      },
    },
    cfrState: {
      method: "GET",
      url: `${API_URL}/retailers/jobs/cfr`,
      reduce: (state, action) => {
        return {
          ...state,
          CFRInstance: {
            ...state.CFRInstance,
            ...action.body,
          },
        };
      },
    },

    fetch: {
      // method: 'GET',
      url: `${API_URL}/retailers/jobs?attachOrders=true`,
      transformResponse: ({ body }) => ({
        body,
      }),
    },
    filter: {
      isPure: true,
      reduce: (state, action) => {
        let newDisplayedData = null;
        if (action.context?.length) {
          newDisplayedData = state.items.find((item) => {
            return item.id === action.context[0];
          });
        }
        return {
          ...state,
          displayedItems: [newDisplayedData],
        };
      },
    },
    filterMany: {
      isPure: true,
      reduce: (state, action) => {
        let newDisplayedData = [];

        if (action.context?.length) {
          let ids = action.context.map(({ id }) => id);
          newDisplayedData = state.items.filter((item) => {
            return ids.includes(item.id);
          });
        }
        return {
          ...state,
          displayedItems: [...newDisplayedData],
        };
      },
    },
    onSelect: {
      isPure: true,
      reduce: (state, action) => ({
        ...state,
        displayedItemsIds: action.context,
      }),
    },
    deleteFromCombine: {
      isPure: true,
      reduce: (state, action) => {
        const newDisplayeddisplayedItemsIds = state.displayedItemsIds.filter((item) => {
          return item !== action.context;
        });
        return {
          ...state,
          displayedItemsIds: [...newDisplayeddisplayedItemsIds],
        };
      },
    },
    clearCombine: {
      isPure: true,
      reduce: (state, action) => {
        return {
          ...state,
          displayedItems: [],
          displayedItemsIds: [],
        };
      },
    },
    deleteFromFilter: {
      isPure: true,
      reduce: (state, action) => {
        const newDisplayedData = state.displayedItems.filter((item) => {
          return item.id !== action.context;
        });
        return {
          ...state,
          displayedItems: [...newDisplayedData],
        };
      },
    },
    deleteAllJobs: {
      method: "DELETE",
      url: `${API_URL}/retailers/jobs/all`,
      transformResponse: (res) => res,
    },

    getAvailableOrders: {
      method: "GET",
      url: `${API_URL}/retailers/jobs/available-orders`,
      transformResponse: (res) => res,
      reduce: (state, action) => {
        if (action.status === "resolved" && action.code === 200) {
          return {
            ...state,
            orders: action.body,
          };
        }
        return {
          ...state,
          orders: [],
        };
      },
    },

    getAvailableDrivers: {
      method: "GET",
      url: `${API_URL}/retailers/drivers/available-drivers`,
      transformResponse: (res) => res,
      reduce: (state, action) => {
        if (action.status === "resolved" && action.code === 200) {
          return {
            ...state,
            drivers: action.body,
          };
        }
        return {
          ...state,
          drivers: [],
        };
      },
    },

    moveOrders: {
      method: "PUT",
      url: `${API_URL}/retailers/jobs/:jobId/orders`,
    },
    saveRoutes: {
      isPure: true,
      reduce: (state, action) => {
        return { ...state, routes: action.context };
      },
    },

    checkCertitude: {
      method: "GET",
      url: `${API_URL}/retailers/jobs/validate/:jobId`,
      reduce: (state, action) => {
        return { ...state, jobValidation: action.body };
      },
    },
  },
});

setAuthHeader(getAuthData().accessToken);
