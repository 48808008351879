const ROLES = {
  admin: {
    dashboard: "edit",
    test_algorithm: "edit",
    delivery_manager: "edit",
    orders_managment: "edit",
    drivers_manager: "edit",
    drivers_stats: "edit",
    retailers: "edit",
    retailers_list: "edit",
    retailers_groups: "edit",
    system_report: "edit",
    environment_stats: "edit",
    delivery_reports: "edit",
    drivers_map: "edit",
    notifications: "edit",
    preferences: "edit",
    user_management: "edit",
    reports: "edit",
    Logs: "edit",
    accounting: "edit",  
    zones_manager: "edit",
    payments: "edit",
    tickets: "edit",
    cloud_fleet_routing: "edit",
    job_details: "edit",
    combine_jobs: "edit",
    price_management: "edit",
    invoices:"edit",
    cash_to_be_collected:"edit"
  },
  customer_service: {
    dashboard: "view",
    test_algorithm: "view",
    cloud_fleet_routing: "view",
    delivery_manager: "view",
    orders_managment: "view",
    drivers_manager: "view",
    drivers_stats: "view",
    retailers_list: "view",
    retailers_groups: "view",
    system_report: "view",
    environment_stats: "view",
    delivery_reports: "view",
    drivers_map: "view",
    notifications: "view",
    preferences: "view",
    user_management: "hide",
    reports: "view",
    Logs: "hide",
    accounting: "hide", // view
    zones_manager: "edit",
    payments: "edit",
    retailers: "edit",
    job_details: "view",
    combine_jobs: "view",
    price_management: "edit",
  },
  accounts_team: {
    dashboard: "view",
    test_algorithm: "hide",
    cloud_fleet_routing: "hide",
    delivery_manager: "hide",
    orders_managment: "edit",
    drivers_manager: "view",
    drivers_stats: "view",
    retailers_list: "view",
    retailers_groups: "hide",
    system_report: "hide",
    environment_stats: "hide",
    delivery_reports: "hide",
    drivers_map: "hide",
    notifications: "hide",
    preferences: "hide",
    user_management: "hide",
    reports: "hide",
    Logs: "hide",
    accounting: "hide", // view
    zones_manager: "hide",
    payments: "edit",
    retailers: "edit",
    job_details: "hide",
    combine_jobs: "hide",
    price_management: "edit",
  },
  operations_team: {
    dashboard: "edit",
    test_algorithm: "edit",
    cloud_fleet_routing: "edit",
    delivery_manager: "edit",
    orders_managment: "edit",
    drivers_manager: "edit",
    drivers_stats: "edit",
    retailers_list: "edit",
    retailers_groups: "edit",
    system_report: "edit",
    environment_stats: "edit",
    delivery_reports: "edit",
    drivers_map: "edit",
    notifications: "edit",
    preferences: "edit",
    user_management: "hide",
    reports: "edit",
    Logs: "edit",
    accounting: "hide", // edit
    zones_manager: "edit",
    payments: "edit",
    tickets: "edit",
    retailers: "edit",
    job_details: "edit",
    combine_jobs: "edit",
    price_management: "edit",
  },
  driver_manager: {
    dashboard: "view",
    test_algorithm: "hide",
    cloud_fleet_routing: "hide",
    delivery_manager: "view",
    orders_managment: "hide",
    drivers_manager: "edit",
    drivers_stats: "view",
    retailers_list: "hide",
    retailers_groups: "hide",
    system_report: "hide",
    environment_stats: "hide",
    delivery_reports: "hide",
    drivers_map: "view",
    notifications: "hide",
    preferences: "hide",
    user_management: "hide",
    reports: "hide",
    Logs: "hide",
    accounting: "hide",
    zones_manager: "hide",
    payments: "hide",
    job_details: "view",
    combine_jobs: "view",
    price_management: "edit",
  },
  guest: {
    dashboard: "hide",
    test_algorithm: "hide",
    cloud_fleet_routing: "hide",
    delivery_manager: "hide",
    orders_managment: "hide",
    drivers_manager: "hide",
    drivers_stats: "hide",
    retailers_list: "hide",
    retailers_groups: "hide",
    system_report: "hide",
    environment_stats: "hide",
    delivery_reports: "hide",
    drivers_map: "hide",
    notifications: "hide",
    preferences: "hide",
    user_management: "hide",
    reports: "hide",
    Logs: "hide",
    accounting: "hide",
    zones_manager: "hide",
    payments: "edit",
    retailers: "hide",
    job_details: "hide",
    combine_jobs: "hide",
    price_management: "edit",
  },
};
Object.freeze(ROLES);
// Admin - access to all pages with edit level
// Customer service - [all pages] - [view]
// Accounts team - [order management] - [edit]
// [Driver stats] - [view]
// [other pages] - [hide]
// Operations team - [user management page] - [hide]
// [other pages] - [edit]

// New role. Driver manager
// Edit access - Driver management, "support-chats"
// View - dashboard, Driver stats, Driver map.
// Other pages - hide

export default ROLES;
