import React from "react";

import "./trackingInfoItem.less";

const TrackingInfoItem = ({ title, value, additionalInfo, placeholderValue }) => (
  <div className="trackingInfoItem">
    <p className="trackingInfoItemTitle">{title}</p>
    <p className="trackingInfoItemValue">{value}</p>
    {additionalInfo && <p className="trackingInfoAdditional">{additionalInfo}</p>}
  </div>
);

export default TrackingInfoItem;
