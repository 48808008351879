import React from "react";
import { Form, Button, Input, Select } from "antd";
import { transformUserRole } from "src/helpers/formatUserData";
import { CreateUserProps } from "../types";

const { Option } = Select;

const CreateUser : React.FC<CreateUserProps> = ({ roles, handleAdminInvite, tenants }) => {
  const [form] = Form.useForm();

  const onFinish = async (values:any) => {
    handleAdminInvite(values);
    form.resetFields();
  };
  const filteredRoles = roles.filter((e:any) => e !== "guest");

  return (
    <>
      <Form
        style={{ width: "100%"}}
        form={form}
        layout="vertical"
        name="register"
        onFinish={onFinish}
        initialValues={{}}
        scrollToFirstError
      >
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input placeholder="Enter Email" />
        </Form.Item>

        <Form.Item
          name="username"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input your name!",
            },
          ]}
        >
          <Input placeholder="Enter Name" />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Enter password" />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The two passwords that you entered do not match!"));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm password" />
        </Form.Item>
        <Form.Item
          name="tenants"
          label="Tenants"
          rules={[
            {
              required: true,
              message: "Please input tenants!",
            },
          ]}
          hasFeedback
        >
          <Select mode="multiple" placeholder="select tenants">
            {tenants.map((e:any) => (
              <Option key={e} value={e}>
                {e}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="role"
          label="Role"
          rules={[
            {
              required: true,
              message: "Please input role!",
            },
          ]}
        >
          <Select placeholder="Select role">
            {filteredRoles.map((role:any) => (
              <Option key={role} value={role}>
                {transformUserRole(role)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button className="save-user-btn" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default CreateUser;
