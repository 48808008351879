import React, { FC } from "react";
import { Button, Dropdown, Menu, message, notification, Popconfirm } from "antd";
import { DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import { IOrderActionsDropdownProps } from "src/types/JobDetails";
import { OrderStatusEnum } from "src/types/orderEntity";

const DropdownMenu: FC<IOrderActionsDropdownProps> = ({
  data: { jobId, orderId, status },
  deleteOrder,
  returnToShipper,
  postponeOrder,
  cancelOrder,
  completeOrder,
  cancelCompletedOrder,
}) => {
  const items = [
    {
      key: "delete",
      icon: <DeleteOutlined style={{ color: "red" }} />,
      label: (
        <Popconfirm
          title="Are You sure, You want to delete this order?"
          onConfirm={() => deleteOrder({ jobId, orderId })}
          okText="Yes"
          cancelText="No"
        >
          <span style={{ color: "red" }}>Delete</span>
        </Popconfirm>
      ),
    },
    ...(status === OrderStatusEnum.CANCELED || status === OrderStatusEnum.CANCELED_AFTER_PICKUP
      ? [
          {
            key: OrderStatusEnum.RETURN_TO_SHIPPER,
            label: "Return to shipper",
          },
        ]
      : []),
    ...(status !== OrderStatusEnum.COMPLETED
      ? [
          {
            key: OrderStatusEnum.POSTPONED,
            label: "Postpone",
          },
          {
            key: OrderStatusEnum.COMPLETED,
            label: "Complete",
          },
          {
            key: OrderStatusEnum.CANCELED,
            label: "Cancel",
          },
        ]
      : [
          {
            key: "cancel-completed",
            label: "Cancel Completed Order",
          },
        ]),
  ];

  const handleMenuItemsAction = (item: any) => {
    switch (item.key) {
      case OrderStatusEnum.RETURN_TO_SHIPPER:
        returnToShipper({ jobId, orderId });

        break;
      case OrderStatusEnum.CANCELED:
        cancelOrder({ jobId, orderId });

        break;
      case "cancel-completed":
        cancelCompletedOrder({ jobId, orderId });

        break;
      case OrderStatusEnum.POSTPONED:
        postponeOrder({ jobId, orderId });

        break;
      case OrderStatusEnum.COMPLETED:
        completeOrder({ jobId, orderId });

        break;
      case "delete":
        break;
      default:
        notification.info({ message: "No action to excute" });
    }
  };

  return <Menu data-testid="orders-actions-menu" items={items} onClick={handleMenuItemsAction}></Menu>;
};

const ActionsDropdown: FC<IOrderActionsDropdownProps> = (props) => {
  return (
    <Dropdown overlay={<DropdownMenu {...props} />} placement="bottom" arrow>
      <Button icon={<MoreOutlined />} />
    </Dropdown>
  );
};

export default ActionsDropdown;
