const footerSections = {
  businessSolutions: [
    { id: "1", label: "Ship from store", path: "/", isActive: false },
    { id: "2", label: "Ship from warehouse", path: "/", isActive: false },
    { id: "3", label: "Customer returns", path: "/", isActive: false },
    { id: "4", label: "Business collections", path: "/", isActive: false },
    { id: "5", label: "Store to store", path: "/", isActive: false },
  ],
  industries: [
    { id: "1", label: "E-commerce", path: "/", isActive: false },
    { id: "2", label: "Retail and luxury", path: "/", isActive: false },
    { id: "3", label: "Home businesses", path: "/", isActive: false },
    { id: "4", label: "Professional services", path: "/", isActive: false },
  ],
};

export default footerSections;
