import React, { useState, useEffect } from "react";
import { Table, Tag } from "antd";
import { API_URL } from "src/constants/config";
const SuperRetailers = () => {
  const [retailersData, setRetailersData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem("authTokenSuper");

        const response = await fetch(`${API_URL}/superadmin/allretailers`, {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setRetailersData(data);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Support Number",
      dataIndex: "supportNumber",
      key: "supportNumber",
    },
    {
      title: "Office Address",
      dataIndex: "officeAddress",
      key: "officeAddress",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (accepted) => <Tag color={accepted ? "green" : "red"}>{accepted ? "Yes" : "No"}</Tag>,
    },
  ];

  return (
    <div className="allDriversSettings">
      <h2>Retailers</h2>
      <Table
        dataSource={retailersData}
        columns={columns}
        scroll={{ x: "max-content" }}
        responsive={true}
        className="superAdminTables"
        rowKey={(record) => record?.id} 

      />
    </div>
  );
};

export default SuperRetailers;
