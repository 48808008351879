import React from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import "./style.less";

const DownloadBtn = ({ path }) => (
  <a className="downloadLink" href={`${path}?download`} download>
    <Button type="primary" shape="round" icon={<DownloadOutlined />}>
      Download
    </Button>
  </a>
);

export default DownloadBtn;
