import React from "react";
import { Pie, PieChart, Cell, ResponsiveContainer, Tooltip, Legend } from "recharts";

export const ChartPie = ({ chartData, className }) => {
  return (
    <ResponsiveContainer width="100%" height={300} className={className}>
      <PieChart>
        <Tooltip />
        <Legend />
        <Pie data={chartData} dataKey="value" nameKey="type" cx="50%" cy="50%" innerRadius={5} outerRadius={100} label>
          {chartData.map(({ color, id }) => (
            <Cell key={`cell-${id}`} fill={color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
