import { createResource } from "redux-rest-resource";

import { API_URL } from "../../constants/config";
import { getAuthData, setAuthHeader } from "../../helpers/authStorage";

export const { types, actions, rootReducer } = createResource({
  name: "payments",
  url: `${API_URL}/admin/payments`,
  actions: {
    fetch: {
      transformResponse: ({ body }) => {
        return {
          items: body.items,
          pagination: {
            currentPageNumber: body.meta.currentPage,
            numItemsPerPage: body.meta.itemsPerPage,
            totalCount: body.meta.totalItems,
            totalPages: body.meta.totalPages,
          },
        };
      },
      reduce: (state, action) => {
        return {
          ...state,
          items: action.items,
          pagination: action.pagination,
        };
      },
    },
  },
});

setAuthHeader(getAuthData().accessToken);
