/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Divider, Slider, InputNumber, Space, Form, Input, Switch, Button, notification } from "antd";
import GoogleMapReact from "google-map-react";
import { GoogleApiWrapper } from "google-maps-react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useDispatch } from "react-redux";

import { actions as settingsActions } from "../../../../redux/resources/preferences";

import "./styles.less";
import { API_KEY } from "../../../../constants/config";

// const defaultCoords = { lat: 54, long: 40 };
// const defaultRadius = 100;
const coordInputStyle = { display: "flex", flexDirection: "column", alignItems: "flex-start" };

function AreaOfOperation({ tenant, settings }) {
  const [radius, setRadius] = useState(settings?.areaRadius);
  const [customLocation, setCustomLocation] = useState({ lat: settings?.areaLat, long: settings?.areaLong });
  // eslint-disable-next-line
  const [isRefreshingMap, setIsRefreshingMap] = useState(false);
  const [zoom, setZoom] = useState(0);
  const [circle, setCircle] = useState(null);
  const [marker, setMarker] = useState(null);
  const [mapReference, setMapReference] = useState(null);
  const [mapsReference, setMapsReference] = useState(null);
  const [addressSearch, setAddressSearch] = useState("");
  const [isAreaEnabled, setIsAreaEnabled] = useState(settings?.areaRestriction);

  const onRangeInput = (value) => {
    setRadius(value);
  };
  // const onFetchArea = () => {
  //   // const later = (delay, value) => new Promise((resolve) => setTimeout(resolve, delay, value));
  //   // later(1000, { body: { radius: 100, lat: 48, long: 35 } }).then(({ body }) => {
  //   //   setRadius(body.radius);
  //   //   setCustomLocation({ lat: body.lat, long: body.long });
  //   // });
  // };
  // eslint-disable-next-line
  const refreshMap = async () => {
    setIsRefreshingMap(true);
    setTimeout(() => {
      setIsRefreshingMap(false);
    }, 100);
  };
  const handleSelect = async (value) => {
    setAddressSearch(value);
    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);
    setCustomLocation({ lat: latLng.lat, long: latLng.lng });
  };

  const dispatch = useDispatch();
  const { putPreferences } = settingsActions;
  const onSubmit = () => {
    dispatch(
      putPreferences({
        ...settings,
        areaRestriction: isAreaEnabled,
        areaRadius: radius,
        areaLat: Number(customLocation.lat),
        areaLong: Number(customLocation.long),
      }),
    ).then(
      () => {
        notification.success({
          message: "Success",
          description: "Updated restriction",
        });
      },
      () => {
        notification.error({
          message: "Error",
          description: "Can't update restriction",
        });
      },
    );
  };

  const toggleArea = (value) => {
    dispatch(
      putPreferences({
        ...settings,
        areaLat: Number(customLocation.lat),
        areaLong: Number(customLocation.long),
        areaRadius: radius,
        areaRestriction: value,
      }),
    ).then(
      () => {
        setIsAreaEnabled(value);
        notification.success({
          message: "Success",
          description: value ? "Added restriction" : "Removed restriction",
        });
      },
      () => {
        if (value) {
          notification.info({
            message: "Area not valid",
            description: "Please, provide area suitable for operation",
          });
          setIsAreaEnabled(value);
        } else {
          notification.error({
            message: "Error",
            description: "Can't remove restriction",
          });
        }
      },
    );
  };

  useEffect(() => {
    if (mapReference && mapsReference) {
      if (circle && marker) {
        circle.setMap(null);
        marker.setMap(null);
      }
      setMarker(
        new mapsReference.Marker({
          position: { lat: Number(customLocation.lat || "0"), lng: Number(customLocation.long || "0") },
          map: mapReference,
          title: "Area Center",
        }),
      );
      setCircle(
        new mapsReference.Circle({
          strokeOpacity: 0.4,
          strokeWeight: 2,
          fillColor: "rgb(144, 238, 144)",
          fillOpacity: 0.2,
          map: mapReference,
          center: { lat: Number(customLocation.lat || "0"), lng: Number(customLocation.long || "0") },
          radius: radius * 1000,
        }),
      );
    }
  }, [mapReference, mapsReference, customLocation.lat, customLocation.long, radius]);

  return (
    <>
      <h3>Configure Area Of Operation For {tenant}</h3>
      <div style={{ height: "500px", width: "100%" }}>
        {isAreaEnabled ? (
          <>
            {" "}
            {!isRefreshingMap && (
              <GoogleMapReact
                options={{ clickableIcons: false, fullscreenControl: false }}
                bootstrapURLKeys={{ key: API_KEY }}
                // defaultCenter={{ lat: 0, lng: 0 }}
                center={{ lat: Number(customLocation.lat || "0"), lng: Number(customLocation.long || "0") }}
                layerTypes={[]}
                defaultZoom={0}
                onZoomAnimationEnd={(zoom) => setZoom(zoom)}
                zoom={zoom}
                onClick={({ x, y, lat, lng, event }) => {
                  setAddressSearch("");
                  setCustomLocation({ lat, long: lng });
                }}
                distanceToMouse={() => {}}
                onGoogleApiLoaded={({ map, maps }) => {
                  setMapReference(map);
                  setMapsReference(maps);
                }}
              />
            )}
          </>
        ) : (
          <>
            <div className="areaMap-locked"> Area Restriction Rules Disabled</div>
          </>
        )}
      </div>
      <Space
        style={{ width: "100%", justifyContent: "space-between", alignItems: "flex-start" }}
        split={<Divider type="vertical" />}
      >
        <div style={{ margin: "10px 0px", minWidth: 90 }}>
          Area Restriction Rules
          <Switch
            style={{ marginLeft: 10 }}
            checked={isAreaEnabled}
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={toggleArea}
          />
        </div>
        <Button
          type="primary"
          style={{ borderRadius: 0, borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
          disabled={!isAreaEnabled}
          onClick={onSubmit}
        >
          Save Area
        </Button>
      </Space>
      <Space wrap align="center">
        <Form.Item label="Latitude" style={coordInputStyle} colon={false}>
          <InputNumber
            disabled={!isAreaEnabled}
            value={customLocation.lat}
            onChange={(v) => setCustomLocation((old) => ({ ...old, lat: v }))}
          />
        </Form.Item>
        <Form.Item label="Longitude" style={coordInputStyle} colon={false}>
          <InputNumber
            disabled={!isAreaEnabled}
            value={customLocation.long}
            onChange={(v) => setCustomLocation((old) => ({ ...old, long: v }))}
          />
        </Form.Item>
        <Form.Item label="Search By Address" style={coordInputStyle} colon={false}>
          <PlacesAutocomplete
            value={addressSearch}
            onChange={(address) => setAddressSearch(address)}
            onSelect={handleSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <Input.Search
                  {...getInputProps({
                    placeholder: "Search",
                  })}
                  disabled={!isAreaEnabled}
                  value={addressSearch}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const style = { backgroundColor: suggestion.active ? "#fafafa" : "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          style,
                        })}
                        key={suggestion.description}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </Form.Item>
        <Form.Item label="Radius, Km" style={coordInputStyle} colon={false}>
          <Slider
            disabled={!isAreaEnabled}
            style={{ minWidth: 200, maxWidth: 420, width: "calc(80vw - 60px)" }}
            min={1}
            max={1000}
            defaultValue={radius}
            onAfterChange={onRangeInput}
          />
        </Form.Item>
        {/* <Form.Item style={coordInputStyle} colon={false}>
     
        </Form.Item> */}
      </Space>

      {/* <Divider orientation="left">Radius, Km</Divider> */}
    </>
  );
}
export default GoogleApiWrapper({
  apiKey: API_KEY,
})(AreaOfOperation);
// export default AreaOfOperation;
