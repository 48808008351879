import { message } from "antd";
import moment from "moment";
import { useState } from "react";
import { IMessageDef } from "./type";

export const useCommentBox = (
  sendMessage: any,
  getMessages: any,
) => {
  const [messages, setMessages] = useState<IMessageDef[]>([]);

  const onSendClick = async (ticketId: number, text: string, currentRole: string) => {
    try {
      await sendMessage({ ticketId, message: text });
      setMessages([
        {
          "message": text,
          "author": {
            "role": currentRole
          },
          "craetedAt": moment().format("MMM DD, HH:mm")
        },
        ...messages,
      ]);
    }
    catch {
      message.error("unable to send message");
    }
  }

  const fetchMessages = async (ticketId: number) => {
    try {
      const { body } = await getMessages({},
        {
          query: {
            page: 1,
            ticketId
          }
        });
      setMessages(body.items);
    }
    catch {
      message.error("Unable to get messages")
    }
  }

  return {
    messages,
    onSendClick,
    fetchMessages,
  }
}

