import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Input, Space, Popconfirm, Spin } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { API_URL } from "src/constants/config";

const Plans = () => {
  const [subscriptions, setSubscriptionsData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editableSubscriptionTypes, setEditableSubscriptionTypes] = useState({});
  const [editablePrices, setEditablePrices] = useState({});
  const [isEditingRow, setIsEditingRow] = useState(null);

  const [form] = Form.useForm();

  const fetchData = async () => {
    try {
      const authToken = localStorage.getItem("authTokenSuper");
      const response = await fetch(`${API_URL}/superadmin/subscriptions`, {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setSubscriptionsData(data);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onEdit = (record) => {
    setEditableSubscriptionTypes({
      ...editableSubscriptionTypes,
      [record.id]: record.subscription_type,
    });
    setEditablePrices({
      ...editablePrices,
      [record.id]: record.price,
    });
    setIsEditingRow(record.id);
  };

  const onCancelEdit = () => {
    setEditableSubscriptionTypes({});
    setEditablePrices({});
    setIsEditingRow(null);
  };

  const onSaveEdit = (record) => {
    const updatedSubscriptionType = editableSubscriptionTypes[record.id];
    const updatedPrice = editablePrices[record.id];

    if (updatedSubscriptionType !== undefined || updatedPrice !== undefined) {
      const authToken = localStorage.getItem("authTokenSuper");
      const headers = {
        authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      };

      if (updatedSubscriptionType !== undefined) {
        const apiEndpointSubscriptionType = `${API_URL}/superadmin/subscription_plan_name/${record.id}/${encodeURIComponent(updatedSubscriptionType)}`;
        fetch(apiEndpointSubscriptionType, {
          method: "PUT",
          headers: headers,
        })
          .then(() => fetchData())
          .then((data) => {
            console.log("API Response for Subscription Type Update:", data);
          })
          .catch((error) => {
            console.error("API Error for Subscription Type Update:", error);
          });
      }

      if (updatedPrice !== undefined) {
        const apiEndpointPrice = `${API_URL}/superadmin/subscription_plan/${record.id}/${updatedPrice}`;
        fetch(apiEndpointPrice, {
          method: "PUT",
          headers: headers,
        })
          .then(() => fetchData())
          .then((data) => {
            console.log("API Response for Price Update:", data);
            // Handle the API response as needed
          })
          .catch((error) => {
            console.error("API Error for Price Update:", error);
            // Handle the API error as needed
          });
      }
    }

    setIsEditingRow(null);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      const requestData = {
        subscription_type: values.subscription_type,
        price: values.price,
        payment_period: values.payment_period,
      };

      const authToken = localStorage.getItem("authTokenSuper");
      const response = await fetch(`${API_URL}/superadmin/subscriptions`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        setIsModalVisible(false);
        form.resetFields();
        fetchData();
      } else {
        console.error("Failed to add subscription:", response.statusText);
      }
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  };

  const handleDelete = async (subscriptionId) => {
    try {
      setSubscriptionsData((prevData) =>
        prevData.map((subscription) =>
          subscription.id === subscriptionId ? { ...subscription, loading: true } : subscription,
        ),
      );

      const authToken = localStorage.getItem("authTokenSuper");
      const response = await fetch(`${API_URL}/superadmin/subscriptions/${subscriptionId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.ok) {
        fetchData();
      } else {
        console.error("Failed to delete subscription:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting subscription:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Subscription Type",
      key: "subscription_type",
      dataIndex: "subscription_type",
      render: (subscriptionType, record) => (
        <>
          {isEditingRow === record.id ? (
            <Input
              value={editableSubscriptionTypes[record.id]}
              onChange={(e) =>
                setEditableSubscriptionTypes({
                  ...editableSubscriptionTypes,
                  [record.id]: e.target.value,
                })
              }
            />
          ) : (
            subscriptionType
          )}
        </>
      ),
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
      render: (price, record) => (
        <>
          {isEditingRow === record.id ? (
            <Input
              type="number"
              value={editablePrices[record.id]}
              onChange={(e) =>
                setEditablePrices({
                  ...editablePrices,
                  [record.id]: e.target.value,
                })
              }
            />
          ) : (
            <>
              {price} $ <span style={{ color: "#999", marginLeft: 5 }}>X per order</span>
            </>
          )}
        </>
      ),
    },
    {
      title: "Period",
      key: "payment_period",
      dataIndex: "payment_period",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Space size="small">
            <Popconfirm
              title="Are you sure you want to delete this subscription?"
              onConfirm={() => handleDelete(record.id)}
              okText="Yes"
              cancelText="No"
              icon={<Spin />}
            >
              <Button type="link" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
          <Space size="small">
            {isEditingRow === record.id ? (
              <>
                <Button type="link" onClick={() => onSaveEdit(record)}>
                  Save
                </Button>
                <Button type="link" onClick={onCancelEdit}>
                  Cancel
                </Button>
              </>
            ) : (
              <Button type="link" danger icon={<EditOutlined />} onClick={() => onEdit(record)} />
            )}
          </Space>
        </>
      ),
    },
  ];

  return (
    <div className="allDriversSettings">
      <div>
        <Button
          type="primary"
          onClick={showModal}
          style={{
            marginBottom: 16,
            color: "white",
            fontWeight: "bold",
            backgroundColor: "black",
            borderColor: "black",
          }}
        >
          Add Subscription Plan
        </Button>
      </div>
      <Modal title="Add Subscription Plan" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form} layout="vertical">
          <Form.Item
            label="Subscription Name"
            name="subscription_type"
            rules={[{ required: true, message: "Please enter Subscription Name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Price" name="price" rules={[{ required: true, message: "Please enter Price!" }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Period" name="payment_period" rules={[{ required: true, message: "Please enter Period!" }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Table
        dataSource={subscriptions}
        columns={columns}
        scroll={{ x: "max-content" }}
        responsive={true}
        className="superAdminTables"
        rowKey={(record) => record?.invoice_id}
      />
    </div>
  );
};

export default React.memo(Plans);
