import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { CollapsePanel } from "./Panel";

import "./styles.less";

const Collapse = ({ children }) => <div className="mobile-custom-collapse">{children}</div>;

const SortableItem = SortableElement(
  ({
    order,
    innerCollapsed,
    centerCoordinates,
    onOrderCollapse,
    onOpenCashAmountModal,
    onForceNextOrder,
    onDeleteOrder,
    postponeOrderDeliveryJob,
    cancelOrderDeliveryJob,
    getOneDeliveryJob,
    jobId,
  }) => (
    <CollapsePanel
      order={order}
      innerCollapsed={innerCollapsed}
      centerCoordinates={centerCoordinates}
      onOrderCollapse={onOrderCollapse}
      onOpenCashAmountModal={onOpenCashAmountModal}
      onForceNextOrder={onForceNextOrder}
      onDeleteOrder={onDeleteOrder}
      postponeOrderDeliveryJob={postponeOrderDeliveryJob}
      cancelOrderDeliveryJob={cancelOrderDeliveryJob}
      getOneDeliveryJob={getOneDeliveryJob}
      jobId={jobId}
    />
  ),
);

export const OrdersCollapse = SortableContainer(
  ({
    orders,
    innerCollapsed,
    centerCoordinates,
    onOrderCollapse,
    onOpenCashAmountModal,
    onForceNextOrder,
    onDeleteOrder,
    postponeOrderDeliveryJob,
    cancelOrderDeliveryJob,
    getOneDeliveryJob,
    jobId,
  }) => (
    <Collapse>
      {orders?.map((order, index) => (
        <SortableItem
          key={order.id}
          index={index}
          order={order}
          innerCollapsed={innerCollapsed}
          centerCoordinates={centerCoordinates}
          onOrderCollapse={onOrderCollapse}
          onOpenCashAmountModal={onOpenCashAmountModal}
          onForceNextOrder={onForceNextOrder}
          onDeleteOrder={onDeleteOrder}
          postponeOrderDeliveryJob={postponeOrderDeliveryJob}
          cancelOrderDeliveryJob={cancelOrderDeliveryJob}
          getOneDeliveryJob={getOneDeliveryJob}
          jobId={jobId}
        />
      ))}
    </Collapse>
  ),
);
