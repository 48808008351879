import React from "react";
import { Button, Form, Input } from "antd";

const InputGroup = ({ intl , confirmSelf }) => {
  return (
    <Form className="input-group" onFinish={confirmSelf}>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: `${intl.formatMessage({ id: "error.required", defaultMessage: "Field is required!" })}`,
          },
          { min: 6, message: `${intl.formatMessage({ id: "error.minLengthPassword", defaultMessage: "Password must be minimum 6 characters." })}` },
        ]}
      >
        <Input.Password placeholder={`${intl.formatMessage({ id: "input.password", defaultMessage: "Password" })}`} />
      </Form.Item>
      <Form.Item
        name="passwordConfirmation"
        dependencies={["password"]}
        rules={[
          {
            required: true,
            message: `${intl.formatMessage({ id: "error.required", defaultMessage: "Field is required!" })}`,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(`${intl.formatMessage({ id: "error.notMatchPasswords", defaultMessage: "The two passwords that you entered do not match!" })}`));
            },
          }),
        ]}
      >
        <Input.Password
          placeholder={`${intl.formatMessage({ id: "input.confirmPassword", defaultMessage: "Confirm password" })}`}
        />
      </Form.Item>
      <Form.Item>
        <Button className="input-group__submit" htmlType="submit">
          {`${intl.formatMessage({ id: " tabPane.actions.confirm", defaultMessage: "Confirm" })}`}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default InputGroup;
