import React from "react";

import { MobileModalWrapper } from "../../../../../components/AdminPanel/Mobile/MobileModalWrapper";

export const MobileConfirmModal = ({ visible, onConfirm, onClose }) => {
  const confirmType = () => {
    switch (visible?.type) {
      case "status":
        return "change status of";
      case "archive":
        return "archive";
      case "approve":
        return "approve";
      case "reject":
        return "reject";
      case "wait":
        return "place on wait";
      default:
        return "";
    }
  };

  return (
    <MobileModalWrapper visible={visible} title="Select option" onClose={onClose} footer={null}>
      <div className="notifications-mobile-modal">
        <div className="notifications-mobile-modal__text">
          Are you sure to <span>{confirmType()}</span> this request?
        </div>
        <div className="notifications-mobile-modal__btn-group">
          <div onClick={onConfirm} className="mobile-btn mobile-btn--blue">
            Confirm
          </div>
          <div onClick={onClose} className="mobile-btn mobile-btn--purple">
            Cancel
          </div>
        </div>
      </div>
    </MobileModalWrapper>
  );
};
