import { Modal, Form, Row, Col, Input, Divider, Typography, Button } from "antd";
import React, { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IAddRetailerModal } from "./types.d";
import "./style.less";
import TenantSelector from "src/components/DataEntry/TenantSelector";
import { TENANT_STORAGE_KEY } from "src/helpers/authStorage";
import { ModalModeEnum } from "../../types/store-management";

const { Item } = Form;
const AddRetailerModal: FC<IAddRetailerModal> = ({
  onConfirmRetailerForm,
  onDiscard,
  isOpen,
  mode = ModalModeEnum.CREATE,
  retailerToEdit,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const defaultTenant = localStorage.getItem(TENANT_STORAGE_KEY);

  // state
  const [loading, setIsLoading] = useState<boolean>(false);

  // methods
  const handleFormSubmit = async (values: any) => {
    const { pocFirstName, pocLastName, pocPhoneNumber, tenantName, ...rest } = values;
    const mappedBody = {
      ...rest,
      name: values?.fullName,
      tenant: {
        name: tenantName,
      },
      pointOfContact: {
        firstName: pocFirstName,
        lastName: pocLastName,
        phoneNumber: pocPhoneNumber,
      },
    };
    setIsLoading(true);
    await onConfirmRetailerForm(mappedBody);
    setIsLoading(false);
  };
  const handleCancel = () => {
    form?.resetFields();
    onDiscard();
  };
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        form.resetFields();
        handleFormSubmit(values);
      })
      .catch((info: Error) => {
        console.log("Validate Failed:", info);
      });
  };
  const handleCountryChange = (value: string) => {
    form.setFieldsValue({
      tenantName: value,
    });
  };

  // effects
  useEffect(() => {
    if (mode === ModalModeEnum.EDIT && retailerToEdit) {
      form.setFieldsValue({
        fullName: retailerToEdit?.name,
        pocFirstName: retailerToEdit?.pointOfContact?.firstName,
        pocLastName: retailerToEdit?.pointOfContact?.lastName,
        pocPhoneNumber: retailerToEdit?.pointOfContact?.phoneNumber,
        ...retailerToEdit,
      });
    }
  }, [mode, retailerToEdit]);

  return (
    <Modal
      visible={isOpen}
      className="retailer-modal" // temp
      bodyStyle={{
        height: "70vh",
        overflowX: "hidden",
        overflowY: "scroll",
        paddingLeft: "40px",
        paddingRight: "40px",
      }}
      title={
        <Typography.Title level={4}>
          <FormattedMessage id={`retailers.modal.title.${mode}`} defaultMessage="Add New Retailer" />
        </Typography.Title>
      }
      width={"60vw"}
      onOk={handleFormSubmit}
      onCancel={handleCancel}
      okText={<FormattedMessage id="modal.save" defaultMessage="Save" />}
      cancelText={<FormattedMessage id="modal.discard" defaultMessage="Discard" />}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Return
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>
          Submit
        </Button>,
      ]}
    >
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        form={form}
        initialValues={{
          tenantName: defaultTenant,
        }}
      >
        {/* Main infos */}
        <Typography.Title level={5}>
          <FormattedMessage id="retailers.basicInfos" defaultMessage="Basic Information" />
        </Typography.Title>
        <Row justify="space-between">
          <Col lg={11} sm={23}>
            <Item
              name="fullName"
              label={intl.formatMessage({ id: "retailers.name", defaultMessage: "Retailer Name" })}
              rules={[{ required: true, message: "Retailer name is required!" }]}
            >
              <Input placeholder={intl.formatMessage({ id: "retailers.name", defaultMessage: "Retailer Name" })} />
            </Item>
          </Col>
          <Col lg={11} sm={23}>
            <Item
              name="tenantName"
              label={intl.formatMessage({ id: "retailers.country", defaultMessage: "Country" })}
              rules={[{ required: true, message: "Country is required!" }]}
            >
              <TenantSelector
                isAdmin
                defaultTenant={defaultTenant}
                onChange={handleCountryChange}
                isMobile={false}
                isCustom={true}
              />
            </Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col lg={11} sm={23}>
            <Item
              name="phoneNumber"
              label={intl.formatMessage({ id: "retailers.phoneNumber", defaultMessage: "Phone number" })}
              rules={[{ required: true, message: "Phone number is required!" }]}
            >
              <Input placeholder={intl.formatMessage({ id: "randomNumber", defaultMessage: "Ex: +96 9876 0000" })} />
            </Item>
          </Col>
          {mode === ModalModeEnum.CREATE && (
            <Col lg={11} sm={23}>
              <Item
                name="email"
                label={intl.formatMessage({ id: "retailers.email", defaultMessage: "Email" })}
                rules={[{ required: true, message: "Email is empty or has wrong format!", type: "email" }]}
              >
                <Input
                  type="email"
                  placeholder={intl.formatMessage({ id: "retailers.email", defaultMessage: "Email" })}
                />
              </Item>
            </Col>
          )}
        </Row>
        {mode === ModalModeEnum.CREATE && (
          <Row justify="space-between">
            <Col lg={11} sm={23}>
              <Item
                name="password"
                label={intl.formatMessage({ id: "input.password", defaultMessage: "Password" })}
                rules={[
                  {
                    required: true,
                    message: `${intl.formatMessage({
                      id: "error.invalidPasword",
                      defaultMessage: "Please input your password!",
                    })}`,
                  },
                  {
                    min: 6,
                    message: `${intl.formatMessage({
                      id: "error.minLengthPassword",
                      defaultMessage: "Please input your password!",
                    })}`,
                  },
                ]}
              >
                <Input.Password
                  placeholder={intl.formatMessage({ id: "input.password", defaultMessage: "Password" })}
                />
              </Item>
            </Col>
            <Col lg={11} sm={23}>
              <Item
                name="passwordConfirm"
                label={intl.formatMessage({ id: "input.confirmPassword", defaultMessage: "Confirm Password" })}
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: `${intl.formatMessage({
                      id: "error.confirmPassword",
                      defaultMessage: "Please confirm your password!",
                    })}`,
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        `${intl.formatMessage({
                          id: "error.notMatchPasswords",
                          defaultMessage: "The two passwords that you entered do not match!",
                        })}`,
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder={intl.formatMessage({
                    id: "input.confirmPassword",
                    defaultMessage: "Confirm Password",
                  })}
                />
              </Item>
            </Col>
          </Row>
        )}
        <Divider />

        {/* Other infos */}
        <Typography.Title level={5}>
          <FormattedMessage id="retailers.otherInfos" defaultMessage="Other Information (Optional)" />
        </Typography.Title>
        <Col span={24}>
          <Item
            name="officeAddress"
            label={intl.formatMessage({ id: "retailers.officeAddress", defaultMessage: "Office Address" })}
          >
            <Input
              placeholder={intl.formatMessage({ id: "retailers.officeAddress", defaultMessage: "Office Address" })}
            />
          </Item>
        </Col>

        <Row justify="space-between">
          <Col lg={11} sm={23}>
            <Item
              name="officePhoneNumber"
              label={intl.formatMessage({ id: "retailers.officePhoneNumber", defaultMessage: "Offiche phone number" })}
            >
              <Input placeholder={intl.formatMessage({ id: "randomNumber", defaultMessage: "Ex: +96 9876 0000" })} />
            </Item>
          </Col>
          <Col lg={11} sm={23}>
            <Item
              name="supportNumber"
              label={intl.formatMessage({ id: "retailers.customerphoneNumber", defaultMessage: "Customer support" })}
            >
              <Input placeholder={intl.formatMessage({ id: "randomNumber", defaultMessage: "Ex: +96 9876 0000" })} />
            </Item>
          </Col>
        </Row>
        <Divider />

        {/* POC */}
        <Typography.Title level={5}>
          <FormattedMessage id="retailers.poc" defaultMessage="Point of contact (Optional)" />
        </Typography.Title>
        <Row justify="space-between">
          <Col lg={11} sm={23}>
            <Item
              name="pocFirstName"
              label={intl.formatMessage({ id: "input.firstName", defaultMessage: "First Name" })}
            >
              <Input placeholder={intl.formatMessage({ id: "input.firstName", defaultMessage: "First Name" })} />
            </Item>
          </Col>
          <Col lg={11} sm={23}>
            <Item name="pocLastName" label={intl.formatMessage({ id: "input.lastName", defaultMessage: "Last Name" })}>
              <Input placeholder={intl.formatMessage({ id: "input.lastName", defaultMessage: "Last Name" })} />
            </Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col lg={11} sm={23}>
            <Item
              name="pocPhoneNumber"
              label={intl.formatMessage({ id: "retailers.phoneNumber", defaultMessage: "Phone number" })}
            >
              <Input placeholder={intl.formatMessage({ id: "randomNumber", defaultMessage: "Ex: +96 9876 0000" })} />
            </Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddRetailerModal;
