/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { notification } from "antd";

import { actions as envStatsActions } from "../../../redux/resources/envStats";
import { getAuthData } from "../../../helpers/authStorage";
import { API_URL } from "../../../constants/config";
import { formatChartData, formatGeneralStats, formatOrderStatsData } from "../formatFunctions";
import { customFetch } from "../../../utils/customFetch";

export const useHooks = () => {
  const [env, setEnv] = useState("Qatar");
  const [dateRange, setDateRange] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const envList = useSelector(({ envStats }) => envStats.envTenants);
  const envOrders = useSelector(({ envStats }) => envStats.envOrders);
  const envOrdersStats = useSelector(({ envStats }) => envStats.envOrdersStats);
  const envJobs = useSelector(({ envStats }) => envStats.envJobs);
  const envJobsStats = useSelector(({ envStats }) => envStats.envOrdersStats);
  const envRetailers = useSelector(({ envStats }) => envStats.envRetailers);
  const retailersList = useSelector(({ envStats }) => envStats.retailersList);
  const isMobile = useSelector(({ admin }) => admin.isMobile);
  const dispatch = useDispatch();
  const {
    loadOrdersEnvStats,
    loadOrdersStatsEnvStats,
    loadJobsEnvStats,
    loadJobsStatsEnvStats,
    loadRetailersEnvStats,
    loadRetailersStatsEnvStats,
    loadTenantsEnvStats,
  } = envStatsActions;

  const [ordersQuery, setOrdersQuery] = useState({ page: 1, limit: 5 });
  const [jobsQuery, setJobsQuery] = useState({ page: 1, limit: 5 });

  const showError = (error, env) => {
    notification.error({
      message: "Error",
      description: error || `Error on collecting data from ${env}`,
    });
  };

  const effectWrapper = async (env, callback) => {
    try {
      await callback();
    } catch (e) {
      showError(e?.body?.message, env);
    }
  };

  useEffect(() => {
    dispatch(loadTenantsEnvStats());
  }, []);

  const loadRetailersList = useCallback(() => {
    dispatch(loadRetailersEnvStats({}, { query: { name: env } }));
  }, [env]);

  const loadEnvRetailers = useCallback(() => {
    dispatch(loadRetailersStatsEnvStats({}, { query: { name: env, ...dateRange } }));
  }, [env]);

  const loadEnvOrders = useCallback(() => {
    dispatch(loadOrdersEnvStats({}, { query: { name: env, ...dateRange, ...ordersQuery } }));
    dispatch(loadOrdersStatsEnvStats({}, { query: { name: env, ...dateRange, ...ordersQuery } }));
  }, [env, dateRange, ordersQuery]);

  const loadEnvJobs = useCallback(() => {
    dispatch(loadJobsEnvStats({}, { query: { name: env, ...dateRange, ...jobsQuery } }));
    dispatch(loadJobsStatsEnvStats({}, { query: { name: env, ...dateRange, ...jobsQuery } }));
  }, [env, dateRange, jobsQuery]);

  useEffect(() => {
    effectWrapper(env, loadRetailersList);
  }, [env]);

  useEffect(() => {
    if (dateRange.from && dateRange.to) {
      effectWrapper(env, loadEnvRetailers);
    }
  }, [env, dateRange]);

  useEffect(() => {
    if (dateRange.from && dateRange.to) {
      effectWrapper(env, loadEnvOrders);
    }
  }, [env, dateRange, ordersQuery]);

  useEffect(() => {
    if (dateRange.from && dateRange.to) {
      effectWrapper(env, loadEnvJobs);
    }
  }, [env, dateRange, jobsQuery]);

  const onOrderPaginatonChange = (page, limit) => {
    setOrdersQuery({ page, limit });
  };

  const onJobPaginationChange = (page, limit) => {
    setJobsQuery({ page, limit });
  };

  const downloadReport = async (retailerId) => {
    const retailerQuery = retailerId ? `&retailerId=${retailerId}` : "";
    const { from, to } = dateRange;

    if (!from || !to) {
      return notification.error({
        message: "Error",
        description: `Please, select date range before continue.`,
      });
    }

    try {
      const filtersQuery = new URLSearchParams({
        from: `${from}T00:00:00.000`,
        to: `${to}T23:59:59.000`,
      });
      const response = await customFetch(`${API_URL}/admin/stats/generate-report?${filtersQuery}${retailerQuery}`, {
        method: "GET",
        cache: "no-cache",
        headers: {
          Authorization: `Bearer ${getAuthData().accessToken}`,
          Tenant: env,
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });

      if (!response.ok) {
        const data = await response.json();
        return notification.error({
          message: "Error",
          description: data?.message || "Error wile generating report",
        });
      }

      setIsLoading(true);
      const blob = await response.blob();
      const newBlob = new Blob([blob]);

      const blobUrl = window.URL.createObjectURL(newBlob);

      const link = document.createElement("a");
      link.href = blobUrl;
      let fileName = `${env}-report.xlsx`;
      let disposition = response.headers.get("Content-Disposition");
      if (disposition && disposition.indexOf("attachment") !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, "");
        }
      }
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      window.URL.revokeObjectURL(blob);
    } catch (error) {
      notification.error({
        message: "Error",
        description: error?.message || "Error while generating report",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const disabledTomorrowAndFuture = (current) => {
    return current > new Date();
  };

  const generalStats = formatGeneralStats({
    jobsStats: envJobsStats,
    ordersStats: envOrdersStats,
    retailers: envRetailers,
  });
  const chartData = formatChartData(envOrdersStats);
  const orderStats = formatOrderStatsData(envOrdersStats);
  const jobsData = envJobs?.items || [];
  const ordersData = envOrders?.items || [];

  const changeJobPagination = (page = jobsQuery.page, pageSize = jobsQuery.limit) => {
    setJobsQuery({ page, limit: pageSize });
  };
  const changeOrderPagination = (page = jobsQuery.page, pageSize = jobsQuery.limit) => {
    setOrdersQuery({ page, limit: pageSize });
  };

  const ordersPaginationConfig = {
    pageSize: ordersQuery.limit || 10,
    total: envOrders?.meta?.totalItems || 50,
    current: ordersQuery.page || 1,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
    position: ["bottomRight"],
    onChange: changeOrderPagination,
  };

  const jobsPaginationConfig = {
    pageSize: jobsQuery.limit || 10,
    total: envJobs?.meta?.totalItems || 50,
    current: jobsQuery.page || 1,
    pageSizeOptions: [5, 10, 20],
    showSizeChanger: true,
    position: ["bottomRight"],
    onChange: changeJobPagination,
  };

  return {
    isMobile,
    isLoading,
    env,
    envList,
    retailersList,
    generalStats,
    orderStats,
    ordersData,
    chartData,
    jobsData,
    dateRange,
    ordersPaginationConfig,
    jobsPaginationConfig,
    disabledTomorrowAndFuture,
    setEnv,
    setDateRange,
    downloadReport,
    onOrderPaginatonChange,
    onJobPaginationChange,
    // changeOrderPagination,
    // changeJobPagination,
  };
};
