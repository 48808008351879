import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { actions as systemReportActions } from "../../../redux/resources/systemReport";

export const useHooks = () => {
  const [monthReportQuery, setMonthReportQuery] = useState({});
  const [chartReportQuery, setChartReportQuery] = useState({});
  const [targetMonth, setTargetMonth] = useState();
  const [comparedMonth, setComparedMonth] = useState();
  const [chartDateRange, setChartDateRange] = useState({});
  const [chartValue, setChartValue] = useState();
  const isMobile = useSelector(({ admin }) => admin.isMobile);
  const retailersList = useSelector(({ systemReport }) => systemReport.retailersList);
  const driversList = useSelector(({ systemReport }) => systemReport.driversList);
  const comapredMonthData = useSelector(({ systemReport }) => systemReport.comapredMonth);
  const targetMonthData = useSelector(({ systemReport }) => systemReport.targetMonth);
  const chartData = useSelector(({ systemReport }) => systemReport.chartData);
  const dispatch = useDispatch();

  const { loadChartDataSystemReport, loadRetalilerListSystemReport, loadDriverListSystemReport } = systemReportActions;

  const loadMonthData = (action, stateData) => {
    const { [action]: targetAction } = systemReportActions;

    if (stateData) {
      dispatch(targetAction({}, { query: { range: stateData, ...monthReportQuery } }));
    }
  };

  useEffect(() => {
    dispatch(loadRetalilerListSystemReport());
    dispatch(loadDriverListSystemReport());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadMonthData("loadTargetMonthSystemReport", targetMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetMonth, monthReportQuery]);

  useEffect(() => {
    loadMonthData("loadComparedMonthSystemReport", comparedMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparedMonth, monthReportQuery]);

  useEffect(() => {
    const { from, to } = chartDateRange;

    if (from && to && chartValue) {
      dispatch(
        loadChartDataSystemReport(
          {},
          {
            query: {
              from,
              to,
              type: chartValue,
              ...chartReportQuery,
            },
          },
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartDateRange, chartValue, chartReportQuery]);

  const onSelect = (key, setQuery) => (value) => {
    setQuery(value ? { [key]: value } : {});
  };

  const onChartValueSelect = (value) => {
    setChartValue(value);
  };

  const setDateRange = ([from, to]) => {
    setChartDateRange({
      from,
      to,
    });
  };

  const onDatePick = (setFunction, format) => (momentDate) => {
    if (!momentDate) {
      return;
    }

    if (Array.isArray(momentDate)) {
      return setFunction(momentDate.map((date) => date.format(format)));
    }

    setFunction(momentDate.format(format));
  };

  return {
    isMobile,
    driversList,
    retailersList,
    monthReportQuery,
    comapredMonthData,
    targetMonthData,
    chartReportQuery,
    chartData,
    chartDateRange,
    onSelect,
    onDatePick,
    setDateRange,
    setComparedMonth,
    setTargetMonth,
    setMonthReportQuery,
    setChartReportQuery,
    onChartValueSelect,
  };
};
