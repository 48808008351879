import React from "react";

export default ({ isMobile }) => {
  if (isMobile) {
    return [
      {
        title: "Users",
        key: "name",
        render: ({ profile, name, phoneNumber, email }) => {
          let nameField;
          let phoneField;
          let emailField;
          if (profile) {
            const { firstName } = profile;
            const { lastName } = profile;
            const { phoneNumber } = profile;
            phoneField = phoneNumber || "No number";
            nameField = `${firstName || "No name"} ${lastName || ""}`;
          } else {
            nameField = `${name || "No name"}`;
            phoneField = phoneNumber || "No number";
          }
          emailField = email || "No email";
          return (
            <div className="mobileAccountingTable">
              Name: {nameField}
              <br />
              Phone: {phoneField}
              <br />
              Email: {emailField}
            </div>
          );
        },
      },
    ];
  }
  return [
    {
      title: "Name",
      key: "name",
      render: ({ profile, name }) => {
        if (profile) {
          const { firstName } = profile;
          const { lastName } = profile;
          return `${firstName || "No name"} ${lastName || ""}`;
        }
        if (name) {
          return `${name || "No name"}`;
        }
        return "No name";
      },
    },
    {
      title: "Phone number",
      key: "phone",
      render: ({ profile, phoneNumber }) => {
        if (profile) {
          const { phoneNumber } = profile;
          return phoneNumber || "";
        }
        if (phoneNumber) {
          return phoneNumber || "";
        }
        return "No number";
      },
    },
    {
      title: "Email",
      key: "email",
      render: ({ email }) => {
        if (email) {
          return email || "";
        }
        return "No email";
      },
    },
    // defaultFilteredValue: ['pending']
  ];
};
