import React from "react";
import { Modal, Typography } from "antd";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./getStartedModal.module.less";

const GetStartedModal = ({ isVisible, onToggleModal, isMobile }) => (
  <Modal
    centered={!isMobile}
    footer={null}
    maskStyle={{ background: "rgba(22, 22, 22, 0.4)", backdropFilter: "blur(16px)" }}
    visible={isVisible}
    onCancel={onToggleModal}
    width={640}
    className={classNames(styles.modal, { [styles.mobileModal]: isMobile })}
    transitionName="slideUp"
    wrapClassName={isMobile && styles.mobileModalWrapper}
  >
    <Typography.Title level={3} className={styles.modalTitle}>
      Power your Deliveries <br />
      <Typography.Text className={styles.modalTitleInsertion}>with Dliv</Typography.Text>
    </Typography.Title>

    <Typography.Paragraph className={styles.modalDescription}>
      Enjoy guaranteed delivery within 24 hours <br /> anywhere in the country!
    </Typography.Paragraph>

    <div className={styles.signUpTypeWrap}>
      <div className={styles.signUpTypeCard}>
        <Link to="/retailer_signup" className={styles.signUpTypeLink}>
          <span className={styles.signUpTypeIconWrap}>
            <span className={styles.signUpTypeUserIcon} />
            <span className={styles.signUpTypeBusinessIcon} />
          </span>
          <span style={{ fontSize: 16 }}>I’m a Business</span>
        </Link>
      </div>

      <div className={styles.signUpTypeCard}>
        <Link to="/driver_signup" className={styles.signUpTypeLink}>
          <span className={styles.signUpTypeIconWrap}>
            <span className={styles.signUpTypeUserIcon} />
            <span className={styles.signUpTypeDriverIcon} />
          </span>
          <span>I’m a Driver</span>
        </Link>
      </div>
    </div>
  </Modal>
);

export default GetStartedModal;
