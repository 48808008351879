import React from "react";
import { ColumnsType } from "antd/lib/table";
import { Tag, Tooltip } from "antd";
import { ITicketsTableData } from "./type";

export const ticketsTableColumns: ColumnsType<ITicketsTableData> = [
  {
    title: "Ticket Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Order Id",
    dataIndex: "orderId",
    key: "orderId",
  },
  {
    title: "Label",
    key: "label",
    dataIndex: "label",
    render: (_, { labels }) => (
      <>
        {labels?.map((label) => {
          return (
            <Tooltip placement="top" title={label.toUpperCase()} key={label}>
              <Tag color="geekblue" key={label} className="text-ellipsis">
                {label.toUpperCase()}
              </Tag>
            </Tooltip>
          );
        })}
      </>
    ),
  },
];
