import React from "react";

import { DesktopView } from "./DesktopView";
import { MobileView } from "./MobileView";
import { useHooks } from "./hooks/useHooks";

import "./style.less";

const EnvStats = () => {
  const {
    isMobile,
    isLoading,
    env,
    envList,
    retailersList,
    generalStats,
    orderStats,
    ordersData,
    chartData,
    jobsData,
    dateRange,
    ordersPaginationConfig,
    jobsPaginationConfig,
    disabledTomorrowAndFuture,
    setEnv,
    setDateRange,
    downloadReport,
    onOrderPaginatonChange,
    onJobPaginationChange,
  } = useHooks();

  return (
    <>
      {isMobile ? (
        <MobileView
          isLoading={isLoading}
          env={env}
          envList={envList}
          retailersList={retailersList}
          generalStats={generalStats}
          orderStats={orderStats}
          ordersData={ordersData}
          chartData={chartData}
          jobsData={jobsData}
          dateRange={dateRange}
          ordersPaginationConfig={ordersPaginationConfig}
          jobsPaginationConfig={jobsPaginationConfig}
          disabledTomorrowAndFuture={disabledTomorrowAndFuture}
          setEnv={setEnv}
          setDateRange={setDateRange}
          downloadReport={downloadReport}
          onOrderPaginatonChange={onOrderPaginatonChange}
          onJobPaginationChange={onJobPaginationChange}
        />
      ) : (
        <DesktopView
          isLoading={isLoading}
          env={env}
          envList={envList}
          retailersList={retailersList}
          generalStats={generalStats}
          orderStats={orderStats}
          ordersData={ordersData}
          chartData={chartData}
          jobsData={jobsData}
          ordersPaginationConfig={ordersPaginationConfig}
          jobsPaginationConfig={jobsPaginationConfig}
          disabledTomorrowAndFuture={disabledTomorrowAndFuture}
          setEnv={setEnv}
          setDateRange={setDateRange}
          downloadReport={downloadReport}
        />
      )}
    </>
  );
};

export default EnvStats;
