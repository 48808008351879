import { Button, Popover, Typography } from "antd";
import React from "react";
import { DeleteUserProps } from "../types";
import { DeleteOutlined } from "@ant-design/icons";



const { Paragraph } = Typography;

const DeleteUser : React.FC<DeleteUserProps> = ({id, handleDeleteUser}) => {
    const content = (
        <>
            <Paragraph>Are you sure you want to <br /> delete this user?</Paragraph>
            <Button className="delete-user-btn" onClick={() => {handleDeleteUser(id);}}>
                Delete User
            </Button>
        </>
    );
    return (
        (
        <>
            <Popover content={content} trigger="click" placement="bottom">
            <Button className="no-border-style">
                <DeleteOutlined />
            </Button>
            </Popover>
        </>
        ) || "No actions"
    );
}

export default DeleteUser;
