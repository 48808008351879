import React, { FC } from "react";
import { Button, Modal } from "antd";
import { FormattedMessage } from "react-intl";
import { IJobConfirmationPrintModal } from "../../../../../types/JobManagement";

const ConfirmationPrintModal: FC<IJobConfirmationPrintModal> = ({
  isOpen,
  onCloseModal,
  printAllJobs,
  selectedJobs,
}) => {
  return (
    <Modal
      visible={isOpen}
      onCancel={onCloseModal}
      title={<FormattedMessage id="page.jobsManagment.print" defaultMessage="Print Jobs" />}
      footer={[
        <Button key="Cancel" onClick={onCloseModal} loading={false}>
          <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
        </Button>,
        <Button key="submit" type="primary" loading={false} onClick={printAllJobs}>
          <FormattedMessage id="tabPane.actions.confirm" defaultMessage="Confirm" />
        </Button>,
      ]}
    >
      {selectedJobs > 0 ? (
        <p>Do you really want to print the {selectedJobs} jobs selected ?</p>
      ) : (
        <p>Do you really want to print all jobs ?</p>
      )}
    </Modal>
  );
};

export default ConfirmationPrintModal;
