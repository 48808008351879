import React, { useEffect, useRef, useState } from "react";
import { Button, Form, InputNumber } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { MobileModalWrapper } from "../../../../../components/AdminPanel/Mobile/MobileModalWrapper";
import { MobileLoader } from "../../../../../components/AdminPanel/Mobile/MobileLoader";

import "./styles.less";

const FORBIDDEN_VALUES = [null, undefined, ""];

const useResetFormOnCloseModal = ({ form, visible }) => {
  const prevVisibleRef = useRef();

  useEffect(() => {
    prevVisibleRef.current = visible;
  }, [visible]);

  const prevVisible = prevVisibleRef.current;

  useEffect(() => {
    if (!visible && prevVisible) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
};

export const LocationModal = ({ old, isLocationModalOpen, getMapData, setIsLocationModalOpen, addLocationToLocal }) => {
  const [customLocation, setCustomLocation] = useState({ lat: null, long: null });
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [displayedAddress, setDisplayedAddress] = useState("");
  const [form] = Form.useForm();
  const intl = useIntl();

  useResetFormOnCloseModal({
    form,
    visible: isLocationModalOpen,
  });

  const changeLocation = (value, coord) => {
    setCustomLocation((prevState) => ({
      ...prevState,
      [coord]: value,
    }));
  };

  const saveNewPosition = () => {
    const { lat, long } = form.getFieldsValue(true);

    addLocationToLocal({
      selectedId: isLocationModalOpen,
      lat: parseFloat(lat),
      long: parseFloat(long),
      old,
      address: displayedAddress,
    });
    setIsLocationModalOpen(null);
  };

  const validateForm = async () => {
    const { lat, long } = form.getFieldsValue(true);

    if (FORBIDDEN_VALUES.includes(lat) || FORBIDDEN_VALUES.includes(long)) {
      return;
    }

    setIsLoading(true);

    const res = await getMapData({ lat, long }, "coords");

    if (!res) {
      setFormError(true);
      setDisplayedAddress("Unknown place");
    } else {
      setDisplayedAddress(res.address);
    }
    setIsLoading(false);
  };

  const closeModal = () => {
    setIsLocationModalOpen(null);
    setDisplayedAddress("");
    setFormError(false);
  };

  return (
    <>
      {isLoading && <MobileLoader />}
      <MobileModalWrapper
        visible={isLocationModalOpen}
        title={`${intl.formatMessage({ id: "modal.addLocation", defaultMessage: "Add location" })}`}
        onClose={closeModal}
        footer={null}
      >
        <div className="mobile-location-modal">
          <Form form={form} requiredMark onValuesChange={validateForm}>
            <div className="inputsLatLongWrapper">
              <Form.Item
                label={`${intl.formatMessage({ id: "modal.latitude", defaultMessage: "Latitude" })}`}
                name="lat"
                requiredMark
                rules={[
                  {
                    required: true,
                    message: `${intl.formatMessage({
                      id: "error.requiredLatitude",
                      defaultMessage: "Latitude is required",
                    })}`,
                  },
                ]}
                validateTrigger="onBlur"
              >
                <InputNumber
                  min={-90}
                  max={90}
                  value={customLocation.lat}
                  placeholder={`${intl.formatMessage({ id: "modal.latitude", defaultMessage: "Latitude" })}`}
                  onChange={(value) => changeLocation(value, "lat")}
                />
              </Form.Item>
              <Form.Item
                label={`${intl.formatMessage({ id: "modal.longitude", defaultMessage: "Longitude" })}`}
                name="long"
                requiredMark
                rules={[
                  {
                    required: true,
                    message: `${intl.formatMessage({
                      id: "error.requiredLongitude",
                      defaultMessage: "Longitude is required",
                    })}`,
                  },
                ]}
                validateTrigger="onBlur"
              >
                <InputNumber
                  min={-180}
                  max={180}
                  value={customLocation.long}
                  placeholder={`${intl.formatMessage({ id: "modal.longitude", defaultMessage: "Longitude" })}`}
                  onChange={(value) => changeLocation(value, "long")}
                />
              </Form.Item>
            </div>
            {formError && (
              <p className="locationModal__error">
                <FormattedMessage
                  id="modal.checkLatitude"
                  defaultMessage="Check your latitude and longitude probably they are wrong"
                />
              </p>
            )}
            {displayedAddress && !isLoading && (
              <>
                <FormattedMessage id="input.input.address" defaultMessage="Address" />: {displayedAddress}
              </>
            )}
          </Form>
          <Button
            onClick={saveNewPosition}
            disabled={!customLocation.lat || !customLocation.long || formError}
            loading={isLoading}
            className="mobile-btn mobile-btn--yellow"
          >
            <FormattedMessage id="tabPane.actions.save" defaultMessage="Save" />
          </Button>
          <Button onClick={closeModal} className="mobile-btn mobile-btn--white">
            <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
          </Button>
        </div>
      </MobileModalWrapper>
    </>
  );
};
