import React from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import "./styles.less";

export const MobileInputSearch = ({ placeholder, onSearch }) => {
  return (
    <Input
      onChange={onSearch}
      placeholder={placeholder}
      prefix={<SearchOutlined color="rgba(1, 21, 40, 0.25)" />}
      className="mobile-input-search"
    />
  );
};
