import { useEffect, useMemo } from "react";

const createControlledPromise = () => {
  let resolver;
  let rejector;
  const promise = new Promise((resolve, reject) => {
    resolver = resolve;
    rejector = reject;
  });
  return { promise, resolver, rejector };
};

export const useMarker = ({ lat, lng }) => {
  const { promise: apiPromise, resolver: handleGoogleApiLoaded } = useMemo(createControlledPromise, []);

  useEffect(() => {
    let marker;
    apiPromise.then((api) => {
      const { map, maps } = api;
      marker = new maps.Marker({ position: { lat, lng }, map });
    });
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng]);
  return { handleGoogleApiLoaded };
};
