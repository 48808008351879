import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { LockOutlined } from "@ant-design/icons";

import { Button, Form, Input, notification, Row, Layout, Col, Typography } from "antd";
import styles from "../NewLanding/pages/SignInRetailerPage/signInRetailerPage.module.less";
import RetailerTablet from "../../assets/newLanding/auth/RetailerTablet.png";

import { actions as adminActions } from "../../redux/resources/admin";
import { ReactComponent as EmailIcon } from "../../assets/newLanding/auth/emailIcon.svg";
import "./AdminSignup.less";
import MainLogo from "../../assets/newLanding/MainLogo";

const AdminSignup = ({ signupAdmin }) => {
  const intl = useIntl();

  const [form] = Form.useForm();

  const openErrorNotification = (error) => {
    notification.error({
      message: "Error",
      description: error.body && error.body.message && error.body.message,
    });
  };

  const onFinish = (values) => {
    const formattedFields = { ...values };
    formattedFields.email = formattedFields.email.toLowerCase();
    signupAdmin({ ...formattedFields })
      .then(
        (
          response, //deirect to login
        ) => (window.location.href = "/admin"),
      )
      .catch((error) => openErrorNotification(error));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const title = `${intl.formatMessage({ id: "delivery", defaultMessage: "Delivery company" })}`;
  return (
    <Layout className={styles.signUpWrap}>
      <Row>
        <Col span={12} md={24} lg={12} className={styles.promoSection}>
          <Row gutter={[0, 56]}>
            <Col span={23} offset={1}>
              <Link to="/">
                <MainLogo />
              </Link>
            </Col>

            <Col span={21} md={24} offset={3}>
              <Typography.Title level={2} className={styles.promoSectionTitle}>
                {title} <br />
                <Typography.Text className={styles.promoSectionSubtitle}>
                  <FormattedMessage id="page.login.signup" defaultMessage="Sign up" />
                </Typography.Text>
              </Typography.Title>
              <div className={styles.tablet_img}>
                <img src={RetailerTablet} style={{ width: 106, height: 207, zIndex: 1 }} alt="imageForTablet" />
              </div>{" "}
            </Col>
          </Row>
        </Col>

        <Col span={12} md={24} lg={12} className={styles.mainSection}>
          <Row style={{ height: 80, paddingBottom: 36 }} justify="end">
            <Col
              span={8}
              style={{ display: "flex", alignItems: "end", fontSize: 14, fontWeight: 300, lineHeight: "18px" }}
            >
              Already have a company account?
            </Col>
            <Col
              span={4}
              style={{
                display: "flex",
                alignItems: "end",
                fontSize: 16,
                fontWeight: 700,
                lineHeight: "24px",
                color: "#0A0A0B",
                justifyContent: "end",
                paddingRight: 50,
              }}
            >
              <Link
                to="/admin_login"
                style={{
                  color: "#0A0A0B",
                }}
              >
                <FormattedMessage id="page.login.login" defaultMessage="Log In" />
              </Link>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={12} offset={6}>
              <div style={{ marginTop: 250, transform: "translate(-12%, -50%)" }}>
                <Form
                  name="adminSignup"
                  form={form}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    name="email"
                    className={styles.formItem}
                    rules={[
                      {
                        type: "email",
                        message: `${intl.formatMessage({
                          id: "error.invalidEmail",
                          defaultMessage: "Email is not valid!",
                        })}`,
                      },
                      {
                        required: true,
                        message: `${intl.formatMessage({
                          id: "error.inputEmail",
                          defaultMessage: "Please input your email!",
                        })}`,
                      },
                    ]}
                  >
                    <Input
                      prefix={<EmailIcon />}
                      placeholder={`${intl.formatMessage({ id: "input.email1", defaultMessage: "Email" })}`}
                      className={styles.formInput}
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    className={styles.formItem}
                    rules={[
                      {
                        required: true,
                        message: `${intl.formatMessage({
                          id: "error.invalidPasword",
                          defaultMessage: "Please input your password!",
                        })}`,
                      },
                      {
                        pattern: /^.{6,}$/,
                        message: "Password should be at least 6 characters long",
                      },
                    ]}
                  >
                    <Input.Password
                      prefix={<LockOutlined className="prefixIconYellow" />}
                      placeholder={`${intl.formatMessage({ id: "input.password", defaultMessage: "Password" })}`}
                      className={styles.formInput}
                    />
                  </Form.Item>
                  <Form.Item
                    name="passwordConfirmation"
                    dependencies={["password"]}
                    className={styles.formItem}
                    rules={[
                      {
                        required: true,
                        message: `${intl.formatMessage({
                          id: "error.confirmPassword",
                          defaultMessage: "Please confirm your password!",
                        })}`,
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          return !value || getFieldValue("password") === value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(
                                  `${intl.formatMessage({
                                    id: "error.notMatchPasswords",
                                    defaultMessage: "The two passwords that you entered do not match!",
                                  })}`,
                                ),
                              );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      prefix={<LockOutlined className="prefixIconYellow" />}
                      placeholder={`${intl.formatMessage({
                        id: "input.confirmPassword",
                        defaultMessage: "Confirm password",
                      })}`}
                      className={styles.formInput}
                    />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    className={styles.formItem}
                    rules={[
                      {
                        required: true,
                        message: `${intl.formatMessage({
                          id: "error.inputCompanyName",
                          defaultMessage: "Please input your company name!",
                        })}`,
                      },
                    ]}
                  >
                    <Input
                      placeholder={`${intl.formatMessage({ id: "input.companyName", defaultMessage: "Company name" })}`}
                      className={styles.formInput}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button className="signUpButton" type="primary" htmlType="submit">
                      Sign up
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default connect(null, (dispatch) => ({
  ...bindActionCreators(adminActions, dispatch),
}))(AdminSignup);
