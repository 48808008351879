import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";

import "./style.less";

const HomePageBtn = ({ path, text, width }) => (
  <Link to={path}>
    <Button type="primary" style={{ width }} className="homePageBtn">
      {text}
    </Button>
  </Link>
);

export default HomePageBtn;
