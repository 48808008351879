import React from "react";

export default function RetailerTotal({ data, intl }) {
  function colorPicker(data) {
    if (data < 100) return "rgba(235, 87, 87, 1)";
    else if (data < 250) return "rgba(252, 220, 62, 1)";
    else return "rgba(39, 174, 96, 1)";
  }
  return (
    <div className="retailerInsights__total">
      <h3 style={{ color: colorPicker(data) }}>{data}</h3>{`${intl.formatMessage({ id: "page.home.insights.total", defaultMessage: "Total orders since start" })}`}
    </div>
  );
}
