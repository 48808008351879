import { notification } from "antd";

export const useAdminTicket = (
  getAdminTicket: any,
  updateAdminTicket: any,
) => {

  const fetchTickets = async (query: Record<any, any>) => {
    try {
      await getAdminTicket({}, { query });
    }
    catch {
      notification.error({ message: "OOPs! Unable to fetch tickets" });
    }
  }

  const updateTicket = async (payload: Record<any, any>) => {
    try {
      await updateAdminTicket(payload);
      notification.success({ message: "Ticket updated successfully" });
    }
    catch {
      notification.error({ message: "OOPs! Unable to updated tickets" });
    }
  }

  return {
    fetchTickets,
    updateTicket
  }
}