import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { bindActionCreators } from "redux";
import { notification, Spin } from "antd";

import RegistrationSteps from "../../components/DataEntry/RegistrationSteps";
import RetailerInformation from "./components/RetailerInformation";
import MainPointOfContact from "./components/MainPointOfContact";
import CustomSupportNumber from "./components/CustomSupportNumber";
import RetailerDocumentUpload from "./components/RetailerDocumentUpload";
import AddressAndInstructions from "./components/AddressAndInstructions";
import { actions as retailerActions } from "../../redux/resources/retailer";
import { getQueryParam } from "../../helpers/general";
import { getAuthData } from "../../helpers/authStorage";
import "./retailerRegistration.less";

const RetailerRegistration = ({
  location,
  confirmEmailRetailer,
  putSelfInfoRetailer,
  pickUpPointsRetailer,
  history,
  retailerInfo,
}) => {
  const [currentStep, setCurrentStep] = useState(JSON.parse(localStorage.getItem("RetailerRegistrationStep")) || 0);
  const [uploadedImage, setUploadedImage] = useState("");

  const openErrorNotification = (error) => {
    notification.error({
      message: "Error",
      description: error.body && error.body.message && error.body.message,
    });
  };

  const handleUsedConfirmationToken = () => {
    notification.success({
      message: "Logged In",
    });
  };

  const areValuesChanged = (values) => {
    let currentValuesFields;
    let changedValue;
    if (values.pointOfContact && retailerInfo.pointOfContact) {
      currentValuesFields = Object.keys(values.pointOfContact);
      changedValue = currentValuesFields.find(
        (fieldName) =>
          !retailerInfo.pointOfContact[fieldName] ||
          retailerInfo.pointOfContact[fieldName] !== values.pointOfContact[fieldName],
      );
    } else {
      currentValuesFields = Object.keys(values);
      changedValue = currentValuesFields.find(
        (fieldName) => !retailerInfo[fieldName] || retailerInfo[fieldName] !== values[fieldName],
      );
    }

    return changedValue;
  };

  useEffect(() => {
    const queryParams = getQueryParam(location.search);

    queryParams.confirmationToken &&
      confirmEmailRetailer(
        {},
        {
          query: queryParams,
        },
      )
        .then((response) => {
          history.push("/retailer_registration");
        })
        .catch((error) => {
          if (error.status === 403 && getAuthData().accessToken) {
            handleUsedConfirmationToken();
          } else {
            openErrorNotification(error);
          }
        });
  }, [location, confirmEmailRetailer, history]);

  useEffect(() => {
    localStorage.setItem("RetailerRegistrationStep", currentStep.toString());
  }, [currentStep]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("addressAndInstructionsInfo");
      localStorage.removeItem("customerSupportInfo");
      localStorage.removeItem("retailerMainPointInfo");
      localStorage.removeItem("retailerInfo");
    };
  }, []);

  const onNextStep = () => {
    const current = currentStep + 1;
    setCurrentStep(current);
  };

  const onPrevStep = () => {
    const current = currentStep - 1;
    setCurrentStep(current);
  };

  const validatePoints = (points) => {
    let foundValid = false;
    points.forEach((point) => {
      if (point?.lat && point?.long && point?.name && point?.address) {
        foundValid = true;
      }
    });
    return foundValid;
  };

  const putSelfInfoRetailerWithErrorNotification = (values) => {
    const changedFieldName = areValuesChanged(values);
    return changedFieldName
      ? putSelfInfoRetailer({ ...values })
          .then(() => onNextStep())
          .catch((error) => openErrorNotification(error))
      : onNextStep();
  };

  const pickUpPointsRetailerWithErrorNotification = (pickupPoints) => {
    if (validatePoints(pickupPoints)) {
      onNextStep();
    } else {
      notification.error({
        message: "Error",
        description: "Setup at least one point fully!",
      });
    }
  };

  const steps = [
    {
      title: (
        <span className="stepTitleDisplaying">
          <FormattedMessage id="steps.retailerInfo" defaultMessage="Retailer Info" />
        </span>
      ),
      additionalTitle: (
        <span className="stepAdditionalTitle">
          <FormattedMessage id="steps.retailerInfo" defaultMessage="Retailer Info" />
        </span>
      ),
      content: (
        <RetailerInformation
          onNextStep={onNextStep}
          saveInformationRetailer={putSelfInfoRetailerWithErrorNotification}
          currentStep={currentStep}
          onPrevStep={onPrevStep}
          stepsLength={5}
          retailerInfo={retailerInfo}
        />
      ),
    },
    {
      title: (
        <span className="stepTitleDisplaying">
          <FormattedMessage id="steps.mainPoint" defaultMessage="Main Point Of Contact" />
        </span>
      ),
      additionalTitle: (
        <span className="stepAdditionalTitle">
          <FormattedMessage id="steps.mainPoint" defaultMessage="Main Point Of Contact" />
        </span>
      ),
      content: (
        <MainPointOfContact
          saveInformationRetailer={putSelfInfoRetailerWithErrorNotification}
          currentStep={currentStep}
          onPrevStep={onPrevStep}
          stepsLength={5}
          retailerInfo={retailerInfo}
        />
      ),
    },
    {
      title: (
        <span className="stepTitleDisplaying">
          <FormattedMessage id="steps.customerSupport" defaultMessage="Customer Support Number" />
        </span>
      ),
      additionalTitle: (
        <span className="stepAdditionalTitle">
          <FormattedMessage id="steps.customerSupport" defaultMessage="Customer Support Number" />
        </span>
      ),
      content: (
        <CustomSupportNumber
          currentStep={currentStep}
          onPrevStep={onPrevStep}
          stepsLength={5}
          retailerInfo={retailerInfo}
          putSelfInfoRetailerWithErrorNotification={putSelfInfoRetailerWithErrorNotification}
        />
      ),
    },
    {
      title: (
        <span className="stepTitleDisplaying">
          <FormattedMessage id="steps.address&Instructions" defaultMessage="Address & Instructions" />
        </span>
      ),
      additionalTitle: (
        <span className="stepAdditionalTitle">
          <FormattedMessage id="steps.address&Instructions" defaultMessage="Address & Instructions" />
        </span>
      ),
      content: getAuthData()?.accessToken ? (
        <AddressAndInstructions
          pickUpPointsRetailerWithErrorNotification={pickUpPointsRetailerWithErrorNotification}
          onNextStep={onNextStep}
          currentStep={currentStep}
          onPrevStep={onPrevStep}
          stepsLength={5}
          retailerInfo={retailerInfo}
          openErrorNotification={openErrorNotification}
        />
      ) : (
        <Spin spinning />
      ),
    },
    {
      title: (
        <span className="stepTitleDisplaying">
          <FormattedMessage id="steps.documentUpload" defaultMessage="Document Upload" />
        </span>
      ),
      additionalTitle: (
        <span className="stepAdditionalTitle">
          <FormattedMessage id="steps.documentUpload" defaultMessage="Document Upload" />
        </span>
      ),
      content: (
        <RetailerDocumentUpload
          uploadedImage={uploadedImage}
          setUploadedImage={setUploadedImage}
          onPrevStep={onPrevStep}
          retailerInfo={retailerInfo}
        />
      ),
    },
  ];
  return <RegistrationSteps steps={steps} onNextStep={onNextStep} onPrevStep={onPrevStep} currentStep={currentStep} />;
};
const MSTP = (state) => {
  return {
    retailerInfo: state.retailer.items,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(retailerActions, dispatch),
}))(RetailerRegistration);
