import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Layout } from "antd";
import { actions as retailerOrdersActions } from "../../redux/resources/retailerOrders";
import { actions as retailerActions } from "../../redux/resources/retailer";
import { actions as retailetTicketActions } from "../../redux/resources/retailerTicket";
import { actions as adminTicketActions } from "../../redux/resources/adminTicket";
import OrdersWidgetHome from "../../components/OrdersWidget/OrdersWidgetHome";
import ReatailerDashboardWidget from "./components/RetailerDashboardWidget";
import "./retailerCabinet.less";
import "../../components/OrdersWidget/OrdersWidgetHome/ordersWidgetHome.less";

import { useHooks } from "./useHooks.js";

const { Content } = Layout;

const RetailerCabinet = ({
  putSelfInfoRetailer,
  getSelfInfoRetailer,
  fetchRetailerOrders,
  fetchStatsRetailerOrder,
  fetchExtendedStatsRetailerOrder,
  cancelRetailerOrder,
  deleteRetailerOrder,
  deleteRetailerOrders,
  createReturnRetailerOrder,
  postponeRetailerOrder,
  retailerOrdersCancel,
  retailerOrders,
  saveUploadedRetailerOrder,
  history,
  location,
  paginationData,
  editPhoneRetailerOrder,
  fetchSelfStorePointsRetailerOrder,
  fetchStorePointsRetailerOrder,
  sendStoreToStoreRetailerOrder,
  getOwnSMSGroupsRetailer,
  retailer,
  createRetailerTicket,
}) => {
  const {
    intl,
    fetchAllOrders,
    fetchStoreSelection,
    selfStoresSelection,
    selfTemplateSelection,
    stats,
    isOrdersSaving,
    saveRetailerOrders,
    changeQueryParams,
    filteredLocationShared,
    currentQueryParams,
    createTicket,
    fetchRetailerStats,
  } = useHooks({
    getSelfInfoRetailer,
    fetchRetailerOrders,
    history,
    location,
    fetchExtendedStatsRetailerOrder,
    saveUploadedRetailerOrder,
    sendStoreToStoreRetailerOrder,
    getOwnSMSGroupsRetailer,
    allowedSMS: retailer?.retailerData?.isCustomMessagingTemplatesAllowed,
    createRetailerTicket,
  });
  const ordersListPagination = {
    pageSize: currentQueryParams.limit ? currentQueryParams.limit : 10,
    total: paginationData ? paginationData.totalItems : 50,
    current: currentQueryParams.page ? +currentQueryParams.page : 1,
    pageSizeOptions: [10, 20, 30, 50, 100],
    showSizeChanger: true,
    position: ["bottomRight"],
  };
  return (
    <Layout>
      <Content>
        <OrdersWidgetHome
          hideHeader
          setCurrentTableViews={putSelfInfoRetailer}
          tablesView={retailer.retailerData?.tablesView}
          retailer={retailer.retailerData}
          onDeleteOrder={deleteRetailerOrder}
          onFetchAllOrders={fetchAllOrders}
          onCancelOrder={cancelRetailerOrder}
          onPostponeOrder={postponeRetailerOrder}
          onCreateReturn={createReturnRetailerOrder}
          ordersUploading={isOrdersSaving}
          onDeleteOrders={deleteRetailerOrders}
          ordersList={retailerOrders && retailerOrders}
          saveOrders={saveRetailerOrders}
          userURL="/retailers/orders/upload"
          pagination={ordersListPagination}
          onChangeQueryParams={changeQueryParams}
          filteredLocationShared={filteredLocationShared}
          showReportsButton={false}
          editPhone={editPhoneRetailerOrder}
          fetchStoreSelection={fetchStoreSelection}
          //  storesSelection={storesSelection}
          selfStoresSelection={selfStoresSelection}
          selfTemplateSelection={selfTemplateSelection}
          createTicket={createTicket}
          fetchRetailerStats={fetchRetailerStats}
        >
          {
            /* TODO : make it a separate menu*/
            <ReatailerDashboardWidget
              isExtended
              intl={intl}
              isFixedDate
              stats={stats}
              name={retailer?.retailerData?.name}
            ></ReatailerDashboardWidget>
          }
        </OrdersWidgetHome>
      </Content>
    </Layout>
  );
};

const MSTP = (state) => {
  const retailerOrders = state.retailerOrders?.items;
  const paginationData = state.retailerOrders?.items[0]?.meta;
  return {
    retailer: state.retailer,
    retailerOrders,
    paginationData,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(retailerActions, dispatch),
  ...bindActionCreators(retailerOrdersActions, dispatch),
  ...bindActionCreators(retailetTicketActions, dispatch),
  ...bindActionCreators(adminTicketActions, dispatch),
}))(RetailerCabinet);
