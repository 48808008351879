import React, { FC } from "react";
import { IJobRetailerProps } from "../../../../../types/JobManagement";
import "./retailer.less";

const Retailer: FC<IJobRetailerProps> = ({ orderInfo }) => (
  <div className="retailerInfoWrapper">
    <p className="retailerInfoTitle"> Retailer: </p>
    <p className="retailerInfoItem">
      Name: <span>{orderInfo.retailer.name ? orderInfo.retailer.name : <span className="noData">No name</span>}</span>
    </p>
    <p className="retailerInfoItem">
      Office Address:{" "}
      <span>
        {orderInfo.retailer.officeAddress ? (
          orderInfo.retailer.officeAddress
        ) : (
          <span className="noData">No address</span>
        )}
      </span>
    </p>
    <p className="retailerInfoItem">
      Phone number:{" "}
      <span>
        {orderInfo.retailer.phoneNumber ? (
          orderInfo.retailer.phoneNumber
        ) : (
          <span className="noData">No phone number</span>
        )}
      </span>
    </p>
    <p className="retailerInfoItem">
      Support number:{" "}
      <span>
        {orderInfo.retailer.supportNumber ? (
          orderInfo.retailer.supportNumber
        ) : (
          <span className="noData">No support number</span>
        )}
      </span>
    </p>
  </div>
);

export default Retailer;
