import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "antd";

import { MobileModalWrapper } from "../../../../../components/AdminPanel/Mobile/MobileModalWrapper";
import useReports from "../../../../../components/OrdersWidget/OrdersWidgetHome/components/Reports/useReports";
import { MobileSelect } from "../../../../../components/AdminPanel/Mobile/MobileSelect";
import { MobileRangePicker } from "../../../../../components/AdminPanel/Mobile/MobileRangePicker";

import "./styles.less";
import {
  allOrdersStatus,
  rangeDateOptions,
} from "../../../../../components/OrdersWidget/OrdersWidgetHome/constants/helper";

export const ReportModal = ({ retailersList, isReportsModalOpen, onClose }) => {
  const {
    dates,
    disabledTomorrowAndFuture,
    handleSetDates,
    generateReport,
    onCloseModal,
    isLoading,
    setRetailer,
    rangeDateType,
    setRangeDateType,
    selectedStatus,
    setSelectedStatus,
  } = useReports({
    onClose,
  });

  const options = [{ text: "All retailers", value: null }].concat(
    retailersList?.map(({ id, name }) => ({ text: name, value: id })),
  );

  const rangeOptions = rangeDateOptions.map(({ label, value }) => ({ text: label, value }));

  const statusOptions = [{ text: "All status", value: "all" }].concat(
    allOrdersStatus.map(({ label, value }) => ({ text: label, value })),
  );
  return (
    <MobileModalWrapper
      visible={!!isReportsModalOpen}
      title={<FormattedMessage id="chooseDatesToGenerate" defaultMessage="Choose dates to generate report" />}
      onClose={onClose}
      footer={null}
    >
      <div className="mobile-report-modal-body">
        <MobileSelect options={options} onSelect={setRetailer} defaultValue={null} />
        <MobileSelect options={rangeOptions} onSelect={setRangeDateType} defaultValue={rangeDateType} />

        <MobileRangePicker
          value={dates}
          format="YYYY-MM-DD"
          onChange={handleSetDates}
          disabledDate={disabledTomorrowAndFuture}
        />

        <MobileSelect options={statusOptions} onSelect={setSelectedStatus} defaultValue={selectedStatus} />

        <Button
          disabled={!dates || !Array.isArray(dates)}
          onClick={generateReport}
          loading={isLoading}
          className="mobile-btn mobile-btn--yellow"
        >
          <FormattedMessage id="generate" defaultMessage="Generate" />
        </Button>
        <Button onClick={onCloseModal} loading={isLoading} className="mobile-btn mobile-btn--white">
          <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
        </Button>
      </div>
    </MobileModalWrapper>
  );
};
