import React, { useState, useEffect } from "react";
import { Button, Form, notification } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import PhoneInput from "react-phone-input-2";

import { phoneValidation } from "../../../../../utils/PhoneValidation";
import { MobileModalWrapper } from "../../../../../components/AdminPanel/Mobile/MobileModalWrapper";

import "./styles.less";
import { getPhoneInputCountryCode } from "../../../../../helpers/general";

export const PhoneModal = ({ visible, oldPhone, orderId, editPhone, fetchAccordingToTab, onClose }) => {
  const [phone, setPhone] = useState();
  const [form] = Form.useForm();
  const intl = useIntl();

  useEffect(() => {
    if (oldPhone) {
      form.setFields([{ name: "phone", value: oldPhone }]);
    }
  }, [oldPhone, form]);

  const onSave = async () => {
    try {
      await editPhone({ orderId, phoneNumber: `+${phone}` });
      await fetchAccordingToTab();
    } catch (e) {
      return notification.error({
        message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
        description:
          e?.body?.message ||
          `${intl.formatMessage({ id: "error.invalidPhoneNumber", defaultMessage: "Failed to edit phone number." })}`,
      });
    }

    setPhone(null);
    onClose();
  };

  const onChange = ({ phone }) => {
    setPhone(phone);
  };

  return (
    <MobileModalWrapper
      visible={visible}
      title={intl.formatMessage({ id: "tabPane.actions.editPhone", defaultMessage: "Edit Phone" })}
      onClose={onClose}
      footer={null}
    >
      <div className="mobile-confirm-modal-body phone-modal">
        <Form form={form} onValuesChange={onChange}>
          <Form.Item name="phone" rules={[{ required: true, validator: phoneValidation }]}>
            <PhoneInput preferredCountries={["qa", "jo"]} country={getPhoneInputCountryCode()} />
          </Form.Item>
        </Form>
        <Button
          onClick={onSave}
          disabled={!phone || oldPhone === `+${phone}`}
          className="mobile-btn mobile-btn--yellow"
        >
          <FormattedMessage id="tabPane.actions.confirm" defaultMessage="Confirm" />
        </Button>
        <Button onClick={onClose} className="mobile-btn mobile-btn--white">
          <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
        </Button>
      </div>
    </MobileModalWrapper>
  );
};
