import { notification } from "antd";

export const useHooks = ({ clearCombineJobsMap, clearJobsForCombine, deleteAllJobsJobsMap }) => {
  const clearAllJobs = () => {
    deleteAllJobsJobsMap()
      .then((res) => {
        clearJobsForCombine();
        clearCombineJobsMap();
        // fetchJobsMaps({}, { query: { status: statusQuery, ...getRetailerQueryPart() } }).then(() => {});
      })
      .catch((error) => notification.error({ message: "Error", description: error.body.message }));
  };

  return {
    clearAllJobs,
  };
};
