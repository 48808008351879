import React from "react";
import { ReactComponent as YellowBox } from "./assets/YellowBox.svg";
import { ReactComponent as CheckedBox } from "./assets/BoxChecked.svg";
import { ReactComponent as Truck } from "./assets/Truck.svg";
import { ReactComponent as Cash } from "./assets/Cash.svg";
import { ReactComponent as Paused } from "./assets/paused.svg";
import { ReactComponent as Returned } from "./assets/returns.svg";
import { ReactComponent as Canceled } from "./assets/canceled.svg";
import { Spin } from "antd";

const parseNull = (value) => {
  if (value === null) return 0;
  else return value;
};

export default function RetailerDashboardWidget({
  intl,
  stats,
  noHeader,
  isFixedDate,
  isExtended,
  isInsights,
  children,
  name,
}) {
  const dayTotal =
    Number(stats?.totalDelivered) +
    Number(stats?.totalCanceled) +
    Number(stats?.totalPostponed) +
    Number(stats?.totalReturned) +
    Number(stats?.totalNewOrders);
  return (
    <div className="retailerDashboardContainer">
      {!noHeader && (
        <h2>
          {`${intl.formatMessage({
            id: "page.home.navbar.hello",
            defaultMessage: "Hello",
          })}`}
          {name && `, ${name}`}!
        </h2>
      )}
      <div className="retailerDashboardFlex">
        <StatBox
          Icon={YellowBox}
          txt={`${intl.formatMessage({
            id: isFixedDate ? "page.home.navbar.totalOrdersDay" : "page.home.navbar.totalOrders",
            defaultMessage: "Total orders",
          })}`}
          data={dayTotal || dayTotal === 0 ? dayTotal : <Spin />}
        />
        <StatBox
          Icon={CheckedBox}
          txt={`${intl.formatMessage({
            id: "page.home.navbar.totalNew",
            defaultMessage: "Total New orders",
          })}`}
          data={stats.totalNewOrders || stats.totalNewOrders === 0 ? stats.totalNewOrders : <Spin />}
        />
        <StatBox
          Icon={CheckedBox}
          txt={`${intl.formatMessage({
            id: "page.home.navbar.totalDelivered",
            defaultMessage: "Total delivered orders",
          })}`}
          data={stats.totalDelivered || stats.totalDelivered === 0 ? stats.totalDelivered : <Spin />}
        />
        {isExtended && !isInsights && (
          <StatBox
            Icon={Paused}
            txt={`${intl.formatMessage({
              id: "page.home.navbar.totalPostponed",
              defaultMessage: "Total postponed orders",
            })}`}
            data={stats.totalPostponed || stats.totalPostponed === 0 ? stats.totalPostponed : <Spin />}
          />
        )}
        {isExtended && (
          <StatBox
            Icon={Canceled}
            txt={`${intl.formatMessage({
              id: "page.home.navbar.totalCanceled",
              defaultMessage: "Total canceled orders",
            })}`}
            data={stats.totalCanceled || stats.totalCanceled === 0 ? stats.totalCanceled : <Spin />}
          />
        )}
        {/* {isExtended && (
          <StatBox //TODO COD here
            Icon={Canceled}
            txt={`${intl.formatMessage({
              id: "page.home.navbar.totalCanceledOnDelivery",
              defaultMessage: "Total canceled on delivery orders",
            })}`}
            data={stats.totalCanceledOnDelivery || stats.totalCanceledOnDelivery === 0 ? stats.totalCanceledOnDelivery : <Spin />}//TODO !!!
          />
        )} */}
        {isExtended && (
          <StatBox
            Icon={Returned}
            txt={`${intl.formatMessage({
              id: "page.home.navbar.totalReturned",
              defaultMessage: "Total returned orders",
            })}`}
            data={stats.totalReturned || stats.totalReturned === 0 ? stats.totalReturned : <Spin />}
          />
        )}
        {isExtended && !isInsights && (
          <StatBox
            Icon={Truck}
            txt={`${intl.formatMessage({
              id: "page.home.navbar.totalTransit",
              defaultMessage: "Total Orders in Transit",
            })}`}
            data={stats.totalTransit || stats.totalTransit === 0 ? stats.totalTransit : <Spin />}
          />
        )}
        <StatBox
          Icon={Cash}
          txt={`${intl.formatMessage({
            id: "page.home.navbar.totalCash",
            defaultMessage: "Total cash on delivery collected $",
          })}`}
          data={
            stats.totalDeliveryCash || [null, 0].includes(stats.totalDeliveryCash) ? (
              parseNull(stats.totalDeliveryCash)
            ) : (
              <Spin />
            )
          }
        />
        {
          // uncomment this to display the API tokens
          //children
        }
      </div>
    </div>
  );
}

const StatBox = ({ Icon, txt, data }) => {
  return (
    <div className="statBox">
      <span className="svg-container">
        <Icon />
      </span>
      <div>
        <h3>{data}</h3>
        <span>{txt}</span>
      </div>
    </div>
  );
};
