export default (additionalInfo) => [
  {
    label: "Order reference",
    value: additionalInfo?.orderReference,
  },
  {
    label: "Unit",
    value: additionalInfo?.unit,
  },
  {
    label: "Goods description",
    value: additionalInfo?.goodsDesc,
  },
  {
    label: "Remarks",
    value: additionalInfo?.remarks,
  },
  {
    label: "No. of pcs",
    value: additionalInfo?.piecesNumber,
  },
  {
    label: "Shipper name",
    value: additionalInfo?.shipperName,
  },
];
