import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Layout, notification, Button, Table, Divider, Input } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { actions as RetailerPointsActions } from "../../../redux/resources/retailerPoints";
import tableColumns from "./columns";
import DrawerWrap from "./components/Drawer";
import CustomDivider from "../../../components/Divider";

import "./style.less";
import Modal from "antd/lib/modal/Modal";
import { getUserTenant } from "../../../helpers/authStorage";
import { mapCenters } from "../../../constants/config";

const MAX_PICKUP_LOCATION = 17; // this change was made to accomodate Chalhoub. Not sure why we have this limitation to start with.

const locationTemplate = {
  name: "new location",
  adress: "",
  x: 12.123,
  y: 45.123,
};

const RetailerPoints = ({
  isRegistration,
  getPoints,
  state,
  replacePoints,
  delPoints,
  sendPoints,
  shortened,
  getRestrictionsPoints,
}) => {
  const currentTenant = getUserTenant() ?? "Qatar";
  const defaultCenter = { lat: mapCenters[currentTenant].lat, lng: mapCenters[currentTenant].long };

  const { Content } = Layout;
  const intl = useIntl();
  const [locations, setLocations] = useState([]);
  const [restrictions, setRestrictions] = useState({
    areaLat: "0",
    areaLong: "0",
    areaRadius: 1000,
    areaRestriction: false,
  });

  const handleLocationsDataUpdate = () => {
    getPoints().then((res) => {
      setLocations(res.body);
    });
  };
  const getNewNameNum = () => {
    for (let i = 1; i < MAX_PICKUP_LOCATION + 1; i++) {
      let isAvailable = true;
      locations.forEach((e) => {
        if (e.name === locationTemplate.name + " " + i) {
          isAvailable = false;
        }
      });
      if (isAvailable) {
        return i;
      }
    }
    return "";
  };

  useEffect(() => {
    handleLocationsDataUpdate();
    getRestrictionsPoints().then(({ areaLat, areaLong, areaRadius, areaRestriction }) => {
      setRestrictions({ areaLat, areaLong, areaRadius, areaRestriction });
    });
    // eslint-disable-next-line
  }, []);

  const [selectedLocation, setSelectedLocation] = useState(defaultCenter);
  const [originalLocation, setOriginalLocation] = useState(undefined);

  const [isDrawerVisible, setDrawerVisible] = useState(false);

  const checkName = (loc) => {
    let res = 0;
    locations.forEach((e) => {
      if (e.name === loc.name && e.id !== loc.id) res++;
    });
    if (res > 0) return false;
    else return true;
  };

  const handleLocationDelete = (loc) => {
    delPoints(loc).then(() => handleLocationsDataUpdate());
    setDrawerVisible(false);
  };

  const handleLocationUpdate = (location) => {
    if (location.id && checkName(location)) {
      const updates = { id: location.id };
      for (let key in location) {
        if (location[key] && originalLocation[key] && location[key] !== originalLocation[key]) {
          updates[key] = location[key];
        } else if (location[key] && !originalLocation[key]) {
          updates[key] = location[key];
        }
      }
      replacePoints(updates).then(
        () => handleLocationsDataUpdate(),
        (e) => {
          if (e.statusCode === 403) {
            notification.error({
              message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
              description: e.body.message,
            });
          } else {
            notification.error({
              message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
              description: "Invalid location!",
            });
          }
          handleLocationsDataUpdate();
        },
      );
    }
    setDrawerVisible(false);
  };

  const handleLocationCreate = () => {
    if (locations.length < MAX_PICKUP_LOCATION) {
      const newLocation = { ...locationTemplate };
      sendPoints({ ...newLocation, name: newLocation.name + " " + getNewNameNum() })
        .then(() => handleLocationsDataUpdate())
        .catch((err) => {
          notification.error({
            message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
            description: `Unknown error : can't add another location point !`,
          });
        });
    } else {
      notification.error({
        message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
        description: `Can't add more than  ${MAX_PICKUP_LOCATION} locations!`,
      });
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [instructionsModalValue, setInstructionsModalValue] = useState("");
  const openInstructionsModal = (location) => (event) => {
    event.stopPropagation();
    setInstructionsModalValue(location);
    setIsModalVisible(true);
  };
  const handleSetInstructions = () => {
    replacePoints({
      ...instructionsModalValue,
      lat: Number(instructionsModalValue?.lat),
      long: Number(instructionsModalValue?.long),
    }).then(() => {
      setIsModalVisible(false);
      handleLocationsDataUpdate();
    });
  };
  const handleInstructionsModalInput = (e) =>
    setInstructionsModalValue((old) => ({ ...old, instructions: e.target.value }));

  return (
    <>
      {/* <Layout>
        <RetailerCabinetHeader /> */}
      <Content
        className="retailerCabinetContent retailerSettingsContent"
        style={{ paddingTop: 0, ...(shortened && { minHeight: "auto" }) }}
      >
        <h2 className="pageTitle">
          <FormattedMessage id="page.pickupPoints.title" defaultMessage="Pickup points" />
          {locations.length < MAX_PICKUP_LOCATION ? (
            <Button type="primary" className="createPickupButton" onClick={handleLocationCreate}>
              Create Location
            </Button>
          ) : null}
        </h2>
        <CustomDivider />
        <Divider />
        {locations ? (
          <div>
            <Table
              rowKey={(location) => location.id}
              columns={tableColumns(intl, openInstructionsModal)}
              dataSource={locations}
              pagination={false}
              onRow={(location) => ({
                onClick: () => {
                  if (!location?.main || isRegistration) {
                    setOriginalLocation(location);
                    setSelectedLocation(location);
                    setDrawerVisible(true);
                  } else {
                    notification.error({
                      message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
                      description: "Default pickup can only be changed from account settings!",
                    });
                  }
                },
              })}
              // rowClassName="driverTableItem"
            />
          </div>
        ) : null}

        <DrawerWrap
          restrictions={restrictions}
          handleLocationUpdate={handleLocationUpdate}
          handleLocationDelete={handleLocationDelete}
          setDrawerVisible={setDrawerVisible}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          isDrawerVisible={isDrawerVisible}
        />
        <Modal
          title="Edit instructions"
          visible={isModalVisible}
          onOk={handleSetInstructions}
          onCancel={() => setIsModalVisible(false)}
        >
          <Input.TextArea value={instructionsModalValue?.instructions} onChange={handleInstructionsModalInput} />
        </Modal>
      </Content>
      {/* </Layout> */}
    </>
  );
};

const MSTP = (state) => {
  return {
    locations: state.retailerPoints?.items,
    state,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(RetailerPointsActions, dispatch),
}))(RetailerPoints);
