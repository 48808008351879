/* eslint-disable */

import fetchIntercept from "fetch-intercept";
import { notification } from "antd";
import { API_URL } from "../constants/config";
import { refreshToken } from "./resources/changeUserToken";
import errorLogger from "../utils/errorLogging/error-logger";

export const fetchInterceptor = fetchIntercept.register({
  request: function (url, config) {
    return [url, config];
  },

  requestError: function (error) {
    errorLogger.report(`Redux Error ${error}`);
    return Promise.reject(error);
  },

  response: function (response) {
    // if (response.status === 401 && response.url.includes(API_URL)) {
    //   refreshToken().then((res) => {
    //     if (response.url.includes("orders") && response.url.includes("retailers")) {
    //       console.log("uploaded orders after refresh");
    //     } else window.location.reload();
    //   });
    // }

    if (response.status === 405) {
      let newResponse = { ...response };
      newResponse.status = 200;
      notification.error({
        message: "Error",
        description: "You have no permission to do this!",
      });
      return newResponse;
    }
    return response;
  },

  responseError: function (error) {
    return Promise.reject(error);
  },
});
