import React, { FC, useState } from "react";
import { Checkbox } from "antd";
import { StarOutlined } from "@ant-design/icons";
import { IJobsListProps } from "src/types/CombineJobs";
import JobDescription from "./JobDescription";
import "./style.less";

const JobsList: FC<IJobsListProps> = ({ selectedJobs, items, selectedStatus, onJobSelect }) => {
  const filteredItems =
    selectedStatus && selectedStatus !== "all"
      ? items.filter(({ status }: { status: string }) => status === selectedStatus)
      : items;

  const [showDetails, setShowDetails] = useState<boolean>(false);

  return (
    <Checkbox.Group
      onChange={onJobSelect}
      value={selectedJobs}
      className={items.length ? "jobsMapFilter" : "scrollHidden"}
      data-testid="cfr-job-checkbox-group"
    >
      {filteredItems?.length > 0 &&
        filteredItems.map((item: any) => (
          <div key={item.id} className="jobRadioWrapper" data-testid="cfr-job-wrapper">
            <Checkbox
              data-testid="cfr-job-checkbox"
              value={item.id}
              className={
                selectedJobs && selectedJobs.includes(item.id)
                  ? "jobRadioStyle"
                  : "jobRadioStyle jobRadioStyleNotSelected"
              }
            >
              <span>Job #{item.id}</span>
              <span>
                {item.status} {item.combined && <StarOutlined />}
              </span>
              <span onClick={() => setShowDetails(!showDetails)}>{showDetails ? "Hide" : "Show"}</span>
            </Checkbox>
            {showDetails && <JobDescription item={item} />}
          </div>
        ))}
    </Checkbox.Group>
  );
};

export default JobsList;
