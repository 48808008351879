import React from "react";

import additionalInfoFields from "../../../config/additionalInfoFields";
import "./orderAdditionalInformation.less";

const OrderAdditionalInfo = ({ additionalInfo }) => (
  <div className="additionalInfoWrapper">
    <p className="additionalInfoTitle"> Order details: </p>
    {additionalInfoFields(additionalInfo)
      .filter(({ value }) => value)
      .map(({ label, value }) => (
        <p className="additionalInfoItem">
          {label}: <span>{value}</span>
        </p>
      ))}
  </div>
);

export default OrderAdditionalInfo;
