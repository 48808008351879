import { CheckCircleFilled, ClockCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Badge, Card, Col, Divider, Row, Timeline } from "antd";
import React, { FC, useState } from "react";
import StatusTag from "src/components/StatusTag";
import { ProviderType } from "src/components/TrackingShipmentMap";
import "../styles.less";
const getColorByState = (state: string) => {
  switch (state) {
    case "FAILED":
      return "red";
    case "SUCCEEDED":
      return "green";
    default:
      return "blue";
  }
};

const getIconByState = (state: string) => {
  switch (state) {
    case "FAILED":
      return <CloseCircleFilled />;
    case "SUCCEEDED":
      return <CheckCircleFilled />;
    default:
      return <ClockCircleFilled />;
  }
};

const JobCard: FC<any> = ({ job, onCardClick, isSelectedJob }) => {
  const [selectedTask, setSelectedTask] = useState<any>(undefined);
  const handleSelectTask = (task: any) => {
    setSelectedTask(task);
    onCardClick(job, ProviderType.TASKS_TRACKER, task);
  };
  const taskCard = (task: any) => {
    return (
      <div className="task-wrapper" onClick={() => handleSelectTask(task)}>
        <p className="task-wrapper__header">
          {" "}
          <b>{task.type}</b> {task.name.split("/")?.[3]}
        </p>
      </div>
    );
  };

  return (
    <Col>
      <div
        data-testid="job-card-wrapper"
        onClick={() => onCardClick(job, ProviderType.VEHICLE_TRACKER, undefined)}
        className={isSelectedJob ? "job-card-wrapper job-card-wrapper--selected" : "job-card-wrapper"}
      >
        <Col>
          <Row justify="space-between" className="job-card-wrapper__header">
            <h3 className="job-card-wrapper__header--title">Job ID {job.id}</h3>
            <StatusTag status={job.status} />
          </Row>
          <Row className="job-card-wrapper__content">
            <Col>
              <p className="job-card-wrapper__content--title">Driver</p>
              <p className="job-card-wrapper__content--info">
                {`${job.driver?.profile?.firstName} ${job.driver?.profile?.lastName}` ?? "unassigned"}
              </p>
            </Col>
          </Row>
        </Col>
      </div>
      {job.hasTasks && isSelectedJob ? (
        <Timeline className="job-card-wrapper__tasks-list" data-testid="job-card-tasks-list">
          {job.tasks
            ?.sort((a: any, b: any) => {
              return a.pointOrder - b.pointOrder;
            })
            .map((task: any) => (
              <Timeline.Item
                key={task.name}
                color={getColorByState(task.taskOutcome)}
                dot={getIconByState(task.taskOutcome)}
                className="task-item"
              >
                {taskCard(task)}
              </Timeline.Item>
            ))}
        </Timeline>
      ) : (
        <></>
      )}
    </Col>
  );
};

export default JobCard;
