import React from "react";
import { Button } from "antd";

export const BulkActions = ({ selection, onDelete, disabled, children }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {children}
      <Button danger disabled={selection.length < 1 || disabled} onClick={onDelete}>
        Delete selected
      </Button>
    </div>
  );
};
