import React from "react";
import { DatePicker } from "antd";

import "./styles.less";

const { RangePicker } = DatePicker;

export const MobileRangePicker = ({ value, format, picker, onChange, disabledDate }) => {
  return (
    <RangePicker
      allowClear
      format={format}
      picker={picker}
      value={value}
      onChange={onChange}
      disabledDate={disabledDate}
      className="mobile-range-picker"
      dropdownClassName="mobile-range-picker-dropdown"
    />
  );
};
