import { notification } from "antd";
import { useState } from "react";
// import { notification } from "antd";

export const useHooks = ({
  queryDrivers,
  queryRetailers,
  setQueryDrivers,
  setQueryRetailers,
  getStatsRetailerAccounting,
  getStatsDriverAccounting,
  setLoading,
  loadAllRetailersAccounting,
  setCostDriverAccounting,
  setCostRetailerAccounting,
  loadAllDriversAccounting,
  loadRetailerAccounting,
  loadDriverAccounting,
  removeBracketAccounting,
  addBracketAccounting,
  changeBracketAccounting,
}) => {
  const [searchQuery, setSearchQuery] = useState("email");
  const [searchedText, setSearchedText] = useState("");
  const [currentList, setCurrentList] = useState("retailers");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [query, setQuery] = useState({ page: 1, limit: localStorage.getItem("driversOnPage") || 5, status: "" });
  const [loadedList, setLoadedList] = useState([{ firstName: "testName", phoneNumber: "000" }]);
  const [paginationData, setPaginationData] = useState({});
  const [profile, setProfile] = useState();
  const [accounting, setAccounting] = useState();
  const [stats, setStats] = useState();

  const fetchData = async (type) => {
    setLoading(true);
    if (searchedText) {
      if (type === "retailers") {
        await loadAllRetailersAccounting(
          {},
          { query: { [searchQuery]: searchedText, page: query.page, limit: query.limit } },
        ).then((res) => {
          setLoadedList(res.items);
          setPaginationData({
            currentPageNumber: res.pagination.currentPage,
            numItemsPerPage: res.pagination.itemsPerPage,
            totalCount: res.pagination.totalItems,
          });
        });
      } else {
        await loadAllDriversAccounting(
          {},
          { query: { [searchQuery]: searchedText, page: query.page, limit: query.limit } },
        ).then((res) => {
          setLoadedList(res.items);
          setPaginationData({
            currentPageNumber: res.pagination.currentPage,
            numItemsPerPage: res.pagination.itemsPerPage,
            totalCount: res.pagination.totalItems,
          });
        });
      }
    } else if (type === "retailers") {
      await loadAllRetailersAccounting({}, { query: { page: query.page, limit: query.limit } }).then((res) => {
        setLoadedList(res.items);
        setPaginationData({
          currentPageNumber: res.pagination.currentPage,
          numItemsPerPage: res.pagination.itemsPerPage,
          totalCount: res.pagination.totalItems,
        });
      });
    } else {
      await loadAllDriversAccounting({}, { query: { page: query.page, limit: query.limit } }).then((res) => {
        setLoadedList(res.items);
        setPaginationData({
          currentPageNumber: res.pagination.currentPage,
          numItemsPerPage: res.pagination.itemsPerPage,
          totalCount: res.pagination.totalItems,
        });
      });
    }
    setLoading(false);
  };

  const loadStatsWithDate = (id, range) => {
    if (currentList === "retailers") {
      getStatsRetailerAccounting(id, {
        query: { from: range[0].toISOString(true).substring(0, 23), to: range[1].toISOString(true).substring(0, 23) },
      }).then((res) => {
        setStats(res.body);
      });
    } else {
      getStatsDriverAccounting(id, {
        query: { from: range[0].toISOString(true).substring(0, 23), to: range[1].toISOString(true).substring(0, 23) },
      }).then((res) => {
        setStats(res.body);
      });
    }
  };

  const loadAccounting = (id) => {
    if (currentList === "retailers") {
      loadRetailerAccounting(id).then((res) => {
        setAccounting(res.body);
      });
      getStatsRetailerAccounting(id).then((res) => {
        setStats(res.body);
      });
    } else if (currentList === "drivers") {
      loadDriverAccounting(id).then((res) => {
        setAccounting(res.body);
      });
      getStatsDriverAccounting(id).then((res) => {
        setStats(res.body);
      });
    }
  };

  const onChangeTable = async (pagination, filters) => {
    const { current, pageSize } = pagination;
    const { status } = filters;
    setQuery({
      ...query,
      page: current,
      limit: pageSize,
      status: status ? status[0] : "",
    });
    if (pageSize !== query.limit) {
      localStorage.setItem("driversOnPage", pageSize);
    }
  };
  const onChangeList = (type) => {
    if (type === "retailers") {
      setQuery(queryRetailers);
      fetchData(type);
    } else {
      setQuery(queryDrivers);
      fetchData(type);
    }
    setCurrentList(type);
  };

  const handleSearch = async (e) => {
    setLoading(true);
    if (e.target.value) {
      setSearchedText(e.target.value);
      if (currentList === "retailers") {
        await loadAllRetailersAccounting(
          {},
          { query: { [searchQuery]: e.target.value, page: query.page, limit: query.limit } },
        );
      } else {
        await loadAllDriversAccounting(
          {},
          { query: { [searchQuery]: e.target.value, page: query.page, limit: query.limit } },
        );
      }
    } else {
      setSearchedText("");
      if (currentList === "retailers") {
        await loadAllRetailersAccounting({}, { query: { page: query.page, limit: query.limit } });
      } else {
        await loadAllDriversAccounting({}, { query: { page: query.page, limit: query.limit } });
      }
    }
    setLoading(false);
  };

  const handleRemoveBracket = async (driver, id) => {
    await removeBracketAccounting({ driverId: driver.id, bracketId: id });
    loadAccounting(driver.id);
  };
  const handleAddBracket = async (driver, bracket) => {
    await addBracketAccounting({ ...bracket, id: driver.id });
    loadAccounting(driver.id);
  };

  const handleChangeBracket = async (driver, bracket) => {
    await changeBracketAccounting({ id: bracket.id, cost: bracket.cost, driverId: driver.id });
    loadAccounting(driver.id);
  };
  const handleSetCost = async (accounting_, value) => {
    try {
      const newAccounting = { ...accounting, cost: value };
      if (currentList === "retailers") {
        await setCostRetailerAccounting({ ...newAccounting, id: profile.id });
      } else {
        await setCostDriverAccounting({ ...newAccounting, id: profile.id });
      }
      loadAccounting(profile.id);
    } catch {
      notification.error({
        message: "Error",
        description: "Cost not set!",
      });
    }
  };

  const changeAccountingType = async (accounting, value) => {
    const newAccounting = { ...accounting, type: value };

    if (currentList === "retailers") {
      await setCostRetailerAccounting({ ...newAccounting, id: profile.id });
    } else {
      await setCostDriverAccounting({ ...newAccounting, id: profile.id });
    }
    loadAccounting(profile.id);
  };

  const onChangeSearchFilter = (value) => setSearchQuery(value);

  return {
    query,
    profile,
    stats,
    accounting,
    setProfile,
    paginationData,
    drawerVisible,
    currentList,
    onChangeList,
    loadedList,
    setDrawerVisible,
    fetchData,
    onChangeTable,
    onChangeSearchFilter,
    handleSearch,
    loadAccounting,
    handleSetCost,
    loadStatsWithDate,
    changeAccountingType,
    handleRemoveBracket,
    handleAddBracket,
    handleChangeBracket,
  };
};
