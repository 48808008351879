import { notification } from "antd";
import { useState } from "react";
import { getQuery, getRetailerQueryPart } from "../helper";
import { IFetchAvailableOrders, IUseAvailableOrders } from "../../../types/CloudFleetRouting";

const useAvailableOrders: IUseAvailableOrders = (props) => {
  const { getAvailableOrdersJobsMap, fetchRestrictedListAdminRetailers, clearCFRStateJobsMap } = props;
  const [isOrdersLoading, setOrdersLoading] = useState<boolean>(false);

  const fetchAvailabeOrders = async ({ selectedRetailer, searchedAddress }: IFetchAvailableOrders) => {
    setOrdersLoading(true);
    try {
      const restrictedQuery = getRetailerQueryPart(selectedRetailer);
      const addressQuery = getQuery(searchedAddress, "address");
      await getAvailableOrdersJobsMap({}, { query: { ...restrictedQuery, ...addressQuery } });
    } catch (error) {
      notification.error({ message: "Opps! Unable to fetch restricted available orders" });
    } finally {
      setOrdersLoading(false);
    }
  };

  const fetchRestrictedRetailer = async () => {
    try {
      await fetchRestrictedListAdminRetailers();
    } catch (error) {
      notification.error({ message: "Opps! Unable to fetch restricted retailers" });
    }
  };

  const clearCFRState = async () => {
    try {
      await clearCFRStateJobsMap();
    } catch (error) {
      notification.error({ message: "Opps! Unable to clear CFR state" });
    }
  };

  return {
    isOrdersLoading,
    fetchAvailabeOrders,
    fetchRestrictedRetailer,
    clearCFRState,
  };
};

export default useAvailableOrders;
