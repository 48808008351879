import React, { useEffect, useLayoutEffect, useState } from "react";
import { notification, Button, Result, Tabs, Tooltip, Popover, Spin, Pagination } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import classnames from "classnames";
import { ShopOutlined, ArrowDownOutlined, ExclamationCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { FrownOutlined } from "@ant-design/icons";
import { ReactComponent as SelectedPackageSVG } from "../../../../assets/customer/packageSelected.svg";
// import { ReactComponent as ChatButtonIcon } from "../../../../assets/chatButton.svg";
import { ReactComponent as PackageSVG } from "../../../../assets/customer/package.svg";
import { ReactComponent as AttentionSVG } from "../../../../assets/customer/attention.svg";
import { getAuthData, getUserTenant } from "../../../../helpers/authStorage";
import { API_URL, isLocalhost, isStage } from "../../../../constants/config";
import ShareLocationMap from "./components/ShareLocationMap";
import TrackingSteps from "../TrackingSteps";
import ShareLocation from "../ShareLocation";
import TrackingInfoItem from "../TrackingInfoItem";
import { getQueryParam, replaceAt } from "../../../../helpers/general";
import { actions as customerActions } from "../../../../redux/resources/customer";
import DriverRating from "../DriverRating";
import FeedbackIsLeft from "../FeedbackIsLeft";
import Divider from "../../../../components/Divider";

import "./trackingInfoHome.less";
import PayModal from "./components/PayModal";
import { customFetch } from "../../../../utils/customFetch";

const { TabPane } = Tabs;

const AttentionTooltip = () => {
  return (
    <div className="noLocationTooltip">
      <FormattedMessage id="page.client.home.order.noLocation" defaultMessage="This order has no location..." />
    </div>
  );
};

const TrackingInfoHome = ({
  logInCustomer,
  fetchCustomers,
  leaveFeedbackCustomer,
  shareLocationCustomer,
  shareLocationBuildingCustomer,
  checkShareLocationAvailabilityCustomer,
  location,
  orders,
  fetchOrdersCustomer,
  selectedTab,
  setSelectedTab,
  // prevLocation,
}) => {
  const intl = useIntl();
  const [loginError, setLoginError] = useState("");
  const [isCustomLocationOpen, setIsCustomLocationOpen] = useState(false);
  const [tabPosition, setTabsPosition] = useState("left");
  const [stepsDirection, setStepsDirection] = useState("horizontal");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [canShareLocation, setCanShareLocation] = useState(false);
  const [isPayModalOpen, setIsPayModalOpen] = useState(false);
  const [isOrderRefreshing, setIsOrderRefreshing] = useState(false);
  const [paginationData, setPaginationData] = useState({ currentPageNumber: 1, numItemsPerPage: 25 });
  // const [isUsePreviousLocationModalOpen, setIsPreviousLocationModalOpen] = useState(false);
  // const toggleUsePreviousLocationModal = () => {
  //   setIsPreviousLocationModalOpen((prevState) => !prevState);
  // };

  // const onOpenDefaultMailClient = () => {
  //   window.location.href = "mailto:beebolt.support@example.com?subject=Support%20tracking%20info";
  // };

  function handleWindowSizeChange() {
    if (window.innerWidth <= 1024) {
      setTabsPosition("top");
    } else {
      setTabsPosition("left");
    }

    if (window.innerWidth <= 768) {
      setStepsDirection("vertical");
      setIsMobile(true);
    } else {
      setIsMobile(false);
      setStepsDirection("horizontal");
    }
  }

  useLayoutEffect(() => {
    const layout = document.querySelector(".ant-layout");
    layout.classList.add("customer_cabinet");

    window.addEventListener("resize", handleWindowSizeChange);
    handleWindowSizeChange();

    return () => {
      layout.classList.remove("customer_cabinet");
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const onOpenPayModal = () => {
    setIsPayModalOpen(true);
  };
  const onClosePayModal = async (id) => {
    setIsOrderRefreshing(true);
    try {
      await fetchOrdersCustomer();
      setIsOrderRefreshing(false);
    } catch (e) {
      notification.error({ description: "Try refreshing page", message: "Error" });
      setIsOrderRefreshing(false);
    }
    setIsPayModalOpen(false);
  };

  const checkLocationLimit = (id) => {
    checkShareLocationAvailabilityCustomer({ orderId: id }).then(
      (res) => setCanShareLocation(true),
      (e) => setCanShareLocation(false),
    );
  };

  useEffect(() => {
    if (selectedTab) {
      checkLocationLimit(Number(selectedTab));
    }
    // eslint-disable-next-line
  }, [selectedTab, isCustomLocationOpen]);

  useEffect(() => {
    async function handleLogin(customerToken) {
      try {
        setIsOrderRefreshing(true);
        await logInCustomer({ token: customerToken });
        const res = await fetchOrdersCustomer(
          {},
          { limit: paginationData.numItemsPerPage, page: paginationData.currentPageNumber },
        );
        if (res.items.length) {
          setSelectedTab(res.items[0].id);
        }
        setPaginationData({
          currentPageNumber: res.pagination.currentPageNumber,
          numItemsPerPage: res.pagination.numItemsPerPage,
          totalCount: res.pagination.totalCount,
        });
        setIsOrderRefreshing(false);
      } catch (e) {
        setLoginError(e.body);
        setIsOrderRefreshing(false);
      }
    }

    const queryParams = getQueryParam(location.search);
    const customerToken = queryParams?.token;
    setLoginError("");
    if (customerToken) {
      handleLogin(customerToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCustomers, fetchOrdersCustomer, logInCustomer, location, setSelectedTab]);

  const [buildingNumExists, setBuildingNumExists] = useState(false);

  const toggleCustomLocationModal = (buildingNum) => () => {
    const contentWrapper = document.querySelector(".trackingInfoContent");
    if (isCustomLocationOpen) {
      contentWrapper.classList.remove("mapOpen");
    } else {
      contentWrapper.classList.add("mapOpen");
    }
    setIsCustomLocationOpen((prevState) => !prevState);
    setBuildingNumExists(!!buildingNum);
  };

  const loadOrdersData = async (page, pageSize) => {
    try {
      setIsOrderRefreshing(true);
      const res = await fetchOrdersCustomer({}, { query: { page: page, limit: pageSize } });
      if (res.items.length) {
        setSelectedTab(res.items[0].id);
      }
      setPaginationData({
        currentPageNumber: res.pagination.currentPageNumber,
        numItemsPerPage: res.pagination.numItemsPerPage,
        totalCount: res.pagination.totalCount,
      });
      setIsOrderRefreshing(false);
    } catch (e) {
      setIsOrderRefreshing(false);
    }
  };
  // const onOpenDefaultMailClient = () => {
  //   window.location.href = "mailto:beebolt.support@example.com?subject=Support%20tracking%20info";
  // };

  const openNotification = (description, type = "open") => {
    notification[type]({
      message: `${intl.formatMessage({
        id: "page.step.address&Instructions.thanksForSharing",
        defaultMessage: "Thank you for sharing your location!",
      })}`,
      description,
    });
  };

  // const shareLocation = async () => {
  //   const notificationDescription = (
  //     <div>
  //       <p>Latitude: + ${prevLocation.lat} </p>
  //       <p>Longitude: + ${prevLocation.long}`</p>
  //     </div>
  //   );

  //   try {
  //     await shareLocationCustomer({ ...prevLocation, orderId: selectedTab });
  //     fetchOrdersCustomer();
  //     openNotification(notificationDescription);
  //   } catch (e) {
  //     openNotification(e.body.message, "error");
  //   } finally {
  //     toggleUsePreviousLocationModal();
  //   }
  // };

  const downloadReceipt = async () => {
    try {
      const response = await customFetch(`${API_URL}/customers/self/orders/${selectedTab}/receipt`, {
        method: "GET",
        cache: "no-cache",
        headers: {
          Authorization: `Bearer ${getAuthData().accessToken}`,
          Tenant: getUserTenant(),
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      });
      if (!response.ok) {
        const data = await response.json();
        notification.error({
          message: "Error",
          description: data.message ? data.message : "Error on downloading the receipt.",
        });
        return;
      }

      const blob = await response.blob();
      const newBlob = new Blob([blob]);

      const blobUrl = window.URL.createObjectURL(newBlob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", "receipt.pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      window.URL.revokeObjectURL(blob);
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.message ? error.message : "Error on downloading the receipt.",
      });
    }
  };

  const leaveDeliveryFeedbackCustomer = async ({ values, jobId, deliveryPointId }) => {
    try {
      await leaveFeedbackCustomer({ jobId, deliveryPointId, ...values });
      await loadOrdersData(paginationData.currentPageNumber, paginationData.numItemsPerPage);
    } catch (e) {
      openNotification(e.body.message, "error");
    }
  };

  if (loginError) {
    return <Result status="403" title={loginError.error} subTitle={loginError.message} />;
  }

  if (isCustomLocationOpen) {
    return (
      <ShareLocationMap
        isMobile={isMobile}
        onFetchOrdersCustomer={fetchOrdersCustomer}
        selectedOrderId={selectedTab}
        openNotification={openNotification}
        onToggleCustomLocationModal={toggleCustomLocationModal}
        shareLocationCustomer={shareLocationCustomer}
        shareLocationBuildingCustomer={shareLocationBuildingCustomer}
        buildingNumNeeded={!buildingNumExists}
      />
    );
  }

  const isPaymentEnabled = isLocalhost || isStage;

  return (
    <div className="trackingInfoPageWrapper">
      <div className="nav-tabs-suptitle">
        <FormattedMessage id="page.client.home.title" defaultMessage="Your orders" />
      </div>
      <Spin spinning={isOrderRefreshing}>
        <Pagination
          size="small"
          total={paginationData.totalCount || 0}
          current={paginationData.currentPageNumber}
          pageSize={paginationData.numItemsPerPage}
          onChange={(page, pageSize) => {
            loadOrdersData(page, pageSize);
          }}
          /* 
          KEEP : in case we want to change number of items per page
          showSizeChanger
          pageSizeOptions={["1", "2", "5", "10", "25"]}
          onShowSizeChange={(size) => {
            loadOrdersData(paginationData.currentPageNumber, size);
          }} */
        />
        <br />{" "}
        <Tabs
          tabBarGutter={24}
          tabPosition={tabPosition}
          activeKey={String(selectedTab)}
          onChange={(tabNumber) => setSelectedTab(tabNumber)}
        >
          {orders?.map((order) => {
            const time = order?.deliveryTime;
            const deliveryTime = time
              ? replaceAt(time, time.indexOf(" "), "\xa0\xa0")
              : !order?.isReturn
              ? `${intl.formatMessage({
                  id: "page.client.home.order.timingsUpdate",
                  defaultMessage: "Delivery timings will be updated once the package has been picked up",
                })}`
              : `${intl.formatMessage({
                  id: "page.client.home.order.noDeliveryTime",
                  defaultMessage: "No delivery time",
                })}`;
            const isOrdersCompleted = order.status === "completed";
            const showDriversRating = isOrdersCompleted && !order.feedbackLeft;
            const showAfterFeedback = order.feedbackLeft;
            const warnUser = () => {
              notification.warn({
                className: "notificationWarning",
                message: canShareLocation
                  ? `${intl.formatMessage({
                      id: "page.client.home.share.error.driver",
                      defaultMessage: "Your order is already with the driver",
                    })}`
                  : `${intl.formatMessage({
                      id: "page.client.home.share.error.limit",
                      defaultMessage: "Order location update limit exceeded",
                    })}`,
                description: `${intl.formatMessage({
                  id: "page.client.home.share.error.contact",
                  defaultMessage: "To change your location please contact customer service",
                })}`,
                icon: <FrownOutlined style={{ color: "yellow" }} />,
              });
            };

            const orderType = order?.isReturn ? (
              <strong>R</strong>
            ) : order?.isStoreToStore ? (
              <>
                <ShopOutlined />
                <ArrowDownOutlined />
                <ShopOutlined />
              </>
            ) : (
              ""
            );

            const buildingNum = String(
              [
                order.deliveryPoint?.building,
                order.deliveryPoint?.initialAddress?.building,
                order.customerInfo?.building,
                order.customerInfo?.initialAddress?.building,
              ].find((e) => e || e === 0),
            );

            return (
              <TabPane
                tab={
                  <div className="tabButton">
                    <div>{order.id === Number(selectedTab) ? <SelectedPackageSVG /> : <PackageSVG />}</div>
                    <div className="tabInfo">
                      <div>
                        <span>
                          <FormattedMessage id="page.client.home.order.title" defaultMessage="Order #" />
                          {order.id}
                        </span>
                        {!order.locationShared && (
                          <Tooltip placement="right" color="#EB5757" title={<AttentionTooltip />}>
                            <AttentionSVG />
                          </Tooltip>
                        )}
                      </div>
                      <div className="tabAddress">
                        {order.deliveryPoint?.address ??
                          order.deliveryPoint?.initialAddress?.address ??
                          `${intl.formatMessage({
                            id: "page.client.home.order.noAddress",
                            defaultMessage: "no address provided",
                          })}`}
                      </div>
                    </div>
                    <div className="order-type-icon">{orderType}</div>
                  </div>
                }
                key={order.id}
              >
                {showDriversRating && (
                  <DriverRating
                    jobId={order.job.id}
                    deliveryPointId={order.deliveryPoint.id}
                    leaveDeliveryFeedbackCustomer={leaveDeliveryFeedbackCustomer}
                  />
                )}
                {showAfterFeedback && <FeedbackIsLeft />}
                {!showDriversRating && !showAfterFeedback && (
                  <div
                    className={classnames({ packageContentContainer: true, locationNotShared: !order.locationShared })}
                  >
                    <div className={classnames({ packageMainContent: true, locationNotShared: !order.locationShared })}>
                      <h2 className="signUpFormTitle">
                        <FormattedMessage
                          id="page.client.home.order.title.extended"
                          defaultMessage="Tracking Info: Order #"
                        />
                        {order.id}
                      </h2>
                      <Divider />
                      {order.isReturn && (
                        <div className="additionalFunctionalityButtonsContainer">
                          <Button
                            type="primary"
                            shape="circle-outline"
                            className="shareLocationButtonStyle"
                            onClick={downloadReceipt}
                          >
                            <FormattedMessage id="page.client.home.order.downloadForm" defaultMessage="Download Form" />
                          </Button>
                        </div>
                      )}
                      <TrackingSteps
                        stepsDirection={stepsDirection}
                        currentActiveStep={order?.status}
                        locationShared={order?.locationShared}
                        isReturn={order?.isReturn}
                        isStoreToStore={order?.isStoreToStore}
                      />
                      <div key={order.trackingId}>
                        <div className="trackingNumberDate">
                          <TrackingInfoItem
                            title={`${intl.formatMessage({
                              id: "page.client.home.order.trackingNum",
                              defaultMessage: "Your tracking number",
                            })}`}
                            value={
                              order.trackingId
                                ? order.trackingId
                                : `${intl.formatMessage({
                                    id: "shared.noData",
                                    defaultMessage: "No data",
                                  })}`
                            }
                          />
                          <TrackingInfoItem
                            title={
                              order?.isReturn
                                ? `${intl.formatMessage({
                                    id: "tabPane.pickupTime",
                                    defaultMessage: "Pick up time",
                                  })}`
                                : `${intl.formatMessage({
                                    id: "tabPane.deliveryTime",
                                    defaultMessage: "Delivery time",
                                  })}`
                            }
                            value={deliveryTime}
                            // placeholderValue={"Delivery timings will be updated once the package has been picked up"}
                          />
                          <TrackingInfoItem
                            title={
                              <>
                                <FormattedMessage id="retailer" defaultMessage="Retailer" />{" "}
                                <Popover
                                  content={
                                    <>
                                      {`${intl.formatMessage({
                                        id: "page.client.home.order.contactSupport",
                                        defaultMessage: "Contact retailer's customer support at",
                                      })}  ${order?.tenant?.supportNumber}`}
                                      <br />
                                      <small>
                                        <FormattedMessage
                                          id="page.client.home.order.pleaseCheck"
                                          defaultMessage="Please make sure to check"
                                        />{" "}
                                        <Link to={`/tracking_info/faq${location?.search}`}>FAQ</Link>
                                      </small>
                                    </>
                                  }
                                  title={`${intl.formatMessage({
                                    id: "page.trackingInfo.faqQuestions.pageHeader",
                                    defaultMessage: "Need help?",
                                  })}`}
                                >
                                  <ExclamationCircleOutlined />
                                </Popover>
                              </>
                            }
                            value={order.retailer?.name}
                          />
                          <TrackingInfoItem
                            title={`${intl.formatMessage({
                              id: "shared.cashOnDelivery",
                              defaultMessage: "Cash on delivery",
                            })}`}
                            value={order.cashAmount ?? 0}
                          />
                          <TrackingInfoItem
                            title={
                              order?.isReturn
                                ? `${intl.formatMessage({
                                    id: "shared.pickupLocation",
                                    defaultMessage: "Pick up location",
                                  })}`
                                : `${intl.formatMessage({
                                    id: "shared.deliveryLocation",
                                    defaultMessage: "Delivery location",
                                  })}`
                            }
                            value={
                              order.deliveryPoint?.address ??
                              order.deliveryPoint?.initialAddress?.address ??
                              `${intl.formatMessage({
                                id: "page.client.home.order.noAddress",
                                defaultMessage: "no address provided",
                              })}`
                            }
                            additionalInfo={
                              order.deliveryPoint?.address &&
                              `${intl.formatMessage({
                                id: "page.client.home.order.onlyUntil",
                                defaultMessage: "You can change your delivery location only until 11:00AM",
                              })}`
                            }
                          />
                          <TrackingInfoItem
                            title={`${intl.formatMessage({
                              id: "input.building",
                              defaultMessage: "Building",
                            })}`}
                            value={
                              buildingNum ||
                              `${intl.formatMessage({
                                id: "page.client.home.order.noBuilding",
                                defaultMessage: "No building provided",
                              })}`
                            }
                            // additionalInfo={
                            //   order.deliveryPoint?.address && "You can change your delivery location only until 11:00AM"
                            // }
                          />

                          {/*Number(order.cashAmount) > 0 && isPaymentEnabled && (
                            <Button
                              type="primary"
                              style={{
                                marginTop: 15,
                                ...(order.isPaid && {
                                  backgroundColor: "lightgreen",
                                  border: "none",
                                  color: "white",
                                  boxShadow: "0px 2px 8px lightgreen",
                                }),
                              }}
                              onClick={!order.isPaid && onOpenPayModal}
                              // onClick={disabled ? warnUser(limitExceeded) : onOpenModal}
                              // icon={<SharearrowIcon className="shareArrowIconStyle" />}
                              className="shareLocationButtonStyle"
                            >
                              {!order.isPaid ? "Pay for the order" : "Order is paid"}
                            </Button>
                          )*/}
                        </div>
                      </div>
                    </div>
                    <div
                      className={classnames(
                        "packageShareLocationContent",
                        !order.locationShared && "packageShareLocationContent--top",
                      )}
                    >
                      <ShareLocation
                        isReturn={order?.isReturn}
                        clsName={!order.locationShared ? "locationNotShared" : ""}
                        onOpenModal={toggleCustomLocationModal(buildingNum)}
                        disabled={["in_transit", "in_delivery"].includes(order?.status) || !canShareLocation}
                        warnUser={warnUser}
                      />
                      {isPayModalOpen && <PayModal onClosePayModal={onClosePayModal} order={order} />}
                      <div className="supportIconWrapper">
                        <div className="supportButtonWrapper">
                          {/* <Button
                          type="primary"
                          onClick={() => onOpenDefaultMailClient()}
                          shape="circle-outline"
                          icon={<ChatButtonIcon style={{ marginTop: "10px" }} />}
                          style={{ width: "80px", height: "80px" }}
                        /> */}

                          <Button
                            type="primary"
                            onClick={() => (document.location.href = `/tracking_info/faq${location?.search}`)}
                            shape="circle-outline"
                            style={{
                              width: "80px",
                              height: "80px",
                              color: "black",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              boxShadow: "grey 0px 2px 5px",
                            }}
                          >
                            <QuestionCircleOutlined style={{ fontSize: "2.2rem" }} />
                            <strong>
                              <FormattedMessage id="Help" defaultMessage="Help" />
                            </strong>
                          </Button>
                          {/* {!order.locationShared && prevLocation && (
                      <div className="additionalFunctionalityButtonsContainer">
                        <Button
                          type="primary"
                          shape="circle-outline"
                          className="shareLocationButtonStyle"
                          onClick={toggleUsePreviousLocationModal}
                        >
                          Use previous location
                        </Button>
                      </div>
                    )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </TabPane>
            );
          })}
        </Tabs>
      </Spin>
      {/* <div
        className={
          isUsePreviousLocationModalOpen ? "usePreviousLocationModalWrapper" : "usePreviousLocationModalWrapperHidden"
        }
      >
        <div className="usePreviousLocationModal">
          <div className="modalText">Do you want to use your previous location?</div>
          <div className="buttonBox">
            <Button key="back" onClick={toggleUsePreviousLocationModal}>
              No
            </Button>
            <Button key="submit" type="primary" onClick={shareLocation}>
              Yes
            </Button>
          </div>
        </div>

        </div> */}
    </div>
  );
};

const MSTP = (state) => {
  return {
    orders: state.customerInfo.orders,
    // prevLocation: state.customerInfo.prevLocation,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(customerActions, dispatch),
}))(TrackingInfoHome);
