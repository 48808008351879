import { createResource } from "redux-rest-resource";
import { setAuthHeader, clearAuthData, getAuthData, saveAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";
import setResourceToken from "./config";

const withSaveTokens = (res) => {
  saveAuthData(res.body.tokens, "driver");
  setResourceToken(res.body.tokens.accessToken);
  return {
    res,
    body: res.body,
  };
};

export const { types, actions, rootReducer } = createResource({
  name: "driver",
  url: `${API_URL}/auth/drivers/registration`,

  actions: {
    signup: {
      method: "POST",
    },
    confirmEmail: {
      method: "GET",
      url: `${API_URL}/auth/drivers/registration/confirm-email`,
      transformResponse: withSaveTokens,
    },
    getSelf: {
      method: "GET",
      url: `${API_URL}/drivers/self`,
      reduce: (state, action) => {
        if (action.status !== "resolved") {
          return state;
        }
        return { ...state, selfInfo: action.body };
      },
    },
    putSelfProfile: {
      method: "PUT",
      url: `${API_URL}/drivers/self/profile`,
      reduce: (state, action) => {
        const updatedDriver = { ...state.items, ...action.context };
        return { ...state, items: updatedDriver };
      },
    },
    putSelfCar: {
      method: "PUT",
      url: `${API_URL}/drivers/self/car`,
      reduce: (state, action) => {
        const updatedDriver = { ...state.items, ...action.context };
        return { ...state, items: updatedDriver };
      },
    },
    putBankDetails: {
      method: "PUT",
      url: `${API_URL}/drivers/self/bank-details`,
      reduce: (state, action) => {
        const updatedDriver = { ...state.items, ...action.context };
        return { ...state, items: updatedDriver };
      },
    },

    login: {
      method: "POST",
      url: `${API_URL}/auth/drivers/login`,
      transformResponse: (res) => {
        return withSaveTokens(res);
      },
    },

    logout: {
      isPure: true,
      reduce: (state) => {
        clearAuthData();
        setAuthHeader(null);

        return {
          ...state,
          item: null,
        };
      },
    },
  },
});

setAuthHeader(getAuthData().accessToken);
