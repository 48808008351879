import { useState } from "react";
import { notification } from "antd";
import { EditActionProps, EditPriceSetting } from "../types";

export function useEditAction({
    updateOneAdminPriceManagement,
    handleFetchCompanyPrices
} : EditActionProps) {
    const [editedSetting , setEditedSetting ] = useState<EditPriceSetting | null>(null);

    const IsEditing = (id:number) => {
      return editedSetting?.id === id;
    }

    const handleSavePriceSetting = async () => {
      try {
        await  updateOneAdminPriceManagement({
                  priceId:editedSetting?.id,
                  initialDeliveryFee: editedSetting?.initialDeliveryFee,
                  maxKmLimit: editedSetting?.maxKmLimit,
                  additionalCostPerKm: editedSetting?.additionalCostPerKm,
                });
        notification.success({
          message: "Success",
          description: "Price Setting updated",
        });
        handleFetchCompanyPrices();
        setEditedSetting(null);
      } catch (error) {
        notification.error({
          message: "Error",
          description: "Setting was not updated!",
        });
        setEditedSetting(null);
      }
    }

  return {
    // states
    editedSetting,
    // functions
    IsEditing,
    setEditedSetting,
    handleSavePriceSetting
  };
}