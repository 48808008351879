import React, { useState } from "react";
import { Radio } from "antd";

import { MobileSelect } from "../../../../../components/AdminPanel/Mobile/MobileSelect";
import { radioButtons, REPORT_TYPE_ENUM } from "../../../system-report-config";

export const ReportConfig = ({ query, driversList, retailersList, setQuery, onSelect }) => {
  const [reportType, setReportType] = useState(REPORT_TYPE_ENUM.overall);

  const changeReportType = (e) => {
    setReportType(e.target.value);
    e.target.value === REPORT_TYPE_ENUM.overall && setQuery && setQuery({});
  };

  const isReportTypeDriver = reportType === REPORT_TYPE_ENUM.driver;
  const isReportTypeRetailer = reportType === REPORT_TYPE_ENUM.retailer;

  const driversOptions = [{ text: "All", value: null }].concat(
    driversList?.map(({ id, profile: { firstName, lastName } }) => ({
      text: `${firstName} ${lastName}`,
      value: id,
    })),
  );
  const retailersOptions = [{ text: "All", value: null }].concat(
    retailersList?.map(({ id, name }) => ({ text: name, value: id })),
  );

  return (
    <div className="mobile-report-config">
      <Radio.Group
        defaultValue={reportType}
        buttonStyle="solid"
        onChange={changeReportType}
        className="mobile-report-config__radio"
      >
        {radioButtons.map(({ value, title }) => (
          <Radio.Button value={value}>{title}</Radio.Button>
        ))}
      </Radio.Group>
      {isReportTypeDriver && (
        <MobileSelect
          options={driversOptions}
          value={query.driverId || null}
          onSelect={onSelect("driverId", setQuery)}
        />
      )}
      {isReportTypeRetailer && (
        <MobileSelect
          options={retailersOptions}
          value={query.retailerId || null}
          onSelect={onSelect("retailerId", setQuery)}
        />
      )}
    </div>
  );
};
