import React from "react";

import { MobileTableRows } from "../MobileTableRows";

export const MobileTableCollapseRows = ({ title, rows }) => {
  return (
    <div className="expand-body-item">
      {title && <div className="expand-body-item__title">{title}</div>}
      {rows && <MobileTableRows rows={rows} />}
    </div>
  );
};
