import React, { useState, useEffect } from "react";
import { Button, Form, notification, Popover } from "antd";
import { useIntl } from "react-intl";
import PhoneInput from "react-phone-input-2";

import { phoneValidation } from "../../../../utils/PhoneValidation";
import { getPhoneInputCountryCode } from "../../../../helpers/general";

export const PhonePopover = ({ oldPhone, orderId, editPhone, fetchAccordingToTab }) => {
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [editedPhone, setEditedPhone] = useState(null);
  const [form] = Form.useForm();
  const intl = useIntl();

  const setInitialPhone = () => {
    form.setFields([{ name: "phone", value: oldPhone }]);
  };

  useEffect(() => {
    if (oldPhone) {
      setInitialPhone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldPhone]);

  const savePhone = async () => {
    try {
      await editPhone({ orderId, phoneNumber: `+${editedPhone}` });
      await fetchAccordingToTab();
    } catch (e) {
      return notification.error({
        message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
        description:
          e?.body?.message ||
          `${intl.formatMessage({ id: "error.invalidPhoneNumber", defaultMessage: "Failed to edit phone number." })}`,
      });
    }

    setEditedPhone(null);
    setIsEditingPhone(false);
  };

  const onChangePhone = ({ phone }) => {
    setEditedPhone(phone);
  };

  const openEditingPhone = (bool) => () => {
    setIsEditingPhone(bool);

    if (!bool) {
      setTimeout(() => setInitialPhone(), 200);
    }
  };

  const onVisibleChange = (bool) => {
    setIsEditingPhone(bool);
  };

  const preventDefault = (e) => {
    e.preventDefault();
  };

  return (
    <Popover
      content={
        <div onClick={preventDefault} className="phone-popup-content">
          <Form form={form} onValuesChange={onChangePhone}>
            <Form.Item name="phone" rules={[{ required: true, validator: phoneValidation }]}>
              <PhoneInput preferredCountries={["qa", "jo"]} country={getPhoneInputCountryCode()} />
            </Form.Item>
          </Form>
          <div className="phone-popup-content__btn-group">
            <Button onClick={savePhone} disabled={!editedPhone || oldPhone === `+${editedPhone}`}>
              Save
            </Button>
            <Button onClick={openEditingPhone(false)}>Cancel</Button>
          </div>
        </div>
      }
      trigger="click"
      visible={isEditingPhone}
      onVisibleChange={onVisibleChange}
    >
      {oldPhone ? (
        <div onClick={openEditingPhone(true)} style={{ cursor: "pointer" }}>
          {oldPhone}
        </div>
      ) : (
        <Button onClick={openEditingPhone(true)}>Add Phone</Button>
      )}
    </Popover>
  );
};
