import React from "react";
import { Divider as DividerAnt } from "antd";
import { Link } from "react-router-dom";

import bbboltLogo from "../../assets/bbboltLogo.png";
import Divider from "../../components/Divider";
import { ReactComponent as DriverLogo } from "../../assets/signupLogo.svg";
import HomePageBtn from "./components/HomePageBtn";
import arrowRight from "../../assets/arrow-right.png";
import "./style.less";
// import { CLIENT_TOKEN } from "../../constants/config";

const HomePage = () => (
  <div className="signupWrapper">
    <div className="signUpLogoWrapper">
      <Link to="/">
        <img src={bbboltLogo} alt="bbboltLogo" width="30px" />
      </Link>
      <h1 className="driverRegistrationTitle">Choose your role</h1>
      <Divider classname="dividerLine" />
      <DriverLogo />
    </div>

    <div className="signUpFormWrapper">
      <div className="logInReference">
        <Link to="/admin_login">
          <span className="loginTitle">Admin Log In</span>
        </Link>
        <img src={arrowRight} alt="Arrow right" />
      </div>

      <div className="homeButtonsWrapper">
        <div className="signUpFormTitleWrapper">
          <h2 className="signUpFormTitle">You could be the ...</h2>
          <Divider classname="signUpFormDivider" />
        </div>
        <div>
          <DividerAnt orientation="left" className="roleTitle">
            Driver
          </DividerAnt>
          <div className="buttonWrapper">
            {/* <HomePageBtn path='/driver_login' text='Log In'/> */}
            <HomePageBtn path="/driver_signup" text="Sign Up" />
          </div>
          <DividerAnt orientation="left" className="roleTitle">
            Retailer
          </DividerAnt>
          <div className="buttonWrapper">
            <HomePageBtn path="/retailer_login" text="Log In" />
            <HomePageBtn path="/retailer_signup" text="Sign Up" />
          </div>
          <DividerAnt orientation="left" className="roleTitle">
            Customer
          </DividerAnt>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "125px" }}>
            {/* <HomePageBtn
              path={`/tracking_info/home?token=${CLIENT_TOKEN}`}
              width="260px"
              text="Customer page (completed orders)"
            />
            <HomePageBtn
              path={`/tracking_info/home?token=${CLIENT_TOKEN}`}
              width="260px"
              text="Customer page (in proccess)"
            /> */}
            <HomePageBtn path="/" width="260px" text="Landing Page" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HomePage;
