import React, { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { IoHome, IoTicketSharp } from "react-icons/io5";
import { IoIosMan } from "react-icons/io";
import { BsBuildingsFill } from "react-icons/bs";
import { MdStoreMallDirectory, MdOutlineEmojiFlags } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";
import { FaBoxes, FaFileInvoice } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { SiLiquibase } from "react-icons/si";
import { RiShieldUserFill } from "react-icons/ri";
import { PiFlagFill } from "react-icons/pi";
 
const SuperAdminSideBar = () => {
  const history = useHistory();

  useEffect(() => {
    const authTokenSuper = localStorage.getItem("authTokenSuper");

    if (!authTokenSuper) {
      history.push("/super_admin/login");
    }
  }, [history]);

  const [collapsedSide, setCollapsedSide] = useState(true);
  return (
    <Sidebar className="superAdminSideBar" collapsed={collapsedSide}>
      <Menu onMouseEnter={() => setCollapsedSide(false)} onMouseLeave={() => setCollapsedSide(true)}>
        <MenuItem component={<Link to="/super_admin/dashboard" />} icon={<IoHome size={20} />}>
          Dashboard
        </MenuItem>
        <MenuItem component={<Link to="/super_admin/drivers" />} icon={<IoIosMan size={20} />}>
          Drivers
        </MenuItem>
        <MenuItem component={<Link to="/super_admin/companies" />} icon={<BsBuildingsFill size={20} />}>
          Companies
        </MenuItem>
        <MenuItem component={<Link to="/super_admin/retailers" />} icon={<MdStoreMallDirectory size={20} />}>
          Retailers
        </MenuItem>
        <MenuItem component={<Link to="/super_admin/orders" />} icon={<FaBoxes size={20} />}>
          Orders
        </MenuItem>
        <MenuItem component={<Link to="/super_admin/users" />} icon={<RiShieldUserFill size={20} />}>
          Users
        </MenuItem>

        <MenuItem component={<Link to="/super_admin/subscriptions" />} icon={<IoTicketSharp size={20} />}>
          Subscriptions
        </MenuItem>

        <MenuItem component={<Link to="/super_admin/plans" />} icon={<SiLiquibase size={20} />}>
          Plans
        </MenuItem>
      
        <MenuItem component={<Link to="/super_admin/invoices" />} icon={<FaFileInvoice size={20} />}>
        Invoices
        </MenuItem>

        <MenuItem component={<Link to="/super_admin/countries" />} icon={<PiFlagFill size={20} />}>
          Countries
        </MenuItem>

        <MenuItem component={<Link to="/super_admin/login" />} icon={<AiOutlineLogout size={20} color="red" />}>
          Logout
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default SuperAdminSideBar;
