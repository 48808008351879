import React , { FC } from "react";
import { Typography, Row, Switch } from 'antd';
import "./style.less";
import { NotificationHeaderProps } from "../types";

const {Title,Paragraph} = Typography;

export const NotificationHeader : FC<NotificationHeaderProps> = ({filterByUnread, setFilterByUnread}) => {
    return (
        <Row justify="space-between" align="bottom">
            <Title level={4} >Notifications</Title>
            <Row justify="center">
                <Paragraph className="notification-header-subtitle">Show unread</Paragraph>
                <Switch className="notification-header-switch" size="small" defaultChecked checked={filterByUnread} onClick={setFilterByUnread} />
            </Row>
        </Row>
    );
}