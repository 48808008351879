import { Form, Input, Space, Button } from "antd";
import React, { FC } from "react";

interface IEditZoneNameProps {
  zoneName: string;
  onSave: (zoneName: string) => void;
  onHide: () => void;
}

const EditZoneName: FC<IEditZoneNameProps> = ({ zoneName, onSave, onHide }) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => onSave(values.zoneName);

  return (
    <Form form={form} layout="vertical" initialValues={{ zoneName }} onFinish={onFinish} size="small">
      <Form.Item name="zoneName" rules={[{ required: true, message: "Zone name is required" }]}>
        <Input />
      </Form.Item>
      <Space>
        <Button type="primary" htmlType="submit" size="small">
          Save
        </Button>
        <Button size="small" onClick={onHide}>
          Close
        </Button>
      </Space>
    </Form>
  );
};

export default EditZoneName;
