import { IOrder } from "./order.entity";

export interface IPoint {
  arrivalTime: string;
  order?: IOrder;
  deliveryPoint?: {
    id: number;
    lat: string;
    long: string;
    customerId?: number;
  };
  deliveryPointId?: number;

  jobId: number;
  pickupPoint?: {
    id: number;
    lat: string;
    long: string;
    name: string;
  };
  pickupPointId?: number;

  warehouse?: {
    id: number;
    lat: string;
    long: string;
    name: string;
  };
  warehousePointId?: number;

  pointOrder: number;
  pointType: PointTypeEnum;
  visited: boolean;
}

export enum PointTypeEnum {
  DELIVERY = "delivery",
  PICKUP = "pickup",
}
