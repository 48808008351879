import { InfoCircleOutlined, PushpinOutlined } from "@ant-design/icons";
import { Button, Col, notification, Row } from "antd";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { IPreviewOrdersAction } from "../../types/previewActions.types";
import "./previewOrdersAction.style.less";
const PreviewOrdersAtion: FC<IPreviewOrdersAction> = ({
  currentPreviewAction,
  cancelCurrentAction,
  confirmMoveOrders,
  confirmAddOrders,
  confirmRemoveOrders,
}) => {
  const handleActionConfirm = () => {
    if (!currentPreviewAction.orderIds?.length || !currentPreviewAction.jobId) {
      notification.warn({
        message: "You can't perform this action ! please make sure to select orders and a valid job !",
      });
      return;
    }
    switch (currentPreviewAction.type) {
      case "move":
        confirmMoveOrders(
          currentPreviewAction.originalJobId!,
          currentPreviewAction.orderIds,
          currentPreviewAction.jobId,
        );
        break;
      case "add":
        confirmAddOrders(
          currentPreviewAction.originalJobId!,
          currentPreviewAction.orderIds,
          currentPreviewAction.jobId,
        );
        break;
      case "remove":
        confirmRemoveOrders(
          currentPreviewAction.originalJobId!,
          currentPreviewAction.orderIds,
          currentPreviewAction.jobId,
        );
        break;
      default:
        notification.warn({ message: "Unkown action !" });
        break;
    }
  };

  const canConfirm = currentPreviewAction.orderIds && currentPreviewAction.orderIds?.length > 0;
  return (
    <Row hidden={!currentPreviewAction?.isOn} className="preview_wrapper">
      <Col span="14" sm="24" md="12">
        <Row style={{ alignItems: "baseline" }}>
          <h4 className="preview_wrapper--header">
            <PushpinOutlined />
            <b>
              <FormattedMessage id="page.configureJob.previewTitle" defaultMessage={"Preview Mode"} />
            </b>
          </h4>
          {!canConfirm && (
            <p className="preview_wrapper--important">
              <InfoCircleOutlined />
              <FormattedMessage
                id="page.configureJob.previewMustSelect"
                defaultMessage={`Please make sure to select at least one order !`}
              />
            </p>
          )}
          {canConfirm && (
            <p className="preview_wrapper--confirm">
              <FormattedMessage
                id="page.configureJob.confirm"
                defaultMessage={`Do you really want to ${currentPreviewAction.type} the selected ${currentPreviewAction.orderIds?.length} orders to job # ${currentPreviewAction.jobId}`}
                values={{
                  job: currentPreviewAction.jobId,
                  action: currentPreviewAction.type,
                  orders: currentPreviewAction.orderIds?.length,
                }}
              />
            </p>
          )}
        </Row>
      </Col>

      <Col span="10" sm="24" md="12">
        <Row className="preview_wrapper--actions">
          <Button key="Cancel" onClick={cancelCurrentAction} loading={false} data-testid="btn-cancel">
            <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
          </Button>

          <Button
            key="submit"
            type="primary"
            loading={false}
            onClick={handleActionConfirm}
            disabled={!canConfirm}
            data-testid="btn-confirm"
          >
            <FormattedMessage id="tabPane.actions.confirm" defaultMessage="Confirm" />
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default PreviewOrdersAtion;
