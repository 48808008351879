import React from "react";

const JobDescription = ({ item }) => (
  <div data-testid="cfr-job-description">
    <p className="jobInfo">
      Total time <span>{item.totalTime}</span>
    </p>
    <p className="jobInfo">
      Number of delivery points
      <span>{item.deliveryPointsCount}</span>
    </p>
    <p className="jobInfo">
      Number of pick up points <span>{item.pickupPointsCount}</span>
    </p>
    <p className="jobInfo">
      General number of packages <span>{item.packagesCount}</span>
    </p>
    <p className="jobInfo">
      Driver to assign <span>{item.route?.driverId ?? null}</span>
    </p>
  </div>
);

export default JobDescription;
