import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "src/redux";
import { actions as adminPriceManagementActions } from "../../redux/resources/adminPriceManagement";
import { PriceManagementProps } from "./types";
import { usePriceManagementActions } from "./hooks/usePriceManagementActions";
import DesktopView from "./views/Desktop";
import MobileView from "./views/Mobile";
import { Modal } from "antd";
import CreateSetting from "./components/CreateSetting";
import { useEditAction } from "./hooks/useEditAction";

const PriceManagement: React.FC<PriceManagementProps> = ({
  isMobile,
  getAdminPriceManagement,
  createOneAdminPriceManagement,
  updateOneAdminPriceManagement,
  deleteOneAdminPriceManagement,
}) => {
  const {
    companyPricesList,
    tenants,
    createSettingModalVisible,
    handleFetchCompanyPrices,
    handleInit,
    handleCreateSetting,
    onOpenCreateSettingModal,
    onCloseCreateSettingModal,
    handleDeleteSetting,
  } = usePriceManagementActions({
    getAdminPriceManagement,
    createOneAdminPriceManagement,
    deleteOneAdminPriceManagement,
  });

  const { editedSetting, IsEditing, setEditedSetting, handleSavePriceSetting } = useEditAction({
    updateOneAdminPriceManagement,
    handleFetchCompanyPrices,
  });

  useEffect(() => {
    handleFetchCompanyPrices();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleInit();
  }, []);

  const modalPadding = isMobile ? 25 : 50;

  return (
    <>
      {isMobile ? (
        <MobileView
          handleFetchCompanyPrices={handleFetchCompanyPrices}
          companyPricesList={companyPricesList}
          onOpenCreateSettingModal={onOpenCreateSettingModal}
          handleDeleteSetting={handleDeleteSetting}
          IsEditing={IsEditing}
          setEditedSetting={setEditedSetting}
          editedSetting={editedSetting}
          handleSavePriceSetting={handleSavePriceSetting}
        />
      ) : (
        <DesktopView
          handleFetchCompanyPrices={handleFetchCompanyPrices}
          companyPricesList={companyPricesList}
          onOpenCreateSettingModal={onOpenCreateSettingModal}
          handleDeleteSetting={handleDeleteSetting}
          IsEditing={IsEditing}
          setEditedSetting={setEditedSetting}
          editedSetting={editedSetting}
          handleSavePriceSetting={handleSavePriceSetting}
        />
      )}
      <Modal
        visible={createSettingModalVisible}
        onCancel={onCloseCreateSettingModal}
        title="Add New Price setting"
        footer={false}
        bodyStyle={{ paddingLeft: modalPadding, paddingRight: modalPadding }}
        {...(isMobile ? { width: 350 } : null)}
      >
        <CreateSetting tenants={tenants} handleAddSetting={handleCreateSetting} />
      </Modal>
    </>
  );
};

const MSTP = (state: RootState) => ({
  isMobile: state.admin.isMobile,
});

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(adminPriceManagementActions, dispatch),
}))(PriceManagement);
