import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Modal, Form, Spin } from "antd";
import GoogleMapReact from "google-map-react";

import LocationInputForm from "./LocationInputForm";
import { API_KEY, mapCenters } from "../../../../constants/config";
import Customer from "../../../markers/Customer";
import errorLogger from "../../../../utils/errorLogging/error-logger";

function LocationModal({
  isLocationModalOpen,
  setIsLocationModalOpen,
  getMapData,
  addLocationToLocal,
  old,
  updateOrders,
  getUserTenant,
}) {
  const [customLocation, setCustomLocation] = useState({ lat: 0, long: 0 });
  const [geolocationResponse, setGeolocationResponse] = useState({ address: null, isError: false });
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const intl = useIntl();

  useEffect(() => {
    const tenant = getUserTenant();
    if (tenant) {
      // case of retailer, tenant is not set
      setCustomLocation(mapCenters[tenant]);
    }
  }, [getUserTenant]);

  useEffect(() => {
    form.resetFields();
    setGeolocationResponse({ address: null, isError: false });
  }, [isLocationModalOpen, form]);

  const saveNewPosition = async () => {
    const { lat, long } = form.getFieldsValue(true);

    await addLocationToLocal({
      selectedId: isLocationModalOpen,
      lat: parseFloat(lat),
      long: parseFloat(long),
      old,
      address: geolocationResponse.address,
    });
    setIsLocationModalOpen(null);
    updateOrders();
  };

  const closeModal = () => {
    setIsLocationModalOpen(null);
  };

  return (
    <Modal
      visible={isLocationModalOpen}
      title={`${intl.formatMessage({ id: "modal.addLocation", defaultMessage: "Add location" })}`}
      onCancel={closeModal}
      onOk={saveNewPosition}
      footer={[
        <Button key="Cancel" onClick={closeModal}>
          <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!customLocation || !customLocation?.lat || !customLocation?.long || geolocationResponse.isError}
          onClick={saveNewPosition}
          loading={isLoading}
        >
          <FormattedMessage id="tabPane.actions.save" defaultMessage="Save" />
        </Button>,
      ]}
    >
      <h1>
        <FormattedMessage id="modal.addCustomLocation" defaultMessage="Add custom location to order" />
      </h1>
      <div style={{ height: "400px", width: "100%" }}>
        {customLocation && (
          <GoogleMapReact
            bootstrapURLKeys={{ key: API_KEY }}
            center={{ lat: customLocation.lat, lng: customLocation.long }}
            defaultZoom={10}
            onClick={({ x, y, lat, lng, event }) => {
              getMapData({ lat, long: lng }, "coords").then(
                (res) => {
                  setCustomLocation((old) => ({
                    ...old,
                    lat: parseFloat(res.lat),
                    long: parseFloat(res.long),
                  }));
                  setGeolocationResponse({ address: res.address, isError: false });
                },
                (err) => {
                  errorLogger.report(`Map error ${err}`);
                },
              );
            }}
            distanceToMouse={() => {}}
          >
            <Customer lat={customLocation.lat} lng={customLocation.long} />
          </GoogleMapReact>
        )}
      </div>
      <LocationInputForm
        form={form}
        customLocation={customLocation}
        setCustomLocation={setCustomLocation}
        setIsLoading={setIsLoading}
        setGeolocationResponse={setGeolocationResponse}
        getMapData={getMapData}
      />
      <div className="inputsLatLongWrapper">
        {geolocationResponse.isError && (
          <p className="locationModal__error">
            <FormattedMessage
              id="modal.checkLatitude"
              defaultMessage="Check your latitude and longitude probably they are wrong"
            />
          </p>
        )}
        {geolocationResponse.address && !isLoading && (
          <p className="locationModal__address">
            <FormattedMessage id="input.input.address" defaultMessage="Address" />: {geolocationResponse.address}
          </p>
        )}
        {isLoading && (
          <p className="locationModal__loading">
            <Spin size="small" />
          </p>
        )}
      </div>
    </Modal>
  );
}

export default LocationModal;
