import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getQueryParam } from "../../../helpers/general";
import { actions as customerActions } from "../../../redux/resources/customer";

const useHooks = () => {
  const location = useLocation();
  const getQueryParams = useCallback(() => getQueryParam(location.search), [location.search]);
  const dispatch = useDispatch();
  const [paymentResult, setPaymentResult] = useState();

  const { getPaymentResultCustomer } = customerActions;

  const fetchData = useCallback(
    (cb) => {
      const queryParams = getQueryParams();
      const uuid = queryParams?.paymentUuid;

      dispatch(
        cb({
          uuid,
        }),
      )
        .then(({ body }) => {
          setPaymentResult(body);
        })
        .catch(() => {
          setPaymentResult({
            error: true,
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getQueryParams],
  );

  const onFetchPaymentResults = () => {
    fetchData(getPaymentResultCustomer);
  };

  useEffect(() => {
    onFetchPaymentResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return { paymentResult };
};

export default useHooks;
