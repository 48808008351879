import React from "react";
import { EditOutlined } from "@ant-design/icons";
import { Descriptions, Popover, Space, InputNumber, Button, Popconfirm, Tooltip } from "antd";
import { FC, useState } from "react";
import Ticket from "src/components/Ticket";
import { TicketViewEnum } from "src/components/Ticket/type";
import { IJobDetailsPanelContent } from "src/types/JobDetails";

const ALLOWED_FORCE_NEXT = ["in_delivery", "arrived"];

const OrderDetails: FC<IJobDetailsPanelContent> = (props) => {
  const { order, jobId, forceNextOrder, onUpdateCashAmount } = props;
  const [cashAmount, setCashAmount] = useState<number>(order?.cashAmount || 0);
  const isSaveCodDisabled = cashAmount === null || cashAmount < 0;

  const onChange = (value: number) => {
    setCashAmount(value);
  };

  const onSave = () => {
    onUpdateCashAmount(order?.id, jobId, cashAmount);
  };

  return (
    <Descriptions column={1} size="small" bordered>
      <Descriptions.Item label="Retailer Name">{order?.retailer?.name}</Descriptions.Item>
      <Descriptions.Item label="Tracking Id">{order?.trackingId}</Descriptions.Item>
      <Descriptions.Item label="Customer Phone Number">{order?.customer?.phoneNumber}</Descriptions.Item>
      {order?.warehousePoint && (
        <Descriptions.Item label="Warehouse Address">
          <p>{order?.warehousePoint?.address}</p>
        </Descriptions.Item>
      )}
      <Descriptions.Item label="Customer Address">
        <p>{order?.deliveryPoint?.initialAddress?.building}</p>
        <p>{order?.deliveryPoint?.initialAddress?.street}</p>
        <p>{order?.deliveryPoint?.initialAddress?.city}</p>
      </Descriptions.Item>
      <Descriptions.Item label="Package Count">{order?.packagesCount}</Descriptions.Item>
      <Descriptions.Item label="COD Amount">
        {order?.cashAmount}
        <Popover
          placement="top"
          title="Update COD"
          content={
            <Space>
              <InputNumber
                defaultValue={cashAmount}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                onChange={onChange}
              />
              <Button type="link" onClick={onSave} disabled={isSaveCodDisabled}>
                Save
              </Button>
            </Space>
          }
          trigger="click"
        >
          <Button type="link" icon={<EditOutlined />} />
        </Popover>
      </Descriptions.Item>
      <Descriptions.Item label="Ticket">
        <Ticket
          currentView={TicketViewEnum.JOB_DETAILS}
          buttonType="link"
          jobId={jobId}
          orderId={order?.id}
          ticketDetails={order?.ticket || null}
          users={{
            retailer: order?.retailerName,
          }}
        />
      </Descriptions.Item>
      {ALLOWED_FORCE_NEXT.includes(order?.status!) && (
        <Descriptions.Item label="Force Next Order">
          <Popconfirm
            title="Are You sure, You want to move to next order?"
            onConfirm={() => forceNextOrder(jobId)}
            okText="Yes"
            cancelText="No"
            data-testid="order_move_next_popup"
          >
            <Tooltip placement="bottom" title="Force job to move to next order">
              <Button type="link">Force to Next Order</Button>
            </Tooltip>
          </Popconfirm>
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

export default OrderDetails;
