import React from "react";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";

import arrowRight from "../../../../../../assets/arrow-right.png";

import "./uploadDocumentsActions.less";

const UploadDocumentsActions = ({ onNext, onCancel }) => (
  <div className="uploadDocumentsActionsWrapper">
    <Button onClick={onCancel} type="text">
      <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
    </Button>
    <span>
      <Button onClick={onNext} type="text" className="uploadNext">
        <FormattedMessage id="steps.next" defaultMessage="Next" />
      </Button>
      <img src={arrowRight} alt="Right arrow" />
    </span>
  </div>
);

export default UploadDocumentsActions;
