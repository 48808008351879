import React, { useState } from "react";
import { Collapse, Empty } from "antd";

import { MobileTableCollapseRows } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableCollapseRows";
import { MobileTableExpand } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableExpand";
import { MobileTableRows } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableRows";
import { MobileTable } from "../../../../../components/AdminPanel/Mobile/MobileTable";
import { ReactComponent as OpenCollapse } from "../../../../../assets/adminPanel/mobile-collapse.svg";
import { ReactComponent as OpenedCollapse } from "../../../../../assets/adminPanel/mobile-opened-collase.svg";
import { NO_INFO, transformStatus } from "../../../../../components/AdminPanel/Mobile/helpers";

const { Panel } = Collapse;

export const JobsTable = ({ data }) => {
  const [, setSelectedJob] = useState(null);
  const [innerCollapsed, setInnerCollapsed] = useState(null);

  const [expandedId, setExpandedId] = useState(-1);
  const handleSetExpandedId = (id) => {
    setExpandedId(id);
  };

  const collapseHeader = (orderId, customerName) => (
    <div className="expand-inner-collapse__panel-header">
      <div className="left">Customer</div>
      <div className="right">
        <div>{customerName}</div>
        {innerCollapsed === orderId ? <OpenedCollapse /> : <OpenCollapse />}
      </div>
    </div>
  );

  const onInnerCollapse = (orderId) => {
    setInnerCollapsed(Number(orderId));
  };

  const getOrderStatus = (status) => {
    switch (status) {
      case "completed":
        return <span className="badge-success">Completed</span>;
      case "processing":
        return <span className="badge-pending">Processing</span>;
      case "canceled":
        return <span className="badge-declined">Canceled</span>;
      case "canceled_after_pickup":
        return <span className="badge-declined">Canceled after pickup</span>;
      default:
        return <span className="badge-pending">{transformStatus(status)}</span>;
    }
  };

  return (
    <MobileTable>
      {data?.map((job) => (
        <div className="table-item" key={job.id}>
          <MobileTableRows
            rows={[
              { label: "Job ID", value: job.id },
              {
                label: "Driver Name",
                value: job.driver?.profile?.firstName ? (
                  `${job.driver.profile.firstName} ${job.driver.profile.lastName}`
                ) : (
                  <div className="no-info">Unassigned</div>
                ),
              },
              { label: "Packages count", value: job.packagesCount || NO_INFO },
              { label: "Pickup points", value: job.pickupPointsCount || NO_INFO },
              { label: "Delivery points", value: job.deliveryPointsCount || NO_INFO },
              { label: "Overall Delivery Time", value: job.totalTime || NO_INFO },
              { label: "Status", value: getOrderStatus(job.status) },
            ]}
          />
          <MobileTableExpand setIsPanelCollapsed={() => setSelectedJob(job.id)} id={job.id} expanded={expandedId===job.id} handleSetExpandedId={handleSetExpandedId}>
            {job.orders.length ? (
              <Collapse
                onChange={onInnerCollapse}
                accordion
                expandIconPosition="right"
                className="expand-inner-collapse"
              >
                {job.orders.map((order) => (
                  <Panel header={collapseHeader(order.id, order.customerInfo?.name)} key={order.id}>
                    <MobileTableCollapseRows
                      rows={[
                        { label: "Tracking ID", value: order.trackingId || NO_INFO },
                        { label: "Delivery time", value: order.deliveryTime || NO_INFO },
                        { label: "Retailer name", value: order.retailer?.name || NO_INFO },
                        { label: "Status", value: getOrderStatus(order.status) || NO_INFO },
                      ]}
                    />
                  </Panel>
                ))}
              </Collapse>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </MobileTableExpand>
        </div>
      ))}
    </MobileTable>
  );
};
