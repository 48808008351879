import React, { FC, useEffect, useState } from "react";
import { Button, notification, Input, Modal, Form, Row, Col } from "antd";
import GoogleMapReact from "google-map-react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { FormattedMessage, useIntl } from "react-intl";
import Geocode from "react-geocode";

import PointMarker from "./PointMarker";
import { ReactComponent as Target } from "../../assets/map/target.svg";
import { API_KEY, mapCenters } from "src/constants/config";
import { getUserTenant } from "src/helpers/authStorage";
import { ModalModeEnum } from "src/pages/StoreManagement/types/store-management";
import { IPickupPoint } from "src/types/orderEntity";

Geocode.setApiKey(API_KEY);
type mapCentersKey = keyof typeof mapCenters;

interface RetailerPickupModalProps {
  setIsModalOpen: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      mode?: ModalModeEnum;
    }>
  >;

  isModalOpen: {
    visible: boolean;
    mode?: ModalModeEnum;
  };
  handleLocationUpdate: (location: any, mode: ModalModeEnum) => Promise<void>;
  restrictions: any;
  locationToEdit?: IPickupPoint;
}
const RetailerPickupModal: FC<RetailerPickupModalProps & any> = ({
  setIsModalOpen,
  isModalOpen,
  handleLocationUpdate,
  restrictions,
  locationToEdit,
}) => {
  const [currentZoom, setCurrentZoom] = useState<number>(5);
  const [addressSearch, setAddressSearch] = useState<string>("");
  const [selectedLocation, setSelectedLocation] = useState<any>({ lat: 0, long: 0, name: "", address: "" });

  const currentTenant: mapCentersKey = (getUserTenant() ?? "Qatar") as mapCentersKey;
  const defaultCenter = { lat: mapCenters[currentTenant].lat, lng: mapCenters[currentTenant].long };

  const intl = useIntl();

  const handleSelect = async (address: string, placeID: string) => {
    setAddressSearch(address);
    const result = await geocodeByAddress(address);
    const latLng = await getLatLng(result[0]);
    setCurrentZoom(10);
    setSelectedLocation((prev: any) => {
      return { ...prev, address: address, lat: latLng.lat, long: latLng.lng };
    });
  };

  const handleMapSelect = (loc: any) => {
    Geocode.fromLatLng(loc.lat, loc.lng).then(
      (response) => {
        const adress = response.results[0].formatted_address;
        setSelectedLocation((prev: any) => {
          return { ...prev, address: adress, lat: loc.lat, long: loc.lng };
        });
      },
      (error) => {
        console.error(error);
      },
    );
  };
  const handleSave = async () => {
    if (selectedLocation.name !== "") {
      const location = { ...selectedLocation };
      location.lat = selectedLocation.lat;
      location.long = selectedLocation.long;
      await handleLocationUpdate(location, isModalOpen?.mode);
    } else {
      notification.error({
        message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
        description: "Add name please!",
      });
    }
    setSelectedLocation({ lat: 0, long: 0, name: "", address: "" });
  };

  const getCurrentLocation = (e: any) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        handleMapSelect({ lat: position.coords.latitude, lng: position.coords.longitude });
      });
    } else {
      notification.error({
        message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
        description: "Geolocation is not supported by this browser.",
      });
    }
  };

  const onChangeValue = (e: { target: { value: any } }, type: string) => {
    switch (type) {
      case "name":
        if ((/^[^0-9]+.*/.test(e.target.value) || !e.target.value) && String(e.target.value)?.length < 60) {
          setSelectedLocation((prev: any) => {
            return { ...prev, name: e.target.value };
          });
        }
        break;
      case "long":
        {
          if (Number(e.target.value) <= 180 && Number(e.target.value) >= -180) {
            setSelectedLocation((prev: any) => {
              return { ...prev, long: e.target.value };
            });
          }
        }
        break;
      case "lat":
        {
          if (Number(e.target.value) <= 90 && Number(e.target.value) >= -90) {
            setSelectedLocation((prev: any) => {
              return { ...prev, lat: e.target.value };
            });
          }
        }
        break;
      case "instructions":
        {
          setSelectedLocation((prev: any) => {
            return { ...prev, instructions: e.target.value };
          });
        }
        break;
      case "address":
        {
          setSelectedLocation((prev: any) => {
            return { ...prev, address: e.target.value };
          });
        }
        break;
    }
  };

  useEffect(() => {
    if (locationToEdit) setSelectedLocation(locationToEdit);
  }, [locationToEdit]);

  return (
    <Modal
      className="retailer-modal" // temp
      width="60vw"
      closable
      onCancel={() => setIsModalOpen({ visible: false })}
      visible={isModalOpen.visible}
      title={isModalOpen?.mode === "ADD" ? "Add new pickup" : `Edit pickup ${selectedLocation.name}`}
      footer={[
        <Button onClick={() => setIsModalOpen(false)} key="cancel">
          <FormattedMessage id="modal.discard" defaultMessage="Discard" />
        </Button>,
        <Button
          type="primary"
          disabled={!parseFloat(selectedLocation.lat) || !parseFloat(selectedLocation.long)}
          onClick={handleSave}
          key="save"
        >
          <FormattedMessage id="tabPane.actions.save" defaultMessage="Save" />
        </Button>,
      ]}
    >
      <PlacesAutocomplete
        value={addressSearch}
        onChange={(address: string) => setAddressSearch(address)}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }: any) => (
          <div>
            <Input.Search
              {...getInputProps({
                placeholder: `${intl.formatMessage({ id: "search", defaultMessage: "Search" })}`,
              })}
              value={addressSearch}
              className="autocomplete-search"
            />
            <div className="autocomplete-dropdown-container">
              {loading && (
                <div>
                  <FormattedMessage id="loading" defaultMessage="Loading..." />
                </div>
              )}
              {suggestions.map((suggestion: any) => {
                const style = { backgroundColor: suggestion.active ? "#fafafa" : "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                    key={suggestion.description}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>

      <div className="retailer-modal__map-wrapper">
        <GoogleMapReact
          options={{ clickableIcons: false, fullscreenControl: false }}
          onClick={({ x, y, lat, lng, event }) => {
            handleMapSelect({ lat, lng });
          }}
          defaultCenter={{ lat: Number(selectedLocation.lat), lng: Number(selectedLocation.long) }}
          bootstrapURLKeys={{ key: API_KEY, libraries: ["places"] }}
          zoom={currentZoom}
          center={{
            lat: Number(selectedLocation.lat) || defaultCenter.lat,
            lng: Number(selectedLocation.long) || defaultCenter.lng,
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            if (restrictions?.areaRestriction) {
              new maps.Circle({
                strokeOpacity: 0.4,
                strokeWeight: 2,
                fillColor: "rgb(144, 238, 144)",
                fillOpacity: 0.2,
                map,
                center: { lat: Number(restrictions?.areaLat || "0"), lng: Number(restrictions?.areaLong || "0") },
                radius: restrictions?.areaRadius * 1000,
              });
            }
          }}
        >
          <PointMarker lat={selectedLocation.lat} lng={selectedLocation.long} />
        </GoogleMapReact>
      </div>
      <div
        className="findMe"
        style={{ position: "relative", right: "10px", bottom: "90px" }}
        onClick={getCurrentLocation}
      >
        <Button>
          <Target />
        </Button>
      </div>
      <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <Row justify="space-between">
          <Col lg={11} sm={23}>
            <Form.Item
              label={intl.formatMessage({
                id: "name",
                defaultMessage: "Name",
              })}
            >
              <Input
                value={selectedLocation.name}
                placeholder={`${intl.formatMessage({
                  id: "name",
                  defaultMessage: "Name",
                })}`}
                onChange={(e: any) => onChangeValue(e, "name")}
              />
            </Form.Item>
          </Col>
          <Col lg={11} sm={23}>
            <Form.Item
              label={intl.formatMessage({
                id: "address",
                defaultMessage: "Address",
              })}
            >
              <Input
                value={selectedLocation.address}
                placeholder={`${intl.formatMessage({
                  id: "address",
                  defaultMessage: "Address",
                })}`}
                onChange={(e) => onChangeValue(e, "address")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col lg={11} sm={23}>
            <Form.Item
              label={intl.formatMessage({
                id: "modal.latitude",
                defaultMessage: "Latitude",
              })}
            >
              <Input
                value={selectedLocation.lat || "0"}
                placeholder={intl.formatMessage({
                  id: "modal.latitude",
                  defaultMessage: "Latitude",
                })}
                onChange={(e) => onChangeValue(e, "lat")}
              />
            </Form.Item>
          </Col>
          <Col lg={11} sm={23}>
            <Form.Item
              label={intl.formatMessage({
                id: "modal.longitude",
                defaultMessage: "Longtitude",
              })}
            >
              <Input
                value={selectedLocation.long || "0"}
                placeholder={intl.formatMessage({
                  id: "modal.longitude",
                  defaultMessage: "Longtitude",
                })}
                onChange={(e) => onChangeValue(e, "long")}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={intl.formatMessage({
                id: "input.instructions",
                defaultMessage: "Instructions",
              })}
            >
              <Input.TextArea
                value={selectedLocation.instructions}
                placeholder={`${intl.formatMessage({
                  id: "input.instructions",
                  defaultMessage: "Instructions",
                })}`}
                onChange={(e) => onChangeValue(e, "instructions")}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default RetailerPickupModal;
