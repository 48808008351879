import React from "react";
import moment from "moment";
import { Table, Select, Input, Divider } from "antd";
import { useIntl } from "react-intl";
import useHooks from "./hooks/useHooks";
import CustomDivider from "../../components/Divider";
import { WarningTwoTone } from "@ant-design/icons";

const { Option } = Select;

const columns = [
  { title: "Id", dataIndex: "id", key: "id", render: (id) => <b>{id}</b> },
  { title: "OrderId", dataIndex: "orderId", key: "orderId", render: (orderId) => <span>{orderId}</span> },
  {
    title: "External Id",
    dataIndex: "externalId",
    key: "externalId",
    render: (externalId) => <span>{externalId}</span>,
  },
  {
    title: "Payment Type",
    dataIndex: "gateway",
    key: "gateway",
    render: (gateway) => <span>{gateway}</span>,
  },
  {
    title: "Payment Amount",
    dataIndex: "amount",
    key: "amount",
    render: (amount) => <span>{amount}</span>,
  },
  {
    title: "Date Created",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (createdAt) => <span>{moment(createdAt).format("DD.MM.YYYY HH:mm")}</span>,
  },
  {
    title: "Date Updated",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (updatedAt) => <span>{moment(updatedAt).format("DD.MM.YYYY HH:mm")}</span>,
  },
  { title: "Status", dataIndex: "status", key: "status", render: (status) => <b>{status}</b> },
].filter(Boolean);

const Payments = () => {
  const {
    data,
    paginationConfig,
    loading,
    setPaymentSearchQuery,
    searchFilter,
    handleSearchPayment,
    query,
    setQuery,
    searchedText,
    setSearchedText,
    searchInputErrors,
    handleChange,
  } = useHooks();
  const onChangeSearchFilter = (value) => setPaymentSearchQuery(value);
  const intl = new useIntl();
  const selectSearchFilter = (
    <Select defaultValue="orderId" className="select-before" onChange={onChangeSearchFilter}>
      {searchFilter.map(({ value, text }) => (
        <Option key={value} value={value}>
          {text}
        </Option>
      ))}
    </Select>
  );

  const searchPayments = (
    <>
      <Input
        value={searchedText}
        placeholder={`${intl.formatMessage({ id: "search", defaultMessage: "Search" })}`}
        addonBefore={selectSearchFilter}
        onChange={(event) => {
          // validate input value with each change
          handleChange(event);
          setSearchedText(event.target.value);
        }}
        onPressEnter={handleSearchPayment}
        className="searchDriver"
        status={searchedText?.length > 0 && searchInputErrors.err !== "" ? "error" : ""}
      />

      {searchedText?.length > 0 && searchInputErrors.err !== "" && (
        <>
          <br />
          <WarningTwoTone twoToneColor="#db2269" />
          <span className="error"> {searchInputErrors.err}</span>
        </>
      )}
    </>
  );

  const onChange = async (pagination) => {
    console.log("query ", query);
    const { current, pageSize } = pagination;
    await setQuery({
      ...query,
      page: current,
      limit: pageSize,
    });
    if (pageSize !== query.limit) {
      localStorage.setItem("paymentOnPage", pageSize);
    }
  };
  return (
    <div className="pageWrapper">
      <h2 className="pageTitle">Payments</h2>
      <CustomDivider />
      <Divider />
      {searchPayments}
      <Divider />
      <Table columns={columns} dataSource={data} pagination={paginationConfig} onChange={onChange} loading={loading} />
    </div>
  );
};

export default Payments;
