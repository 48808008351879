import StackdriverErrorReporter from "stackdriver-errors-js";
import { ERROR_TRACKING_CREDS } from "../../constants/config";
let errorLogger;
if (process.env.NODE_ENV === "production") {
  // only report errors to GC when we are on production
  errorLogger = new StackdriverErrorReporter();

  errorLogger.start({
    key: ERROR_TRACKING_CREDS.API_KEY,
    projectId: ERROR_TRACKING_CREDS.PROJECT_ID,
    service: "bb-front-end",
    context: {
      user: "undefined", // this will change during login
    },
    // version: myServiceVersion,
    // Version identifier of the service reporting the error.
  });
} else {
  errorLogger = { report: console.error };
}

export default errorLogger;
