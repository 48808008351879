import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { v4 as uuidv4 } from "uuid";

import DriverMarker from "../DriverMarker";
import CustomerMarker from "../../../../components/JobsMap/CustomerMarker";
import PickupPointMarker from "../../../../components/JobsMap/PickupPointMarker";
import TrackMarker from "../TrackMarker";
import { API_KEY } from "../../../../constants/config";

const Map = ({ driverLocations, onMarkerClick, job, allDriverSelected, showJobs }) => {
  const [currentZoom, setCurrentZoom] = useState(driverLocations && !allDriverSelected ? 10 : 0);
  const allOrders = () => {
    const arr = [];
    job.orders.forEach((order) => arr.push(order));
    return arr;
  };

  const mapMarkers = (markerType, Component) => {
    const arrayOfOrders = allOrders();
    return job.orders.map((order) => {
      const res = arrayOfOrders.filter((elem) => {
        return elem[markerType].lat === order[markerType].lat && elem[markerType].long === order[markerType].long;
      });
      return (
        <Component
          showJobs={showJobs}
          key={uuidv4()}
          lat={order[markerType].lat}
          lng={order[markerType].long}
          jobId={job.id}
          info={res}
        />
      );
    });
  };

  return (
    <div className="google-map-wrapper">
      <GoogleMapReact
        onChange={({ center, zoom, bound, marginBounds }) => {
          setCurrentZoom(zoom);
        }}
        bootstrapURLKeys={{ key: API_KEY }}
        defaultCenter={{ lat: 42.86032, lng: 19.53049 }}
        defaultZoom={0}
        zoom={allDriverSelected ? 0 : currentZoom}
        center={{
          lat: driverLocations ? Number(driverLocations[0]?.lat) : 42.86032,
          lng: driverLocations ? Number(driverLocations[0]?.long) : 19.53049,
        }}
        yesIWantToUseGoogleMapApiInternals
      >
        {driverLocations?.map((location, idx) => {
          if (allDriverSelected && location.lat) {
            return (
              <DriverMarker
                key={uuidv4()}
                lat={location.lat}
                lng={location.long}
                onMarkerClick={() => {
                  onMarkerClick(location.id);
                }}
              />
            );
          }
          if (idx === 0 && location.lat) {
            return (
              <DriverMarker
                key={uuidv4()}
                lat={location.lat}
                lng={location.long}
                onMarkerClick={() => {
                  onMarkerClick();
                }}
              />
            );
          }
          return location.lat && showJobs ? (
            <TrackMarker key={uuidv4()} lat={location.lat} lng={location.long} />
          ) : null;
        })}
        {job && showJobs ? mapMarkers("customer", CustomerMarker) : null}
        {job && showJobs ? mapMarkers("pickupPoint", PickupPointMarker) : null}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
