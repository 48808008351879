import { Col, Typography } from "antd";
import React, { FC, ReactElement } from "react";
//style
import "./style.less";
const InfoWrapper: FC<{ title: string; info?: string; child?: ReactElement }> = ({ title, info }) => {
  return (
    <Col className="info-wrapper" span={8} lg={8} md={12} sm={12} xs={24}>
      <Typography.Text className="info-wrapper__title">{title}</Typography.Text>
      {info && <Typography.Text className="info-wrapper__info">{info}</Typography.Text>}
    </Col>
  );
};

export default InfoWrapper;
