import antdAr from "antd/es/locale/ar_EG";

import arMessages from "../locales/ar_EG.json";

export const retailerArLang = {
  messages: {
    ...arMessages,
  },
  antd: antdAr,
  locale: "ar-EG",
};
