import React from "react";
import { DatePicker } from "antd";
import moment from "moment";

import GenericDashboardBox from "../Assets/Components/GenericDashboardBox";
import ChartDashboardBox from "../Assets/Components/ChartDashboardBox";
import DoubleDashboardBox from "../Assets/Components/DoubleDashboardBox";
import CustomDivider from "../../../components/Divider";
import { MobileLoader } from "../../../components/AdminPanel/Mobile/MobileLoader";
import { ReactComponent as YellowBox } from "./../Assets/Icons/YellowBox.svg";
import { ReactComponent as GreenBox } from "./../Assets/Icons/GreenBox.svg";
import { ReactComponent as RedBox } from "./../Assets/Icons/RedBox.svg";
import { ReactComponent as GrayBox } from "./../Assets/Icons/GrayBox.svg";
import { ReactComponent as GrayArrow } from "./../Assets/Icons/GrayArrow.svg";
import { ReactComponent as DriverIcon } from "./../Assets/Icons/DriverIcon.svg";
import { ReactComponent as DollarIcon } from "./../Assets/Icons/Dollar.svg";
import { ReactComponent as PauseIcon } from "./../Assets/Icons/Pause.svg";

import "./style.less";

function disabledDate(current) {
  return current > moment().endOf("day");
}

export default function MobileView({ isDashboardDataFetching, dashboardStats, date, setDate }) {
  return (
    <div className="mobile-page-wrapper">
      <h2 className="pageTitle">Dashboard</h2>
      <CustomDivider />

      <DatePicker
        format={"YYYY-MM-DD"}
        className="DashboardDatePicker"
        value={date}
        disabledDate={disabledDate}
        onChange={(date) => {
          setDate(date);
        }}
        allowClear={false}
      />
      <div className="MobileDashboardArea">
        {isDashboardDataFetching && <MobileLoader />}
        <GenericDashboardBox color="rgba(252, 220, 62, 0.2)">
          <div className="GenericIconWrap">
            <YellowBox />
          </div>
          <b>Total orders for the day</b>
          {dashboardStats?.totalOrders}
        </GenericDashboardBox>

        <GenericDashboardBox color="rgba(39, 174, 96, 0.2)">
          <div className="GenericIconWrap">
            <GreenBox />
          </div>
          <b>Total delivered orders </b>
          {dashboardStats?.totalDelivered}
        </GenericDashboardBox>

        <ChartDashboardBox dashboardStats={dashboardStats}></ChartDashboardBox>

        <GenericDashboardBox color="rgba(227, 75, 75, 0.2)">
          <div className="GenericIconWrap">
            <RedBox />
          </div>
          <b>Total canceled orders </b>
          {dashboardStats?.totalCanceled}
        </GenericDashboardBox>

        <GenericDashboardBox color="rgba(131, 131, 131, 0.2)">
          <div className="GenericIconWrap">
            <GrayBox />
            <GrayArrow />
          </div>
          <b>Total of returns </b>
          {dashboardStats?.totalReturned}
        </GenericDashboardBox>

        <GenericDashboardBox color="rgba(39, 174, 96, 0.2)">
          <div className="GenericIconWrap">
            <DollarIcon width={70} height={70} fill="rgba(39, 174, 96, 1)" stroke="rgba(39, 174, 96, 1)" />
          </div>
          <b>Cash collected: </b>
          {dashboardStats?.cashOnDelivery} / {dashboardStats?.totalCashOnDelivery}
        </GenericDashboardBox>

        <GenericDashboardBox color="rgba(131, 131, 131, 0.2)">
          <div className="GenericIconWrap">
            <GrayBox />
            <PauseIcon width={31} height={31} />
          </div>
          <b>Total of postponed orders </b>
          {dashboardStats?.totalPostponed}
        </GenericDashboardBox>

        <DoubleDashboardBox color="white">
          <DriverIcon />
          <b>Total Active Drivers</b>
          {dashboardStats?.totalActiveDrivers}
        </DoubleDashboardBox>
      </div>
    </div>
  );
}
