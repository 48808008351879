import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Typography, Button, Layout, notification } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { GetStartedButton, SignUpForm, SuccessAuth } from "../../components";
import { SelectLanguage } from "../../../../components/SelectLanguage";
import { actions as retailerActions } from "../../../../redux/resources/retailer";
import MainLogo from "../../../../assets/newLanding/MainLogo";
import MainLogoMobile from "../../../../assets/newLanding/MainLogoMobile";
import MobileBottomToolbar from "../../components/MobileBottomToolbar";
import RetailerTablet from "../../../../assets/newLanding/auth/RetailerTablet.png";
import SocialAuth from "../../components/SocialAuth";
import styles from "./signUpRetailerPage.module.less";

const SignUpRetailerPage = ({ signupRetailer, isMobile }) => {
  const [isSignUpSuccess, setIsSignUpSuccess] = useState(false);

  const intl = useIntl();

  const openNotification = (description) => {
    notification.error({
      message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
      description,
    });
  };

  const handleSubmit = (values) => {
    const formattedFields = { ...values };
    formattedFields.email = formattedFields.email.toLowerCase();

    signupRetailer({ ...formattedFields })
      .then(() => setIsSignUpSuccess(true))
      .catch((error) => {
        typeof error.body.message === "object"
          ? openNotification(error.body.message[0])
          : openNotification(error.body.message);
      });
  };

  return isMobile ? (
    <Layout className={styles.signUpWrap}>
      <Row style={{ flex: "auto" }}>
        <Col span={24} className={styles.mainSection}>
          <Row align="middle" justify="space-between">
            <Col span={6} offset={2}>
              <Link to="/">
                <MainLogoMobile />
              </Link>
            </Col>
            <Col span={16}>
              <Row align="top" justify="end">
                <Col span={8} offset={15}>
                  <SelectLanguage />{" "}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row align="middle" style={{ flex: "auto" }}>
            <Col
              span={20}
              offset={2}
              style={{ height: "100%", display: "flex", flexDirection: "column", paddingTop: 60, paddingBottom: 35 }}
            >
              <SignUpForm isMobile retailer onSubmit={handleSubmit} />
              {/* <SocialAuth isDriver={false} /> */}
              <MobileBottomToolbar
                isVisible={isSignUpSuccess}
                onToggleModal={() => {
                  window.location.href = "/";
                }}
              >
                <Typography.Title level={3} className={styles.modalTitle}>
                  Success! <br />
                  <Typography.Text className={styles.modalSubTitle}>Link on your mailbox</Typography.Text>
                </Typography.Title>

                <Typography.Paragraph className={styles.modalDescription}>
                  Follow the link in the email you received to register in the system.
                </Typography.Paragraph>
                <Link to="/">
                  <GetStartedButton
                    htmlType="submit"
                    className={styles.signUpButton}
                    style={isMobile ? { marginTop: 16, width: "100%" } : {}}
                  >
                    <ArrowLeftOutlined style={{ paddingRight: 20 }} /> Back to Main page
                  </GetStartedButton>
                </Link>
              </MobileBottomToolbar>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ height: 80, borderTop: "#E9E9E9 1px solid", paddingBottom: 36 }}>
        <Col
          offset={2}
          span={14}
          style={{ display: "flex", alignItems: "end", fontSize: 14, fontWeight: 300, lineHeight: "18px" }}
        >
          Already have an account?
        </Col>
        <Col
          span={6}
          style={{
            display: "flex",
            alignItems: "end",
            fontSize: 16,
            fontWeight: 700,
            lineHeight: "24px",
            color: "#0A0A0B",
            justifyContent: "end",
            paddingRight: 10,
          }}
        >
          <Link
            to="/retailer_login"
            style={{
              color: "#0A0A0B",
            }}
          >
            <FormattedMessage id="page.login.login" defaultMessage="Log In" />
          </Link>
        </Col>
      </Row>
    </Layout>
  ) : (
    <Layout className={styles.signUpWrap}>
      <Row>
        <Col span={12} md={24} lg={12} className={styles.promoSection}>
          <Row gutter={[0, 56]}>
            <Col span={23} offset={1}>
              <Link to="/">
                <MainLogo />
              </Link>
            </Col>

            <Col span={21} md={24} offset={3}>
              <Typography.Title level={2} className={styles.promoSectionTitle}>
                <FormattedMessage id="page.signup.retailer" defaultMessage="Retailer" /> <br />
                <Typography.Text className={styles.promoSectionSubtitle}>
                  <FormattedMessage id="page.signup.registration" defaultMessage="Registration" />
                </Typography.Text>
              </Typography.Title>
              <div className={styles.tablet_img}>
                <img src={RetailerTablet} style={{ width: 106, height: 207, zIndex: 1 }} alt="imageForTablet" />
              </div>{" "}
            </Col>
          </Row>
        </Col>

        <Col span={12} md={24} lg={12} className={styles.mainSection}>
          <Row align="middle">
            <Col span={3} offset={17}>
              <SelectLanguage />
            </Col>

            <Col span={3}>
              <Button size="large" type="link" className={styles.ligInButton}>
                <Link to="/retailer_login">
                  <FormattedMessage id="page.login.login" defaultMessage="Log In" />
                </Link>
              </Button>
            </Col>
          </Row>

          <Row align="middle" style={{ flex: "auto" }}>
            <Col span={11} offset={6}>
              <div style={{ marginTop: 250, transform: "translate(-12%, -50%)" }}>
                {!isSignUpSuccess ? (
                  <>
                    <SignUpForm onSubmit={handleSubmit} />
                    {/* <SocialAuth isDriver={false} /> */}
                  </>
                ) : (
                  <SuccessAuth
                    title="page.signup.linkOnMailbox"
                    subtitle="page.signup.success"
                    message="page.signup.successMessage"
                    goToBtnLink="/"
                    goToBtnLabel="page.signup.backToMainPage"
                  />
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default connect(null, (dispatch) => ({
  ...bindActionCreators(retailerActions, dispatch),
}))(SignUpRetailerPage);
