import React, { useEffect } from "react";
import { Button, Popover } from "antd";
import moment from "moment";

import { MobileTable } from "../../../../../components/AdminPanel/Mobile/MobileTable";
import { MobileTableRows } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableRows";
import { BtnGroup } from "../BtnGroup";
import { NO_INFO } from "../../../../../components/AdminPanel/Mobile/helpers";
import { MobilePagination } from "../../../../../components/AdminPanel/Mobile/MobilePagination";

export const MobileDeliveryTable = ({
  data,
  location,
  paginationConfig,
  fetchDriversPoints,
  fetchData,
  onOpenModal,
  onChangePagination,
  handleToggleOne,
  isSelected,
}) => {
  useEffect(() => {
    fetchData(fetchDriversPoints);
  }, [fetchDriversPoints, fetchData, location.search]);

  return (
    <>
      <MobileTable>
        {data?.map(({ requests_id, description, driver_name, job_id, order_id, reason, status, time }) => (
          <div className="table-item" key={requests_id}>
            {/* <div style={{ margin: "10px" }} onClick={(e) => handleToggleOne(e, requests_id)}>
              <Checkbox checked={isSelected(requests_id)} />
            </div> */}
            <MobileTableRows
              rows={[
                { label: "Driver name", value: driver_name || NO_INFO },
                { label: "Order ID", value: order_id || NO_INFO },
                { label: "Job ID", value: job_id || NO_INFO },
                {
                  label: "Reason",
                  value: description ? (
                    <Popover placement="topRight" content={<div>{description}</div>} title={null}>
                      <span>{reason}</span>
                    </Popover>
                  ) : (
                    <div>{reason}</div>
                  ),
                },
                { label: "Driver notes", value: description },
                { label: "Time", value: moment(time).format("DD.MM.YYYY HH:mm") },
                { label: "Status", value: status },
              ]}
            />
            {status === "pending" && (
              <>
                <BtnGroup isThree>
                  <Button
                    onClick={onOpenModal({ type: "approve", requestId: requests_id })}
                    className="mobile-btn mobile-btn--blue"
                  >
                    Approve
                  </Button>
                  <Button
                    onClick={onOpenModal({ type: "wait", requestId: requests_id })}
                    className="mobile-btn mobile-btn--blue"
                  >
                    Wait
                  </Button>
                  <Button
                    onClick={onOpenModal({ type: "reject", requestId: requests_id })}
                    className="mobile-btn mobile-btn--purple"
                  >
                    Reject
                  </Button>
                </BtnGroup>
              </>
            )}
            {status === "waiting" && (
              <BtnGroup>
                <Button
                  onClick={onOpenModal({ type: "approve", requestId: requests_id })}
                  className="mobile-btn mobile-btn--blue"
                >
                  Approve
                </Button>
                <Button
                  onClick={onOpenModal({ type: "reject", requestId: requests_id })}
                  className="mobile-btn mobile-btn--purple"
                >
                  Reject
                </Button>
              </BtnGroup>
            )}
          </div>
        ))}
      </MobileTable>
      <MobilePagination onPageChange={onChangePagination} {...paginationConfig} />
    </>
  );
};
