import React from "react";
import { Modal, Typography } from "antd";

import styles from "./termsConditionsModal.module.less";

const TermsConditionsModal = ({ isModalVisible, onToggleModal }) => (
  <Modal
    centered
    width={640}
    footer={null}
    visible={isModalVisible}
    onCancel={onToggleModal}
    maskStyle={{ background: "rgba(22, 22, 22, 0.4)", backdropFilter: "blur(16px)" }}
    className={styles.modal}
  >
    <div className={styles.scrollAreaWrap}>
      <Typography.Title level={3} className={styles.modalTitle}>
        Dliv Terms and Conditions
      </Typography.Title>

      <Typography.Title level={4} className={styles.modalSubTitle}>
        1. Who we are and our Services
      </Typography.Title>

      <p className={styles.modalText}>
        1.1. Dliv Trading Company is a limited liability company incorporated in the state of Qatar under commercial
        registration number 147529 with its address at The Gate Mall, Tower 2, 16th floor, P.O. Box 23918 (“Dliv” or
        “we” or “us” or “our”).
      </p>
      <p className={styles.modalText}>
        1.2. Dliv offers first mile and last mile delivery solutions for businesses through a digital network which
        includes, but is not limited to, a website (www.Dliv.com), a mobile application and a dedicated call center
        (altogether, the “Dliv Platform”).
      </p>
      <p className={styles.modalText}>
        1.3. The Dliv Platform provides a coordinated network between all stakeholders whereby businesses who seek
        the delivery of goods, products, restaurant meals and/or logistics services to their customers agree to be
        matched with delivery providers to carry out the relevant deliveries (“Services”).
      </p>
      <p className={styles.modalSubtext}>
        1.4. Neither Dliv nor its affiliates provide transportation, goods or logistics services or function as a
        transportation, goods or logistics carrier or carrier agent, or a transportation, goods or logistics service
        provider, or own any vehicles for transportation, goods or logistics and that all such services are provided by
        independent third-party contractors who are not employed by Dliv or any of its affiliates.
      </p>
      <p className={styles.modalSubtext}>
        1.5. All commercial and contractual terms are offered by and agreed to between customers and merchants alone.
        Such terms include without limitation price, taxes, delivery costs, payment methods, payment terms, date and
        mode of delivery, warranties related to products and services and after sales services related to products and
        services. Dliv does not have any control or does not determine or advise or in any way involve itself in the
        offering or acceptance of such terms between the customers and merchants. Dliv’s services are limited to
        offering support services to businesses in respect to order fulfilment, payment collection, and other logistical
        support services, pursuant to independent contracts executed by Dliv with such businesses.
      </p>

      <Typography.Title level={4} className={styles.modalSubTitle}>
        2. Acceptance of these Terms
      </Typography.Title>
      <p className={styles.modalText}>
        By using the Dliv platform, including but not limited to our website, our mobile application, and more
        generally our Services, you agree to these terms and conditions (the “Terms”), which constitute a legally
        binding agreement between you and Dliv
      </p>
      <p className={styles.modalSubtext}>
        <span className={styles.insertion}>!</span> Please read these terms carefully before accessing or using the
        Dliv platform. If you do not agree to be bound by these terms, you may not use or access the Dliv
        platform.
      </p>
      <p className={styles.modalText}>
        Supplemental terms may apply to the provisions of our Services. Such supplemental terms will be agreed with you
        separately. Supplemental terms are in addition to, and shall be deemed a part of these Terms for the purposes of
        the applicable Services.
      </p>
      <p className={styles.modalText}>
        Supplemental terms shall prevail over these Terms to the extent of any inconsistency.
      </p>
      <p className={styles.modalSubtext}>
        <span className={styles.insertion}>!</span> Dliv may amend these terms from time to time. Amendments will be
        effective upon Dliv’s posting of updated terms at this location (www.Dliv.com) or the amended policies or
        supplemental terms on the applicable service. Your continued access or use of the Dliv platform after such
        posting constitutes your consent to be bound by these terms, as amended.
      </p>

      <Typography.Title level={4} className={styles.modalSubTitle}>
        3. Your use of the Dliv Platform
      </Typography.Title>
      <p className={styles.modalText}>3.1. Your Use of the Dliv Platform</p>
      <p className={styles.modalText}>
        Any decision to make use of or accept the Dliv Platform and/or the Services is a decision made in your sole
        discretion. Each service provided by a retailer, restaurant, driver or other third-party provider to you shall
        constitute a separate agreement with such persons.
      </p>
      <p className={styles.modalText}>
        Materials posted on the Dliv Platform are not intended to amount to advice on which reliance should be
        placed. We therefore disclaim all liability and responsibility arising from any reliance placed on such
        materials by you or any other visitor to the Dliv Platform, or by anyone who may be informed of any of its
        contents.
      </p>
      <p className={styles.modalText}>
        We aim to update the Dliv Platform regularly and may change the content at any time. If the need arises, we
        may suspend access to the Dliv Platform and the Services or close them indefinitely. Any of the material on
        the Dliv Platform or the Services may be out of date at any given time, and we are under no obligation to
        update such material.
      </p>
      <p className={styles.modalText}>3.2. Third Party Services and Content</p>
      <p className={styles.modalText}>
        The Dliv Platform may be made available or accessed in connection with third party services and content
        (including advertising) that Dliv does not control. You acknowledge that different terms of use and privacy
        policies may apply to your use of such third-party services and content. Dliv does not endorse such
        third-party services and content and in no event shall Dliv be responsible or liable for any products or
        services of such third-party providers. Additionally, Apple Inc., Google, Inc., and/or their applicable
        international subsidiaries and affiliates will be third-party beneficiaries to these Terms if you access the
        Dliv Platform using applications developed for Apple iOS or Android-powered mobile devices, respectively.
        These third-party beneficiaries are not parties to these Terms and are not responsible for the provision or
        support of the Dliv Platform in any manner. Your access to the Dliv Platform using these services or
        applications is subject to terms set forth in the applicable third-party beneficiary’s terms of service.
      </p>
      <p className={styles.modalText}>3.3. Dliv Platform Ownership</p>
      <p className={styles.modalText}>
        The Dliv Platform and all rights therein are and shall remain Dliv’s property. Neither these Terms nor
        your use of the Dliv Platform convey or grant to you any rights: (i) in or related to the Dliv Platform
        except for the limited license granted herein; or (ii) to use or reference in any manner Dliv’s company
        names, logos, product and service names, trademarks or services marks or those of Dliv’s licensors.
      </p>
      <p className={styles.modalText}>3.4. User Account</p>
      <p className={styles.modalText}>
        In order to use the Dliv Platform, you must register for and maintain an active user account (“User
        Account”). You agree to maintain accurate, complete, and up-to-date information in your User Account. Your
        failure to maintain accurate, complete, and up-to-date User Account information may result in your inability to
        access and use the Dliv Platform, including your ability to request access to your personal information or to
        opt in or out of marketing preferences. You agree to maintain the security and secrecy of your User Account
        username and password at all times. You agree and understand that you are responsible for all activity that
        occurs under your User Account, even as a result of loss, damage or theft of the device through which you access
        the Dliv Platform. Unless otherwise permitted by Dliv in writing, you may only possess one User Account.
      </p>
      <p className={styles.modalText}>3.5. User Requirements and Conduct</p>
      <p className={styles.modalText}>
        Dliv reserves the right to disable any User Account, at any time, if in Dliv’s reasonable opinion you have
        failed to comply with these Terms. You may not assign or otherwise transfer your User Account to any other
        person or entity. You agree to comply with all applicable laws when using the Dliv Platform, and you may only
        use the Dliv Platform for lawful purposes. You shall not, for the avoidance of doubt, use the Dliv
        Platform to transport, facilitate or procure the transport of illegal or banned substances or items. You will
        not, in your use of the Dliv Platform, cause nuisance, annoyance, inconvenience, or property damage, to any
        third party. In certain instances, you may be asked to provide proof of identity to access or use the Dliv
        Platform, and you agree that you may be denied access to or use of the Dliv Platform if you refuse to provide
        proof of identity. Failure to comply with the terms of this section may result in our taking certain actions
        against you, including but not limited to: (i) immediate, temporary or permanent withdrawal of your right to use
        the Dliv Platform (ii) legal action against you including proceedings for reimbursement of all costs and
        expenses (including, but not limited to, reasonable administrative and legal costs) resulting from the breach
        (iii) disclosure of such information to law enforcement authorities as we feel is necessary and/or (iv)
        immediate, temporary or permanent removal of any posting or material uploaded by you to our Service.
      </p>
      <p className={styles.modalText}>3.6. Notifications</p>
      <p className={styles.modalText}>
        By becoming a user, you agree to receive communications from us, including via e-mail, text message, calls, and
        push notifications. You agree that texts, calls or pre-recorded messages may be generated by automatic telephone
        dialing systems (where allowed by and in compliance with applicable law).
      </p>
      <p className={styles.modalText}>3.7. Restricted Activities</p>
      <p className={styles.modalText}>
        With respect to your use of the Dliv Platform and your participation in the Services, you agree that you will
        not: (i) impersonate any person or entity (ii) violate any law, statute, rule, permit, ordinance or regulation
        (iii) interfere with or disrupt the Services or the Dliv Platform or the servers or networks connected to the
        Dliv Platform (iv) post information or interact on the Dliv Platform or with respect to Services in a
        manner which is false, inaccurate, misleading (directly or by omission or failure to update information),
        defamatory, libelous, abusive, obscene, profane, offensive, sexually oriented, threatening, harassing, or
        illegal (v) use the Dliv Platform in any way that infringes any third party’s rights, including but not
        limited to: intellectual property rights, copyright, patent, trademark, trade secret or other proprietary rights
        or rights of publicity or privacy (vi) post, email or otherwise transmit any malicious code, files or programs
        designed to interrupt, damage, destroy or limit the functionality of any computer software or hardware or
        telecommunications equipment or surreptitiously intercept or expropriate any system, data or personal
        information (vii) forge headers or otherwise manipulate identifiers in order to disguise the origin of any
        information transmitted through the Dliv Platform (viii) “frame” or “mirror” any part of the Dliv
        Platform, without our prior written authorization or use meta tags or code or other devices containing any
        reference to us in order to direct any person to any other web site for any purpose (ix) modify, adapt,
        translate, reverse engineer, decipher, decompile or otherwise disassemble any portion of the Dliv Platform or
        any software used on or for the Dliv Platform (x) rent, lease, lend, sell, redistribute, license or
        sublicense the Dliv Platform or access to any portion of the Dliv Platform (xi) use any robot, spider,
        site search/retrieval application, or other manual or automatic device or process to retrieve, index, scrape,
        “data mine”, or in any way reproduce or circumvent the navigational structure or presentation of the Dliv
        Platform or its contents (xiv) link directly or indirectly to any other web sites (xii) transfer or sell your
        User Account, password and/or identification to any other party (xiii) cause any third party to engage in the
        restricted activities above. In the event that you undertake in any of the above while participating in
        Services, a third-party provider shall be permitted to refuse to provide you Services or, if such Services have
        commenced, a third-party provider driver shall be permitted to refuse to continue to provide you Services.
      </p>
      <p className={styles.modalText}>3.8. Prohibited Items</p>
      <p className={styles.modalText}>
        Dliv does not accept any packages containing prohibited items. Prohibited items include combustible/hazardous
        materials, cash, firearms, gold, precious metals, narcotics, personal letters, any delicate or fragile products,
        and any other items prohibited by applicable laws. The list of prohibited items may be updated, from time to
        time. You are entirely responsible for any consequences (in whichever form) arising out of an attempt to
        dispatch prohibited items through Dliv.
      </p>

      <Typography.Title level={4} className={styles.modalSubTitle}>
        4. Charges and Payments
      </Typography.Title>
      <p className={styles.modalText}>
        4.1. You agree to pay applicable charges for the Services provided to you by Dliv. Applicable rates and other
        payment terms, including invoicing periodicity, will be agreed between you and Dliv in a separate agreement
      </p>
      <p className={styles.modalText}>
        4.2. You shall be responsible for the payment of any and all contributions or taxes applicable to you under
        applicable law.
      </p>
      <p className={styles.modalText}>
        4.3. In the event of value added tax (“VAT”) being implemented under applicable law at any time, Dliv shall
        be entitled to apply the applicable VAT rate to the relevant Dliv fees and charges, starting from the
        implementation date for such VAT.
      </p>

      <Typography.Title level={4} className={styles.modalSubTitle}>
        5. Provision of Services
      </Typography.Title>
      <p className={styles.modalText}>
        You acknowledge that portions of the Services may be made available under other Dliv brands and/or provided
        in accordance with specific request and/or logistic options. You also acknowledge that the Services may be made
        available under such brands or request options by or in connection with: (i) certain affiliates; or (ii)
        independent third-party contractors, including transportation network company drivers or holders of similar
        transportation permits, authorizations or licenses. It is at Dliv’s discretion which brands or request
        options are made available to you.
      </p>

      <Typography.Title level={4} className={styles.modalSubTitle}>
        6. Intellectual Property
      </Typography.Title>
      <p className={styles.modalText}>
        6.1. Dliv is is the owner and/or the licensee of all intellectual property rights in the Dliv Platform.
        All such rights are reserved.
      </p>
      <p className={styles.modalText}>
        6.2. All intellectual property rights in or arising out of or in connection with your use of the Dliv
        Platform and/or in connection with the Services shall be owned by Dliv. Dliv grants you a non-exclusive,
        non-transferable right to use the Dliv Platform’s features solely in connection with the Services.
      </p>
      <p className={styles.modalText}>
        6.3. You agree that you shall not copy, decompile, reverse engineer or otherwise derive or attempt to derive
        source code from the Dliv Platform except as is permitted by applicable law where such actions cannot be
        prohibited because they are essential for the purpose of achieving inter-operability of the Dliv Platform
        with another software program, and provided that the information obtained by you during such activities:
      </p>
      <p className={styles.modalText}>
        (a) is use only for the purpose of achieving inter-operability of the Dliv Platform with another software
        program;
      </p>
      <p className={styles.modalText}>
        (b) is not unnecessarily disclosed or communicated without our prior written consent to any third party; and
      </p>
      <p className={styles.modalText}>
        (c) is not used to create any software or service that is substantially similar to the Dliv Platform or the
        Services.
      </p>

      <Typography.Title level={4} className={styles.modalSubTitle}>
        7. Data Protection and Privacy
      </Typography.Title>
      <p className={styles.modalText}>
        Dliv is committed to your privacy. By using our Services, you acknowledge and agree that you will comply with
        applicable laws. Our collection and use of personal information in connection with the Dliv Platform is as
        provided in Dliv’s Privacy Policy located at <span className={styles.insertion}>www.Dliv.com.</span>
      </p>

      <Typography.Title level={4} className={styles.modalSubTitle}>
        8. Disclamers. Limitation of Liability. Indemnity
      </Typography.Title>
      <p className={styles.modalText}>8.1. Disclaimer</p>
      <p className={styles.modalText}>
        The Dliv platform and services are provided “as is” and “as available.” Dliv disclaims all representations
        and warranties, express, implied or statutory, not expressly set out in these terms in relation to the Dliv
        platform, the services and third party providers, including the implied warranties of merchantability, fitness
        for a particular purpose and non-infringement. In addition, Dliv makes no representation, warranty, or
        guarantee regarding the reliability, timeliness, quality, suitability or availability of the Dliv platform,
        services or any other services requested through the use of the Dliv platform, or that the Dliv platform
        will be uninterrupted or error-free. Dliv and its affiliates do not guarantee the quality, suitability,
        safety or ability of any third-party providers. You agree that the entire risk arising out of your use of the
        Dliv platform, services, third party providers and any other service requested in connection therewith,
        remains solely with you, to the maximum extent permitted under applicable law.
      </p>
      <p className={styles.modalText}>
        Dliv and its affiliates are not responsible for the conduct, whether online or offline, of any third-party
        provider.
      </p>
      <p className={styles.modalText}>
        Dliv and its affiliates expressly disclaim any liability arising from the unauthorized use of your user
        account. should you suspect that any unauthorized party may be using your user account or you suspect any other
        breach of security, you agree to notify us immediately.
      </p>
      <p className={styles.modalText}>
        Location data provided by the Dliv platform is for basic location purposes only and is not intended to be
        relied upon in situations where precise location information is needed or where erroneous, inaccurate or
        incomplete location data may lead to death, personal injury, property or environmental damage. Neither Dliv,
        nor its affiliates nor any of its content providers, guarantees the availability, accuracy, completeness,
        reliability, or timeliness of location data tracked or displayed by the Dliv platform. Any of your
        information, including geolocational data, you upload, provide, or post on the Dliv platform may be
        accessible to Dliv and third-party providers.
      </p>
      <p className={styles.modalText}>
        Third party providers are independently obligated to comply with applicable laws, rules, regulations, and
        standards pertaining to food preparation, sale, marketing and safety and Dliv does not in any way
        independently verify that a third party provider is in compliance with applicable law. Dliv does not in any
        way independently verify the credentials, representations or products of third-party providers. Dliv shall
        not be liable or responsible for any food or services offered by third party providers or retailer that is
        unhealthy, is the cause of injury, that is otherwise unacceptable to you or that does not meet your expectation
        in any manner.
      </p>
      <p className={styles.modalText}>
        You are solely responsible for verifying the accuracy of delivery addresses, and Dliv shall have no liability
        or responsibility for any such erroneous addresses. All food preparation and deliveries are the sole
        responsibility of the third-party providers accepting the order. Please be aware that delivery times may be
        affected by factors beyond their control and therefore cannot be guaranteed. We will inform you if we become
        aware of an unexpected delay.
      </p>
      <p className={styles.modalText}>8.2. Limitation of Liability</p>
      <p className={styles.modalText}>
        To the maximum extent permitted by applicable law, Dliv, its affiliates, subsidiaries, directors, officers,
        employees, agents, consultants or representatives shall not be liable for indirect, incidental, special,
        exemplary, punitive or consequential damages, including lost profits, lost data, personal injury or property
        damage related to, in connection with, or otherwise resulting from any use of the Dliv platform, Dliv
        services and/or third-party providers, even if Dliv has been advised of the possibility of such damages.
      </p>
      <p className={styles.modalText}>
        Dliv, its affiliates, subsidiaries, directors, officers, employees, agents, consultants or representatives
        shall not be liable for any damages, liability or losses arising out of: (i) your use of or reliance on the
        Dliv platform or the services or your inability to access or use the Dliv platform or the services; or
        (ii) any transaction or relationship between you and any third party provider, even if Dliv or its affiliates
        or its partners have been advised of the possibility of such damages. Dliv and its affiliates and its
        partners shall not be liable for delay or failure in performance resulting from causes beyond our reasonable
        control.
      </p>
      <p className={styles.modalText}>8.3. Indemnity </p>
      <p className={styles.modalText}>
        You agree to indemnify and hold Dliv, its affiliates, subsidiaries, directors, officers, employees, agents,
        consultants or representatives harmless from any and all claims of whatsoever kind, demands, losses,
        liabilities, and expenses, including third party claims and attorneys’ fees, arising out of or in connection
        with: (i) your use of the Dliv Platform and the Services and/or (ii) services or goods obtained by third
        parties through your use of the Dliv Platform and/or (iii) your violation of the rights of any third party
      </p>

      <Typography.Title level={4} className={styles.modalSubTitle}>
        9. Technical Limitations
      </Typography.Title>
      <p className={styles.modalText}>
        9.1. The Dliv Platform and Services are provided without express or implied warranty or condition of any
        kind, on an “as-is” basis and all implied warranties (whether by statute, custom or otherwise) are excluded to
        the maximum extent permitted by applicable law.
      </p>
      <p className={styles.modalText}>
        9.2. You acknowledge and accept that your access to the Dliv Platform is dependent on connectivity over
        communications networks and facilities that are outside of our operation and/or control and that your use of the
        Dliv Platform may be subject to limitations, delays and other problems inherent in the use of such networks
        and facilities.
      </p>
      <p className={styles.modalText}>
        9.3. We give no warranty that your use of the Dliv Platform will be uninterrupted or error free. We will use
        our reasonable endeavours to provide a good, consistent service, however, you acknowledge and accept that we are
        not responsible for any loss or damage of any kind that you may suffer as a result of any interruption or delay
        to your access to the Dliv Platform, or as a result of any failure or error in the transfer of data over
        those networks and facilities in connection with your use of the Dliv Platform.
      </p>

      <Typography.Title level={4} className={styles.modalSubTitle}>
        10. Governing law and dispute resolution
      </Typography.Title>
      <p className={styles.modalText}>
        10.1. These Terms shall be governed by and construed in accordance with the laws of the State of Qatar, without
        giving effect to any conflict of law principles.
      </p>
      <p className={styles.modalText}>10.2. Arbitration</p>
      <p className={styles.modalText}>
        Any dispute arising in connection with the Dliv Platform, the Services and/or these Terms shall be referred
        to and finally resolved by arbitration. Any arbitration held under these Terms shall be administered as follows:
      </p>
      <p className={styles.modalText}>
        (a) The arbitration shall be administered in accordance with the Rules of Arbitration of the International
        Chamber of Commerce (the “ICC Rules”) which such rules are deemed to be incorporated by reference into this
        Clause;
      </p>
      <p className={styles.modalText}>(b) The seat of arbitration shall be Doha, Qatar;</p>
      <p className={styles.modalText}>(c) Any meetings or hearings shall be held in Doha, Qatar;</p>
      <p className={styles.modalText}>(d) One (1) arbitrator shall be appointed in accordance with the ICC Rules;</p>
      <p className={styles.modalText}>(e) The language of the proceedings shall be English;</p>
      <p className={styles.modalText}>
        (f) The arbitrators’ award shall be final and binding on us and shall not be subject to any appeal.
      </p>
      <p className={styles.modalText}>
        (g) The appointed arbitrator shall assign responsibility for the costs and fees for the arbitration, including
        administrative costs and fees and legal, witness and expert costs and fees, in light of its determination on the
        merits and taking into consideration the conduct of the parties including the extent (if any) to which a Party
        failed to cooperate with the other party in appointing the arbitrators.
      </p>

      <Typography.Title level={4} className={styles.modalSubTitle}>
        11. How to contact Us
      </Typography.Title>
      <p className={styles.modalText}>
        If you have any queries or issues regarding the Dliv Platform or the Services, please email
        <span className={styles.insertion}>
          {" "}
          <a href="mailto:support@Dliv.com">support@Dliv.com</a>.
        </span>
      </p>
    </div>
  </Modal>
);

export default TermsConditionsModal;
