import React from "react";
import { Table, Select, Input, Divider, DatePicker, Radio } from "antd";

import { getStatusBage } from "../../../../helpers/general";
import CustomDivider from "../../../../components/Divider";
import DeliveryReportsDetails from "./../../../DeliveryReport/DesktopView/components/DeliveryReports/ReportDetails";
import { isMainServer } from "../../../../constants/config";
import { useHistory, useLocation } from "react-router";
import { useHooks } from "./hooks/useHooks";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as retailerOrdersActions } from "../../../../redux/resources/retailerOrders";
import { FormattedMessage, useIntl } from "react-intl";

const { Option } = Select;
const { RangePicker } = DatePicker;

const RetailerReports = ({ fetchReportsRetailerOrder, deleteOneReports = () => {}, hideTitle }) => {
  //   isDeliveryReportFetching,
  //   reports,
  //   searchedValue,
  //   searchFilter,
  //   paginationConfig,
  //   disabledTomorrowAndFuture,
  //   setSearchedValue,
  //   onChangeSearchFilter,
  //   onChangePagination,
  //   handleSearchDelivery,
  //   handleSetDates,
  //   deleteReport,
  //   reportStatusQuery,
  //   setReportStatusQuery,
  //   reportTypeQuery,
  //   setReportTypeQuery,

  // !!!
  //

  const location = useLocation();
  const history = useHistory();
  const {
    reports,
    isDeliveryReportFetching,
    searchedValue,
    searchFilter,
    paginationConfig,
    disabledTomorrowAndFuture,
    setSearchedValue,
    onChangeSearchFilter,
    onChangePagination,
    handleSearchDelivery,
    handleSetDates,
    reportStatusQuery,
    setReportStatusQuery,
  } = useHooks({ location, history, isMobile: false, fetchReportsRetailerOrder, deleteOneReports });

  const mainServerColums = [
    // {
    //   title: "Delete",
    //   key: "key",
    //   render: (data) => {
    //     return (
    //       <Popconfirm
    //         title="Are you sure delete this report?"
    //         onConfirm={deleteReport(data.id)}
    //         okText="Yes"
    //         cancelText="No"
    //       >
    //         <DeleteOutlined style={{ color: "red", textAlign: "center" }} />
    //       </Popconfirm>
    //     );
    //   },
    // },
  ];

  const intl = useIntl();

  const columns = [
    {
      title: `${intl.formatMessage({
        id: "tabPane.orderID",
        defaultMessage: "Order ID",
      })}`,
      key: "orderId",
      render: ({ orderId }) => {
        if (!orderId) {
          return "No order";
        }
        return orderId;
      },
    },

    {
      title: `${intl.formatMessage({
        id: "status",
        defaultMessage: "Status",
      })}`,
      key: "status",
      dataIndex: "status",
      render: (status) => getStatusBage(status),
    },
    {
      title: `${intl.formatMessage({
        id: "page.home.reports.table.createdAt",
        defaultMessage: "Created at",
      })}`,
      key: "createdAt",
      render: ({ createdAt }) => {
        const milisec = new Date(createdAt);
        return milisec.toLocaleDateString();
      },
    },
    {
      title: `${intl.formatMessage({
        id: "driver",
        defaultMessage: "Driver",
      })}`,
      key: "driver",
      render: (data) => {
        const driverFullName =
          data.driver?.profile && `${data.driver.profile.firstName} ${data.driver.profile.lastName}`;
        return driverFullName;
      },
    },
    ...(isMainServer ? mainServerColums : []),
  ];

  const selectSearchFilter = (
    <Select defaultValue="driverName" className="select-before" onChange={onChangeSearchFilter}>
      {searchFilter.map(({ value, text }) => (
        <Option value={value} key={value}>
          {text}
        </Option>
      ))}
    </Select>
  );

  const formatReports = (reports) => {
    if (reports?.length) {
      return reports.map((e) => {
        return { ...e, orderId: e?.order?.id };
      });
    } else return [];
  };

  return (
    <div className="pageWrapper retailerCabinetContent">
      {!hideTitle && (
        <>
          <div>
            <h2 className="pageTitle">Delivery Reports</h2>
            <CustomDivider />
          </div>
          <Divider />
        </>
      )}

      <h3>
        <FormattedMessage id="page.home.reports.filters.type" defaultMessage="Order type" />:
      </h3>
      <Radio.Group value="pickup_report">
        <Radio.Button value="pickup_report">
          <FormattedMessage id="page.home.reports.filters.type.utp" defaultMessage="Unable to pick up" />
        </Radio.Button>
      </Radio.Group>
      <Divider />
      <h3>
        <FormattedMessage id="page.home.reports.filters.status" defaultMessage="Order status" />:
      </h3>
      <Radio.Group value={reportStatusQuery} onChange={(e) => setReportStatusQuery(e.target.value)}>
        <Radio.Button value="all">
          <FormattedMessage id="page.home.reports.filters.status.all" defaultMessage="All" />{" "}
        </Radio.Button>
        <Radio.Button value="new">
          <FormattedMessage id="page.home.reports.filters.status.new" defaultMessage="New" />
        </Radio.Button>
        <Radio.Button value="processing">
          <FormattedMessage id="page.home.reports.filters.status.pending" defaultMessage="Pending" />
        </Radio.Button>
        <Radio.Button value="resolved">
          <FormattedMessage id="page.home.reports.filters.status.resolved" defaultMessage="Resolved" />
        </Radio.Button>
      </Radio.Group>
      <Divider />
      <div className="filtersWrapper">
        <div className="searchWrapper">
          <Input
            value={searchedValue}
            onChange={(e) => setSearchedValue(e.target.value)}
            placeholder="Search"
            addonBefore={selectSearchFilter}
            onPressEnter={handleSearchDelivery}
            className="searchReports"
          />
          <div className="datePickerWrapper">
            <RangePicker format="YYYY-MM-DD" onChange={handleSetDates} disabledDate={disabledTomorrowAndFuture} />
          </div>
        </div>
      </div>
      <Divider />

      {!!reports?.length && (
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={formatReports(reports)}
          loading={isDeliveryReportFetching}
          onChange={onChangePagination}
          expandable={{
            expandedRowRender: (record) => <DeliveryReportsDetails data={record} />,
          }}
          pagination={paginationConfig}
        />
      )}
    </div>
  );
};

const MSTP = (state) => {
  return {
    // reports: state.reports.deliveryReports?.items,
    // isDeliveryReportFetching: state.reports.deliveryReports?.isFetching,
    // paginationData: state.reports.deliveryReports?.pagination,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(retailerOrdersActions, dispatch),
}))(RetailerReports);
