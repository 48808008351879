import React from "react";
import { Typography } from "antd";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import localeEnUs from "../../../../utils/IntlProvider/locales/en_US.json";
import ArrowLeft from "../../../../assets/newLanding/ArrowLeft";
import GetStartedButton from "../GetStartedButton";
import styles from "./successAuth.module.less";

const SuccessSingUp = ({ title, subtitle, message, goToBtnLink, goToBtnLabel }) => (
  <>
    <Typography.Title level={2} className={styles.successTitle}>
      <Typography.Text className={styles.successSubtitle}>
        <FormattedMessage id={subtitle} defaultMessage={localeEnUs[subtitle]} /> <br />
      </Typography.Text>
      <FormattedMessage id={title} defaultMessage={localeEnUs[title]} />
    </Typography.Title>

    {message && (
      <Typography.Paragraph className={styles.successMessage}>
        <FormattedMessage id={message} defaultMessage={localeEnUs[message]} />
      </Typography.Paragraph>
    )}

    <GetStartedButton className={styles.backToMainPageBtn}>
      <Link to={goToBtnLink}>
        <ArrowLeft />
        <FormattedMessage id={goToBtnLabel} defaultMessage={localeEnUs[goToBtnLabel]} />
      </Link>
    </GetStartedButton>
  </>
);

export default SuccessSingUp;
