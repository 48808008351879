import React from "react";
import { Col, Row } from "antd";

import DownloadBtn from "../DownloadBtn";
import ImageFile from "../ImageFile";
import PdfFile from "../PdfFile";
import "./style.less";

const DriverDocuments = ({ driver }) => {
  const extension = (url) => {
    return url.split(".").pop().toLowerCase();
  };

  const file = (url) => {
    if (extension(url) !== "pdf") {
      return <ImageFile url={url} />;
    }
    return <PdfFile url={url} />;
  };

  return (
    <>
      {driver.documents && (
        <Row>
          Documents:
          {driver.documents.carRegistrationDocuments && (
            <Col span={24}>
              <strong>Car Registration Documents:</strong>
              <div className="documentWrapper">
                {file(driver.documents.carRegistrationDocuments.url)}
                <DownloadBtn path={driver.documents.carRegistrationDocuments.url} />
              </div>
            </Col>
          )}
          {driver.documents.drivingLicense && (
            <Col span={24}>
              <strong>Driving License:</strong>

              <div className="documentWrapper">
                {file(driver.documents.drivingLicense.url)}
                <DownloadBtn path={driver.documents.drivingLicense.url} />
              </div>
            </Col>
          )}
          {driver.documents.nationalId && (
            <Col span={24}>
              <strong>National Id:</strong>

              <div className="documentWrapper">
                {file(driver.documents.nationalId.url)}
                <DownloadBtn path={driver.documents.nationalId.url} />
              </div>
            </Col>
          )}
          {driver.documents.policeClearanceCertificate && (
            <Col span={24}>
              <strong>Police Clearance Certificate:</strong>
              <div className="documentWrapper">
                {file(driver.documents.policeClearanceCertificate.url)}
                <DownloadBtn path={driver.documents.policeClearanceCertificate.url} />
              </div>
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

export default DriverDocuments;
