import React, { FC } from "react";
import { JobStatus, OrderStatus, TagState } from "src/constants/jobsStatus";
import "./style.less";

const StatusTag: FC<any> = ({ status }) => {
  const getColorByStatus = (status: String) => {
    switch (status) {
      case JobStatus.NEW:
        return TagState.DEFAULT;
      case JobStatus.COMPLETE:
      case OrderStatus.COMPLETED:
      case OrderStatus.COMPLETE_RETURN:
      case OrderStatus.ARRIVED:
        return TagState.SUCCESS;
      case JobStatus.IN_TRANSIT:
      case OrderStatus.DELIVERY:
      case OrderStatus.PICKED:
        return TagState.ONGOING;
      case OrderStatus.POSTPONED:
      case OrderStatus.POSTPONED_AFTER_PICKUP:
        return TagState.BASIC;
      case OrderStatus.CANCELED:
      case OrderStatus.CANCELED_AFTER_PICKUP:
      case OrderStatus.FAILED_DELIVERY:
      case OrderStatus.FAILED_PICKUP:
        return TagState.FAILED;
      default:
        return TagState.DEFAULT;
    }
  };
  return <span className={`status-tag status-tag-${getColorByStatus(status)}`}>{status?.split("_").join(" ")}</span>;
};

export default StatusTag;
