import React, { FC, useEffect, useState } from "react";
import { Button, Form, Input, Popover } from "antd";
import initialAddressFields from "../../../config/initialAddressFields";
import { IJobCustomerDetails } from "../../../../../types/JobManagement";

import "./customerDetails.less";

const CustomerDetails: FC<IJobCustomerDetails> = ({
  orderInfo,
  updateOrderCash,
  setExpandedRowKeys,
  selectedJobId,
}) => {
  const [isEditingCashAmount, setIsEditingCashAmount] = useState<boolean>(false);
  const [cashAmount, setCashAmount] = useState<any>(null);
  const [form] = Form.useForm();

  const initialCashAmount = (value: any) => {
    form.setFields([{ name: "cashAmount", value }]);
  };

  useEffect(() => {
    initialCashAmount(orderInfo.cashAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderInfo.cashAmount]);

  const onChangeCashAmount = ({ cashAmount }: { cashAmount: any }) => {
    setCashAmount(cashAmount);
  };

  const onSaveCashAmount = async () => {
    setExpandedRowKeys(null);
    await updateOrderCash(cashAmount, selectedJobId);
    setIsEditingCashAmount(false);
  };

  const onCancelCashAmount = () => {
    setIsEditingCashAmount(false);
    initialCashAmount(orderInfo.cashAmount);
  };

  const onVisibleCashAmountPopupChange = (bool: any) => {
    setIsEditingCashAmount(bool);
  };

  const preventDefault = (e: any) => {
    e.preventDefault();
  };

  const isValidCashAmount = (value: any) => {
    return /^\d+(\.\d+)*$/.test(value);
  };

  return (
    <div className="customerInfoWrapper">
      <p className="customerInfoTitle"> Customer: </p>
      <p className="customerInfoItem">
        Name:{" "}
        {orderInfo.customerInfo.name ? (
          <span>{orderInfo.customerInfo.name}</span>
        ) : (
          <span className="noData">No name</span>
        )}
      </p>
      <p className="customerInfoItem">
        Phone number:{" "}
        {orderInfo.customer.phoneNumber ? (
          <span>{orderInfo.customer.phoneNumber}</span>
        ) : (
          <span className="noData">No phone number</span>
        )}
      </p>
      <p className="customerInfoItem">
        Lat and lng:{" "}
        <span>
          {orderInfo.deliveryPoint.lat ? (
            `lat: ${orderInfo.deliveryPoint.lat} long: ${orderInfo.deliveryPoint.long}`
          ) : (
            <span className="noData">No lat and long found</span>
          )}
        </span>
      </p>
      {initialAddressFields(orderInfo)
        .filter(({ value }) => value)
        .map(({ label, value }) => (
          <p className="customerInfoItem">
            {label}: <span>{value}</span>
          </p>
        ))}
      <p className="customerInfoItem">
        Amount Cash On Delivery:{" "}
        <Popover
          content={
            <div onClick={preventDefault} className="cash-amount-popup-content">
              <Form form={form} onValuesChange={onChangeCashAmount}>
                <Form.Item
                  name="cashAmount"
                  rules={[
                    { required: true, message: "Required field!" },
                    () => ({
                      validator(rule, value) {
                        if (isValidCashAmount(value)) {
                          return Promise.resolve();
                        }

                        return Promise.reject("Only float numbers");
                      },
                    }),
                  ]}
                >
                  <Input />
                </Form.Item>
              </Form>
              <div className="cash-amount-popup-content__btn-group">
                <Button onClick={onSaveCashAmount} disabled={!isValidCashAmount(cashAmount)}>
                  Save
                </Button>
                <Button onClick={onCancelCashAmount}>Cancel</Button>
              </div>
            </div>
          }
          trigger="click"
          visible={isEditingCashAmount}
          onVisibleChange={onVisibleCashAmountPopupChange}
        >
          <span className="cash-amount-link">{orderInfo.cashAmount || "No cash amount"}</span>
        </Popover>
      </p>
    </div>
  );
};

export default CustomerDetails;
