export const formatCompleted = (order) =>
  order?.status === "completed" && order?.isReturn ? "return completed" : order?.status;

// jobs status query
export const getJobStatusQuery = (status) => {
  const query = status
    .map((s, i) => {
      if (i === 0) return s;
      else return `statuses[${i}]=${s}`;
    })
    .join("&");

  return { "statuses[0]": query };
};
