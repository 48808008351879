// @ts-nocheck 
import React, { FC } from "react";
import { CheckCircleOutlined, CloseCircleOutlined, IssuesCloseOutlined } from "@ant-design/icons";
import { CFRScreenEnum, ICFRResultsProps, ResultScreenTypeEnum } from "src/types/CloudFleetRouting";
import MessageOutput from "./MessageOutput";
import "./cfr-results.less";
import { Button, Typography } from "antd";
import { connect } from "react-redux";
import { RootState } from "src/redux";
import { useHistory } from "react-router-dom";
import { actions as jobsMapActions } from "../../../redux/resources/jobsMap";
import { isRetailer } from "../../../helpers/authStorage";
import { BASE_ROUTE } from "../../../routes/index";
import { bindActionCreators } from "redux";

const { Title } = Typography;

const CFRResults: FC<any> = ({ type, cfrResults, onScreenChange }) => {
  const navigate = useHistory();

  const onClickBack = () => {
    onScreenChange(CFRScreenEnum.ORDERS_SCREEN, { clearCFRState: true });
  };

  const onClickJobView = () => {
    if (isRetailer) {
      navigate.push(`/${BASE_ROUTE}/jobs`);
    } else {
      navigate.push(`/admin/jobs`);
    }
  };

  const onClickTryAgain = () => {
    onScreenChange(CFRScreenEnum.CFR_SETTINGS_SCREEN, { clearCFRState: true });
  };

  const renderSuccessScreen = () => {
    return (
      <>
        <div className="success-header">
          <CheckCircleOutlined className="icon" />
          <Title level={4} className="title">
            Job Created Successfuly
          </Title>
        </div>
        <div className="message-output-wrapper">
          <MessageOutput cfrResults={cfrResults} />
        </div>
        <div className="cfr-results-actions">
          <Button className="view-job-btn" type="default" onClick={onClickBack}>
            Back
          </Button>
          <Button className="view-job-btn" type="primary" onClick={onClickJobView}>
            View Job
          </Button>
        </div>
      </>
    );
  };

  const renderErrorScreen = () => {
    return (
      <>
        <div className="error-header">
          <CloseCircleOutlined className="icon" />
          <Title level={4} className="title">
            Oops! Something went wrong.
          </Title>
        </div>
        {cfrResults &&
          ("message" in cfrResults ? (
            <p className="error-message">{cfrResults.message}</p>
          ) : (
            <div className="message-output-wrapper">
              <MessageOutput cfrResults={cfrResults} />
            </div>
          ))}
        <div className="cfr-results-actions">
          <Button className="view-job-btn" type="default" onClick={onClickBack}>
            Back
          </Button>
          {/* <Button className="view-job-btn" type="primary" onClick={onClickTryAgain}>
            Try Again
          </Button> */}
        </div>
      </>
    );
  };

  const renderPartialSuccessScreen = () => {
    return (
      <>
        <div className="partial-success-header">
          <IssuesCloseOutlined className="icon" />
          <Title level={4} className="title">
            Job Created Successfuly
          </Title>
        </div>
        <div className="message-output-wrapper">
          <MessageOutput cfrResults={cfrResults} />
        </div>
        <div className="cfr-results-actions">
          <Button className="view-job-btn" type="default" onClick={onClickBack}>
            Back
          </Button>
          <Button className="view-job-btn" type="primary" onClick={onClickJobView}>
            View Job
          </Button>
        </div>
      </>
    );
  };

  const renderResultScreen = () => {
    switch (type) {
      case ResultScreenTypeEnum.SUCCESS:
        return renderSuccessScreen();
      case ResultScreenTypeEnum.PARTIAL_SUCCESS:
        return renderPartialSuccessScreen();
      case ResultScreenTypeEnum.ERROR:
        return renderErrorScreen();
      default:
        return <p>Empty screen</p>;
    }
  };

  return <div className="cfr-results-container">{renderResultScreen()}</div>;
};

const MSTP = (state: RootState, props: ICFRResultsProps) => {
  const { onScreenChange, type } = props;

  return {
    type,
    onScreenChange,
    isCFRInstance: state.jobsMap.CFRInstance?.isRunning,
    estimatedTime: state.jobsMap.CFRInstance?.estimatedTime,
    cfrResults: state.jobsMap.cfrResults,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(jobsMapActions, dispatch),
}))(CFRResults);
