import React, { useState, useEffect } from "react";
import { Table, Switch, Button, Modal, DatePicker } from "antd";
import moment from "moment";
import { API_URL } from "src/constants/config";
import { notification } from "antd";

const Subscriptions = () => {
  const currentDate = new Date();

  const [companiesData, setCompaniesData] = useState([]);
  const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState((currentDate.getMonth() + 1).toString().substring(1));
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear().toString());
  const [numberOfOrders, setNumberOfOrders] = useState(0);

  const [plansPrice, setPlanPrice] = useState(0);

  const getOrdersByMonthYear = async () => {
    try {
      const authToken = localStorage.getItem("authTokenSuper");
      const response = await fetch(
        `${API_URL}/superadmin/total-packages/${selectedCompany.id}/${selectedYear}/${selectedMonth}`,
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        },
      );

      if (response.ok) {
        const data = await response.json();
        const { totalPackagesCount } = data.totalPackagesCount;
        setNumberOfOrders(totalPackagesCount);
      } else {
        throw new Error("Failed to fetch packages count for the selected date");
      }
    } catch (error) {
      console.error("Error fetching packages count:", error);
    }
  };

  const getSubscriptionPrice = async () => {
    try {
      const authToken = localStorage.getItem("authTokenSuper");
      const response = await fetch(`${API_URL}/superadmin/subscriptions`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        const final = data.find((plan) => plan?.subscription_type === selectedCompany?.subscription_type);
        console.group("+++++++");
        console.log(final?.price);

        console.groupEnd("------");

        setPlanPrice(final?.price || 0);
      } else {
        throw new Error("Failed to fetch packages count for the selected date");
      }
    } catch (error) {
      console.error("Error fetching packages count:", error);
    }
  };

  useEffect(() => {
    getOrdersByMonthYear();
  }, [selectedMonth, selectedYear, selectedCompany]);

  useEffect(() => {
    getSubscriptionPrice();
    console.log(plansPrice[0]?.price);
  }, [selectedCompany]);

  const handleGenerateInvoice = (company) => {
    setSelectedCompany(company);
    setInvoiceModalVisible(true);
    fetchInvoices(company.id);
  };

  const handleModalCancel = () => {
    setInvoiceModalVisible(false);
    setSelectedMonth(currentDate?.getMonth() + 1)
      .toString()
      .substring(1);
    setSelectedYear(currentDate?.getFullYear().toString());
  };

  const fetchData = async () => {
    try {
      const authToken = localStorage.getItem("authTokenSuper");
      const response = await fetch(`${API_URL}/superadmin/allcompanies`, {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCompaniesData(data);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    setSelectedYear(currentDate.getFullYear().toString());
    setSelectedMonth((currentDate.getMonth() + 1).toString());
  }, []);

  const handleToggleStatus = async (id) => {
    try {
      const updatedStatus =
        companiesData.find((company) => company.id === id)?.status === "active" ? "inactive" : "active";

      const authToken = localStorage.getItem("authTokenSuper");
      const response = await fetch(`${API_URL}/superadmin/change_company_status/${id}/${updatedStatus}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.ok) {
        const updatedCompaniesData = companiesData.map((company) =>
          company.id === id ? { ...company, status: updatedStatus } : company,
        );
        setCompaniesData(updatedCompaniesData);
      } else {
        throw new Error("Failed to update company status");
      }
    } catch (error) {
      console.error("Error updating company status:", error);
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Subscription Type",
      key: "subscription_type",
      dataIndex: "subscription_type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Switch
          checked={text === "active"}
          onChange={() => handleToggleStatus(record.id)}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          style={{ backgroundColor: text === "active" ? "green" : "#cf1322" }}
        />
      ),
    },
    {
      title: "Generate invoice",
      key: "generate_invoice",
      render: (_, record) => (
        <Button
          type="primary"
          shape="round"
          style={{ backgroundColor: "#1890ff", color: "#fff" }}
          onClick={() => handleGenerateInvoice(record)}
        >
          Generate invoice
        </Button>
      ),
    },
  ];

  const handelCreateInvoice = async () => {
    try {
      const billingPeriodText = `${moment(selectedYear + "-" + selectedMonth + "-01").format("MMMM YYYY")} to ${moment(
        selectedYear + "-" + selectedMonth + "-01",
      )
        .endOf("month")
        .format("MMMM DD, YYYY")}`;

      const invoicePayload = {
        invoice_date: moment().format("YYYY-MM-DD"),
        billing_period: billingPeriodText,
        total_amount: plansPrice * numberOfOrders,
        paid_at: null,
        status: "not paid",
        identifier: `${selectedCompany?.name}#${selectedMonth}#${selectedYear}#${selectedCompany?.id}`,
        attachment_data: null,
        company_id: selectedCompany.id,
      };

      const generateInvoiceResponse = await fetch(`${API_URL}/superadmin/invoice`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("authTokenSuper")}`,
        },
        body: JSON.stringify(invoicePayload),
      });
      const response = await generateInvoiceResponse.json();

      if (response?.statusCode === 200) {
        setInvoiceModalVisible(false);
        notification.success({
          message: "Invoice Generated",
          description: `Invoice for ${selectedMonth} - ${selectedYear} Generated.`,
        });
      } else if (response?.statusCode === 409) {
        notification.error({
          message: "Invoice Generation Error",
          description: `Invoice for ${selectedMonth} - ${selectedYear}  already exists.`,
        });
      } else {
        throw new Error("Failed to generate invoice");
      }
    } catch (error) {
      console.error("Error generating invoice:", error);
    }
  };

  /** Disable the selection for months that already had an invoice (START) */
  const [invoicesData, setInvoicesData] = useState([]);

  const fetchInvoices = async (companyId) => {
    try {
      const data = await fetch(`${API_URL}/superadmin/invoice/${companyId}`);
      const result = await data.json();
      setInvoicesData(result);
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);



  

  const isMonthDisabled = (current) => {
    const currentDate = moment();
    
    // Disable future months
    const futureMonths = moment().add(1, 'month'); // You can adjust the range as needed
  
    return (
      isMonthTagged(current) || 
      current.isAfter(futureMonths, 'month') || 
      current.isAfter(currentDate, 'month')
    );
  };
 
  const isMonthTagged = (current) => {
    const companyId = selectedCompany?.id;
    
    // Check if an invoice exists for the selected month
    const taggedMonths = invoicesData
      .filter((invoice) => invoice.company_id === companyId)
      .map((invoice) => moment(invoice.billing_period, "MMMM YYYY"));
  
    return taggedMonths.some((taggedMonth) => current.isSame(taggedMonth, 'month'));
  };
  /** Disable the selection for months that already had an invoice (END) */

  return (
    <div className="allDriversSettings">
      <h2>Subscription</h2>
      <Table
        dataSource={companiesData}
        columns={columns}
        scroll={{ x: "max-content" }}
        responsive={true}
        className="superAdminTables"
        rowKey={(record) => record?.id} 

      />

      <Modal title="Invoice Generation" visible={invoiceModalVisible} onCancel={handleModalCancel} footer={null}>
        <div style={{ padding: "24px" }}>
          <div style={{ marginBottom: "24px" }}>
            <DatePicker.MonthPicker
              clearIcon={false}
              placeholder="Select Month and Year"
              onChange={(date, dateString) => {
                const [year, month] = dateString.split("-");
                setSelectedYear(year);
                setSelectedMonth(month);
              }}
              defaultValue={moment(`${selectedYear}-${selectedMonth}`, "YYYY-MM")}
              disabledDate={isMonthDisabled}
              dateRender={(current) => {
                if (isMonthTagged(current)) {
                  return <div className="tagged-month">{current.format("MMMM YYYY")} (Generated)</div>;
                }
                return current.date();
              }}
            />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <h1 style={{ fontSize: "1.5rem", marginBottom: "8px" }}>Invoice Summary</h1>
            <p>
              <strong>Company Name:</strong> {selectedCompany?.name}
            </p>
            <p>
              <strong>Invoice Date:</strong> {currentDate.toDateString()}
            </p>
            <p>
              <strong>Billing Period:</strong>{" "}
              {`${moment(selectedYear + "-" + selectedMonth + "-01").format("MMMM YYYY")} to ${moment(
                selectedYear + "-" + selectedMonth + "-01",
              )
                .endOf("month")
                .format("MMMM DD, YYYY")}`}
            </p>
            <p>
              <strong>Total Number of Orders:</strong> <span style={{ color: "blue" }}>{numberOfOrders}</span>
            </p>
            <p>
              <strong>Subscription Type:</strong> {selectedCompany?.subscription_type} (
              <span style={{ color: "green" }}>{plansPrice} per order</span>)
            </p>
            <p>
              <strong>Total Amount:</strong>{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>{plansPrice * numberOfOrders}</span>
            </p>
            <p>
              <strong>Invoice Identifier:</strong>{" "}
              {`${selectedCompany?.name}-${selectedMonth}-${selectedYear}-${selectedCompany?.id}`}
            </p>
          </div>

          <div>
            <Button type="primary" style={{ marginRight: "8px" }} onClick={handelCreateInvoice}>
              Generate Invoice for Selected Date
            </Button>
            <Button onClick={handleModalCancel}>Cancel</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(Subscriptions);
