import React from "react";

const AppStoreEng = () => (
  <svg width="136" height="32" viewBox="0 0 136 32" fill="current" xmlns="http://www.w3.org/2000/svg">
    <path d="M130.963 0.64C132.085 0.64 133.161 0.993999 133.955 1.62412C134.748 2.25424 135.194 3.10887 135.194 4V28C135.194 28.8911 134.748 29.7458 133.955 30.3759C133.161 31.006 132.085 31.36 130.963 31.36H5.03704C3.91488 31.36 2.83868 31.006 2.04519 30.3759C1.2517 29.7458 0.805926 28.8911 0.805926 28V4C0.805926 3.10887 1.2517 2.25424 2.04519 1.62412C2.83868 0.993999 3.91488 0.64 5.03704 0.64H130.963ZM130.963 0H5.03704C3.70113 0 2.41994 0.421427 1.47531 1.17157C0.530686 1.92172 0 2.93913 0 4V28C0 29.0609 0.530686 30.0783 1.47531 30.8284C2.41994 31.5786 3.70113 32 5.03704 32H130.963C132.299 32 133.58 31.5786 134.525 30.8284C135.469 30.0783 136 29.0609 136 28V4C136 2.93913 135.469 1.92172 134.525 1.17157C133.58 0.421427 132.299 0 130.963 0Z" />
    <path d="M26.1662 15.4504C26.1436 12.9937 28.215 11.7985 28.3097 11.7428C27.1366 10.0636 25.3184 9.83418 24.6794 9.81589C23.1523 9.65811 21.671 10.7131 20.8931 10.7131C20.0997 10.7131 18.9017 9.83114 17.6107 9.85705C15.9492 9.88221 14.395 10.8266 13.5425 12.2932C11.7833 15.2835 13.0953 19.6778 14.7808 22.0949C15.624 23.2787 16.6092 24.6004 17.8987 24.5539C19.1603 24.5028 19.6315 23.7642 21.154 23.7642C22.6624 23.7642 23.105 24.5539 24.4201 24.5242C25.7741 24.5028 26.6265 23.3351 27.4402 22.1406C28.4145 20.7838 28.8058 19.4476 28.8213 19.379C28.7895 19.3683 26.1918 18.395 26.1662 15.4504Z" />
    <path d="M23.6817 8.22571C24.3602 7.39257 24.8245 6.25911 24.6956 5.10889C23.7135 5.15157 22.4853 5.77585 21.778 6.59069C21.1523 7.30873 20.5933 8.48563 20.7377 9.59241C21.8409 9.67321 22.9736 9.04588 23.6817 8.22571Z" />
    <path d="M54.4236 24.3837H52.6605L51.6947 21.4041H48.3377L47.4177 24.3837H45.7012L49.0271 14.2397H51.0813L54.4236 24.3837ZM51.4035 20.154L50.5301 17.5052C50.4377 17.2346 50.2646 16.5974 50.0092 15.5943H49.9781C49.8764 16.0257 49.7126 16.6629 49.4875 17.5052L48.6296 20.154H51.4035Z" />
    <path d="M62.9766 20.6369C62.9766 21.8809 62.6342 22.8642 61.9495 23.586C61.3361 24.2286 60.5745 24.5495 59.6654 24.5495C58.6841 24.5495 57.9792 24.2034 57.5498 23.5113H57.5188V27.3645H55.8636V19.4775C55.8636 18.6955 55.8426 17.8928 55.8022 17.0696H57.2579L57.3503 18.229H57.3814C57.9334 17.3554 58.771 16.9194 59.8952 16.9194C60.7741 16.9194 61.5077 17.2602 62.0946 17.9424C62.6831 18.6253 62.9766 19.5233 62.9766 20.6369ZM61.2903 20.6964C61.2903 19.9844 61.1273 19.3975 60.7997 18.9356C60.4418 18.4538 59.9612 18.213 59.3588 18.213C58.9504 18.213 58.5793 18.3471 58.2478 18.6116C57.9155 18.8784 57.6981 19.2268 57.5964 19.6582C57.5452 19.8594 57.5196 20.0241 57.5196 20.1536V21.3732C57.5196 21.9053 57.6857 22.3542 58.018 22.7209C58.3503 23.0875 58.7819 23.2705 59.3129 23.2705C59.9364 23.2705 60.4216 23.0342 60.7686 22.5631C61.1164 22.0913 61.2903 21.4693 61.2903 20.6964Z" />
    <path d="M71.5455 20.6369C71.5455 21.8809 71.2031 22.8642 70.5176 23.586C69.9051 24.2286 69.1435 24.5495 68.2343 24.5495C67.253 24.5495 66.5481 24.2034 66.1195 23.5113H66.0885V27.3645H64.4333V19.4775C64.4333 18.6955 64.4123 17.8928 64.3719 17.0696H65.8276L65.92 18.229H65.9511C66.5023 17.3554 67.34 16.9194 68.4649 16.9194C69.343 16.9194 70.0766 17.2602 70.6651 17.9424C71.2513 18.6253 71.5455 19.5233 71.5455 20.6369ZM69.8593 20.6964C69.8593 19.9844 69.6954 19.3975 69.3678 18.9356C69.0099 18.4538 68.5309 18.213 67.9277 18.213C67.5185 18.213 67.1482 18.3471 66.8159 18.6116C66.4837 18.8784 66.267 19.2268 66.1653 19.6582C66.1149 19.8594 66.0885 20.0241 66.0885 20.1536V21.3732C66.0885 21.9053 66.2546 22.3542 66.5854 22.7209C66.9176 23.0868 67.3493 23.2705 67.8819 23.2705C68.5053 23.2705 68.9905 23.0342 69.3376 22.5631C69.6854 22.0913 69.8593 21.4693 69.8593 20.6964Z" />
    <path d="M81.1256 21.5392C81.1256 22.4021 80.8205 23.1041 80.2079 23.646C79.5348 24.2383 78.5978 24.5341 77.3936 24.5341C76.2819 24.5341 75.3906 24.3237 74.7159 23.9022L75.0995 22.5477C75.8261 22.9791 76.6235 23.1956 77.4922 23.1956C78.1156 23.1956 78.6009 23.0568 78.9494 22.7809C79.2965 22.505 79.4696 22.1345 79.4696 21.6726C79.4696 21.261 79.3268 20.9142 79.0403 20.6329C78.7554 20.3516 78.2794 20.0902 77.6149 19.8485C75.806 19.1862 74.9023 18.2158 74.9023 16.9398C74.9023 16.1059 75.219 15.4222 75.8533 14.8901C76.4853 14.3573 77.3284 14.0913 78.3827 14.0913C79.3229 14.0913 80.1039 14.2521 80.7273 14.573L80.3135 15.8978C79.7312 15.5868 79.0729 15.4313 78.3361 15.4313C77.7538 15.4313 77.2989 15.5724 76.9728 15.8529C76.6972 16.1036 76.559 16.4093 76.559 16.7714C76.559 17.1723 76.7166 17.5039 77.0334 17.7646C77.309 18.0054 77.8097 18.2661 78.5364 18.5474C79.4254 18.8988 80.0783 19.3096 80.4983 19.7807C80.9167 20.2502 81.1256 20.8379 81.1256 21.5392Z" />
    <path d="M86.5984 18.2892H84.774V21.8405C84.774 22.7438 85.0954 23.195 85.7398 23.195C86.0356 23.195 86.2809 23.1698 86.475 23.1195L86.5208 24.3536C86.1947 24.4733 85.7654 24.5335 85.2336 24.5335C84.5799 24.5335 84.0691 24.3376 83.7003 23.9466C83.3331 23.5548 83.1483 22.8977 83.1483 21.9746V18.2877H82.0614V17.0681H83.1483V15.7288L84.774 15.2471V17.0681H86.5984V18.2892Z" />
    <path d="M94.83 20.6664C94.83 21.7907 94.5024 22.7137 93.8487 23.4356C93.1631 24.1788 92.2532 24.5492 91.119 24.5492C90.0259 24.5492 89.1556 24.1933 88.5065 23.4813C87.8575 22.7694 87.533 21.8707 87.533 20.7876C87.533 19.6541 87.8668 18.7257 88.5368 18.0038C89.2052 17.2812 90.1074 16.9199 91.2416 16.9199C92.3348 16.9199 93.2144 17.2759 93.8782 17.9886C94.5132 18.6799 94.83 19.5725 94.83 20.6664ZM93.1127 20.719C93.1127 20.0444 92.9659 19.4658 92.6686 18.9833C92.3216 18.3994 91.8255 18.1083 91.1826 18.1083C90.5173 18.1083 90.0119 18.4002 89.6649 18.9833C89.3675 19.4666 89.2208 20.0543 89.2208 20.7494C89.2208 21.424 89.3675 22.0026 89.6649 22.4843C90.0228 23.0682 90.5227 23.3594 91.1679 23.3594C91.7998 23.3594 92.2959 23.0621 92.6538 22.4691C92.959 21.9774 93.1127 21.3928 93.1127 20.719Z" />
    <path d="M100.21 18.4988C100.046 18.4691 99.8716 18.4538 99.6884 18.4538C99.1061 18.4538 98.6558 18.6695 98.339 19.1017C98.0634 19.4829 97.9253 19.9646 97.9253 20.5462V24.3841H96.2708L96.2864 19.3731C96.2864 18.5301 96.2654 17.7625 96.2242 17.0704H97.6659L97.7265 18.4698H97.7723C97.947 17.9889 98.2226 17.6016 98.5999 17.3112C98.9687 17.0498 99.367 16.9194 99.7963 16.9194C99.9492 16.9194 100.087 16.9301 100.21 16.9492V18.4988Z" />
    <path d="M107.613 20.3808C107.613 20.672 107.593 20.9175 107.552 21.1179H102.586C102.606 21.8405 102.846 22.3932 103.307 22.7743C103.725 23.115 104.267 23.2858 104.931 23.2858C105.666 23.2858 106.337 23.1707 106.94 22.9397L107.2 24.0678C106.495 24.3697 105.662 24.5198 104.702 24.5198C103.547 24.5198 102.64 24.186 101.98 23.519C101.322 22.852 100.992 21.9564 100.992 20.8328C100.992 19.7299 101.298 18.8114 101.913 18.0789C102.555 17.2968 103.424 16.9058 104.517 16.9058C105.591 16.9058 106.404 17.2968 106.956 18.0789C107.393 18.7001 107.613 19.4684 107.613 20.3808ZM106.034 19.9593C106.045 19.4776 105.937 19.0614 105.713 18.71C105.426 18.258 104.986 18.0324 104.394 18.0324C103.853 18.0324 103.413 18.2526 103.076 18.6948C102.801 19.0461 102.637 19.4677 102.586 19.9586H106.034V19.9593Z" />
    <path d="M50.8565 7.99936C50.8565 8.89652 50.5824 9.57187 50.0351 10.0254C49.5281 10.4439 48.8077 10.6535 47.8745 10.6535C47.4118 10.6535 47.0158 10.6337 46.6843 10.594V5.69204C47.1168 5.62344 47.5826 5.58838 48.0857 5.58838C48.9746 5.58838 49.6446 5.77818 50.0964 6.15778C50.6026 6.58692 50.8565 7.20053 50.8565 7.99936ZM49.9986 8.02146C49.9986 7.43987 49.8418 6.99396 49.5281 6.68296C49.2145 6.37273 48.7564 6.21723 48.1532 6.21723C47.897 6.21723 47.6788 6.234 47.498 6.26906V9.99568C47.5981 10.0109 47.7813 10.0178 48.0476 10.0178C48.6703 10.0178 49.1508 9.8478 49.4893 9.50784C49.8278 9.16788 49.9986 8.67242 49.9986 8.02146Z" />
    <path d="M55.4051 8.78293C55.4051 9.33556 55.2444 9.78833 54.923 10.1435C54.5861 10.5087 54.1397 10.6908 53.5822 10.6908C53.045 10.6908 52.6172 10.5163 52.2981 10.1656C51.9798 9.81577 51.8207 9.37443 51.8207 8.84238C51.8207 8.28595 51.9845 7.82936 52.3137 7.47492C52.6428 7.12047 53.0854 6.94287 53.6428 6.94287C54.18 6.94287 54.6117 7.11742 54.9385 7.4673C55.2491 7.80726 55.4051 8.24631 55.4051 8.78293ZM54.5612 8.80885C54.5612 8.47727 54.4883 8.19295 54.3431 7.95589C54.1723 7.66929 53.9293 7.52599 53.6133 7.52599C53.2864 7.52599 53.038 7.66929 52.8672 7.95589C52.7213 8.19295 52.6491 8.48184 52.6491 8.82333C52.6491 9.15491 52.722 9.43922 52.8672 9.67628C53.0434 9.96288 53.2888 10.1062 53.6055 10.1062C53.9161 10.1062 54.1599 9.9606 54.3353 9.66866C54.4859 9.42703 54.5612 9.14042 54.5612 8.80885Z" />
    <path d="M61.5042 7.01611L60.3591 10.6093H59.6138L59.1394 9.04902C59.0191 8.65951 58.9212 8.27229 58.8452 7.88812H58.8304C58.7598 8.28296 58.6619 8.66942 58.5362 9.04902L58.0323 10.6093H57.2785L56.2017 7.01611H57.0378L57.4516 8.7243C57.5517 9.12829 57.634 9.51323 57.7 9.87758H57.7148C57.7753 9.57726 57.8755 9.19461 58.0168 8.73193L58.5362 7.01688H59.1992L59.6968 8.69534C59.8172 9.10466 59.915 9.49875 59.9903 9.87834H60.0128C60.0679 9.50865 60.151 9.11457 60.2612 8.69534L60.7053 7.01688H61.5042V7.01611Z" />
    <path d="M65.7221 10.6091H64.9085V8.55105C64.9085 7.91687 64.6631 7.59977 64.1709 7.59977C63.9295 7.59977 63.7346 7.68667 63.5832 7.86122C63.4334 8.03578 63.3573 8.24158 63.3573 8.47712V10.6084H62.5437V8.04264C62.5437 7.72707 62.5336 7.38482 62.5142 7.01437H63.2292L63.2672 7.57614H63.2897C63.3845 7.40159 63.5258 7.25752 63.7113 7.14242C63.9318 7.00827 64.1787 6.94043 64.4489 6.94043C64.7905 6.94043 65.0746 7.04867 65.3005 7.26591C65.5816 7.53193 65.7221 7.92906 65.7221 8.45654V10.6091Z" />
    <path d="M67.9656 10.6091H67.1527V5.36719H67.9656V10.6091Z" />
    <path d="M72.7561 8.78293C72.7561 9.33556 72.5954 9.78833 72.274 10.1435C71.937 10.5087 71.4899 10.6908 70.9332 10.6908C70.3952 10.6908 69.9674 10.5163 69.6491 10.1656C69.3308 9.81577 69.1716 9.37443 69.1716 8.84238C69.1716 8.28595 69.3354 7.82936 69.6646 7.47492C69.9938 7.12047 70.4363 6.94287 70.993 6.94287C71.531 6.94287 71.9619 7.11742 72.2895 7.4673C72.6 7.80726 72.7561 8.24631 72.7561 8.78293ZM71.9114 8.80885C71.9114 8.47727 71.8384 8.19295 71.6933 7.95589C71.5232 7.66929 71.2795 7.52599 70.9642 7.52599C70.6366 7.52599 70.3882 7.66929 70.2182 7.95589C70.0722 8.19295 70 8.48184 70 8.82333C70 9.15491 70.073 9.43922 70.2182 9.67628C70.3944 9.96288 70.6397 10.1062 70.9565 10.1062C71.267 10.1062 71.51 9.9606 71.6855 9.66866C71.8369 9.42703 71.9114 9.14042 71.9114 8.80885Z" />
    <path d="M76.6936 10.6093H75.963L75.9025 10.1954H75.88C75.63 10.5254 75.2736 10.6908 74.8109 10.6908C74.4654 10.6908 74.186 10.5818 73.9756 10.3654C73.7846 10.1687 73.6891 9.92401 73.6891 9.63359C73.6891 9.19454 73.8754 8.85992 74.2504 8.62819C74.6246 8.39647 75.151 8.2829 75.8287 8.28823V8.22116C75.8287 7.7478 75.5756 7.51151 75.0687 7.51151C74.7077 7.51151 74.3894 7.60069 74.1145 7.77753L73.9492 7.2531C74.2892 7.04654 74.7092 6.94287 75.2045 6.94287C76.161 6.94287 76.6408 7.43833 76.6408 8.42925V9.75251C76.6408 10.1115 76.6587 10.3974 76.6936 10.6093ZM75.8489 9.37443V8.82028C74.9514 8.80504 74.5027 9.04667 74.5027 9.54441C74.5027 9.73193 74.554 9.87218 74.6588 9.96593C74.7636 10.0597 74.8971 10.1062 75.0563 10.1062C75.2348 10.1062 75.4017 10.0505 75.5539 9.94002C75.7069 9.82873 75.8008 9.68771 75.8357 9.51468C75.8443 9.47581 75.8489 9.42855 75.8489 9.37443Z" />
    <path d="M81.3166 10.6091H80.5946L80.5566 10.0321H80.5341C80.3035 10.4712 79.9106 10.6907 79.3586 10.6907C78.9177 10.6907 78.5504 10.5207 78.2593 10.1808C77.9682 9.8408 77.823 9.39946 77.823 8.85751C77.823 8.27591 77.9806 7.80485 78.2974 7.44507C78.604 7.10968 78.9798 6.94199 79.427 6.94199C79.9184 6.94199 80.2623 7.10434 80.458 7.42982H80.4735V5.36719H81.2879V9.6411C81.2879 9.99097 81.2972 10.3134 81.3166 10.6091ZM80.4735 9.0938V8.49468C80.4735 8.39101 80.4657 8.30717 80.451 8.24314C80.4052 8.05105 80.3066 7.88946 80.1567 7.75911C80.0053 7.62877 79.8229 7.56322 79.6125 7.56322C79.3089 7.56322 79.0714 7.68136 78.8967 7.91842C78.7236 8.15548 78.6358 8.45809 78.6358 8.82778C78.6358 9.18299 78.7189 9.47111 78.8858 9.69293C79.0621 9.92922 79.2996 10.0474 79.597 10.0474C79.8641 10.0474 80.0776 9.94904 80.2398 9.75162C80.3966 9.56944 80.4735 9.34992 80.4735 9.0938Z" />
    <path d="M88.2755 8.78293C88.2755 9.33556 88.1148 9.78833 87.7934 10.1435C87.4564 10.5087 87.0108 10.6908 86.4526 10.6908C85.9161 10.6908 85.4884 10.5163 85.1685 10.1656C84.8502 9.81577 84.691 9.37443 84.691 8.84238C84.691 8.28595 84.8549 7.82936 85.184 7.47492C85.5132 7.12047 85.9557 6.94287 86.5139 6.94287C87.0504 6.94287 87.4828 7.11742 87.8089 7.4673C88.1194 7.80726 88.2755 8.24631 88.2755 8.78293ZM87.4324 8.80885C87.4324 8.47727 87.3594 8.19295 87.2142 7.95589C87.0426 7.66929 86.8004 7.52599 86.4837 7.52599C86.1576 7.52599 85.9091 7.66929 85.7376 7.95589C85.5916 8.19295 85.5194 8.48184 85.5194 8.82333C85.5194 9.15491 85.5924 9.43922 85.7376 9.67628C85.9138 9.96288 86.1591 10.1062 86.4759 10.1062C86.7864 10.1062 87.031 9.9606 87.2064 9.66866C87.3563 9.42703 87.4324 9.14042 87.4324 8.80885Z" />
    <path d="M92.6499 10.6091H91.837V8.55105C91.837 7.91687 91.5917 7.59977 91.0987 7.59977C90.8573 7.59977 90.6624 7.68667 90.5118 7.86122C90.3612 8.03578 90.2859 8.24158 90.2859 8.47712V10.6084H89.4715V8.04264C89.4715 7.72707 89.4622 7.38482 89.4427 7.01437H90.157L90.195 7.57614H90.2176C90.3131 7.40159 90.4543 7.25752 90.6391 7.14242C90.8604 7.00827 91.1065 6.94043 91.3774 6.94043C91.7183 6.94043 92.0024 7.04867 92.2283 7.26591C92.5102 7.53193 92.6499 7.92906 92.6499 8.45654V10.6091Z" />
    <path d="M98.1256 7.61433H97.2297V9.35987C97.2297 9.8035 97.3889 10.0253 97.7041 10.0253C97.85 10.0253 97.9712 10.0131 98.0666 9.98796L98.0876 10.5939C97.9269 10.6534 97.7157 10.6831 97.4557 10.6831C97.1342 10.6831 96.8843 10.5871 96.7034 10.395C96.5217 10.2029 96.4316 9.87972 96.4316 9.42619V7.61433H95.8967V7.01597H96.4316V6.35739L97.229 6.12109V7.01521H98.1249V7.61433H98.1256Z" />
    <path d="M102.433 10.6091H101.619V8.56633C101.619 7.92223 101.374 7.5998 100.882 7.5998C100.505 7.5998 100.247 7.78655 100.106 8.16005C100.082 8.23856 100.068 8.33461 100.068 8.44742V10.6084H99.2549V5.36719H100.068V7.53273H100.083C100.339 7.13865 100.707 6.94199 101.183 6.94199C101.52 6.94199 101.798 7.05022 102.02 7.26746C102.295 7.53806 102.433 7.94053 102.433 8.47257V10.6091Z" />
    <path
      d="M106.876 8.6427C106.876 8.786 106.865 8.90644 106.846 9.00477H104.406C104.417 9.35997 104.533 9.63057 104.759 9.81808C104.966 9.98578 105.232 10.0696 105.558 10.0696C105.919 10.0696 106.248 10.0132 106.545 9.89964L106.672 10.4546C106.325 10.6024 105.917 10.6764 105.444 10.6764C104.877 10.6764 104.431 10.5125 104.108 10.1847C103.783 9.85696 103.622 9.41714 103.622 8.86528C103.622 8.32332 103.772 7.87207 104.074 7.51229C104.389 7.12812 104.816 6.93604 105.354 6.93604C105.88 6.93604 106.28 7.12812 106.55 7.51229C106.768 7.81719 106.876 8.1945 106.876 8.6427ZM106.1 8.43613C106.106 8.19907 106.052 7.99479 105.942 7.82253C105.801 7.60071 105.586 7.48943 105.295 7.48943C105.029 7.48943 104.813 7.59766 104.647 7.8149C104.512 7.98793 104.432 8.1945 104.406 8.43613H106.1Z"
      fill="#E9E9E9"
    />
  </svg>
);

export default AppStoreEng;
