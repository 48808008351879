import React, { memo, useRef, FC } from "react";
import GoogleMapReact from "google-map-react";

import PickupPointMarker from "../PickupPointMarker";
import { API_KEY, mapCenters } from "../../../constants/config";
import { v4 as uuidv4 } from "uuid";

import "./style.less";
import { LightMapStyle } from "./mapStyles/lightMap";
import { markersColors } from "./mapStyles/markerColors";
import CustomerJobPickerMarker from "../CustomerMarker/CustomerJobPickerMarker";
import { getPickupPoint } from "src/helpers/maps/maps.helpers";
import { Spin } from "antd";

type mapCentersKey = keyof typeof mapCenters;

const JobsMapRoutes: FC<{
  items: any[];
  itemsForCombine: any[];
  calculateAndDisplayRoute?: (
    items: any[],
    map: google.maps.Map,
    maps: typeof google.maps,
    prevResults?: {
      routes: Map<number, any>;
    },
  ) => Promise<void>;
  isMoveOrders: boolean;
  jobs: any[];
  routes?: any;
  tenant: mapCentersKey;
  availableJobs: (id: number) => { jobId: number }[];
  handleMoveOrder: (orderId: number, jobId: number, originalJobId: number) => Promise<void>;
  mapHeight?: string;
  showRoutes?: boolean;
  isLoading?: boolean;
  mapKey?: string;
}> = (props) => {
  const {
    items,
    itemsForCombine,
    calculateAndDisplayRoute,
    jobs,
    routes,
    tenant,
    mapHeight,
    availableJobs,
    handleMoveOrder,
    isMoveOrders = true,
    showRoutes,
    isLoading = false,
    mapKey,
  } = props;
  const defaultCenter = { lat: mapCenters[tenant].lat, lng: mapCenters[tenant].long };
  const mapRef = useRef<google.maps.Map>();

  const allItems = [...items, ...itemsForCombine];

  const getAllOrders = () => {
    const arr: any[] = [];
    allItems?.length > 0 &&
      allItems.forEach((item: any) => (item?.deliveryOrders || item?.orders)?.forEach((order: any) => arr.push(order)));
    return arr;
  };

  const defaultPickUpPoint = getPickupPoint(items);
  const defaultPickUpPointCenter = defaultPickUpPoint
    ? { lat: +defaultPickUpPoint?.lat, lng: +defaultPickUpPoint?.long }
    : defaultCenter;

  const mapMarkers = (markerType: any, Component: any) => {
    const arrayOfOrders = getAllOrders();
    let correctedMarkerType = markerType === "customer" ? "deliveryPoint" : markerType;

    return allItems.map((item, i) => {
      let jobColor = markersColors[i % 10];
      return (item?.deliveryOrders || item?.orders)?.map((order: any) => {
        const res = arrayOfOrders.filter((elem) => {
          return (
            elem[correctedMarkerType]?.lat === order[correctedMarkerType]?.lat &&
            elem[correctedMarkerType]?.long === order[correctedMarkerType]?.long
          );
        });

        const location = order[correctedMarkerType];

        return (
          <Component
            key={uuidv4()}
            lat={location?.lat}
            lng={location?.long}
            jobId={item.id}
            info={res}
            color={markerType === "pickupPoint" ? null : jobColor}
            availableJobs={availableJobs}
            handleMoveOrder={isMoveOrders ? handleMoveOrder : []}
            isMoveOrders={isMoveOrders}
          />
        );
      });
    });
  };

  return (
    <div className="map-wrapper" style={{ height: mapHeight ?? "80wh" }} data-testid="job-orders-map">
      {isLoading ? (
        <div className="job-dertails-loading">
          <Spin size="large" />
        </div>
      ) : (
        <GoogleMapReact
          key={mapKey || `map-key-${items?.length}-${showRoutes}`}
          bootstrapURLKeys={{ key: API_KEY, libraries: ["geometry"] }}
          options={{
            styles: LightMapStyle,
          }}
          defaultCenter={defaultCenter}
          center={defaultPickUpPointCenter}
          zoom={defaultPickUpPoint ? 10 : 5}
          defaultZoom={0}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={
            calculateAndDisplayRoute && showRoutes
              ? ({ map, maps }) => {
                  mapRef.current = map;
                  const allJobs = [...jobs, ...itemsForCombine];
                  calculateAndDisplayRoute(allJobs, map, maps, routes);
                }
              : () => {}
          }
        >
          {items?.length > 0 && mapMarkers("customer", CustomerJobPickerMarker)}
          {items?.length > 0 && mapMarkers("pickupPoint", PickupPointMarker)}
        </GoogleMapReact>
      )}
    </div>
  );
};

export default memo(JobsMapRoutes);
