import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Select } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import classnames from "classnames";
import queryString from "query-string";

import "./styles.less";

const { Option } = Select;

export const MobileCustomSelect = ({
  isFromOrders,
  options,
  label,
  selectedTab,
  onFilterStatus,
  isOrdersCompleted,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const { search } = useLocation();
  const { locationShared, retailerName } = queryString.parse(search);

  const newOptions = options && [{ text: "All", value: "" }, ...options];

  const inputWidth = isFromOrders || !selectedValue ? { width: "64px" } : { width: "105px" };

  useEffect(() => {
    if (selectedTab && !locationShared && !retailerName) {
      setSelectedValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, search]);

  const onSelect = (value) => {
    setSelectedValue(value);
    onFilterStatus(value);
  };

  return (
    <div className="custom-select">
      <span className="custom-select__label">{label}:</span>
      <Select
        bordered={false}
        value={selectedValue}
        style={inputWidth}
        // dropdownRender={(menu) => <div style={isOrdersCompleted ? { width: "120px",position: "relative",left:"-50px",} : {}}>{menu}</div>}
        className={classnames(
          "custom-select__select",
          selectedValue === "accepted" && "select-accepted",
          selectedValue === "declined" && "select-declined",
          ["pending", "true", "false"].includes(selectedValue) && "select-pending",
          selectedValue === "has_issue" && "select-issue",
        )}
        dropdownClassName="custom-select__dropdown"
        suffixIcon={<CaretDownOutlined rotate={isOpened ? 180 : 0} />}
        onDropdownVisibleChange={setIsOpened}
        onChange={onSelect}
      >
        {newOptions?.map(({ text, value, isMarquee }) => (
          <Option className={isMarquee && "scroll-container"} value={value} key={value}>
            <div className={isMarquee && "scroll-text"}>{text}</div>
          </Option>
        ))}
      </Select>
    </div>
  );
};
