import React from "react";

import { Button, Divider, Select, Input, Row, Col, Tooltip, Tag, Menu, Dropdown } from "antd";
import {
  DownloadOutlined,
  WarningTwoTone,
  PrinterOutlined,
  ControlOutlined,
  EyeOutlined,
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import UploadedOrdersTable from "../UploadedOrdersTable";
import ReportsModal from "./components/Reports/ReportsModal";
import OrdersModal from "./components/OrdersModal";
import FiltersModal from "./components/filtersModal";

import { useHooks } from "./useHooks/useHooks";
import "./ordersWidgetHome.less";
import { useSettings } from "./constants/menu";
import OrdersFileDraggerModal from "./components/OrdersFileDraggerModal";
import { CustomCard } from "../../../utils/common";
import OrdersBulkActionsDropdown from "./components/OrdersBulkActions";
import OrdersActionConfirmModal from "./components/OrdersActionModal/OrdersActionConfirmModal";

const { Option } = Select;

const OrdersWidgetHome = ({
  hideHeader,
  children,
  retailer,
  tablesView,
  isAdminOrders,
  userURL,
  ordersList = null,
  ordersUploading = false,
  saveOrders = new Promise((resolve) => resolve()),
  pagination,
  onChangeQueryParams,
  onCancelOrder,
  onDeleteOrder,
  onDeleteOrders,
  onPostponeOrder,
  onCreateReturn,
  onCompleteOrder,
  onRemoveLocation,
  onFetchAllOrders,
  onHandleSelectOrder,
  filteredRetailer,
  filteredAddress,
  filteredLocationShared,
  retailersList,
  returnToShipper,
  onChangeLocationAdminOrder,
  showReportsButton = true,
  editPhone,
  restoreAdminOrder,
  storesSelection,
  selfStoresSelection,
  selfTemplateSelection,
  fetchStoreSelection,
  editInitialAddressOrder,
  fetchOrderHistoryAdminOrder,
  fetchReturnShipperForm,
  onCancelCompletedOrder,
  setCurrentTableViews,

  removeOrdersFromJob,
  addOrdersToJob,
  getJobsByStatusAdminOrder,
  incompleteJobs,
  createTicket,
  fetchRetailerStats,
}) => {
  const {
    handleSelectedTables,
    handleChange,
    intl,
    fetchOrderHistory,
    addLocationToLocal,
    selectedTab,
    searchedValue,
    selectedStatuses,
    searchInputErrors,
    fetchAccordingToTab,
    onOpenOrdersModal,
    historyArray,
    orderSearchQuery,
    downloadTemplate,
    draggerProps,
    searchRetailer,
    setIsReportsModalOpen,
    setIsFiltersModalOpen,
    setSearchedValue,

    onChangeSearchFilter,
    downloadedOrders,
    onUploadOrders,
    deleteLocal,
    setSelectedTab,
    isReportsModalOpen,
    isOrdersModaOpen,
    isFiltersModalOpen,
    onUploadOrdersManually,
    setDownloadedOrders,

    downloadOrdersBarcodes,
    onSelectRows,
    selectedOrders,
    handleStatusChoices,
    statusOrdersFilter,
    removeStatus,
    vueColumns,
    handleVueColumns,
    isOrdersDraggerOpen,
    onOpenOrdersDragger,
    saveRetailerView,
    selectedTables,

    handleOpenViewSetting,
    ViewSettingOpen,
    isOrdersActionModalOpen,
    setOrdersActionModalOpen,
    resetModalState,
    fetchJobsByStatus,
    filterOrders,
  } = useHooks({
    fetchStoreSelection,
    fetchOrderHistoryAdminOrder,
    onChangeLocationAdminOrder,
    onChangeQueryParams,
    saveOrders,
    onFetchAllOrders,
    isAdminOrders,
    userURL,
    tablesView,
    setCurrentTableViews,
    filteredRetailer,

    removeOrdersFromJob,
    addOrdersToJob,
    getJobsByStatusAdminOrder,
    fetchRetailerStats,
    ordersUploading,
  });
  //
  const uploadTableRef = React.createRef();
  const { tablesKeys, vueSettingsItems, matchStatusOrders } = useSettings({
    intl,
    vueColumns,
    isAdminOrders,
    saveRetailerView,
  });

  const tablesChoice = [];
  tablesKeys.forEach((table) => {
    if (table) {
      tablesChoice.push(
        <Option key={table.key}>
          <FormattedMessage id={table.messageId} defaultMessage={table.defaultMessage} />
        </Option>,
      );
    }
  });

  const selectSearchFilter = (
    <Select defaultValue="orderId" className="select-before" onChange={onChangeSearchFilter} value={orderSearchQuery}>
      {/* LEAVE THIS TEMPORARY
       <Option value="trackingId">
        <FormattedMessage id="tabPane.trackingID" defaultMessage="Tracking ID" />
      </Option> */}
      <Option value="orderId">
        <FormattedMessage id="tabPane.orderID" defaultMessage="Order ID" />
      </Option>
      <Option value="customerPhone">
        <FormattedMessage id="tabPane.customerPhone" defaultMessage="Customer Phone" />
      </Option>
      <Option value="customerName">
        <FormattedMessage id="tabPane.customerName" defaultMessage="Customer Name" />
      </Option>
      <Option value="address">
        <FormattedMessage id="tabPane.address" defaultMessage="Address" />
      </Option>
    </Select>
  );

  const uploadOrdersMenu = (
    <Menu
      onClick={(info) => {
        switch (info.key) {
          case "2":
            downloadTemplate();
            break;
          case "1":
            onOpenOrdersDragger(true);
            break;
          case "3":
            onOpenOrdersModal(true);
            break;
          default:
            break;
        }
      }}
      items={[
        {
          label: `${intl.formatMessage({
            id: "button.orders.uploadManually",
            defaultMessage: "Upload orders manually",
          })}`,
          key: "3",
          icon: <UploadOutlined />,
        },

        {
          label: `${intl.formatMessage({ id: "button.orders.uploadExcel", defaultMessage: "Upload orders via File" })}`,
          key: "1",
          icon: <FileExcelOutlined />,
        },
        { type: "divider" },
        {
          label: `${intl.formatMessage({
            id: "button.orders.download",
            defaultMessage: "Download Orders template example",
          })}`,
          key: "2",
          icon: <DownloadOutlined />,
        },
      ]}
    />
  );

  const vueSettingsMenu = (
    <Menu
      onClick={({ key, keyPath, domEvent }) => {
        handleVueColumns(key);
        if (key === "save") handleOpenViewSetting(false);
      }}
      items={vueSettingsItems}
    />
  );

  const searchOrders = (
    <>
      <Input
        value={searchedValue}
        placeholder={`${intl.formatMessage({ id: "search", defaultMessage: "Search" })}`}
        addonBefore={selectSearchFilter}
        onChange={(event) => {
          // validate input value with each change
          handleChange(event);
          setSearchedValue(event.target.value);
        }}
        onPressEnter={
          /* Send search request when there is no errors in current state */
          searchedValue?.length > 0 && searchInputErrors.err !== ""
            ? (e) => {
                e.preventDefault(); // to avoid handling the press event
                // TODO show that the search is invalid
              }
            : searchRetailer
        }
        className="searchOrderInput"
        style={{ marginBottom: "10px" }}
        status={searchedValue?.length > 0 && searchInputErrors.err !== "" ? "error" : ""}
      />

      {searchedValue?.length > 0 && searchInputErrors.err !== "" && (
        <>
          <br />
          <WarningTwoTone twoToneColor="#db2269" />
          <span className="error"> {searchInputErrors.err}</span>
        </>
      )}
    </>
  );

  const displayTags = (group) => {
    let content = [];
    switch (group) {
      case "searched":
        content.push(
          <Tag
            closable={true}
            className="FilterTag"
            key={"searchWord"}
            color="cyan"
            onClose={() => {
              setSearchedValue("");
              searchRetailer("");
            }}
          >
            <div className="FilterTag__wrapper">
              <b className="FilterTag__name">{orderSearchQuery}</b>
              <p className="FilterTag__item">{searchedValue}</p>
            </div>
          </Tag>,
        );
        break;
      case "status":
        statusOrdersFilter &&
          statusOrdersFilter.forEach((e) =>
            content.push(
              <Tag
                closable={e === "all" ? false : true}
                className="FilterTag"
                color={e === "can_be_assigned" ? "green" : "gold"}
                key={e}
                onClose={() => {
                  removeStatus(e);
                }}
              >
                <div className="FilterTag__wrapper">
                  <b className="FilterTag__name">Status</b>
                  <p className="FilterTag__item">{matchStatusOrders[e]}</p>
                </div>
              </Tag>,
            ),
          );
        break;
      default:
        content = "unclear";
        break;
    }

    return content;
  };
  // features for uploaded table
  let mainAttributes = {
    onSelectRows: onSelectRows,
    searchedValue: searchedValue,
    selectedStatuses: selectedStatuses,
    historyArray: historyArray,
    fetchOrderHistory: fetchOrderHistory,
    addLocationToLocal: addLocationToLocal,
    onHandleSelectOrder: onHandleSelectOrder,
    loading: ordersUploading,
    editInitialAddressOrder: editInitialAddressOrder,
    fetchAccordingToTab: fetchAccordingToTab,
    isAdminOrders: isAdminOrders,
    filteredLocationShared: filteredLocationShared,
    updateOrders: fetchAccordingToTab,
    showAllCols: vueColumns,
    createTicket: createTicket,
  };

  const mainFunctions = {
    onCancelOrder: onCancelOrder,
    onPostponeOrder: onPostponeOrder,
    onCreateReturn: onCreateReturn,
    onCompleteOrder: onCompleteOrder,
    onRemoveLocation: onRemoveLocation,
    onFetchAllOrders: onFetchAllOrders,
    onDeleteOrder: onDeleteOrder, //
    onDeleteOrders: onDeleteOrders, //
    // saveNotesRetailerOrder: saveNotesRetailerOrder, //
    onCancelCompletedOrder: onCancelCompletedOrder,
    editPhone: editPhone,
    returnToShipper: returnToShipper,
    onChangeQueryParams: onChangeQueryParams,
    // saveNotesAdminOrder: saveNotesAdminOrder,
    old: true,
    retailers: retailersList,
    filteredRetailer: filteredRetailer,
    filteredAddress: filteredAddress,
    restoreAdminOrder: restoreAdminOrder,
  };

  const newUploadedFunctions = {
    ordersList: downloadedOrders,
    onSaveOrders: onUploadOrders,
    onDeleteLocal: deleteLocal,
    onSetSelectedTab: setSelectedTab,
    onSetDownloadedOrders: setDownloadedOrders,
    retailer: retailer,
    selfStoresSelection: selfStoresSelection,
    selfTemplateSelection: selfTemplateSelection,
    newUploaded: true,
    old: false,
  };

  const completedOrders = {
    allOrdersTab: true,
  };

  const returnedFunctions = {
    isReturnOrders: true,
    fetchReturnShipperForm: fetchReturnShipperForm,
  };

  const displayTable = (key) => {
    mainAttributes = {
      ...mainAttributes,
      ...{
        ordersList: key !== 2 ? ordersList : downloadedOrders,
        pagination: key !== 2 ? pagination : undefined,
        orderSearchQuery: key !== 2 ? orderSearchQuery : undefined,
        allOrdersTab: key === 4 ? true : false,
      },
    };
    // TODO : refactor this
    if (key === 2) mainAttributes = { ...mainAttributes, ...newUploadedFunctions };
    else mainAttributes = { ...mainAttributes, ...mainFunctions };

    if (key === 5) mainAttributes = { ...mainAttributes, ...completedOrders };
    else if (key === 8) mainAttributes = { ...mainAttributes, ...returnedFunctions };

    return ordersList ? (
      <>
        <UploadedOrdersTable {...mainAttributes} ref={uploadTableRef} />
      </>
    ) : null;
  };

  return (
    <div>
      {!isAdminOrders && (
        <>
          <CustomCard>
            <div className={classnames({ retailerHeader: !isAdminOrders })}>{!hideHeader ? null : children}</div>
          </CustomCard>
          <Divider />
        </>
      )}

      <CustomCard
        title={
          isAdminOrders ? (
            <FormattedMessage id="page.home.title" defaultMessage="Upload orders list" />
          ) : (
            <FormattedMessage id="page.ordersManagment.tabs.command" defaultMessage="Command Center" />
          )
        }
        toolbar={
          <Row justify="end">
            <Dropdown
              overlay={uploadOrdersMenu}
              onClick={() => {
                onOpenOrdersModal(true);
              }}
              style={{ marginRight: "5px" }}
            >
              <Button type="primary" icon={<PlusOutlined />} className="headerBar__addOrderBtn">
                <FormattedMessage id="button.orders.create" defaultMessage="Create Orders " />
              </Button>
            </Dropdown>
            {showReportsButton && (
              <Button onClick={() => setIsReportsModalOpen(true)} style={{ marginLeft: "5px" }}>
                <FormattedMessage id="page.ordersManagment.reports" defaultMessage="Reports" />
              </Button>
            )}
          </Row>
        }
      >
        <Row className="filtersWrapper">
          <Col span={24}>
            <Row className="filtersWrapper__actionBar">
              <Col>{searchOrders}</Col>
              <Col>
                <Button
                  onClick={() => setIsFiltersModalOpen(true)}
                  icon={<ControlOutlined />}
                  className="filtersWrapper__actionBar--filterBtn"
                >
                  <FormattedMessage id="page.ordersManagment.filters" defaultMessage="Filters" />
                </Button>

                <Tooltip
                  placement="top"
                  title={
                    selectedOrders?.length
                      ? `${intl.formatMessage({ id: "modal.delete", defaultMessage: "delete" })}`
                      : `${intl.formatMessage({ id: "modal.delete", defaultMessage: "delete" })}`
                  }
                >
                  <Button
                    icon={<DeleteOutlined />}
                    size="default"
                    onClick={() => {
                      uploadTableRef.current.deleteMany();
                    }}
                    disabled={!selectedOrders?.length}
                  />
                </Tooltip>
                <Dropdown
                  overlay={vueSettingsMenu}
                  onVisibleChange={handleOpenViewSetting}
                  visible={ViewSettingOpen}
                  trigger={["hover"]}
                >
                  <Button
                    icon={<EyeOutlined />}
                    size="default"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  />
                </Dropdown>

                <Tooltip
                  placement="top"
                  title={
                    selectedOrders?.length
                      ? "Download Selected"
                      : `${intl.formatMessage({ id: "button.tab.downloadAll", defaultMessage: "Download All" })}`
                  }
                >
                  <Button
                    icon={<PrinterOutlined />}
                    size="default"
                    onClick={() => {
                      downloadOrdersBarcodes();
                    }}
                  />
                </Tooltip>

                <Tooltip
                  placement="top"
                  title={`${intl.formatMessage({ id: "button.tab.more", defaultMessage: "Other Actions" })}`}
                >
                  <OrdersBulkActionsDropdown
                    openAddOrders={() => {
                      setOrdersActionModalOpen({ id: "add", open: true });
                    }}
                    openRemoveOrders={() => setOrdersActionModalOpen({ id: "remove", open: true })}
                    orders={selectedOrders}
                    jobs={incompleteJobs}
                    isAdminOrders={isAdminOrders}
                    fetchJobsByStatus={fetchJobsByStatus}
                  />
                </Tooltip>
              </Col>
            </Row>
            <Row className="filtersWrapper__filtersBar">
              {displayTags("status")}
              {searchedValue ? displayTags("searched") : <></>}
            </Row>
          </Col>
        </Row>
        {displayTable(selectedTab)}
      </CustomCard>

      <FiltersModal
        isOpen={isFiltersModalOpen}
        onClose={() => setIsFiltersModalOpen(false)}
        onCloseModal={() => setIsFiltersModalOpen(false)}
        onStatusChoice={handleStatusChoices}
        selectedTables={selectedTables}
        tablesChoice={tablesChoice}
        filterListOrders={fetchAccordingToTab}
        handleSelectedTables={handleSelectedTables}
        statusOrdersFilter={statusOrdersFilter}
      />
      <ReportsModal
        retailersList={retailersList}
        isOpen={isReportsModalOpen}
        onClose={() => setIsReportsModalOpen(false)}
      />
      <OrdersModal
        isAdmin={isAdminOrders}
        retailers={retailersList}
        retailer={retailer}
        storesSelection={storesSelection}
        selfStoresSelection={selfStoresSelection}
        selfTemplateSelection={selfTemplateSelection}
        visible={isOrdersModaOpen}
        onUploadOrdersManually={onUploadOrdersManually}
        onClose={() => onOpenOrdersModal(false)}
      />
      <OrdersFileDraggerModal
        draggerProps={draggerProps}
        isAdminOrders={isAdminOrders}
        retailers={retailersList}
        onCancel={() => {
          onOpenOrdersDragger(false);
          setSelectedTab(1);
          setDownloadedOrders([]);
        }}
        onSubmit={() => {
          onOpenOrdersDragger(false);
          setSelectedTab(1);
        }}
        visible={isOrdersDraggerOpen}
        downloadedOrders={downloadedOrders}
        mainAttributes={mainAttributes}
      />
      <OrdersActionConfirmModal
        isAdminOrders={isAdminOrders}
        onActionDone={() => {
          resetModalState();
        }}
        onCancel={() => setOrdersActionModalOpen({ id: "", open: false })}
        visible={isOrdersActionModalOpen}
        orders={filterOrders(selectedOrders, ordersList)}
        jobs={incompleteJobs}
        removeOrdersFromJob={removeOrdersFromJob}
        addOrdersToJob={addOrdersToJob}
        afterClose={() => {
          onSelectRows([]);
        }}
      />
    </div>
  );
};

export default OrdersWidgetHome;
