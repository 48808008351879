import React from "react";

import { MobileTitle } from "../../../components/AdminPanel/Mobile/MobileTitle";
import { MobileRangePicker } from "../../../components/AdminPanel/Mobile/MobileRangePicker";
import { Search as MobileSearch } from "./components/Search";
import { JobsTable } from "./components/JobsTable";
import { MobilePagination } from "../../../components/AdminPanel/Mobile/MobilePagination";
import { MobileLoader } from "../../../components/AdminPanel/Mobile/MobileLoader";

export const MobileView = ({
  loading,
  data,
  dates,
  disabledDate,
  searchFilter,
  centerCoordinates,
  paginationConfig,
  setSelectedOrder,
  selectedFilterValue,
  onChangeSearchFilter,
  onRatingChange,
  onSearchDriver,
  onDatesChange,
  onChangePagination,
}) => {
  const onPageChange = (current, pageSize) => {
    onChangePagination({ current, pageSize: Number(pageSize) });
  };

  return (
    <div className="mobile-page-wrapper">
      {loading && <MobileLoader />}
      <MobileTitle title="Driver statistics list" />
      <MobileSearch
        searchFilter={searchFilter}
        selectedFilterValue={selectedFilterValue}
        onChangeSearchFilter={onChangeSearchFilter}
        onRatingChange={(e) => {
          onRatingChange(e);
          onChangePagination({ current: 1, pageSize: paginationConfig.pageSize });
        }}
        onSearchDriver={(e) => {
          e.persist();
          onSearchDriver(e);
          onChangePagination({ current: 1, pageSize: paginationConfig.pageSize });
        }}
      />
      <MobileRangePicker value={dates} onChange={onDatesChange} disabledDate={disabledDate} />
      <JobsTable data={data} centerCoordinates={centerCoordinates} setSelectedOrder={setSelectedOrder} />
      <MobilePagination onPageChange={onPageChange} {...paginationConfig} />
    </div>
  );
};
