import React from "react";
import { Typography, Image, Row, Spin } from "antd";
import emptyListTruck from "src/assets/empty-list-truck.png";
import { EmptyListComponentProps } from "../types";

const EmptyListComponent : React.FC<EmptyListComponentProps> = ({ title, description, isFetching } ) => {
    const { Title, Text } = Typography;
    return (
        <Row className="empty-list-container-style" align="middle" justify="center">
            <Row className="empty-list-content-style" justify="center">
                {
                    isFetching ? <Spin size="large" /> :
                        <>
                            <Image
                                src={emptyListTruck}
                                width={150}
                            />
                            <Title level={4}>{title}</Title>
                            <Text type="secondary">{description}</Text>
                        </>
                }

            </Row>
        </Row>
    );
}

export default EmptyListComponent;
