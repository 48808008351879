import React from "react";
import { Button, DatePicker, Modal, Select, Row } from "antd";
import { FormattedMessage } from "react-intl";

import RetailerSelect from "../../../../Select";
import useReports from "./useReports";
import "./reportsModal.less";
import {
  others,
  optionsCompletedOrders,
  optionsCanceledOrders,
  optionsFailedOrders,
  optionsPostponedOrders,
  rangeDateOptions,
} from "../../constants/helper";

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;

function ReportsModal({ isOpen, onClose, retailersList }) {
  const {
    dates,
    disabledTomorrowAndFuture,
    handleSetDates,
    generateReport,
    onCloseModal,
    isLoading,
    setRetailer,
    rangeDateType,
    setRangeDateType,
    selectedStatus,
    setSelectedStatus,
  } = useReports({
    onClose,
  });

  const getChoices = (group) => {
    const choices = [];
    group.forEach((op) => {
      choices.push(
        <Option value={op.value} key={op.value}>
          {op.label}
        </Option>,
      );
    });

    return choices;
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={onCloseModal}
      footer={[
        <Button key="Cancel" onClick={onCloseModal} loading={isLoading}>
          <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!dates || !Array.isArray(dates)}
          onClick={generateReport}
          loading={isLoading}
        >
          <FormattedMessage id="generate" defaultMessage="Generate" />
        </Button>,
      ]}
    >
      <div>
        <p>
          <FormattedMessage id="chooseDatesToGenerate" defaultMessage="Choose dates to generate report" />
        </p>
        <Row className="filtersOptions__row">
          <label className="filtersOptions__row--label"> Dates </label>

          <div>
            <Select value={rangeDateType} onChange={(value) => setRangeDateType(value)}>
              {getChoices(rangeDateOptions)}
            </Select>
            <RangePicker value={dates} onChange={handleSetDates} disabledDate={disabledTomorrowAndFuture} />
          </div>
        </Row>
        <Row className="filtersOptions__row">
          <label className="filtersOptions__row--label"> Retailers </label>

          <RetailerSelect
            className="reportModalSelect"
            onChange={setRetailer}
            defaultLabel="All retailers"
            list={retailersList}
          />
        </Row>
        <Row className="filtersOptions__row">
          <label className="filtersOptions__row--label"> Orders status </label>
          <Select
            className="filtersOptions__row--status"
            value={selectedStatus}
            onChange={(values) => setSelectedStatus(values)}
          >
            <Option value={"all"} key={"all"}>
              All status
            </Option>
            {getChoices(others)}
            <OptGroup label="Completed Orders" key="completed">
              {getChoices(optionsCompletedOrders)}
            </OptGroup>
            <OptGroup label="Postponed Orders" key="postponed">
              {getChoices(optionsPostponedOrders)}
            </OptGroup>
            <OptGroup label="Canceled Orders" key="canceled">
              {getChoices(optionsCanceledOrders)}
            </OptGroup>
            <OptGroup label="Failed Orders" key="failed">
              {getChoices(optionsFailedOrders)}
            </OptGroup>
          </Select>
        </Row>
      </div>
    </Modal>
  );
}

export default ReportsModal;
