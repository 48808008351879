import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "deliveryReport",
  url: `${API_URL}/admin/orders/trouble-reports`,

  actions: {
    fetch: {
      transformResponse: (res) => {
        return { ...res, body: res.body };
      },
    },

    deleteOne: {
      method: "DELETE",
      url: `${API_URL}/admin/orders/trouble-reports/:reportId`,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
