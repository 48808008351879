import { notification } from "antd";
import { ProviderType } from ".";
import { LightMapStyle } from "../JobsMap/MapMutipleRoutes/mapStyles/lightMap";
import { TrackingShipementService } from "./trackingShipement.service";
const PROJECT_ID = "beebolt-lmfs";

interface useFleetUpdatesInput {
  trackingId: string;
  vehicleId: string;
  jobId: number;
  center: { lat: number; long: number };
  polyline: string;
}

interface useFleetUpdatesReturn {
  initTrackingMap: (map: google.maps.Map, maps: typeof google.maps, ref: Element, options: Record<string, any>) => void;
  removeListeners: () => void;
}

const getContent = (task: google.maps.journeySharing.Task) => {
  const name = task.name?.split("/")?.[3] ?? "Unspecified";

  return `<p><b> ${task.type} Task</b> </p></br> <p>ID : ${name}</p> <p>Outcome : ${task.outcome}</p>`;
};

export const useFleetUpdates: (props: useFleetUpdatesInput) => useFleetUpdatesReturn = ({
  trackingId,
  vehicleId,
  jobId,
  center,
  polyline,
}) => {
  const trackingShipementService = new TrackingShipementService();
  let errorListener: google.maps.MapsEventListener;
  let updatesListener: google.maps.MapsEventListener;
  let mapInstance: google.maps.Map;
  let mapView: google.maps.journeySharing.JourneySharingMapView;

  // styling options for map
  const routePolyline = {
    polylineOptions: {
      strokeOpacity: 0.5,
      strokeColor: "red",
    },
    visible: true,
  };

  const retailer: google.maps.Symbol = {
    path: "M195 323q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T195 263h572q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T767 323H195Zm5 567q-12.75 0-21.375-8.625T170 860V645h-25q-14.14 0-23.07-11T116 609l44-202q2-11 10.25-17.5T189 383h583q10.5 0 18.75 6.5T801 407l44 202q3 14-5.93 25T816 645h-25v215q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T731 860V645H552v215q0 12.75-8.625 21.375T522 890H200Zm30-60h262V645H230v185Z",
    fillColor: "blue",
    fillOpacity: 0.6,
    strokeWeight: 1,
    rotation: 0,
    scale: 2,
  };

  // Customization

  const destinationVehicleMarkerCustomization = (
    params:
      | google.maps.journeySharing.TaskMarkerCustomizationFunctionParams
      | google.maps.journeySharing.ShipmentMarkerCustomizationFunctionParams,
  ) => {
    if (params.isNew) {
      params.marker.setTitle(params.task?.name);

      const infoWindow = new google.maps.InfoWindow({ disableAutoPan: true });
      infoWindow.setContent(getContent(params.task));

      params.marker.addListener("click", () => {
        infoWindow.open(mapInstance, params.marker);
      });
    }
  };

  const plannedStopMarkerCustomization = (
    params: google.maps.journeySharing.PlannedStopMarkerCustomizationFunctionParams,
  ) => {
    if (params.isNew) {
      params.marker.setTitle(`Stop ${params.stopIndex + 1}`);
      params.marker.addListener("click", () => {
        // Perform desired action.
        console.log("clicked waypoint", params.vehicle);
      });
    }
  };

  const vehicleMarkerCustomization = { icon: "../../assets/truck.png" };

  // Location providers

  /** This is used to fetch latest locations of drivers **/
  const initDriversLocationProvider = (maps: typeof google.maps, options: Record<string, any>) => {
    return new maps.journeySharing.FleetEngineDeliveryFleetLocationProvider({
      projectId: PROJECT_ID,
      authTokenFetcher: trackingShipementService.authTokenFetcher,
      staleLocationThresholdMillis: (options.threshold ?? 3) * 60 * 60 * 1000, // last 3 hours
      deliveryVehicleFilter: "",
      locationRestriction: null, //new google.maps.LatLngBounds({ lat: 0, lng: 0 }, { lat: center.lat, lng: center.long }),
    });
  };

  /** This is used to track shipement (one task in our case)  **/
  const initShipementLocationProvider = (options: Record<string, any>) => {
    return new google.maps.journeySharing.FleetEngineShipmentLocationProvider({
      projectId: PROJECT_ID,
      authTokenFetcher: trackingShipementService.authTokenFetcher,
      trackingId: trackingId,
      pollingIntervalMillis: 10000,
      destinationMarkerCustomization: destinationVehicleMarkerCustomization,
    });
  };

  /** This is used to track state of all tasks of a given job **/
  const initLocationProvider = (options: Record<string, any>) => {
    return new google.maps.journeySharing.FleetEngineDeliveryVehicleLocationProvider({
      projectId: PROJECT_ID,
      authTokenFetcher: trackingShipementService.authTokenFetcher,
      pollingIntervalMillis: 20000,
      deliveryVehicleId: options.vehicleId ?? vehicleId,
      shouldShowOutcomeLocations: false,
      shouldShowTasks: true,
      staleLocationThresholdMillis: 1000,
      taskFilterOptions: {
        completionTimeFrom: null,
        completionTimeTo: null,
        state: options.state === "ALL" ? null : options.state,
      },
      taskMarkerCustomization: destinationVehicleMarkerCustomization,
      plannedStopMarkerCustomization: plannedStopMarkerCustomization,
    });
  };

  // functions

  const removeListeners = () => {
    if (errorListener) errorListener.remove();
    if (updatesListener) updatesListener.remove();
  };

  const initTrackingMap = (
    map: google.maps.Map,
    maps: typeof google.maps,
    ref: Element,
    options: Record<string, any>,
  ) => {
    // reset  : TODO it doesn't seem like it is working
    removeListeners();

    // init location provider
    let locationProvider;
    switch (options.providerType) {
      case ProviderType.TASKS_TRACKER:
        locationProvider = initShipementLocationProvider(options);
        break;
      case ProviderType.FLEET_TRACKER:
        locationProvider = initDriversLocationProvider(maps, options);
        break;
      default:
        locationProvider = initLocationProvider(options);
        break;
    }

    // event listeners
    errorListener = locationProvider.addListener("error", (e: google.maps.ErrorEvent) => {
      console.error(e.error);
      if (e.error.name === "MapsJourneySharingError")
        notification.error({ message: "Tracking Map Error", description: e.error.message });
    });
    updatesListener = locationProvider.addListener(
      "update",
      (e: google.maps.journeySharing.FleetEngineShipmentLocationProviderUpdateEvent) => {
        // e.task contains data that may be useful
        // to the rest of the UI.
        console.log(e);
      },
    );

    // init mapView
    mapView = new maps.journeySharing.JourneySharingMapView({
      element: ref,
      locationProvider: locationProvider,
      mapOptions: {
        styles: LightMapStyle,
      },
      takenRoutePolylineSetup: routePolyline,
    });
    mapView.map.setCenter({ lat: center.lat, lng: center.long });
    mapView.map.setZoom(14);
    mapInstance = mapView.map;

    // display route if we trcking job's task (same as Compute route)
    if (options.providerType === ProviderType.VEHICLE_TRACKER) {
      const routeColor = "#000000";
      // decode the path
      var decodedPath = maps.geometry.encoding.decodePath(polyline);
      const Path = new google.maps.Polyline({
        path: decodedPath,
        geodesic: true,
        strokeColor: routeColor,
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });

      Path.setMap(mapView.map);
    }
  };

  return {
    initTrackingMap,
    removeListeners,
  };
};
