import React, { useState, useEffect } from "react";
import { Table, Tag } from "antd";
import { API_URL } from "src/constants/config";
import moment from "moment";

const SuperCompanies = () => {
  const [companiesData, setCompaniesData] = useState([]);
  const fetchData = async () => {
    try {
      const authToken = localStorage.getItem("authTokenSuper");
      const response = await fetch(`${API_URL}/superadmin/allcompanies`, {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCompaniesData(data);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        const isOnline = record.last_activity
          ? moment().diff(moment(record.last_activity), 'minutes') <= 1
          : false;
  
        return (
          <div>
            {record.last_activity ? (
              <Tag color={isOnline ? "green" : "red"}>
                {isOnline ? "Online" : "Offline"}
              </Tag>
            ) : (
              <Tag color={"red"}>Offline</Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Number of Drivers",
      dataIndex: "number_of_drivers",
      key: "number_of_drivers",
    },
    {
      title: "Subscription",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Type of subscription",
      dataIndex: "subscription_type",
      key: "subscription_type",
    },
    {
      title: "Joined At",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).format("YYYY-MM-DD"),
    },
    {
      title: "Last Activity",
      dataIndex: "last_activity",
      key: "last_activity",
      render: (last_activity) => {
        if (last_activity) {
          const formattedTime = moment(last_activity).format("YYYY-MM-DD || hh:mm A");
          const isMorning = moment(last_activity).format("A") === "AM";
          const emoji = isMorning ? "🌤️" : "🌙";
  
          return (
            <div>
              <span>{formattedTime} {emoji}</span>
            </div>
          );
        } else {
          return "Long time ago";
        }
      },
    },
  ];
  

  return (
    <div className="allDriversSettings">
      <h2>Companies</h2>
      <Table
        dataSource={companiesData}
        columns={columns}
        scroll={{ x: "max-content" }}
        responsive={true}
        className="superAdminTables"
        rowKey={(record) => record?.id} 

      />
    </div>
  );
};

export default React.memo(SuperCompanies);
