import React, { useContext } from "react";
import { Select } from "antd";

import { IntlContext } from "../../utils/IntlProvider/IntlContextProvider";

import "./styles.less";

const { Option } = Select;

const locales = ["en", "ar"];

const languages = {
  en: "ENG",
  ar: "ARB",
};

export const SelectLanguage = () => {
  const { localeKey, changeLocale } = useContext(IntlContext);

  return (
    <Select
      onChange={changeLocale}
      defaultValue={localeKey}
      dropdownClassName="language-select-item"
      className="language-select-s"
    >
      {locales.map((locale) => (
        <Option value={locale} key={locale}>
          {languages[locale]}
        </Option>
      ))}
    </Select>
  );
};
