import React, { FC } from "react";
import { actions as adminOrderActions } from "../../redux/resources/adminOrders";
import { actions as adminTicketActions } from "../../redux/resources/adminTicket";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TicketListView from "src/components/TicketListView";

const AdminTickets: FC<any> = ({
  getAdminTicket,
  updateAdminTicket,
  sendMessageAdminTicket,
  getMessagesAdminTicket,
  updateTicketStateAdminTicket,
  adminTickets,
  isLoadingTickets,
}) => {
  return (
    <TicketListView
      getTicketAction={getAdminTicket}
      updateTicketAction={updateAdminTicket}
      sendMessageAction={sendMessageAdminTicket}
      getAllMessagesAction={getMessagesAdminTicket}
      updateTicketState={updateTicketStateAdminTicket}
      tickets={adminTickets}
      isLoading={isLoadingTickets}
      isAdmin={true}
    />
  );
};

export default connect(
  (state: any) => {
    return {
      adminTickets: state.adminTicket?.item,
      isLoadingTickets: state.adminTicket?.isFetching,
    };
  },
  (dispatch) => ({
    ...bindActionCreators(adminOrderActions, dispatch),
    ...bindActionCreators(adminTicketActions, dispatch),
  }),
)(AdminTickets);
