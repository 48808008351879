import { Drawer, Row } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React, { FC } from "react";
import { actions as activityLogsActions } from "../../redux/resources/activityLogs";

// styling
import { CustomCard } from "../../utils/common";
import ActivityTable from "./components/ActivityTable";
import "./retailerActivity.less";
import useHooks from "./useHooks/useHooks";
import { RootState } from "../../redux";
import OrderHistory from "./components/OrderHistory";

const RetailerActivity: FC<any> = ({
  props,
  fetchActivityLogs,
  getOrderHistoryActivityLogs,
  activityLogs,
  paginationData,
  history,
  location,
}) => {
  const { Intl, pagination, changeQueryParams, openOrderHistory, orderHistoryData, historyDrawer, setHistoryDrawer } =
    useHooks({
      fetchActivityLogs,
      getOrderHistoryActivityLogs,
      paginationData,
      history,
      location,
    });

  const toolbar = (
    <Row className="activity_toolbar">
      {/* TODO add filtering options
      
      <Input
        size="small"
        placeholder="Search by Order or Customer Name "
        prefix={<SearchOutlined />}
        bordered={false}
        className="activity_toolbar--search"
      />
      <Button size="small" icon={<PlusOutlined />} type="primary" className="activity_toolbar--filtersBtn">
        Filters
      </Button> */}
    </Row>
  );
  return (
    <CustomCard
      title={`${Intl.formatMessage({ id: "page.activityLogs.title", defaultMessage: "Activity logs" })}`}
      toolbar={toolbar}
    >
      <ActivityTable
        intl={Intl}
        data={activityLogs}
        pagination={pagination}
        onChangeQueryParams={changeQueryParams}
        onOrderSelected={openOrderHistory}
      />

      <Drawer
        style={{ zIndex: 1050 }}
        width="450px"
        placement="right"
        closable
        onClose={() => setHistoryDrawer(false)}
        visible={historyDrawer}
        className="historyDrawer"
      >
        <OrderHistory history={orderHistoryData} intl={Intl} />
      </Drawer>
    </CustomCard>
  );
};

const MSTP = (state: RootState) => {
  const retailerActivities = state.activityLogs?.items[0]?.items;
  const paginationData = state.activityLogs?.items[0]?.meta;
  return {
    activityLogs: retailerActivities ?? [],
    paginationData,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(activityLogsActions, dispatch),
}))(RetailerActivity);
