import React from "react";
import { Pagination } from "antd";

import "./styles.less";

export const MobilePagination = ({ onPageChange, pageSize, total, current, pageSizeOptions, showSizeChanger }) => (
  <Pagination
    onChange={onPageChange}
    pageSize={pageSize}
    total={total}
    current={current}
    pageSizeOptions={pageSizeOptions}
    showSizeChanger={showSizeChanger}
    className="mobile-pagination"
    size="small"
  />
);
