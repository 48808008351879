import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Modal, notification, Popconfirm, Table, Tooltip } from "antd";
import React, { FC, useRef } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { IOrder, PointTypeEnum } from "src/entities";
import { getStatusBage } from "src/helpers/general";
import { getPointsByType, splitOrdersByLocation } from "src/helpers/maps/maps.helpers";
import { DraggableBodyRowProps } from "src/pages/JobsManagement/JobsConfigurePage/types/ordersTable.types";
import { IExpandedJobOrdersList } from "./types";
import ActionsDropdown from "../ActionsDropdown";
import ConfirmOrderActionsModal from "../ConfirmModal/ConfirmModal";
import IJob from "src/entities/job.entity";
import Ticket from "src/components/Ticket";
import { TicketViewEnum } from "src/components/Ticket/type";

const type = "DragableBodyRow";
const ALLOWED_FORCE_NEXT = ["in_delivery", "arrived"];
const { confirm } = Modal;

const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }: DraggableBodyRowProps) => {
  const ref = useRef<HTMLTableRowElement>(null);

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    },
  });

  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

export const blockReorderDeliveries = (record: IJob) => {
  const deliveryPoints = getPointsByType(record, PointTypeEnum.DELIVERY);
  return record.status === "completed" || deliveryPoints.length <= 1 || deliveryPoints.length >= 25;
};

export const mapOrders = (record: IJob) => {
  let orderIndex = 0;
  return record?.deliveryOrders?.map((order) => {
    let mapIndex = "";
    if (order.locationShared) {
      orderIndex += 1;
      mapIndex = orderIndex.toString();
    }

    return {
      ...order,
      mapIndex,
      customerName: order.customerInfo?.name,
      trackingID: order.trackingId,
      retailerName: order.retailer?.name ? order.retailer.name : "Retailer name",
      key: order.code,
    };
  });
};

const ExpandedJobOrdersList: FC<IExpandedJobOrdersList> = ({
  isAdmin,
  record,
  forceNextOrder,
  postponeOrder,
  completeOrder,
  returnToShipper,
  cancelOrder,
  deleteOrder,
  cancelCompletedOrder,
  confirmOrdersAction,
  setConfirmOrdersAction,
  operationLoading,
  setSelectedOrder,
  changeOrderPositionDeliveryJob,
  fetchDeliveryJobs,
  getRoutesDeliveryJob,
  isFetchingDeliveryJobs,
  withErrors,
}) => {
  const moveRow = (dragIndex: number, hoverIndex: number) => {
    confirm({
      title: "Are you sure to change order of orders?",
      async onOk() {
        const originOrder = data[dragIndex];
        const swappedOrder = data[hoverIndex];

        if (!originOrder || !swappedOrder) {
          notification.warning({ message: "One of the swapped orders is undefined ! please retry .." });
          return;
        }

        const pointsOrder = [originOrder, swappedOrder].map(({ pointOrder }) => pointOrder);

        try {
          await changeOrderPositionDeliveryJob({ jobId: record.id }, { query: { save: true }, body: { pointsOrder } });
          await fetchDeliveryJobs({}, { query: { withError: withErrors } });
          await getRoutesDeliveryJob({ jobId: record.id });
        } catch (error: any) {
          notification.error({
            message: "Error",
            description: error?.body?.message || "Failed to change order of order in job",
          });
        }
      },
      onCancel() {
        return false;
      },
    });
  };
  const columns = () => [
    {
      title: "№",
      key: "mapIndex",
      dataIndex: "mapIndex",
      render: (data: IOrder, _: any, i: number) => {
        if (data) return i + 1;
      },
    },
    {
      title: "Customer",
      key: "customerName",
      render: (data: IOrder) => {
        return (
          <Button
            type="text"
            className="orderLink"
            data-testid="order-customer-link"
            onClick={() => setSelectedOrder(data)}
          >
            {data.customerInfo.name}
          </Button>
        );
      },
    },
    { title: "Tracking ID", dataIndex: "trackingID", key: "trackingID" },
    { title: "Order ID", dataIndex: "id", key: "id" },
    { title: "Delivery time", dataIndex: "deliveryTime", key: "deliveryTime" },
    {
      title: "Status",
      key: "status",
      render: (data: IOrder) => {
        return getStatusBage(data.status!.split("_").join(" "));
      },
    },
    { title: "Retailer name", dataIndex: "retailerName", key: "retailerName" },
    {
      title: "Ticket",
      key: "ticket",
      render: (data: IOrder) => (
        <Ticket
          currentView={TicketViewEnum.JOBS}
          buttonType="link"
          jobId={record.id}
          orderId={data.id}
          ticketDetails={data?.ticket || null}
          users={{
            retailer: data?.retailerName,
          }}
        />
      ),
    },
    {
      title: "Actions",
      key: "key",
      render: (data: IOrder) => {
        return (
          <p className="actionsColumn">
            <ActionsDropdown
              deleteOrder={deleteOrder}
              returnToShipper={returnToShipper}
              postponeOrder={() =>
                setConfirmOrdersAction({ open: true, id: data.id, job: record.id, type: "postpone" })
              }
              cancelOrder={cancelOrder}
              completeOrder={completeOrder}
              cancelCompletedOrder={() =>
                setConfirmOrdersAction({ open: true, id: data.id, job: record.id, type: "cancelCompleted" })
              }
              record={record}
              data={data}
            />
          </p>
        );
      },
    },

    {
      title: "R",
      key: "isReturn",
      render: (order: IOrder) => (order.isReturn ? "Yes" : "No"),
    },
    {
      title: " ",
      key: "configure",
      render: (data: IOrder) => {
        return ALLOWED_FORCE_NEXT.includes(data.status!) ? (
          <Popconfirm
            title="Are You sure, You want to move to next order?"
            onConfirm={() => forceNextOrder(record.id)}
            okText="Yes"
            cancelText="No"
            data-testid="order_move_next_popup"
          >
            <Tooltip placement="bottom" title="Force job to move to next order">
              <ArrowRightOutlined className="forceNextOrder__btn" data-testid="order_move_next_btn" />
            </Tooltip>
          </Popconfirm>
        ) : null;
      },
    },
  ];

  const components = {
    body: {
      row: DragableBodyRow,
    },
  };

  const changedData = mapOrders(record);

  const [ordersWithLocation, ordersWithoutLocation] = splitOrdersByLocation(changedData || []);
  const data = ordersWithLocation.concat(ordersWithoutLocation) || [];

  return (
    <>
      {record.deliveryOrders && (
        <div className="jobs-table-wrapper">
          <DndProvider backend={HTML5Backend}>
            <Table
              data-testid="expanded-job-orders-table"
              components={blockReorderDeliveries(record) ? undefined : components}
              columns={columns()}
              className="ordersTablewrapper"
              dataSource={data}
              pagination={false}
              loading={isFetchingDeliveryJobs}
              onRow={(localRecord, index) => {
                if (blockReorderDeliveries(record)) {
                  return {};
                }
                return {
                  index,
                  moveRow,
                } as React.HTMLAttributes<any>;
              }}
            />
          </DndProvider>
        </div>
      )}
      <ConfirmOrderActionsModal
        openModal={confirmOrdersAction}
        handleModalSubmit={confirmOrdersAction.type === "postpone" ? postponeOrder : cancelCompletedOrder}
        setOpenModal={setConfirmOrdersAction}
        loading={operationLoading}
      />
    </>
  );
};

export default ExpandedJobOrdersList;
