import React, { FC } from "react";
import { Drawer } from "antd";
import OrderMap from "../OrderMap";
import { IJobOrderDetailsProps } from "../../../../../types/JobManagement";

import "./orderDetails.less";

const OrderDetails: FC<IJobOrderDetailsProps> = ({
  onClose,
  selectedOrder,
  centerCoordinates,
  updateOrderCash,
  setExpandedRowKeys,
  selectedJobId,
}) => (
  <Drawer width={640} placement="right" onClose={onClose} visible={!!selectedOrder}>
    <p className="orderInfotitle" style={{ marginBottom: 24 }}>
      Order info <span> № {selectedOrder?.id}</span>
    </p>
    <OrderMap
      orderInfo={selectedOrder}
      selectedJobId={selectedJobId}
      centerCoordinates={centerCoordinates}
      setExpandedRowKeys={setExpandedRowKeys}
      updateOrderCash={updateOrderCash}
    />
  </Drawer>
);

export default OrderDetails;
