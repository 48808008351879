import React, { useState, useEffect } from "react";
import { notification, Table } from "antd";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import { MenuOutlined, LockOutlined } from "@ant-design/icons";

import "./pickupTable.less";
import { uniqBy } from "lodash";
import { PointTypeEnum } from "src/entities";

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />);
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
const mapPoints = (previewOrders, isPreview) => {
  return previewOrders.map(({ isReturn, pickupPoint, deliveryPoint }) => ({
    ...(isReturn ? deliveryPoint : pickupPoint),
    pickupPointId: isReturn ? deliveryPoint?.id : pickupPoint?.id,
    deliveryPointId: isReturn ? deliveryPoint?.id : pickupPoint?.id,
    isPreview: isPreview,
    isReturn: isReturn,
  }));
};

const isReturn = (record) => {
  return record.isReturn || (record.pointType === PointTypeEnum.PICKUP && !record.pickupPoint);
};

const mapRetailerName = (record, orders) => {
  const retailerName = isReturn(record)
    ? orders?.find((order) => order?.pickupPoint?.id === record?.deliveryPoint?.id)?.customerInfo?.name
    : orders?.find((order) => order?.pickupPoint?.id === record.pickupPointId)?.retailer?.name;
  return retailerName;
};
function PickupTable({ dataSource, jobOrders, changeOrderHandler, previewOrders }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (previewOrders) {
      const pickupsPreview = mapPoints(previewOrders, true);

      const filteredPickups = uniqBy(pickupsPreview, (el) => el.pickupPointId);
      setData([...dataSource, ...filteredPickups]);
    } else {
      setData(dataSource);
    }
  }, [dataSource, previewOrders]);

  const columns = [
    {
      title: "Sort",
      width: 30,
      className: "drag-visible",
      render: (record) => (record.isPreview ? <LockOutlined /> : <DragHandle />),
    },
    {
      title: "Address",
      render: (record) => {
        return (
          <span>
            <b>{mapRetailerName(record, record.isPreview ? previewOrders : jobOrders)}</b> {record.address}
          </span>
        );
      },
      className: "drag-visible",
    },
    {
      title: "Id",
      render: (record) =>
        !isReturn(record) ? (
          <>
            <b>{record.pickupPoint?.name}</b>
            {` (point #${record.pickupPointId})`}
          </>
        ) : (
          <b>Customer return</b>
        ),
    },
  ];

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const originOrder = data[oldIndex];
      const swappedOrder = data[newIndex];

      if (!originOrder || !swappedOrder) {
        notification.warning({ message: "One of the swapped pickups is undefined ! please retry .." });
        return;
      }
      const pointsOrder = [originOrder, swappedOrder].map((order) => order?.pointOrder);

      await changeOrderHandler(pointsOrder);
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = data.findIndex((data) => data.pickupPointId === restProps["data-row-key"]);
    const isPreview = data[index]?.isPreview;
    return <SortableItem index={index} {...restProps} className={isPreview ? "pickupRow--preview" : "pickupRow"} />;
  };

  return (
    <Table
      pagination={false}
      dataSource={data}
      columns={columns}
      rowKey="pickupPointId"
      rowClassName={"pickupRow"}
      showHeader={false}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  );
}

export default PickupTable;
