import React from "react";
import { Input, Rate, Select } from "antd";

const { Option } = Select;

export const Search = ({ searchFilter, selectedFilterValue, onChangeSearchFilter, onRatingChange, onSearchDriver }) => {
  return (
    <div className="driverstats-mobile-search">
      <Select value={selectedFilterValue} onChange={onChangeSearchFilter}>
        {searchFilter.map(({ text, value }) => (
          <Option value={value} key={value}>
            {text}
          </Option>
        ))}
      </Select>
      {selectedFilterValue === "averageRating" ? (
        <Rate onChange={onRatingChange} />
      ) : (
        <Input placeholder="Search" onChange={onSearchDriver} />
      )}
    </div>
  );
};
