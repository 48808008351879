import React, { FC } from "react";
import { actions as retailerTicketActions } from "../../redux/resources/retailerTicket";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TicketListView from "src/components/TicketListView";

const RetailerTickets: FC<any> = ({
  getRetailerTicket,
  updateRetailerTicket,
  getMessagesRetailerTicket,
  sendMessageRetailerTicket,
  updateTicketStateRetailerTicket,
  retailerTickets,
  isLoading,
}) => {
  return (
    <TicketListView
      getTicketAction={getRetailerTicket}
      updateTicketAction={updateRetailerTicket}
      sendMessageAction={sendMessageRetailerTicket}
      getAllMessagesAction={getMessagesRetailerTicket}
      updateTicketState={updateTicketStateRetailerTicket}
      tickets={retailerTickets}
      isLoading={isLoading}
      isAdmin={false}
    />
  );
};

export default connect(
  (state: any) => ({
    retailerTickets: state.retailerTicket?.item,
    isLoading: state.retailerTicket?.isFetching,
  }),
  (dispatch) => ({
    ...bindActionCreators(retailerTicketActions, dispatch),
  }),
)(RetailerTickets);
