import React from "react";
import { uniqueId } from "lodash";

import { ChartPie } from "../../../components/ChartPie";

export const TopStats = ({ generalStats, orderStats, chartData }) => {
  return (
    <div className="top-stats">
      {generalStats?.map((stat) => (
        <div className="top-stats__item" key={uniqueId()}>
          <div className="item-title">{stat?.title}</div>
          <div className="item-value">{stat?.value}</div>
        </div>
      ))}
      <ChartPie chartData={chartData} className="mb-16" />
      {orderStats?.map((stat) => (
        <div className="top-stats__item" key={uniqueId()}>
          <div className="item-title">{stat?.title}</div>
          <div className="item-value">{stat?.value}</div>
        </div>
      ))}
    </div>
  );
};
