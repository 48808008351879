import React, { FC, ReactElement, useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Dropdown,
  Empty,
  Image,
  Menu,
  Modal,
  notification,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";
import { connect, RootStateOrAny } from "react-redux";
import { bindActionCreators } from "redux";
import { CustomCard } from "src/utils/common";
import { RetailerDetailProps } from "./types";
import { actions as adminRetailersActions } from "../../../../redux/resources/adminRetailers";
import { useHistory, useParams } from "react-router-dom";
import "./styles.less";
import { FormattedMessage, useIntl } from "react-intl";
import RetailerMap from "./components/RetailerMap";
import RetailerPickupsTable from "./components/Pickups/PickupsTable";
import FileImg from "../../../../assets/file.png";
import useRetailerDetail from "./hooks/useRetailerDetails";
import RetailerStatus from "./components/RetailerStatusTag";
import { BASE_ROUTE } from "src/routes";
import InfoWrapper from "src/components/InfoWrapper";
import AddRetailerModal from "../AddRetailerModal";
import { ModalModeEnum } from "../../types/store-management";

const DropdownMenu = ({
  onDeleteRetailer,
  onEditRetailer,
  id,
}: {
  onDeleteRetailer: (retailerId: number) => Promise<void>;
  onEditRetailer: () => void;
  id: number;
}) => {
  const items = [
    {
      key: "edit",
      label: " Edit Retailer",
      icon: <EditOutlined />,
    },
    {
      key: "delete",
      label: " Delete Retailer",
      icon: <DeleteOutlined />,
    },
  ];

  const handleMenuItemsAction = (item: any) => {
    switch (item.key) {
      case "delete":
        return Modal.confirm({
          title: "Confirmation",
          icon: <ExclamationCircleOutlined />,
          content: `Do you really want to delete this retailer ?`,
          okText: "YES",
          cancelText: "NO",
          async onOk(...args) {
            await onDeleteRetailer(id);
          },
        });
      default:
        onEditRetailer();
        break;
    }
  };

  return <Menu data-testid="orders-actions-menu" items={items} onClick={handleMenuItemsAction}></Menu>;
};

const RetailerDetail: FC<RetailerDetailProps> = ({
  selectOneAdminRetailers,
  deleteOneAdminRetailers,
  updateStatusAdminRetailers,
  resetFlagAdminRetailers,
  updateOneAdminRetailers,
  fetchOneAdminRetailers,
  retailer,
  pickups,
  forceReturn,
}) => {
  const REDIRECT_URL = `/${BASE_ROUTE}/retailers`;
  const { id: retailerId } = useParams<{ id?: string }>();
  const intl = useIntl();
  const history = useHistory();
  // state
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  // methods
  const onDiscardAddModal = () => {
    setModalOpen(false);
  };
  const onOpenAddModal = () => {
    setModalOpen(true);
  };

  const { deleteRetailer, updateRetailerStatus, updateRetailer, fetchRetailerProfile } = useRetailerDetail(
    deleteOneAdminRetailers,
    updateStatusAdminRetailers,
    updateOneAdminRetailers,
    fetchOneAdminRetailers,
  );

  useEffect(() => {
    if (retailerId) selectOneAdminRetailers(retailerId);
  }, [retailerId]);

  useEffect(() => {
    if (forceReturn) {
      resetFlagAdminRetailers();
      history.push(REDIRECT_URL);
    }
  }, [forceReturn]);

  // methods
  const onDeleteRetailer = async (retailerId: number) => {
    try {
      await deleteRetailer(retailerId);
      history.push(REDIRECT_URL);
    } catch (error) {
      notification.warn({
        message: "Unexpected error, please try again !",
      });
    }
  };

  const onConfirmRetailerForm = async (values: any) => {
    try {
      await updateRetailer(retailer?.id, values);
      onDiscardAddModal();
      await fetchRetailerProfile(retailer?.id);
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.message ?? "Unexpected error has occured !",
      });
    }
  };

  const onUpdateRetailerStatus = async (status: string) => {
    try {
      await updateRetailerStatus(retailer?.id, status);
    } catch (error) {
      notification.warn({
        message: "Unexpected error, please try again !",
      });
    }
  };

  return (
    <CustomCard withPaddings>
      <Row className="retailer-header-wrapper">
        <Space size={"middle"}>
          <Avatar size="large" icon={<UserOutlined />} />
          <Col>
            <Row align="middle">
              <Typography.Title className="name" level={4}>
                {retailer?.name}
              </Typography.Title>
              <RetailerStatus status={retailer?.status} toUpdate updateRetailerStatus={onUpdateRetailerStatus} />
            </Row>
            <Typography.Text className="address">{retailer?.officeAddress}</Typography.Text>
          </Col>
        </Space>
        <Dropdown
          overlay={DropdownMenu({ onDeleteRetailer, id: retailer?.id, onEditRetailer: onOpenAddModal })}
          trigger={["click"]}
        >
          <Button>
            <FormattedMessage id="actions" defaultMessage="Actions" />
          </Button>
        </Dropdown>
      </Row>
      <Tabs defaultActiveKey="1">
        {/* Details tab */}
        <Tabs.TabPane
          tabKey="1"
          tab={intl.formatMessage({ id: "retailers.details.title", defaultMessage: "Details" })}
          key={"1"}
        >
          <Typography.Title level={5}>
            <FormattedMessage id="retailers.basicInfos" defaultMessage="Basic Information" />
          </Typography.Title>
          <Row justify="space-between">
            <Col lg={14} md={12} sm={24} xs={24}>
              <Row justify="space-between" className="retailer-details__info-row">
                <InfoWrapper
                  title={intl.formatMessage({ id: "retailers.phoneNumber", defaultMessage: "Phone number" })}
                  info={retailer?.phoneNumber}
                />
                <InfoWrapper
                  title={intl.formatMessage({ id: "retailers.email", defaultMessage: "Email" })}
                  info={retailer?.email}
                />
                <InfoWrapper
                  title={intl.formatMessage({ id: "address", defaultMessage: "Address" })}
                  info={retailer?.officeAddress}
                />
                <InfoWrapper
                  title={intl.formatMessage({
                    id: "retailers.customerphoneNumber",
                    defaultMessage: "Customer support ",
                  })}
                  info={retailer?.supportNumber}
                />
                <InfoWrapper
                  title={intl.formatMessage({ id: "restricted", defaultMessage: "Restricted" })}
                  info={retailer?.restricted ? "YES" : "NO"}
                />
                <InfoWrapper
                  title={intl.formatMessage({ id: "retailers.customTemplates", defaultMessage: "Custom SMS template" })}
                  info={retailer?.isCustomMessagingTemplatesAllowed ? "YES" : "NO"}
                />
              </Row>
              <Divider />

              {/* Other infos */}
              <Typography.Title level={5}>
                <FormattedMessage id="retailers.poc" defaultMessage="Point of contact" />
              </Typography.Title>

              <Row justify="space-between" className="retailer-details__info-row">
                <InfoWrapper
                  title={intl.formatMessage({ id: "input.firstName", defaultMessage: "First name" })}
                  info={retailer?.pointOfContact?.firstName}
                />
                <InfoWrapper
                  title={intl.formatMessage({ id: "input.lastName", defaultMessage: "Last name" })}
                  info={retailer?.pointOfContact?.lastName}
                />
                <InfoWrapper
                  title={intl.formatMessage({ id: "retailers.phoneNumber", defaultMessage: "Phone number" })}
                  info={retailer?.pointOfContact?.phoneNumber}
                />
              </Row>
            </Col>
            <Col lg={9} md={11} sm={24} xs={24}>
              <div className="map-wrapper">
                <RetailerMap pickupPoints={pickups ?? []} />
              </div>
            </Col>
          </Row>
          <Divider />
          {/* Other infos */}
          <RetailerPickupsTable retailerId={retailer?.id} />
        </Tabs.TabPane>
        {/* Documents */}
        <Tabs.TabPane
          key="2"
          tabKey="2"
          tab={intl.formatMessage({ id: "retailers.details.documents", defaultMessage: "Documents" })}
        >
          {/* TODO exatract this to separate component ? */}
          {retailer?.commercialRegistration ? (
            <Col className="document-wrapper" lg={3} md={5} sm={7} xs={12}>
              <Row justify="center">
                <Image
                  width={100}
                  src={retailer?.commercialRegistration?.url}
                  placeholder={<Image preview={false} src={FileImg} />}
                  fallback={FileImg}
                />
              </Row>
              <Row justify="center">
                <Typography.Text className="document-wrapper__title">Commercial Registration</Typography.Text>
              </Row>
            </Col>
          ) : (
            <Empty
              description={intl.formatMessage({
                id: "retailers.details.documents.empty",
                defaultMessage: "No documents found for this retailer.",
              })}
            />
          )}
        </Tabs.TabPane>
      </Tabs>
      <AddRetailerModal
        isOpen={isModalOpen}
        onDiscard={onDiscardAddModal}
        onConfirmRetailerForm={onConfirmRetailerForm}
        mode={ModalModeEnum.EDIT}
        retailerToEdit={retailer}
      />
    </CustomCard>
  );
};

const MSTP = (state: RootStateOrAny) => {
  return {
    isMobile: state.admin.isMobile,
    retailer: state.adminRetailers.selectedRetailer,
    forceReturn: state.adminRetailers.forceReturn,
    pickups: state.retailerPickups.items,
  };
};

export default connect(MSTP, (dispatch) => {
  const {
    selectOneAdminRetailers,
    deleteRetailerAdminRetailers,
    changeStatusAdminRetailers,
    resetFlagAdminRetailers,
    updateRetailerAdminRetailers,
    fetchOneAdminRetailers,
  } = bindActionCreators(adminRetailersActions, dispatch);

  return {
    selectOneAdminRetailers,
    updateOneAdminRetailers: updateRetailerAdminRetailers,
    deleteOneAdminRetailers: deleteRetailerAdminRetailers,
    updateStatusAdminRetailers: changeStatusAdminRetailers,
    resetFlagAdminRetailers,
    fetchOneAdminRetailers,
  };
})(RetailerDetail);
