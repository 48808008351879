import React from "react";
import { Button } from "antd";

import { MobileLoader } from "../../../components/AdminPanel/Mobile/MobileLoader";
import { MobileTitle } from "../../../components/AdminPanel/Mobile/MobileTitle";
import { RetailersGroupsTable } from "./components/RetailersGroupsTable";

export const MobileView = ({
  intl,
  query,
  loading,
  retailers,
  paginationConfig,
  groups,
  selectedGroupRetailers,
  selectedRetailer,
  setQuery,
  createNewGroup,
  loadSelectedGroupRetailers,
  deleteGroupById,
  selectRetailer,
  removeGroupMember,
}) => {
  return (
    <div className="mobile-page-wrapper">
      {loading && <MobileLoader />}
      <MobileTitle title="Retailers Groups" />
      <Button onClick={createNewGroup} className="mobile-btn mobile-btn--blue">
        Create New Group
      </Button>
      <RetailersGroupsTable
        query={query}
        groups={groups}
        retailers={retailers}
        paginationConfig={paginationConfig}
        selectedGroupRetailers={selectedGroupRetailers}
        selectedRetailer={selectedRetailer}
        setQuery={setQuery}
        loadSelectedGroupRetailers={loadSelectedGroupRetailers}
        deleteGroupById={deleteGroupById}
        selectRetailer={selectRetailer}
        removeGroupMember={removeGroupMember}
      />
    </div>
  );
};
