import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Steps, Select, Typography } from "antd";
import UploadOrdersDragger from "../../UploadOrdersDragger";
import { FormattedMessage } from "react-intl";
import UploadedOrdersTable from "../../UploadedOrdersTable";
const { Step } = Steps;
const { Option } = Select;
const OrdersFileDraggerModal = ({
  draggerProps,
  isAdminOrders,
  onSubmit,
  onCancel,
  visible,
  downloadedOrders,
  mainAttributes,
  retailers,
}) => {
  const confirmStep = isAdminOrders ? 2 : 1;
  const defaultStep = 0;
  const [current, setCurrent] = useState(defaultStep);
  const [saveOrders, setSaveOrders] = useState(false);
  const [retailerId, handleSetRetailer] = useState(undefined);
  const [stores, setStores] = useState([]);

  const onSaveOrderCancel = (isSaveOrder) => {
    setSaveOrders(isSaveOrder);
  };

  useEffect(() => {
    if (downloadedOrders?.length) setCurrent(confirmStep);
    else setCurrent(defaultStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadedOrders]);

  useEffect(() => {
    if (retailerId) {
      const points = retailers.find((retailer) => retailer.id === Number(retailerId))?.pickupPoints;
      setStores(points);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailerId]);

  const displayNextStep = (index) => {
    const hasDownloadedOrders = downloadedOrders?.length > 0;
    const isRetailerStep = index === 0 && isAdminOrders;
    const isUploadStep = (index === 1 && isAdminOrders) || (index === 0 && !isAdminOrders);
    const isconfirmUploadSteps =
      ((index === 2 && isAdminOrders) || (index === 1 && !isAdminOrders)) && hasDownloadedOrders;

    const retailerStep = (
      <Row justify="center" align="middle">
        <Typography.Text>Before uploading the orders sheet, please choose a retailer </Typography.Text>
        <Select
          placeholder={"Retailer"}
          className="order-modal__select"
          value={retailerId}
          onSelect={(value) => handleSetRetailer(value)}
        >
          {retailers?.map((value) => (
            <Option key={value.id}> {value.name}</Option>
          ))}
        </Select>
      </Row>
    );

    const uploadStep = (
      <Row style={{ justifyContent: "center" }}>
        <div className="uploadOrdersWrapper">
          <UploadOrdersDragger draggerProps={draggerProps} isAdmin={isAdminOrders} retailerId={retailerId} />
        </div>
      </Row>
    );

    const confirmUploadSteps = (
      <UploadedOrdersTable
        {...mainAttributes}
        saveOrders={saveOrders}
        onSaveOrdersDone={() => {
          setSaveOrders(false);
          onSubmit();
        }}
        onSaveOrderCancel={onSaveOrderCancel}
        selfStoresSelection={isAdminOrders ? stores : mainAttributes?.selfStoresSelection}
        retailerId={retailerId}
      />
    );

    return (
      <>
        {isRetailerStep && retailerStep}
        {isUploadStep && uploadStep}
        {isconfirmUploadSteps && confirmUploadSteps}
      </>
    );
  };

  return (
    <Modal
      key={"upload-orders-modal"}
      visible={visible}
      onCancel={onCancel}
      closable={false}
      className="upload-orders-modal"
      title={<FormattedMessage id="button.orders.uploadExcel" defaultMessage="Upload orders via File" />}
      footer={[
        <Button onClick={onCancel} key="cancel">
          <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
        </Button>,

        <Button onClick={() => setSaveOrders(true)} key="upload" disabled={!downloadedOrders?.length}>
          <FormattedMessage id="button.tab.confirm&UploadOrders" defaultMessage="Confirm and upload all orders" />
        </Button>,
      ]}
    >
      <Row style={{ justifyContent: "center", marginBottom: "20px" }}>
        <Steps current={current} size="small" onChange={setCurrent} style={{ maxWidth: "60vw" }}>
          {isAdminOrders && <Step title="Choose the retailer" key={0} stepIndex={0} />}
          <Step
            title="Upload orders file"
            status={downloadedOrders?.length ? "finish" : "wait"}
            disabled={isAdminOrders && !retailerId}
            key={1}
            stepIndex={1}
          />
          <Step title="Confirm uploaded orders" disabled={!downloadedOrders?.length} key={2} stepIndex={2} />
        </Steps>
      </Row>
      {displayNextStep(current)}
    </Modal>
  );
};

export default OrdersFileDraggerModal;
