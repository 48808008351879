import React from "react";
import { Radio, Collapse, Divider } from "antd";

import { MobileTitle } from "../../../components/AdminPanel/Mobile/MobileTitle";
import { MobileSearch } from "../../../components/AdminPanel/Mobile/MobileSearch";
import { MobileRangePicker } from "../../../components/AdminPanel/Mobile/MobileRangePicker";
import { DeliveriesTable } from "./components/DeliveriesTable";
import { MobileLoader } from "../../../components/AdminPanel/Mobile/MobileLoader";
import { MobilePagination } from "../../../components/AdminPanel/Mobile/MobilePagination";

const { Panel } = Collapse;

export const MobileView = ({
  isDeliveryReportFetching,
  reports,
  searchFilter,
  paginationConfig,
  disabledTomorrowAndFuture,
  onChangeSearchFilter,
  onChangePagination,
  handleSearchDelivery,
  handleSetDates,
  deleteReport,
  reportStatusQuery,
  setReportStatusQuery,
  reportTypeQuery,
  setReportTypeQuery,
}) => {
  const onPageChange = (page, limit) => {
    onChangePagination({ current: page, pageSize: limit });
  };

  return (
    <div className="mobile-page-wrapper mobile-page-wrapper--delivery-reports">
      {isDeliveryReportFetching && <MobileLoader />}
      <MobileTitle title="Delivery Reports" />
      <MobileSearch
        searchFilter={searchFilter}
        defaultSearchFilterValue="driverName"
        onChangeSearchFilter={onChangeSearchFilter}
        onSearch={handleSearchDelivery}
      />
      <MobileRangePicker format="YYYY-MM-DD" onChange={handleSetDates} disabledDate={disabledTomorrowAndFuture} />
      <Collapse ghost>
        <Panel header="Filters" key="1">
          <h3>Order type:</h3>
          <Radio.Group value={reportTypeQuery} onChange={(e) => setReportTypeQuery(e.target.value)}>
            {/* <Radio.Button value="all"> All</Radio.Button>
            <br /> */}
            <Radio.Button value="delivery_report"> Delivery report</Radio.Button>
            {/* <Radio.Button value="driver_report"> Pick up report</Radio.Button> */}
            <Radio.Button value="pickup_report"> Unable to pick up</Radio.Button>
          </Radio.Group>
          <Divider />
          <h3>Order status:</h3>
          <Radio.Group value={reportStatusQuery} onChange={(e) => setReportStatusQuery(e.target.value)}>
            <Radio.Button value="all"> All</Radio.Button>
            <br />
            <Radio.Button value="new"> New</Radio.Button>
            <Radio.Button value="processing"> Pending</Radio.Button>
            <Radio.Button value="resolved"> Resolved</Radio.Button>
          </Radio.Group>
        </Panel>
      </Collapse>
      <DeliveriesTable reports={reports} deleteReport={deleteReport} />
      <MobilePagination {...paginationConfig} onPageChange={onPageChange} />
    </div>
  );
};
