import React from "react";
import { MobileRangePicker } from "../../../components/AdminPanel/Mobile/MobileRangePicker";
import { MobileSelect } from "../../../components/AdminPanel/Mobile/MobileSelect";

import { MobileTitle } from "../../../components/AdminPanel/Mobile/MobileTitle";
import Chart from "../components/Chart";
import SystemReportTable from "../DesktopView/components/Table";
import { chartValueList } from "../system-report-config";
import { ReportConfig } from "./components/ReportConfig";
import { SystemDatePicker } from "./components/SystemDatePicker";

export const MobileView = ({
  isMobile,
  driversList,
  retailersList,
  monthReportQuery,
  comapredMonthData,
  targetMonthData,
  chartReportQuery,
  chartData,
  chartDateRange,
  onSelect,
  onDatePick,
  setDateRange,
  setComparedMonth,
  setTargetMonth,
  setMonthReportQuery,
  setChartReportQuery,
  onChartValueSelect,
}) => {
  const chartOptions = [{ text: "All", value: null }].concat(
    chartValueList.map(({ title, value }) => ({ text: title, value })),
  );

  return (
    <div className="mobile-page-wrapper mobile-page-wrapper--system-report">
      <MobileTitle title="Month Сomparison" />
      <div className="top-content">
        <ReportConfig
          query={monthReportQuery}
          driversList={driversList}
          retailersList={retailersList}
          setQuery={setMonthReportQuery}
          onSelect={onSelect}
        />
        <SystemDatePicker placeholder="Select month for target" onSelect={onDatePick(setTargetMonth, "YYYY-MM-DD")} />
        <SystemDatePicker
          placeholder="Select month for compared"
          onSelect={onDatePick(setComparedMonth, "YYYY-MM-DD")}
        />
        <SystemReportTable
          isMobile={isMobile}
          comparedMonthData={comapredMonthData}
          targetMonthData={targetMonthData}
        />
      </div>
      <ReportConfig
        query={chartReportQuery}
        driversList={driversList}
        retailersList={retailersList}
        setQuery={setChartReportQuery}
        onSelect={onSelect}
      />
      <MobileSelect options={chartOptions} defaultValue={null} onSelect={onChartValueSelect} />
      <MobileRangePicker onChange={onDatePick(setDateRange, "YYYY-MM")} picker="month" format="MM.YYYY" />
      <Chart data={chartData || []} isOneMonth={chartDateRange.from === chartDateRange.to} />
    </div>
  );
};
