import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "retailerInsights",
  url: `${API_URL}/retailers/self/insights`,

  actions: {
    fetchGraph: {
      method: "GET",
      url: `${API_URL}/retailers/self/dashboard/graph`,
      transformResponse: ({ body }) => ({
        body,
      }),
    },
    fetchTotal: {
      method: "GET",
      url: `${API_URL}/retailers/self/dashboard/orders-total`,
      transformResponse: ({ body }) => ({
        body,
      }),
    },
    fetchReport: {
      method: "GET",
      url: `${API_URL}/retailers/self/dashboard/report/generate`,
      // transformResponse: ({ body }) => ({
      //   body,
      // }),
    },
    fetchEmails: {
      method: "GET",
      url: `${API_URL}/retailers/self/email-subscribers`,
      // transformResponse: ({ body }) => ({
      //   body,
      // }),
    },
    sendEmails: {
      method: "POST",
      url: `${API_URL}/retailers/self/email-subscribers`,
      // transformResponse: ({ body }) => ({
      //   body,
      // }),
    },

    changeEmail: {
      method: "PUT",
      url: `${API_URL}/retailers/self/email-subscribers/:id`,
      // transformResponse: ({ body }) => ({
      //   body,
      // }),
    },
    deleteEmail: {
      method: "DELETE",
      url: `${API_URL}/retailers/self/email-subscribers/:id`,
      // transformResponse: ({ body }) => ({
      //   body,
      // }),
    },
  },
});

setAuthHeader(getAuthData().accessToken);
