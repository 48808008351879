import React, { FC } from "react";
import { actions as deliveryJob } from "../../redux/resources/deliveryJobs";
import { bindActionCreators } from "redux";
import { connect, RootStateOrAny } from "react-redux";
import JobsManagement from "src/components/JobsManagement";


const AdminJobManagement: FC<any> = ({
  isMobile,
  fetchDeliveryJobs,
  getOneDeliveryJob,
  getRoutesDeliveryJob,
  updateCashDeliveryJob,
  togglePublishDeliveryJob,
  deleteIncompleteDeliveryJob,
  getBadDeliveryJob,
}) => {

  return (
    <>
      <JobsManagement
        isAdmin={true}
        isMobile={isMobile}
        fetchDeliveryJobs={fetchDeliveryJobs}
        getOneDeliveryJob={getOneDeliveryJob}
        getRoutesDeliveryJob={getRoutesDeliveryJob}
        updateCashDeliveryJob={updateCashDeliveryJob}
        togglePublishDeliveryJob={togglePublishDeliveryJob}
        deleteIncompleteDeliveryJob={deleteIncompleteDeliveryJob}
        getBadDeliveryJob={getBadDeliveryJob}
      />
    </>
  );
};

const MSTP = (state: RootStateOrAny) => {
  return {
    isMobile: state.admin.isMobile,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(deliveryJob, dispatch),
}))(AdminJobManagement);
