import { ClearOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, Descriptions, Divider, Empty, Space, Spin, Tag, Tooltip, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import { connect, RootStateOrAny } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { CustomCard } from "src/utils/common";
import { actions as deliveryJobDetailsActions } from "../../../redux/resources/deliveryJobDetails";
import { useJobDetailsComp } from "./useJobDetails";
import "./job-details-component.less";
import JobsMapRoutes from "../../JobsMap/MapMutipleRoutes";
import { useMapDirectionConfig } from "src/pages/CloudFleeting/useHooks/useMapDircetionConfig";
import { mapCenters } from "src/constants/config";
import { getUserTenant } from "src/helpers/authStorage";
import { BASE_ROUTE } from "src/routes";
import OrdersList from "../OrdersList";
import { JobStatusEnum } from "src/types/CombineJobs";
import { generateRandomKey } from "src/utils/utils";

const { Title } = Typography;

const JobDetailsComponent: FC<any> = (props) => {
  const { jobDetails, isFetchingJobDetails, fetchDeliveryJobDetails, cleanDeliveryJobDetails } = props;
  const { id: jobId } = useParams<{ id?: string }>();
  const [isRouteLoading, setIsRouteLoading] = useState<boolean>(false);
  const currentTenant = getUserTenant();
  const navigate = useHistory();
  const { calculateAndDisplayRoute } = useMapDirectionConfig();
  const { fetchJobDetailsById, downloadJobOrderReciept, cleanJob } = useJobDetailsComp({
    fetchDeliveryJobDetails,
    cleanDeliveryJobDetails,
  });

  const isEditDisabled = !!(
    jobDetails?.status === JobStatusEnum.COMPLETED ||
    jobDetails?.status === JobStatusEnum.PICKUP_COMPLETED ||
    jobDetails?.status === JobStatusEnum.IN_TRANSIT
  );

  useEffect(() => {
    if (jobId) {
      fetchJobDetailsById(Number(jobId));
    }
  }, [jobId]);

  const onRouteChange = (isUpdated: boolean) => {
    setIsRouteLoading(isUpdated);
  };

  if (jobDetails && jobDetails?.orders?.length === 0) {
    return (
      <CustomCard className="job-details-wrapper">
        <div className="job-details-empty">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      </CustomCard>
    );
  }

  const renderJobDetails = () => (
    <>
      <div className="job-dertails-header">
        <div className="title-wrapper">
          <Title level={4}>Job ID # {jobId}</Title>
          <Tag>{jobDetails?.status}</Tag>
        </div>
        <div className="action-wrapper">
          <Tooltip title="Print Job">
            <Button shape="circle" icon={<PrinterOutlined />} onClick={() => downloadJobOrderReciept(Number(jobId))} />
          </Tooltip>
          <Tooltip title="Clean Job">
            <Button
              shape="circle"
              icon={<ClearOutlined />}
              disabled={isEditDisabled}
              onClick={() => cleanJob(Number(jobId))}
            />
          </Tooltip>
          <Button
            type="primary"
            disabled={isEditDisabled}
            onClick={() => navigate.push(`/${BASE_ROUTE}/jobs/${jobId}/configure`)}
          >
            Configure Job
          </Button>
        </div>
      </div>

      <Divider plain />

      <div className="order-details-wrapper">
        <OrdersList onRouteChange={onRouteChange} />
        <div className="job-map-wrapper">
          <JobsMapRoutes
            items={[jobDetails]}
            mapHeight="400px"
            itemsForCombine={[]}
            calculateAndDisplayRoute={calculateAndDisplayRoute}
            jobs={[jobDetails]}
            tenant={(currentTenant as keyof typeof mapCenters) ?? "Qatar"}
            availableJobs={(id: number) => [{ jobId: id }]}
            handleMoveOrder={() => new Promise(() => console.log("not implemented"))}
            isMoveOrders={false}
            showRoutes={true}
            isLoading={isRouteLoading || isFetchingJobDetails}
            mapKey={`map-key-${jobDetails}-${isRouteLoading}-${isFetchingJobDetails}`}
          />
        </div>
      </div>

      <Divider plain />

      <div className="job-info-wrapper">
        <Descriptions title="" layout="vertical">
          <Descriptions.Item label="Pickup Points">{jobDetails?.pickupPointsCount}</Descriptions.Item>
          <Descriptions.Item label="Delivery Points">{jobDetails?.deliveryPointsCount}</Descriptions.Item>
          <Descriptions.Item label="Status">{jobDetails?.status}</Descriptions.Item>
          <Descriptions.Item label="Delivery Time">{jobDetails?.totalTime || "N/A"}</Descriptions.Item>
          <Descriptions.Item label="Package Count">{jobDetails?.packagesCount}</Descriptions.Item>
        </Descriptions>
      </div>
    </>
  );

  const renderLoading = () => (
    <div className="job-details-loading">
      <Spin size="large" />
    </div>
  );

  const renderEmptyScreen = () => (
    <div className="job-details-empty">
      <Empty description="No Job Found" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      <Link to="/admin/jobs">
        <Button>Go Back</Button>
      </Link>
    </div>
  );

  return (
    <CustomCard className="job-details-wrapper">
      {isFetchingJobDetails
        ? renderLoading()
        : jobDetails && jobDetails?.orders?.length > 0
        ? renderJobDetails()
        : renderEmptyScreen()}
    </CustomCard>
  );
};

export default connect(
  (state: RootStateOrAny, ownProps: any) => {
    return {
      jobDetails: state.deliveryJobDetails.items[0],
      isFetchingJobDetails: state.deliveryJobDetails.isFetching,
    };
  },
  (dispatch: any, ownProps: any) => ({
    ...bindActionCreators(deliveryJobDetailsActions, dispatch),
  }),
)(JobDetailsComponent);
