import React, { useState } from "react";
import { Button, Popconfirm, Popover } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

export const StatusPopover = ({
  record,
  onApproveStatus,
  onWaitStatus,
  onRejectStatus,
  isWaiting,
  onAction = () => {},
}) => {
  const [popupVisible, setPopupVisible] = useState(false);

  const handleVisibleChange = (visible) => {
    setPopupVisible(visible);
  };

  return (
    <Popover
      content={
        <>
          <Button
            onClick={() => {
              onApproveStatus(record.id)();
              onAction();
            }}
            style={{ marginRight: 8 }}
          >
            Approve
          </Button>
          {/* {!isWaiting && (
            <Button onClick={onWaitStatus(record.id)} style={{ marginRight: 8 }}>
              Wait
            </Button>
          )} */}
          <Popconfirm
            title="Are you sure to reject request?"
            onConfirm={() => {
              onRejectStatus(record.id)();
              onAction();
            }}
            okText="Yes"
            cancelText="No"
            okType="danger"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          >
            <Button danger>Reject</Button>
          </Popconfirm>
        </>
      }
      title={null}
      trigger="click"
      visible={popupVisible}
      onVisibleChange={handleVisibleChange}
    >
      <Button>{isWaiting ? "Waiting" : "Change status"}</Button>
    </Popover>
  );
};
