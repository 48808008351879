import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "accounting",
  url: `${API_URL}/admin/orders`,

  actions: {
    loadAllDrivers: {
      method: "GET",
      url: `${API_URL}/admin/accounting/drivers?page=:page&limit=:limit`,
      transformResponse: ({ body }) => {
        return {
          items: body.items,
          pagination: {
            currentPageNumber: body.meta.currentPage,
            numItemsPerPage: body.meta.itemsPerPage,
            totalCount: body.meta.totalItems,
          },
        };
      },
      reduce: (state, action) => {
        return {
          ...state,
          items: action.items,
          pagination: action.pagination,
        };
      },
    },
    loadAllRetailers: {
      method: "GET",
      url: `${API_URL}/admin/accounting/retailers?page=:page&limit=:limit`,
      transformResponse: ({ body }) => {
        return {
          items: body.items,
          pagination: {
            currentPageNumber: body.meta.currentPage,
            numItemsPerPage: body.meta.itemsPerPage,
            totalCount: body.meta.totalItems,
          },
        };
      },
      reduce: (state, action) => {
        return {
          ...state,
          items: action.items,
          pagination: action.pagination,
        };
      },
    },
    loadRetailer: {
      method: "GET",
      url: `${API_URL}/admin/accounting/retailers/:id`,
    },
    loadDriver: {
      method: "GET",
      url: `${API_URL}/admin/accounting/drivers/:id`,
    },
    addBracket: {
      method: "POST",
      url: `${API_URL}/admin/accounting/drivers/:id/brackets`,
    },
    removeBracket: {
      method: "DELETE",
      url: `${API_URL}/admin/accounting/drivers/:driverId/brackets/:bracketId`,
    },
    changeBracket: {
      method: "PATCH",
      url: `${API_URL}/admin/accounting/drivers/:driverId/brackets/:id`,
    },
    setCostRetailer: {
      method: "POST",
      url: `${API_URL}/admin/accounting/retailers/:id`,
    },
    setCostDriver: {
      method: "POST",
      url: `${API_URL}/admin/accounting/drivers/:id`,
    },
    getStatsDriver: {
      method: "GET",
      url: `${API_URL}/admin/accounting/drivers/:id/total`,
    },
    getStatsRetailer: {
      method: "GET",
      url: `${API_URL}/admin/accounting/retailers/:id/total`,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
