import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Upload, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import uploadIcon from "../../../assets/uploadIcon.png";

import "./uploadOrdersDragger.less";
import { getAuthData, getUserTenant } from "../../../helpers/authStorage";

const { Dragger } = Upload;

const UploadOrdersDragger = ({ draggerProps, isAdmin, retailerId }) => {
  const intl = useIntl();

  const uploadOptions = isAdmin
    ? { ...draggerProps, action: `${draggerProps.action}?retailerId=${retailerId}` }
    : draggerProps;

  return (
    <Dragger
      {...uploadOptions}
      headers={{
        Authorization: `Bearer ${getAuthData().accessToken}`,
        Tenant: getUserTenant(),
      }}
      className="draggerStyle"
    >
      <div className="uploadOrdersDraggerWrapper">
        <img src={uploadIcon} alt="Upload icon" />
        <p className="uploadText">
          <FormattedMessage id="button.orders.upload" defaultMessage="Upload orders" />
        </p>
        <Tooltip
          placement="top"
          title={`${intl.formatMessage({
            id: "page.ordersManagment.tooltip",
            defaultMessage: "Customer phone number should include the country code",
          })}`}
        >
          <InfoCircleOutlined />
        </Tooltip>
      </div>
    </Dragger>
  );
};

export default UploadOrdersDragger;
