import React, { FC } from "react";
import { mapCenters } from "../../../../constants/config";
import { CustomCard } from "../../../../utils/common";
import { ISingleJobHandler } from "../../types/configureModal.types";
import { IOrdersTable, IOrdersTableAction } from "../../types/ordersTable.types";
import { IPreviewOrdersAction } from "../../types/previewActions.types";
import { OrdersTable } from "../OrdersTable";
import JobsMapRoutes from "../../../JobsMap/MapMutipleRoutes";
import { Button, Divider, Row, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { InfoCircleOutlined, NodeIndexOutlined } from "@ant-design/icons";
import useDisplayRoutes from "../../hooks/useDisplayRoutes";
import PickupTable from "../PickupTable";

import { getPointsByType } from "src/helpers/maps/maps.helpers";
import { IOrder, PointTypeEnum } from "src/entities";
import StatusTag from "src/components/StatusTag";
const { Option } = Select;

const SingleJobHandler: FC<ISingleJobHandler & IPreviewOrdersAction & IOrdersTable & IOrdersTableAction> = ({
  jobToConfigure,
  deleteOrders,
  currentPreviewAction,
  isMain,
  moveOrders,
  addOrders,
  onSelectOrders,
  destinationJob,
  showMaps,
  availableJobs,
  currentTenant,
  setShowMaps,
  allJobsIds,
  handleChooseJob,
  handleReorderJobOrders,
  changePickupOrder,
}) => {
  // translation
  const intl = useIntl();
  // map rendering
  const calculateAndDisplayRoute = useDisplayRoutes();
  const renderMap = (items: any, previewOrders: IOrder[]) => {
    let allItems: any[] = [items]; // main job
    if (previewOrders?.length > 0) {
      allItems.push({ orders: previewOrders });
    }

    return (
      <>
        {showMaps.isOn && jobToConfigure?.job && (
          <JobsMapRoutes
            key={`${jobToConfigure?.job?.id}-${showMaps.key}`}
            mapHeight="50vh"
            items={allItems}
            itemsForCombine={[]}
            jobs={[items]}
            tenant={(currentTenant as keyof typeof mapCenters) ?? "Qatar"}
            availableJobs={availableJobs}
            calculateAndDisplayRoute={calculateAndDisplayRoute}
            handleMoveOrder={() => new Promise(() => console.log("not implemented"))}
            isMoveOrders={false}
            showRoutes={true}
          />
        )}
      </>
    );
  };

  // we allow add, move and remove orders for these job' status
  const JOBS_NOTALLOWED_STATUS = ["completed", "in_transit"];

  const isJobModifcationAllowed = (status: string) => status != null && !JOBS_NOTALLOWED_STATUS.includes(status);
  return (
    <CustomCard
      title={
        isMain
          ? intl.formatMessage(
              {
                id: "page.configureJob.header",
                defaultMessage: `Configure Job n° ${jobToConfigure?.job?.id}`,
              },
              {
                job: jobToConfigure?.job?.id ?? "--",
              },
            )
          : intl.formatMessage(
              {
                id: "page.configureJob.headerCompare",
                defaultMessage: `Compare to Job n° ${jobToConfigure?.job?.id ?? "--"}`,
              },
              {
                job: jobToConfigure?.job?.id ?? "--",
              },
            )
      }
      toolbar={
        isMain && setShowMaps ? (
          <Row className="jobHandler--toolbar">
            <StatusTag status={jobToConfigure?.job?.status} />

            <Button
              type="primary"
              icon={<NodeIndexOutlined />}
              onClick={() => {
                setShowMaps({ ...showMaps, isOn: !showMaps.isOn });
              }}
            >
              <FormattedMessage
                id={!showMaps.isOn ? "page.viewMenu.show" : "page.viewMenu.hide"}
                defaultMessage={!showMaps.isOn ? `Show maps` : `Hide maps`}
                values={{ col: "maps" }}
              />
            </Button>
          </Row>
        ) : (
          <Row className="jobHandler--toolbar">
            <Select
              data-testid="jobs-selector"
              placeholder="Select a job"
              style={{ minWidth: "150px" }}
              onSelect={(value: number) => {
                handleChooseJob(value);
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (option!.children as unknown as number).toString().toLowerCase().includes(input.toLowerCase());
              }}
            >
              {allJobsIds?.map(({ jobId }: { jobId: number }) => (
                <Option key={jobId} data-testid={`job-selector-${jobId}`}>
                  {" "}
                  Job n° {jobId}
                </Option>
              ))}
            </Select>
          </Row>
        )
      }
    >
      {jobToConfigure && renderMap(jobToConfigure.job, jobToConfigure.previewOrdersToAdd)}
      {jobToConfigure && jobToConfigure.job?.deliveryOrders?.length >= 25 && (
        <Row justify="center">
          <p className="preview_wrapper--note">
            <InfoCircleOutlined />
            <FormattedMessage
              id="page.configureJob.reorderAffected"
              defaultMessage={`Job has more than 25 orders, reordering deliveries is blocked by the system !`}
            />
          </p>
        </Row>
      )}
      {jobToConfigure && (
        <OrdersTable
          isMain={isMain}
          moveOrders={moveOrders}
          JobInfo={jobToConfigure}
          destinationJob={destinationJob}
          className="jobs_wrapper--compare_orders-table"
          onSelectOrders={onSelectOrders}
          isActionPreview={currentPreviewAction.isOn}
          addOrders={addOrders}
          deleteOrders={deleteOrders}
          handleReorderJobOrders={handleReorderJobOrders}
          isJobModifcationAllowed={isJobModifcationAllowed(jobToConfigure?.job?.status)}
        />
      )}

      {!jobToConfigure && (
        <Row justify="center">
          <p className="preview_wrapper--info">
            <InfoCircleOutlined />
            <FormattedMessage
              id="page.configureJob.mustSelectJob"
              defaultMessage={`Make sure to select a job to compare jobs / move orders !`}
            />
          </p>
        </Row>
      )}

      {jobToConfigure && (
        <div className="jobHandler--pickup_wrapper" data-testid="job-pickups-table">
          <Divider />
          <h4>
            {intl.formatMessage({
              id: "input.pickupPoint",
              defaultMessage: "Pickup Point",
            })}
          </h4>
          <PickupTable
            jobOrders={jobToConfigure?.job?.deliveryOrders}
            dataSource={getPointsByType(jobToConfigure?.job, PointTypeEnum.PICKUP)}
            changeOrderHandler={changePickupOrder}
            previewOrders={jobToConfigure?.previewOrdersToAdd}
          />
          {}
        </div>
      )}
    </CustomCard>
  );
};

export default SingleJobHandler;
