import React from "react";

import Honeycomb from "../../../../assets/newLanding/Honeycomb";
import styles from "./mainHoneycomb.module.less";

const MainHoneycomb = () => (
  <div className={styles.honeycombWrap}>
    <Honeycomb className={styles.honeycomb1} />
    <Honeycomb className={styles.honeycomb2} />
    <Honeycomb className={styles.honeycomb3} />
    <Honeycomb className={styles.honeycomb4} />

    <Honeycomb className={styles.honeycomb5} />
    <Honeycomb className={styles.honeycomb6} />
    <Honeycomb className={styles.honeycomb7} />
    <Honeycomb className={styles.honeycomb8} />
  </div>
);

export default MainHoneycomb;
