import React from "react";
import { Select } from "antd";

import "./styles.less";

const { Option } = Select;

export const MobileSelect = ({ options, defaultValue, value, placeholder, onSelect, getPopupContainer }) => (
  <Select
    placeholder={placeholder}
    defaultValue={defaultValue}
    value={value}
    onChange={onSelect}
    className="mobile-select"
    getPopupContainer={getPopupContainer}
  >
    {options?.map(({ value, text }) => (
      <Option value={value} key={value}>
        {text}
      </Option>
    ))}
  </Select>
);
