import { Col, Divider, Row } from "antd";
import React from "react";

import CustomDivider from "../../../components/Divider";
import { CustomCard } from "../../../utils/common";
import Map from "../components/Map";
import DriversMapFilter from "./components/DriversMapFilter";
import TrackingShipmentMap, { ProviderType } from "../../../components/TrackingShipmentMap";

export const DesktopView = ({
  job,
  drivers,
  selectedDriver,
  allDriverSelected,
  locations,
  paginationData,
  selectDriver,
  markerClick,
  showJobs,
  changeQueryParams,
}) => {
  return (
    <div className="pageWrapper page-wrapper-fix-driver-map">
      <CustomCard title="Tracking Maps">
        <TrackingShipmentMap job={{}} providerType={ProviderType.FLEET_TRACKER} />
      </CustomCard>

      <Row style={{ height: "80%" }}>
        {/*  <Col span={18}>
          <Map
            allDriverSelected={allDriverSelected}
            driverLocations={locations}
            onMarkerClick={markerClick}
            job={job}
            showJobs={showJobs}
          />
        </Col>
        <Col span={5} offset={1}>
          <DriversMapFilter
            drivers={drivers}
            selectedDriver={selectedDriver}
            onSelectDriver={selectDriver}
            paginationData={paginationData}
            changeQueryParams={changeQueryParams}
          />
        </Col> */}
      </Row>
    </div>
  );
};
