import React from "react";
import { Button } from "antd";

import styles from "./getStartedButton.module.less";

const GetStartedButton = ({ children, onClick, className, ...restProps }) => (
  <Button type="primary" size="large" onClick={onClick} className={`${styles.button} ${className}`} {...restProps}>
    {children}
  </Button>
);

export default GetStartedButton;
