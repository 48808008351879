import { createResource } from "redux-rest-resource";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "retailerInfo",
  url: `${API_URL}/auth/retailers/leave-information`,
  actions: {
    save: {
      method: "POST",
    },
  },
});
