import React from "react";
import { EyeOutlined, EyeInvisibleOutlined, SaveTwoTone } from "@ant-design/icons";
import { Button } from "antd";
export const useSettings = ({ intl, vueColumns, isAdminOrders, saveRetailerView }) => {
  const tablesKeys = [
    {
      messageId: "tab.orders.current",
      defaultMessage: "Current Orders",
      key: "1",
    },
    {
      messageId: "tab.orders.new",
      defaultMessage: "New Orders",
      key: "11",
    },
    {
      messageId: "tab.orders.postponed",
      defaultMessage: "Postponed Orders",
      key: "3",
    },
    {
      messageId: "tab.orders.completed",
      defaultMessage: "Completed Orders",
      key: "5",
    },
    {
      messageId: "tab.orders.notAssigned",
      defaultMessage: "Not Assigned Orders",
      key: "6",
    },
    {
      messageId: "tab.orders.canceled",
      defaultMessage: "Canceled Orders",
      key: "7",
    },

    {
      messageId: "tab.orders.returnToShipper",
      defaultMessage: "Returned to shipper",
      key: "8",
    },
    {
      messageId: "tab.orders.trash",
      defaultMessage: "Trash Orders",
      key: "9",
    },

    isAdminOrders && {
      messageId: "tab.orders.pickupFailed",
      defaultMessage: "Pickup failed",
      key: "10",
    },
  ];

  const vueSettingsItems = [
    {
      label: vueColumns.all
        ? `${intl.formatMessage({
            id: "page.viewMenu.all",
            defaultMessage: "Show all columns",
          })}`
        : `${intl.formatMessage({
            id: "page.viewMenu.not.all",
            defaultMessage: "Show main columns only",
          })}`,
      key: "all",
    },
    {
      label: `${intl.formatMessage({ id: "tabPane.trackingID", defaultMessage: "tracking ID" })}`,
      key: "tracking",
      icon: vueColumns.tracking ? <EyeOutlined /> : <EyeInvisibleOutlined />,
    },

    {
      label: `${intl.formatMessage({
        id: "tabPane.customerPhone",
        defaultMessage: "phone number",
      })}`,
      key: "phone",
      icon: vueColumns.phone ? <EyeOutlined /> : <EyeInvisibleOutlined />,
    },
    {
      label: "Ticket",
      key: "ticket",
      icon: vueColumns.ticket ? <EyeOutlined /> : <EyeInvisibleOutlined />,
    },

    {
      label: `${intl.formatMessage({ id: "tabPane.uploadedAt", defaultMessage: "Uploaded at" })}`,
      key: "uploadedAt",
      icon: vueColumns.uploadedAt ? <EyeOutlined /> : <EyeInvisibleOutlined />,
    },
    {
      label: `${intl.formatMessage({ id: "tabPane.updatedAt", defaultMessage: "Updated at" })}`,
      key: "updatedAt",
      icon: vueColumns.updatedAt ? <EyeOutlined /> : <EyeInvisibleOutlined />,
    },

    {
      label: `${intl.formatMessage({ id: "tabPane.postponedTo", defaultMessage: "Postponed to" })}`,
      key: "postponedTo",
      icon: vueColumns.postponedTo ? <EyeOutlined /> : <EyeInvisibleOutlined />,
    },

    isAdminOrders && {
      label: `${intl.formatMessage({ id: "tabPane.retailerName", defaultMessage: "Retailer Name" })}`,
      key: "retailer",
      icon: vueColumns.retailer ? <EyeOutlined /> : <EyeInvisibleOutlined />,
    },
    {
      label: `${intl.formatMessage({ id: "tabPane.address", defaultMessage: "Adress" })}`,
      key: "address",
      icon: vueColumns.address ? <EyeOutlined /> : <EyeInvisibleOutlined />,
    },
    {
      label: `${intl.formatMessage({ id: "tabPane.zone", defaultMessage: "Zone" })}`,
      key: "zone",
      icon: vueColumns.zone ? <EyeOutlined /> : <EyeInvisibleOutlined />,
    },
    isAdminOrders && {
      label: `${intl.formatMessage({ id: "tabPane.reports", defaultMessage: "Reports" })}`,
      key: "reports",
      icon: vueColumns.reports ? <EyeOutlined /> : <EyeInvisibleOutlined />,
    },
    { type: "divider" },
    {
      key: "save",
      label: (
        <Button
          type="text"
          icon={<SaveTwoTone />}
          onClick={() => {
            saveRetailerView();
          }}
        >
          {intl.formatMessage({ id: "page.viewMenu.save", defaultMessage: "Save view" })}
        </Button>
      ),
    },
  ];
  const matchStatusOrders = {
    all: "All",
    completed: "Completed",
    return_completed: "Return completed",
    completed_manually_return: "Completed manually return",
    canceled: "Canceled",
    canceled_after_pickup: "Canceled after pickup",
    postponed: "Postponed",
    postponed_after_pickup: "Postponed after pickup",
    failed_to_deliver: "Failed Delivery",
    pickup_failed: "Failed Pickup",
    new: "New Orders",
    pickup_completed: "Pickup Done",
    in_transit: "In transit",
    in_delivery: "In delivery",
    arrived: "Arrived",
    return_to_shipper: "Returned to Shipper",
    deleted: "Deleted",
    can_be_assigned: "Can be assigned",
  };
  return { tablesKeys, vueSettingsItems, matchStatusOrders };
};
