import { FC, ReactNode } from "react";
import clsx from "clsx";
import React from "react";
import "./__card.style.less";
import { Col, Row } from "antd";
import CustomDivider from "../../../components/Divider";

type Props = {
  className?: string;
  shadow?: boolean;
  border?: boolean;
  rounded?: "rounded" | "rounded-top" | "rounded-bottom";
  title?: string;
  subtitle?: string;

  toolbar?: ReactNode;
  subaction?: ReactNode;
  testId?: string;
  withPaddings? : boolean
};

const CustomCard: FC<Props & { children?: ReactNode }> = (props) => {
  const { className, shadow, border, rounded, children, title, toolbar, subtitle, subaction, testId, withPaddings } = props;
  return (
    <div
      data-testid={testId}
      className={clsx(
        "card",
        className && className,
        {
          "shadow-sm": shadow,
          "card-bordered": border,
        },
        rounded && `card-${rounded}`,
        withPaddings && "card-margins"
      )}
    >
      {title && (
        <Row className="card_header">
          <Col>
            <div className="card_header--title">{title}</div>
            <CustomDivider />
          </Col>
          <Col className="card_header--toolbar ant-col-lg-10 ant-col-md-12 ant-col-sm-18">{toolbar}</Col>
        </Row>
      )}
      <div className="card_body">
        <Row className="card_body--subheader">
          {subtitle && <div className="card_body--subtitle">{subtitle}</div>}
          {subaction}
        </Row>

        {children}
      </div>
    </div>
  );
};

export { CustomCard };
