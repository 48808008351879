import React from "react";
import { Tabs } from "antd";

import "./styles.less";

const { TabPane } = Tabs;

export const MobileTabs = ({
  leftTab,
  rightTab,
  leftTabTitle,
  rightTabTitle,
  onChange,
  centralTab,
  centralTabTitle,
  centralSecondTab,
  centralSecondTabTitle,
  centralThirdTab,
  centralThirdTabTitle,
  defaultActiveKey,
  activeKey,
}) => {
  return (
    <Tabs
      type="card"
      onChange={onChange}
      className="mobile-tabs"
      defaultActiveKey={defaultActiveKey}
      activeKey={activeKey}
      // tabBarStyle={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
    >
      <TabPane tab={leftTabTitle} key="1">
        {leftTab}
      </TabPane>
      {centralTab && centralTabTitle && (
        <TabPane tab={centralTabTitle} key="3">
          {centralTab}
        </TabPane>
      )}
      {centralSecondTab && centralSecondTabTitle && (
        <TabPane tab={centralSecondTabTitle} key="4">
          {centralSecondTab}
        </TabPane>
      )}
      {centralThirdTab && centralThirdTabTitle && (
        <TabPane tab={centralThirdTabTitle} key="5">
          {centralThirdTab}
        </TabPane>
      )}
      <TabPane tab={rightTabTitle} key="2">
        {rightTab}
      </TabPane>
    </Tabs>
  );
};
