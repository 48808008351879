import React from "react";
// import {Button} from "antd";
// import {EyeOutlined} from '@ant-design/icons';

import "./style.less";

const PdfFile = ({ url }) => (
  <>
    {/* <div className='pdfFileWrapper'>
                <FilePdfOutlined className='pdfIcon'/>
                <span className='pdfFileText'>PDF file</span>
            </div> */}
    <div className="pdfFileWrapper">
      <iframe
        frameBorder="0"
        title="downloaded pdf"
        style={{ borderRadius: "20px" }}
        height="100%"
        width="100%"
        src={url}
      />
    </div>

    {/* {url && <Button className='pdfPreviewBtn' type="primary" shape="round" icon={<EyeOutlined />}><a className='previewLink' href={url} target='_blank' rel="noopener noreferrer">Preview</a></Button>} */}
  </>
);

export default PdfFile;
