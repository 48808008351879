import React, { useState } from "react";
import { Layout as AntLayout } from "antd";

import Header from "../Header";
import Footer from "../Footer";
import styles from "./layout.module.less";
import GetInTouchModal from "../GetInTouchModal";

const Layout = ({ children, isMobile }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleToggleModal = () => setIsModalVisible((presState) => !presState);
  return (
    <AntLayout className={styles.layout}>
      <Header
        isMobile={isMobile}
        isModalVisible={isModalVisible}
        handleToggleModal={handleToggleModal}
        setIsModalVisible={setIsModalVisible}
      />

      <AntLayout.Content className={styles.main}>
        {children}
        {isModalVisible && (
          <GetInTouchModal isMobile={isMobile} isVisible={isModalVisible} onToggleModal={handleToggleModal} />
        )}
      </AntLayout.Content>

      <Footer isMobile={isMobile} />
    </AntLayout>
  );
};

export default Layout;
