import { AsyncActionCreator } from "redux-rest-resource";
import IRetailer from "src/entities/retailer.entity";

export interface IStoreManagement {
  // actions
  fetchAdminRetailers: AsyncActionCreator;
  addOneAdminRetailers: AsyncActionCreator;
  // state
  retailers: IRetailer[];
  pagination: any;
  isFetching: boolean;
}

export interface IUseHooks {
  // actions
  fetchAdminRetailers: AsyncActionCreator;
  addOneAdminRetailers: AsyncActionCreator;
}

export interface RetailersListProps {
  data: IRetailer[];
  isLoading: boolean;
  getRetailers: (
    searchedValue: {
      value?: string | undefined;
      field?: string | undefined;
    },
    query: any,
  ) => Promise<void>;
  onAddRetailer: () => void;
  pagination: any;
}

export enum ModalModeEnum {
  EDIT = "edit",
  CREATE = "add",
}
