import { Button, Modal, Row, Table, Tooltip, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ColumnsType } from "antd/es/table";
import { ExclamationCircleOutlined, FlagTwoTone } from "@ant-design/icons";
import PickupModal from "src/components/PickupModal";
import { connect, RootStateOrAny } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as retailerPickupsActions } from "../../../../../../redux/resources/retailerPickups";
import useRetailerPickup from "./useRetailerPickups";
import { EmptyTableIndicator } from "src/components";
import { ModalModeEnum } from "src/pages/StoreManagement/types/store-management";

const RetailerPickupsTable: FC<any> = ({
  retailerId,
  items: data,
  fetchPickups,
  deleteOnePickup,
  addOnePickup,
  updateOnePickup,
  loading,
}) => {
  const intl = useIntl();
  const columns: ColumnsType<any> = [
    {
      title: `${intl.formatMessage({ id: "name", defaultMessage: "Name" })}`,
      key: "name",
      render(value, record, index) {
        if (value.main) {
          return (
            <Tooltip title="Main pickup point">
              <Typography.Text>
                {value.name} <FlagTwoTone twoToneColor="#eb2f96" />
              </Typography.Text>
            </Tooltip>
          );
        } else {
          return value.name;
        }
      },
    },

    {
      title: `${intl.formatMessage({ id: "address", defaultMessage: "Address" })}`,
      dataIndex: "address",
      key: "address",
    },
    {
      title: `${intl.formatMessage({ id: "input.instructions", defaultMessage: "Instructions" })}`,
      dataIndex: "instructions",
      key: "instructions",
    },

    {
      title: `${intl.formatMessage({ id: "actions", defaultMessage: "Actions" })}`,
      key: "action",
      render(value, record, index) {
        return (
          <Row>
            <Button type="text" onClick={() => openPickupModal(ModalModeEnum.EDIT, record)}>
              Edit
            </Button>
            <Button type="text" danger onClick={() => onDeletePickup(record)}>
              Delete
            </Button>
          </Row>
        );
      },
    },
  ];

  // state
  const [isPickupModalOpen, setIsPickupModalOpen] = useState<{ visible: boolean; mode?: ModalModeEnum }>({
    visible: false,
  });
  const [selectedPickupLocation, setSelectedPickupLocation] = useState<
    { lat: number; long: number; name: string; address: string } | undefined
  >(undefined);

  // hooks
  const { getPickupsForRetailer, deletePickupForRetailer, addPickupForRetailer, updatePickupForRetailer } =
    useRetailerPickup({
      fetchPickups,
      deleteOnePickup,
      addOnePickup,
      updateOnePickup,
    });

  useEffect(() => {
    if (retailerId) getPickupsForRetailer(retailerId);
  }, [retailerId]);

  // methods
  const openPickupModal = (
    mode: ModalModeEnum,
    pickupLocation:
      | { id: number; lat: number; long: number; name: string; address: string; instructions?: string }
      | undefined,
  ) => {
    if (pickupLocation) setSelectedPickupLocation(pickupLocation);
    setIsPickupModalOpen({ visible: true, mode });
  };

  const onDeletePickup = (pickup: any) => {
    Modal.confirm({
      title: "Confirmation",
      icon: <ExclamationCircleOutlined />,
      content: `Do you really want to delete ${pickup?.name} ?`,
      okText: "YES",
      cancelText: "NO",
      async onOk(...args) {
        await deletePickupForRetailer(retailerId, pickup.id);
      },
    });
  };

  const onPickupUpdate = async (pickup: any, mode: ModalModeEnum) => {
    try {
      if (mode === ModalModeEnum.CREATE) {
        await addPickupForRetailer(retailerId, pickup);
      } else {
        await updatePickupForRetailer(retailerId, pickup, selectedPickupLocation);
      }
      await getPickupsForRetailer(retailerId);
    } catch (error) {
    } finally {
      setIsPickupModalOpen({ visible: false });
    }
  };
  return (
    <>
      <Row justify="space-between">
        <Typography.Title level={5}>
          <FormattedMessage id="retailers.pickupPoints" defaultMessage="Pickup points" />
        </Typography.Title>
        <Button type="primary" onClick={() => openPickupModal(ModalModeEnum.CREATE, undefined)}>
          <FormattedMessage id="retailers.pickupPoints.add" defaultMessage="Add new pickup" />
        </Button>
      </Row>
      <Table
        className="retailers-pickups-table"
        columns={columns}
        dataSource={data}
        rowKey={(item: any) => item.id}
        size={"small"}
        loading={loading}
        locale={{
          emptyText: <EmptyTableIndicator title="No pickup location found for this retailer !" description="" />,
        }}
      />
      <PickupModal
        isModalOpen={isPickupModalOpen}
        setIsModalOpen={setIsPickupModalOpen}
        handleLocationUpdate={onPickupUpdate}
        locationToEdit={selectedPickupLocation}
      />
    </>
  );
};

const MSTP = (state: RootStateOrAny) => {
  return {
    items: state.retailerPickups.items,
    loading: state.retailerPickups.isFetching,
  };
};

export default connect(MSTP, (dispatch) => {
  const {
    fetchRetailerPickups,
    deleteOnePickupRetailerPickups,
    addOnePickupRetailerPickups,
    updateOnePickupRetailerPickups,
  } = bindActionCreators(retailerPickupsActions, dispatch);

  return {
    fetchPickups: fetchRetailerPickups,
    deleteOnePickup: deleteOnePickupRetailerPickups,
    addOnePickup: addOnePickupRetailerPickups,
    updateOnePickup: updateOnePickupRetailerPickups,
  };
})(RetailerPickupsTable);
