import React from "react";

import { ReactComponent as Target } from "../../assets/map/target.svg";

import "./style.less";
const style = {
  transform: "translate(-50%, -50%)",
  position: "relative",
  left: "-13px",
  top: "-13px",
};

const PointMarker = ({ lat, lng }) => {
  return (
    <div style={style}>
      <Target />
    </div>
  );
};

export default PointMarker;
