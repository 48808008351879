import React from "react";
import { FormattedMessage } from "react-intl";

import registrationFinished from "../../../../assets/registrationFinished.png";
import appStore from "../../../../assets/appStore.png";
import googlePlay from "../../../../assets/googlePlay.png";

import "./style.less";

const DriverRegistrationFinished = () => (
  <>
    <h3 className="title">
      <FormattedMessage id="page.step.driver.final.title" defaultMessage="Thank you!" />
    </h3>
    <h3 className="title">
      <FormattedMessage id="page.step.driver.final.subtitle" defaultMessage="Your application is now under review." />
    </h3>
    <img className="finishImage" src={registrationFinished} alt="Registration finished" />
    <p className="driverRegistrationFinishedText">
      <FormattedMessage
        id="page.step.driver.final.text"
        defaultMessage="Your application will be processed within 1-3 business days. You will recieve an email notification once your application has been processed."
      />
    </p>
    <div className="driverRegistrationFinishedImagesWrapper">
      <a href="https://apps.apple.com/us/app/dliv/id6470184640">
        <img src={appStore} alt="App store link" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.dliv">
        <img src={googlePlay} alt="Google play link" />
      </a>
    </div>
  </>
);

export default DriverRegistrationFinished;
