import React from "react";
import { Column } from "@ant-design/charts";
import { DatePicker, Select } from "antd";
import moment from "moment";
import { FormattedMessage } from "react-intl";

const { Option } = Select;
const { RangePicker } = DatePicker;

// const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const week = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

//eslint-disable-next-line
Date.prototype.getWeek = function () {
  var onejan = new Date(this.getFullYear(), 0, 1);
  var today = new Date(this.getFullYear(), this.getMonth(), this.getDate());
  var dayOfYear = (today - onejan + 86400000) / 86400000;
  return Math.ceil(dayOfYear / 7);
};

function RetailerGraph({ intl, data, handleGraphDate, dateType, handleDateType, date }) {
  const formattedData = data.map((e, i) => {
    if (e.date) {
      // const tempDate = new Date(e.date);
      // e.month = month[tempDate.getMonth()];
      // e.year = tempDate.getYear() + 1900;
      // e.day = "".concat(tempDate.getDate(), "/", e.month,"/",e.year);

      //new Date(tempDate.getTime() - tempDate.getTimezoneOffset() * 60000)
      //    .toISOString()
      //   .split("T")[1]
      //     .split(".")[0];

      // e.week = tempDate.getWeek();

      if (e.date.length === 4 && !e.formatted) {
        e.title = "Year " + e.date;
      } else if (e.date.length === 7 && !e.formatted) {
        e.date = e.date.slice(5) + "/" + e.date.slice(0, 4);
        e.title = "Month " + e.date;
      } else if (!e.formatted && dateType !== "week") {
        const index = moment(e.date).add(3, "hours").day() - 1;
        e.date = e.date.slice(8) + "/" + e.date.slice(5, 7) + "/" + e.date.slice(0, 4);
        e.title = week[index] + " " + e.date;
      } else if (!e.formatted) {
        const dateBits = e.date.split("-");
        e.title =
          "Week " +
          moment(dateBits[0]).add(parseInt(dateBits[2]), "weeks").startOf("isoweek").format("DD/MM/YYYY") +
          " - " +
          moment(dateBits[0]).add(parseInt(dateBits[2]), "weeks").endOf("isoweek").format("DD/MM/YYYY");

        e.date = dateBits[2] + "/" + dateBits[0];
      }
      e.formatted = true;
      e.total = e.completed + e.returned + e.canceled;
      return e;
    } else return null;
  });
  const destructuredData = [];
  formattedData.forEach((e, i) => {
    destructuredData.push({
      type: "completed",
      value: e.completed,
      month: e.month,
      day: e.day,
      week: e.week,
      year: e.year,
      date: e.date,
      title: e.title,
    });
    destructuredData.push({
      type: "returned",
      value: e.returned,
      month: e.month,
      day: e.day,
      week: e.week,
      year: e.year,
      date: e.date,
      title: e.title,
    });
    destructuredData.push({
      type: "canceled",
      value: e.canceled,
      month: e.month,
      day: e.day,
      week: e.week,
      year: e.year,
      date: e.date,
      title: e.title,
    });
  });

  const config = {
    data: destructuredData.reverse(),
    // padding:60,
    height: 300,
    isStack: true,
    meta: {
      day: {
        formatter: function formatter(val) {
          return <b>{val.slice(0, -5)}</b>;
        },
      },
      date: {
        formatter: function formatter(val) {
          return val;
        },
      },
    },
    yField: "value",
    seriesField: "type",
    colorField: "type",
    color: ["rgba(235, 87, 87, 1)", "rgb(62,175,232)", "rgb(62,232,113)"],
    legend: {
      layout: "horizontal",
      position: "bottom",
    },
    tooltip: {
      showTitle: true,
      title: "title",
    },
    label: {
      style: {
        fill: "black",
        // opacity: 0.6,
        // fontSize: 24
        fontWeight: "bold",
      },
      position: "middle",
      content: function content(item) {
        if (item.value > 0) return item.value;
      },
      //   layout: [{ type: "interval-adjust-position" }, { type: "interval-hide-overlap" }, { type: "adjust-color" }],
    },
  };

  return (
    <div className="retailerInsights__graph">
      <h3>
        <RangePicker onChange={handleGraphDate} value={date} picker={dateType} />
        <Select value={dateType} onChange={handleDateType}>
          <Option value={"day"}>
            <FormattedMessage id="page.home.insights.mailing.daily" defaultMessage="Daily" />
          </Option>
          <Option value={"week"}>
            <FormattedMessage id="page.home.insights.mailing.weekly" defaultMessage="Weekly" />
          </Option>
          <Option value={"month"}>
            <FormattedMessage id="page.home.insights.mailing.monthly" defaultMessage="Monthly" />
          </Option>
          <Option value={"year"}>
            <FormattedMessage id="page.home.insights.mailing.yearly" defaultMessage="Yearly" />
          </Option>
        </Select>
      </h3>
      <Column {...config} xField={"date"} />
    </div>
  );
}

function isSame(oldProps, newProps) {
  if (oldProps.data === newProps.data && oldProps.data[0]?.date === newProps.data[0]?.date) {
    return true;
  } else return false;
}

export default React.memo(RetailerGraph, isSame);
