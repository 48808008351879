import React from "react";
import { Modal, notification, Typography } from "antd";
import cn from "classnames";

import classNames from "classnames";
import socialLinks from "../Footer/socialLinks";
import MailIcon from "../../../../assets/newLanding/MailIcon";
import styles from "./getInTouchModal.module.less";
import MobileCopy from "../../../../assets/newLanding/MobileCopy";

const copyMail = () => {
  navigator.clipboard.writeText("contact@futeric.com");
  notification.success({
    message: "Copied to clipboard",
  });
};

const GetInTouchModal = ({ isVisible, onToggleModal, isMobile }) => (
  <Modal
    centered={!isMobile}
    width={640}
    transitionName="slideUp"
    wrapClassName={isMobile && styles.mobileModalWrapper}
    footer={
      <>
        <Typography.Paragraph className={styles.modalFooterTitle}>Check us out on</Typography.Paragraph>

        <div className={cn(styles.socialLinksWrap, { [styles.socialLinksWrapMobile]: isMobile })}>
          {socialLinks.map(({ id, link, isActive, icon: SocialIcon }) => (
            <a
              key={id}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className={cn(styles.socialLink, { [styles.socialLinkActive]: isActive })}
            >
              <SocialIcon />
            </a>
          ))}
        </div>
      </>
    }
    visible={isVisible}
    onCancel={onToggleModal}
    maskStyle={{ background: "rgba(22, 22, 22, 0.4)", backdropFilter: "blur(16px)" }}
    className={classNames(styles.modal, { [styles.mobileModal]: isMobile })}
  >
    <Typography.Title level={3} className={styles.modalTitle}>
      Get in Touch <br />
      <Typography.Text className={styles.modalSubTitle}>with Dliv</Typography.Text>
    </Typography.Title>

    <Typography.Paragraph className={styles.modalDescription}>
      Let's get this conversation started! <br /> Feel free to ask your question
    </Typography.Paragraph>

    <Typography.Paragraph className={styles.modalEmailLink}>
      <MailIcon />
      <a href="mailto:contact@futeric.com">contact@futeric.com</a>{" "}
      {isMobile && <MobileCopy style={{ marginLeft: 20 }} onClick={copyMail} />}
    </Typography.Paragraph>
  </Modal>
);

export default GetInTouchModal;
