import React from "react";
import { v4 as uuid } from "uuid";

export const MobileTableRows = ({ rows }) => (
  <div className="rows-wrapper">
    {rows
      .filter((row) => !!row)
      .map(({ label, value }) => (
        <div className="row" key={uuid()}>
          <div className="row__name">{label}</div>
          <div className="row__value">{value}</div>
        </div>
      ))}
  </div>
);
