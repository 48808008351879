import { List, Comment, Input, Button, Divider } from "antd";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { ICommentBoxProps } from "./type";
import "./comment-box.less";
import { SendOutlined } from "@ant-design/icons";
import { useCommentBox } from "./useCommentBox";
import moment from "moment";

const initialsUrl = "https://avatars.dicebear.com/api/initials";
const getEmoji = (name: string) => `${initialsUrl}/:${name}.svg`;

export const computeChatData = (messages: any, ticketUsers: any) => {
  return (
    messages &&
    [...messages].reverse().map((m: any) => {
      const authorRole = m?.author?.role;
      const authorName = m?.author?.name;
      const name = authorName ? authorName : ticketUsers[authorRole] || "unknown";

      return {
        author: `${name} - (${authorRole})`,
        avatar: getEmoji(`${name}${authorRole}`),
        content: <p>{m.message}</p>,
        datetime: moment(m.createdAt).format("MMM DD, HH:mm"),
      };
    })
  );
};

const CommentBox: FC<ICommentBoxProps> = ({ ticketId, users: ticketUsers, currentRole, sendMessage, getMessages }) => {
  const [textMessage, setTextMessage] = useState<string>("");
  const { messages, onSendClick, fetchMessages } = useCommentBox(sendMessage, getMessages);
  const commentContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchMessages(ticketId);
    setTextMessage("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId]);

  const handleSendClick = (ticketId: number, text: string) => {
    onSendClick(ticketId, text, currentRole);
    setTextMessage("");
    scrollToBottom();
  };

  const scrollToBottom = useCallback(() => {
    const lastItem = commentContainerRef.current?.getElementsByClassName(`comment-box-item-${messages.length - 1}`);
    if (lastItem && lastItem.length > 0) {
      lastItem[0].scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, commentContainerRef]);

  return (
    <>
      {ticketUsers && (
        <div ref={commentContainerRef} className="comment-container">
          <List
            data-testid="comment-box-list"
            className="comment-list"
            itemLayout="horizontal"
            dataSource={computeChatData(messages, ticketUsers)}
            renderItem={(item: any, index) => (
              <li data-testid="comment-box-item" className={`comment-box-item-${index}`}>
                <Comment
                  actions={item.actions}
                  author={item.author}
                  avatar={item.avatar}
                  content={item.content}
                  datetime={item.datetime}
                />
              </li>
            )}
          />
        </div>
      )}
      <Divider />
      <div className="chat-input-wrapper">
        <Input.TextArea
          data-testid="comment-box-input"
          value={textMessage}
          onChange={(e: any) => setTextMessage(e.target.value)}
        />
        <Button
          data-testid="comment-box-send-btn"
          className="send-btn"
          type="primary"
          disabled={!textMessage}
          onClick={() => handleSendClick(ticketId, textMessage)}
          icon={<SendOutlined />}
        >
          Send
        </Button>
      </div>
    </>
  );
};

export default CommentBox;
