import React from "react";
import { notification } from "antd";
import { useState, useEffect } from "react";
import { getUserTenant } from "src/helpers/authStorage";
import { registerPushServiceWorker } from "../../registerPushServiceWorker";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import { isLocalhost, isStage } from "src/constants/config";
// import errorLogger from "../../utils/errorLogging/error-logger";

const isLocal = isLocalhost || isStage;

interface IUseRetailerNotificationProps {
  subscribeFirebaseRetailer: any;
  unsubscribeFirebaseRetailer: any;
  testNotificationsRetailer: any;
}

interface IUseRetailerNotificationReturn {}

type IUseRetailerNotification = (props: IUseRetailerNotificationProps) => IUseRetailerNotificationReturn;

const firebaseConfig = isLocal
  ? {
      apiKey: "AIzaSyAG3rY29rd4-ySnHiruGEO8QC_AwgqW8fk",
      authDomain: "beebolt-staging.firebaseapp.com",
      projectId: "beebolt-staging",
      storageBucket: "beebolt-staging.appspot.com",
      messagingSenderId: "76644883938",
      appId: "1:76644883938:web:dab7e0a21be8aefde8edbb",
      measurementId: "G-NGL1H0M4VJ",
    }
  : {
      apiKey: "AIzaSyAAvjFzLEQ2IAiK1kJmzK5HEu78Bbr7meE",
      authDomain: "beebolt-280114.firebaseapp.com",
      databaseURL: "https://beebolt-280114.firebaseio.com",
      projectId: "beebolt-280114",
      storageBucket: "beebolt-280114.appspot.com",
      messagingSenderId: "27207299621",
      appId: "1:27207299621:web:a2015870dd7186a34b85cc",
      measurementId: "G-W1SJG8JRTT",
    };

export const useRetailerNotification: IUseRetailerNotification = ({
  subscribeFirebaseRetailer,
  unsubscribeFirebaseRetailer,
  testNotificationsRetailer,
}) => {
  const [token, setToken] = useState<string>();
  const bbIcon = "/mstile-144x144.png";

  useEffect(() => {
    async function handleMessages() {
      registerPushServiceWorker();
      const app = initializeApp(firebaseConfig);
      const isSupportedBrowser = await isSupported();
      if (isSupportedBrowser) {
        const messaging = getMessaging(app);

        onMessage(messaging, (payload) => {
          alert("hello");
          notification.open({
            message: payload?.notification?.title || "Notification",
            description: payload?.notification?.body || " ",
            icon: <img style={{ maxWidth: "30px" }} alt="icon" src={payload?.notification?.image || bbIcon} />,
          });
        });

        try {
          const messagingToken = await getToken(messaging, {
            vapidKey: isLocal
              ? "BCdtjs1WWI_vqTCbEY_M78qiZAL4m3BPPvmd0PrGvJUobUbYZqBjcbyvDQ6ttB_rxNIvhoL3cpv_FPfa7uqjxK8"
              : "BBbgsmE1no7lQ5B36d-6jaQnVwfTCJz5bZ7nDcg1zik54YLPucAJ5_EOrjJ0fXFhVi6t1fDMLCUEqB8qoX-0i9Y",
          });
          setToken(messagingToken);
          subscribeFirebaseRetailer({ token: messagingToken, tenant: getUserTenant() }).catch(() => {
            console.log("unable to subscribe Firebase");
          });
        } catch (e) {
          console.log("unable to subscribe Firebase");
        }
      }
    }
    handleMessages();
    // eslint-disable-next-line
  }, []);

  return { token };
};
