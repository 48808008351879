import { createResource } from "redux-rest-resource";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "retailerPickups",
  url: `${API_URL}/company/retailers/pickup-points`,
  actions: {
    fetch: {
      url: `${API_URL}/company/retailers/pickup-points/:retailerId`,

      transformResponse: ({ body }) => {
        return {
          items: body,
        };
      },
      reduce: (state, action) => {
        return {
          ...state,
          items: action.items,
        };
      },
    },

    getOnePickup: {
      method: "GET",
      url: `${API_URL}/company/retailers/pickup-points/:retailerId/:pickupPointId`,
    },
    addOnePickup: {
      method: "POST",
      url: `${API_URL}/company/retailers/pickup-points/:retailerId`,
    },
    updateOnePickup: {
      method: "PUT",
      url: `${API_URL}/company/retailers/pickup-points/:retailerId/:pickupPointId`,
      transformResponse: (res) => ({ ...res, body: res.body }),
    },
    deleteOnePickup: {
      method: "DELETE",
      url: `${API_URL}/company/retailers/pickup-points/:retailerId/:pickupPointId`,
      reduce: (state, action) => {
        const newItems = state.items.filter((pickup) => pickup.id != action.context.pickupPointId);
        return {
          ...state,
          items: newItems,
        };
      },
    },
  },
});
