import { notification } from "antd";
import { PointTypeEnum } from "src/entities";
import { getPointsByType, getPolyline } from "src/helpers/maps/maps.helpers";

const useDisplayRoutes = () => {
  const calculateAndDisplayRoute = async (items: any[], map: google.maps.Map, maps: typeof google.maps) => {
    let deliveryPoints: any[] = [];

    items?.forEach((job) => {
      let points = getPointsByType(job, PointTypeEnum.DELIVERY);
      deliveryPoints.push(...points);
    });

    const addMarker = (position: google.maps.LatLng, i: number) => {
      return new maps.Marker({
        icon: `http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${i}|FF0000|FFFFFF`,
        position,
        map,
      });
    };

    const directionsService = new maps.DirectionsService();
    const directionsDisplay = new maps.DirectionsRenderer({ suppressMarkers: true });

    directionsDisplay.setMap(map);
    const waypoints: google.maps.DirectionsWaypoint[] = deliveryPoints?.map((item) => {
      return {
        location: new google.maps.LatLng({ lat: item.lat, lng: item.long }),
        stopover: true,
      };
    });

    const polyline: string | undefined = getPolyline(items[0]);

    if (polyline !== undefined && maps.geometry) {
      // decode the path
      var decodedPath = maps.geometry.encoding.decodePath(polyline);
      const Path = new maps.Polyline({
        path: decodedPath,
        geodesic: true,
        strokeColor: "#2f84fa",
        strokeOpacity: 0.8,
        strokeWeight: 3,
      });

      Path.setMap(map);

      let markerCounter = 1;
      addMarker(waypoints[0].location as google.maps.LatLng, markerCounter++);
      for (let i = 1; i < waypoints.length; i++) {
        addMarker(waypoints[i].location as google.maps.LatLng, markerCounter++);
      }
    } else {
      // we run this instead polyline is not defined
      const origin = waypoints?.shift()?.location;
      const destination = waypoints?.pop()?.location;
      if (origin && destination) {
        directionsService.route(
          {
            origin,
            destination,
            waypoints,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (response, status) => {
            if (status === "OK" && response) {
              let markerCounter = 1;
              directionsDisplay.setDirections(response);
              let route = response.routes[0];
              addMarker(route.legs[0].start_location, markerCounter++);
              for (let i = 0; i < route.legs.length; i++) {
                addMarker(route.legs[i].end_location, markerCounter++);
              }
            } else {
              notification.error({ message: `Directions request failed due to ${status}` });
            }
          },
        );
      }
    }
  };

  return calculateAndDisplayRoute;
};

export default useDisplayRoutes;
