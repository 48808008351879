export interface AdditionalInfo {
  unit: string;
  remarks: string;
  shipperName: string;
  piecesNumber?: any;
  orderReference: string;
  additionalNotes: string;
}

export interface InitialAddress {
  zone: string;
  street: string;
  address: string;
  building: string;
  city: string;
}

export interface CustomerInfo {
  id: number;
  name: string;
  email?: any;
  initialAddress: InitialAddress;
  createdAt: Date;
  updatedAt: Date;
}

export interface Geom {
  type: string;
  coordinates: number[];
}
export interface Ticket {
  id: number;
  orderId: number;
  description?: any;
  labels: string[];
  status: string;
  isReadAdmin: boolean;
  isReadRetailer: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface Customer {
  id: number;
  phoneNumber: string;
  lat?: any;
  long?: any;
  geom: Geom;
  initialAddress?: any;
  createdAt: Date;
  updatedAt: Date;
}

export interface OrdersView {
  all: boolean;
  notes: boolean;
  ticket: boolean;
  tracking: boolean;
  phone: boolean;
  updatedAt: boolean;
  uploadedAt: boolean;
  postponedTo: boolean;
  retailer: boolean;
  zone: boolean;
  address: boolean;
  reports: boolean;
}

export interface TablesView {
  ordersView: OrdersView;
}

export interface Retailer {
  id: number;
  email: string;
  name: string;
  phoneNumber: string;
  officeAddress: string;
  supportNumber: string;
  status: string;
  isCustomMessagingTemplatesAllowed: boolean;
  restricted: boolean;
  smsProvider: string;
  tablesView: TablesView;
  createdAt: Date;
  updatedAt: Date;
  acceptedAt: Date;
}

export interface InitialAddress2 {
  zone: string;
  street: string;
  address: string;
  building: string;
  city: string;
}

export interface IDeliveryPoint extends ILocation {
  id: number;
  customerId: number;
  geom: Geom;
  instructions?: any;
  initialAddress: InitialAddress2;
  buildingUpdatedAt?: any;
}

export interface IPickupPoint extends ILocation {
  main: boolean;
  geom: Geom;
  instructions?: any;
  deletedAt?: any;
}

export interface ILocation {
  id: number;
  name: string;
  address: string;
  lat: string;
  long: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IWarehousePoint {
  address: string;
  createdAt: string;
  id: number;
  lat: string;
  long: string;
  name: string;
  updatedAt: string;
}

export interface IOrder {
  id: number;
  trackingId: string;
  messagingTemplateGroupId?: any;
  packagesCount: number;
  status: string;
  deliveryTime: Date;
  postponedTime: string;
  jobId: number;
  cashAmount: number;
  locationShared: boolean;
  warehouseDirection: string;
  isReturn: boolean;
  isStoreToStore: boolean;
  createdAt: Date;
  updatedAt: Date;
  realPickupTimeUTC?: any;
  realDeliveryTimeUTC?: any;
  manualCompleteDate?: any;
  notes: string;
  retailerNotes: string;
  additionalInfo: AdditionalInfo;
  deletedAt?: any;
  isPaid: boolean;
  paymentId?: any;
  warehousePointId?: any;
  customerInfo: CustomerInfo;
  payment?: any;
  pickupPoint: IPickupPoint;
  ticket: Ticket;
  customer: Customer;
  retailer: Retailer;
  deliveryPoint: IDeliveryPoint;
  pointOrder: number;
  mapIndex: string;
  customerName: string;
  trackingID: string;
  retailerName: string;
  warehousePoint?: ILocation;
}

export enum OrderStatusEnum {
  NEW = "new",
  POSTPONED = "postponed",
  PICKUP_COMPLETED = "pickup_completed",
  IN_TRANSIT = "in_transit",
  IN_DELIVERY = "in_delivery",
  ARRIVED = "arrived",
  COMPLETED = "completed",
  CANCELED = "canceled",
  CANCELED_AFTER_PICKUP = "canceled_after_pickup",
  POSTPONED_AFTER_PICKUP = "postponed_after_pickup",
  FAILED_TO_DELIVER = "failed_to_deliver",
  PICKUP_FAILED = "pickup_failed",
  RETURN_TO_SHIPPER = "return_to_shipper",
  DELETED = "deleted",
  COMPLETED_MANUALLY_RETURN = "completed_manually_return",
}
