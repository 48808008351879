import React from "react";
import { Modal } from "antd";

import styles from "./getInTouchModal.module.less";
import classNames from "classnames";

const MobileBottomToolbar = ({ isVisible, onToggleModal = () => {}, isMobile = true, footer = null, children }) => (
  <Modal
    centered={false}
    width={640}
    transitionName="slideUp"
    wrapClassName={isMobile && styles.mobileModalWrapper}
    footer={footer}
    visible={isVisible}
    onCancel={onToggleModal}
    maskStyle={{ background: "rgba(22, 22, 22, 0.4)", backdropFilter: "blur(16px)" }}
    className={classNames(styles.modal, { [styles.mobileModal]: isMobile })}
  >
    {children}
  </Modal>
);

export default MobileBottomToolbar;
