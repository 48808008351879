import React, { FC } from "react";
import { Button, Col, Row, Table, TablePaginationConfig } from "antd";
import type { ColumnsType } from "antd/es/table";
import { FormattedMessage, IntlShape } from "react-intl";
import { ActivityLogsDataModel, fromJSON } from "../entities/activity-logs.interface";
import { displayChanges, getDegreeIcon } from "../helper/helper";

const ActivityTable: FC<{
  intl: IntlShape;
  data: any[];
  pagination: TablePaginationConfig;
  onChangeQueryParams: (pagination: TablePaginationConfig, filters: any) => void;
  onOrderSelected: (id: number) => (event: any) => void;
}> = ({ intl, data, pagination, onChangeQueryParams, onOrderSelected }) => {
  // columns
  const columns: ColumnsType<ActivityLogsDataModel> = [
    {
      title: `${intl.formatMessage({ id: "page.activityLogs.table.date", defaultMessage: "Date" })}`,
      dataIndex: "date",
      key: "date",
      render: (_, record) =>
        `${intl.formatDate(record.date, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })} ${intl.formatTime(record.date, {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        })}`,
    },
    {
      title: `${intl.formatMessage({ id: "page.activityLogs.table.order", defaultMessage: "Order" })}`,

      dataIndex: "order",
      key: "order",
      render: (_, record) => (
        <Col>
          <Button style={{ fontWeight: "bold" }} onClick={onOrderSelected(record.order)} type="text">
            {record.order}
          </Button>
        </Col>
      ),
    },

    {
      title: `${intl.formatMessage({ id: "page.activityLogs.table.changes", defaultMessage: "Changes" })}`,

      dataIndex: "changes",
      key: "changes",
      width: "40%",

      render: (_, record) => (
        <Row className="changes_wrapper">
          {getDegreeIcon(record.changes)} {displayChanges(record.changes)}
        </Row>
      ),
    },
    {
      title: `${intl.formatMessage({ id: "page.activityLogs.table.user", defaultMessage: "User" })}`,

      dataIndex: "user",
      key: "user",
      filters: [
        {
          text: <span>Admin</span>,
          value: "admin",
        },
        {
          text: <span>Retailer</span>,
          value: "retailer",
        },
        {
          text: <span>Driver</span>,
          value: "driver",
        },
      ],
      onFilter: (value: string | any, record) => record.user.role.startsWith(value),

      render: (_, record) => (
        <Col>
          <p style={{ fontWeight: "bold", marginBottom: "2px" }}>{record.user.name}</p>
          <p style={{ marginBottom: 0, color: "rgb(183 182 182)", fontSize: "small" }}>{record.user.role}</p>
        </Col>
      ),
    },

    {
      title: `${intl.formatMessage({ id: "page.activityLogs.table.actions", defaultMessage: "Actions" })}`,
      key: "action",
      render: (_, record) => (
        <Row style={{ justifyContent: "space-around" }}>
          <Button size="small" type="dashed" onClick={onOrderSelected(record.order)}>
            <FormattedMessage id="page.activityLogs.table.fullHistory" defaultMessage="Show Order history" />
          </Button>
        </Row>
      ),
    },
  ];

  // data source
  const dataSource: ActivityLogsDataModel[] = [];
  data.forEach((el) => {
    dataSource.push(fromJSON(el));
  });

  //
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      className="logs-table"
      size="small"
      pagination={pagination?.total ? { ...pagination } : {}}
      onChange={onChangeQueryParams}
    />
  );
};

export default ActivityTable;
