import React, { useState, useEffect, useCallback } from "react";
import { map, uniqueId } from "lodash";
import { useIntl } from "react-intl";
import { message, notification } from "antd";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Geocode from "react-geocode";

import { API_KEY, API_URL } from "../../../../constants/config";

import {
  CAN_BE_ASSIGNED_TAB_QUERY,
  CURRENT_ORDERS_TAB_QUERY,
  POSTPONED_TAB_QUERY,
  COMPLETED_TAB_QUERY,
  CANCELED_TAB_QUERY,
} from "../../../../constants/query";
import { getAuthData, getUserTenant } from "../../../../helpers/authStorage";

Geocode.setApiKey(API_KEY);

export const useHooks = ({
  adminOrders,
  fetchAllOrders,
  paginationConfig,
  setSelectedOrderTrackingId,
  createReturnAdminOrder,
  completeOrderAdminOrder,
  removeLocationAdminOrder,
  saveAdminOrders,
  postponeAdminOrder,
  cancelAdminOrder,
  deleteAdminOrder,
  deleteAdminOrders,
  cancelCompletedAdminOrder,
}) => {
  const [downloadedOrders, setDownloadedOrders] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [orderSearchQuery, setOrderSearchQuery] = useState("orderId");
  const [searchedValue, setSearchedValue] = useState(undefined);
  const [isReportsModalOpen, setIsReportsModalOpen] = useState(false);
  const [openModal, setOpenModal] = useState({ id: "", type: "", open: false });
  const [phoneModalVisible, setPhoneModalVisible] = useState(null);
  const [selectedSharedLocation, setSelectedSharedLocation] = useState(null);
  const [completedOrdersFilter, setCompletedOrdersFilter] = useState("all");
  const [postponedOrdersFilter, setPostponedOrdersFilter] = useState("all");
  const [canceledOrdersFilter, setCanceledOrdersFilter] = useState("all");
  const [canEditModal, setCanEditModal] = useState(false);
  const intl = useIntl();

  const fetchAccordingToTab = useCallback(() => {
    switch (selectedTab) {
      case 1:
        fetchAllOrders({ status: CURRENT_ORDERS_TAB_QUERY });
        break;
      case 2:
        break;
      case 3:
        switch (postponedOrdersFilter) {
          case "postponed":
            fetchAllOrders({ fetchAll: true, status: postponedOrdersFilter });
            break;
          case "postponed_after_pickup":
            fetchAllOrders({ fetchAll: true, status: postponedOrdersFilter });
            break;
          default:
            fetchAllOrders({ fetchAll: true, status: POSTPONED_TAB_QUERY });
            break;
        }
        break;
      case 4:
        fetchAllOrders({ fetchAll: true, status: CURRENT_ORDERS_TAB_QUERY });
        break;
      case 5:
        switch (completedOrdersFilter) {
          case "completed":
            fetchAllOrders({ fetchAll: true, status: completedOrdersFilter, isReturn: false });
            break;
          case "completed_manually_return":
            fetchAllOrders({ fetchAll: true, status: completedOrdersFilter });
            break;
          case "return_completed":
            fetchAllOrders({ fetchAll: true, status: "completed", isReturn: true });
            break;
          default:
            fetchAllOrders({ fetchAll: true, status: COMPLETED_TAB_QUERY });
            break;
        }
        break;
      case 6:
        fetchAllOrders({ fetchAll: true, withoutJobs: true, status: CAN_BE_ASSIGNED_TAB_QUERY });
        break;
      case 7:
        switch (canceledOrdersFilter) {
          case "canceled":
            fetchAllOrders({ fetchAll: true, status: canceledOrdersFilter });
            break;
          case "canceled_after_pickup":
            fetchAllOrders({ fetchAll: true, status: canceledOrdersFilter });
            break;
          default:
            fetchAllOrders({ fetchAll: true, status: CANCELED_TAB_QUERY });
            break;
        }
        break;
      case 8:
        fetchAllOrders({ fetchAll: true, status: "pickup_failed" });
        break;
      default:
        break;
    }
  }, [selectedTab, fetchAllOrders, completedOrdersFilter, canceledOrdersFilter, postponedOrdersFilter]);

  useEffect(() => {
    fetchAccordingToTab();
  }, [fetchAccordingToTab]);

  useEffect(() => {
    if (Number(selectedTab) === 2) {
      paginationConfig.total = downloadedOrders.length;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const draggerProps = {
    name: "orders",
    multiple: false,
    method: "post",
    headers: {
      Authorization: `Bearer ${getAuthData().accessToken}`,
      Tenant: getUserTenant(),
    },
    action: `${API_URL}/admin/orders/upload`,
    showUploadList: false,
    beforeUpload(file, filelist) {
      console.log("beforeUpload", file);
    },
    onChange(info) {
      const { status } = info.file;
      // eslint-disable-next-line no-empty
      if (status !== "uploading") {
      }
      if (status === "done") {
        const downloadedOrders = info.file.response.length;
        setDownloadedOrders(map(info.file.response, (el) => ({ ...el, id: uniqueId() })));
        setSelectedTab(2);
        message.success(
          `${downloadedOrders} ${intl.formatMessage({
            id: "page.ordersManagment.ordersUploaded",
            defaultMessage: "orders uploaded successfully.",
          })}`,
        );
      } else if (status === "error") {
        if (info.file?.response) {
          message.error(
            <>
              {info.file.response.map((err) => {
                if (err?.errors?.length > 0) {
                  return (
                    <div>
                      {err.errors.map((error) => {
                        return (
                          <p className="errorMessageLine">
                            {`${intl.formatMessage({
                              id: "row",
                              defaultMessage: "Row",
                            })} ${err.row}. ${intl.formatMessage({
                              id: "wrong",
                              defaultMessage: "Wrong",
                            })} ${error.property}.`}
                          </p>
                        );
                      })}
                    </div>
                  );
                }
                return (
                  <p className="errorMessageLine">
                    {`${intl.formatMessage({
                      id: "row",
                      defaultMessage: "Row",
                    })} ${err.row}. ${intl.formatMessage({
                      id: "wrong",
                      defaultMessage: "Wrong",
                    })} ${err.errors[0].property}.`}
                  </p>
                );
              })}
            </>,
          );
        }
      }
    },
  };

  const getMapData = (query, type) => {
    switch (type) {
      case "address":
        return geocodeByAddress(query.address).then(
          (result) => {
            return getLatLng(result[0]).then(
              (latLng) => {
                return {
                  address: query.address,
                  lat: latLng.lat,
                  long: latLng.lng,
                  formatted_address: result[0].formatted_address,
                };
              },
              (error) => {
                notification.error({
                  message: "Error",
                  description: "no coords match this address",
                });
                return null;
              },
            );
          },
          (error) => {
            notification.error({
              message: "Error",
              description: "no such address",
            });
            return null;
          },
        );
      case "coords":
        return Geocode.fromLatLng(query.lat, query.long).then(
          (response) => {
            return { address: response.results[0].formatted_address, lat: query.lat, long: query.long };
          },
          (error) => {
            return { address: "unknown place", lat: query.lat, long: query.long };
          },
        );
      default:
        return null;
    }
  };

  const deleteLocal =
    (...ids) =>
    () => {
      setDownloadedOrders((prevState) => {
        const orders = [...prevState];
        return orders.filter((el) => !ids.includes(el.id) && el);
      });
    };

  const onOpenReportModal = () => setIsReportsModalOpen(true);

  const onOpenSharedLocation =
    (order, completed = false) =>
    () => {
      if (completed) {
        setCanEditModal(false);
      } else {
        setCanEditModal(true);
      }
      setSelectedSharedLocation(order);
    };

  const onCloseSharedLocation = () => {
    setSelectedSharedLocation(null);
  };

  const onCloseOrderDetails = () => {
    setSelectedOrderTrackingId("");
  };

  const onCloseReportModal = () => {
    setIsReportsModalOpen(false);
  };

  const handleModalSubmit =
    (type, id, openForAll, old, { postponeDate }) =>
    async () => {
      if (type === "cancel") {
        try {
          await cancelAdminOrder({ orderId: id });
          await fetchAccordingToTab();
        } catch (error) {
          notification.error({
            message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
            description:
              error?.body?.message ||
              `${intl.formatMessage({ id: "error.cancelOrder", defaultMessage: "Failed to cancel order." })}`,
          });
        }
      } else if (type === "postpone") {
        try {
          await postponeAdminOrder({ orderId: id, postponedTime: postponeDate.format("DD.MM.YYYY") });
          await fetchAccordingToTab();
        } catch (error) {
          notification.error({
            message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
            description:
              error?.body?.message ||
              `${intl.formatMessage({ id: "error.postponeOrder", defaultMessage: "Failed to postpone order." })}`,
          });
        }
      } else if (type === "delete") {
        try {
          const res = await deleteAdminOrder({ orderId: id });
          if (res.status === "resolved") {
            notification.success({
              message: `${intl.formatMessage({
                id: "success.deletedOrder",
                defaultMessage: "Order deleted successfully",
              })}`,
            });
          } else {
            notification.error({
              message: `${intl.formatMessage({ id: "error.deletionOrder", defaultMessage: "Order deletion failed" })}`,
            });
          }
          await fetchAccordingToTab();
        } catch (error) {
          notification.error({
            message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
            description:
              error?.body?.message ||
              `${intl.formatMessage({ id: "error.deleteOrder", defaultMessage: "Failed to delete order." })}`,
          });
        }
      } else if (type === "deleteMany") {
        if (old && !downloadedOrders?.length) {
          try {
            await deleteAdminOrders({ ordersIds: selectedOrders }, {});
            await fetchAccordingToTab();
            setSelectedOrders([]);
          } catch (error) {
            notification.error({
              message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
              description:
                error?.body?.message ||
                `${intl.formatMessage({ id: "error.deleteOrders", defaultMessage: "Failed to delete orders." })}`,
            });
          }
        } else {
          deleteLocal(...selectedOrders)();
          setSelectedOrders([]);
        }
      } else if (type === "saveOrders") {
        saveAdminOrders(adminOrders);
        setSelectedTab(1);
        setDownloadedOrders([]);
        notification.success({
          message: `${intl.formatMessage({
            id: "success.uploadedOrders",
            defaultMessage:
              "Your orders have been uploaded successfully to Dliv, you can track the status of your order on the current tab of this page",
          })}`,
        });
      } else if (type === "return") {
        try {
          const query = typeof openForAll === "boolean" ? { openForAll } : {};
          await createReturnAdminOrder({ orderId: id }, { query });
          await fetchAccordingToTab();
          notification.success({
            message: `${intl.formatMessage({
              id: "success.returnCreation",
              defaultMessage: "Return successfully created!",
            })}`,
          });
        } catch (error) {
          notification.error({
            message: "Error",
            description:
              error?.body?.message ||
              `${intl.formatMessage({ id: "error.returnCreation", defaultMessage: "Failed to create return." })}`,
          });
        }
      } else if (type === "removeLocation") {
        try {
          await removeLocationAdminOrder({ orderId: id });
          await fetchAccordingToTab();
          notification.success({
            message: `${intl.formatMessage({
              id: "success.removeLocation",
              defaultMessage: "Location removed successfully!",
            })}`,
          });
        } catch (error) {
          notification.error({
            message: "Error",
            description:
              error?.body?.message ||
              `${intl.formatMessage({ id: "error.removeLocation", defaultMessage: "Failed to remove location." })}`,
          });
        }
      } else if (type === "completeOrder") {
        try {
          await completeOrderAdminOrder({ orderId: id });
          await fetchAccordingToTab();
          notification.success({
            message: `${intl.formatMessage({
              id: "success.completeOrder",
              defaultMessage: "Order completed successfully!",
            })}`,
          });
        } catch (error) {
          notification.error({
            message: "Error",
            description:
              error?.body?.message ||
              `${intl.formatMessage({ id: "error.completeOrder", defaultMessage: "Failed to complete order." })}`,
          });
        }
      } else if (type === "cancelCompleted") {
        try {
          await cancelCompletedAdminOrder({ orderId: id });
          await fetchAccordingToTab();
          notification.success({
            message: `${intl.formatMessage({
              id: "success.cancelOrder",
              defaultMessage: "Order was canceled successfully!",
            })}`,
          });
        } catch (error) {
          notification.error({
            message: "Error",
            description:
              error?.body?.message ||
              `${intl.formatMessage({ id: "error.cancelOrder", defaultMessage: "Failed to cancel this order." })}`,
          });
        }
      }
      setOpenModal((prevState) => ({
        ...prevState,
        open: false,
      }));
      setTimeout(() => {
        setOpenModal((prevState) => ({
          ...prevState,
          id: "",
          type: "",
        }));
      }, 500);
    };

  const onOpenPhoneModal =
    ({ orderId, phone }) =>
    () =>
      setPhoneModalVisible({ orderId, phone });
  const onClosePhoneModal = () => setPhoneModalVisible(null);

  return {
    getMapData,
    draggerProps,
    selectedTab,
    selectedOrders,
    downloadedOrders,
    orderSearchQuery,
    searchedValue,
    setSelectedSharedLocation,
    selectedSharedLocation,
    openModal,
    isReportsModalOpen,
    phoneModalVisible,
    fetchAccordingToTab,
    setSelectedTab,
    setDownloadedOrders,
    setOpenModal,
    setOrderSearchQuery,
    setSearchedValue,
    setSelectedOrders,
    handleModalSubmit,
    onOpenReportModal,
    onOpenSharedLocation,
    onOpenPhoneModal,
    onCloseSharedLocation,
    onCloseOrderDetails,
    onCloseReportModal,
    onClosePhoneModal,
    deleteLocal,
    completedOrdersFilter,
    setCompletedOrdersFilter,
    setCanceledOrdersFilter,
    setPostponedOrdersFilter,
    canEditModal,
  };
};
