import { FileUnknownOutlined } from "@ant-design/icons";
import React from "react";

export default (intl, openInstructionsModal) => {
  return [
    {
      title: `${intl.formatMessage({
        id: "id",
        defaultMessage: "ID",
      })}`,
      key: "id",
      render: ({ id }) => {
        if (id) {
          return (
            id || (
              <b className="pointTableEmptyCell">
                {`${intl.formatMessage({
                  id: "no",
                  defaultMessage: "No",
                })}`}{" "}
                {`${intl.formatMessage({
                  id: "id",
                  defaultMessage: "ID",
                })}`}
              </b>
            )
          );
        }
      },
    },
    {
      title: `${intl.formatMessage({
        id: "name",
        defaultMessage: "Name",
      })}`,
      key: "name",
      render: ({ name }) => {
        return (
          name || (
            <b className="pointTableEmptyCell">
              {`${intl.formatMessage({
                id: "no",
                defaultMessage: "No",
              })}`}{" "}
              {`${intl.formatMessage({
                id: "name",
                defaultMessage: "Name",
              })}`}
            </b>
          )
        );
      },
      width: "150px",
      ellipsis: true,
    },
    {
      title: `${intl.formatMessage({
        id: "address",
        defaultMessage: "Address",
      })}`,
      key: "address",
      render: ({ address }) => {
        return (
          address || (
            <b className="pointTableEmptyCell">
              {`${intl.formatMessage({
                id: "no",
                defaultMessage: "No",
              })}`}{" "}
              {`${intl.formatMessage({
                id: "address",
                defaultMessage: "Address",
              })}`}
            </b>
          )
        );
      },
    },
    {
      title: "Lat",
      key: "lat",
      render: ({ lat }) => {
        return Math.floor(lat) || <b className="pointTableEmptyCell">No lat</b>;
      },
    },
    {
      title: "Long",
      key: "long",
      render: ({ long }) => {
        return Math.floor(long) || <b className="pointTableEmptyCell">No long</b>;
      },
    },
    {
      title: "Instructions",
      key: "instructions",
      render: (location) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FileUnknownOutlined onClick={openInstructionsModal(location)} />
          </div>
        );
      },
    },
  ];
};
