import React from "react";
import { Popover } from "antd";
import { ShopFilled } from "@ant-design/icons";

const PickUpPoint = ({ orderInfo, retailer }) => {
  const pickupPoint = orderInfo && (
    <div className="customerInfo">
      <p>
        Name: <span>{retailer.name}</span>
      </p>
      <p>
        Instructions: <span>{orderInfo.instructions}</span>
      </p>
      {retailer.officeAddress && (
        <p>
          Address: <span>{retailer.officeAddress}</span>
        </p>
      )}
    </div>
  );

  return (
    <Popover content={pickupPoint} title="PickUpPoint" trigger="click">
      <div style={{ width: "25px", height: "25px", cursor: "pointer" }}>
        <ShopFilled style={{ color: "green", fontSize: "18px" }} />
      </div>
    </Popover>
  );
};

export default PickUpPoint;
