import React, { useEffect, useState } from "react";
import { notification, Button, Spin } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import { useIntl } from "react-intl";
import { actions as retailerOrdersActions } from "../../redux/resources/retailerOrders";
import { actions as retailerInsightsActions } from "../../redux/resources/retailerInsights";
import { actions as retailerActions } from "../../redux/resources/retailer";

import "./retailerInsights.less";
import RetailerGraph from "./components/Graph";
import RetailerTotal from "./components/RetailerTotal";
import RetailerEmails from "./components/RetailerEmails";
import EmailDrawer from "./components/Drawer";
import { API_URL } from "../../constants/config";
import { getAuthData, getUserTenant } from "../../helpers/authStorage";
import RetailerDashboardWidget from "../RetailerCabinet/components/RetailerDashboardWidget";
import { customFetch } from "../../utils/customFetch";
import { CustomCard } from "../../utils/common";
import RetailerReportsModal from "./components/Reports/ReportsModal";

const RetailerInsights = ({
  history,
  location,
  fetchGraphRetailerInsights,
  fetchTotalRetailerInsights,
  fetchEmailsRetailerInsights,
  sendEmailsRetailerInsights,
  changeEmailRetailerInsights,
  deleteEmailRetailerInsights,
  fetchExtendedStatsRetailerOrder,
  retailer,
  getSelfInfoRetailer,
}) => {
  const [graph, setGraph] = useState([]);
  const [total, setTotal] = useState(0);
  const [emails, setEmails] = useState([]);
  const [graphDate, setGraphDate] = useState([moment().startOf("year"), moment().endOf("year")]);
  const [date, setDate] = useState();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [dateType, setDateType] = useState("month");
  const [datePickerDL, setDatePickerDL] = useState("month");
  const [stats, setStats] = useState({});
  const [dlDatepickerControl, setDlDatepickerControl] = useState(false);
  const [isReportsModalOpen, setisReportsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState("all");
  const [rangeDateType, setRangeDateType] = useState("created_at");
  const intl = useIntl();

  useEffect(() => {
    getSelfInfoRetailer();
  }, [getSelfInfoRetailer]);

  useEffect(() => {
    fetchTotalRetailerInsights().then((res) => setTotal(res.body.total));
    fetchEmailsRetailerInsights().then((res) => setEmails(res.body));
  }, [fetchTotalRetailerInsights, fetchEmailsRetailerInsights]);

  useEffect(() => {
    if (graphDate) {
      fetchExtendedStatsRetailerOrder(
        {},
        {
          query: {
            from: graphDate[0].toISOString(true).substring(0, 23),
            to: graphDate[1].toISOString(true).substring(0, 23)
          }
        },
      ).then(setStats);
      handleFetchGraph();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphDate, fetchExtendedStatsRetailerOrder])

  const handleFetchGraph = () => {
    const newDate = [...graphDate];
    switch (dateType) {
      case "year":
        newDate[0] = newDate[0].startOf("year");
        newDate[1] = newDate[1].endOf("year");
        break;
      case "month":
        newDate[0] = newDate[0].startOf("month");
        newDate[1] = newDate[1].endOf("month");
        break;
      // case "week":
      //   newDate[0] = newDate[0].startOf("week");
      //   newDate[1] = newDate[1].endOf("week");
      //   break;
      case "day":
        newDate[0] = newDate[0].startOf("day");
        newDate[1] = newDate[1].endOf("day");
        break;
      default:
        break;
    }

    fetchGraphRetailerInsights(
      {},
      {
        query: {
          groupBy: dateType,
          from: newDate[0].toISOString(true).substring(0, 23),
          to: newDate[1].toISOString(true).substring(0, 23),
        },
      },
    ).then((res) => {
      setGraph(res.body.graph);
    });
  };

  const handleGraphDate = (date, dateString) => {
    setGraphDate(date);
  };

  const handleDateType = (newDateType) => {
    setDateType(newDateType);
    const newDate = [...graphDate];

    switch (newDateType) {
      case "year":
        newDate[0] = moment().startOf("year");
        newDate[1] = moment().endOf("year");
        break;
      case "month":
        newDate[0] = moment().startOf("year");
        newDate[1] = moment().endOf("year");
        break;
      case "week":
        newDate[0] = moment().startOf("month");
        newDate[1] = moment().endOf("month");
        break;
      case "day":
        newDate[0] = moment().startOf("week");
        newDate[1] = moment().endOf("week");
        break;
      default:
        break;
    }

    fetchExtendedStatsRetailerOrder(
      {},
      {
        query: {
          from: newDate[0].toISOString(true).substring(0, 23),
          to: newDate[1].toISOString(true).substring(0, 23)
        }
      },
    ).then(setStats);
    handleFetchGraph();
  }

  const handleSendEmails = (created = false) => {
    const array = [...emails];
    if (created) {
      array.push(created);
    }
    sendEmailsRetailerInsights(array).then(
      () => {
        fetchEmailsRetailerInsights().then((res) => setEmails(res.body));
      },
      (err) => {
        notification.error({
          message: "Error",
          description: err?.message || "Bad Input",
        });
        fetchEmailsRetailerInsights().then((res) => setEmails(res.body));
      },
    );
  };

  const handleDeleteEmail = (id, setDisabled) => (event) => {
    setDisabled(true);
    deleteEmailRetailerInsights(id).then(
      () => {
        fetchEmailsRetailerInsights().then((res) => setEmails(res.body));
      },
      (err) => {
        setDisabled(false);
        notification.error({
          message: "Error",
          description: err?.message || "Cannot delete!",
        });
        fetchEmailsRetailerInsights().then((res) => setEmails(res.body));
      },
    );
  };
  const handleCreateEmail = (newEmail) => {
    if (/\S+@\S+\.\S+/.test(newEmail?.email)) {
      handleSendEmails(newEmail);
    } else {
      notification.error({
        message: "Error",
        description: `${intl.formatMessage({ id: "error.invalidEmail", defaultMessage: "Mail is not valid!" })}`,
      });
    }
  };

  const handleFetchReport = async (date = moment()) => {
    setIsLoading(true);
    try {
      let statusQuery = "";
      if (selectedStatus && selectedStatus !== "all") {
        statusQuery = `&orderStatus=${selectedStatus}`;
      }

      let mainQuery = new URLSearchParams({
        from: date.startOf(datePickerDL).toISOString(true).substring(0, 23),
        to: date.endOf(datePickerDL).toISOString(true).substring(0, 23),
        range: rangeDateType,
      });
      const response = await customFetch(
        `${API_URL}/retailers/self/dashboard/report/generate?${mainQuery}${statusQuery}`,
        {
          method: "GET",
          cache: "no-cache",
          headers: {
            Authorization: `Bearer ${getAuthData().accessToken}`,
            Tenant: getUserTenant(),
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
        },
      );

      if (!response.ok) {
        const data = await response.json();
        return notification.error({
          message: "Error",
          description: data?.message || "Error while generating report",
        });
      }
      const blob = await response.blob();
      const newBlob = new Blob([blob]);

      const blobUrl = window.URL.createObjectURL(newBlob);
      let fileName = "report.xlsx";
      let disposition = response.headers.get("Content-Disposition");
      if (disposition && disposition.indexOf("attachment") !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, "");
        }
      }
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      window.URL.revokeObjectURL(blob);
    } catch (error) {
      try {
        const data = await error.json();
        notification.error({
          message: "Error",
          description: data?.message || "Error while generating report",
        });
      } catch {
        notification.error({
          message: "Error",
          description: "Unknown Error while generating report",
        });
      }
    } finally {
      setIsLoading(false);
      setisReportsModalOpen(false);
    }
  };
  const handleChangeReportDate = (date) => {
    setDate(date?.utc());
    setDlDatepickerControl(false);
  };
  return (
    <CustomCard
      title={`${retailer?.retailerData?.name && retailer?.retailerData?.name.concat("'s ")} ${intl.formatMessage({
        id: "page.home.insights.title",
        defaultMessage: "Retailer Insights",
      })}`}
      toolbar={
        <Button className="retailerInsights__Layout__button" onClick={() => setisReportsModalOpen(true)}>
          {`${intl.formatMessage({ id: "download.reports", defaultMessage: "Generate Reports" })}`}
        </Button>
      }
    >
      <div className="retailerInsights__Layout">
        <RetailerGraph
          intl={intl}
          data={graph}
          handleGraphDate={handleGraphDate}
          date={graphDate}
          dateType={dateType}
          handleDateType={handleDateType}
        />
        <RetailerTotal intl={intl} data={total} />
        {emails ? (
          <RetailerEmails
            intl={intl}
            data={emails}
            setData={setEmails}
            sendEmails={handleSendEmails}
            setDrawerVisible={setDrawerVisible}
          />
        ) : (
          <Spin />
        )}
        <EmailDrawer
          intl={intl}
          data={emails}
          setData={setEmails}
          drawerVisible={drawerVisible}
          setDrawerVisible={setDrawerVisible}
          sendEmails={handleSendEmails}
          handleDelete={handleDeleteEmail}
          createEmail={handleCreateEmail}
        />
      </div>
      <RetailerDashboardWidget noHeader intl={intl} stats={stats} isExtended isInsights />

      <RetailerReportsModal
        isOpen={isReportsModalOpen}
        onClose={() => setisReportsModalOpen(false)}
        date={date}
        onCloseModal={() => setisReportsModalOpen(false)}
        isLoading={isLoading}
        handleFetchReport={handleFetchReport}
        setRangeDateType={setRangeDateType}
        rangeDateType={rangeDateType}
        dlDatepickerControl={dlDatepickerControl}
        handleChangeReportDate={handleChangeReportDate}
        datePickerDL={datePickerDL}
        setDlDatepickerControl={setDlDatepickerControl}
        setDatePickerDL={setDatePickerDL}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
      />
    </CustomCard>
  );
};

const MSTP = (state) => {
  return {
    retailer: state.retailer,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(retailerOrdersActions, dispatch),
  ...bindActionCreators(retailerInsightsActions, dispatch),
  ...bindActionCreators(retailerActions, dispatch),
}))(RetailerInsights);
