import React, { useEffect } from "react";
import { Button, Modal, Form, Select } from "antd";
import { FormattedMessage } from "react-intl";
import {
  others,
  optionsCompletedOrders,
  optionsCanceledOrders,
  optionsFailedOrders,
  optionsPostponedOrders,
  optionsCanBeAssigned,
} from "../constants/helper";
const { Option, OptGroup } = Select;
function FiltersModal({
  isOpen,
  onCloseModal,
  onStatusChoice,
  tablesChoice,
  selectedTables,
  filterListOrders,
  handleSelectedTables,
  statusOrdersFilter,
}) {
  const [form] = Form.useForm();

  const onCategoryChosen = (key) => {
    handleSelectedTables(key);
  };

  useEffect(() => {
    form.setFieldsValue({ status: statusOrdersFilter });
  }, [statusOrdersFilter, form]);

  useEffect(() => {
    form.setFieldsValue({ categorie: selectedTables });
  }, [selectedTables, form]);

  const getChoices = (group) => {
    const choices = [];
    group.forEach((op) => {
      choices.push(
        <Option value={op.value} key={op.value}>
          {op.label}
        </Option>,
      );
    });

    return choices;
  };

  const handleCloseModal = () => {
    filterListOrders();
    onCloseModal();
  };
  return (
    <Modal
      visible={isOpen}
      onCancel={onCloseModal}
      title={<FormattedMessage id="page.ordersManagment.filters" defaultMessage="Filters" />}
      footer={[
        <Button key="Cancel" onClick={onCloseModal} loading={false}>
          <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
        </Button>,
        <Button key="submit" type="primary" loading={false} onClick={handleCloseModal}>
          <FormattedMessage id="tabPane.actions.apply" defaultMessage="Apply filters" />
        </Button>,
      ]}
    >
      <Form form={form} initialValues={{ status: statusOrdersFilter }}>
        <Form.Item
          name="categorie"
          label={<FormattedMessage id="modal.filters.views" defaultMessage="Predefined views" />}
          rules={[{ required: true }]}
        >
          <Select
            //mode="multiple"
            allowClear
            placeholder="Select please .."
            onChange={onCategoryChosen}
            // value={selectedTables}
            className="tableSelector"
          >
            {tablesChoice}
          </Select>
        </Form.Item>
        <Form.Item
          name="status"
          label={<FormattedMessage id="modal.filters.status" defaultMessage="Orders Status" />}
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select a status"
            mode="multiple"
            allowClear
            onChange={(value) => {
              onStatusChoice(value);
            }}
          >
            {getChoices([...others, ...optionsCanBeAssigned])}

            <OptGroup label="Completed Orders" key="completed">
              {getChoices(optionsCompletedOrders)}
            </OptGroup>
            <OptGroup label="Postponed Orders" key="postponed">
              {getChoices(optionsPostponedOrders)}
            </OptGroup>
            <OptGroup label="Canceled Orders" key="canceled">
              {getChoices(optionsCanceledOrders)}
            </OptGroup>
            <OptGroup label="Failed Orders" key="failed">
              {getChoices(optionsFailedOrders)}
            </OptGroup>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default FiltersModal;
