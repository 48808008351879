export const allowedMoveToJobStatus = ["new", "ready", "incomplete"];
export enum JobStatus {
  NEW = "new",
  COMPLETE = "completed",
  IN_TRANSIT = "in_transit",
}

export enum OrderStatus {
  NEW = "new",
  POSTPONED = "postponed",
  POSTPONED_AFTER_PICKUP = "postponed_after_pickup",
  PICKED = "pickup_completed",
  IN_TRANSIT = "in_transit",
  DELIVERY = "in_delivery",
  ARRIVED = "arrived",
  COMPLETED = "completed",
  CANCELED = "canceled",
  CANCELED_AFTER_PICKUP = "canceled_after_pickup",
  FAILED_DELIVERY = "failed_to_deliver",
  FAILED_PICKUP = "pickup_failed",
  RETURNED = "return_to_shipper",
  DELETED = "deleted",
  COMPLETE_RETURN = "completed_manually_return",
}

export enum TagState {
  SUCCESS = "green",
  ONGOING = "orange",
  BASIC = "blue",
  FAILED = "red",
  DEFAULT = "gray",
}

export enum PaymentStatus {
  OPEN = "open",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}
