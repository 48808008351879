import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Radio, DatePicker, Input, Row } from "antd";
import moment from "moment";
import { CheckCircleTwoTone } from "@ant-design/icons";

import { MobileModalWrapper } from "../../../../../components/AdminPanel/Mobile/MobileModalWrapper";

import "./styles.less";

export const ConfirmModal = ({ orders, openModal, modalTitle, old, handleModalSubmit, setOpenModal }) => {
  const [openForAll, setOpenForAll] = useState(false);
  const [postponeDate, setPostponeDate] = useState(moment());
  const [orderIdConfirm, setorderIdConfirm] = useState();
  const isCancelGranted = () => {
    return orderIdConfirm && orderIdConfirm === openModal?.id;
  };
  const intl = useIntl();

  const handleOpenForAllChange = (e) => {
    setOpenForAll(e.target.value);
  };

  const postponeDisabledDate = (postponeDate) => {
    return postponeDate && postponeDate < moment().startOf("day");
  };

  const onClose = () => {
    setOpenModal((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const isConfirmDisabled = () => {
    if (openModal?.type === "postpone" && !postponeDate) return true;
    if (openModal?.type === "cancelCompleted" && !isCancelGranted()) return true;
    return false;
  };

  const renderModalContent = () => {
    const wasRestricted = orders?.find(({ id }) => id === Number(openModal?.id))?.retailer?.restricted || false;

    if (openModal?.type === "return" && wasRestricted) {
      return (
        <div className="open-for-all">
          <Radio.Group onChange={handleOpenForAllChange} value={openForAll}>
            <Radio value>
              <FormattedMessage id="page.ordersManagment.openForDrivers" defaultMessage="Open for all drivers" />
            </Radio>
            <Radio value={false}>
              <FormattedMessage
                id="page.ordersManagment.onlyForRetailers"
                defaultMessage="Only for retailer's drivers"
              />
            </Radio>
          </Radio.Group>
        </div>
      );
    }

    if (openModal?.type === "postpone") {
      return (
        <>
          <label> Postponed delivery date </label>
          <DatePicker
            value={postponeDate}
            disabledDate={postponeDisabledDate}
            allowClear={false}
            onChange={(date, dateString) => {
              setPostponeDate(date);
            }}
            format={"DD.MM.YYYY"}
          />
        </>
      );
    } else if (openModal?.type === "cancelCompleted") {
      return (
        <>
          <Row justify="space-between">
            <label>Please confirm the order ID</label>

            <Input
              style={{ width: "30%", borderColor: isCancelGranted() ? "#27ae60" : "" }}
              value={orderIdConfirm}
              status={!isCancelGranted() ? "warning" : ""}
              onChange={(e) => setorderIdConfirm(e.target.value)}
              suffix={isCancelGranted() ? <CheckCircleTwoTone twoToneColor="#27ae60" /> : null}
            />
          </Row>
          <br />
        </>
      );
    }
  };

  return (
    <MobileModalWrapper
      visible={openModal?.open}
      title={
        <>
          {modalTitle[openModal?.type]} {intl.formatMessage({ id: "order", defaultMessage: "order" })}
        </>
      }
      onClose={onClose}
      afterClose={() => {
        setOpenModal(() => ({
          open: false,
          id: "",
          type: "",
        }));
      }}
      footer={null}
    >
      <div className="mobile-confirm-modal-body">
        <div className="mobile-confirm-modal-body__title">
          {intl.formatMessage(
            {
              id: "modal.areYouSureWantTo",
              defaultMessage: "Are you sure you want to",
            },
            {
              operation: modalTitle[openModal?.type],
              concern: `${intl.formatMessage({ id: "order", defaultMessage: "order" })}`,
            },
          )}
        </div>
        {renderModalContent()}
        <Button
          onClick={handleModalSubmit(openModal?.type, openModal?.id, openForAll, old, {
            postponeDate: postponeDate,
          })}
          className="mobile-btn mobile-btn--yellow"
          disabled={isConfirmDisabled()}
        >
          <FormattedMessage id="tabPane.actions.confirm" defaultMessage="Confirm" />
        </Button>
        <Button onClick={onClose} className="mobile-btn mobile-btn--white">
          <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
        </Button>
      </div>
    </MobileModalWrapper>
  );
};
