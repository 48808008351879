import React, { useEffect } from "react";
import { Form, Input, DatePicker, Select } from "antd";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";

import StepActions from "../../../../components/DataEntry/StepActions";
import { getDataLocalStorageAndSetData } from "../../../../helpers/general";

import "./style.less";

const allCarsModels = require("car-models");

const YourCarForm = ({ onNextStep, currentStep, onPrevStep, stepsLength, putSelfCarDriver, driverInfo, carInfo }) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const allCars = allCarsModels.all();

  const typeOfCar = [
    { label: `${intl.formatMessage({ id: "carModel.SUV", defaultMessage: "SUV" })}`, value: "SUV" },
    { label: `${intl.formatMessage({ id: "carModel.Truck", defaultMessage: "Truck" })}`, value: "Truck" },
    { label: `${intl.formatMessage({ id: "carModel.Van", defaultMessage: "Van" })}`, value: "Van" },
    { label: `${intl.formatMessage({ id: "carModel.Coupe", defaultMessage: "Coupe" })}`, value: "Coupe" },
    { label: `${intl.formatMessage({ id: "carModel.Wagon", defaultMessage: "Wagon" })}`, value: "Wagon" },
    {
      label: `${intl.formatMessage({ id: "carModel.Convertible", defaultMessage: "Convertible" })}`,
      value: "Convertible",
    },
    { label: `${intl.formatMessage({ id: "carModel.Sports", defaultMessage: "Sports Car" })}`, value: "Sports Car" },
    { label: `${intl.formatMessage({ id: "carModel.Crossover", defaultMessage: "Crossover" })}`, value: "Crossover" },
    {
      label: `${intl.formatMessage({ id: "carModel.Hybrid", defaultMessage: "Hybrid/Electric" })}`,
      value: "Hybrid/Electric",
    },
  ];

  useEffect(() => {
    localStorage.setItem("DriverRegistrationStep", "2");

    const driverCarInfo = JSON.parse(localStorage.getItem("driverCarInfo"));
    getDataLocalStorageAndSetData(driverCarInfo, "model", form);
    getDataLocalStorageAndSetData(driverCarInfo, "otherModel", form);
    getDataLocalStorageAndSetData(driverCarInfo, "type", form);
    getDataLocalStorageAndSetData(driverCarInfo, "manufacturedAt", form);
    getDataLocalStorageAndSetData(driverCarInfo, "plateNumber", form);
    getDataLocalStorageAndSetData(driverCarInfo, "color", form);
    getDataLocalStorageAndSetData(driverCarInfo, "firstIssueDate", form);
    getDataLocalStorageAndSetData(driverCarInfo, "type", form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIfExistAndThenSet = (name) => {
    if (name === "model" && !allCars.includes(carInfo.model)) {
      form.setFieldsValue({
        model: "other",
        otherModel: carInfo.model,
      });
    } else if (!form.getFieldsValue(name)[name]) {
      form.setFieldsValue({
        [name]: carInfo[name] ?? "",
      });
    } else if (name === "firstIssueDate") {
      form.setFieldsValue({
        firstIssueDate: moment(carInfo.firstIssueDate) ?? moment("1990-01-01"),
      });
    }
  };
  useEffect(() => {
    if (carInfo) {
      Object.entries(carInfo).forEach(([name]) => {
        checkIfExistAndThenSet(name);
      });
    }
    // eslint-disable-next-line
  }, [carInfo]);

  const onFinish = (values) => {
    const manufacturedAt = moment(values.manufacturedAt).format("YYYY");
    const formData = { ...values };
    if (values.model === "other") {
      formData.model = values.otherModel;
    }
    delete formData.otherModel;
    putSelfCarDriver({ ...formData, manufacturedAt: +manufacturedAt });
  };

  const dateFormat = "YYYY/MM/DD";
  const dateFormatManufectered = "YYYY";

  const manufacturedAtDisabledDate = (current) => {
    const currentYear = new Date().getFullYear();
    const tooLate = moment(current).format("YYYY") > currentYear;
    return tooLate;
  };

  const obtainedLicenseDisabledDate = (current) => {
    return current > new Date();
  };

  return (
    <>
      <h3 className="driverInfoTitle">
        <FormattedMessage id="page.step.carInfo.title" defaultMessage="Document Upload" />
      </h3>
      <p className="driverInfoDescription">
        <FormattedMessage
          id="page.step.carInfo.subtitle"
          defaultMessage="Please make sure the information below matches the car registration documents."
        />
      </p>
      <Form
        autocomplete="off"
        onValuesChange={(changedValues, allValues) => {
          localStorage.setItem("driverCarInfo", JSON.stringify(allValues));
        }}
        form={form}
        name="your_car"
        layout="vertical"
        onFinish={onFinish}
        hideRequiredMark
        scrollToFirstError
        className="formStyle"
      >
        <Form.Item
          className="personalInfoItem"
          name="model"
          key="model"
          label={`${intl.formatMessage({ id: "input.model", defaultMessage: "Model" })}`}
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({ id: "error.selectModel", defaultMessage: "Select car model!" })}`,
            },
          ]}
          initialValue={driverInfo ? driverInfo.model : ""}
        >
          <Select showSearch placeholder={`${intl.formatMessage({ id: "select", defaultMessage: "Select" })}`}>
            {allCars.map((car) => (
              <Select.Option key={car} value={car}>
                <span style={{ fontWeight: "normal" }}>{car}</span>
              </Select.Option>
            ))}
            <Select.Option key="other" value="other">
              <FormattedMessage id="otherModel" defaultMessage="Other model.." />
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.model !== currentValues.model}>
          {({ getFieldValue }) =>
            getFieldValue("model") === "other" || driverInfo.model === "other" ? (
              <Form.Item
                name="otherModel"
                key="otherModel"
                label={`${intl.formatMessage({ id: "input.otherCarModel", defaultMessage: "Other car model" })}`}
                className="personalInfoItem"
                rules={[
                  {
                    required: true,
                  },
                ]}
                initialValue={driverInfo ? driverInfo.model : ""}
              >
                <Input />
              </Form.Item>
            ) : (
              <Form.Item
                name="otherModel"
                key="otherModel"
                label={`${intl.formatMessage({ id: "input.otherCarModel", defaultMessage: "Other car model" })}`}
                className="personalInfoItem"
              >
                <Input disabled />
              </Form.Item>
            )
          }
        </Form.Item>
        <Form.Item
          className="personalInfoItem"
          name="type"
          key="type"
          label={`${intl.formatMessage({ id: "input.type", defaultMessage: "Type" })}`}
          rules={[{ required: true, message: `${intl.formatMessage({ id: "input.type", defaultMessage: "Type" })}` }]}
          initialValue={driverInfo ? driverInfo.type : ""}
        >
          <Select showSearch placeholder={`${intl.formatMessage({ id: "select", defaultMessage: "Select" })}`}>
            {typeOfCar.map(({ value }) => {
              return (
                <Select.Option value={value} key={value}>
                  <span style={{ fontWeight: "normal" }}>{value}</span>
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          className="personalInfoItem"
          key="manufacturedAt"
          name="manufacturedAt"
          label={`${intl.formatMessage({ id: "input.manufacturedAt", defaultMessage: "Manufactured at" })}`}
          format={dateFormatManufectered}
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({
                id: "error.selectManufacturedAt",
                defaultMessage: "Select manufactured at!",
              })}`,
            },
          ]}
          initialValue={
            driverInfo && driverInfo.manufacturedAt
              ? moment(driverInfo.manufacturedAt, dateFormatManufectered)
              : moment("2018", dateFormatManufectered)
          }
        >
          <DatePicker className="birthDatePicker" picker="year" disabledDate={manufacturedAtDisabledDate} />
        </Form.Item>
        <Form.Item
          className="personalInfoItem"
          key="plateNumber"
          name="plateNumber"
          label={`${intl.formatMessage({ id: "input.plateNumber", defaultMessage: "Car plate number" })}`}
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({ id: "error.inputPlateNumber", defaultMessage: "Input plate number!" })}`,
            },
          ]}
          initialValue={driverInfo ? driverInfo.plateNumber : ""}
        >
          <Input placeholder={`${intl.formatMessage({ id: "plateNumber", defaultMessage: "Plate number" })}`} />
        </Form.Item>
        <Form.Item
          className="personalInfoItem"
          key="color"
          name="color"
          label={`${intl.formatMessage({ id: "input.color", defaultMessage: "Color" })}`}
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({ id: "error.inputColor", defaultMessage: "Input car color!" })}`,
            },
          ]}
          initialValue={driverInfo ? driverInfo.color : ""}
        >
          <Input placeholder={`${intl.formatMessage({ id: "carColor", defaultMessage: "Car color" })}`} />
        </Form.Item>
        <Form.Item
          className="personalInfoItem"
          name="firstIssueDate"
          key="firstIssueDate"
          format={dateFormat}
          label={`${intl.formatMessage({
            id: "input.firstIssueDate",
            defaultMessage: "Date of first issue of driving licence",
          })}`}
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({
                id: "error.selectFirstIssueDate",
                defaultMessage: "Select date of first issue of driving licence!",
              })}`,
            },
          ]}
          initialValue={driverInfo ? moment(driverInfo.firstIssueDate) : ""}
        >
          <DatePicker className="birthDatePicker" disabledDate={obtainedLicenseDisabledDate} />
        </Form.Item>
        <StepActions currentStep={currentStep} onPrevStep={onPrevStep} stepsLength={stepsLength} />
      </Form>
    </>
  );
};

export default YourCarForm;
