import React from "react";

const MobileClose = (props) => (
  <svg {...props} width="24" height="26" viewBox="0 0 26 26" fill="current" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.3333 5L5 21.3333"
      stroke="#E9E9E9"
      strokeWidth="2.72222"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 5L21.3333 21.3333"
      stroke="#E9E9E9"
      strokeWidth="2.72222"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MobileClose;
