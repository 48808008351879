import React from "react";
import { Button, Form, Input } from "antd";

const InputGroup = ({ intl, createUser }) => {
  return (
    <Form className="input-group" onFinish={createUser}>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: `${intl.formatMessage({ id: "error.required", defaultMessage: "Field is required!" })}`,
          },
          {
            type: "email",
            message: `${intl.formatMessage({ id: "error.invalidEmail", defaultMessage: "Email is not valid!" })}`,
          },
        ]}
      >
        <Input placeholder={`${intl.formatMessage({ id: "email", defaultMessage: "Email" })}`} />
      </Form.Item>
      <Form.Item name="name" rules={[{ required: true, message: "This field is required!" }]}>
        <Input placeholder={`${intl.formatMessage({ id: "name", defaultMessage: "Name" })}`} />
      </Form.Item>
      <Form.Item>
        <Button className="input-group__submit" htmlType="submit">
          {`${intl.formatMessage({ id: "tabPane.actions.send", defaultMessage: "Send" })}`}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default InputGroup;
