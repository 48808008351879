import React, { useState, useEffect, memo } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Typography, Button, Layout } from "antd";
import { FormattedMessage } from "react-intl";

import { ForgotPasswordForm, RecoveryPasswordForm } from "../../components";
import { SelectLanguage } from "../../../../components/SelectLanguage";
import { getQueryParam } from "../../../../helpers/general";
import MainLogo from "../../../../assets/newLanding/MainLogo";
import styles from "./forgotPassword.module.less";
import MainLogoMobile from "../../../../assets/newLanding/MainLogoMobile";
import ImageTablet from "../../../../assets/newLanding/auth/recoveryPassword.png";

const ForgotPassword = ({ location, isMobile }) => {
  const [confirmationToken, setConfirmationToken] = useState(false);

  useEffect(() => {
    const queryParams = getQueryParam(location.search);

    queryParams.confirmationToken
      ? setConfirmationToken(decodeURIComponent(queryParams.confirmationToken))
      : setConfirmationToken(false);
  }, [location.search]);

  return isMobile ? (
    <Layout className={styles.forgotPasswordWrap}>
      <Row style={{ flex: "auto" }}>
        <Col span={24} className={styles.mainSection}>
          <Row align="middle" justify="space-between">
            <Col span={6} offset={2}>
              <Link to="/">
                <MainLogoMobile />
              </Link>
            </Col>
            <Col span={16}>
              <Row align="top" justify="end">
                <Col span={8}>
                  <SelectLanguage />{" "}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row align="middle" style={{ flex: "auto" }}>
            <Col
              span={20}
              offset={2}
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                paddingTop: 60,
                paddingBottom: 35,
                // paddingLeft: "2%",
              }}
            >
              {!confirmationToken ? (
                <ForgotPasswordForm isMobile />
              ) : (
                <RecoveryPasswordForm isMobile confirmationToken={confirmationToken} />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  ) : (
    <Layout className={styles.forgotPasswordWrap}>
      <Row style={{ height: "100%" }}>
        <Col span={12} md={24} lg={12} className={styles.promoSection}>
          <Row>
            <Col span={23} offset={1}>
              <Link to="/">
                <MainLogo />
                <SelectLanguage />
              </Link>
            </Col>

            <Col span={21} md={24}>
              <Typography.Title level={2} className={styles.promoSectionTitle}>
                <FormattedMessage id="page.login.password" defaultMessage="Password" /> <br />
                <Typography.Text className={styles.promoSectionSubtitle}>
                  <FormattedMessage id="page.login.recover" defaultMessage="Recover" />
                </Typography.Text>
              </Typography.Title>
              <div className={styles.tablet_img}>
                <img src={ImageTablet} style={{ width: 278, height: 250, zIndex: 1 }} alt="imageForTablet" />
              </div>
            </Col>
          </Row>
        </Col>

        <Col span={12} md={24} lg={12} className={styles.mainSection}>
          <Row>
            <Col span={3} offset={17}>
              <SelectLanguage />
            </Col>

            <Col span={3}>
              <Button size="large" type="link" className={styles.signUpButton}>
                <Link to="/retailer_signup">
                  <FormattedMessage id="page.login.signup" defaultMessage="Sign Up" />
                </Link>
              </Button>
            </Col>
          </Row>

          <Row justify="center" style={{ height: "100%" }}>
            <Col span={12} offset={6}>
              <div style={{ marginTop: 200, transform: "translate(-12%, -50%)" }}>
                {!confirmationToken ? (
                  <ForgotPasswordForm />
                ) : (
                  <RecoveryPasswordForm confirmationToken={confirmationToken} />
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default memo(ForgotPassword);
