import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "driversReports",

  actions: {
    getAll: {
      url: `${API_URL}/admin/drivers/trouble-reports?page=:page&limit=:limit`,
      transformResponse: ({ body }) => ({
        items: body.items,
        pagination: body.meta,
      }),
      reduce: (state, action) => ({
        ...state,
        items: action.items,
        pagination: action.pagination,
      }),
    },

    deleteOne: {
      method: "DELETE",
      url: `${API_URL}/admin/drivers/trouble-reports/:reportId`,
      reduce: (state, action) => {
        if (action.status === "resolved") {
          const updateItems = () => {
            const idx = state.items.findIndex((item) => item.id === action.context.reportId);
            return [...state.items.slice(0, idx), ...state.items.slice(idx + 1)];
          };
          return {
            ...state,
            items: updateItems(),
          };
        }
        return {
          ...state,
        };
      },
    },
  },
});

setAuthHeader(getAuthData().accessToken);
