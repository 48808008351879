import { createResource, defaultHeaders } from "redux-rest-resource";
import { getAuthData, setAuthHeader } from "../../helpers/authStorage";
import { API_URL } from "../../constants/config";

const updateReduce = (state, { context, status }) => {
  if (state?.item && status === "resolved") {
    return {
      ...state,
      item: {
        ...state.item,
        items: state.item.items.map((item) =>
          item.ticketId === context.ticketId ? { ...item, status: context.status } : item,
        ),
      },
    };
  } else return { ...state };
};

const updateTicketStateReduce = (state, { context, status }) => {
  if (state?.item && status === "resolved") {
    return {
      ...state,
      item: {
        ...state.item,
        items: state.item.items.map((item) =>
          item.ticketId === context.ticketId ? { ...item, ...context.ticket } : item,
        ),
      },
    };
  } else return { ...state };
};

export const { types, actions, rootReducer } = createResource({
  name: "adminTicket",
  actions: {
    get: {
      method: "GET",
      url: `${API_URL}/admin/ticket/tickets`,
    },
    create: {
      method: "POST",
      url: `${API_URL}/admin/ticket`,
    },

    update: {
      method: "PUT",
      url: `${API_URL}/admin/ticket`,
      reduce: updateReduce,
    },
    sendMessage: {
      method: "POST",
      url: `${API_URL}/admin/ticket/message`,
    },

    getMessages: {
      method: "GET",
      url: `${API_URL}/admin/ticket/messages`,
    },

    // pure actions
    updateTicketState: {
      isPure: true,
      reduce: updateTicketStateReduce,
    },
  },
});

setAuthHeader(getAuthData().accessToken, defaultHeaders);
