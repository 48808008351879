import { createResource, defaultHeaders } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";
import { makeDefaultRequest } from "../../helpers/requestComponents";

export const { types, actions, rootReducer } = createResource({
  name: "envStats",

  actions: {
    loadOrders: makeDefaultRequest("GET", `${API_URL}/admin/stats/orders`, "envOrders"),
    loadOrdersStats: makeDefaultRequest("GET", `${API_URL}/admin/stats/orders-stats`, "envOrdersStats"),
    loadJobs: makeDefaultRequest("GET", `${API_URL}/admin/stats/jobs`, "envJobs"),
    loadJobsStats: makeDefaultRequest("GET", `${API_URL}/admin/stats/jobs-stats`, "envJobsStats"),
    loadRetailers: makeDefaultRequest("GET", `${API_URL}/admin/stats/retailers-list`, "retailersList"),
    loadRetailersStats: makeDefaultRequest("GET", `${API_URL}/admin/stats/retailers`, "envRetailers"),
    loadTenants: makeDefaultRequest("GET", `${API_URL}/tenants`, 'envTenants')
  },
});

setAuthHeader(getAuthData().accessToken, defaultHeaders);
