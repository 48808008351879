import React, { FC, useEffect, useState } from "react";
import { Select, Table, TableProps } from "antd";
import type { ColumnsType } from "antd/es/table/interface";
import moment from "moment";
import { CustomCard } from "src/utils/common/customCard/customCard";
import RetailerSelect from "../../Select";
import Search from "antd/lib/input/Search";
import { IOrder } from "src/entities";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState } from "src/redux";
import { actions as jobsMapActions } from "../../../redux/resources/jobsMap";
import { actions as adminRetailerActions } from "../../../redux/resources/adminRetailers";
import { IAvailableOrdersTableProps } from "../../../types/CloudFleetRouting";
import useAvailableOrders from "./useAvailableOrders";
import "./available-orders-table.less";
import { isRetailer } from "src/helpers/authStorage";
import CFRWidget from "../CFRWidget";

const { Option } = Select;

export const extractRetailers = (dataSource: IOrder[]) =>
  [...new Set<string>(dataSource.map(({ retailerName }) => retailerName!))].map((el) => {
    return { id: el, text: el, value: el };
  });

const generateTableData = (orders: IOrder[]) =>
  orders.map((el: IOrder) => ({
    ...el,
    key: el.id,
    address: el.deliveryPoint?.address ?? el.address?.address,
    uploadedAt: moment(el.createdAt).format("DD.MM.YYYY HH:mm"),
  }));

const AvailableOrdersTable: FC<any> = ({
  retailers,
  orders,
  getAvailableOrdersJobsMap,
  fetchRestrictedListAdminRetailers,
  clearCFRStateJobsMap,
}) => {
  const intl = useIntl();
  const [selectedRetailer, setSelectedRetailer] = useState<string | undefined>();
  const [selectedOrders, setSelectedOrders] = useState<React.Key[]>([]);
  const [filteredRetailer, setFilteredRetailer] = useState<any>({});
  const [searchedAddress, setSearchedAddress] = useState<string>("");
  const [tableData, setTableData] = useState<any>([]);
  const [retailerNameFilter, setRetailerNameFilter] = useState<any>();

  const { isOrdersLoading, fetchAvailabeOrders, fetchRestrictedRetailer, clearCFRState } = useAvailableOrders({
    orders,
    getAvailableOrdersJobsMap,
    fetchRestrictedListAdminRetailers,
    clearCFRStateJobsMap,
  });

  useEffect(() => {
    if (!isRetailer) {
      fetchRestrictedRetailer();
    }
  }, []);

  useEffect(() => {
    setSelectedOrders([]);
    fetchAvailabeOrders({ searchedAddress, selectedRetailer });
  }, [selectedRetailer, searchedAddress]);

  useEffect(() => {
    if (orders) {
      const data = generateTableData(orders);
      setTableData(data);
      setRetailerNameFilter(extractRetailers(data));
    }
  }, [orders]);

  const onOrderSelection = (selectedRowKeys: React.Key[], selectedRows: IOrder[]): void => {
    setSelectedOrders(selectedRowKeys);
  };

  const onTableChange: TableProps<IOrder>["onChange"] = (pagination, filters): void => {
    setFilteredRetailer(filters);
  };

  const onReset = (refreshOrders: boolean): void => {
    clearCFRState();
    setSelectedOrders([]);
    setSelectedRetailer(undefined);

    if (refreshOrders) {
      fetchAvailabeOrders({ searchedAddress, selectedRetailer });
    }
  };
  // columns
  const columns: ColumnsType<IOrder> = [
    {
      title: `${intl.formatMessage({ id: "page.activityLogs.table.order", defaultMessage: "Order" })}`,
      dataIndex: "id",
      key: "id",
      width: "10%",
      render: (_, record) => <p style={{ fontWeight: "700" }}>{record.id}</p>,
    },
    {
      title: `${intl.formatMessage({ id: "page.cfr.table.driverStatus", defaultMessage: "Status" })}`,
      dataIndex: "status",
      key: "status",
    },
    {
      title: `${intl.formatMessage({ id: "page.cfr.table.package", defaultMessage: "Packages" })}`,
      dataIndex: "packagesCount",
      key: "packagesCount",
    },
    {
      ...(!isRetailer && {
        title: `${intl.formatMessage({ id: "page.cfr.table.retailer", defaultMessage: "Retailer" })}`,
        dataIndex: "retailerName",
        key: "retailerName",
        filters: retailerNameFilter,
        filteredValue: filteredRetailer.retailerName || null,
        onFilter: (value: string | number | boolean, record) =>
          record.retailerName?.startsWith(value.toString()) ?? false,
        filterSearch: true,
      }),
    },
    {
      title: `${intl.formatMessage({ id: "page.cfr.table.address", defaultMessage: "Address" })}`,
      dataIndex: "address",
      key: "address",
    },
    {
      title: `${intl.formatMessage({ id: "page.cfr.table.uploadedAt", defaultMessage: "uploaded at" })}`,
      dataIndex: "uploadedAt",
      key: "uploadedAt",
    },
    {
      title: `${intl.formatMessage({ id: "page.cfr.table.deliveryTime", defaultMessage: "Delivery time" })}`,
      dataIndex: "deliveryTime",
      key: "deliveryTime",
    },
  ];

  return (
    <>
      <CustomCard>
        <div className="cfr-actions-container">
          <div>
            <Search
              onSearch={(value: string) => {
                setSearchedAddress(value);
              }}
              allowClear
              enterButton="Search"
              placeholder="Address search"
              data-testid="cfr-orders-search-box"
            />
          </div>
          <div className="cfr-actions-right">
            {!isRetailer && (
              <div className="cfr-retailer-select">
                <label>Restricted Retailers</label>
                <RetailerSelect
                  mapFunction={(obj: any) => (
                    <Option value={obj.type} key={obj.type}>
                      {obj.name}
                    </Option>
                  )}
                  style={{}}
                  onChange={setSelectedRetailer}
                  defaultLabel="General orders"
                  defaultValue={null}
                  list={retailers}
                  value={selectedRetailer}
                  className="retailer-select"
                />
              </div>
            )}

            <CFRWidget selectedRetailer={selectedRetailer} selectedOrders={selectedOrders} onReset={onReset} />
          </div>
        </div>

        <div className="cfr-order-table">
          <Table
            data-testid="cfr-orders-table"
            loading={isOrdersLoading}
            columns={columns}
            dataSource={tableData}
            size="small"
            // pagination={{}}
            rowSelection={{
              selectedRowKeys: selectedOrders,
              onChange: onOrderSelection,
            }}
            onChange={onTableChange}
          />
        </div>
      </CustomCard>
    </>
  );
};

const MSTP = (state: RootState, props: IAvailableOrdersTableProps) => {
  return {
    orders: state.jobsMap.orders,
    retailers: state.adminRetailers.restrictedList,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(jobsMapActions, dispatch),
  ...bindActionCreators(adminRetailerActions, dispatch),
}))(AvailableOrdersTable);
