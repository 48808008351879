import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "antd";
import { actions as adminUserListActions } from "../../redux/resources/adminUserList";
import DesktopView from "./DesktopView/index";
import MobileView from "./MobileView/index";
import CreateUser from "./components/CreateUser";
import ROLES from "src/constants/roles";
import { UserManagementProps } from "./types";
import { RootState } from "src/redux";
import { useUserActions } from "./hooks/useUserActions";

const UserManagement : React.FC<UserManagementProps> =  ({ 
  isMobile, 
  state, 
  getAdminUserList, 
  createOneAdminUserList, 
  updateOneAdminUserList, 
  deleteOneAdminUserList }) => {

  const {
    usersPage,
    totalPages,
    userList,
    tenants,
    searchedValue,
    searchFilter,
    createUserModalVisible,
    handleFetchUsers,
    handleFetchTenants,
    handleDeleteUser,
    handleChangeRole,
    handleAdminInvite,
    onOpenCreateUserModal,
    setSearchFilter,
    setSearchedValue,
    handleSearchKeyPress,
    onCloseCreateUserModal
  } = useUserActions({
    getAdminUserList,
    createOneAdminUserList,
    updateOneAdminUserList,
    deleteOneAdminUserList
  });

  useEffect(() => {
    handleFetchUsers();
    handleFetchTenants();
    //eslint-disable-next-line
  }, []);

  const modalPadding = isMobile ? 25 : 50;

  return (
    <>
      {isMobile ? (
        <MobileView
          handleFetchUsers={handleFetchUsers}
          handleDeleteUser={handleDeleteUser}
          handleChangeRole={handleChangeRole}
          usersPage={usersPage}
          totalPages={totalPages}
          userList={userList}
          tenants={tenants}
          onOpenCreateUserModal={onOpenCreateUserModal}
          searchedValue={searchedValue}
          setSearchedValue={setSearchedValue}
          handleSearchKeyPress={handleSearchKeyPress}
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
        />
      ) : (
        <DesktopView
          handleFetchUsers={handleFetchUsers}
          handleChangeRole={handleChangeRole}
          handleDeleteUser={handleDeleteUser}
          totalPages={totalPages}
          userList={userList}
          tenants={tenants}
          onOpenCreateUserModal={onOpenCreateUserModal}
          searchedValue={searchedValue}
          setSearchedValue={setSearchedValue}
          handleSearchKeyPress={handleSearchKeyPress}
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
        />
      )}
      <Modal
        visible={createUserModalVisible}
        onCancel={onCloseCreateUserModal}
        title="Add New User"
        footer={false}
        bodyStyle={{ paddingLeft: modalPadding, paddingRight: modalPadding }}
        {...(isMobile ? { width: 350 } : null)}
      >
        <CreateUser
          tenants={tenants}
          handleAdminInvite={handleAdminInvite}
          roles={Object.keys(ROLES)}
        />
      </Modal>
    </>
  );
}
const MSTP = (state: RootState) => ({
  isMobile: state.admin.isMobile,
});

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(adminUserListActions, dispatch),
}))(UserManagement);
