import React from "react";
import { Provider } from "react-redux";
import { defaultGlobals as reduxRestResourceGlobals } from "redux-rest-resource";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { GOOGLE_AUTH_CLIENT_ID } from "./constants/config";
import { customFetch } from "./utils/customFetch";
import Routes from "./routes";
import configureStore from "./redux/store";
import { BreadcrumbProvider } from "./layouts/BreadCrumpManager/BreadCrumpProvider";


Object.assign(reduxRestResourceGlobals, { customFetch });

function App() {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
      <Provider store={configureStore()}>
        <BreadcrumbProvider>
          <Routes />
        </BreadcrumbProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
