import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Typography } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import GetStartedButton from "../GetStartedButton";
import { ReactComponent as EmailIcon } from "../../../../assets/newLanding/auth/emailIcon.svg";
import { ReactComponent as PasswordIcon } from "../../../../assets/newLanding/auth/passwordIcon.svg";
import styles from "./signInForm.module.less";

const SignInForm = ({ onSubmit, recoverPasswordPath, isMobile }) => {
  const [form] = Form.useForm();

  const intl = useIntl();

  useEffect(() => {
    form.isFieldsTouched() && form.validateFields();
    // eslint-disable-next-line
  }, [intl.locale]);

  return (
    <>
      <Typography.Title level={2} className={styles.signInTitle}>
        <FormattedMessage id="page.login.login" defaultMessage="Log In" /> <br />
        <Typography.Text className={styles.signInSubtitle}>
          <FormattedMessage id="page.login.gladToSee" defaultMessage="Glad to see you..." />
        </Typography.Text>
      </Typography.Title>

      <Form
        scrollToFirstError
        form={form}
        name="login"
        onFinish={onSubmit}
        className={styles.signInForm}
        style={
          isMobile ? { flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" } : {}
        }
      >
        {isMobile ? (
          <div>
            <Form.Item
              name="email"
              className={styles.formItem}
              rules={[
                {
                  type: "email",
                  message: `${intl.formatMessage({ id: "error.invalidEmail", defaultMessage: "Email is not valid!" })}`,
                },
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: "error.inputEmail",
                    defaultMessage: "Please input your email!",
                  })}`,
                },
              ]}
            >
              <Input
                prefix={<EmailIcon />}
                placeholder={`${intl.formatMessage({ id: "input.email1", defaultMessage: "Email" })}`}
                className={styles.formInput}
              />
            </Form.Item>
            <Form.Item
              name="password"
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: "error.invalidPasword",
                    defaultMessage: "Please input your password!",
                  })}`,
                },
              ]}
            >
              <Input.Password
                prefix={<PasswordIcon />}
                placeholder={`${intl.formatMessage({ id: "input.password", defaultMessage: "Password" })}`}
                className={styles.formInput}
              />
            </Form.Item>
            <div className={styles.signInForgotPass}>
              <Link to={recoverPasswordPath} style={isMobile ? { borderBottom: "1px dashed black" } : {}}>
                <FormattedMessage id="page.login.forgotPassword" defaultMessage="Forgot Password?" />
              </Link>
            </div>
          </div>
        ) : (
          <>
            {" "}
            <Form.Item
              name="email"
              className={styles.formItem}
              rules={[
                {
                  type: "email",
                  message: `${intl.formatMessage({ id: "error.invalidEmail", defaultMessage: "Email is not valid!" })}`,
                },
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: "error.inputEmail",
                    defaultMessage: "Please input your email!",
                  })}`,
                },
              ]}
            >
              <Input
                prefix={<EmailIcon />}
                placeholder={`${intl.formatMessage({ id: "input.email1", defaultMessage: "Email" })}`}
                className={styles.formInput}
              />
            </Form.Item>
            <Form.Item
              name="password"
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: "error.invalidPasword",
                    defaultMessage: "Please input your password!",
                  })}`,
                },
              ]}
            >
              <Input.Password
                prefix={<PasswordIcon />}
                placeholder={`${intl.formatMessage({ id: "input.password", defaultMessage: "Password" })}`}
                className={styles.formInput}
              />
            </Form.Item>
            <div className={styles.signInForgotPass}>
              <Link
                to={recoverPasswordPath}
                style={isMobile ? { textDecoration: "underline", textDecorationStyle: "dashed" } : {}}
              >
                <FormattedMessage id="page.login.forgotPassword" defaultMessage="Forgot Password?" />
              </Link>
            </div>
          </>
        )}

        <Form.Item noStyle>
          <GetStartedButton htmlType="submit" className={styles.signInButton} style={isMobile ? { width: "100%" } : {}}>
            <FormattedMessage id="page.login.login" defaultMessage="Log In" />
          </GetStartedButton>
        </Form.Item>
      </Form>
    </>
  );
};

export default SignInForm;
