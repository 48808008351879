import React, { useState } from "react";
import { Layout } from "antd";
import { Switch, Route } from "react-router-dom";

import TrackingInfoHeader from "./components/TrackingInfoHeader";
import TrackingInfoHome from "./components/TrackingInfoHome";
import "./trackingInfo.less";
import TrackingInfoFAQ from "./components/TrackingInfoFAQ";

const { Content } = Layout;

const TrackingInfo = ({ match, location }) => {
  const [selectedTab, setSelectedTab] = useState("");
  return (
    <Layout>
      <TrackingInfoHeader location={location} />
      <Content className="trackingInfoContent">
        <Switch>
          <Route
            path={`${match.url}/home`}
            exact
            render={(props) => (
              <TrackingInfoHome selectedTab={selectedTab} setSelectedTab={setSelectedTab} {...props} />
            )}
          />
          <Route path={`${match.url}/drivers`} exact render={(props) => <p>Drivers </p>} />
          <Route path={`${match.url}/customers`} exact render={(props) => <p>Customers </p>} />
          <Route path={`${match.url}/packages`} exact render={(props) => <p>Packages </p>} />
          <Route
            path={`${match.url}/faq`}
            exact
            render={(props) => <TrackingInfoFAQ selectedTab={selectedTab} setSelectedTab={setSelectedTab} {...props} />}
          />
        </Switch>
      </Content>
    </Layout>
  );
};

export default TrackingInfo;
