import { Breadcrumb } from "antd";
import React, { FC } from "react";
import { RootStateOrAny, connect } from "react-redux";
import { Link } from "react-router-dom";
import JobDetailsComponent from "src/components/JobDetails/JobDetailsComponent";
import { BASE_ROUTE } from "src/routes";

const JobDetails: FC<any> = () => {
  return <JobDetailsComponent />;
};

const MSTP = (state: RootStateOrAny) => {
  return {};
};

export default connect(MSTP, (dispatch) => ({
  //   ...bindActionCreators(deliveryJob, dispatch),
}))(JobDetails);
