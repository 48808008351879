import React, { useCallback, useEffect, useState } from "react";
import { notification, Table } from "antd";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { assignIn, debounce } from "lodash";
import moment from "moment";

import { getQueryParam, updatePageUrl } from "../../helpers/general";
import { actions as statisticsActions } from "../../redux/resources/statistics";

export const useStatisticsTable = (statistics) => {
  const [selectedOrder, setSelectedOrder] = useState(null);

  const handleCloseDrawer = () => {
    setSelectedOrder(null);
  };

  const columns = [
    { title: "Job Id", dataIndex: "jobId", key: "jobId" },
    { title: "Date of completion", dataIndex: "dateOfCompetition", key: "dateOfCompetition" },
    { title: "Driver name", dataIndex: "driverName", key: "driverName" },
  ];

  const data = statistics?.map(({ id, completedAt, driver, ...otherStats }) => {
    const d = new Date(completedAt);
    const dateString = moment(d).format("DD.MM.YYYY HH:mm");
    return {
      jobId: id,
      dateOfCompetition: dateString,
      driverName:
        driver?.profile?.firstName && driver?.profile?.lastName
          ? `${driver.profile.firstName} ${driver.profile.lastName}`
          : "no info",
      ...otherStats,
    };
  });

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "Order Id",
        dataIndex: "orderId",
        key: "orderId",
        render: (id) => (
          <p
            className="orderLink"
            onClick={() => {
              setSelectedOrder(record.orders.find((order) => order.id === id));
            }}
          >
            {id}
          </p>
        ),
      },
      { title: "Tracking ID", dataIndex: "trackingId", key: "trackingId" },
      { title: "Cash on delivery", dataIndex: "cashOnDelivery", key: "cashOnDelivery" },
      { title: "Driver Rating", dataIndex: "rating", key: "rating" },
      { title: "Order Rating", dataIndex: "orderRating", key: "orderRating" },
      { title: "Driver Feedback", dataIndex: "feedbackMessage", key: "feedbackMessage" },
      { title: "Product Feedback", dataIndex: "orderRatingBody", key: "orderRatingBody" },
      { title: "Status", dataIndex: "status", key: "status" },
      { title: "Notes", dataIndex: "notes", key: "notes" },
      {
        title: (
          <div>
            <p className="estimatedTimeHeader">Estimated</p>
            <p className="realTimeHeader">Real time</p>
          </div>
        ),
        dataIndex: "realTime",
        key: "realTime",
        render: ({ deliveryTime, realDeliveryTimeUTC }) => {
          return (
            <div>
              <p className="estimatedTime">{deliveryTime || "N/A"}</p>
              <p className="realTime">
                {realDeliveryTimeUTC ? moment(realDeliveryTimeUTC).format("DD.MM.YYYY HH:mm") : "N/A"}
              </p>
            </div>
          );
        },
      },
    ];
    const data = record?.orders?.map(
      ({ id, cashAmount, trackingId, feedback, status, realDeliveryTimeUTC, deliveryTime, notes }) => {
        return {
          orderId: id,
          trackingId,
          cashOnDelivery: cashAmount,
          rating: feedback?.rating || "No rating left",
          orderRating: feedback?.orderRating || "No rating left",
          feedbackMessage: feedback?.body || "No message left",
          orderRatingBody: feedback?.orderRatingBody || "No message left",
          status,
          notes,
          realTime: { deliveryTime, realDeliveryTimeUTC },
        };
      },
    );

    return (
      record?.orders && (
        <div>
          <Table
            columns={columns}
            // style={{ overflowX: "visible" }}
            className="ordersTablewrapper"
            dataSource={data}
            pagination={false}
            rowKey={(record) => record.id}
          />
        </div>
      )
    );
  };
  return { data, columns, expandedRowRender, selectedOrder, setSelectedOrder, handleCloseDrawer };
};

export const usePagination = ({ pagination: paginationData, isMobile }) => {
  const location = useLocation();
  const history = useHistory();

  const getDriversStatisticsQueryParams = useCallback(() => getQueryParam(location.search), [location.search]);

  const updateDriversStatisticsPageUrl = (newQueryParams) => {
    const previousQueryParams = getDriversStatisticsQueryParams();
    updatePageUrl(newQueryParams, previousQueryParams, history, "admin/drivers_stats");
  };

  const currentQueryParams = getDriversStatisticsQueryParams();
  const onChangePagination = (pagination) => {
    updateDriversStatisticsPageUrl({ page: pagination.current, limit: pagination.pageSize });
  };

  const driversStatisticsPagination = {
    pageSize: currentQueryParams?.limit || 10,
    total: paginationData?.totalItems || 10,
    current: Number(currentQueryParams?.page) || 1,
    pageSizeOptions: isMobile ? [10, 20] : [10, 20, 30, 50],
    showSizeChanger: true,
  };
  return { driversStatisticsPagination, onChangePagination, getDriversStatisticsQueryParams };
};

export const useFilters = (getDriversStatisticsQueryParams) => {
  const [selectedFilter, setSelectedFilter] = useState("driverName");
  const [dates, setDates] = useState({ to: "", from: "" });
  const [queryDates, setQueryDates] = useState({ from: "", to: "" });
  const [driverName, setDriverName] = useState("");
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [trackingId, setTrackingId] = useState("");
  const [jobId, setJobId] = useState("");
  const [rating, setRating] = useState("");
  const [orderRating, setOrderRating] = useState("");

  const dispatch = useDispatch();

  const fetchAllDriversStatics = useCallback(
    async (otherQueries) => {
      const { fetchStatistics } = statisticsActions;
      const queryParams = getDriversStatisticsQueryParams();
      const queryWithPage = queryParams.page ? queryParams : { page: 1, limit: 10 };
      const withOtherQueries = otherQueries ? assignIn(queryWithPage, otherQueries) : queryWithPage;

      try {
        await dispatch(
          fetchStatistics(
            {},
            {
              query: {
                ...withOtherQueries,
                ...(driverName && { name: driverName }),
                ...(queryDates.from && { from: queryDates.from }),
                ...(queryDates.to && { to: queryDates.to }),
                ...(trackingId && { orderTrackingId: trackingId }),
                ...(customerPhoneNumber && { customerPhoneNumber }),
                ...(orderRating && { orderRating }),
                ...(rating && { rating }),
                ...(jobId && { jobId }),
              },
            },
          ),
        );
      } catch (error) {
        notification.error({
          message: "Error",
          description: error.body && error.body.message && error.body.message,
        });
      }
    },
    [
      getDriversStatisticsQueryParams,
      dispatch,
      driverName,
      queryDates,
      trackingId,
      customerPhoneNumber,
      rating,
      orderRating,
      jobId,
    ],
  );

  useEffect(() => {
    fetchAllDriversStatics();
  }, [fetchAllDriversStatics]);

  const changeFilter = (value) => {
    setSelectedFilter(value);
    setDriverName("");
    setTrackingId("");
    setJobId("");
    setRating("");
    setOrderRating("");
    setCustomerPhoneNumber("");
  };

  const changeDriverName = (event) => {
    const fullName = event.target.value;
    setDriverName(fullName);
  };

  const changeTrackingId = (event) => {
    const trackingId = event.target.value;
    setTrackingId(trackingId);
  };

  const changeJobId = (event) => {
    const jobId = event.target.value;
    setJobId(jobId);
  };

  const changeCustomerPhoneNumber = (event) => {
    const phoneNumber = event.target.value;
    setCustomerPhoneNumber(phoneNumber);
  };

  const setInputValue = debounce((event) => {
    if (selectedFilter === "driverName") {
      changeDriverName(event);
    }
    if (selectedFilter === "trackingId") {
      changeTrackingId(event);
    }
    if (selectedFilter === "customerPhoneNumber") {
      changeCustomerPhoneNumber(event);
    }
    if (selectedFilter === "jobId") {
      changeJobId(event);
    }
    if (selectedFilter === "driverRating") {
      onRatingChange(event.target.value);
    }
    if (selectedFilter === "orderRating") {
      onRatingChange(event.target.value);
    }
  }, 800);

  const onRatingChange = (rate, filter) => {
    if (selectedFilter === "orderRating") {
      setOrderRating(rate);
    }
    if (selectedFilter === "driverRating") {
      setRating(rate);
    }
  };

  const handleSetDates = (dates, momentDates) => {
    setDates(dates);
    if (dates) {
      const fromDate = moment(momentDates[0]).toISOString(true).substring(0, 23);
      const toDate = moment(momentDates[1]).toISOString(true).substring(0, 23);
      // toDate.setUTCHours(23, 59, 59, 999);
      // toDate = toDate.toISOString();
      setQueryDates({ from: fromDate, to: toDate });
    } else {
      setQueryDates({ from: "", to: "" });
    }
  };

  const disabledTomorrowAndFuture = (current) => {
    return current > new Date();
  };

  return {
    dates,
    setInputValue,
    handleSetDates,
    disabledTomorrowAndFuture,
    selectedFilter,
    changeFilter,
    rating,
    onRatingChange,
  };
};
