import React from "react";
import { Button } from "antd";
import { FormattedMessage } from "react-intl";

export const ActionButtons = ({
  old,
  orderId,
  phone,
  isRetailer,
  onActionBtnClick,
  onOpenPhoneModal,
  deleteLocal,
  orderStatus,
  ticketDetails,
}) => {
  const isCompleted = "completed" === orderStatus;
  return (
    <div className="btn-actions">
      {old ? (
        <>
          <Button
            hidden={isCompleted}
            onClick={onActionBtnClick({ type: "completeOrder", id: orderId, open: true })}
            className="mobile-btn mobile-btn--blue"
          >
            <FormattedMessage id="tabPane.actions.complete" defaultMessage="Complete" />
          </Button>
          <Button
            hidden={isCompleted}
            onClick={onActionBtnClick({ type: "postpone", id: orderId, open: true })}
            className="mobile-btn mobile-btn--orange"
          >
            <FormattedMessage id="tabPane.actions.postpone" defaultMessage="Postpone" />
          </Button>
          <Button onClick={onOpenPhoneModal({ orderId, phone })} className="mobile-btn mobile-btn--blue">
            <FormattedMessage id="tabPane.actions.editPhone" defaultMessage="Edit Phone" />
          </Button>

          <div className="btn-actions__group">
            <Button
              hidden={isCompleted}
              onClick={onActionBtnClick({ type: "cancel", id: orderId, open: true })}
              className="mobile-btn mobile-btn--gray"
            >
              <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              hidden={!isCompleted}
              onClick={onActionBtnClick({ type: "cancelCompleted", id: orderId, open: true })}
              className="mobile-btn mobile-btn--gray"
            >
              <FormattedMessage id="tabPane.actions.cancelCompleted" defaultMessage="Cancel Completed" />
            </Button>
            <Button
              onClick={onActionBtnClick({ type: "delete", id: orderId, open: true })}
              className="mobile-btn mobile-btn--purple"
            >
              <FormattedMessage id="tabPane.actions.delete" defaultMessage="Delete" />
            </Button>
          </div>
          <div className="btn-actions__group">
            <Button
              onClick={onActionBtnClick({ type: "return", id: orderId, open: true })}
              className="mobile-btn mobile-btn--gray"
            >
              <FormattedMessage id="tabPane.actions.return" defaultMessage="Return" />
            </Button>
            <Button
              onClick={onActionBtnClick({ type: "removeLocation", id: orderId, open: true })}
              className="mobile-btn mobile-btn--blue"
            >
              <FormattedMessage id="modal.removeLocation" defaultMessage="Remove location" />
            </Button>
          </div>
        </>
      ) : (
        <Button onClick={deleteLocal(orderId)} className="mobile-btn mobile-btn--purple">
          <FormattedMessage id="tabPane.actions.delete" defaultMessage="Delete" />
        </Button>
      )}
    </div>
  );
};
