import React, { useEffect, useState } from "react";
import { Steps } from "antd";
import classnames from "classnames";

import { ReactComponent as WaitingStep } from "../../../../assets/waitingStep.svg";
import { ReactComponent as ProgressStep } from "../../../../assets/progressStep.svg";
import { ReactComponent as FinishStep } from "../../../../assets/doneStep.svg";

import "./trakingSteps.less";

const { Step } = Steps;

const TrackingSteps = ({ stepsDirection, currentActiveStep, locationShared, isReturn, isStoreToStore }) => {
  const [activeStep, setActiveStep] = useState(0);

  let steps;

  if (isReturn) {
    steps = [
      {
        title: "Share location",
        values: ["locationShared"],
      },
      {
        title: "Package has been picked up",
        values: ["completed"],
      },
    ];
  } else if (isStoreToStore) {
    steps = [
      {
        title: "Your package is being prepared",
        values: ["locationShared"],
      },
      {
        title: "You package has been picked up",
        values: ["pickup_completed", "in_transit", "in_delivery", "arrived"],
      },
      {
        title: "Your package has been delivered",
        values: ["completed"],
      },
    ];
  } else {
    steps = [
      {
        title: "Share location",
        values: ["new"],
      },
      {
        title: "Your package is being prepared",
        values: ["locationShared"],
      },
      {
        title: "You package has been picked up",
        values: ["pickup_completed", "in_transit"],
      },
      {
        title: "Your package is on the way",
        values: ["in_delivery"],
      },
      {
        title: "Your package is at your door",
        values: ["arrived"],
      },
      {
        title: "Your package has been delivered",
        values: ["completed"],
      },
    ];
  }

  useEffect(() => {
    steps.find(({ values }, index) => {
      if (values?.includes(currentActiveStep)) {
        if (currentActiveStep === "new" && locationShared) {
          setActiveStep(1);
        } else {
          setActiveStep(index);
        }
      }
      return values.includes(currentActiveStep);
    });
  }, [steps, currentActiveStep, locationShared]);

  const getStepIcon = (index) => {
    if (activeStep > index) {
      return <FinishStep />;
    }
    if (activeStep < index) {
      return <WaitingStep />;
    }
    if (activeStep === index) {
      return <ProgressStep />;
    }
  };

  return (
    <Steps direction={stepsDirection} size="small" current={activeStep} className="stepsWidth">
      {steps.map((item, index) => (
        <Step
          key={item.values[0]}
          className="trackingStep"
          icon={getStepIcon(index)}
          description={
            <p
              className={classnames(
                "stepDescription",
                (index === currentActiveStep || index < currentActiveStep) && "activeStepTitle",
              )}
            >
              {item.title}
            </p>
          }
          title={
            <p
              className={classnames(
                "stepTitle",
                (index === currentActiveStep || index < currentActiveStep) && "activeStepTitle",
              )}
            >
              {item.title}
            </p>
          }
        />
      ))}
    </Steps>
  );
};

export default TrackingSteps;
