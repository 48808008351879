import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal } from "antd";

import { ReactComponent as CloseModal } from "../../../../../assets/adminPanel/close-note-modal.svg";

import "./styles.less";

export const MobileCashAmountModal = ({ visible, updateOrderCash, closeModal, selectedJobId }) => {
  const [amount, setAmount] = useState(null);
  const [form] = Form.useForm();

  const setInitialCashAmount = () => {
    form.setFields([{ name: "cashAmount", value: visible?.cashAmount }]);
  };

  useEffect(() => {
    if (visible?.cashAmount) {
      setInitialCashAmount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible?.cashAmount]);

  const isValidCashAmount = (value) => {
    return /^\d+(\.\d+)*$/.test(value);
  };

  const onSubmit = async () => {
    if (isValidCashAmount(amount)) {
      await updateOrderCash(amount, selectedJobId);
      setAmount(null);
      closeModal();
    }
  };

  const onValuesChange = ({ cashAmount }) => {
    setAmount(cashAmount);
  };

  const onCancel = () => {
    setAmount(null);
    closeModal();
    setTimeout(() => setInitialCashAmount(), 200);
  };

  const onClose = () => {
    setAmount(null);
    closeModal();
  };

  return (
    <Modal
      title="Edit cash amount of the order"
      closeIcon={<CloseModal />}
      visible={!!visible}
      onCancel={onClose}
      className="mobile-cash-amount-modal"
      footer={null}
    >
      <div className="mobile-cash-amount-modal__body">
        <Form form={form} onValuesChange={onValuesChange}>
          <Form.Item
            name="cashAmount"
            rules={[
              { required: true, message: "Required field!" },
              () => ({
                validator(rule, value) {
                  if (isValidCashAmount(value)) {
                    return Promise.resolve();
                  }

                  return Promise.reject("Only float numbers");
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
        <Button onClick={onSubmit} disabled={!isValidCashAmount(amount)} className="mobile-btn mobile-btn--yellow">
          Confirm
        </Button>
        <Button onClick={onCancel} className="mobile-btn mobile-btn--white">
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
