import React from "react";
import { Typography } from "antd";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import client1 from "../../../../assets/newLanding/ourClients/client_1.png";
import client2 from "../../../../assets/newLanding/ourClients/client_2.png";
import client3 from "../../../../assets/newLanding/ourClients/client_3.png";
import client4 from "../../../../assets/newLanding/ourClients/client_4.png";
import client5 from "../../../../assets/newLanding/ourClients/client_5.png";
import client6 from "../../../../assets/newLanding/ourClients/client_6.png";
import client7 from "../../../../assets/newLanding/ourClients/client_7.png";
import client8 from "../../../../assets/newLanding/ourClients/client_8.png";
import client9 from "../../../../assets/newLanding/ourClients/client_9.png";
import client10 from "../../../../assets/newLanding/ourClients/client_10.png";
import { ReactComponent as ArrowLeft } from "../../../../assets/newLanding/ourClients/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../../../assets/newLanding/ourClients/arrowRight.svg";

import styles from "./ourClients.module.less";

const clients = [client1, client2, client3, client4, client5, client6, client7, client8, client9, client10];

const OurClients = ({ isMobile }) => (
  <div className={styles.clientsArticle}>
    <Typography.Title level={2} className={isMobile ? styles.clientsTitleMobile : styles.clientsTitle}>
      Some of our
      <Typography.Text className={isMobile ? styles.clientsTitleInsertionMobile : styles.clientsTitleInsertion}>
        {" "}
        Clients
      </Typography.Text>
    </Typography.Title>

    <CarouselProvider
      infinite
      // isPlaying
      touchEnabled={isMobile}
      dragStep={isMobile ? 2 : 1}
      totalSlides={clients.length}
      visibleSlides={isMobile ? 3 : 5}
      naturalSlideWidth={139}
      naturalSlideHeight={110}
      className={isMobile ? styles.sliderWrapMobile : styles.sliderWrap}
      interval={isMobile ? 2000 : 5000}
    >
      <Slider className={styles.slider}>
        {clients.map((clientImg, idx) => (
          <Slide key={idx.toString(36)} index={idx}>
            <Image src={clientImg} alt="client1" className={isMobile ? styles.clientImgMobile : styles.clientImg} />
          </Slide>
        ))}
      </Slider>

      {!isMobile && (
        <div className={styles.controlWrap}>
          <ButtonBack className={styles.controlBtn}>
            <ArrowLeft />
          </ButtonBack>

          <ButtonNext className={styles.controlBtn}>
            <ArrowRight />
          </ButtonNext>
        </div>
      )}
    </CarouselProvider>
  </div>
);

export default OurClients;
