import { createResource } from "redux-rest-resource";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "adminRetailers",
  url: `${API_URL}/admin/retailers`,
  actions: {
    fetch: {
      transformResponse: ({ body }) => {
        return {
          items: body.items,
          pagination: {
            currentPageNumber: body.meta.currentPage,
            numItemsPerPage: body.meta.itemsPerPage,
            totalCount: body.meta.totalItems,
            totalPages: body.meta.totalPages,
          },
        };
      },
      reduce: (state, action) => {
        return {
          ...state,
          items: action.items,
          pagination: action.pagination,
          forceReturn: false,
          selectedRetailer: undefined,
        };
      },
    },
    fetchOne: {
      method: "GET",
      url: `${API_URL}/admin/retailers/:retailerId`,
      reduce: (state, action) => {
        if (action.status === "resolved") {
          return {
            ...state,
            selectedRetailer: action.body,
          };
        }
        return state;
      },
    },
    changeStatus: {
      method: "PUT",
      url: `${API_URL}/admin/retailers/:retailerId`,
      reduce: (state, action) => {
        if (action.status === "resolved") {
          return {
            ...state,
            selectedRetailer: action.body,
          };
        }
        return state;
      },
    },
    fetchRestrictedList: {
      method: "GET",
      url: `${API_URL}/admin/retailers/restricted/list`,
      transformResponse: ({ body }) => {
        return {
          list: body,
        };
      },
      reduce: (state, action) => {
        return {
          ...state,
          restrictedList: action.list,
        };
      },
    },
    fetchList: {
      method: "GET",
      url: `${API_URL}/admin/retailers/list`,
      transformResponse: ({ body }) => {
        return {
          list: body,
        };
      },
      reduce: (state, action) => {
        return {
          ...state,
          list: action.list,
        };
      },
    },
    updateInstructions: {
      method: "PUT",
      url: `${API_URL}/admin/retailers/:retailerId/pickup-point/:pickupPointId/instructions`,
    },

    deleteRetailer: {
      method: "DELETE",
      url: `${API_URL}/admin/retailers/:retailerId`,
      reduce: (state, action) => {
        if (action.status === "resolved") {
          return {
            ...state,
            selectedRetailer: undefined,
            forceReturn: false,
          };
        }
        return state;
      },
    },
    updateRetailer: {
      method: "PUT",
      url: `${API_URL}/company/retailers/info/:retailerId`,
    },
    changeRetailerSMSrights: {
      method: "PATCH",
      url: `${API_URL}/admin/retailers/:retailerId/messaging-templates`,
    },
    addOne: {
      method: "POST",
      url: `${API_URL}/admin/retailers`,
    },
    selectOne: {
      isPure: true,
      reduce: (state, action) => {
        if (action.status === "resolved") {
          const retailer = state.items.find((item) => item.id === Number(action.context));
          return {
            ...state,
            selectedRetailer: retailer,
            forceReturn: !retailer,
          };
        }
        return state;
      },
    },

    resetFlag: {
      isPure: true,
      reduce: (state, action) => {
        if (action.status === "resolved") {
          return {
            ...state,
            selectedRetailer: undefined,
            forceReturn: false,
          };
        }
        return state;
      },
    },
  },
});
