import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Tabs } from "antd";
import { DownloadOutlined, WarningOutlined } from "@ant-design/icons";
import moment from "moment";

import { MobileTitle } from "../../../components/AdminPanel/Mobile/MobileTitle";
import UploadOrdersDragger from "../../../components/OrdersWidget/UploadOrdersDragger";
import { API_URL } from "../../../constants/config";
import { SharedOrderLocationModal } from "./components/SharedOrderLocationModal";
import { OrderDetailsModal } from "./components/OrderDetailsModal";
import { ReportModal } from "./components/ReportModal";
import { OrdersTable } from "./components/OrdersTable";
import { useHooks } from "./hooks/useHooks";
import { MobileLoader } from "../../../components/AdminPanel/Mobile/MobileLoader";

const { TabPane } = Tabs;

const downloadUrl = `${API_URL}/server/files/template`;

export const MobileView = ({
  selectedRow,
  setSelectedOrderTrackingId,
  handleSelectOrder,
  isAdminOrdersFetching,
  isOrdersSaving,
  saveAdminOrders,
  adminOrders,
  paginationConfig,
  changeQueryParams,
  cancelAdminOrder,
  deleteAdminOrder,
  deleteAdminOrders,
  postponeAdminOrder,
  fetchAllOrders,
  createReturnAdminOrder,
  completeOrderAdminOrder,
  removeLocationAdminOrder,
  retailersList,
  filteredRetailer,
  filteredLocationShared,
  changeLocationAdminOrder,
  editPhoneAdminOrder,
  restoreFailedAdminOrder,
  getReportsAdminOrder,
  removeOrdersFromJob,
  addOrdersToJob,
  getJobsByStatusAdminOrder,
  incompleteJobs,
  cancelCompletedAdminOrder,
}) => {
  const {
    draggerProps,
    selectedTab,
    selectedOrders,
    downloadedOrders,
    orderSearchQuery,
    searchedValue,
    selectedSharedLocation,
    setSelectedSharedLocation,
    openModal,
    isReportsModalOpen,
    phoneModalVisible,
    fetchAccordingToTab,
    setSelectedTab,
    setDownloadedOrders,
    setOpenModal,
    setOrderSearchQuery,
    setSearchedValue,
    setSelectedOrders,
    handleModalSubmit,
    onOpenReportModal,
    onOpenSharedLocation,
    onOpenPhoneModal,
    onCloseSharedLocation,
    onCloseOrderDetails,
    onCloseReportModal,
    onClosePhoneModal,
    deleteLocal,
    getMapData,
    completedOrdersFilter,
    setCompletedOrdersFilter,
    setCanceledOrdersFilter,
    setPostponedOrdersFilter,
    canEditModal,
  } = useHooks({
    adminOrders,
    fetchAllOrders,
    paginationConfig,
    setSelectedOrderTrackingId,
    createReturnAdminOrder,
    completeOrderAdminOrder,
    removeLocationAdminOrder,
    cancelCompletedAdminOrder,
    saveAdminOrders,
    postponeAdminOrder,
    cancelAdminOrder,
    deleteAdminOrder,
    deleteAdminOrders,
  });

  const addLocationToLocal = async ({ selectedId, lat, long, old, address }) => {
    if (!old) {
      setDownloadedOrders((prevState) => {
        const copied = [...prevState];
        const searchedOrder = copied.find(({ id }) => id === selectedId);
        searchedOrder.deliveryPoint.lat = lat;
        searchedOrder.deliveryPoint.long = long;
        return copied;
      });
    } else {
      await changeLocationAdminOrder({ orderId: selectedId, lat, long, address });
      fetchAccordingToTab();
    }
  };

  return (
    <>
      <div className="mobile-page-wrapper mobile-page-wrapper--orders-managment">
        {(isAdminOrdersFetching || isOrdersSaving) && <MobileLoader />}
        <MobileTitle title="Upload Orders List" />
        <div className="upload-orders-block">
          <UploadOrdersDragger draggerProps={draggerProps} isAdmin />
          <div className="upload-orders-block__btn-group">
            <Button className="mobile-btn mobile-btn--blue" icon={<DownloadOutlined />}>
              <a href={`${downloadUrl}?localDate=${moment().format("DD.MM.YYYY")}`} download>
                <FormattedMessage id="downloadTemplate" defaultMessage="Download Template" />
              </a>
            </Button>
            <Button onClick={onOpenReportModal} className="mobile-btn mobile-btn--purple" icon={<WarningOutlined />}>
              <FormattedMessage id="modal.report" defaultMessage="Report" />
            </Button>
          </div>
        </div>
        <Tabs
          activeKey={String(selectedTab)}
          defaultActiveKey="1"
          onChange={(tabNumber) => {
            setOrderSearchQuery("trackingId");
            changeQueryParams({});
            setSearchedValue("");
            setSelectedOrders([]);
            setDownloadedOrders([]);
            setSelectedTab(Number(tabNumber));
          }}
        >
          <TabPane tab={<FormattedMessage id="tab.orders.current" defaultMessage="Current Orders" />} key="1">
            <OrdersTable
              old
              orders={adminOrders}
              retailers={retailersList}
              searchedValue={searchedValue}
              selectedOrders={selectedOrders}
              openModal={openModal}
              phoneModalVisible={phoneModalVisible}
              orderSearchQuery={orderSearchQuery}
              selectedTab={selectedTab}
              filteredRetailer={filteredRetailer}
              filteredLocationShared={filteredLocationShared}
              paginationConfig={paginationConfig}
              fetchAccordingToTab={fetchAccordingToTab}
              changeLocationAdminOrder={changeLocationAdminOrder}
              handleModalSubmit={handleModalSubmit}
              onOpenSharedLocation={onOpenSharedLocation}
              onOpenPhoneModal={onOpenPhoneModal}
              handleSelectOrder={handleSelectOrder}
              changeQueryParams={changeQueryParams}
              setSelectedOrders={setSelectedOrders}
              setSearchedValue={setSearchedValue}
              setOrderSearchQuery={setOrderSearchQuery}
              setDownloadedOrders={setDownloadedOrders}
              setOpenModal={setOpenModal}
              onClosePhoneModal={onClosePhoneModal}
              deleteLocal={deleteLocal}
              editPhoneAdminOrder={editPhoneAdminOrder}
              getMapData={getMapData}
              removeOrdersFromJob={removeOrdersFromJob}
              addOrdersToJob={addOrdersToJob}
              getJobsByStatusAdminOrder={getJobsByStatusAdminOrder}
              incompleteJobs={incompleteJobs}
            />
          </TabPane>
          <TabPane tab={<FormattedMessage id="tab.orders.newUploaded" defaultMessage="New Uploaded Orders" />} key="2">
            <OrdersTable
              orders={downloadedOrders}
              searchedValue={searchedValue}
              selectedOrders={selectedOrders}
              openModal={openModal}
              orderSearchQuery={orderSearchQuery}
              selectedTab={selectedTab}
              filteredRetailer={filteredRetailer}
              filteredLocationShared={filteredLocationShared}
              paginationConfig={paginationConfig}
              fetchAccordingToTab={fetchAccordingToTab}
              changeLocationAdminOrder={changeLocationAdminOrder}
              handleModalSubmit={handleModalSubmit}
              onOpenSharedLocation={onOpenSharedLocation}
              handleSelectOrder={handleSelectOrder}
              changeQueryParams={changeQueryParams}
              setSelectedOrders={setSelectedOrders}
              setSearchedValue={setSearchedValue}
              setOrderSearchQuery={setOrderSearchQuery}
              setDownloadedOrders={setDownloadedOrders}
              setOpenModal={setOpenModal}
              deleteLocal={deleteLocal}
              getMapData={getMapData}
              getJobsByStatusAdminOrder={getJobsByStatusAdminOrder}
              incompleteJobs={incompleteJobs}
            />
          </TabPane>
          <TabPane tab={<FormattedMessage id="tab.orders.postponed" defaultMessage="Postponed Orders" />} key="3">
            <OrdersTable
              old
              isPostponed
              orders={adminOrders}
              retailers={retailersList}
              searchedValue={searchedValue}
              selectedOrders={selectedOrders}
              openModal={openModal}
              phoneModalVisible={phoneModalVisible}
              orderSearchQuery={orderSearchQuery}
              selectedTab={selectedTab}
              filteredRetailer={filteredRetailer}
              filteredLocationShared={filteredLocationShared}
              paginationConfig={paginationConfig}
              fetchAccordingToTab={fetchAccordingToTab}
              changeLocationAdminOrder={changeLocationAdminOrder}
              handleModalSubmit={handleModalSubmit}
              onOpenSharedLocation={onOpenSharedLocation}
              onOpenPhoneModal={onOpenPhoneModal}
              handleSelectOrder={handleSelectOrder}
              changeQueryParams={changeQueryParams}
              setSelectedOrders={setSelectedOrders}
              setSearchedValue={setSearchedValue}
              setOrderSearchQuery={setOrderSearchQuery}
              setDownloadedOrders={setDownloadedOrders}
              setOpenModal={setOpenModal}
              onClosePhoneModal={onClosePhoneModal}
              deleteLocal={deleteLocal}
              editPhoneAdminOrder={editPhoneAdminOrder}
              getMapData={getMapData}
              setPostponedOrdersFilter={setPostponedOrdersFilter}
              removeOrdersFromJob={removeOrdersFromJob}
              addOrdersToJob={addOrdersToJob}
              getJobsByStatusAdminOrder={getJobsByStatusAdminOrder}
              incompleteJobs={incompleteJobs}
            />
          </TabPane>
          <TabPane tab={<FormattedMessage id="tab.orders.all" defaultMessage="All Orders" />} key="4">
            <OrdersTable
              old
              orders={adminOrders}
              retailers={retailersList}
              searchedValue={searchedValue}
              selectedOrders={selectedOrders}
              openModal={openModal}
              phoneModalVisible={phoneModalVisible}
              orderSearchQuery={orderSearchQuery}
              selectedTab={selectedTab}
              filteredRetailer={filteredRetailer}
              filteredLocationShared={filteredLocationShared}
              paginationConfig={paginationConfig}
              fetchAccordingToTab={fetchAccordingToTab}
              changeLocationAdminOrder={changeLocationAdminOrder}
              handleModalSubmit={handleModalSubmit}
              onOpenSharedLocation={onOpenSharedLocation}
              onOpenPhoneModal={onOpenPhoneModal}
              handleSelectOrder={handleSelectOrder}
              changeQueryParams={changeQueryParams}
              setSelectedOrders={setSelectedOrders}
              setSearchedValue={setSearchedValue}
              setOrderSearchQuery={setOrderSearchQuery}
              setDownloadedOrders={setDownloadedOrders}
              setOpenModal={setOpenModal}
              onClosePhoneModal={onClosePhoneModal}
              deleteLocal={deleteLocal}
              editPhoneAdminOrder={editPhoneAdminOrder}
              getMapData={getMapData}
              removeOrdersFromJob={removeOrdersFromJob}
              addOrdersToJob={addOrdersToJob}
              getJobsByStatusAdminOrder={getJobsByStatusAdminOrder}
              incompleteJobs={incompleteJobs}
            />
          </TabPane>
          <TabPane tab={<FormattedMessage id="tab.orders.completed" defaultMessage="Completed Orders" />} key="5">
            <OrdersTable
              old
              isCompleted
              orders={adminOrders}
              retailers={retailersList}
              searchedValue={searchedValue}
              selectedOrders={selectedOrders}
              openModal={openModal}
              phoneModalVisible={phoneModalVisible}
              orderSearchQuery={orderSearchQuery}
              selectedTab={selectedTab}
              filteredRetailer={filteredRetailer}
              filteredLocationShared={filteredLocationShared}
              paginationConfig={paginationConfig}
              fetchAccordingToTab={fetchAccordingToTab}
              changeLocationAdminOrder={changeLocationAdminOrder}
              handleModalSubmit={handleModalSubmit}
              onOpenSharedLocation={onOpenSharedLocation}
              onOpenPhoneModal={onOpenPhoneModal}
              handleSelectOrder={handleSelectOrder}
              changeQueryParams={changeQueryParams}
              setSelectedOrders={setSelectedOrders}
              setSearchedValue={setSearchedValue}
              setOrderSearchQuery={setOrderSearchQuery}
              setDownloadedOrders={setDownloadedOrders}
              setOpenModal={setOpenModal}
              onClosePhoneModal={onClosePhoneModal}
              deleteLocal={deleteLocal}
              editPhoneAdminOrder={editPhoneAdminOrder}
              getMapData={getMapData}
              completedOrdersFilter={completedOrdersFilter}
              setCompletedOrdersFilter={setCompletedOrdersFilter}
              getJobsByStatusAdminOrder={getJobsByStatusAdminOrder}
              incompleteJobs={incompleteJobs}
              onCancelCompletedOrder={cancelCompletedAdminOrder}
            />
          </TabPane>
          <TabPane tab={<FormattedMessage id="tab.orders.notAssigned" defaultMessage="Not Assigned Orders" />} key="6">
            <OrdersTable
              old
              orders={adminOrders}
              retailers={retailersList}
              searchedValue={searchedValue}
              selectedOrders={selectedOrders}
              openModal={openModal}
              phoneModalVisible={phoneModalVisible}
              orderSearchQuery={orderSearchQuery}
              selectedTab={selectedTab}
              filteredRetailer={filteredRetailer}
              filteredLocationShared={filteredLocationShared}
              paginationConfig={paginationConfig}
              fetchAccordingToTab={fetchAccordingToTab}
              changeLocationAdminOrder={changeLocationAdminOrder}
              handleModalSubmit={handleModalSubmit}
              onOpenSharedLocation={onOpenSharedLocation}
              onOpenPhoneModal={onOpenPhoneModal}
              handleSelectOrder={handleSelectOrder}
              changeQueryParams={changeQueryParams}
              setSelectedOrders={setSelectedOrders}
              setSearchedValue={setSearchedValue}
              setOrderSearchQuery={setOrderSearchQuery}
              setDownloadedOrders={setDownloadedOrders}
              setOpenModal={setOpenModal}
              onClosePhoneModal={onClosePhoneModal}
              deleteLocal={deleteLocal}
              editPhoneAdminOrder={editPhoneAdminOrder}
              getMapData={getMapData}
              removeOrdersFromJob={removeOrdersFromJob}
              addOrdersToJob={addOrdersToJob}
              getJobsByStatusAdminOrder={getJobsByStatusAdminOrder}
              incompleteJobs={incompleteJobs}
            />
          </TabPane>
          <TabPane tab={<FormattedMessage id="tab.orders.canceled" defaultMessage="Canceled Orders" />} key="7">
            <OrdersTable
              old
              isCanceled
              orders={adminOrders}
              retailers={retailersList}
              searchedValue={searchedValue}
              selectedOrders={selectedOrders}
              openModal={openModal}
              phoneModalVisible={phoneModalVisible}
              orderSearchQuery={orderSearchQuery}
              selectedTab={selectedTab}
              filteredRetailer={filteredRetailer}
              filteredLocationShared={filteredLocationShared}
              paginationConfig={paginationConfig}
              fetchAccordingToTab={fetchAccordingToTab}
              changeLocationAdminOrder={changeLocationAdminOrder}
              handleModalSubmit={handleModalSubmit}
              onOpenSharedLocation={onOpenSharedLocation}
              onOpenPhoneModal={onOpenPhoneModal}
              handleSelectOrder={handleSelectOrder}
              changeQueryParams={changeQueryParams}
              setSelectedOrders={setSelectedOrders}
              setSearchedValue={setSearchedValue}
              setOrderSearchQuery={setOrderSearchQuery}
              setDownloadedOrders={setDownloadedOrders}
              setOpenModal={setOpenModal}
              onClosePhoneModal={onClosePhoneModal}
              deleteLocal={deleteLocal}
              editPhoneAdminOrder={editPhoneAdminOrder}
              getMapData={getMapData}
              setCanceledOrdersFilter={setCanceledOrdersFilter}
              getJobsByStatusAdminOrder={getJobsByStatusAdminOrder}
              incompleteJobs={incompleteJobs}
            />
          </TabPane>
          <TabPane tab={<FormattedMessage id="tab.orders.pickupFailed" defaultMessage="Pickup failed" />} key="8">
            <OrdersTable
              old
              pickupFailed
              orders={adminOrders}
              retailers={retailersList}
              searchedValue={searchedValue}
              selectedOrders={selectedOrders}
              openModal={openModal}
              phoneModalVisible={phoneModalVisible}
              orderSearchQuery={orderSearchQuery}
              selectedTab={selectedTab}
              filteredRetailer={filteredRetailer}
              filteredLocationShared={filteredLocationShared}
              paginationConfig={paginationConfig}
              fetchAccordingToTab={fetchAccordingToTab}
              changeLocationAdminOrder={changeLocationAdminOrder}
              handleModalSubmit={handleModalSubmit}
              onOpenSharedLocation={onOpenSharedLocation}
              onOpenPhoneModal={onOpenPhoneModal}
              handleSelectOrder={handleSelectOrder}
              changeQueryParams={changeQueryParams}
              setSelectedOrders={setSelectedOrders}
              setSearchedValue={setSearchedValue}
              setOrderSearchQuery={setOrderSearchQuery}
              setDownloadedOrders={setDownloadedOrders}
              setOpenModal={setOpenModal}
              onClosePhoneModal={onClosePhoneModal}
              deleteLocal={deleteLocal}
              editPhoneAdminOrder={editPhoneAdminOrder}
              getMapData={getMapData}
              restoreFailedAdminOrder={restoreFailedAdminOrder}
              getReportsAdminOrder={getReportsAdminOrder}
              getJobsByStatusAdminOrder={getJobsByStatusAdminOrder}
              incompleteJobs={incompleteJobs}
            />
          </TabPane>
        </Tabs>
      </div>
      <SharedOrderLocationModal
        disabled={!canEditModal}
        updateOrders={fetchAccordingToTab}
        updateLocation={addLocationToLocal}
        getMapData={getMapData}
        setSelectedSharedLocation={setSelectedSharedLocation}
        selectedSharedLocation={selectedSharedLocation}
        onClose={onCloseSharedLocation}
      />
      <OrderDetailsModal selectedRow={selectedRow} onClose={onCloseOrderDetails} />
      <ReportModal isReportsModalOpen={isReportsModalOpen} retailersList={retailersList} onClose={onCloseReportModal} />
    </>
  );
};
