import { createResource, defaultHeaders } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";
import { makeDefaultRequest } from "../../helpers/requestComponents";

export const { types, actions, rootReducer } = createResource({
  name: "systemReport",

  actions: {
    loadRetalilerList: makeDefaultRequest("GET", `${API_URL}/admin/retailers/list`, "retailersList"),
    loadDriverList: makeDefaultRequest("GET", `${API_URL}/admin/drivers/list`, "driversList"),
    // TODO: find better way to handle this
    loadTargetMonth: makeDefaultRequest("GET", `${API_URL}/admin/system-reports/month-data`, "targetMonth"),
    loadComparedMonth: makeDefaultRequest("GET", `${API_URL}/admin/system-reports/month-data`, "comapredMonth"),
    loadChartData: makeDefaultRequest("GET", `${API_URL}/admin/system-reports/chart-data`, "chartData"),
  },
});

setAuthHeader(getAuthData().accessToken, defaultHeaders);
