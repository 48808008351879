import React from "react";

import feedbackIsLeft from "../../../../assets/registrationFinished.png";
import CustomDivider from "../../../../components/Divider";

const FeedbackIsLeft = () => (
  <div className="thank-for-feedback">
    <h3 className="signUpFormTitle">Thank you for feedback!</h3>
    <CustomDivider />
    <img className="finishImage" src={feedbackIsLeft} alt="Thanks for feedback" />
  </div>
);

export default FeedbackIsLeft;
