import React from "react";
import { Divider, Tabs } from "antd";

import CustomDivider from "../../../components/Divider";
import JobsTable from "../components/JobsTable";
import OrdersTable from "../components/OrdersTable";
import StatsTable from "../components/StatsTable";
import EnvStatsConfig from "../components/EnvStatsConfig";

const { TabPane } = Tabs;

export const DesktopView = ({
  isLoading,
  env,
  envList,
  retailersList,
  generalStats,
  orderStats,
  ordersData,
  chartData,
  jobsData,
  setEnv,
  setDateRange,
  downloadReport,
  disabledTomorrowAndFuture,
  ordersPaginationConfig,
  jobsPaginationConfig,
}) => {
  return (
    <main className="pageWrapper">
      <article>
        <section>
          <h2 className="pageTitle">Environment stats</h2>
          <CustomDivider />
        </section>
        <Divider />
        <EnvStatsConfig
          list={envList}
          env={env}
          setEnv={setEnv}
          setDateRange={setDateRange}
          downloadReport={downloadReport}
          retailersList={retailersList}
          isLoading={isLoading}
          disabledTomorrowAndFuture={disabledTomorrowAndFuture}
        />
        <Divider />
        <StatsTable generalStats={generalStats} chartData={chartData} ordersStats={orderStats} />
        <Divider />
        <section className="dataTableWrapper">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Jobs" key="1">
              <JobsTable deliveryJobs={jobsData} jobsPaginationConfig={jobsPaginationConfig} />
            </TabPane>
            <TabPane tab="Orders" key="2">
              <OrdersTable ordersList={ordersData} ordersPaginationConfig={ordersPaginationConfig} />
            </TabPane>
          </Tabs>
        </section>
      </article>
    </main>
  );
};
