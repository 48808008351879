import React, { useLayoutEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import SignUpDriverPage from "../pages/NewLanding/pages/SignUpDriverPage";
import SignUpRetailerPage from "../pages/NewLanding/pages/SignUpRetailerPage";
import SignInRetailerPage from "../pages/NewLanding/pages/SignInRetailerPage";
import DriverRegistration from "../pages/DriverRegistration";
import RetailerRegistration from "../pages/RetailerRegistration";
import SuperAdminLogin from "../pages/SuperAdminLogin/SuperAdminLogin";
import AdminLogin from "../pages/AdminLogin";
import AdminSignup from "../pages/AdminSignup";
import { getAuthData, isLogin, isMainCompany, isRetailer } from "../helpers/authStorage";
import AdminLayout from "../layouts/AdminLayout";
import DriversManager from "../pages/DriversManager";

import JobsConfigurePage from "../components/JobsConfigurePage";
import OrdersManagment from "../pages/OrdersManagement";
import DeliveryReport from "../pages/DeliveryReport";
import DriverCabinet from "../pages/DriverCabinet";
import ReportPdf from "../pages/ReportPdf";
import TrackingInfo from "../pages/TrackingInfo";
import DriversMap from "../pages/DriversMap";
import FleetTrackingPage from "../pages/FleetTrackingPage";
import RetailerCabinet from "../pages/RetailerCabinet";
import RetailerPoints from "../pages/RetailerSettings/RetailerPoints";
import RetailerAccount from "../pages/RetailerSettings/RetailerAccount";
import RetailerSMS from "../pages/RetailerSettings/RetailerSMS";
import RetailerTokens from "../pages/RetailerSettings/RetailerTokens";
import RetailerPreferences from "../pages/RetailerSettings/RetailerPreferences";

import HomePage from "../pages/HomePage";
import NewLanding from "../pages/NewLanding";
import DriversStats from "../pages/DriverStats";
import NewForgotPassword from "../pages/NewLanding/pages/ForgotPassword";
import SystemReportPage from "../pages/SystemReport";
import EnvStatsPage from "../pages/EnvStats";
import Preferences from "../pages/Preferences";
import RetailersGroups from "../pages/RetailersGroups";
import Notifications from "../pages/Notifications";
import { IntlContextProvider } from "../utils/IntlProvider/IntlContextProvider";
import Dashboard from "../pages/Dashboard";
import ProhibitedPage from "../pages/ProhibitedPage";
import UserManagement from "../pages/UserManagement";
import Reports from "../pages/Reports";
import Accounting from "../pages/Accounting";
import RetailerManagment from "../pages/RetailerSettings/RetailerManagment";
import RetailerConfirmation from "../pages/RetailerConfirmation";
import RetailerInsights from "../pages/RetailerInsights.jsx/index.jsx";
import RetailerReports from "../pages/RetailerCabinet/components/Reports";
import PaymentsResultPage from "../pages/PaymentResultPage";
import RetailerJobManagement from "../pages/RetailerJobManagement";

import ROLES from "../constants/roles";
import ZoneManagement from "../pages/ZoneManagement";
import Payments from "../pages/Payments";
import Admin404Page from "../pages/Admin404Page";
import ScrollToTop from "../helpers/ScrollToTop";
import RetailerLayout from "../layouts/RetailerLayout";
import RetailerActivity from "../pages/RetailerActivity";
import ErrorBoundary from "../utils/errorLogging/errorBoundaries";
import RetailerTickets from "../pages/ReatilerTickets";
import AdminTickets from "../pages/AdminTicket";
import CloudFleetRouting from "../pages/CloudFleetRouting";
import CombineJobs from "../pages/CombineJobs";
import JobDetails from "..//pages/JobDetails";
import AdminJobManagement from "../pages/AdminJobManagement";
import StoreManagement from "src/pages/StoreManagement";
import RetailerDetail from "src/pages/StoreManagement/components/RetailerDetails";
import PriceManagement from "src/pages/PriceManagement";
/* --- Delivery Company New Added --- */
import Invoices from "src/pages/Invoices";
import CashToBeCollected from "src/pages/CashToBeCollected";
/* ---- Super Admin (Start) ---- */
import SuperAdminLayout from "src/layouts/SuperAdminLayout/index.js";
import SuperDrivers from "src/pages/SuperAdmin/SuperDrivers";
import SuperDashboard from "src/pages/SuperAdmin/SuperDashboard";
import SuperCompanies from "src/pages/SuperAdmin/SuperCompanies";
import SuperRetailers from "src/pages/SuperAdmin/SuperRetailers";
import SuperOrders from "src/pages/SuperAdmin/SuperOrders";
import SuperUsers from "src/pages/SuperAdmin/SuperUsers";
import Subscriptions from "src/pages/SuperAdmin/Subscriptions";
import SuperInvoices from "src/pages/SuperAdmin/SuperInvoices";
import Plans from "src/pages/SuperAdmin/Plans";
/* ---- Super Admin (End) ---- */

export const getRole = () => {
  const tokens = JSON.parse(localStorage.getItem("authData"));
  return ROLES[tokens?.access];
};

export const checkRole = (rights, Page, Replacement = ProhibitedPage) => {
  switch (rights) {
    case "edit":
      return <Page />;
    case "view":
      return <Page viewOnly />;
    default:
      return <Replacement />;
  }
};

export const BASE_ROUTE = isRetailer ? "retailer_cabinet" : "admin";

const getFirstAvailableRoute = (role) => {
  const availableRoute = Object.keys(role).find((key) => ["edit", "view"].includes(role?.[key]));
  return availableRoute || "not_found";
};

const getTrackingInfoRedirect = ({ location }) => {
  return <Redirect to={`/tracking_info/home${location?.search}`} />;
};
const baseRoute = (isMobile) => {
  const { accessToken, role } = getAuthData();
  if (!accessToken) {
    return (props) => <NewLanding {...props} isMobile={isMobile} />;
  } else if (role === "retailer") {
    return <Redirect to={`/retailer_cabinet/home`} />;
  } else if (role === "driver") {
    return <Redirect to={`/driver_cabinet`} />;
  } else {
    return (props) => <NewLanding {...props} isMobile={isMobile} />;
  }
};

const maxSizeForMobile = 770;

const Routes = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < maxSizeForMobile);
  const isRestricted =
    useSelector((state) => {
      return state?.retailer?.retailerData?.restricted;
    }) || false;

  useLayoutEffect(() => {
    function updateSize() {
      setIsMobile(window.innerWidth < maxSizeForMobile);
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const renderMenuForCompany = () => {
    return (
      <>
        <AdminLayout isOutsiderCompany={!isMainCompany()}>
          <Switch>
            <Route exact path="/admin/not_found">
              <Admin404Page />
            </Route>
            <Route exact path="/admin/tickets">
              {checkRole(getRole()?.tickets, AdminTickets)}
            </Route>
            <Route exact path="/admin/dashboard">
              {checkRole(getRole()?.dashboard, Dashboard)}
            </Route>
            <Route exact path="/admin/jobs">
              {checkRole(getRole()?.delivery_manager, AdminJobManagement)}
            </Route>
            <Route exact path="/admin/jobs/create">
              {checkRole(getRole()?.delivery_manager, CloudFleetRouting)}
            </Route>
            <Route exact path="/admin/jobs/:id/details">
              {checkRole(getRole()?.job_details, JobDetails)}
            </Route>
            <Route exact path="/admin/jobs/combine">
              {checkRole(getRole()?.combine_jobs, CombineJobs)}
            </Route>
            <Route exact path="/admin/jobs/:id/configure">
              {checkRole(getRole()?.delivery_manager, JobsConfigurePage)}
            </Route>
            <Route exact path="/admin/orders_managment">
              {checkRole(getRole()?.orders_managment, OrdersManagment)}
            </Route>
            <Route exact path="/admin/drivers_manager">
              {checkRole(getRole()?.drivers_manager, DriversManager)}
            </Route>
            <Route exact path="/admin/zones_manager">
              {checkRole(getRole()?.zones_manager, ZoneManagement)}
            </Route>
            <Route exact path="/admin/drivers_stats">
              {checkRole(getRole()?.drivers_stats, DriversStats)}
            </Route>
            <Route exact path="/admin/payments">
              {checkRole(getRole()?.payments, Payments)}
            </Route>
            <Route exact path="/admin/invoices">
              {checkRole(getRole()?.invoices, Invoices)}
            </Route>
            <Route exact path="/admin/cash_to_be_collected">
              {checkRole(getRole()?.cash_to_be_collected, CashToBeCollected)}
            </Route>
            <Route exact path="/admin/retailers">
              {checkRole(getRole()?.retailers_list, StoreManagement)}
            </Route>
            <Route exact path="/admin/retailers/:id/details">
              {checkRole(getRole()?.retailers_list, RetailerDetail)}
            </Route>

            <Route exact path="/admin/retailers_groups">
              {checkRole(getRole()?.retailers_groups, RetailersGroups)}
            </Route>
            <Route exact path="/admin/accounting">
              {checkRole(getRole().accounting, Accounting)}
            </Route>
            {/* <Route exact path="/admin/drivers_reports" component={DriversReports} /> */}
            <Route exact path="/admin/delivery_reports">
              {checkRole(getRole()?.delivery_reports, DeliveryReport)}
            </Route>

            <Route exact path="/admin/system_report">
              {checkRole(getRole()?.system_report, SystemReportPage)}
            </Route>
            <Route exact path="/admin/environment_stats">
              {checkRole(getRole()?.environment_stats, EnvStatsPage)}
            </Route>
            <Route exact path="/admin/drivers_map">
              {checkRole(getRole()?.drivers_map, DriversMap)}
            </Route>
            <Route exact path="/admin/tracking_map">
              <FleetTrackingPage />
            </Route>
            <Route exact path="/admin/reports">
              {checkRole(getRole().reports, Reports)}
            </Route>
            <Route exact path="/admin/preferences">
              {checkRole(getRole()?.preferences, Preferences)}
            </Route>
            <Route exact path="/admin/price-settings">
              {checkRole(getRole()?.price_management, PriceManagement)}
            </Route>
            <Route exact path="/admin/notifications/:activeTab?">
              {checkRole(getRole()?.notifications, Notifications)}
            </Route>
            <Route exact path="/admin/user_management">
              {checkRole(getRole()?.user_management, UserManagement)}
            </Route>
            <Route exact path="/admin">
              <Redirect to={`/admin/${getFirstAvailableRoute(getRole())}`} />
            </Route>
            <Redirect to="/admin/not_found" />
          </Switch>
        </AdminLayout>
      </>
    );
  };

  const renderSuperAdminDashboard = () => {
    return (
      <>
        <SuperAdminLayout>
          <Switch>
            <Route exact path="/super_admin/drivers">
              <SuperDrivers />
            </Route>

            <Route exact path="/super_admin/companies">
              <SuperCompanies />
            </Route>
            <Route exact path="/super_admin/dashboard">
              <SuperDashboard />
            </Route>
            <Route exact path="/super_admin/retailers">
              <SuperRetailers />
            </Route>
            <Route exact path="/super_admin/orders">
              <SuperOrders />
            </Route>

            <Route exact path="/super_admin/users">
              <SuperUsers />
            </Route>

            <Route exact path="/super_admin/subscriptions">
              <Subscriptions />
            </Route>

            <Route exact path="/super_admin/invoices">
              <SuperInvoices />
            </Route>

            <Route exact path="/super_admin/plans">
              <Plans />
            </Route>
          </Switch>
        </SuperAdminLayout>
      </>
    );
  };
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <IntlContextProvider>
          <ScrollToTop />
          <Switch>
            <Route exact path="/info" component={HomePage} />
            <Route
              exact
              path="/driver_signup"
              render={(props) => <SignUpDriverPage {...props} isMobile={isMobile} />}
            />
            <Route path="/retailer_signup" render={(props) => <SignUpRetailerPage {...props} isMobile={isMobile} />} />
            <Route path="/driver_login" render={(props) => <SignInRetailerPage {...props} isMobile={isMobile} />} />
            <Route
              path="/driver_forgot_password"
              render={(props) => <NewForgotPassword {...props} isMobile={isMobile} />}
            />{" "}
            <Route path="/driver_cabinet" component={DriverCabinet} />
            <Route path="/driver_registration" component={DriverRegistration} />
            <Route path="/password_recovery" render={(props) => <NewForgotPassword {...props} isMobile={isMobile} />} />
            <Route path="/retailer_registration" component={RetailerRegistration} />
            <Route path="/retailer_login" render={(props) => <SignInRetailerPage {...props} isMobile={isMobile} />} />
            <Route path="/retailer_user_confirmation" component={RetailerConfirmation} />
            <Route
              path="/retailer_cabinet"
              render={() => (
                <RetailerLayout>
                  <Switch>
                    <Route
                      exact
                      path="/retailer_cabinet"
                      render={() => {
                        return <Redirect to="/retailer_cabinet/home" />;
                      }}
                    />
                    <Route
                      exact
                      path="/retailer_cabinet/home"
                      render={(routeProps) => <RetailerCabinet {...routeProps} />}
                    ></Route>
                    {/* TODO: Temporarliy hiding job feature for reatilers */}
                    {/* <Route
                      exact
                      path="/retailer_cabinet/jobs"
                      render={(routeProps) =>
                        isRestricted ? (
                          <RetailerJobManagement {...routeProps} />
                        ) : (
                          <Redirect to="/retailer_cabinet/home" />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/retailer_cabinet/jobs/:id/configure"
                      render={(routeProps) =>
                        isRestricted ? <JobsConfigurePage {...routeProps} /> : <Redirect to="/retailer_cabinet/home" />
                      }
                    />
                    <Route
                      exact
                      path="/retailer_cabinet/jobs/:id/details"
                      render={(routeProps) =>
                        isRestricted ? <JobDetails {...routeProps} /> : <Redirect to="/retailer_cabinet/home" />
                      }
                    ></Route>
                    <Route
                      exact
                      path="/retailer_cabinet/jobs/create"
                      render={(routeProps) =>
                        isRestricted ? <CloudFleetRouting {...routeProps} /> : <Redirect to="/retailer_cabinet/home" />
                      }
                    />
                    <Route
                      exact
                      path="/retailer_cabinet/jobs/combine"
                      render={(routeProps) =>
                        isRestricted ? <CombineJobs {...routeProps} /> : <Redirect to="/retailer_cabinet/home" />
                      }
                    /> */}
                    <Route exact path="/retailer_cabinet/settings/sms">
                      <RetailerPreferences />
                    </Route>
                    <Route exact path="/retailer_cabinet/settings/templates">
                      <RetailerSMS />
                    </Route>
                    <Route exact path="/retailer_cabinet/settings/api-tokens">
                      <RetailerTokens />
                    </Route>
                    <Route exact path="/retailer_cabinet/insights">
                      <RetailerInsights />
                    </Route>
                    <Route exact path="/retailer_cabinet/tickets">
                      <RetailerTickets />
                    </Route>
                    <Route exact path="/retailer_cabinet/reports">
                      <RetailerReports />
                    </Route>
                    <Route exact path="/retailer_cabinet/pickup_points">
                      <RetailerPoints />
                    </Route>
                    <Route exact path="/retailer_cabinet/account">
                      <RetailerAccount />
                    </Route>
                    <Route exact path="/retailer_cabinet/users_management">
                      <RetailerManagment />
                    </Route>

                    <Route
                      exact
                      path="/retailer_cabinet/activity_logs"
                      render={(routeProps) => <RetailerActivity {...routeProps} />}
                    ></Route>
                  </Switch>
                </RetailerLayout>
              )}
            />
            <Route path="/customer/payment-result" component={PaymentsResultPage} />
            <Route path="/tracking_info" component={TrackingInfo} />
            <Route path="/customer" render={getTrackingInfoRedirect} />
            <Route path="/admin_login" component={AdminLogin} />
            <Route path="/super_admin/login" component={SuperAdminLogin} />
            {/* <Route path="/super_admin_dashboard" component={SuperAdminDashBoard} /> */}
            <Route path="/signup" component={AdminSignup} />
            <Route path="/reportPdf" component={ReportPdf} />
            <Route path="/admin" render={() => (isLogin() ? renderMenuForCompany() : <Redirect to="/admin_login" />)} />
            <Route
              path="/super_admin"
              render={() => (!isLogin() ? renderSuperAdminDashboard() : <Redirect to="/super_admin/login" />)}
            />
            <Route path="/">{baseRoute(isMobile)}</Route>
          </Switch>
        </IntlContextProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default Routes;
