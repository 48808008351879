import React from "react";
import { Input, Select } from "antd";
import { debounce } from "lodash";

import "./styles.less";

const { Option } = Select;

export const MobileSearch = ({
  searchFilter,
  placeholder = "Search",
  selectedFilterValue,
  inputValue,
  defaultSearchFilterValue,
  onChangeSearchFilter,
  onSearch,
  onChangeSearchValue,
}) => {
  const selectSearchFilter = (
    <Select
      onChange={onChangeSearchFilter}
      value={selectedFilterValue}
      defaultValue={defaultSearchFilterValue}
      dropdownClassName="mobile-search-dropdown"
    >
      {searchFilter.map(({ value, text }) => (
        <Option value={value} key={value}>
          {text}
        </Option>
      ))}
    </Select>
  );

  const onInputChange = debounce(onSearch, 800);

  return (
    <Input
      placeholder={placeholder}
      addonBefore={selectSearchFilter}
      value={inputValue}
      onChange={(e) => {
        e.persist();
        inputValue !== undefined && onChangeSearchValue(e);
        onInputChange(e);
      }}
      className="mobile-search"
    />
  );
};
