import React, { Fragment } from "react";
import { Popover } from "antd";
import { v4 as uuidv4 } from "uuid";

import "./style.less";

const PickupPointMarker = ({ info, jobId, color, width, height, pickUpPointSymbol }) => {
  const content = (
    <ul className="infoList">
      <li>JobId: {jobId}</li>
      <hr />
      {info?.map((item) => (
        <Fragment key={uuidv4()}>
          <li>OrderId: {item.id}</li>
          <li>Pickup point name: {item.pickupPoint?.name}</li>
          <li>Retailer name: {item.retailer.name}</li>
          <li>Instructions: {item.pickupPoint?.instructions}</li>
          <hr />
        </Fragment>
      ))}
    </ul>
  );

  return (
    <Popover content={content} title="Pickup Point" trigger="click">
      <div
        className="pickupMarker"
        style={{ background: color && "none", width, height, color, border: `2px solid ${color}` }}
      >
        {pickUpPointSymbol && <p style={{ margin: 0 }}>{pickUpPointSymbol}</p>}
      </div>
    </Popover>
  );
};

export default PickupPointMarker;
