import React from "react";
import { Badge, Rate } from "antd";
import { replaceAt } from "../../../../helpers/general";

export default () => {
  return [
    {
      title: "Name",
      key: "name",
      render: ({ profile = {} }) => {
        if (profile) {
          const { firstName } = profile;
          const { lastName } = profile;
          return `${firstName || "No name"} ${lastName || ""}`;
        }
      },
    },
    {
      title: "Car model",
      key: "car",
      render: ({ car = {} }) => {
        if (car) {
          return car.model;
        }
      },
      // dataIndex: 'car',
    },
    {
      title: "Phone number",
      key: "phone",
      render: ({ profile = {} }) => {
        if (profile) {
          const { phoneNumber } = profile;
          return phoneNumber || "";
        }
      },
    },
    {
      title: "Rating",
      key: "averageRating",
      dataIndex: "averageRating",
      render: (averageRating) => {
        return <Rate allowHalf defaultValue={averageRating} disabled />;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Pending", value: "pending" },
        { text: "Accepted", value: "accepted" },
        { text: "Declined", value: "declined" },
        { text: "Has issues", value: "has_issue" },
      ],
      filterMultiple: false,
      render: (data) => {
        if (data === "accepted") {
          return (
            <span>
              <Badge status="success" />
              {data}
            </span>
          );
        }
        if (data === "declined") {
          return (
            <span>
              <Badge status="error" />
              {data}
            </span>
          );
        }
        if (data === "pending") {
          return (
            <span>
              <Badge status="processing" />
              {data}
            </span>
          );
        }
        if (data === "has_issue") {
          return (
            <span>
              <Badge>{replaceAt(data, data.indexOf("_"), " ")}</Badge>
            </span>
          );
        }
      },
      // defaultFilteredValue: ['pending']
    },
  ];
};
