import { notification } from "antd";
import { useState } from "react";
import { API_URL } from "src/constants/config";
import { API_ROLE, getAuthData, getUserTenant } from "src/helpers/authStorage";
import { IUseJobDetailsComp } from "src/types/JobDetails";
import { customFetch } from "src/utils/customFetch";

const getData = async (url = "") => {
  const response = await customFetch(url, {
    method: "GET",
    cache: "no-cache",

    headers: {
      Authorization: `Bearer ${getAuthData().accessToken}`,
      Tenant: getUserTenant(),
      contentType: "application/pdf",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });
  return response.blob();
};

const fetchJobOrderReciept = async (id: any) => {
  const blob = await getData(`${API_URL}/${API_ROLE}/orders/receipts?jobId=${id}`);
  const url = window.URL.createObjectURL(new Blob([blob], { type: "application/pdf" }));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `receipts_${Date.now()}_${id}`);
  document.body.appendChild(link);
  // Force download from link
  link.click();
  // Clean up to remove the link
  link?.parentNode?.removeChild(link);
};

export const useJobDetailsComp: IUseJobDetailsComp = ({ fetchDeliveryJobDetails, cleanDeliveryJobDetails }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchJobDetailsById = async (id: number) => {
    try {
      setIsLoading(true);
      await fetchDeliveryJobDetails({ id }, { query: { attachOrders: true } });
    } catch (error: any) {
      notification.error({ message: "Erro in fetching Job details", description: error?.body?.message });
    } finally {
      setIsLoading(false);
    }
  };

  const downloadJobOrderReciept = async (id: any) => {
    await fetchJobOrderReciept(id);
  };

  const cleanJob = async (id: any) => {
    try {
      await cleanDeliveryJobDetails({}, { body: { jobIds: [id] } });
      notification.success({
        message: "Success",
        description: "Incomplete orders have been removed successfully",
      });
      await fetchDeliveryJobDetails({}, { query: { withError: true } });
    } catch (e: any) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Error on clean jobs.",
      });
    }
  };
  return {
    fetchJobDetailsById,
    downloadJobOrderReciept,
    cleanJob,
  };
};
