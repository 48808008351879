import { notification } from "antd";
import { useEffect, useState } from "react";
import { IFleetTrackingHooksInput } from "./types";

export const useHooks = ({ fetchJobs, getTasks }: IFleetTrackingHooksInput) => {
  const [isJobsFetching, setIsJobsFetching] = useState<boolean>(false);

  const getJobs = async () => {
    try {
      setIsJobsFetching(true);
      await fetchJobs({}, { query: { withError: false } });
    } catch (error) {
      notification.error({ message: "Error", description: "Couldn't fetch active jobs !" });
    } finally {
      setIsJobsFetching(false);
    }
  };

  const getJobTasks = async (job: any) => {
    try {
      const deliveryVehicleId = `${job.id}-${job.driverId}`
      await getTasks({ trackingId: deliveryVehicleId });
    } catch {
      notification.error({ message: "Error", description: "Couldn't fetch tasks for this job !" });
    }
  };

  // TODO change this logique
  useEffect(() => {
    getJobs();
  }, []);
  return { isJobsFetching, getJobTasks };
};
