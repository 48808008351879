import React, { FC } from "react";
import { Button, Modal } from "antd";
import { FormattedMessage } from "react-intl";
import { IJobConfirmationModal } from "../../../../../types/JobManagement";

const ConfirmationModal: FC<IJobConfirmationModal> = ({ isOpen, onCloseModal, cleanAllJobs, selectedJobs }) => {
  return (
    <Modal
      visible={isOpen}
      onCancel={onCloseModal}
      title={<FormattedMessage id="page.jobsManagment.clean" defaultMessage="Clean Jobs" />}
      footer={[
        <Button key="Cancel" onClick={onCloseModal} loading={false}>
          <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
        </Button>,
        <Button key="submit" type="primary" loading={false} onClick={cleanAllJobs}>
          <FormattedMessage id="tabPane.actions.confirm" defaultMessage="Confirm" />
        </Button>,
      ]}
    >
      {selectedJobs > 0 ? (
        <p>Do you really want to clean the {selectedJobs} jobs selected ?</p>
      ) : (
        <p>Do you really want to clean all jobs ?</p>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
