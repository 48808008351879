import React from "react";
import SuperAdminSideBar from "./SideBar/SuperAdminSideBar.js";
import SuperAdminNavBar from "./NavBar/NavBar.js";
import "./index.css";
const SuperAdminLayout = ({ children }) => {
  return (
    <div className="superAdminMain">
      <SuperAdminSideBar />
      {children}
      <SuperAdminNavBar />
    </div>
  );
};

export default SuperAdminLayout;
