/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState, useImperativeHandle } from "react";
import { Button, notification, Row, Table, Divider, Drawer, List, Image, Steps } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Geocode from "react-geocode";
import { formatCompleted } from "../../../helpers/formatStatus";
import { getUserTenant } from "../../../helpers/authStorage";
import { API_KEY } from "../../../constants/config";
import DownloadIcon from "./components/DownloadIcon";
import ActionBtns from "./components/ActionBtns";
import LocationModal from "./components/LocationModal";
import ConfirmModal from "./components/ConfirmModal";
import OrderMap from "./components/OrderMap";
import { PhonePopover } from "./components/PhonePopover";
import "./uploadedOrdersTable.less";
import { COMPLETED_STATUSES } from "../../../constants/query";
import {
  adminItems,
  menuItems,
  deleteItem,
  restoreItem,
  restoreFailedItem,
  returnItem,
  journeyView,
  returnToShipper as returnToShipperAction,
  cancelCompleted,
} from "./components/DropdownMenu";
import useTableHandler from "./hooks/useTableHandler";
import Ticket from "../../Ticket";
import StatusTag from "../../StatusTag";

const { Step } = Steps;
Geocode.setApiKey(API_KEY);

const modalTitle = {
  completeOrder: <FormattedMessage id="modal.completeOrder" defaultMessage="complete order" />,
  removeLocation: <FormattedMessage id="modal.removeLocation" defaultMessage="remove location" />,
  return: <FormattedMessage id="modal.return" defaultMessage="return" />,
  delete: <FormattedMessage id="modal.delete" defaultMessage="delete" />,
  cancel: <FormattedMessage id="modal.cancel" defaultMessage="cancel" />,
  cancelCompleted: <FormattedMessage id="modal.cancel" defaultMessage="cancel" />,
  postpone: <FormattedMessage id="modal.postpone" defaultMessage="postpone" />,
  deleteMany: <FormattedMessage id="modal.delete" defaultMessage="delete" />,
  saveOrders: <FormattedMessage id="modal.save" defaultMessage="save" />,
  restore: <FormattedMessage id="modal.restore" defaultMessage="restore" />,
  restoreFailed: <FormattedMessage id="modal.restore" defaultMessage="restore" />,
  returnToShipper: <FormattedMessage id="modal.returnToShipper" defaultMessage="return to shipper" />,
  createTicket: <span>Create Ticket</span>,
};
const NOT_ALLOWED_TO_CHANGE_LOCATION = ["in_delivery", "arrived", "completed", "deleted"];

const UploadedOrdersTable = React.forwardRef(
  (
    {
      showAllCols = true,
      newUploaded,
      retailer,
      selfStoresSelection,
      selfTemplateSelection,
      ordersList,
      old,
      loading = false,
      pagination,
      onChangeQueryParams,
      onCancelOrder,
      onDeleteOrder,
      onDeleteOrders,
      onPostponeOrder,
      onCreateReturn,
      onCompleteOrder,
      onRemoveLocation,
      onSaveOrders,
      onDeleteLocal,
      onSetSelectedTab,
      onSetDownloadedOrders,
      isAdminOrders,
      onHandleSelectOrder,
      retailers,
      filteredRetailer,
      addLocationToLocal,
      editPhone,
      returnToShipper,
      filteredLocationShared,
      fetchAccordingToTab,
      restoreAdminOrder,
      restoreFailedAdminOrder,
      getReportsAdminOrder,
      pickupFailed,
      updateOrders,
      completedOrdersTab,
      postponedOrdersTab,
      canceledOrdersTab,
      editInitialAddressOrder,
      onCancelCompletedOrder,
      fetchOrderHistory,
      historyArray,
      onSelectRows,
      saveOrders,
      onSaveOrdersDone,
      createTicket,
      onSaveOrderCancel,
      retailerId,
    },
    ref,
  ) => {
    const [isLocationModalOpen, setIsLocationModalOpen] = useState(null);
    const [canEditModal, setCanEditModal] = useState(false);
    const [selectedSharedLocation, setSelectedSharedLocatoin] = useState(null);
    const [reportsDrawer, setReportsDrawer] = useState(false);
    const [reports, setReports] = useState([]);
    const intl = useIntl();

    const {
      handleModalSubmit,
      handleDeleteManyOrders,
      setOpenModal,
      openModal,
      selectedOrders,
      setSelectedOrders,
      historyDrawerOpen,
      setHistoryDrawerOpen,
    } = useTableHandler({
      intl,
      ordersList,
      old,
      retailer,
      onCancelOrder,
      onDeleteOrder,
      onDeleteOrders,
      onPostponeOrder,
      onCreateReturn,
      onCompleteOrder,
      onRemoveLocation,
      onSaveOrders,
      onDeleteLocal,
      onSaveOrdersDone,
      fetchAccordingToTab,
      fetchOrderHistory,
      restoreAdminOrder,
      restoreFailedAdminOrder,
      returnToShipper,
      onSetSelectedTab,
      onSetDownloadedOrders,
      onCancelCompletedOrder,
      createTicket,
      retailerId,
    });
    const steps = useRef(null);

    useImperativeHandle(ref, () => ({
      deleteMany: () => {
        handleDeleteManyOrders(selectedOrders);
      },
    }));

    useEffect(() => {
      setOpenModal({ type: "saveOrders", open: saveOrders });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveOrders]);

    // to get the actions possible according to the status
    const getActionsByState = (status) => {
      switch (status) {
        case "deleted":
          return isAdminOrders ? [journeyView, restoreItem] : [];
        case "completed":
          return isAdminOrders ? [cancelCompleted, returnItem, deleteItem, journeyView] : [returnItem];
        case "return_to_shipper":
          return isAdminOrders ? [journeyView, deleteItem] : [deleteItem];
        case "pickup_failed":
          return isAdminOrders ? [journeyView, restoreFailedItem] : [];
        case "canceled":
        case "canceled_after_pickup":
          return isAdminOrders ? [returnToShipperAction, journeyView, deleteItem] : [deleteItem];
        default:
          return [...menuItems, ...(isAdminOrders ? adminItems : [])];
      }
    };

    useEffect(() => {
      if (steps?.current?.click) {
        steps.current.click();
      }
    }, [historyDrawerOpen]);

    let list = ordersList;
    if (old && ordersList[0]?.items) {
      list = ordersList[0].items;
    }

    const setReportsDrawerOpen = (id) => () => {
      getReportsAdminOrder({ id }).then((results) => {
        setReports(results.body);
        setReportsDrawer(true);
      });
    };

    const retailerNameFilter = retailers?.map((retailer) => {
      return { id: retailer?.id, text: retailer?.name, value: retailer?.name };
    });

    const openDrawer = (item) => {
      setSelectedSharedLocatoin(item);
    };

    const onShareLocation = (condition, order) => () => {
      const { id, locationShared, status } = order;
      if (!NOT_ALLOWED_TO_CHANGE_LOCATION.includes(status)) {
        if (isAdminOrders && !locationShared && old && !NOT_ALLOWED_TO_CHANGE_LOCATION.includes(status)) {
          //TODO : create an api to allow retailers to set the location for an order before removing the isAdminOrders condition
          setCanEditModal(true);
          setIsLocationModalOpen(id);
        } else {
          setCanEditModal(true);
          condition && openDrawer(order);
        }
      } else if (COMPLETED_STATUSES.includes(status) || status === "return_completed" || old) {
        setCanEditModal(false);
        openDrawer(order);
      } else {
        notification.error({
          message: "Error",
          description: "Location of this order can't be changed!",
        });
      }
    };

    const columns = [
      {
        title: `${intl.formatMessage({ id: "tabPane.customer", defaultMessage: "Customer" })}`,
        dataIndex: "customerName",
        key: "customerName",
        responsive: ["md"],
      },
      {
        title: `${intl.formatMessage({ id: "tabPane.orderID", defaultMessage: "Order ID" })}`,
        dataIndex: "orderId",
        key: "orderId",
        width: "85px",
      },
      (showAllCols.all || showAllCols.tracking) &&
        old && {
          title: `${intl.formatMessage({ id: "tabPane.trackingID", defaultMessage: "Tracking ID" })}`,
          dataIndex: "trackingID",
          key: "trackingID",
          width: "170px",
        },
      (showAllCols.all || showAllCols.phone) && {
        title: `${intl.formatMessage({ id: "tabPane.customerPhone", defaultMessage: "Customer Phone" })}`,
        dataIndex: "customerPhoneNumber",
        key: "customerPhoneNumber",
        render: (phone, row) => {
          return (
            <>
              {editPhone ? (
                <PhonePopover
                  oldPhone={phone}
                  orderId={row.id}
                  editPhone={editPhone}
                  fetchAccordingToTab={fetchAccordingToTab}
                />
              ) : (
                <span>{phone}</span>
              )}
            </>
          );
        },
      },
      {
        title: `${intl.formatMessage({ id: "tabPane.packages", defaultMessage: "Packages" })}`,
        dataIndex: "packages",
        key: "packages",
      },
      (showAllCols.all || showAllCols.address) && {
        title: `${intl.formatMessage({ id: "tabPane.address", defaultMessage: "Address" })}`,
        dataIndex: "address",
        key: "address",
      },
      (showAllCols.all || showAllCols.zone) && {
        title: `${intl.formatMessage({ id: "tabPane.zone", defaultMessage: "Zone" })}`,
        dataIndex: "zone",
        key: "zone",
      },
      (showAllCols.all || showAllCols.ticket) &&
        old && {
          title: "Ticket",
          dataIndex: "ticket",
          key: "ticket",
          render: (_, row) => {
            let users = {
              retailer: row?.retailerName,
            };
            return <Ticket buttonType="link" orderId={row.id} jobId={null} ticketDetails={row?.ticket} users={users} />;
          },
        },
      (showAllCols.all || showAllCols.retailer) &&
        old &&
        isAdminOrders && {
          title: `${intl.formatMessage({ id: "tabPane.retailerName", defaultMessage: "Retailer Name" })}`,
          dataIndex: "retailerName",
          key: "retailerName",
          filters: retailerNameFilter,
          filteredValue: filteredRetailer && [filteredRetailer],
          filterMultiple: false,
          render: (retailerName, row) => {
            return (
              <span
                className="orderLink"
                onClick={() => {
                  if (onHandleSelectOrder) {
                    onHandleSelectOrder(row.trackingId);
                  }
                }}
              >
                {retailerName}
              </span>
            );
          },
        },
      !isAdminOrders && {
        title: `${intl.formatMessage({ id: "tabPane.pickupTime", defaultMessage: "Pick Up Time" })}`,
        dataIndex: "deliveryTime",
        key: "deliveryTime",
      },
      {
        title: `${intl.formatMessage({ id: "tabPane.locationShared", defaultMessage: "Location Shared" })}`,
        dataIndex: "locationSharedComponent",
        key: "locationShared",
        filters: [
          {
            text: `${intl.formatMessage({ id: "yes", defaultMessage: "Yes" })}`,
            value: "true",
          },
          {
            text: `${intl.formatMessage({ id: "no", defaultMessage: "No" })}`,
            value: "false",
          },
        ],
        filterMultiple: false,
        filteredValue: filteredLocationShared && [filteredLocationShared],
      },
      old && {
        title: `${intl.formatMessage({ id: "tabPane.orderStatus", defaultMessage: "Order Status" })}`,
        dataIndex: "orderStatus",
        key: "orderStatus",
        width: "120px",
        render: (status) => <StatusTag status={status} />,
      },
      old && {
        title: `${intl.formatMessage({ id: "tabPane.downloadBarcode", defaultMessage: "Barcode" })}`,
        dataIndex: "downloadBarcode",
        key: "downloadBarcode",
        align: "center",
      },
      (showAllCols.all || showAllCols.reports) &&
        pickupFailed && {
          title: `${intl.formatMessage({ id: "tabPane.reports", defaultMessage: "Reports" })}`,
          dataIndex: "orderId",
          key: "orderId",
          align: "center",
          render: (id) => <Button onClick={setReportsDrawerOpen(id)}>Reports</Button>,
        },
      (showAllCols.all || showAllCols.uploadedAt) && {
        title: `${intl.formatMessage({ id: "tabPane.uploadedAt", defaultMessage: "Uploaded at" })}`,
        dataIndex: "uploadedAt",
        key: "uploadedAt",
        align: "center",
      },
      (showAllCols.all || showAllCols.updatedAt) && {
        title: `${intl.formatMessage({ id: "tabPane.updatedAt", defaultMessage: "Updated at" })}`,
        dataIndex: "updatedAt",
        key: "updatedAt",
        align: "center",
      },
      (showAllCols.all || showAllCols.postponedTo) && {
        title: `${intl.formatMessage({ id: "tabPane.postponedTo", defaultMessage: "Postponed to" })}`,
        dataIndex: "postponedTo",
        key: "postponedTo",
        align: "center",
      },
      {
        title: `${intl.formatMessage({ id: "tabPane.actions", defaultMessage: "Actions" })}`,
        dataIndex: "actions",
        key: "actions",
      },
      { dataIndex: "isReturn", key: "isReturn", render: (isReturn) => (isReturn ? <b>R</b> : "") },
    ].filter(Boolean);

    const data = list?.map((order) => {
      const isLocationShared = old ? order?.locationShared : order.deliveryPoint?.lat && order.deliveryPoint?.long;

      return {
        ...order,
        orderId: order.id,
        package: order.packages[0]?.no,
        customerName: order.customerInfo?.name,
        zone: order.deliveryPoint?.initialAddress?.zone,
        address: order.deliveryPoint?.address ?? order.customerInfo?.initialAddress?.address,
        trackingID: order.trackingId,
        customerPhoneNumber: order.customer?.phoneNumber,
        packages: order.packages?.length,
        uploadedAt: moment(order.createdAt).format("DD.MM.YYYY HH:mm"),
        updatedAt: moment(order.updatedAt).format("DD.MM.YYYY HH:mm"),
        postponedTo: order.postponedTime ?? "",
        ...(old && { retailerName: order.retailer?.name }),
        ...(!isAdminOrders && { deliveryTime: order?.deliveryTime }),
        locationSharedComponent: isLocationShared ? (
          <span onClick={onShareLocation(true, order)} className="orderLink">
            <FormattedMessage id="YES" defaultMessage="YES" />
          </span>
        ) : (
          <span onClick={onShareLocation(false, order)} className="orderLink">
            <FormattedMessage id="NO" defaultMessage="NO" />
          </span>
        ),
        orderStatus: formatCompleted(order),
        ...(old && {
          downloadBarcode: <DownloadIcon pdfLink={order?.packages[0]?.receipt?.url} />,
        }),
        actions: (
          <ActionBtns
            id={order.id}
            onSetModal={setOpenModal}
            old={old}
            newStyle={true}
            onDeleteLocal={onDeleteLocal}
            isAdminOrders={isAdminOrders}
            providedActions={getActionsByState(order?.status)}
            isReturn={order.isReturn}
          />
        ),
        key: order?.id,
      };
    });

    const rowSelection = {
      onChange: (selectedRowKeys) => {
        setSelectedOrders(selectedRowKeys);
        onSelectRows(selectedRowKeys);
      },
    };

    const getMapData = (query, type) => {
      switch (type) {
        case "address":
          return geocodeByAddress(query.address).then(
            (result) => {
              return getLatLng(result[0]).then(
                (latLng) => {
                  return {
                    address: query.address,
                    lat: latLng?.lat,
                    long: latLng?.lng,
                    formatted_address: result[0].formatted_address,
                  };
                },
                () => {
                  notification.error({
                    message: "Error",
                    description: "no coords match this address",
                  });
                  return null;
                },
              );
            },
            () => {
              notification.error({
                message: "Error",
                description: "no such address",
              });
              return null;
            },
          );
        case "coords":
          return Geocode.fromLatLng(query.lat, query.long).then(
            (response) => {
              return { address: response.results[0].formatted_address, lat: query.lat, long: query.long };
            },
            () => {
              return { address: "unknown place", lat: query.lat, long: query.long };
            },
          );
        default:
          return null;
      }
    };

    return (
      <div ref={ref}>
        <Row
          className={`actions ${!isAdminOrders && !newUploaded && "orders-actions-single-line"} ${
            !isAdminOrders &&
            !newUploaded &&
            !completedOrdersTab &&
            !canceledOrdersTab &&
            !postponedOrdersTab &&
            "orders-actions-single-line-nofilters"
          }`}
        >
          {/* {!old && !!data.length && (
            <Button onClick={() => setOpenModal({ type: "saveOrders", open: true })}>
              <FormattedMessage id="button.tab.confirm&UploadOrders" defaultMessage="Confirm and upload all orders" />
            </Button>
          )} */}
        </Row>
        <Row className="selectedItemsWrapper">
          {selectedOrders?.length ? (
            <>
              <FormattedMessage
                id="tab.chosen.orders"
                defaultMessage={`${selectedOrders?.length} orders are selected`}
                values={{ number: selectedOrders?.length }}
              />
              <Button
                size="small"
                icon={<CloseOutlined />}
                onClick={() => {
                  setSelectedOrders([]);
                }}
                style={{ marginLeft: "7px" }}
              >
                Clear all
              </Button>
            </>
          ) : null}
        </Row>
        <Row className="TableWrapper">
          <Table
            columns={columns}
            dataSource={data}
            pagination={pagination?.total || pagination?.totalItems ? { ...pagination } : {}}
            onChange={onChangeQueryParams && onChangeQueryParams}
            loading={loading}
            rowSelection={
              !(completedOrdersTab && !isAdminOrders)
                ? {
                    selectedRowKeys: selectedOrders,
                    type: "checkbox",
                    ...rowSelection,
                  }
                : false
            }
          />
        </Row>

        <LocationModal
          isLocationModalOpen={isLocationModalOpen}
          setIsLocationModalOpen={setIsLocationModalOpen}
          addLocationToLocal={addLocationToLocal}
          old={old}
          updateOrders={updateOrders}
          getMapData={getMapData}
          getUserTenant={getUserTenant}
        />
        <ConfirmModal
          // isNewOrders={newUploaded}
          selfStoresSelection={selfStoresSelection}
          selfTemplateSelection={selfTemplateSelection}
          openModal={openModal}
          modalTitle={modalTitle}
          handleModalSubmit={handleModalSubmit}
          setOpenModal={setOpenModal}
          loading={loading}
          isAdminOrders={isAdminOrders}
          orders={data}
          retailer={retailer}
          onSaveOrderCancel={onSaveOrderCancel}
        />
        {selectedSharedLocation && (
          <OrderMap
            selectedSharedLocation={selectedSharedLocation}
            disabled={!canEditModal || !isAdminOrders || !old}
            onClose={() => openDrawer(null)}
            updateLocation={addLocationToLocal}
            updateOrders={updateOrders}
            getMapData={getMapData}
            editInitialAddressOrder={editInitialAddressOrder}
          />
        )}
        {reportsDrawer && (
          <Drawer
            width="80vw"
            placement="right"
            closable
            onClose={() => setReportsDrawer(false)}
            visible={reportsDrawer}
          >
            <h3>Order #{reports[0]?.order}</h3>
            <List
              itemLayout="vertical"
              size="large"
              dataSource={reports}
              renderItem={(item) => (
                <List.Item
                  key={item.id}
                  extra={
                    <div className="reports-image-container">
                      <Image.PreviewGroup>
                        {item.attachments.map((e) => (
                          <>
                            <Image key={e.id} width={200} alt="img" src={e.attachment.url} />
                          </>
                        ))}
                      </Image.PreviewGroup>
                    </div>
                  }
                >
                  <List.Item.Meta
                    // avatar={<Avatar src={item.avatar} />}
                    title={item.reason}
                    description={item.message}
                  />
                  {/* {item.content} */}
                  <div>
                    <b>ReportId:</b> <br /> {item.id}
                  </div>
                  <div>
                    <b>DriverId:</b> <br /> {item.driver}
                  </div>
                  <div>
                    <b>Created at:</b>
                    <br /> {item.createdAt}
                  </div>
                  <div>
                    <b>Updated at:</b>
                    <br /> {item.updatedAt}
                  </div>
                  <Divider />
                </List.Item>
              )}
            />
          </Drawer>
        )}
        <Drawer
          style={{ zIndex: 1050 }}
          width="300px"
          placement="right"
          closable
          onClose={() => setHistoryDrawerOpen(false)}
          visible={historyDrawerOpen}
          className="historyDrawer"
        >
          <h3>Order #{historyArray[0]?.order?.id}</h3>
          {historyArray?.length > 0 ? (
            <Steps ref={steps} autoFocus progressDot current={historyArray?.length} direction="vertical">
              {historyArray.map((e) => {
                const date = moment(e.createdAt).local().toISOString(true);
                const dateUpdated = e.updatedAt ? moment(e.updatedAt).local().toISOString(true) : null;
                return (
                  <Step
                    style={{ width: "100%" }}
                    title={<div className="historyDrawer-step-title">{e.status.replace("_", " ")}</div>}
                    subTitle={
                      <div>
                        {e.driver?.id && (
                          <div>
                            <b>DriverId:</b> {e.driver?.id}
                          </div>
                        )}
                        <div>
                          <b>Date:</b> {date.split("T")[0]} {date.split("T")[1].slice(0, -13)}
                        </div>
                        {e.updatedAt && (
                          <div>
                            <b>Date updated:</b> {dateUpdated.split("T")[0]} {dateUpdated.split("T")[1].slice(0, -13)}
                          </div>
                        )}
                      </div>
                    }
                  />
                );
              })}
            </Steps>
          ) : (
            "Nothing to show"
          )}
        </Drawer>
      </div>
    );
  },
);

export default UploadedOrdersTable;
