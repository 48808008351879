import React, { FC, useState } from "react";
import { debounce } from "lodash";
import { Button, Row, Tooltip } from "antd";
import { ClearOutlined, PrinterOutlined } from "@ant-design/icons";

import JobsList from "./components/JobsList";
import OrderDetails from "./components/OrderDetails";
import { CustomCard } from "../../../utils/common";
import ConfirmationModal from "./components/ConfrimModal/ConfirmModal";
import ConfirmationPrintModal from "./components/ConfrimModal/ConfirmPrintModal";
import { IJobDesktopViewProps, TJobExpandedRowKey } from "../../../types/JobManagement";
import Search from "antd/lib/input/Search";
import { useHistory } from "react-router-dom";
import { BASE_ROUTE } from "src/routes";

export const DesktopView: FC<IJobDesktopViewProps> = (props) => {
  const {
    isAdmin,
    handlePublishJob,
    downloadJobOrdersBarcodes,
    cleanJob,
    onJobsSelected,
    selectedOrder,
    centerCoordinates,
    withErrors,
    selectedJobs,
    setSelectedOrder,
    updateOrderCash,
    cleanAllJobs,
    printAllJobs,

    // actions
    fetchDeliveryJobs,
    getOneDeliveryJob,
    getRoutesDeliveryJob,
  } = props;

  const navigate = useHistory();
  const [expandedRowKeys, setExpandedRowKeys] = useState<TJobExpandedRowKey>(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const [isPrintConfirmationOpen, setPrintConfirmationOpen] = useState<boolean>(false);

  const handleSearchByOrderId = (value: string): void => {
    debounce(
      () =>
        fetchDeliveryJobs({}, { query: { trackingId: value, withError: withErrors, attachOrders: true } }).then(() => {
          if (expandedRowKeys) {
            getOneDeliveryJob({ id: expandedRowKeys }, { query: { attachOrders: true } });
            getRoutesDeliveryJob({ jobId: expandedRowKeys });
          }
        }),
      1000,
    )();
  };

  return (
    <div>
      <Row className="job-actions-row">
        <div className="left-Job-actions">
          <Search placeholder="Tracking ID" onSearch={handleSearchByOrderId} className="search-jobs" allowClear />

          <Tooltip title="Print Job">
            <Button
              className="round-btn"
              shape="circle"
              icon={<PrinterOutlined />}
              disabled={selectedJobs?.length < 1}
              onClick={() => setPrintConfirmationOpen(true)}
            />
          </Tooltip>
          <Tooltip title="Clean Job">
            <Button
              className="round-btn"
              shape="circle"
              icon={<ClearOutlined />}
              disabled={selectedJobs?.length < 1}
              onClick={() => setConfirmationOpen(true)}
            />
          </Tooltip>
        </div>

        <div className="job-actions">
          <Button type="default" onClick={() => navigate.push(`/${BASE_ROUTE}/jobs/combine`)}>
            Combine Job
          </Button>
          <Button type="primary" onClick={() => navigate.push(`/${BASE_ROUTE}/jobs/create`)}>
            Create New Job
          </Button>
        </div>
      </Row>
      <CustomCard>
        <JobsList
          isAdmin={isAdmin}
          setSelectedOrder={setSelectedOrder}
          handlePublishJob={handlePublishJob}
          setExpandedRowKeys={setExpandedRowKeys}
          expandedRowKeys={expandedRowKeys}
          downloadJobOrdersBarcodes={downloadJobOrdersBarcodes}
          withErrors={withErrors}
          selectedJobs={selectedJobs}
          onJobsSelected={onJobsSelected}
          cleanJob={cleanJob}
        />
        <OrderDetails
          selectedOrder={selectedOrder}
          centerCoordinates={centerCoordinates}
          onClose={() => setSelectedOrder(null)}
          updateOrderCash={updateOrderCash}
          setExpandedRowKeys={setExpandedRowKeys}
          selectedJobId={expandedRowKeys}
        />
      </CustomCard>
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        selectedJobs={selectedJobs?.length}
        onCloseModal={() => setConfirmationOpen(false)}
        cleanAllJobs={() => {
          cleanAllJobs();
          setConfirmationOpen(false);
        }}
      />
      <ConfirmationPrintModal
        isOpen={isPrintConfirmationOpen}
        selectedJobs={selectedJobs?.length}
        onCloseModal={() => setPrintConfirmationOpen(false)}
        printAllJobs={() => {
          printAllJobs();
          setPrintConfirmationOpen(false);
        }}
      />
    </div>
  );
};
