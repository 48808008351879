import React, { useState, useEffect } from "react";
import { Drawer, Input, Radio, Button, Divider, DatePicker } from "antd";

import "./style.less";

const { RangePicker } = DatePicker;

export default function DrawerComponent({
  profile,
  stats,
  accounting,
  handleAddBracket,
  handleRemoveBracket,
  handleSetCost,
  setDrawerVisible,
  drawerVisible,
  isRetailer,
  isMobile,
  changeAccountingType,
  handleChangeBracket,
  loadStatsWithDate,
  datepickerRange,
  setDatepickerRange,
}) {
  const [newRule, setNewRule] = useState({ to: 0, cost: 0 });
  const [cost, setCost] = useState(accounting?.cost);

  useEffect(() => {
    setCost(accounting?.cost);
    //eslint-disable-next-line
  }, [accounting?.cost]);

  useEffect(() => {
    //eslint-disable-next-line
  }, [stats]);

  const handleAccountingType = (type) => {
    changeAccountingType(accounting, type);
  };

  const handleStatDate = (range) => {
    setDatepickerRange(range);
    loadStatsWithDate(profile.id, range);
  };

  const handleAddRule = () => {
    if (newRule.cost >= 0 && newRule.to > 0) {
      handleAddBracket(profile, newRule);
    }
  };
  const handleRemoveRule = (id) => {
    handleRemoveBracket(profile, id);
  };
  const handleRuleCostChange = (bracket, value) => {
    const newBracket = { id: bracket, cost: value };
    handleChangeBracket(profile, newBracket);
  };
  const handleCostInput = (value) => {
    setCost(parseFloat(value));
  };
  const handleCostSubmit = (accounting) => {
    handleSetCost(accounting, cost);
  };

  return (
    <Drawer width={"80vw"} placement="right" closable onClose={() => setDrawerVisible(false)} visible={drawerVisible}>
      <Divider />
      <h2>{profile?.name || profile?.profile?.firstName}'s accounting</h2>
      <Divider />
      <Radio.Group value={accounting?.type} onChange={(e) => handleAccountingType(e.target.value)} buttonStyle="solid">
        <Radio.Button value="flat_fee">Flat Fee</Radio.Button>
        <Radio.Button value="brackets" disabled={isRetailer}>
          Bracket
        </Radio.Button>
      </Radio.Group>
      <div className="accounting-stats">
        <RangePicker value={datepickerRange} onChange={handleStatDate} />
        <div>
          <span style={{ margin: 0 }}>Total cash collected: {stats?.total || 0}$</span>
          <br/>
          <span style={{ marginBottom: 20 }}>Total orders completed: {stats?.completedOrders || 0}</span>
        </div>
      </div>

      <Divider />
      {accounting?.type === "brackets" ? (
        <div className="accountingRules">
          <h3>Rules:</h3>
          {accounting.brackets?.map((e, i) => (
            <div className={isMobile ? "accountingRule accountingRule__mobile" : "accountingRule"} key={e.id}>
              {/* <span>From: {e.from} </span> */}
              <span>To: {e.to}</span>
              <span style={{ display: "flex", alignItems: "center" }}>
                Cost:
                <Input
                  type="number"
                  style={{ width: "100px" }}
                  value={e.cost}
                  onChange={(event) => handleRuleCostChange(e.id, parseFloat(event.target.value))}
                />{" "}
              </span>
              <Button onClick={() => handleRemoveRule(e.id)} danger disabled={i !== accounting.brackets?.length - 1}>
                Delete
              </Button>
            </div>
          ))}
          <div className="">
            Cost over {accounting.brackets?.length > 0 ? accounting?.brackets[accounting.brackets?.length - 1].to : 0}{" "}
            km is{" "}
            <Input
              type="number"
              value={cost}
              size="small"
              onChange={(e) => handleCostInput(e.target.value)}
              style={{ width: "150px" }}
            />{" "}
            $ per km
            <Button size="small" onClick={() => handleCostSubmit(accounting)}>
              Submit
            </Button>
          </div>
          <Divider />
          <div className="ruleForm">
            <h3>Add rule:</h3>
            {/* From:
            <Input type="number" placeholder="from" value={rules[rules.length - 1]?.to || 0} onChange={(e) => {}} /> */}
            To:
            <Input
              type="number"
              value={newRule.to}
              onChange={(e) => {
                setNewRule({ to: parseFloat(e.target.value), cost: newRule.cost });
              }}
              placeholder="to"
            />
            Cost:
            <Input
              type="number"
              value={newRule.cost}
              onChange={(e) => {
                setNewRule({ to: newRule.to, cost: parseFloat(e.target.value) });
              }}
              placeholder="cost"
            />
            <Button onClick={handleAddRule}>Add rule</Button>
          </div>
        </div>
      ) : (
        <>
          <Input
            type="number"
            value={cost}
            onChange={(e) => handleCostInput(e.target.value)}
            style={{ width: "150px" }}
          />{" "}
          $ per km{" "}
          <Button size="small" onClick={() => handleCostSubmit(accounting)}>
            Submit
          </Button>
        </>
      )}
      <Divider />
    </Drawer>
  );
}
