import { notification } from "antd";
import { IUseHooks } from "../types/store-management";

const useHook = ({ fetchAdminRetailers, addOneAdminRetailers }: IUseHooks) => {
  const getRetailers = async (searchedValue: { value?: string; field?: string }, query: any) => {
    try {
      if (searchedValue.value && searchedValue.field) {
        await fetchAdminRetailers(
          {},
          { query: { [searchedValue.field]: searchedValue.value, page: query.page, limit: query.limit } },
        );
      } else {
        await fetchAdminRetailers({}, { query: { page: query.page, limit: query.limit } });
      }
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.message ?? "Unexpected error has occured while fetching retailers !",
      });
    }
  };

  const addRetailer = async (values: any) => {
    try {
      await addOneAdminRetailers({ ...values }, {});
      notification.success({
        description: "The new retailer has been successfully created , you can add pickups point now !",
        message: "New retailer created",
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: "Unexpected error has occured while creating retailer !",
      });
    }
  };

  return {
    getRetailers,
    addRetailer,
  };
};

export default useHook;
