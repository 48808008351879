import { useState } from "react";

import { notification } from "antd";
import { API_URL } from "../../../../../constants/config";
import { getAuthData, getUserTenant } from "../../../../../helpers/authStorage";

const initialDates = { to: "", from: "" };

export default function ({ onClose }) {
  const [dates, setDates] = useState(initialDates);
  const [rangeDateType, setRangeDateType] = useState("created_at");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [retailer, setRetailer] = useState(null);

  const disabledTomorrowAndFuture = (current) => {
    return current > new Date();
  };

  const handleSetDates = (dates, momentDates) => {
    setDates(dates);
  };

  const generateReport = async () => {
    let fromDate = new Date(dates[0]);
    // fromDate.setHours(0, 0, 0, 0);
    fromDate = fromDate.toISOString().substring(0, 10);
    let toDate = new Date(dates[1]);
    // toDate.setHours(23, 59, 59, 999);
    toDate = toDate.toISOString().substring(0, 10);

    let retailerQuery = "";
    if (retailer) {
      retailerQuery = `&retailerId=${retailer}`;
    }
    let statusQuery = "";
    if (selectedStatus && selectedStatus !== "all") {
      statusQuery = `&orderStatus=${selectedStatus}`;
    }

    try {
      const filtersQuery = new URLSearchParams({
        range: rangeDateType,
        from: `${fromDate}T00:00:00.000`,
        to: `${toDate}T23:59:59.000`,
      });
      const response = await fetch(
        `${API_URL}/admin/system-reports/generate?${filtersQuery}${retailerQuery}${statusQuery}`,
        {
          method: "GET",
          cache: "no-cache",
          headers: {
            Authorization: `Bearer ${getAuthData().accessToken}`,
            Tenant: getUserTenant(),
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
        },
      );
      if (!response.ok) {
        const data = await response.json();
        notification.error({
          message: "Error",
          description: data.message ? data.message : "Error wile generating report",
        });
        return;
      }
      setIsLoading(true);

      const blob = await response.blob();
      const newBlob = new Blob([blob]);

      const blobUrl = window.URL.createObjectURL(newBlob);

      const link = document.createElement("a");
      link.href = blobUrl;
      let fileName = "report.xlsx";
      let disposition = response.headers.get("Content-Disposition");
      if (disposition && disposition.indexOf("attachment") !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, "");
        }
      }
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      window.URL.revokeObjectURL(blob);
    } catch (error) {
      try {
        const data = await error.json();
        notification.error({
          message: "Error",
          description: data?.message || "Error while generating report",
        });
      } catch {
        notification.error({
          message: "Error",
          description: "Unknown Error while generating report",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseModal = () => {
    setDates(initialDates);
    onClose();
  };

  return {
    dates,
    disabledTomorrowAndFuture,
    handleSetDates,
    generateReport,
    onCloseModal,
    isLoading,
    setRetailer,
    rangeDateType,
    setRangeDateType,
    selectedStatus,
    setSelectedStatus,
  };
}
