import React from "react";
import { DesktopViewProps } from "../../types";
import { CustomCard } from "src/utils/common";
import { Button, Row, Space, Table } from "antd";
import { EmptyTableIndicator } from "src/components";
import { CreditCardOutlined } from "@ant-design/icons";
import tableColumns from "./columns";
import "../../styles/desktop-view.less";
import { IPriceSetting } from "src/entities/price-setting";

const sortCompanyPricesListMethodById = (a: IPriceSetting, b: IPriceSetting) => a.id - b.id;

const DesktopView: React.FC<DesktopViewProps> = ({
  companyPricesList,
  editedSetting,
  handleSavePriceSetting,
  onOpenCreateSettingModal,
  handleDeleteSetting,
  IsEditing,
  setEditedSetting,
}) => {
  return (
    <>
      <div className="page-wrapper-price-settings">
        <CustomCard
          title="Price Management"
          toolbar={
            <Row justify="end">
              <Button icon={<CreditCardOutlined />} type="primary" onClick={onOpenCreateSettingModal}>
                Add New Setting
              </Button>
            </Row>
          }
        >
          <div className="scrollable-table">
            <Table
              className="table-style"
              rowKey={(setting) => setting.id}
              columns={tableColumns({
                handleDeleteSetting,
                IsEditing,
                setEditedSetting,
                editedSetting,
                handleSavePriceSetting,
              })}
              scroll={{ x: true }}
              dataSource={companyPricesList.sort(sortCompanyPricesListMethodById)}
              locale={{
                emptyText: (
                  <EmptyTableIndicator title="No settings found" description="Please add price management settings" />
                ),
              }}
            />
          </div>
        </CustomCard>
      </div>
    </>
  );
};

export default DesktopView;
