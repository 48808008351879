import React, { useState, useEffect } from "react";
import { Divider, Radio, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import { FormattedMessage } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions as RetailerActions } from "../../../redux/resources/retailer";
import CustomDivider from "../../../components/Divider";

const RetailerPreferences = ({ retailer, putSelfInfoRetailer, getSelfInfoRetailer }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSelfInfoRetailer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangeProvider = (event) => {
    setLoading(true);
    putSelfInfoRetailer({ smsProvider: event.target.value }).then(
      () => {
        getSelfInfoRetailer().then(() => {
          setLoading(false);
        });
      },
      () => {
        setLoading(false);
      },
    );
  };
  return (
    <Content className="retailerCabinetContent retailerSettingsContent retailerSMS" style={{ paddingTop: 0 }}>
      <h2 className="pageTitle">
        <FormattedMessage id="page.home.settings.preferences.heading" defaultMessage="SMS Type" />{" "}
      </h2>
      <CustomDivider />
      <Spin spinning={loading}>
        <Divider />
        <Radio.Group onChange={handleChangeProvider} value={retailer?.smsProvider}>
          <div style={{ gap: 18, display: "flex", flexDirection: "column", margin: "32px 0px" }}>
            <Radio style={{ gap: 10 }} value="base-sms">
              SMS
            </Radio>
            <Radio style={{ gap: 10 }} value="whatsapp">
              WhatsApp
            </Radio>
          </div>
        </Radio.Group>
      </Spin>
    </Content>
  );
};

const MSTP = (state) => {
  return { retailer: state.retailer.retailerData };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(RetailerActions, dispatch),
}))(RetailerPreferences);
