import React, { createContext, ReactNode, useContext, useState } from "react";

interface IBreadcrumbProviderProps {
  children: ReactNode | undefined;
}

interface IBreadcrumbContextProps {
  items: IPath[];
  addPath: (item: IPath) => void;
  addAllPaths: (item: IPath[]) => void;
}

export interface IPath {
  name: string;
  path: string;
  icon?: string;
}

const defaultBreadCrumbContext = {
  items: [{ name: "home", path: "/" }],
  addPath: (item: IPath) => {},
  addAllPaths: (item: IPath[]) => {},
};

const BreadcrumbContext = createContext<IBreadcrumbContextProps>(defaultBreadCrumbContext);

export const BreadcrumbProvider = ({ children }: IBreadcrumbProviderProps) => {
  const [breadcrumbs, setBreadcrumbs] = useState<IPath[]>([{ name: "home", path: "/" }]);

  // TODO add more logique here
  const addPath = (item: IPath) => {
    setBreadcrumbs([...breadcrumbs, item]);
  };

  const addAllPaths = (items: IPath[]) => {
    setBreadcrumbs(items);
  };

  const context: IBreadcrumbContextProps = {
    items: breadcrumbs,
    addPath: addPath,
    addAllPaths: addAllPaths,
  };

  return <BreadcrumbContext.Provider value={context}>{children}</BreadcrumbContext.Provider>;
};

export const useBreadcrumbContext = () => useContext(BreadcrumbContext);
