import React, { useState, useEffect, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, useHistory } from "react-router-dom";
import { Badge, Layout, Menu, Space, Modal, Button } from "antd";
import { debounce } from "lodash";

import { useNotifications, useMessages } from "./hooks";
import { actions as adminActions } from "../../redux/resources/admin";
import { ReactComponent as BeeboltWhiteLogo } from "../../assets/header-logo.svg";
import { ReactComponent as BoltSVG } from "../../assets/adminPanel/bolt.svg";
import { MobileLayout } from "./MobileLayout";
import { getRole } from "../../routes/index";


import "./AdminLayout.less";
import LogsWrapper from "../../components/Logs";
import Header from "./Header";
import {
  BellFilled,
  CarFilled,
  CreditCardFilled,
  DashboardFilled,
  EditFilled,
  EnvironmentFilled,
  IdcardFilled,
  MessageFilled,
  ProfileFilled,
  ReconciliationFilled,
  RocketFilled,
  ShopFilled,
  TeamOutlined,
  ContainerFilled,
  GoldFilled,
  CreditCardOutlined,
} from "@ant-design/icons";
import useBreadcrumbManager from "../BreadCrumpManager/useBreadCrumpManager";
import { API_URL } from "src/constants/config";

const { Content, Sider } = Layout;

export const checkMenuItem = (rights, Page, isOutsiderCompany, { showAlways }) => {
  // common items that doesn't require a role
  if (showAlways) return Page;
  // pages that will be displayed ( accessible ) only for Dliv admins
  if (isOutsiderCompany && DLIV_MENU_ITEMS.includes(Page?.key)) return;
  switch (rights) {
    case "edit":
      return Page;
    case "view":
      return Page;
    default:
      return;
  }
};

// pages that will be exposed only for Dliv admins
const DLIV_MENU_ITEMS = [
  "notifications",
  "preferences",
  "environment_stats",
  "system_report",
  "drivers_stats",
  "zones_manager",
  "retailers_groups",
  // TODO : hide temp
  "delivery_reports",
  "reports",
  "system_reports",
];

const AdminLayout = ({
  children,
  notifications,
  logoutAdmin,
  setIsMobileAdmin,
  subscribeToPushAdmin,
  unsubscribeFromPushAdmin,
  isOutsiderCompany,
}) => {
  const [isAccountDisabledModalVisible, setAccountDisabledModalVisible] = useState(false);

  useEffect(() => {
    const makeSureCompanySTatus = JSON.parse(localStorage.getItem("authData"));
    const fetchCompanyStatus = async () => {
      const data = await fetch(`${API_URL}/superadmin/check_if_company_active/${makeSureCompanySTatus?.companyId}`);
      const result = await data.json();

      localStorage.setItem("isCompanyActive", result);
    };

    fetchCompanyStatus();
  }, []);

  // const notifications = useSelector(({ notifications }) => notifications);
  const isMobile = useSelector(({ admin }) => admin.isMobile);
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  useBreadcrumbManager(); //TODO change logique of items updates
  useNotifications();
  const { token } = useMessages({ subscribeToPushAdmin, unsubscribeFromPushAdmin });

  const menuItems = useCallback(
    ({ notifications }) => [
      {
        key: "dashboard",
        icon: <DashboardFilled />,

        label: <Link to="/admin/dashboard">Dashboard</Link>,
      },
      {
        key: "cloud_fleet_routing",
        icon: <RocketFilled />,
        label: (
          <Badge count={isOutsiderCompany ? 0 : notifications?.ordersUnassigned} showZero={false}>
            <Link to="/admin/jobs/create">Cloud Fleet Routing</Link>
          </Badge>
        ),
      },
      {
        key: "delivery_manager",
        icon: <ReconciliationFilled />,

        label: (
          <Badge count={isOutsiderCompany ? 0 : notifications?.jobsUnassigned} showZero={false}>
            <Link to="/admin/jobs">Job Management</Link>
          </Badge>
        ),
      },
      {
        key: "orders_managment",
        icon: <BoltSVG />,
        label: <Link to="/admin/orders_managment">Order Management</Link>,
      },
      {
        key: "drivers_manager",
        icon: <IdcardFilled />,

        label: <Link to="/admin/drivers_manager">Driver Management</Link>,
      },
      {
        key: "zones_manager",
        icon: <EditFilled />,

        label: <Link to="/admin/zones_manager">Zone creation</Link>,
      },
      {
        key: "drivers_stats",
        icon: <BoltSVG />,

        label: <Link to="/admin/drivers_stats">Drivers Stats</Link>,
      },
      {
        key: "payments",
        icon: <CreditCardFilled />,

        label: <Link to="/admin/payments">Payments</Link>,
      },
      {
        key: "invoices",
        icon: <ContainerFilled />,

        label: <Link to="/admin/invoices">Invoices</Link>,
      },
      {
        key: "cash_to_be_collected",
        icon: <GoldFilled />,
        label: <Link to="/admin/cash_to_be_collected">Cash To Be Collected</Link>,
      },
      {
        key: "retailers",
        icon: <ShopFilled />,

        label: <Link to="/admin/retailers">Retailers Management</Link>,
      },
      {
        key: "retailers_groups",
        icon: <BoltSVG />,

        label: <Link to="/admin/retailers_groups">Retailers Groups</Link>,
      },
      {
        key: "accounting",
        icon: <BoltSVG />,

        label: <Link to="/admin/accounting">Accounting</Link>,
      },
      {
        key: "system_report",
        icon: <ProfileFilled />,

        label: <Link to="/admin/system_report">System Report</Link>,
      },
      {
        key: "environment_stats",
        icon: <BoltSVG />,

        label: <Link to="/admin/environment_stats">Environment stats</Link>,
      },
      {
        key: "delivery_reports",
        icon: <ProfileFilled />,

        label: <Link to="/admin/delivery_reports">Delivery Reports</Link>,
      },
      {
        key: "tickets",
        icon: <MessageFilled />,

        label: (
          <Badge count={isOutsiderCompany ? 0 : notifications?.unreadTicketMessages} showZero={false}>
            <Link to="/admin/tickets">Tickets</Link>
          </Badge>
        ),
      },
      {
        key: "drivers_map",
        children: [
          {
            key: "jobs_map",
            icon: <EnvironmentFilled />,

            label: <Link to="/admin/tracking_map">Jobs Map</Link>,
          },
          {
            key: "drivers_tracking_map",
            icon: <CarFilled />,

            label: <Link to="/admin/drivers_map">Driver Map</Link>,
            type: "sub",
          },
        ],
        label: "Fleet Tracking",
      },
      {
        key: "notifications",
        icon: <BellFilled />,

        label: (
          <Badge count={isOutsiderCompany ? 0 : notifications?.notificationsCount} showZero={false}>
            <Link to="/admin/notifications">Notifications</Link>
          </Badge>
        ),
      },
      {
        key: "reports",
        icon: <BoltSVG />,

        label: <Link to="/admin/reports">Reports</Link>,
      },
      {
        key: "user_management",
        icon: <TeamOutlined />,

        label: <Link to="/admin/user_management">User Management</Link>,
      },
      // {
      //   key: "preferences",
      //   icon: <BoltSVG />,

      //   label: <Link to="/admin/preferences">Settings</Link>,
      // }
      // {
      //   key: "price_management",
      //   icon: <CreditCardOutlined />,

      //   label: <Link to="/admin/price-settings">Price Management</Link>,
      // },
    ],
    // eslint-disable-next-line
    [notifications, getRole()],
  );

  const renderMenuItems = (array, role) => {
    const items = array
      .map((item) => {
        return checkMenuItem(role[item.key], item, isOutsiderCompany, { showAlways: item.showAlways ?? false });
      })
      .filter((item) => item !== undefined);
    return items;
  };
  // eslint-disable-next-line
  const [menuItemsState, setMenuItemsState] = useState(renderMenuItems(menuItems({ notifications }), getRole()));
  useEffect(() => {
    setMenuItemsState(renderMenuItems(menuItems({ notifications }), getRole()));
    // eslint-disable-next-line
  }, [notifications]);

  const handleWidth = useCallback(() => {
    const clientWidth = window.innerWidth;

    if (clientWidth <= 768) {
      setIsMobileAdmin(true);
    } else {
      setIsMobileAdmin(false);
    }
  }, [setIsMobileAdmin]);

  useEffect(() => {
    handleWidth();

    window.addEventListener("resize", debounce(handleWidth, 100, { maxWait: 200 }));

    return () => window.removeEventListener("resize", handleWidth);
  }, [handleWidth]);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const logout = () => async () => {
    if (token) {
      console.log("unsubscribe", token);
      await unsubscribeFromPushAdmin({ token });
    }
    logoutAdmin();
    document.location.href = "/admin_login";
    localStorage.clear();
  };

 

  return (
    <>
      {isMobile ? (
        <MobileLayout notifications={notifications} logout={logout} isOutsiderCompany={isOutsiderCompany}>
          {children}
        </MobileLayout>
      ) : (
        <Layout style={{ minHeight: "100vh", maxWidth: "100vw" }}>
          <Layout className="layout" trigger={null}>
            <Sider trigger={null} className="siderStyle" collapsible collapsed={collapsed} onCollapse={onCollapse}>
              <Space className="header-logo">
                <BeeboltWhiteLogo />
              </Space>
              <Menu
                className="admin-menu-items"
                theme="dark"
                defaultSelectedKeys={[history.location.pathname]}
                mode="inline"
                items={menuItemsState}
              ></Menu>
            </Sider>
            <Content
              className="content-wrapper"
              style={{
                marginLeft: collapsed ? "80px" : "200px",
              }}
            >
              <Header notifications={notifications} onLogout={logout} isOutsiderCompany={isOutsiderCompany} />
              <div className="content-body-wrapper">{children}</div>
              <LogsWrapper />
            </Content>
          </Layout>
        </Layout>
      )}
    </>
  );
};

export default connect(
  (state) => ({ notifications: state.notifications }),
  (dispatch) => ({
    ...bindActionCreators(adminActions, dispatch),
  }),
)(AdminLayout);
