/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { notification } from "antd";

import { actions as retailersGroupsActions } from "../../../redux/resources/retailersGroups";

export const useHooks = () => {
  const [query, setQuery] = useState({ page: 1, limit: 10 });
  const [paginationConfig, setPaginationConfig] = useState({
    showSizeChanger: true,
    position: ["bottomRight"],
    total: 10,
    pageSize: 10,
    current: 1,
  });
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [selectedRetailer, setSelectedRetailer] = useState(null);
  const [loading, setLoading] = useState(false);
  const groups = useSelector(({ retailersGroups }) => retailersGroups.items);
  const pagination = useSelector(({ retailersGroups }) => retailersGroups.pagination);
  const selectedGroupRetailers = useSelector(({ retailersGroups }) => retailersGroups.selectedGroupRetailers);
  const retailers = useSelector(({ retailersGroups }) => retailersGroups.retailers);
  const isMobile = useSelector(({ admin }) => admin.isMobile);
  const dispatch = useDispatch();
  const intl = useIntl();

  const {
    fetchRetailersGroups,
    fetchGroupRetailersRetailersGroups,
    fetchRetailersRetailersGroups,
    createNewGroupRetailersGroups,
    deleteGroupRetailersGroups,
    addGroupMemberRetailersGroups,
    removeGroupMemberRetailersGroups,
  } = retailersGroupsActions;

  useEffect(() => {
    loadAvailableRetailersData();
  }, []);

  useEffect(() => {
    setPaginationConfig({ ...paginationConfig, pageSize: query.limit, current: query.page });
    loadGroupsData();
  }, [query]);

  useEffect(() => {
    setPaginationConfig({ ...paginationConfig, total: pagination?.totalItems });
  }, [pagination]);

  useEffect(() => {
    setPaginationConfig({ ...paginationConfig, pageSizeOptions: isMobile ? [5, 10, 20] : [10, 20, 30, 50] });
  }, [isMobile]);

  useEffect(() => {
    if (selectedRetailer) {
      addNewGroupMember(expandedGroup, selectedRetailer);
      setSelectedRetailer(null);
    }
  }, [selectedRetailer]);

  const selectRetailer = (val) => {
    setSelectedRetailer(val);
  };

  const loadGroupsData = async () => {
    setLoading(true);
    try {
      await dispatch(fetchRetailersGroups({}, { query }));
    } catch (e) {
      notification.error({
        message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
        description: e.body && e.body.message && e.body.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const loadAvailableRetailersData = async () => {
    setLoading(true);
    try {
      await dispatch(fetchRetailersRetailersGroups());
    } catch (e) {
      notification.error({
        message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
        description: e.body && e.body.message && e.body.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const addNewGroupMember = async (groupId, retailerId) => {
    setLoading(true);
    try {
      await dispatch(addGroupMemberRetailersGroups({ groupId, retailerId }));
      await dispatch(fetchGroupRetailersRetailersGroups({ groupId }));
      await dispatch(fetchRetailersRetailersGroups());
    } catch (e) {
      notification.error({
        message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
        description: e.body && e.body.message && e.body.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const removeGroupMember = (retailerId) => async () => {
    setLoading(true);
    try {
      await dispatch(removeGroupMemberRetailersGroups({ groupId: expandedGroup, retailerId }));
      await dispatch(fetchGroupRetailersRetailersGroups({ groupId: expandedGroup }));
      await dispatch(fetchRetailersRetailersGroups());
    } catch (e) {
      notification.error({
        message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
        description: e.body && e.body.message && e.body.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const createNewGroup = async () => {
    setLoading(true);
    try {
      await dispatch(createNewGroupRetailersGroups());
      await dispatch(fetchRetailersGroups({}, { query }));
    } catch (e) {
      notification.error({
        message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
        description: e.body && e.body.message && e.body.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteGroupById = (id) => async () => {
    setLoading(true);
    try {
      await dispatch(deleteGroupRetailersGroups({ groupId: id }));
      await dispatch(fetchRetailersGroups({}, { query }));
    } catch (e) {
      notification.error({
        message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
        description: e.body && e.body.message && e.body.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const loadSelectedGroupRetailers = async (groupId) => {
    setLoading(true);
    try {
      await dispatch(fetchGroupRetailersRetailersGroups({ groupId }));
    } catch (e) {
      notification.error({
        message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
        description: e.body && e.body.message && e.body.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    intl,
    query,
    loading,
    retailers,
    paginationConfig,
    isMobile,
    groups,
    selectedGroupRetailers,
    expandedGroup,
    selectedRetailer,
    setQuery,
    createNewGroup,
    setExpandedGroup,
    loadSelectedGroupRetailers,
    deleteGroupById,
    selectRetailer,
    removeGroupMember,
  };
};
