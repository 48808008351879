import React, { useEffect } from "react";
import { Button,Checkbox } from "antd";

import { MobileTable } from "../../../../../components/AdminPanel/Mobile/MobileTable";
import { MobileTableRows } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableRows";
import { BtnGroup } from "../BtnGroup";
import { NO_INFO } from "../../../../../components/AdminPanel/Mobile/helpers";
import { MobilePagination } from "../../../../../components/AdminPanel/Mobile/MobilePagination";

export const MobileLocationsTable = ({
  data,
  location,
  paginationConfig,
  fetchLocationsChangeAdminCustomers,
  fetchData,
  onOpenModal,
  onChangePagination,
  handleToggleOne,
  isSelected,
  isBadLocation
}) => {
  useEffect(() => {
    fetchData(fetchLocationsChangeAdminCustomers,isBadLocation);
  }, [fetchLocationsChangeAdminCustomers, fetchData, location.search,isBadLocation]);

  return (
    <>
      <MobileTable>
        {data?.map(({ id, order, status }) => (
          <div className="table-item" key={id}>
          <div style={{ margin: "10px" }} onClick={handleToggleOne(id)}>
              <Checkbox checked={isSelected(id)} />
            </div>
            <MobileTableRows
              rows={[
                { label: "Job ID", value: order?.job?.id || NO_INFO },
                { label: "Order ID", value: order?.id || NO_INFO },
                { label: "Client name", value: order?.customerInfo?.name || NO_INFO },
                { label: "Client number", value: order?.customer?.phoneNumber || NO_INFO },
                { label: "Status", value: status },
              ]}
            />
            <BtnGroup>
              <Button onClick={onOpenModal({ type: "status", requestId: id })} className="mobile-btn mobile-btn--blue">
                Change Status
              </Button>
              <Button
                onClick={onOpenModal({ type: "archive", requestId: id })}
                className="mobile-btn mobile-btn--purple"
              >
                Archive
              </Button>
            </BtnGroup>
          </div>
        ))}
      </MobileTable>
      <MobilePagination onPageChange={onChangePagination}  {...paginationConfig} />
    </>
  );
};
