import { API_URL } from "src/constants/config";
import { getAuthData, getUserTenant } from "src/helpers/authStorage";
import { customFetch } from "src/utils/customFetch";

const TASKS_TRACKING_URL = "admin/jobs/tracking"; // trackingId
const VEHICLE_TRACKING_URL = "admin/jobs/vehicle"; // vehicleId
export class TrackingShipementService {
  authTokenFetcher = async (options: {
    serviceType: google.maps.journeySharing.FleetEngineServiceType;
    context: google.maps.journeySharing.AuthTokenContext;
  }) => {
    const endPoint =
      options.serviceType === google.maps.journeySharing.FleetEngineServiceType.TASK_SERVICE
        ? `${TASKS_TRACKING_URL}/${options.context.trackingId}`
        : `${VEHICLE_TRACKING_URL}/${"*"}`;

    const response = await customFetch(`${API_URL}/${endPoint}`, {
      method: "GET",
      cache: "no-cache",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: {
        Authorization: `Bearer ${getAuthData().accessToken}`,
        Tenant: getUserTenant(),
      },
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const data = await response.json();
    return {
      token: data.token,
      expiresInSeconds: data.expirationTime,
    };
  };
}
