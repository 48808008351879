export const  addLeadingZeros = (num : number, length = 5) => {
    // Convert number to string
    let str = `${num}`;

    
    // Check if number has less than 5 digits
    if (str.length < length) {
      // Calculate the number of zeros needed
      let zerosNeeded = length - str.length;
      // Add leading zeros to the string
      str = "0".repeat(zerosNeeded) + str;
    }
    
    // Return the string with leading zeros
    return str;
}

export const transformUserRole = (str:string) => {
  // Split the string by underscores
  const words = str.split('_');

  // Capitalize the first letter of each word and join with spaces
  const transformed = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

  return transformed;
}