import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";
import { API_URL } from "../../constants/config";

const updateOrderNewTicketStateReduce = (state, action) => {
  if (action.status === "resolved") {
    return {
      ...state,
      items: state.items.map((stateItem) => {
        return {
          ...stateItem,
          items: stateItem.items.map((i) => {
            if (i.id === action.context.orderId) {
              return {
                ...i,
                ticket: {
                  ...action.context.ticket,
                },
              };
            }
            return i;
          }),
        };
      }),
    };
  }
  return { ...state };
};

export const { types, actions, rootReducer } = createResource({
  name: "retailerOrder",
  url: `${API_URL}/retailers/self/orders`,

  actions: {
    fetch: {
      transformResponse: (res) => ({ ...res, body: res.body }),
    },
    getById: {
      method: "GET",
      url: `${API_URL}/retailers/self/orders/:orderId`,
    },
    saveUploaded: {
      method: "POST",
      url: `${API_URL}/retailers/orders`,
    },
    cancel: {
      method: "PUT",
      url: `${API_URL}/retailers/self/orders/:orderId/cancel`,
    },
    postpone: {
      method: "PUT",
      url: `${API_URL}/retailers/self/orders/:orderId/postpone`,
    },
    delete: {
      method: "DELETE",
      url: `${API_URL}/retailers/self/orders/:orderId`,
    },
    createReturn: {
      method: "POST",
      url: `${API_URL}/retailers/self/orders/:orderId/return`,
    },
    deleteMany: {
      method: "DELETE",
      url: `${API_URL}/retailers/self/orders`,
    },

    editPhone: {
      method: "PUT",
      url: `${API_URL}/retailers/self/orders/:orderId/phone-number`,
    },
    fetchStats: {
      method: "GET",
      url: `${API_URL}/retailers/self/today-stats`,
      transformResponse: (res) => ({
        dailyTotal: res.body.totalUploaded,
        totalDelivered: res.body.delivered,
        totalTransit: res.body.inTransit,
        totalDeliveryCash: res.body.deliveryCash,
        totalPostponed: res.body.postponed,
      }),
    },
    fetchExtendedStats: {
      method: "GET",
      url: `${API_URL}/retailers/self/stats`,
      transformResponse: (res) => ({
        dailyTotal: res.body.totalOrders,
        totalDelivered: res.body.totalDelivered,
        totalTransit: res.body.totalInTransit,
        totalDeliveryCash: res.body.totalCashOnDelivery,
        totalPostponed: res.body.totalPostponed,
        totalReturned: res.body.totalReturned,
        totalCanceled: res.body.totalCanceled,
        totalNewOrders: res.body.totalNewOrders,
        // totalCanceledOnDelivery: res.body.canceledOnDelivery,
      }),
    },
    fetchSelfStorePoints: {
      method: "GET",
      url: `${API_URL}/retailers/self/pickup-points`,
      transformResponse: ({ body }) => ({
        body,
      }),
    },
    fetchStorePoints: {
      method: "GET",
      url: `${API_URL}/retailers/self/orders/store-to-store/points`,
      transformResponse: ({ body }) => ({
        body,
      }),
    },
    sendStoreToStore: {
      method: "POST",
      url: `${API_URL}/retailers/self/orders/store-to-store`,
    },
    fetchReports: {
      method: "GET",
      url: `${API_URL}/retailers/self/jobs-reports`,
    },
    fetchReturnShipperForm: {
      method: "GET",
      url: `${API_URL}/retailers/self/orders/:orderId/return-form`,
    },

    // pure Actions
    updateNewTicketState: {
      isPure: true,
      reduce: updateOrderNewTicketStateReduce,
    },
    updateTicketState: {
      isPure: true,
      reduce: updateOrderNewTicketStateReduce,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
