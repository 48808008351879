import React from "react";

import "./styles.less";

export const MobileTitle = ({ title }) => (
  <div className="mobile-title">
    <div className="mobile-title__title">{title}</div>
    <div className="mobile-title__divider" />
  </div>
);
