const isLocalhost = /localhost|0\.0\.0\.0|127\.0\.0\.0\.1/i.test(document.location.hostname);
const isStaging = /staging/i.test(document.location.hostname);

// eslint-disable-next-line
const STAGING_API_URL = "https://api.dliv.me/";
const DEVELOPMENT_API_URL = "https://api.dliv.me/";
const PRODUCTION_API_URL = "https://api.dliv.me/";


// const API_URL = apiUrlFromLocation || (isLocalhost || isStaging ? STAGING_API_URL : PRODUCTION_API_URL);
let API_URL = "";
if (isLocalhost) API_URL = DEVELOPMENT_API_URL;
else if (isStaging) API_URL = STAGING_API_URL;
else API_URL = PRODUCTION_API_URL;

const getGoogleKey = () => {
  if (isLocalhost) return "AIzaSyBFs71OHCW10OcK1bCcPOKD8SdpEgUQDuc";
  if (isStaging) return "AIzaSyAwKVunWhAlkqAZBud50kLmNWqqBopqyDU";
  if (!isLocalhost && !isStaging) return "AIzaSyB2FNN06b9Aom6pJWEznFCaJzx-d9X9dWI";
  return "AIzaSyBFs71OHCW10OcK1bCcPOKD8SdpEgUQDuc";
};

const API_KEY = getGoogleKey();
const GEOCODE_KEY = getGoogleKey();
const ROUTES_API_KEY = "AIzaSyC24SDh1Hj9N1v3veBHCu-PMKEJIHuJUSU";

const STAGING_URL = "https://dliv.me/";
const PROD_URL = "https://dliv.me/";

const CLIENT_TOKEN = "ba404148d2b114f93148ebe639e4ad386b0eb077";
const GOOGLE_AUTH_CLIENT_ID = "76644883938-k9ri98mpthprdk5drg81kkonogo7l8ur.apps.googleusercontent.com";
const FACEBOOK_CLIENT_ID = "582993033179362";

const PAGE_URL = isLocalhost || isStaging ? STAGING_URL : PROD_URL;

const isStage = API_URL === STAGING_API_URL;
const isMainServer = API_URL === PRODUCTION_API_URL || isStage;

const googlePlayLink = "https://play.google.com/store/apps/details?id=com.dliv";
const appStoreLink = "https://apps.apple.com/us/app/dliv/id6470184640";

// const isPRODUCTION = apiUrlFromLocation || (isLocalhost || isStaging ? false : true);

const paymentResultRedirectLink = `${PAGE_URL}customer/payment-result`;
const RETAILER_AUTH_LINK = "/auth/retailers";
const DRIVER_AUTH_LINK = "/auth/drivers";

const GC_ERROR_TRACKING_STAGING_CREDS = {
  API_KEY: "AIzaSyAwKVunWhAlkqAZBud50kLmNWqqBopqyDU",
  PROJECT_ID: "beebolt-staging",
};

const GC_ERROR_TRACKING_PROD_CREDS = {
  API_KEY: "AIzaSyCdC9d3ZLSZ7Mj0EvZvLiH9DlVEdEKq3h8",
  PROJECT_ID: "beebolt-280114",
};

const mapCenters = {
  Qatar: { lat: 25.349793393583308, long: 51.25526849394423 },
  Jordan: { lat: 31.747459192179864, long: 36.05340304997765 },
  Algeria: { lat: 36.753768, long: 3.05840304997765 }, // for staging
  "Saudi Arabia": { lat: 24.266906, long: 45.107849 },
};

const ERROR_TRACKING_CREDS = isLocalhost || isStaging ? GC_ERROR_TRACKING_STAGING_CREDS : GC_ERROR_TRACKING_PROD_CREDS;

const BEEBOLT_COMPANY_ID = 1; // later must be changed to appropriate one

export {
  API_URL,
  API_KEY,
  GEOCODE_KEY,
  CLIENT_TOKEN,
  PAGE_URL,
  isMainServer,
  isStage,
  isLocalhost,
  googlePlayLink,
  appStoreLink,
  paymentResultRedirectLink,
  GOOGLE_AUTH_CLIENT_ID,
  FACEBOOK_CLIENT_ID,
  RETAILER_AUTH_LINK,
  DRIVER_AUTH_LINK,
  ERROR_TRACKING_CREDS,
  ROUTES_API_KEY,
  mapCenters,
  BEEBOLT_COMPANY_ID,
};
