import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import { actions as dashboardActions } from "../../redux/resources/dashboard";
import DesktopView from "./DesktopView";
import MobileView from "./MobileView";

const Dashboard = ({ isMobile, isDashboardDataFetching, dashboardStats, getDashboard }) => {
  const [date, setDate] = useState(moment());

  useEffect(() => {
    const actionOpts = { query: { day: date.toISOString(true).substring(0, 23) } };
    getDashboard({}, actionOpts);
    //eslint-disable-next-line
  }, [date]);

  return (
    <>
      {isMobile ? (
        <MobileView
          isDashboardDataFetching={isDashboardDataFetching}
          dashboardStats={dashboardStats.item}
          date={date}
          setDate={setDate}
        />
      ) : (
        <DesktopView
          isDashboardDataFetching={isDashboardDataFetching}
          dashboardStats={dashboardStats.item}
          date={date}
          setDate={setDate}
        />
      )}
    </>
  );
};

const MSTP = (state) => {
  return {
    isMobile: state.admin.isMobile,
    dashboardStats: state.dashboard,
    isDashboardDataFetching: state.dashboard?.isFetchingItem,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(dashboardActions, dispatch),
}))(Dashboard);
