import React from "react";
import { Col, Row } from "antd";
import EmptyLogo from "../../assets/empty-table.png";
import "./style.less";
export default ({ title, description }: { title: string; description: string }) => {
  return (
    <Row justify="center" align="middle" className="indicator-container">
      <Col lg="10">
        <Row justify="center">
          {" "}
          <img src={EmptyLogo} alt={"empty cargo"} />
        </Row>
        <span className="indicator-title">{title}</span>
        <br />
        <span>{description}</span>
      </Col>
    </Row>
  );
};
