import { useState, useEffect } from "react";
import { actions as notificationsActions } from "../../../redux/resources/notifications";
import { useDispatch } from "react-redux";

const ITEMS_PER_PAGE = 26;

export default function useHeaderNotifications() {
  const [ notificationsCount , setNotificationsCount] = useState(0);
  const { getUnreadCompanyNotifications } = notificationsActions;
  const dispatch = useDispatch();

  const fetchData = async () => {
    // @ts-ignore
    const { body } : any = await dispatch(getUnreadCompanyNotifications());
    setNotificationsCount(body);
  }

  

  useEffect(() => {
    fetchData();
  }, []);

  return {
    notificationsCount,
  };
}
