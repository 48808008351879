import React, { useState } from "react";
import "./style.less";
import { Select, Typography, Popconfirm, Button, Pagination, Popover,Row, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import CustomDivider from "../../../components/Divider";
import { MobileTable } from "../../../components/AdminPanel/Mobile/MobileTable";
import { MobileTableRows } from "../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableRows";
import ROLES from "../../../constants/roles";
import { addLeadingZeros, transformUserRole } from "src/helpers/formatUserData";
import TenantsSelect from "../components/TenantsSelect";
import EmptyListComponent from "../components/EmptyListComponent";
import { MobileViewProps } from "../types";
import { IUser } from "src/entities";

const { Option } = Select;

const MobileView : React.FC<MobileViewProps> = ({
  userList,
  usersPage,
  handleFetchUsers,
  handleDeleteUser,
  handleChangeRole,
  totalPages,
  tenants,
  onOpenCreateUserModal,
  searchedValue,
  setSearchedValue,
  handleSearchKeyPress,
  searchFilter,
  setSearchFilter,
}) => {

  const filteredRoles = Object.keys(ROLES).filter((e:string) => e !== "guest");
  const [roleChoice, setRoleChoice] = useState<string>('');
  const [popcofirmVisible, setPopcofirmVisible] = useState<string | number>("");

  const handleRoleChange = (id:number) => (event? : React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    handleChangeRole(id, roleChoice);
    // @ts-ignore
    setPopcofirmVisible("");
  };
  const handleSelect = (id:number) => (value:string) => {
    setRoleChoice(value);
    setPopcofirmVisible(id);
  };

  const selectSearchFilter = (
    <Select defaultValue={searchFilter} className="select-before" onChange={setSearchFilter}>
      <Option value="email">Email</Option>
      <Option value="userName">Name</Option>
    </Select>
  );

  return (
    <>
      <div className="mobile-page-wrapper">
        <Row justify="space-between" align="middle">
          <Typography.Title level={5} className="page-title">User Management</Typography.Title>
          <Button onClick={onOpenCreateUserModal} type="primary" className="new-user-btn-style">Add New User</Button>
        </Row>
        <CustomDivider />
        <div className="content-block-users">
              <Input
                placeholder="Search"
                addonBefore={selectSearchFilter}
                onPressEnter={handleSearchKeyPress}
                className="search-user"
                value={searchedValue || ''}
                onChange={(e) => setSearchedValue(e.target.value)}
              />
          <Pagination current={usersPage} onChange={(num) => handleFetchUsers(num)} total={totalPages} />
          {
            userList?.length ?
            <MobileTable>
              {userList?.map((user:IUser) => (
                <div className="table-item" key={user.id}>
                  <MobileTableRows
                    rows={[
                      {
                        label: "Id",
                        value: `${addLeadingZeros(user.id) || "No id"}`,
                      },
                      { label: "Name", value: user.userName },
                      { label: "Email", value: user.email },
                      { label: "Tenants", value: <TenantsSelect accessType={user?.accessType} defaultTenants={tenants} id={user?.id} /> },
                      {
                        label: "Role",
                        value: (
                          <Popconfirm
                            title={`Are you sure to change role to ${roleChoice}?`}
                            onConfirm={handleRoleChange(user.id)}
                            // @ts-ignore
                            onCancel={setPopcofirmVisible}
                            okText="Yes"
                            cancelText="No"
                            visible={popcofirmVisible === user.id}
                          >
                            <Select
                              onSelect={handleSelect(user.id)}
                              style={{ width: 150 }}
                              value={user.accessType}
                              // @ts-ignore
                              onFocus={setPopcofirmVisible}
                            >
                              {filteredRoles.map((e) => (
                                <Option key={e} value={e}>
                                  {transformUserRole(e)}
                                </Option>
                              ))}
                            </Select>
                          </Popconfirm>
                        ),
                      },
                      {
                        label: "Actions",
                        value: (() => {
                          const content = (
                            <>
                              <Typography.Paragraph>Are you sure you want to <br /> delete this user?</Typography.Paragraph>
                              <Button className="delete-user-btn" onClick={() => {
                                handleDeleteUser(user?.id);
                              }} >Delete User</Button>
                            </>
                          );
                          return (
                            (
                              <>
                                <Popover content={content} trigger="click" placement="leftBottom">
                                  <Button className="no-border-style">
                                    <DeleteOutlined />
                                  </Button>
                                </Popover>
                              </>
                            ) || "No actions"
                          );
                        })(),
                      },
                    ]}
                  />
                </div>
              ))}
            </MobileTable> :
            <EmptyListComponent isFetching={userList?.length === 0 && totalPages === 0} title="No users found" description="Please add users to expand your team" />
          }
        </div>
      </div>
    </>
  );
}

export default MobileView;
