import { Col, Row, Button, Select, Spin, Checkbox, Tag, Space, Typography, notification, Divider } from "antd";
import React, { FC, useEffect, useState } from "react";
import { CustomCard } from "../../../utils/common";
import JobsMapRoutes from "../../JobsMap/MapMutipleRoutes";
import JobsList from "../JobsList";
import { getUserTenant } from "../../../helpers/authStorage";
import { mapCenters } from "../../../constants/config";
import { markersColors } from "../../JobsMap/MapMutipleRoutes/mapStyles/markerColors";
import { useMapDirectionConfig } from "./useMapDircetionConfig";
import { RootState } from "src/redux";
import { actions as jobsMapActions } from "../../../redux/resources/jobsMap";
import { actions as jobsForCombineActions } from "../../../redux/resources/jobsForCombine";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ICombineJobsRouting, JobStatusEnum } from "../../../types/CombineJobs";
import { useCombineJobsRouting } from "./useCombineJobsRouting";
import ConfirmCombineJobModal from "../ConfirmCombineJobModal";
import "./combine-jobs-routing.less";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const { Option } = Select;

const filterJobByIds = (allJobs: any[], ids: number[]) => allJobs.filter((job: any) => ids.includes(job.id));

const filterJobByStatus = (allJobs: any[], status: JobStatusEnum) =>
  allJobs.filter((job: any) => job.status === status);

const getAllJobIds = (allJobs: any[]) => {
  const allJobsId = allJobs.map((job) => job.id);
  return allJobsId;
};

const filterStatusOptions = [
  { text: "All", value: JobStatusEnum.ALL, label: "All" },
  { text: "Ready", value: JobStatusEnum.READY, label: "Ready" },
  { text: "Incomplete", value: JobStatusEnum.INCOMPLETE, label: "Incomplete" },
];

const CombineJobsRouting: FC<any> = ({
  // states
  items: allJobs,
  routes,
  combinedItems,
  isFetchingJobs,

  // actions
  combineJobsForCombine,
  splitJobsForCombine,
  fetchJobsMaps,
  clearJobsForCombine,
  moveOrdersJobsMap,
}) => {
  const [jobsList, setJobsList] = useState<any[]>(allJobs);
  const [selectedJobIds, setSelectedJobIds] = useState<number[]>([]);
  const [selectedJobs, setSelectedJobs] = useState<Record<any, any>[]>([]);
  const [confirmCombineJobs, setConfirmCombineJobs] = useState<boolean>(false);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const [showRoutes, setShowRoutes] = useState<boolean>(false);

  const [selectedStatus, setSelectedStatus] = useState<JobStatusEnum>(JobStatusEnum.ALL);
  const enableSplitJob = selectedJobs[0]?.combined && !!(selectedJobs.length < 2);

  const availableJobs = (id: number) =>
    jobsList.filter((job: any) => job.id !== id).map(({ id }: { id: number }) => ({ jobId: id }));

  const { calculateAndDisplayRoute } = useMapDirectionConfig();
  const { fetchJobs, combineJobs, splitJobs, moveOrderFromJob } = useCombineJobsRouting({
    fetchJobsMaps,
    combineJobsForCombine,
    splitJobsForCombine,
    clearJobsForCombine,
    moveOrdersJobsMap,
  });

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    if (allJobs) {
      const currentJobs = selectedJobIds;
      onClearSelectionClick();
      setJobsList(allJobs);
      reselectJobs(currentJobs);
    }
  }, [allJobs]);

  const onClearSelectionClick = () => {
    setSelectedJobIds([]);
    setSelectedJobs([]);
  };

  const onCombineJobsClick = () => {
    combineJobs(selectedJobIds);
    onClearSelectionClick();
    setConfirmCombineJobs(false);
  };

  const onSplitJobClick = () => {
    splitJobs(selectedJobIds[0]);
  };

  const onRemoveJobClick = (id: number, index: number) => {
    const newSelectedIds = selectedJobIds.filter((jobId) => jobId !== id);
    setSelectedJobIds(newSelectedIds);
    setSelectedJobs(filterJobByIds(jobsList, newSelectedIds));
  };

  const onJobSelect = (selectedIds: number[]) => {
    setSelectedJobIds(selectedIds);
    setSelectedJobs(filterJobByIds(jobsList, selectedIds));
    setIndeterminate(!!selectedIds.length && selectedIds.length < jobsList.length);
  };

  const onSelectAllJobClick = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;
    const allJobIds = getAllJobIds(jobsList);
    setIndeterminate(false);

    if (isChecked) {
      setSelectedJobIds(allJobIds);
      setSelectedJobs(jobsList);
    } else {
      onClearSelectionClick();
    }
  };

  const reselectJobs = (selectedJobIds: number[]) => {
    setSelectedJobIds(selectedJobIds);
    setSelectedJobs(filterJobByIds(allJobs, selectedJobIds));
  };

  const onMoveOrder = async (orderId: number, jobId: number, originalJobId: number) => {
    try {
      await moveOrderFromJob(orderId, jobId, originalJobId);
      await fetchJobs();
    } catch (error: any) {
      notification.error({
        message: "There was an error while displaying jobs on map !",
      });
    }
  };

  const onFilterChange = (value: JobStatusEnum) => {
    setSelectedStatus(value);

    if (value === JobStatusEnum.ALL) {
      setJobsList(allJobs);
      return;
    }
    setJobsList(filterJobByStatus(allJobs, value));
  };

  const currentTenant = getUserTenant();

  const onShowRoutes = (event: React.MouseEvent<any, MouseEvent>) => {
    event.preventDefault();
    setShowRoutes(!showRoutes);
  };

  const renderJobsTags = () => {
    return (
      <div className="job-tag-wrapper">
        {selectedJobs?.map(({ id }: any, index: number) => {
          let color = markersColors[index % 10];
          return (
            <Tag color={color} key={id}>
              Job #{id}
            </Tag>
          );
        })}
      </div>
    );
  };

  const renderMap = (items: any[]) => {
    return (
      <JobsMapRoutes
        items={items}
        itemsForCombine={[]} // we are no longer using itemsForCombine hence will be passing empty array temp
        calculateAndDisplayRoute={calculateAndDisplayRoute}
        jobs={selectedJobs}
        routes={routes}
        tenant={(currentTenant as keyof typeof mapCenters) ?? "Qatar"}
        availableJobs={availableJobs}
        handleMoveOrder={onMoveOrder}
        isMoveOrders={true}
        showRoutes={showRoutes}
        mapHeight="calc(100vh - 200px)"
      />
    );
  };
  return (
    <>
      <CustomCard>
        <Row justify="space-between" gutter={20} className="combine-job-header">
          <Col>
            <Space>
              <label>Filter by </label>
              <Select
                className="action"
                value={selectedStatus}
                defaultValue={filterStatusOptions[0].value}
                style={{ width: 120 }}
                onChange={onFilterChange}
              >
                {filterStatusOptions.map(({ label, value }: { label: string; value: string }) => (
                  <Option value={value} key={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            </Space>
          </Col>

          <Col>
            <Space>
              <Button className="" onClick={onShowRoutes}>
                {showRoutes ? "Hide routes" : "Show routes"}
              </Button>
              <Button className="" onClick={onSplitJobClick} disabled={!enableSplitJob}>
                Split Job n°{selectedJobIds[0]}
              </Button>
              <Button
                className=""
                type="primary"
                disabled={selectedJobIds?.length < 2}
                onClick={() => setConfirmCombineJobs(true)}
              >
                Combine
              </Button>
            </Space>
          </Col>
        </Row>
        <Divider />
        <Row className="combine-jobs-action-wrapper" gutter={[16, 0]}>
          <Col md={8}>
            <Spin spinning={isFetchingJobs} tip={"Loading jobs ..."}>
              <div className="job-list-header">
                {/* <Checkbox indeterminate={indeterminate} onChange={onSelectAllJobClick}></Checkbox> */}
                <Typography.Paragraph strong>Job List</Typography.Paragraph>
                <div className="header-actions">
                  <Button type="link" onClick={onClearSelectionClick} disabled={selectedJobIds.length < 1}>
                    Clear
                  </Button>
                </div>
              </div>
              <JobsList
                onJobSelect={onJobSelect}
                selectedJobs={selectedJobIds}
                selectedStatus={JobStatusEnum.ALL}
                items={combinedItems?.length > 0 ? combinedItems : jobsList}
              />
            </Spin>
          </Col>

          <Col md={16}>
            {renderJobsTags()}
            {renderMap(selectedJobs)}
          </Col>
        </Row>
      </CustomCard>
      <ConfirmCombineJobModal
        isModalOpen={confirmCombineJobs}
        handleOk={onCombineJobsClick}
        handleCancel={() => setConfirmCombineJobs(false)}
        selectedJobs={selectedJobs}
        removeJob={onRemoveJobClick}
      />
    </>
  );
};

const MSTP = (state: RootState, ownProps: ICombineJobsRouting) => {
  return {
    displayedItems: state.jobsMap.displayedItems,
    displayedItemsIds: state.jobsMap.displayedItemsIds,
    items: state.jobsMap.items,
    routes: state.jobsMap.routes,
    combinedItems: state.jobsForCombine.item,
    isFetchingJobs: state.jobsMap.isFetching,
    retailers: state.adminRetailers.restrictedList,
    ...ownProps,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(jobsMapActions, dispatch),
  ...bindActionCreators(jobsForCombineActions, dispatch),
}))(CombineJobsRouting);
