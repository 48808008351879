import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "driversMap",

  actions: {
    getAll: {
      method: "GET",
      url: `${API_URL}/admin/drivers?status=accepted&withLocation=true&withCurrentJob=true`,
      transformResponse: ({ body }) => ({
        items: body.items,
        meta: body.meta,
      }),
      reduce: (state, action) => {
        return {
          ...state,
          items: action.items,
          pagination: action.meta,
        };
      },
    },

    getAllMobile: {
      method: "GET",
      url: `${API_URL}/admin/drivers/list`,
      reduce: (state, action) => ({
        ...state,
        items: action.body,
      }),
    },

    getLocation: {
      method: "GET",
      url: `${API_URL}/admin/drivers/:id/location`,
      transformResponse: ({ body }) => ({ locations: body.items }),
      reduce: (state, action) => {
        return {
          ...state,
          selectedDriver: action.context,
          locations: action.locations,
          item: state.selectedDriver !== action.context ? null : state.item,
        };
      },
    },

    getCurrentJob: {
      method: "GET",
      url: `${API_URL}/admin/drivers/:id/current-job`,
      transformResponse: ({ body }) => ({
        item: body,
      }),
      reduce: (state, action) => ({
        ...state,
        item: action.item,
      }),
    },
    clearData: {
      isPure: true,
      reduce: (state) => {
        return {
          ...state,
          locations: null,
          item: null,
          selectedDriver: null,
        };
      },
    },
  },
});

setAuthHeader(getAuthData().accessToken);
