import React from "react";
import { Select } from "antd";

export const { Option } = Select;

export default ({
  defaultValue,
  defaultLabel,
  style,
  onChange,
  list,
  className,
  mapFunction = (obj) => (
    <Option value={obj.id} key={obj.id}>
      {obj.name}
    </Option>
  ),
  disabled = false,
  value,
  loading = false,
}) => (
  <Select
    defaultValue={defaultValue}
    style={style}
    onChange={onChange}
    className={className}
    disabled={disabled}
    value={value}
    loading={loading}
    placeholder="Restricted retailers"
  >
    {defaultLabel && (
      <Option value={defaultValue} key={defaultValue}>
        {defaultLabel}
      </Option>
    )}
    {list?.map(mapFunction)}
  </Select>
);
