import React from "react";

import "./driverCabinet.less";

function DriverCabinet() {
  return (
    <div className="container">
      <h1>Congratulations, your application is approved. Please login into the app.</h1>
    </div>
  );
}

export default DriverCabinet;
