import { notification } from "antd";
import { useState } from "react";
import { ICashAmountModalVisible } from "src/types/JobManagement";

const useOrderOperations: (props: any) => any = ({
  fetchDeliveryJobs,
  deleteOrderDeliveryJob,
  getOneDeliveryJob,
  getRoutesDeliveryJob,
  postponeOrderDeliveryJob,
  forceNextOrderDeliveryJob,
  completeOrderDeliveryJob,
  returnToShipperDeliveryJob,
  cancelOrderDeliveryJob,
  withErrors,
  cancelCompletedAdminOrder,
}) => {
  const [confirmOrdersAction, setConfirmOrdersAction] = useState<ICashAmountModalVisible>({
    open: false,
    id: null,
    job: null,
    type: null,
  });
  const [operationLoading, setOperationLoading] = useState<boolean>(false);

  const deleteOrder = async (values: any) => {
    try {
      await deleteOrderDeliveryJob(values);
      notification.success({
        message: "Message",
        description: `Order ${values?.orderId ?? ""} was deleted successfully.`,
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to delete order.",
      });
    }
  };
  const postponeOrder = async (jobId: number, orderId: number, postponedTime: string) => {
    setOperationLoading(true);
    try {
      await postponeOrderDeliveryJob({ jobId, orderId, postponedTime });
      notification.success({
        message: "Message",
        description: `Order ${orderId ?? ""} was postponed successfully.`,
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to postpone order.",
      });
    } finally {
      setOperationLoading(false);
      setConfirmOrdersAction({ open: false, id: null, job: null, type: null });
    }
  };
  const cancelOrder = async (values: any) => {
    try {
      await cancelOrderDeliveryJob(values);
      notification.success({
        message: "Message",
        description: `Order ${values?.orderId ?? ""} was canceled successfully.`,
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to cancel order.",
      });
    }
  };

  const cancelCompletedOrder = async (jobId: number, orderId: number) => {
    try {
      await cancelCompletedAdminOrder({ orderId: orderId });
      await getRoutesDeliveryJob({ jobId: jobId });

      notification.success({
        message: "Message",
        description: `Order ${orderId ?? ""} was canceled successfully.`,
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to cancel order.",
      });
    } finally {
      setOperationLoading(false);
      setConfirmOrdersAction({ open: false, id: null, job: null, type: null });
    }
  };

  const returnToShipper = async ({ orderId, jobId }: { orderId: number; jobId: number }) => {
    try {
      await returnToShipperDeliveryJob({ orderId, jobId });
      notification.success({
        message: "Message",
        description: `Order ${orderId ?? ""} was returned to shipper successfully.`,
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to create return to shipper.",
      });
    }
  };

  const completeOrder = async (values: any) => {
    try {
      await completeOrderDeliveryJob(values).then(() => {
        notification.success({
          message: "Message",
          description: `Order ${values?.orderId ?? ""} is completed successfully.`,
        });
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to complete order.",
      });
    }
  };

  const forceNextOrder = async (jobId: number) => {
    try {
      await forceNextOrderDeliveryJob({ jobId });
      await fetchDeliveryJobs({}, { query: { withError: withErrors } });
      await getRoutesDeliveryJob({ jobId: jobId });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to move order force change next order",
      });
    }
  };

  return {
    forceNextOrder,
    postponeOrder,
    completeOrder,
    returnToShipper,
    cancelOrder,
    deleteOrder,
    cancelCompletedOrder,
    confirmOrdersAction,
    setConfirmOrdersAction,
    operationLoading,
    setOperationLoading,
  };
};

export default useOrderOperations;
