import React from "react";
import { Menu, Popconfirm } from "antd";

function DropdownMenu({ ids, onHandleAssignJob }) {
  return (
    <Menu className="assignJobMenu">
      {ids.map((id) => (
        <Menu.Item key={id}>
          <Popconfirm
            placement="right"
            title="Are You sure, You want to assign this job to driver?"
            onConfirm={() => onHandleAssignJob(id)}
          >
            job with id {id}
          </Popconfirm>
        </Menu.Item>
      ))}
    </Menu>
  );
}

export default DropdownMenu;
