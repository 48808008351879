import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form, Input, Typography, notification } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { ArrowLeftOutlined } from "@ant-design/icons";
import SuccessAuth from "../SuccessAuth";
import GetStartedButton from "../GetStartedButton";
import { actions as retailerActions } from "../../../../redux/resources/retailer";
import { getUserTenant } from "../../../../helpers/authStorage";
import { ReactComponent as EmailIcon } from "../../../../assets/newLanding/auth/emailIcon.svg";
import ArrowLeft from "../../../../assets/newLanding/ArrowLeft";
import styles from "./forgotPasswordForm.module.less";
import MobileBottomToolbar from "../MobileBottomToolbar";

const ForgotPasswordForm = ({ forgotPasswordRetailer, isMobile }) => {
  const [sendMessage, setSendMessage] = useState(false);

  const [form] = Form.useForm();
  const intl = useIntl();

  useEffect(() => {
    form.isFieldsTouched() && form.validateFields();
    // eslint-disable-next-line
  }, [intl.locale]);

  const handleSubmit = (values) => {
    const formattedFields = { ...values };
    formattedFields.email = formattedFields.email.toLowerCase();

    forgotPasswordRetailer({ ...formattedFields, name: getUserTenant() })
      .then((response) => {
        notification.success({ message: "Success", description: response.body.message });

        setSendMessage(true);
      })
      .catch((error) => notification.error({ message: "Error", description: error.body.message.toString() }));
  };

  return !sendMessage || isMobile ? (
    <>
      {isMobile && (
        <Typography.Title level={2} className={styles.forgotPasswordMainTitleMobile}>
          Password{" "}
          <span level={2} className={styles.forgotPasswordMainTitleYellowMobile}>
            Recovery
          </span>
        </Typography.Title>
      )}
      <Typography.Title level={2} className={isMobile ? styles.forgotPasswordTitleMobile : styles.forgotPasswordTitle}>
        {!isMobile && (
          <Link to="/retailer_login">
            <ArrowLeft />
          </Link>
        )}
        <FormattedMessage id="page.forgot.enterEmail" defaultMessage="Please enter your email:" />
      </Typography.Title>

      <Form
        scrollToFirstError
        form={form}
        name="login"
        onFinish={handleSubmit}
        className={styles.forgotPasswordForm}
        style={
          isMobile ? { flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" } : {}
        }
      >
        {isMobile ? (
          <div>
            {" "}
            <Form.Item
              name="email"
              className={styles.formItem}
              rules={[
                {
                  type: "email",
                  message: `${intl.formatMessage({ id: "error.invalidEmail", defaultMessage: "Email is not valid!" })}`,
                },
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: "error.inputEmail",
                    defaultMessage: "Please input your email!",
                  })}`,
                },
              ]}
            >
              <Input
                prefix={<EmailIcon />}
                placeholder={`${intl.formatMessage({ id: "input.email1", defaultMessage: "Email" })}`}
                className={styles.formInput}
              />
            </Form.Item>
          </div>
        ) : (
          <>
            {" "}
            <Form.Item
              name="email"
              className={styles.formItem}
              rules={[
                {
                  type: "email",
                  message: `${intl.formatMessage({ id: "error.invalidEmail", defaultMessage: "Email is not valid!" })}`,
                },
                {
                  required: true,
                  message: `${intl.formatMessage({
                    id: "error.inputEmail",
                    defaultMessage: "Please input your email!",
                  })}`,
                },
              ]}
            >
              <Input
                prefix={<EmailIcon />}
                placeholder={`${intl.formatMessage({ id: "input.email1", defaultMessage: "Email" })}`}
                className={styles.formInput}
              />
            </Form.Item>
          </>
        )}

        <Form.Item noStyle>
          <div style={{ display: "flex", alignItems: "center" }}>
            {isMobile && (
              <Link
                to="/retailer_login"
                style={{ fontWeight: 700, fontSize: 18, lineHeight: "24px", padding: "12px 28px", color: "black" }}
              >
                Cancel
              </Link>
            )}
            <GetStartedButton
              htmlType="submit"
              className={isMobile ? styles.submitButtonMobile : styles.submitButton}
              style={isMobile ? { marginTop: "0px !important" } : {}}
            >
              <FormattedMessage id="tabPane.actions.send" defaultMessage="Send" />
            </GetStartedButton>
          </div>
        </Form.Item>
      </Form>
      <MobileBottomToolbar
        isVisible={sendMessage}
        onToggleModal={() => {
          window.location.href = "/";
        }}
      >
        <Typography.Title level={3} className={styles.modalTitle}>
          Success! <br />
          <Typography.Text className={styles.modalSubTitle}>Link on your mailbox</Typography.Text>
        </Typography.Title>

        <Typography.Paragraph className={styles.modalDescription}>
          Password recovery request sent to you email. Please check your mailbox.
        </Typography.Paragraph>

        <Link to="/retailer_login">
          <GetStartedButton
            htmlType="submit"
            className={styles.signUpButton}
            style={isMobile ? { marginTop: 16, width: "100%" } : {}}
          >
            <ArrowLeftOutlined style={{ paddingRight: 20 }} /> Back to Log In
          </GetStartedButton>
        </Link>
      </MobileBottomToolbar>
    </>
  ) : (
    <SuccessAuth
      title="page.forgot.linkOnMailbox"
      subtitle="page.forgot.success"
      message="page.forgot.checkMailbox"
      goToBtnLink="/retailer_login"
      goToBtnLabel="page.forgot.backToLogIn"
    />
  );
};

export default connect(null, (dispatch) => ({
  ...bindActionCreators(retailerActions, dispatch),
}))(ForgotPasswordForm);
