import React, { useEffect } from "react";
import { DatePicker, Form, Input, Select } from "antd";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";

import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import StepActions from "../../../../components/DataEntry/StepActions";
import { getDataLocalStorageAndSetData, getPhoneInputCountryCode } from "../../../../helpers/general";
import TenantSelector from "../../../../components/DataEntry/TenantSelector";

import "./style.less";

const nationalities = require("i18n-nationality");
nationalities.registerLocale(require("i18n-nationality/langs/en.json"));

const allNationalities = nationalities.getNames("en");

const allNationalitiesCodes = Object.keys(allNationalities);
const allNationalitiesArray = [];
allNationalitiesCodes.forEach((code) => {
  const nationality = { code, value: `${allNationalities[code]} - ${code}` };
  allNationalitiesArray.push(nationality);
});

const PersonalInformationForm = ({
  putSelfProfileDriverWithError,
  currentStep,
  onPrevStep,
  stepsLength,
  driverInfo,
  personalInfo,
  setCurrentDriverPhone,
}) => {
  const [form] = Form.useForm();
  const intl = useIntl();

  useEffect(() => {
    const driverPersonalInfo = JSON.parse(localStorage.getItem("driverPersonalInfo"));
    getDataLocalStorageAndSetData(driverPersonalInfo, "firstName", form);
    getDataLocalStorageAndSetData(driverPersonalInfo, "lastName", form);
    getDataLocalStorageAndSetData(driverPersonalInfo, "nationality", form);
    getDataLocalStorageAndSetData(driverPersonalInfo, "birthDate", form);
    getDataLocalStorageAndSetData(driverPersonalInfo, "phoneNumber", form);
    getDataLocalStorageAndSetData(driverPersonalInfo, "address", form);
    getDataLocalStorageAndSetData(driverPersonalInfo, "tenantName", form);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIfExistAndThenSet = (name) => {
    if (!form.getFieldsValue(name)[name]) {
      form.setFieldsValue({
        [name]: personalInfo[name] ?? "",
      });
    } else if (name === "birthDate") {
      form.setFieldsValue({
        birthDate: moment(personalInfo.birthDate) ?? moment("1990-01-01"),
      });
    }
  };
  useEffect(() => {
    if (personalInfo) {
      Object.entries(personalInfo).forEach(([name]) => {
        checkIfExistAndThenSet(name);
      });
    }
    // eslint-disable-next-line
  }, [personalInfo]);

  const onFinish = (values) => {
    putSelfProfileDriverWithError(values);
  };

  const dateFormat = "YYYY/MM/DD";

  const disabledDate = (current) => current && current > moment().subtract(18, "years");

  const handleChangePhone = (value) => {
    setCurrentDriverPhone(value);
    form.setFieldsValue({
      phoneNumber: value,
    });
  };

  const handleCountryChange = (value) => {
    form.setFieldsValue({
      tenantName: value,
    });
  };

  const firstName = personalInfo ? personalInfo.firstName : "";
  const lastName = personalInfo ? personalInfo.lastName : "";
  const nationality = personalInfo ? personalInfo.nationality : "";
  const birthDate = personalInfo ? moment(personalInfo.birthDate) : moment("1990-01-01");
  const address = personalInfo ? personalInfo.address : "";
  const defaultTenant = personalInfo ? personalInfo.tenantName : "Qatar";

  return (
    <>
      <h3 className="driverInfoTitle">
        <FormattedMessage id="page.step.driverInfo.title" defaultMessage="Personal Information" />
      </h3>
      <p className="driverInfoDescription">
        <FormattedMessage
          id="page.step.driverInfo.subtitle"
          defaultMessage="Please make sure the information below matches your government issued ID. You will receive a text message on the phone number you entered below. Make sure the phone number is correct!"
        />
      </p>
      <Form
        onValuesChange={(changedValues, allValues) => {
          localStorage.setItem("driverPersonalInfo", JSON.stringify(allValues));
        }}
        autoComplete="off"
        form={form}
        name="personal_info"
        layout="vertical"
        onFinish={onFinish}
        hideRequiredMark
        scrollToFirstError
        className="formStyle"
      >
        <Form.Item
          className="personalInfoItem"
          name="firstName"
          label={`${intl.formatMessage({ id: "input.firstName", defaultMessage: "First Name" })}`}
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({ id: "error.inputFirstName", defaultMessage: "Input first name!" })}`,
            },
          ]}
          initialValue={firstName}
        >
          <Input placeholder={`${intl.formatMessage({ id: "input.name", defaultMessage: "Name" })}`} />
        </Form.Item>
        <Form.Item
          className="personalInfoItem"
          name="lastName"
          label={`${intl.formatMessage({ id: "input.lastName", defaultMessage: "Last Name" })}`}
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({ id: "error.inputLastName", defaultMessage: "Input last name!" })}`,
            },
          ]}
          initialValue={lastName}
        >
          <Input placeholder={`${intl.formatMessage({ id: "input.surname", defaultMessage: "Surname" })}`} />
        </Form.Item>
        <Form.Item
          className="personalInfoItem"
          name="nationality"
          label={`${intl.formatMessage({ id: "input.nationality", defaultMessage: "Nationality" })}`}
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({
                id: "error.selectNationality",
                defaultMessage: "Select nationality!",
              })}`,
            },
          ]}
          initialValue={nationality}
        >
          <Select showSearch placeholder={`${intl.formatMessage({ id: "select", defaultMessage: "Select" })}`}>
            {allNationalitiesArray.map((nationality) => (
              <Select.Option key={nationality.code} value={nationality.value}>
                <span style={{ fontWeight: "normal" }}>{nationality.value}</span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className="personalInfoItem"
          name="birthDate"
          label={`${intl.formatMessage({ id: "dateOfBirth", defaultMessage: "Date of birth" })}`}
          format={dateFormat}
          initialValue={birthDate}
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({
                id: "error.selectDateOfBirth",
                defaultMessage: "Select your birth date!",
              })}`,
            },
          ]}
        >
          <DatePicker className="birthDatePicker" disabledDate={disabledDate} />
        </Form.Item>
        <Form.Item
          className="personalInfoItem"
          name="phoneNumber"
          label={`${intl.formatMessage({ id: "phoneNumber", defaultMessage: "Phone number" })}`}
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({ id: "error.inputPhone", defaultMessage: "Input phone number!" })}`,
            },
            {
              min: 4,
              message: `${intl.formatMessage({
                id: "error.minLength",
                defaultMessage: "Minimum length is 4 charters",
              })}`,
            },
            {
              max: 255,
              message: `${intl.formatMessage({
                id: "error.maxLength",
                defaultMessage: "Maximum length is 255 characters",
              })}`,
            },
          ]}
          initialValue={driverInfo ? driverInfo.phoneNumber : ""}
        >
          <ReactPhoneInput
            defaultMask={".... .... ...."}
            country={getPhoneInputCountryCode()}
            placeholder={`${intl.formatMessage({ id: "input.phone", defaultMessage: "Phone" })}`}
            value={form.getFieldValue("phoneNumber")}
            onChange={handleChangePhone}
          />
        </Form.Item>
        <Form.Item
          className="personalInfoItem"
          name="address"
          label={`${intl.formatMessage({ id: "input.homeAddress", defaultMessage: "Home address" })}`}
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({ id: "error.inputHomeAddress", defaultMessage: "Input home address!" })}`,
            },
          ]}
          initialValue={address}
        >
          <Input placeholder={`${intl.formatMessage({ id: "input.input.address", defaultMessage: "Address" })}`} />
        </Form.Item>
        <Form.Item
          className="personalInfoItem"
          name="tenantName"
          label={`${intl.formatMessage({ id: "input.country", defaultMessage: "Country" })}`}
          initialValue={defaultTenant}
        >
          <TenantSelector isAdmin={false} onChange={handleCountryChange} defaultTenant={defaultTenant} />
        </Form.Item>
        <StepActions currentStep={currentStep} onPrevStep={onPrevStep} stepsLength={stepsLength} />
      </Form>
    </>
  );
};

export default PersonalInformationForm;
