import { Context } from "redux-rest-resource/lib/typings";

export interface IRetailerTicketsProps {
  getByIdRetailerOrder: (context: Context) => Promise<any>;
}

export interface ITicketsTableData {
  key: string;
  id: number;
  orderId: number;
  labels: string[];
  status: string;
  isRead: boolean;
}

export interface IPagination {
  pageSize: number;
  current: number;
}

export enum UsersRolesEnum {
  SUPER_ADMIN = "super_admin",
  ADMIN = "admin",
  OPERATIONS_TEAM = "operations_team",
  DRIVER_MANAGER = "driver_manager",
  DRIVER = "driver",
  RETAILER = "retailer",
  EVENT = "event",
  CUSTOMER_SERVICE = "customer_service",
}

export enum TicketStatusEnum {
  ON_HOLD = "on-hold",
  OPEN = "open",
  CLOSE = "close",
}

export interface ITicketListViewProps {
  getTicketAction: any;
  updateTicketAction: any;
  sendMessageAction: any;
  getAllMessagesAction: any;
  updateTicketState: any;
  tickets: ITicketData | null;
  isAdmin: boolean;
  isLoading: boolean;
}

export interface ITicketData {
  items: Record<any, any>;
  meta: Record<any, any>;
}
