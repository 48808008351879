import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";
import { API_URL } from "../../constants/config";

const updateOrderNewTicketStateReduce = (state, action) => {
  if (action.status === "resolved") {
    return {
      ...state,
      items: state.items.map((stateItem) => {
        return {
          ...stateItem,
          items: stateItem.items.map((i) => {
            if (i.id === action.context.orderId) {
              return {
                ...i,
                ticket: {
                  ...action.context.ticket,
                },
              };
            }
            return i;
          }),
        };
      }),
    };
  }
  return { ...state };
};

export const { types, actions, rootReducer } = createResource({
  name: "adminOrder",
  url: `${API_URL}/admin/orders`,

  actions: {
    fetch: {
      transformResponse: (res) => ({ ...res, body: res.body }),
    },
    getById: {
      method: "GET",
      url: `${API_URL}/admin/orders/:orderId`,
    },
    saveUploaded: {
      method: "POST",
    },
    cancel: {
      method: "PUT",
      url: `${API_URL}/admin/orders/:orderId/cancel`,
    },
    postpone: {
      method: "PUT",
      url: `${API_URL}/admin/orders/:orderId/postpone`,
    },
    delete: {
      method: "DELETE",
      url: `${API_URL}/admin/orders/:orderId`,
    },
    restore: {
      method: "PUT",
      url: `${API_URL}/admin/orders/restore/:orderId`,
    },
    restoreFailed: {
      method: "PATCH",
      url: `${API_URL}/admin/orders/:orderId/new`,
    },
    createReturn: {
      method: "POST",
      url: `${API_URL}/admin/orders/:orderId/return`,
    },
    completeOrder: {
      method: "PUT",
      url: `${API_URL}/admin/orders/:orderId/complete`,
    },

    cancelCompleted: {
      method: "PUT",
      url: `${API_URL}/admin/orders/:orderId/cancel-completed`,
    },

    bulkDelete: {
      method: "DELETE",
      url: `${API_URL}/admin/orders`,
    },
    changeLocation: {
      method: "PUT",
      url: `${API_URL}/admin/orders/:orderId/location`,
    },
    removeLocation: {
      method: "DELETE",
      url: `${API_URL}/admin/orders/:orderId/location`,
    },
    editPhone: {
      method: "PUT",
      url: `${API_URL}/admin/orders/:orderId/phone-number`,
    },
    returnToShipper: {
      method: "PUT",
      url: `${API_URL}/admin/orders/:orderId/return-to-shipper`,
    },
    getReports: {
      method: "GET",
      url: `${API_URL}/admin/orders/:id/job-reports`,
    },

    editInitialAddress: {
      method: "PUT",
      url: `${API_URL}/admin/orders/:orderId/initial-address`,
    },
    fetchOrderHistory: {
      method: "GET",
      url: `${API_URL}/admin/orders/:orderId/status-history`,
    },
    fetchReturnShipperForm: {
      method: "GET",
      url: `${API_URL}/admin/orders/:orderId/return-form`,
    },
    addToJob: {
      method: "PUT",
      url: `${API_URL}/admin/jobs/:jobId/orders/add`,
    },
    removeFromJob: {
      method: "DELETE",
      url: `${API_URL}/admin/jobs/:jobId/orders`,
    },
    getJobsByStatus: {
      method: "GET",
      url: `${API_URL}/admin/jobs/status`,
      reduce: (state, action) => {
        return {
          ...state,
          jobIds: action.body,
        };
      },
    },
    // pure Actions
    updateNewTicketState: {
      isPure: true,
      reduce: updateOrderNewTicketStateReduce,
    },
    updateTicketState: {
      isPure: true,
      reduce: updateOrderNewTicketStateReduce,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
