import React from "react";
import { Divider, Popover, Row, Select } from "antd";
import { v4 as uuidv4 } from "uuid";

import "../style.less";
import { ICustomerMarkerInput } from "./CustomerMarker.types";

const { Option } = Select;
const CustomerJobPickerMarker = ({
  info,
  jobId,
  color,
  availableJobs,
  handleMoveOrder,
  isMoveOrders,
}: ICustomerMarkerInput) => {
  const content = (
    <div className="info-window__wrapper">
      <ul className="infoList">
        {info?.length > 0 &&
          info.map((info: any) => (
            <React.Fragment key={uuidv4()}>
              <li>
                <label className="info-window__title">OrderId:</label>
                {info.id}
              </li>
              <li>
                <label className="info-window__title">Name:</label> {info.customerInfo?.name}
              </li>
              <li>
                <label className="info-window__title">Delivery time:</label> {info.deliveryTime ?? "--:--"}
              </li>
              <li>
                <label className="info-window__title">Address:</label>
                {info.deliveryPoint?.address}
              </li>
              <li>
                <label className="info-window__title">Phone:</label>
                {info.customerInfo?.phoneNumber}
              </li>
              <Row hidden={!isMoveOrders}>
                <label className="info-window__title">Assigned to Job :</label>

                <Select
                  showSearch
                  style={{ minWidth: "150px", marginRight: "10px" }}
                  defaultValue={info.jobId}
                  onSelect={(value: number) => {
                    handleMoveOrder(info.id, value, info.jobId);
                  }}
                  filterOption={(input, option) => {
                    return (option!.children as unknown as number)
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {availableJobs(info.jobId)?.map(({ jobId }: { jobId: number }) => (
                    <Option key={jobId}> Job n° {jobId}</Option>
                  ))}
                </Select>
              </Row>
              <Divider />
            </React.Fragment>
          ))}
      </ul>
    </div>
  );

  return (
    <Popover content={content} title="Customer" trigger="click">
      <div className="customerMarker" style={{ backgroundColor: color }} />
    </Popover>
  );
};

export default CustomerJobPickerMarker;
