import React, { useState } from "react";
import { DatePicker, Button } from "antd";

import Select, { Option } from "../../../../components/Select";
import ReportsModal from "../ReportsModal";

const { RangePicker } = DatePicker;

function EnvStatsConfig({ list, env, setEnv, setDateRange, downloadReport, retailersList, disabledTomorrowAndFuture }) {
  const [showModal, setShowModal] = useState(false);

  const onDateRangeChange = (momentDates) => {
    if (!momentDates) {
      return setDateRange({});
    }

    const [from, to] = momentDates.map((momentDate) => momentDate.format("YYYY-MM-DD"));
    setDateRange({ from, to });
  };

  const listToShow = (list || ['Qatar']).map(tenant => ({ key: tenant, title: tenant }));

  return (
    <section className="envConfigWrapper">
      <div>
        <p>Choose date</p>
        <RangePicker format="DD.MM.YYYY" onChange={onDateRangeChange} disabledDate={disabledTomorrowAndFuture} />
      </div>
      <div>
        <p>Choose environment</p>
        <Select
          loading={!list}
          onChange={setEnv}
          value={env}
          style={{ width: 140 }}
          list={listToShow}
          mapFunction={({ key, title }) => <Option value={key}>{title}</Option>}
        />
      </div>
      <div className="buttonWrapper">
        <Button onClick={() => setShowModal(true)}>Download report</Button>
      </div>
      <ReportsModal
        retailersList={retailersList}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        generateReport={downloadReport}
      />
    </section>
  );
}

export default EnvStatsConfig;
