import React, { useEffect, useState } from "react";
import { Table, Tag, Popconfirm, Button, Space, Spin } from "antd";
import { API_URL } from "src/constants/config";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";

const SuperInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchInvoices = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_URL}/superadmin/invoices`);
      const data = await response.json();
      setInvoices(data?.invoices);
    } catch (error) {
      console.error("Error fetching invoices:", error);
    } finally {
      setLoading(false);  
    }
  };

  const handleDelete = async (invoiceId) => {
    try {
      setInvoices((prevData) =>
        prevData.map((invoice) => (invoice.id === invoiceId ? { ...invoice, loading: true } : invoice)),
      );

      const authToken = localStorage.getItem("authTokenSuper");
      const response = await fetch(`${API_URL}/superadmin/invoice/${invoiceId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.ok) {
        fetchInvoices();
      } else {
        console.error("Failed to delete subscription:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting subscription:", error);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  const columns = [
    {
      title: "Invoice ID",
      dataIndex: "invoice_id",
      key: "invoice_id",
    },
    {
      title: "Company ID",
      dataIndex: "company_id",
      key: "company_id",
    },
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      key: "invoice_date",
      render: (invoice_date) => <span>{moment(invoice_date).format("MMMM DD, YYYY")}</span>
    },
    {
      title: "Billing Period",
      dataIndex: "billing_period",
      key: "billing_period",
    },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Identifier",
      dataIndex: "identifier",
      key: "identifier",
      render: (text, record) => <Tag key={`tag-${record.invoice_id}`}>{text}</Tag>, // Provide a unique key
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="small">
          <Popconfirm
            title="Are you sure you want to delete this invoice?"
            onConfirm={() => handleDelete(record.invoice_id)}
            okText="Yes"
            cancelText="No"
            icon={<Spin />}
          >
            <Button type="link" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="allDriversSettings">
      <h2>Invoices</h2>
      <Spin spinning={loading}>
        <Table
          dataSource={invoices}
          columns={columns}
          className="superAdminTables"
          rowKey={(record) => record?.invoice_id} 
        />
      </Spin>
    </div>
  );
};

export default SuperInvoices;
