/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { notification } from "antd";

import { getAuthData } from "../../helpers/authStorage";
import { getQueryParam } from "../../helpers/general";
import { actions as driverActions } from "../../redux/resources/driver";
import RegistrationSteps from "../../components/DataEntry/RegistrationSteps";
import PersonalInformationForm from "./components/PersonalInformationForm";
import UploadDocumentsForm from "./components/UploadDocumentsForm";
import YourCarForm from "./components/YourCarForm";
import "./style.less";

const DriverRegistration = ({
  location,
  confirmEmailDriver,
  putSelfProfileDriver,
  putSelfCarDriver,
  history,
  driverInfo,
  getSelfDriver,
  personalInfo,
  carInfo,
}) => {
  const [currentStep, setCurrentStep] = useState(JSON.parse(localStorage.getItem("DriverRegistrationStep")) || 0);
  const [uploadedImage, setUploadedImage] = useState({
    nationalId: "",
    drivingLicense: "",
    policeClearanceCertificate: "",
    carRegistrationDocuments: "",
  });

  const [currentDriverPhone, setCurrentDriverPhone] = useState("");
  const [tokenUpdated, setTokenUpdated] = useState(false);

  useEffect(() => {
    const queryParams = getQueryParam(location.search);

    const confirmDriverEmail = async (params) => {
      try {
        await confirmEmailDriver({}, { query: params });
        history.push("/driver_registration");
        setTokenUpdated(true);
      } catch (e) {
        history.push("/driver_signup");
      }
    };

    if (queryParams.confirmationToken) {
      confirmDriverEmail(queryParams);
    }
  }, [confirmEmailDriver, history, location.search]);

  useEffect(() => {
    const token = getAuthData().accessToken;

    if (token && tokenUpdated) {
      getSelfDriver();
    }
    localStorage.setItem("DriverRegistrationStep", currentStep.toString());
  }, [currentStep, getSelfDriver, tokenUpdated]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("driverPersonalInfo");
      localStorage.removeItem("driverCarInfo");
      localStorage.removeItem("DriverRegistrationStep");
    };
    // eslint-disable-next-line
  }, []);

  const onNextStep = () => {
    if (
      currentStep === 0 &&
      personalInfo?.phoneNumber &&
      (personalInfo?.phoneNumber === currentDriverPhone || !currentDriverPhone)
    ) {
      const current = currentStep + 2;
      setCurrentStep(current);
    } else {
      const current = currentStep + 1;
      setCurrentStep(current);
    }
  };

  const onPrevStep = () => {
    if (
      currentStep === 2 &&
      personalInfo?.phoneNumber &&
      (personalInfo?.phoneNumber === currentDriverPhone || !currentDriverPhone)
    ) {
      const current = currentStep - 2;
      setCurrentStep(current);
    } else {
      const current = currentStep - 1;
      setCurrentStep(current);
    }
  };

  const openErrorNotification = (error) => {
    notification.error({
      message: "Error",
      description: error.body && error.body.message && error.body.message,
    });
  };

  const areValuesChanged = (values) => {
    const currentValuesFields = Object.keys(values);
    const changedValue = currentValuesFields.find(
      (currentValueField) => driverInfo[currentValueField] !== values[currentValueField],
    );
    return changedValue;
  };

  const putSelfProfileDriverWithError = (values) => {
    const changedFieldName = areValuesChanged(values);
    return changedFieldName
      ? putSelfProfileDriver(values)
          .then(() => onNextStep())
          .catch((error) => openErrorNotification(error))
      : onNextStep();
  };

  const putSelfCarDriverWithError = (values) => {
    const changedFieldName = areValuesChanged(values);
    return changedFieldName
      ? putSelfCarDriver(values)
          .then(() => onNextStep())
          .catch((error) => openErrorNotification(error))
      : onNextStep();
  };

  const steps = [
    {
      title: (
        <span className="stepTitleDisplaying">
          <FormattedMessage id="page.step.driverInfo" defaultMessage="Driver Info" />
        </span>
      ),
      additionalTitle: (
        <span className="stepAdditionalTitle">
          <FormattedMessage id="page.step.driverInfo" defaultMessage="Driver Info" />
        </span>
      ),
      details: "Driver Info",
      content: (
        <PersonalInformationForm
          personalInfo={personalInfo}
          driverInfo={driverInfo}
          putSelfProfileDriverWithError={putSelfProfileDriverWithError}
          currentStep={currentStep}
          onPrevStep={onPrevStep}
          stepsLength={6}
          setCurrentDriverPhone={setCurrentDriverPhone}
        />
      ),
    },
    {
      title: (
        <span className="stepTitleDisplaying">
          <FormattedMessage id="page.step.carInfo" defaultMessage="Car Info" />
        </span>
      ),
      additionalTitle: (
        <span className="stepAdditionalTitle">
          <FormattedMessage id="page.step.carInfo" defaultMessage="Car Info" />
        </span>
      ),
      details: "Car Info",
      content: (
        <YourCarForm
          carInfo={carInfo}
          driverInfo={driverInfo}
          onNextStep={onNextStep}
          putSelfCarDriver={putSelfCarDriverWithError}
          currentStep={currentStep}
          onPrevStep={onPrevStep}
          stepsLength={6}
        />
      ),
    },
    {
      title: (
        <span className="stepTitleDisplaying">
          <FormattedMessage id="page.step.driver.documentUpload" defaultMessage="Document Upload" />
        </span>
      ),
      additionalTitle: (
        <span className="stepAdditionalTitle">
          <FormattedMessage id="page.step.driver.documentUpload" defaultMessage="Document Upload" />
        </span>
      ),
      details: "DocumensList Upload",
      content: (
        <UploadDocumentsForm
          driverInfo={driverInfo}
          onPrevStep={onPrevStep}
          uploadedImage={uploadedImage}
          setUploadedImage={setUploadedImage}
          onNextStep={onNextStep}
        />
      ),
    },
  ];
  const displayingSteps = steps;
  return (
    <div>
      <RegistrationSteps
        steps={displayingSteps}
        onNextStep={onNextStep}
        onPrevStep={onPrevStep}
        currentStep={currentStep}
      />
    </div>
  );
};

const MSTP = (state) => {
  return {
    driverInfo: state.driver.items,
    personalInfo: state.driver.selfInfo?.profile,
    carInfo: state.driver.selfInfo?.car,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(driverActions, dispatch),
}))(DriverRegistration);
