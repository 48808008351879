import React, { useState, useEffect } from "react";
import { Layout, Menu, Dropdown, Popconfirm } from "antd";
import { useHistory } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { FormattedMessage, useIntl } from "react-intl";
import { actions as retailerActions } from "../../../redux/resources/retailer";
import { ReactComponent as BeeboltFullLogo } from "../../../assets/beeboltFullLogo.svg";
import { ReactComponent as BeeBoltLogo } from "../../../assets/beeBoltLogo.svg";
import { ReactComponent as MenuIcon } from "../../../assets/menu.svg";
import { SelectLanguage } from "../../SelectLanguage";
import { useRetailerNotification } from "../../../layouts/RetailerLayout/useNotification";

import "./ordersWidgetHeader.less";

const { Header } = Layout;

const OrdersWidgetHeader = ({
  logoutRetailer,
  subscribeFirebaseRetailer,
  unsubscribeFirebaseRetailer,
  testNotificationsRetailer,
}) => {
  const [currentMenu, setCurrentMenu] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();

  const intl = useIntl();
  const { token } = useRetailerNotification({
    subscribeFirebaseRetailer,
    unsubscribeFirebaseRetailer,
    testNotificationsRetailer,
  });

  const retailerLogout = async () => {
    if (token) {
      console.log("unsubscribe", token);
      await unsubscribeFirebaseRetailer({ token });
    }

    logoutRetailer();
    document.location.href = "/retailer_login";
  };

  useEffect(() => {
    const pathname = window.location.pathname.split("/");

    const selected = pathname[2];
    if (selected !== currentMenu) {
      setCurrentMenu(selected);
    }
  }, [currentMenu]);

  const toggleMenu = () => {
    setCollapsed(!collapsed);
  };

  const handleClick = (e) => {
    setCurrentMenu(e.key);
  };

  const onLogoClick = () => history.push("/retailer_cabinet/home");

  const menu = (
    <Menu onClick={handleClick} selectedKeys={[currentMenu]} mode="horizontal" disabledOverflow>
      <SelectLanguage dropdownClassName="language-select-item" className="language-select-s" />

      <Menu.Item key="retailer_logout" className="logoutButton">
        <Popconfirm
          title={`${intl.formatMessage({
            id: "page.home.header.logout.confirm",
            defaultMessage: "Are you sure to log out?",
          })}`}
          onConfirm={retailerLogout}
          okText={`${intl.formatMessage({
            id: "yes",
            defaultMessage: "Yes",
          })}`}
          cancelText={`${intl.formatMessage({
            id: "no",
            defaultMessage: "No",
          })}`}
        >
          <LogoutOutlined /> <FormattedMessage id="page.home.header.logout" defaultMessage=" Log out" />
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );
  return (
    <Header className="retailerCabinetHeader">
      <div className="fullScreenHeader">
        {/* <Button onClick={getNotification}>Get Notif</Button> */}
        <div className="fullScreenHeader__logo-wrapper">
          <BeeboltFullLogo onClick={onLogoClick} />
        </div>

        {menu}
      </div>
      <div className="smallScreenHeader">
        <BeeBoltLogo />
        <Dropdown overlay={menu} trigger={["click"]}>
          <MenuIcon onClick={toggleMenu} />
        </Dropdown>
      </div>
    </Header>
  );
};
export default connect(null, (dispatch) => ({
  ...bindActionCreators(retailerActions, dispatch),
}))(OrdersWidgetHeader);
