import React, { useState, useEffect, FC } from "react";
import { Button, Form, Input, Modal, Radio, Select, notification, Row } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SimpleLocationModal from "../../../DataEntry/SimpleMapCoordinateDrawer";
import { getPhoneInputCountryCode } from "../../../../helpers/general";
import "../ordersWidgetHome.less";
import { IBulkOrdersModal, IFormOrder } from "../types";

const { Option } = Select;
const customErrorBox = (
  <div
    className="ordersModal-phone-error"
    style={{
      border: "1px solid red",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0%",
      pointerEvents: "none",
    }}
  />
);

const OrdersModal: FC<IBulkOrdersModal> = ({
  isAdmin,
  visible,
  selfStoresSelection,
  selfTemplateSelection,
  onUploadOrdersManually,
  onClose,
  retailer,
  retailers,
}) => {
  const [deliveryType, setDeliveryType] = useState("standard");
  const intl = useIntl();
  const [form] = Form.useForm();
  const isStoreDeliverySelected = deliveryType === "store";
  const [locationForModal, setLocationForModal] = useState<{ lat: number; long: number }>();
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [modalFieldKey, setModalFieldKey] = useState<number>(0);
  const [defaultStore, setDefaultStore] = useState<any>(undefined);
  const [retailerId, setRetailerId] = useState<number | undefined>(undefined);
  const [stores, setStores] = useState<any[]>([]);
  const [templateId, setTemplateId] = useState(
    selfTemplateSelection?.find((e) => e.status === "approved")?.id || "None selected",
  );
  const intitialValues = {
    delivery: "standard",
    orders: [{}],
  };

  useEffect(() => {
    if (selfStoresSelection) setStores(selfStoresSelection);
  }, [selfStoresSelection]);

  useEffect(() => {
    const mainStore = stores?.find((e: any) => e.main && !e.deletedAt);
    setDefaultStore(mainStore);
    const newForm = form.getFieldsValue();
    if (newForm?.orders?.[0]) {
      newForm.orders[0].pickupPoint = isStoreDeliverySelected ? mainStore?.id : mainStore?.name;
      form.setFieldsValue(newForm);
    }
    // eslint-disable-next-line
  }, [stores]);

  useEffect(() => {
    const mainStore = stores?.find((e) => e.main && !e.deletedAt);
    setDefaultStore(mainStore);
    const newForm = form.getFieldsValue();
    if (newForm?.orders?.[0]) {
      newForm.orders = newForm.orders.map((e: any) => {
        return { ...e, pickupPoint: isStoreDeliverySelected ? mainStore?.id : mainStore?.name };
      });
      form.setFieldsValue(newForm);
    }
    // eslint-disable-next-line
  }, [isStoreDeliverySelected]);

  const handleMapInput = (value: any) => {
    const values = form.getFieldsValue(true);
    values.orders[modalFieldKey] = {
      ...values.orders[modalFieldKey],
      location: "".concat(value?.lat, ", ", value?.long),
      address: value?.address,
    };
    form.setFieldsValue(values);
  };

  const onSubmit = () => {
    form.validateFields().then(async ({ orders }) => {
      let validOrders = [];
      if (!isStoreDeliverySelected) {
        validOrders = orders?.map(
          ({
            name,
            building,
            address,
            street,
            zone,
            location,
            phone,
            cashAmount,
            reference,
            pickupPoint,
            remarks,
          }: IFormOrder) => {
            const initialAddress = {
              address,
              building,
              street,
              zone,
            };
            let lat;
            let long;
            let deliveryPoint: any = {};
            deliveryPoint = {
              address,
              building,
              street,
              zone,
              initialAddress,
            };
            if (location) {
              lat = location.split(",")?.[0]?.trim();
              long = location.split(",")?.[1]?.trim();
              deliveryPoint.lat = lat;
              deliveryPoint.long = long;
            }

            return {
              additionalInfo: {
                unit: building,
                orderReference: reference,
                piecesNumber: null,
                shipperName: "",
                remarks,
              },
              cashAmount: Number(cashAmount) || 0,
              customer: { phoneNumber: phone },
              customerInfo: {
                initialAddress,
                name,
              },
              deliveryPoint,
              packages: [{ no: null }],
              pickupPoint: {
                name: pickupPoint,
              },
              ...(templateId !== "None selected" &&
                retailer.isCustomMessagingTemplatesAllowed && { messagingTemplateGroupId: templateId }),
            };
          },
        );
      } else {
        validOrders = orders?.map(
          ({ reference, remarks, name, phone, deliveryPoint, pickupPoint, cashAmount }: IFormOrder) => {
            if (deliveryPoint !== pickupPoint) {
              return {
                additionalInfo: {
                  remarks,
                  orderReference: reference,
                },
                phoneNumber: phone,
                cashAmount: Number(cashAmount) || 0,
                deliveryPointId: deliveryPoint,
                pickupPointId: pickupPoint,
                ...(templateId !== "None selected" &&
                  retailer.isCustomMessagingTemplatesAllowed && { messagingTemplateGroupId: templateId }),
                customerInfo: {
                  name,
                },
              };
            }
            notification.error({
              message: "Error",
              description: "Points can't be the same!",
            });
            return undefined;
          },
        );
      }
      if (validOrders.length) {
        try {
          if (isAdmin && !retailerId) {
            notification.info({ message: "Invalid request ", description: "Make sure to choose a retailer first !" });
          }
          await onUploadOrdersManually(validOrders, deliveryType, templateId, retailerId);
          // once call is done
          resetOrdersModalState();
        } catch (error) {
          notification.error({ message: "Error", description: "Unable to upload these orders !" });
        }
        onClose();
      }
    });
  };

  /*This will be called  after cancel or confirm*/
  const resetOrdersModalState = () => {
    // reseting the form
    form.resetFields();

    // reset state
    setDefaultStore(undefined);
    setModalFieldKey(0);
    setDeliveryType("standard"); // by default
    setTemplateId(selfTemplateSelection?.find((e) => e.status === "approved")?.id || "None selected");
  };
  const onCancelOrders = () => {
    onClose();
    resetOrdersModalState();
  };
  const onDeliveryChange = (e: any) => {
    setDeliveryType(e.target.value);
  };

  const onSearchLocation = (fieldkey: number) => (value: string) => {
    setModalFieldKey(fieldkey);
    if (/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(value)) {
      const coords = value.split(",");
      setLocationForModal({
        lat: parseFloat(coords?.[0]?.trim()),
        long: parseFloat(coords?.[1]?.trim()),
      });
    } else {
      setLocationForModal({ lat: stores[0]?.lat, long: stores[0]?.long });
    }
    setIsLocationModalOpen(true);
  };

  const handleSetRetailer = (id: number) => {
    setRetailerId(id);
    setStores(retailers?.find((retailer) => retailer.id == id)?.pickupPoints);
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancelOrders}
      closable={false}
      className="upload-orders-modal"
      footer={[
        <Button onClick={onCancelOrders} key="cancel">
          <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
        </Button>,
        <Button key="submit" id="upload-orders-submit" type="primary" onClick={onSubmit}>
          <FormattedMessage id="tabPane.actions.confirm" defaultMessage="Confirm" />
        </Button>,
      ]}
      title={"Create Bulk Orders"}
    >
      <Form form={form} initialValues={intitialValues} colon={false} wrapperCol={{ flex: "auto" }}>
        <h3 className="order-modal__section-title">Retailer Infos</h3>
        <Form.Item name="delivery" label="Delivery type" className="delivery-radio-group">
          <Radio.Group onChange={onDeliveryChange}>
            <Radio value="standard">Standard</Radio>
            {/* <Radio value="warehouse">Warehouse</Radio> */}
            <Radio value="store">Store-to-store</Radio>
          </Radio.Group>
        </Form.Item>
        <Row>
          {isAdmin && (
            <Form.Item
              label={"Retailer Name"}
              name={"retailerId"}
              rules={[{ required: true, message: "Select a retailer first !" }]}
            >
              <Select
                placeholder={"Retailer"}
                className="order-modal__select"
                value={retailerId}
                onSelect={(value: number) => handleSetRetailer(value)}
              >
                {retailers?.map((value) => (
                  <Option key={value.id}> {value.name}</Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item label="SMS Template">
            <Select
              placeholder={`${intl.formatMessage({
                id: "input.template",
                defaultMessage: "Template",
              })}`}
              value={templateId}
              onChange={setTemplateId}
              className="order-modal__select"
            >
              <Select.Option value="None selected" key="none">
                None selected
              </Select.Option>

              {selfTemplateSelection?.length > 0 && retailer?.isCustomMessagingTemplatesAllowed ? (
                <>
                  {" "}
                  {selfTemplateSelection
                    .filter((e) => e.status === "approved")
                    .map((template) => (
                      // eslint-disable-next-line
                      <Select.Option key={template.id} value={template.id}>
                        {/* eslint-disable-next-line */}
                        {"(" + template.id + ") "}
                        {template.name}
                      </Select.Option>
                    ))}{" "}
                </>
              ) : null}
            </Select>
          </Form.Item>
        </Row>

        <h3 className="order-modal__section-title">Orders list</h3>

        <div className={classnames("labels-row", isStoreDeliverySelected && "labels-row--store")}>
          <div className={isStoreDeliverySelected ? "labels-row__1_2" : "labels-row__1"}>Customer Name</div>
          <div className={isStoreDeliverySelected ? "labels-row__2_2" : "labels-row__2"}>Customer Phone</div>
          {!isStoreDeliverySelected && (
            <>
              <div className="labels-row__3">Customer Address</div>
              <div className="labels-row__4">Customer Location</div>
              <div className="labels-row__5">Zone</div>
              <div className="labels-row__6">Building</div>
              <div className="labels-row__7">Street</div>
              <div className="labels-row__8">Cash On Delivery</div>
            </>
          )}
          {isStoreDeliverySelected && <div className="labels-row__8_2">Cash amount</div>}
          <div className={isStoreDeliverySelected ? "labels-row__9_2" : "labels-row__9"}>Reference</div>
          <div className={isStoreDeliverySelected ? "labels-row__10_2" : "labels-row__10"}>Remarks</div>
          <div className="labels-row__11">Pickup Point</div>
          {isStoreDeliverySelected && <div className="labels-row__12">Delivery Point</div>}
        </div>
        <Form.List name="orders">
          {(fields, { add, remove }, meta) => {
            const onAddOrder = () =>
              add(
                defaultStore
                  ? { pickupPoint: isStoreDeliverySelected ? defaultStore.id : defaultStore.name }
                  : undefined,
              );
            const onRemoveOrder = (name: any) => () => remove(name);

            const isRemovable = fields?.length > 1;
            return (
              <>
                {fields.map(({ key, name, ...restField }) => {
                  return (
                    <div className="form-row" key={name}>
                      <Form.Item {...restField} name={[name, "name"]} rules={[{ required: true, message: "" }]}>
                        <Input
                          placeholder={`${intl.formatMessage({
                            id: "input.name",
                            defaultMessage: "Name",
                          })}`}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "phone"]}
                        rules={[
                          {
                            required: true,
                            message: customErrorBox,
                          },
                          { min: 8, message: customErrorBox },
                        ]}
                      >
                        <ReactPhoneInput
                          inputStyle={{
                            width: "200px",
                            height: "32px",
                          }}
                          defaultMask={".... .... ...."}
                          country={getPhoneInputCountryCode()}
                          placeholder={`${intl.formatMessage({ id: "input.phone", defaultMessage: "Phone" })}`}
                        />
                      </Form.Item>
                      {!isStoreDeliverySelected && (
                        <>
                          <Form.Item {...restField} name={[name, "address"]}>
                            <Input
                              placeholder={`${intl.formatMessage({
                                id: "input.input.address",
                                defaultMessage: "Address",
                              })}`}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "location"]}
                            dependencies={[[name, "location"]]}
                            rules={[
                              {
                                pattern:
                                  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
                                message: "",
                              },
                            ]}
                          >
                            <Input.Search
                              enterButton
                              onSearch={onSearchLocation(name)}
                              placeholder={`${intl.formatMessage({
                                id: "input.location",
                                defaultMessage: "Location",
                              })}`}
                            />
                          </Form.Item>
                          <Form.Item {...restField} name={[name, "zone"]}>
                            <Input
                              placeholder={`${intl.formatMessage({ id: "input.zone", defaultMessage: "Zone" })}`}
                            />
                          </Form.Item>
                          <Form.Item {...restField} name={[name, "building"]} rules={[{ required: true, message: "" }]}>
                            <Input
                              placeholder={`${intl.formatMessage({
                                id: "input.building",
                                defaultMessage: "Building",
                              })}`}
                            />
                          </Form.Item>
                          <Form.Item {...restField} name={[name, "street"]}>
                            <Input
                              placeholder={`${intl.formatMessage({ id: "input.street", defaultMessage: "Street" })}`}
                            />
                          </Form.Item>
                        </>
                      )}
                      <Form.Item {...restField} name={[name, "cashAmount"]}>
                        <Input
                          type="number"
                          placeholder={`${intl.formatMessage({ id: "input.amount", defaultMessage: "Amount" })}`}
                        />
                      </Form.Item>
                      <Form.Item {...restField} name={[name, "reference"]}>
                        <Input
                          placeholder={`${intl.formatMessage({ id: "input.reference", defaultMessage: "Reference" })}`}
                        />
                      </Form.Item>
                      <Form.Item {...restField} name={[name, "remarks"]}>
                        <Input
                          placeholder={`${intl.formatMessage({ id: "input.remarks", defaultMessage: "Remarks" })}`}
                        />
                      </Form.Item>
                      {!isStoreDeliverySelected && (
                        <Form.Item {...restField} name={[name, "pickupPoint"]}>
                          <Select
                            placeholder={`${intl.formatMessage({
                              id: "input.pickupPoint",
                              defaultMessage: "Pickup Point",
                            })}`}
                          >
                            {stores?.length > 0
                              ? stores
                                  .filter((e) => !e.deletedAt)
                                  .map((store) => (
                                    <Option key={store.id} value={store.name}>
                                      {`(${store.id}) `}
                                      {store.name}
                                    </Option>
                                  ))
                              : null}
                          </Select>
                        </Form.Item>
                      )}
                      {isStoreDeliverySelected && (
                        <div className="delivery-points-select">
                          <Form.Item
                            {...restField}
                            name={[name, "pickupPoint"]}
                            dependencies={[name, "deliveryPoint"]}
                            rules={[
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || form.getFieldsValue(true).orders[name].deliveryPoint !== value) {
                                    return Promise.resolve();
                                  }
                                  const values = form.getFieldsValue(true);
                                  values.orders[name].deliveryPoint = undefined;
                                  form.setFieldsValue(values);
                                  return Promise.resolve();
                                },
                              }),
                              { required: true, message: "" },
                            ]}
                          >
                            <Select
                              placeholder={`${intl.formatMessage({
                                id: "input.pickupPoint",
                                defaultMessage: "Pickup Point",
                              })}`}
                            >
                              {stores?.length > 0
                                ? stores
                                    .filter((e) => !e.deletedAt)
                                    .map((store) => (
                                      <Option key={store.id} value={store.id}>
                                        {`(${store.id}) `}
                                        {store.name}
                                      </Option>
                                    ))
                                : null}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "deliveryPoint"]}
                            dependencies={[name, "pickupPoint"]}
                            rules={[
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || form.getFieldsValue(true).orders[name].pickupPoint !== value) {
                                    return Promise.resolve();
                                  }
                                  const values = form.getFieldsValue(true);
                                  values.orders[name].pickupPoint = undefined;
                                  form.setFieldsValue(values);
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Select
                              placeholder={`${intl.formatMessage({
                                id: "input.deliveryPoint",
                                defaultMessage: "Delivery Point",
                              })}`}
                            >
                              {stores?.length > 0
                                ? stores
                                    .filter((e) => !e.deletedAt)
                                    .map((store) => (
                                      <Option key={store.id} value={store.id}>
                                        {`(${store.id}) `}
                                        {store.name}
                                      </Option>
                                    ))
                                : null}
                            </Select>
                          </Form.Item>
                        </div>
                      )}
                      {isRemovable && <MinusCircleOutlined onClick={onRemoveOrder(name)} />}
                    </div>
                  );
                })}
                <Form.Item>
                  <Button className="add-order" type="dashed" onClick={onAddOrder} block icon={<PlusOutlined />}>
                    Add order
                  </Button>
                </Form.Item>
              </>
            );
          }}
        </Form.List>
      </Form>
      <SimpleLocationModal
        location={locationForModal}
        setIsLocationModalOpen={setIsLocationModalOpen}
        isLocationModalOpen={isLocationModalOpen}
        submitLocation={handleMapInput}
      />
    </Modal>
  );
};

export default OrdersModal;
