import React, { useState } from "react";
import { DatePicker, List, Button } from "antd";
import moment from "moment";
// import { DownloadOutlined } from "@ant-design/icons";
import { ReactComponent as IconDownload } from "./assets/ico.svg";

import CustomDivider from "../../../components/Divider";

import "./style.less";

const ReportsCard = ({ title, list, name, setPagination, handleDownload, pagination }) => {
  const getReportDescription = ({ type, day, week, year, month }) => {
    switch (type) {
      case "daily":
        return `${"0".concat(day).slice(-2)}/${"0".concat(month).slice(-2)}/${year}`;
      case "weekly":
        return `week ${"0".concat(week).slice(-2)}/${year}`;
      case "monthly":
        return `month ${"0".concat(month).slice(-2)}/${year}`;
      default:
        return "";
    }
  };

  return (
    <div className="reports-card">
      <div className="reports-card-header">{title} </div>

      <div className="reports-card-list">
        <List
          pagination={{
            ...pagination,
            position: "bottom",
            size: "small",
            showSizeChanger: false,
            onChange: async (page) => {
              setPagination((old) => ({ ...old, [name]: { ...old[name], current: page } }));
            },
            pageSize: 8,
          }}
          dataSource={list}
          // footer={
          //   <div>
          //     <b>ant design</b> footer part
          //   </div>
          // }
          renderItem={(item) => (
            <List.Item
              className="reports-card-list-item"
              key={item.id}
              actions={[<IconDownload onClick={() => handleDownload(item.id)} />]}
              // extra={
              //   <img width={272} alt="logo" src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png" />
              // }
            >
              <List.Item.Meta
                title={
                  <div>
                    {item.type}_{item.id}
                  </div>
                }
                description={getReportDescription(item)}
              />
              {/* {item.content} */}
            </List.Item>
          )}
        />
        ,
      </div>
    </div>
  );
};

export default function ReportsPage({
  isMobile,
  handleReportRequest,
  handleDownload,
  pagination,
  setPagination,
  reports,
}) {
  const [date, setDate] = useState();

  const onChangeDate = function (value) {
    if (value) {
      setDate(value);
      handleReportRequest(value.endOf("day"));
    } else {
      setDate();
      handleReportRequest();
    }
  };

  function disabledDate(current) {
    return current && current > moment().startOf("day");
  }

  return (
    <div className="pageWrapper">
      <h2 className="pageTitle">Reports</h2>
      <CustomDivider />
      <DatePicker
        className="reports-group-datepicker"
        value={date}
        onChange={onChangeDate}
        disabledDate={disabledDate}
        renderExtraFooter={() => (
          <Button danger onClick={() => onChangeDate()}>
            Clear
          </Button>
        )}
      />
      <div className={isMobile ? "reports-group  reports-group __mobile" : "reports-group "}>
        <ReportsCard
          title={"Daily Reports"}
          name={"daily"}
          list={reports[0]}
          pagination={pagination.daily}
          setPagination={setPagination}
          onChangeDate={onChangeDate}
          date={date}
          setDate={setDate}
          handleDownload={handleDownload}
        />
        <ReportsCard
          title={"Weekly Reports"}
          name={"weekly"}
          list={reports[1]}
          pagination={pagination.weekly}
          setPagination={setPagination}
          onChangeDate={onChangeDate}
          date={date}
          setDate={setDate}
          handleDownload={handleDownload}
        />
        <ReportsCard
          title={"Monthly Reports"}
          name={"monthly"}
          list={reports[2]}
          pagination={pagination.monthly}
          setPagination={setPagination}
          onChangeDate={onChangeDate}
          date={date}
          setDate={setDate}
          handleDownload={handleDownload}
        />
      </div>
    </div>
  );
}
