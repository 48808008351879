import React from "react";

import { MobileTitle } from "../../../components/AdminPanel/Mobile/MobileTitle";
import { MobileSearch } from "../../../components/AdminPanel/Mobile/MobileSearch";
import { MobileCustomSelect } from "../../../components/AdminPanel/Mobile/MobileCustomSelect";
import { MobilePagination } from "../../../components/AdminPanel/Mobile/MobilePagination";
import { DriversTable } from "./components/DriversTable";
import { MobileLoader } from "../../../components/AdminPanel/Mobile/MobileLoader";

const statusOptions = [
  { text: "Accepted", value: "accepted" },
  { text: "Declined", value: "declined" },
  { text: "Pending", value: "pending" },
  { text: "Has issues", value: "has_issue" },
];

const searchFilter = [
  { text: "Email", value: "email" },
  { text: "Name", value: "name" },
  { text: "Plate number", value: "plateNumber" },
];

export const MobileView = ({
  loading,
  query,
  driver,
  drivers,
  driverAdditionalStats,
  selectedRetailer,
  paginationConfig,
  getJobsAndRetailers,
  setQuery,
  onChangeSearchFilter,
  changeStatusDriversManager,
  handleSearchDriver,
  handleAssignJob,
  handleCancelAssignJob,
  handleCompanyChange,
  loadOneDriversManager,
  loadDriverStatsDriversManager,
  loadDriverStatsJobCompletionSectionDriversManager,
  loadDriverStatsCashSectionDriversManager,
  trustDriverDriversManager,
  loadDriverStatsKMSectionDriversManager,
}) => {
  const onPageChange = (page, limit) => {
    setQuery({ ...query, page, limit });
    localStorage.setItem("driversOnPage", limit);
  };

  const onFilterStatus = (status) => {
    setQuery({ ...query, status });
  };

  const onDriverStatusChange = (status) => {
    changeStatusDriversManager({ id: driver?.id, status }).then(() => loadOneDriversManager({ id: driver?.id }));
  };

  return (
    <>
      <div className="mobile-page-wrapper">
        {loading && <MobileLoader />}
        <MobileTitle title="Drivers Management" />
        <MobileSearch
          searchFilter={searchFilter}
          defaultSearchFilterValue="email"
          onChangeSearchFilter={onChangeSearchFilter}
          onSearch={handleSearchDriver}
        />
        <MobileCustomSelect label="Status" options={statusOptions} onFilterStatus={onFilterStatus} />
        <DriversTable
          driverInfo={driver}
          drivers={drivers}
          driverAdditionalStats={driverAdditionalStats}
          selectedRetailer={selectedRetailer}
          statusOptions={statusOptions}
          getJobsAndRetailers={getJobsAndRetailers}
          onDriverStatusChange={onDriverStatusChange}
          handleAssignJob={handleAssignJob}
          handleCancelAssignJob={handleCancelAssignJob}
          handleCompanyChange={handleCompanyChange}
          loadOneDriversManager={loadOneDriversManager}
          loadDriverStatsDriversManager={loadDriverStatsDriversManager}
          loadDriverStatsJobCompletionSectionDriversManager={loadDriverStatsJobCompletionSectionDriversManager}
          loadDriverStatsCashSectionDriversManager={loadDriverStatsCashSectionDriversManager}
          trustDriverDriversManager={trustDriverDriversManager}
          loadDriverStatsKMSectionDriversManager={loadDriverStatsKMSectionDriversManager}
        />
        <MobilePagination onPageChange={onPageChange} {...paginationConfig} />
      </div>
    </>
  );
};
