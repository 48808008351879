import { createResource } from "redux-rest-resource";

import { API_URL } from "../../constants/config";

export const { types, rootReducer, actions } = createResource({
  name: "statistics",
  url: `${API_URL}/admin/jobs-history`,
  actions: {
    fetch: {
      reduce: (state, action) => {
        if (action.status !== "resolved") {
          return { ...state, isFetching: true };
        }
        return {
          ...state,
          items: [...action.body.items],
          pagination: action.body.meta,
          isFetching: false,
        };
      },
    },
    findDriver: {
      url: `${API_URL}/admin/drivers/search`,
      reduce: (state, action) => {
        if (action.status !== "resolved") {
          return { ...state };
        }
        return {
          ...state,
          searchedDrivers: action.body,
        };
      },
    },
    clearDriver: {
      isPure: true,
      reduce: (state) => {
        return { ...state, searchedDrivers: null };
      },
    },
  },
});
