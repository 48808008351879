import React, { useState } from "react";
import { Rate, Input, Button, Form } from "antd";

import "./style.less";

const { TextArea } = Input;

const DeliveryRating = ({ leaveDeliveryFeedbackCustomer, deliveryPointId, jobId }) => {
  const [rateError, setRateError] = useState("");
  const [text, setText] = useState("");
  const [orderText, setOrderText] = useState("");
  const [rate, setRate] = useState("");
  const [orderRate, setOrderRate] = useState("");
  const [page, setPage] = useState(1);
  const isDriverPage = page === 1;
  const isProductPage = page === 2;

  const onFinish = (values) => {
    if (isDriverPage) {
      setPage(2);
    } else {
      if (!values.rating || !values.orderRating) {
        return setRateError("Please select appropriate rating for both driver and product");
      }
      leaveDeliveryFeedbackCustomer({ values, jobId, deliveryPointId });
    }
  };

  const onRatingChange = (rate) => {
    if (rate && rateError) {
      setRateError("");
    }
    isDriverPage ? setRate(rate) : setOrderRate(rate);
  };

  const onTextChange = (e) => {
    isDriverPage ? setText(e.target.value) : setOrderText(e.target.value);
  };

  return (
    <div className="driverRatingWrapper">
      <h3 className="driverRatingWrapper__text">
        Please share your experience with your {isDriverPage ? "driver" : "product"} . Your feedback is very important
        to us.
      </h3>
      <Form
        name="basic"
        initialValues={{ rating: 0, body: "", orderRating: 0, orderRatingBody: "" }}
        onFinish={onFinish}
        className="driverRatingFormStyle"
      >
        <div style={{ display: isProductPage && "none" }}>
          <Form.Item name="rating" className="ratingFormItem">
            <Rate onChange={onRatingChange} className="rateStar" />
          </Form.Item>
          {rateError && <p style={{ margin: 0, color: "red" }}>{rateError}</p>}
          <Form.Item
            name="body"
            style={{ marginTop: "23px" }}
            rules={[{ required: true, message: "Feedback is required" }]}
          >
            <TextArea
              placeholder="Leave your feedback"
              onChange={onTextChange}
              autoSize={{ minRows: 5, maxRows: 8 }}
              maxLength={255}
              showCount={true}
            />
          </Form.Item>{" "}
        </div>
        <div style={{ display: isDriverPage && "none" }}>
          <Form.Item name="orderRating" className="ratingFormItem">
            <Rate onChange={onRatingChange} className="rateStar" />
          </Form.Item>
          {rateError && <p style={{ margin: 0, color: "red" }}>{rateError}</p>}
          <Form.Item
            name="orderRatingBody"
            style={{ marginTop: "23px" }}
            rules={[{ required: isProductPage, message: "Feedback is required" }]}
          >
            <TextArea
              placeholder="Leave your feedback"
              onChange={onTextChange}
              autoSize={{ minRows: 5, maxRows: 8 }}
              maxLength={255}
              showCount={true}
            />
          </Form.Item>
        </div>

        <Form.Item>
          <Button
            disabled={isDriverPage ? !text || !rate : !orderText || !orderRate}
            type="primary"
            htmlType="submit"
            className="shareFeedbackButton"
          >
            {isDriverPage ? "Next page" : "Send feedback"}
          </Button>
        </Form.Item>
        {isProductPage && (
          <Form.Item>
            <Button
              onClick={() => {
                setPage(1);
              }}
              className="shareFeedbackButton"
            >
              Previous page
            </Button>
          </Form.Item>
        )}
      </Form>
    </div>
  );
};

export default DeliveryRating;
