import { useEffect, useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { assignIn, omit } from "lodash";
import { message, notification } from "antd";

import { getQueryParam, updatePageUrl } from "../../../helpers/general";

const WithoutCompleted =
  "new&status=postponed&status=pickup_completed&status=in_transit&status=arrived&status=canceled&status=canceled_after_pickup&status=postponed_after_pickup&status=failed_to_deliver&status=in_delivery";

export const useHooks = ({
  isMobile,
  adminOrders,
  fetchAdminOrders,
  saveUploadedAdminOrder,
  paginationData,
  fetchListAdminRetailers,
  returnToShipperAdminOrder,
  getAdminTablesView,
  addToJobAdminOrder,
  removeFromJobAdminOrder,
  getJobsByStatusDeliveryJobs,
  createAdminTicket,
}) => {
  const [isOrdersSaving, setIsOrdersSaving] = useState(false);
  const [selectedOrderTrackingId, setSelectedOrderTrackingId] = useState("");
  const [filteredRetailer, setFilteredRetailer] = useState(null);
  const [filteredLocationShared, setFilteredLocationShared] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();

  const getOrdersManagmentQueryParams = useCallback(() => getQueryParam(location.search), [location.search]);

  const fetchAllOrders = useCallback(
    (otherQueries) => {
      const queryParams = getOrdersManagmentQueryParams();
      const queryWithPage = queryParams.page ? queryParams : { ...queryParams, page: 1, limit: 10 };
      const withOtherQueries = otherQueries ? assignIn(queryWithPage, otherQueries, queryParams) : queryWithPage;
      fetchAdminOrders(
        {},
        {
          query: withOtherQueries,
        },
      ).catch((error) =>
        notification.error({
          message: "Error",
          description: error?.body?.message,
        }),
      );
    },
    [fetchAdminOrders, getOrdersManagmentQueryParams],
  );

  const updateOrdersManagementPageUrl = (newQueryParams, queriesToRemove) => {
    const previousQueryParams = omit(getOrdersManagmentQueryParams(), Object.keys(queriesToRemove));
    updatePageUrl(newQueryParams, previousQueryParams, history, "admin/orders_managment");
  };

  const changeQueryParams = (pagination, filters) => {
    const search = omit(pagination, ["pageSizeOptions", "position", "showSizeChanger", "total", "pageSize", "current"]);
    const isLocationShared = filters?.locationShared?.[0] ? filters?.locationShared?.[0] : null;
    const isRetailerShared = filters?.retailerName?.[0] ? filters?.retailerName?.[0] : null;
    setFilteredLocationShared(isLocationShared);
    setFilteredRetailer(isRetailerShared);

    updateOrdersManagementPageUrl(
      {
        ...search,
        ...(filters?.locationShared && { locationShared: isLocationShared }),
        ...(filters?.retailerName && { retailerName: isRetailerShared }),
        page: pagination?.current,
        limit: pagination?.pageSize,
      },
      {
        ...(!filters?.locationShared && { locationShared: true }),
        ...(!filters?.retailerName && { retailerName: true }),
      },
    );
  };

  const currentQueryParams = getOrdersManagmentQueryParams();

  useEffect(() => {
    fetchListAdminRetailers();

    getAdminTablesView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ordersListPagination = {
    pageSize: currentQueryParams?.limit || 10,
    total: paginationData?.totalItems || 50,
    current: Number(currentQueryParams?.page) || 1,
    pageSizeOptions: isMobile ? [5, 10, 20] : [10, 20, 30, 50],
    showSizeChanger: true,
    position: ["bottomRight"],
  };

  const handleSelectOrder = (order) => {
    setSelectedOrderTrackingId(order);
  };

  const saveAdminOrders = async (response, retailerId) => {
    console.log(response, retailerId);
    setIsOrdersSaving(true);
    try {
      await saveUploadedAdminOrder(response, { query: { retailerId } });
      await fetchAllOrders({ status: WithoutCompleted });
      setIsOrdersSaving(false);
      message.success(
        `${response.length} ${intl.formatMessage({
          id: "page.ordersManagment.ordersUploaded",
          defaultMessage: "orders uploaded successfully.",
        })}`,
      );
    } catch (error) {
      setIsOrdersSaving(false);
    }
  };

  const returnToShipper = async ({ orderId }) => {
    try {
      await returnToShipperAdminOrder({ orderId });
      notification.success({
        message: `${intl.formatMessage({
          id: "success.returnToShipper",
          defaultMessage: "Order was returned to shipper!",
        })}`,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to create return to shipper.",
      });
    }
  };

  const addOrdersToJob = async ({ jobId, ordersIds }) => {
    try {
      await addToJobAdminOrder({ jobId, ordersIds });
      notification.success({
        message: `${intl.formatMessage({
          id: "success.addOrdersToJob",
          defaultMessage: `Selected orders were assigned to Job ${jobId}!`,
          value: { jobId: jobId },
        })}`,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to assign orders to Job.",
      });
    } finally {
      fetchAllOrders();
    }
  };

  const removeOrdersFromJob = async ({ jobId, ordersIds }) => {
    try {
      await removeFromJobAdminOrder({ jobId, ordersIds });
      notification.success({
        message: `${intl.formatMessage({
          id: "success.removeOrdersToJob",
          defaultMessage: `Selected orders were removed from their previous Jobs !`,
        })}`,
      });
    } catch (error) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to remove orders from Jobs.",
      });
    } finally {
      fetchAllOrders();
    }
  };

  const createTicket = (ticket) => {
    createAdminTicket(ticket)
      .then(() => {
        notification.success({
          message: "Success",
          description: "Ticket created successfully.",
        });
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error?.body?.message || "OOPS! Unable to create ticket",
        });
      });
  };

  const selectedRow = adminOrders?.find((row) => row.trackingId === selectedOrderTrackingId);

  return {
    selectedRow,
    selectedOrderTrackingId,
    setSelectedOrderTrackingId,
    handleSelectOrder,
    isOrdersSaving,
    saveAdminOrders,
    ordersListPagination,
    changeQueryParams,
    fetchAllOrders,
    filteredRetailer,
    filteredLocationShared,
    returnToShipper,
    removeOrdersFromJob,
    addOrdersToJob,
    createTicket,
  };
};
