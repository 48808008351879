import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions as adminOrdersActions } from "../../redux/resources/adminOrders";
import { actions as adminRetailersActions } from "../../redux/resources/adminRetailers";
import { actions as adminTablesViewActions } from "../../redux/resources/adminTablesView";
import { actions as adminTicketActions } from "../../redux/resources/adminTicket";

import { DesktopView } from "./DesktopView";
import { MobileView } from "./MobileView";
import { useHooks } from "./hooks/useHooks";

import "./ordersManagement.less";

const OrdersManagement = ({
  isMobile,
  tablesView,
  getAdminTablesView,
  setAdminTablesView,
  fetchAdminOrders,
  saveUploadedAdminOrder,
  cancelAdminOrder,
  deleteAdminOrder,
  postponeAdminOrder,
  adminOrders,
  paginationData,
  isAdminOrdersFetching,
  bulkDeleteAdminOrder,
  changeLocationAdminOrder,
  removeLocationAdminOrder,
  returnToShipperAdminOrder,
  fetchListAdminRetailers,
  createReturnAdminOrder,
  completeOrderAdminOrder,
  cancelCompletedAdminOrder,
  editPhoneAdminOrder,
  retailersList,
  restoreAdminOrder,
  restoreFailedAdminOrder,
  getReportsAdminOrder,
  editInitialAddressAdminOrder,
  fetchOrderHistoryAdminOrder,
  fetchReturnShipperFormAdminOrder,
  addToJobAdminOrder,
  removeFromJobAdminOrder,
  getJobsByStatusAdminOrder,
  jobIds,
  createAdminTicket,
}) => {
  const {
    selectedRow,
    selectedOrderTrackingId,
    isOrdersSaving,
    ordersListPagination,
    filteredRetailer,
    filteredLocationShared,
    filteredAddress,
    fetchAllOrders,
    setSelectedOrderTrackingId,
    handleSelectOrder,
    saveAdminOrders,
    changeQueryParams,
    returnToShipper,
    removeOrdersFromJob,
    addOrdersToJob,
    createTicket,
  } = useHooks({
    isMobile,
    adminOrders,
    fetchAdminOrders,
    saveUploadedAdminOrder,
    paginationData,
    fetchListAdminRetailers,
    returnToShipperAdminOrder,
    getAdminTablesView,
    addToJobAdminOrder,
    removeFromJobAdminOrder,
    createAdminTicket,
  });

  return (
    <>
      {isMobile ? (
        <MobileView
          isOrdersSaving={isOrdersSaving}
          isAdminOrdersFetching={isAdminOrdersFetching}
          adminOrders={adminOrders}
          retailersList={retailersList}
          selectedRow={selectedRow}
          filteredRetailer={filteredRetailer}
          filteredLocationShared={filteredLocationShared}
          paginationConfig={ordersListPagination}
          fetchAllOrders={fetchAllOrders}
          saveAdminOrders={saveAdminOrders}
          setSelectedOrderTrackingId={setSelectedOrderTrackingId}
          handleSelectOrder={handleSelectOrder}
          changeQueryParams={changeQueryParams}
          postponeAdminOrder={postponeAdminOrder}
          cancelAdminOrder={cancelAdminOrder}
          deleteAdminOrder={deleteAdminOrder}
          deleteAdminOrders={bulkDeleteAdminOrder}
          createReturnAdminOrder={createReturnAdminOrder}
          completeOrderAdminOrder={completeOrderAdminOrder}
          changeLocationAdminOrder={changeLocationAdminOrder}
          removeLocationAdminOrder={removeLocationAdminOrder}
          editPhoneAdminOrder={editPhoneAdminOrder}
          restoreFailedAdminOrder={restoreFailedAdminOrder}
          getReportsAdminOrder={getReportsAdminOrder}
          removeOrdersFromJob={removeOrdersFromJob}
          addOrdersToJob={addOrdersToJob}
          incompleteJobs={jobIds}
          getJobsByStatusAdminOrder={getJobsByStatusAdminOrder}
          cancelCompletedAdminOrder={cancelCompletedAdminOrder}
        />
      ) : (
        <DesktopView
          tablesView={tablesView?.item}
          setCurrentTableViews={setAdminTablesView}
          isOrdersSaving={isOrdersSaving}
          isAdminOrdersFetching={isAdminOrdersFetching}
          editInitialAddressAdminOrder={editInitialAddressAdminOrder}
          adminOrders={adminOrders}
          retailersList={retailersList}
          selectedRow={selectedRow}
          selectedOrder={selectedOrderTrackingId}
          filteredRetailer={filteredRetailer}
          filteredLocationShared={filteredLocationShared}
          filteredAddress={filteredAddress}
          ordersListPagination={ordersListPagination}
          fetchAllOrders={fetchAllOrders}
          saveAdminOrders={saveAdminOrders}
          setSelectedOrder={setSelectedOrderTrackingId}
          handleSelectOrder={handleSelectOrder}
          changeQueryParams={changeQueryParams}
          returnToShipper={returnToShipper}
          postponeAdminOrder={postponeAdminOrder}
          cancelAdminOrder={cancelAdminOrder}
          deleteAdminOrder={deleteAdminOrder}
          bulkDeleteAdminOrder={bulkDeleteAdminOrder}
          createReturnAdminOrder={createReturnAdminOrder}
          completeOrderAdminOrder={completeOrderAdminOrder}
          restoreAdminOrder={restoreAdminOrder}
          restoreFailedAdminOrder={restoreFailedAdminOrder}
          getReportsAdminOrder={getReportsAdminOrder}
          changeLocationAdminOrder={changeLocationAdminOrder}
          removeLocationAdminOrder={removeLocationAdminOrder}
          editPhoneAdminOrder={editPhoneAdminOrder}
          fetchOrderHistoryAdminOrder={fetchOrderHistoryAdminOrder}
          fetchReturnShipperFormAdminOrder={fetchReturnShipperFormAdminOrder}
          removeOrdersFromJob={removeOrdersFromJob}
          addOrdersToJob={addOrdersToJob}
          getJobsByStatusAdminOrder={getJobsByStatusAdminOrder}
          incompleteJobs={jobIds}
          createTicket={createTicket}
          cancelCompletedAdminOrder={cancelCompletedAdminOrder}
        />
      )}
    </>
  );
};

const MSTP = (state) => {
  const adminOrders = state.adminOrders.items[0]?.items;
  const paginationData = state.adminOrders.items[0] && state.adminOrders.items[0]?.meta;
  const tablesView = state.adminTablesView;
  const jobIds = state.adminOrders.jobIds;
  return {
    adminOrders,
    isAdminOrdersFetching: state.adminOrders.isFetching,
    paginationData,
    retailersList: state.adminRetailers.list,
    isMobile: state.admin.isMobile,
    tablesView,
    jobIds,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(adminOrdersActions, dispatch),
  ...bindActionCreators(adminRetailersActions, dispatch),
  ...bindActionCreators(adminTablesViewActions, dispatch),
  ...bindActionCreators(adminTicketActions, dispatch),
}))(OrdersManagement);
