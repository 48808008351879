import React from "react";
import { Spin } from "antd";

import OrdersWidgetHome from "../../../components/OrdersWidget/OrdersWidgetHome";
import OrderDetails from "./components/OrderDetails";

export const DesktopView = ({
  tablesView,
  setCurrentTableViews,
  selectedRow,
  selectedOrder,
  setSelectedOrder,
  handleSelectOrder,
  isAdminOrdersFetching,
  isOrdersSaving,
  saveAdminOrders,
  adminOrders,
  ordersListPagination,
  changeQueryParams,
  cancelAdminOrder,
  deleteAdminOrder,
  bulkDeleteAdminOrder,
  postponeAdminOrder,
  fetchAllOrders,
  returnToShipper,
  createReturnAdminOrder,
  completeOrderAdminOrder,
  removeLocationAdminOrder,
  cancelCompletedAdminOrder,
  retailersList,
  filteredRetailer,
  filteredLocationShared,
  changeLocationAdminOrder,
  editPhoneAdminOrder,
  restoreAdminOrder,
  restoreFailedAdminOrder,
  getReportsAdminOrder,
  editInitialAddressAdminOrder,
  fetchOrderHistoryAdminOrder,
  fetchReturnShipperFormAdminOrder,
  filteredAddress,

  removeOrdersFromJob,
  addOrdersToJob,
  getJobsByStatusAdminOrder,
  incompleteJobs,
  createTicket,
}) => {
  return (
    <div className="ordersManegmentWrapper">
      {isOrdersSaving && <Spin className="spinner" />}
      <OrdersWidgetHome
        isAdminOrders={true}
        tablesView={tablesView}
        setCurrentTableViews={setCurrentTableViews}
        editInitialAddressOrder={editInitialAddressAdminOrder}
        onHandleSelectOrder={handleSelectOrder}
        isAdminOrdersFetching={isAdminOrdersFetching}
        userURL="/admin/orders/upload"
        saveOrders={saveAdminOrders}
        ordersList={adminOrders}
        pagination={ordersListPagination}
        onChangeQueryParams={changeQueryParams}
        onCancelOrder={cancelAdminOrder}
        onDeleteOrder={deleteAdminOrder}
        onDeleteOrders={bulkDeleteAdminOrder}
        onPostponeOrder={postponeAdminOrder}
        onFetchAllOrders={fetchAllOrders}
        onCreateReturn={createReturnAdminOrder}
        onCompleteOrder={completeOrderAdminOrder}
        onRemoveLocation={removeLocationAdminOrder}
        retailersList={retailersList}
        filteredRetailer={filteredRetailer}
        filteredLocationShared={filteredLocationShared}
        filteredAddress={filteredAddress}
        onChangeLocationAdminOrder={changeLocationAdminOrder}
        editPhone={editPhoneAdminOrder}
        returnToShipper={returnToShipper}
        restoreAdminOrder={restoreAdminOrder}
        restoreFailedAdminOrder={restoreFailedAdminOrder}
        getReportsAdminOrder={getReportsAdminOrder}
        fetchOrderHistoryAdminOrder={fetchOrderHistoryAdminOrder}
        fetchReturnShipperForm={fetchReturnShipperFormAdminOrder}
        removeOrdersFromJob={removeOrdersFromJob}
        addOrdersToJob={addOrdersToJob}
        getJobsByStatusAdminOrder={getJobsByStatusAdminOrder}
        incompleteJobs={incompleteJobs}
        createTicket={createTicket}
        onCancelCompletedOrder={cancelCompletedAdminOrder}
      />
      <OrderDetails visible={selectedOrder} onClose={() => setSelectedOrder("")} selectedRow={selectedRow} />
    </div>
  );
};
