import React from "react";
import { FormattedMessage } from "react-intl";

import { Link } from "react-router-dom";
import arrowRight from "../../assets/arrow-right.png";
import "./style.less";

const HelpLink = () => (
  <Link to="/" className="helpLinkWrapper">
    {/* <a href="URL" className="helpLinkWrapper"> */}
    <span className="troubleQuestion">
      <FormattedMessage id="steps.havingTroubles" defaultMessage="Having Troubles?" />
    </span>
    <span className="helpLink">
      <FormattedMessage id="steps.getHelp" defaultMessage="Get Help" />
    </span>
    <img src={arrowRight} alt="Arrow right" />
    {/* </a> */}
  </Link>
);

export default HelpLink;
