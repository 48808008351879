import { useMemo, useEffect, useState } from "react";
import { notification } from "antd";

import { API_URL } from "../../../constants/config";
import { getAuthData, getUserTenant } from "../../../helpers/authStorage";
import getDefaultCenterFromDegrees from "../../../helpers/getDefaultCenterFromDegrees";
import { customFetch } from "../../../utils/customFetch";

interface IUseJobHandlersProps {
  getBadDeliveryJob: any;
  fetchDeliveryJobs: any;
  togglePublishDeliveryJob: any;
  updateCashDeliveryJob: any;
  deleteIncompleteDeliveryJob: any;
  getRoutesDeliveryJob?: any;
  isAdmin: boolean;
}

interface IUseJobHandlersReturn {
  withErrors: any;
  setWithErrors: any;
  badDeliveryJobCount: any;
  selectedOrder: any;
  centerCoordinates: any;
  setSelectedOrder: any;
  handlePublishJob: any;
  downloadJobOrdersBarcodes: any;
  calculateAndDisplayRoute: any;
  updateOrderCash: any;
  cleanAllJobs: any;
  cleanJob: any;
  printAllJobs: any;
  selectedJobs: any;
  onJobsSelected: any;
}

type TIUseJobHandlers = (param: IUseJobHandlersProps) => IUseJobHandlersReturn;

export const useJobHandlers: TIUseJobHandlers = ({
  getBadDeliveryJob,
  fetchDeliveryJobs,
  togglePublishDeliveryJob,
  updateCashDeliveryJob,
  deleteIncompleteDeliveryJob,
  getRoutesDeliveryJob,
  isAdmin,
}) => {
  const API_ROLE = isAdmin ? "admin" : "retailers/self";
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [withErrors, setWithErrors] = useState<any>(false);
  const [badDeliveryJobCount, setBadDeliveryJobCount] = useState<any>(0);
  const [selectedJobs, onJobsSelected] = useState<any[]>([]);

  useEffect(() => {
    fetchDeliveryJobs({}, { query: { withError: withErrors } });
    // getBadDeliveryJob().then((res: any) => {
    //   setBadDeliveryJobCount(res?.body?.count);
    // });
  }, [fetchDeliveryJobs, withErrors, getBadDeliveryJob]);

  const handlePublishJob = (jobId: any, publish: any) => () => {
    togglePublishDeliveryJob({ jobId, published: publish }).then(() =>
      fetchDeliveryJobs({}, { query: { withError: withErrors } }),
    );
  };

  const getData = async (url = "") => {
    const response = await customFetch(url, {
      method: "GET",
      cache: "no-cache",

      headers: {
        Authorization: `Bearer ${getAuthData().accessToken}`,
        Tenant: getUserTenant(),
        contentType: "application/pdf",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return response.blob();
  };

  const downloadJobOrdersBarcodesLocal = async (id: any) => {
    const blob = await getData(`${API_URL}/${API_ROLE}/orders/receipts?jobId=${id}`);
    const url = window.URL.createObjectURL(new Blob([blob], { type: "application/pdf" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `receipts_${Date.now()}_${id}`);
    document.body.appendChild(link);
    // Force download from link
    link.click();
    // Clean up to remove the link
    link?.parentNode?.removeChild(link);
  };

  const downloadJobOrdersBarcodes = async (id: any) => {
    await downloadJobOrdersBarcodesLocal(id);
  };

  const centerCoordinates = useMemo(() => {
    const coordinatesArray = [
      [Number(selectedOrder?.deliveryPoint?.lat), Number(selectedOrder?.deliveryPoint?.long)],
      [Number(selectedOrder?.pickupPoint?.lat), Number(selectedOrder?.pickupPoint?.long)],
    ];
    return getDefaultCenterFromDegrees(coordinatesArray);
  }, [selectedOrder]);

  const calculateAndDisplayRoute = (deliveryPoints: any, polyline: any, map: any, maps: any) => {
    const addMarker = (position: any, i: any) => {
      return new maps.Marker({
        icon: `http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${i}|FF0000|FFFFFF`,
        position,
        map,
      });
    };

    const directionsService = new maps.DirectionsService();
    const directionsDisplay = new maps.DirectionsRenderer({ suppressMarkers: true });

    directionsDisplay.setMap(map);
    const waypoints = deliveryPoints?.map((item: any) => {
      return {
        location: { lat: item.lat, lng: item.long },
        stopover: true,
      };
    });

    if (polyline !== undefined && maps.geometry) {
      // decode the path
      var decodedPath = maps.geometry.encoding.decodePath(polyline);
      const Path = new maps.Polyline({
        path: decodedPath,
        geodesic: true,
        strokeColor: "#0000FF",
        strokeOpacity: 1.0,
        strokeWeight: 3,
      });

      Path.setMap(map);

      let markerCounter = 1;
      addMarker(waypoints[0].location, markerCounter++);
      for (let i = 1; i < waypoints.length; i++) {
        addMarker(waypoints[i].location, markerCounter++);
      }
    } else {
      const origin = waypoints?.shift()?.location;
      const destination = waypoints?.pop()?.location;

      directionsService.route(
        {
          origin,
          destination,
          waypoints,
          travelMode: "DRIVING",
        },
        (response: any, status: any) => {
          if (status === "OK") {
            let markerCounter = 1;
            directionsDisplay.setDirections(response);
            let route = response.routes[0];
            addMarker(route.legs[0].start_location, markerCounter++);
            for (let i = 0; i < route.legs.length; i++) {
              addMarker(route.legs[i].end_location, markerCounter++);
            }
          } else {
            notification.error({ message: `Directions request failed due to ${status}` });
          }
        },
      );
    }
  };

  const updateOrderCash = async (cashAmount: any, jobId: any) => {
    try {
      await updateCashDeliveryJob({ orderId: selectedOrder.id }, { body: { cashAmount: +cashAmount } });
      setSelectedOrder({ ...selectedOrder, cashAmount: +cashAmount });
      if (getRoutesDeliveryJob && jobId) {
        getRoutesDeliveryJob({ jobId });
      } else {
        fetchDeliveryJobs({}, { query: { withError: withErrors } });
      }
    } catch (e: any) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Error on cash update.",
      });
    }
  };
  const cleanJob = async (id: any) => {
    try {
      await deleteIncompleteDeliveryJob({}, { body: { jobIds: [id] } });
      notification.success({
        message: "Success",
        description: "Incomplete orders have been removed successfully",
      });
      await fetchDeliveryJobs({}, { query: { withError: withErrors } });
    } catch (e: any) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Error on clean jobs.",
      });
    }
  };
  const cleanAllJobs = async () => {
    try {
      await deleteIncompleteDeliveryJob({}, { body: { jobIds: selectedJobs } });
      notification.success({
        message: "Success",
        description: "Incomplete orders have been removed successfully",
      });
      await fetchDeliveryJobs({}, { query: { withError: withErrors } });
    } catch (e: any) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Error on clean jobs.",
      });
    } finally {
      onJobsSelected([]);
    }
  };

  const printAllJobs = async () => {
    try {
      if (selectedJobs.length === 0) {
        notification.warn({
          message: "Warn",
          description: "Please select at least one Job to print",
        });
        return;
      }

      for (let index = 0; index < selectedJobs.length; index++) {
        let jobId = selectedJobs[index];
        await downloadJobOrdersBarcodes(jobId);
      }
    } catch (e: any) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Error on printing jobs.",
      });
    } finally {
      onJobsSelected([]);
    }
  };

  return {
    withErrors,
    setWithErrors,
    badDeliveryJobCount,
    selectedOrder,
    centerCoordinates,
    setSelectedOrder,
    handlePublishJob,
    downloadJobOrdersBarcodes,
    calculateAndDisplayRoute,
    updateOrderCash,
    cleanAllJobs,
    cleanJob,
    printAllJobs,
    selectedJobs,
    onJobsSelected,
  };
};
