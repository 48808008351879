import { notification } from "antd";
import { isRetailer } from "src/helpers/authStorage";

interface IUseCombineJobsRoutingProps {
  fetchJobsMaps: any;
  combineJobsForCombine: any;
  splitJobsForCombine: any;
  clearJobsForCombine: any;
  moveOrdersJobsMap: ({ jobId, ids }: { jobId: number; ids: number[] }) => Promise<void>;
}

type IUseCombineJobsRouting = (props: IUseCombineJobsRoutingProps) => {
  fetchJobs: any;
  combineJobs: (selectedJobs: number[]) => Promise<void>;
  splitJobs: (selectedJobs: number) => Promise<void>;
  clearCombineJob: () => void;
  moveOrderFromJob: (orderId: number, jobId: number, originalJobId: number) => Promise<void>;
};

export const useCombineJobsRouting: IUseCombineJobsRouting = ({
  fetchJobsMaps,
  combineJobsForCombine,
  splitJobsForCombine,
  clearJobsForCombine,
  moveOrdersJobsMap,
}) => {
  const statusQuery = "ready&status=incomplete&status=new"; // TODO make it a state

  const fetchJobs = async () => {
    try {
      await fetchJobsMaps({}, { query: { status: statusQuery } });
    } catch (error: any) {
      notification.error({ message: "Error in fetching Jobs", description: error.body.message });
    }
  };

  const combineJobs = async (selectedJobs: number[]): Promise<void> => {
    try {
      //TODO: API Refactor required
      await combineJobsForCombine(
        isRetailer
          ? {
              jobIds: selectedJobs,
            }
          : selectedJobs.map((jobId) => ({ id: jobId })),
        { query: { save: true, force: true } },
      );
      fetchJobs();
      notification.success({ message: "Combined jobs successfully" });
    } catch (error: any) {
      notification.error({ message: "Error in Combining Jobs", description: error.body.message });
    }
  };

  const splitJobs = async (selectedJobs: number): Promise<void> => {
    splitJobsForCombine({ jobId: selectedJobs })
      .then(() => {
        notification.success({ message: `Splited job ${selectedJobs} successfully` });
        fetchJobs();
      })
      .catch((error: any) => notification.error({ message: "Error", description: error.body.message }));
  };

  const clearCombineJob = async () => {
    try {
      await clearJobsForCombine();
    } catch (error: any) {
      notification.error({ message: "Error to get closest job for combine", description: error.body.message });
    }
  };

  const moveOrderFromJob = async (orderId: number, jobId: number, originalJobId: number) => {
    try {
      await moveOrdersJobsMap({ jobId: jobId, ids: [orderId] });
      notification.success({
        message: `Order ${orderId} was successfully moved from Job # ${originalJobId} to Job # ${jobId}`,
      });
    } catch (error: any) {
      notification.error({
        message: "Moving Orders to new job has failed!",
      });
    }
  };

  return {
    fetchJobs,
    combineJobs,
    splitJobs,
    clearCombineJob,
    moveOrderFromJob,
  };
};
