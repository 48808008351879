import React from "react";
import { Pie } from "@ant-design/charts";

export default function ChartDashboardBox({ children, dashboardStats }) {
  const data = [
    {
      name: "Total delivered orders",
      value: dashboardStats?.totalDelivered,
    },
    {
      name: "Total of cancelled users",
      value: dashboardStats?.totalCanceled,
    },
    {
      name: "Total of returns",
      value: dashboardStats?.totalReturned,
    },
    {
      name: "Total postponed orders",
      value: dashboardStats?.totalPostponed,
    },
  ];
  const config = {
    data,
    radius: 0.7,
    innerRadius: 0.6,
    autoFit: true,
    padding: 10,
    meta: {
      country: {
        alias: "alias",
        range: [0, 1],
      },

      value: {
        alias: "orders",
        formatter: (v) => {
          return v;
        },
      },
    },
    tooltip: false,
    label: {
      layout: "overlap",
      autoHide: true,
      labelEmit: true,
      style: {
        fontWeight: "bold",
        textWrap: "nowrap",
      },
    },
    legend: {
      layout: "horizontal",
      position: "top",
      offsetX: -20,
    },
    statistic: {
      title: false,
    },
    angleField: "value",
    colorField: "name",
    color: ["#27AE60", "#E34B4B", "#838383", "rgb(180,180,180)"],
  };

  return (
    <div className="chart-dashboard-box">
      {children}
      <Pie {...config} />
    </div>
  );
}
