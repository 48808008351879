import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  url: `${API_URL}/admin/self`,
  name: "adminTablesView",

  actions: {
    getSelf: {
      method: "GET",
      url: `${API_URL}/admin/self`,
    },
    get: {
      method: "GET",
      url: `${API_URL}/admin/self/tablesview`,
    },
    set: {
      method: "PUT",
      url: `${API_URL}/admin/self/tablesview`,
      reduce: (state, action) => {
        const updatedRetailer = { ...state.items, ...action.context };
        return { ...state, items: updatedRetailer };
      },
    },
  },
});

setAuthHeader(getAuthData().accessToken);
