import React, { useState } from "react";
import { Radio } from "antd";

import Select, { Option } from "../../../../../components/Select";
import { radioButtons, REPORT_TYPE_ENUM } from "../../../system-report-config";

function ReportConfig({ setQuery, driversList, retailersList, query, onSelect }) {
  const [reportType, setReportType] = useState(REPORT_TYPE_ENUM.overall);

  const changeReportType = (e) => {
    setReportType(e.target.value);
    e.target.value === REPORT_TYPE_ENUM.overall && setQuery && setQuery({});
  };

  const isReportTypeDriver = reportType === REPORT_TYPE_ENUM.driver;
  const isReportTypeRetailer = reportType === REPORT_TYPE_ENUM.retailer;

  return (
    <>
      <p>Type</p>
      <div className="radioGroup">
        <Radio.Group defaultValue={reportType} buttonStyle="solid" onChange={changeReportType}>
          {radioButtons.map(({ value, title }) => (
            <Radio.Button value={value}>{title}</Radio.Button>
          ))}
        </Radio.Group>
        <Select
          defaultLabel="Choose driver"
          disabled={!isReportTypeDriver}
          list={driversList}
          mapFunction={(
            { id, profile }, // destrcturing a null object may causes an issue
          ) => (
            <Option value={id} key={id}>{`${profile?.firstName ?? "Unknown"} ${profile?.lastName ?? "Driver"}`}</Option>
          )}
          className={isReportTypeDriver ? "systemReportSelectActive" : "systemReportSelectHidden"}
          onChange={onSelect("driverId", setQuery)}
          value={query.driverId || null}
        />
        <Select
          defaultLabel="Choose retailer"
          disabled={!isReportTypeRetailer}
          list={retailersList}
          className={isReportTypeRetailer ? "systemReportSelectActive" : "systemReportSelectHidden"}
          onChange={onSelect("retailerId", setQuery)}
          value={query.retailerId || null}
        />
      </div>
    </>
  );
}

export default ReportConfig;
