import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { notification } from "antd";

import { actions as retailerActions } from "../../../redux/resources/adminRetailers";
import { structureErrorResponse } from "src/helpers/handleErrorResponse";

export const useHooks = ({
  driver,
  query,
  setQuery,
  setLoading,
  setSelectedRetailer,
  getJobsDriversManager,
  loadOneDriversManager,
  loadAllDriversManager,
  loadFilteredDriversManager,
  changeCompanyDriversManager,
  assignJobDriversManager,
  cancelAssignJobDriversManager,
  deleteDriverDriversManager,
  createOneDriversManager,
}) => {
  const [driverSearchQuery, setDriverSearchQuery] = useState("email");
  const [searchedText, setSearchedText] = useState("");
  const { fetchListAdminRetailers } = retailerActions;
  const dispatch = useDispatch();

  const connectedRetailerId = driver?.connectedRetailer?.id;

  useEffect(() => {
    connectedRetailerId && setSelectedRetailer(connectedRetailerId);
  }, [connectedRetailerId, setSelectedRetailer]);

  const getFilteredData = async () => {
    setLoading(true);
    if (searchedText) {
      await loadFilteredDriversManager({}, { query: { ...query, [driverSearchQuery]: searchedText } });
    } else {
      await loadFilteredDriversManager({}, { query });
    }
    setLoading(false);
  };

  const getAllData = async () => {
    setLoading(true);
    if (searchedText) {
      await loadAllDriversManager(
        {},
        { query: { [driverSearchQuery]: searchedText, page: query.page, limit: query.limit } },
      );
    } else {
      await loadAllDriversManager({}, { query: { page: query.page, limit: query.limit } });
    }
    setLoading(false);
  };

  const getJobsAndRetailers = async (driverId) => {
    setLoading(true);
    await getJobsDriversManager(driverId);
    await dispatch(fetchListAdminRetailers({}, { query: { restricted: true } }));
    setLoading(false);
  };

  const onChangeTable = async (pagination, filters) => {
    const { current, pageSize } = pagination;
    const { status } = filters;
    setQuery({
      ...query,
      page: current,
      limit: pageSize,
      status: status ? status[0] : "",
    });
    if (pageSize !== query.limit) {
      localStorage.setItem("driversOnPage", pageSize);
    }
  };

  const handleCreateDriver = async (values) => {
    try {
      const res = await createOneDriversManager({ ...values });
      notification.success({
        message: "Driver successfully created !",
        description: ` an email has been sent to ${res.email}.`,
      });
    } catch (error) {
      const description = error?.body?.message
        ? structureErrorResponse(error?.body?.message)
        : "Unexpect error has occured , please try again !";
      notification.error({ message: "Unable to create a driver !", description: description });
    } finally {
      getAllData();
    }
  };

  const handleSearchDriver = async (e) => {
    setLoading(true);
    if (e.target.value) {
      setSearchedText(e.target.value);
      await loadAllDriversManager(
        {},
        { query: { [driverSearchQuery]: e.target.value, page: query.page, limit: query.limit } },
      );
    } else {
      setSearchedText("");
      await loadAllDriversManager({}, { query: { page: query.page, limit: query.limit } });
    }
    setLoading(false);
  };

  const handleAssignJob = (id) => {
    assignJobDriversManager(id, { query: { driverId: driver.id } })
      .then(() => {
        loadOneDriversManager(driver.id);
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error.body && error.body.message && error.body.message,
        });
      });
  };

  const handleCancelAssignJob = async (id) => {
    try {
      await cancelAssignJobDriversManager(id, { query: { driverId: driver.id } });
      await loadOneDriversManager(driver.id);
    } catch (e) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Can't cancel this assigment.",
      });
    }
  };

  const handleCompanyChange = async (retailerId) => {
    try {
      await changeCompanyDriversManager({ retailerId, id: driver.id });
      setSelectedRetailer(retailerId);
      notification.success({
        message: retailerId
          ? "Successfully conected driver to the retailer"
          : "Successfully disconnected driver from the retailer",
      });
    } catch (e) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Can`t connect driver to that retailer.",
      });
    } finally {
      getJobsDriversManager(driver.id);
    }
  };

  const handleDriverDelete = (setDrawerVisible) => async () => {
    try {
      await deleteDriverDriversManager({ driverId: driver.id });
      setDrawerVisible && setDrawerVisible(false);
      await getAllData();
    } catch (e) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Error on driver delete.",
      });
    }
  };

  const onChangeSearchFilter = (value) => setDriverSearchQuery(value);

  return {
    getAllData,
    getFilteredData,
    getJobsAndRetailers,
    onChangeTable,
    onChangeSearchFilter,
    handleSearchDriver,
    handleAssignJob,
    handleCancelAssignJob,
    handleCompanyChange,
    handleDriverDelete,
    handleCreateDriver,
  };
};
