import React from "react";
import { Typography } from "antd";
import ReactRotatingText from "react-rotating-text";

import GetStartedButton from "../GetStartedButton";
import styles from "./getStarted.module.less";

const GetStarted = ({ onToggleGetStartedModal, isMobile }) => (
  <div className={styles.getStartedWrap} style={isMobile ? { padding: "54px", whiteSpace: "nowrap" } : {}}>
    <Typography.Title level={1} className={isMobile ? styles.getStartedTitleMobile : styles.getStartedTitle}>
      We Deliver For
    </Typography.Title>

    <div className={isMobile ? styles.rotatingTextWrapMobile : styles.rotatingTextWrap}>
      <ReactRotatingText
        typingInterval={100}
        deletingInterval={100}
        pause={1000}
        items={["E-commerce", "Professional Services", "Retail", "Home Businesses"]}
      />
    </div>

    <GetStartedButton onClick={onToggleGetStartedModal}>Work with Us</GetStartedButton>
  </div>
);

export default GetStarted;
