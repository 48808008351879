import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";
import { getDeliveryOrders } from "../../helpers/maps/maps.helpers";
import { API_URL } from "../../constants/config";

const updateJobNewTicketStateReduce = (state, action) => {
  if (action.status === "resolved") {
    return {
      ...state,
      items: state.items.map((item) => {
        if (item.id === action.context.jobId) {
          return {
            ...item,
            deliveryOrders: item.deliveryOrders.map((o) => {
              if (o.id === action.context.orderId) {
                return {
                  ...o,
                  ticket: { ...action.context.ticket },
                };
              } else {
                return o;
              }
            }),
          };
        } else {
          return item;
        }
      }),
    };
  }
  return { ...state };
};

const jobReduce = (state, action) => {
  let deliveryJobsWithOutOrder = null;

  if (action.status === "resolved") {
    deliveryJobsWithOutOrder = state.items.map((delJob) => {
      if (delJob.id === action.context.jobId) {
        const updatedOrders = delJob.deliveryOrders.filter((order) => order.id !== action.context.orderId);
        return {
          ...delJob,
          deliveryOrders: updatedOrders,
        };
      }
      return { ...delJob };
    });
  }
  return { ...state, items: deliveryJobsWithOutOrder || state.items };
};

const updateOrderStatus = (orders, orderId, status) => {
  return orders.map((order) =>
    order.id === orderId
      ? {
          ...order,
          status,
          deliveryTime: null,
        }
      : order,
  );
};

const completeJobReduce = (state, action) => {
  const {
    context: { jobId, orderId },
  } = action;
  if (action.status === "resolved") {
    const updatedJobs = state.items.map((job) =>
      job.id === jobId
        ? {
            ...job,
            deliveryOrders: updateOrderStatus(job.deliveryOrders, orderId, "completed"),
          }
        : job,
    );
    return { ...state, items: updatedJobs };
  }
  return state;
};

const handleUpdateJobToConfigure = (state, action) => {
  const {
    context: { jobId, ids, cancel, previewItems, isMain },
  } = action;
  let job = {};
  switch (action.type) {
    case "@@resource/RETAILER_JOB/CONFIGURE_JOB":
      const selectedJob = state.items?.find(({ id }) => id === jobId);
      job = {
        job: selectedJob,
        previewOrdersToAdd: [],
        selectedIds: [],
      };
      break;
    case "@@resource/RETAILER_JOB/SET_SELECTED_ORDERS":
      job = {
        selectedIds: ids,
      };
      break;
    case "@@resource/RETAILER_JOB/SET_PREVIEW_ORDERS":
      if (cancel === true) {
        job = {
          previewOrdersToAdd: [],
          selectedIds: [],
        };
      } else {
        if (previewItems) {
          job = {
            previewOrdersToAdd: previewItems,
          };
        } else {
          const prevState = state.jobsToConfigure;
          const selectedIds = isMain ? prevState.compareToJob.selectedIds : prevState.mainJob.selectedIds;
          let previewItems = isMain
            ? prevState.compareToJob.job.deliveryOrders.filter((item) => selectedIds.includes(item.id))
            : prevState.mainJob.job.deliveryOrders.filter((item) => selectedIds.includes(item.id));
          job = {
            previewOrdersToAdd: previewItems,
          };
        }
      }
      break;
    default:
      break;
  }

  return job;
};

const configureJobReducer = (state, action) => {
  const job = handleUpdateJobToConfigure(state, action);
  const isMain = action.context.isMain;
  const prevJobsToonfigureState = state.jobsToConfigure;

  if (action.status === "resolved") {
    if (!state.items || !state.items.length) {
      return {
        ...state,
        forceBackToMainPage: true,
      };
    }
    return {
      ...state,
      forceBackToMainPage: false,
      jobsToConfigure: {
        mainJob: isMain ? { ...prevJobsToonfigureState?.mainJob, ...job } : prevJobsToonfigureState?.mainJob,
        compareToJob: !isMain
          ? { ...prevJobsToonfigureState?.compareToJob, ...job }
          : prevJobsToonfigureState?.compareToJob,
      },
    };
  }
  return state;
};

const updateConfigureJobNewTicketStateReduce = (state, action) => {
  if (action.status === "resolved") {
    return {
      ...state,
      forceBackToMainPage: false,
      jobsToConfigure: {
        ...state.jobsToConfigure,
        mainJob: {
          ...state.jobsToConfigure.mainJob,
          job: {
            ...state.jobsToConfigure.mainJob.job,
            deliveryOrders: state.jobsToConfigure.mainJob.job.deliveryOrders.map((o) => {
              if (o.id === action.context.orderId) {
                return {
                  ...o,
                  ticket: action.context.ticket,
                };
              } else {
                return o;
              }
            }),
          },
        },
      },
    };
  }
  return state;
};

export const { types, actions, rootReducer } = createResource({
  name: "retailerJob",
  url: `${API_URL}/retailers/jobs/:id`,

  actions: {
    fetch: {
      url: `${API_URL}/retailers/jobs?fetchAll=true`,
      transformResponse: (res) => ({ ...res, body: res.body }),
    },
    getOne: {
      method: "GET",
      reduce: (state, action) => {
        let deliveryJobsWithOrders = null;
        if (action.status === "resolved") {
          deliveryJobsWithOrders = state.items.map((delJob) => {
            if (delJob.id === action.body.id) {
              return {
                ...delJob,
                orders: action.body.orders,
                polyline: action.body.polyline,
              };
            }
            return { ...delJob };
          });
        }
        return { ...state, items: deliveryJobsWithOrders || state.items };
      },
    },
    getRoutes: {
      method: "GET",
      url: `${API_URL}/retailers/jobs/routes/:jobId`,
      reduce: (state, action) => {
        let deliveryJobs;
        if (action.status === "resolved") {
          deliveryJobs = state.items.map((job) => {
            if (job.id === action.context.jobId) {
              const ordersByDelivery = getDeliveryOrders(action.body);
              return {
                ...job,
                jobRoutes: action.body,
                deliveryOrders: ordersByDelivery,
              };
            }
            return { ...job };
          });
        }
        return { ...state, items: deliveryJobs || state.items };
      },
    },
    getPolyline: {
      method: "GET",
      url: `${API_URL}/retailers/jobs/polyline/:jobId`,
      reduce: (state, action) => {
        let deliveryJobs;
        if (action.status === "resolved") {
          deliveryJobs = state.items.map((job) => {
            if (job.id === action.context.jobId) {
              return {
                ...job,
                polyline: action.body.polyline,
              };
            }
            return { ...job };
          });
        }
        return { ...state, items: deliveryJobs || state.items };
      },
    },
    saveOrders: {
      method: "PUT",
      url: `${API_URL}/retailers/jobs/:jobId/orders`,
    },
    deleteOrder: {
      method: "DELETE",
      url: `${API_URL}/retailers/jobs/:jobId/orders/:orderId`,
      reduce: jobReduce,
    },
    returnToShipper: {
      method: "PUT",
      url: `${API_URL}/retailers/self/orders/:orderId/return-to-shipper`,
      reduce: jobReduce,
    },
    postponeOrder: {
      method: "PUT",
      url: `${API_URL}/retailers/self/orders/:orderId/postpone`,
      reduce: jobReduce,
    },
    cancelOrder: {
      method: "PUT",
      url: `${API_URL}/retailers/self/orders/:orderId/cancel`,
      reduce: jobReduce,
    },
    completeOrder: {
      method: "PUT",
      url: `${API_URL}/retailers/self/orders/:orderId/complete`,
      reduce: completeJobReduce,
    },
    togglePublish: {
      method: "PUT",
      url: `${API_URL}/retailers/jobs/:jobId`,
    },
    addOrders: {
      method: "PUT",
      url: `${API_URL}/retailers/jobs/:jobId/orders/add`,
    },
    removeOrder: {
      method: "DELETE",
      url: `${API_URL}/retailers/jobs/:jobId/orders/:orderId`,
    },
    removeOrders: {
      method: "DELETE",
      url: `${API_URL}/retailers/jobs/:jobId/orders`,
    },
    moveOrders: {
      method: "PUT",
      url: `${API_URL}/retailers/jobs/:jobId/orders`,
    },
    changeOrderPosition: {
      method: "PUT",
      url: `${API_URL}/retailers/jobs/:jobId/delivery-order`,
    },
    changePickupPoistion: {
      method: "PUT",
      url: `${API_URL}/retailers/jobs/:jobId/pickup-order`,
    },
    forceNextOrder: {
      method: "PUT",
      url: `${API_URL}/retailers/jobs/:jobId/force-next`,
    },
    editNote: {
      method: "PUT",
      url: `${API_URL}/retailers/self/orders/:orderId/notes`,
    },
    // editJobDriver: {
    //   method: "PUT",
    //   url: `${API_URL}/retailers/self/orders/:jobId/driver`,
    // },
    assignJob: {
      method: "GET",
      url: `${API_URL}/retailers/jobs/:id/assign`, // assignJobDriversManager(id, { query: { driverId: driver.id } })
    },

    cancelAssignJob: {
      method: "GET",
      url: `${API_URL}/retailers/jobs/:id/cancel-assign`, // cancelAssignJobDriversManager(id, { query: { driverId: driver.id } });
    },
    fetchAvailableDrivers: {
      method: "GET",
      url: `${API_URL}/retailers/jobs/:id/drivers`, //
    },
    updateCash: {
      method: "PATCH",
      url: `${API_URL}/retailers/self/orders/:orderId/cash`,
      reduce: (state, action) => {
        if (action.status === "resolved") {
          const jobs = state.items.map((delJob) => {
            if (delJob.id === action.body.job) {
              return {
                ...delJob,
                deliveryOrders: delJob.deliveryOrders.map((order) => {
                  if (order.id === action.body.id) {
                    return {
                      ...order,
                      cashAmount: +action.body.cashAmount,
                    };
                  }

                  return order;
                }),
              };
            }

            return delJob;
          });

          return { ...state, items: jobs };
        }

        return state;
      },
    },
    getBad: {
      method: "GET",
      url: `${API_URL}/retailers/jobs/failed`, // TODO bad location endpoint
    },
    deleteIncomplete: {
      method: "PUT",
      url: `${API_URL}/retailers/jobs/clean-jobs`,
    },
    resetFlag: {
      isPure: true,
      reduce: (state, action) => {
        if (action.status === "resolved") {
          return {
            ...state,

            forceBackToMainPage: false,
          };
        }
      },
    },
    configureJob: {
      isPure: true,
      reduce: configureJobReducer,
    },
    setSelectedOrders: {
      isPure: true,
      reduce: configureJobReducer,
    },
    setPreviewOrders: {
      isPure: true,
      reduce: configureJobReducer,
    },
    resetConfigureJob: {
      isPure: true,
      reduce: (state, actions) => {
        let jobId = state.jobsToConfigure.mainJob?.job.id;
        const mainJob = state.items?.find(({ id }) => id === jobId);

        jobId = state.jobsToConfigure.compareToJob?.job?.id;
        const subJob = jobId ? state.items?.find(({ id }) => id === jobId) : undefined;

        return {
          ...state,
          forceBackToMainPage: mainJob === undefined, // in case we deleted or moved all orders from mainJob
          jobsToConfigure: {
            mainJob: {
              job: mainJob,
              previewOrdersToAdd: [],
              selectedIds: [],
            },
            compareToJob: subJob
              ? {
                  job: subJob,
                  previewOrdersToAdd: [],
                  selectedIds: [],
                }
              : undefined,
          },
        };
      },
    },

    addToJob: {
      method: "PUT",
      url: `${API_URL}/retailers/jobs/:jobId/orders/add`,
    },
    removeFromJob: {
      method: "DELETE",
      url: `${API_URL}/retailers/jobs/:jobId/orders`,
    },
    getJobsByStatus: {
      method: "GET",
      url: `${API_URL}/retailers/jobs/status`,
      reduce: (state, action) => {
        return {
          ...state,
          jobIds: action.body,
        };
      },
    },
    // pure Actions
    updateNewTicketState: {
      isPure: true,
      reduce: updateJobNewTicketStateReduce,
    },
    updateTicketState: {
      isPure: true,
      reduce: updateJobNewTicketStateReduce,
    },
    updateConfigJobTicketState: {
      isPure: true,
      reduce: updateConfigureJobNewTicketStateReduce,
    },
    updateConfigJobNewTicketState: {
      isPure: true,
      reduce: updateConfigureJobNewTicketStateReduce,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
