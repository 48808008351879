import React, { useEffect, useState } from "react";
import { Button, Radio, List, Drawer, notification, Input } from "antd";

import { MobileTable } from "../../../../../components/AdminPanel/Mobile/MobileTable";
import { MobileTableRows } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableRows";
import { BtnGroup } from "../BtnGroup";
import { NO_INFO } from "../../../../../components/AdminPanel/Mobile/helpers";
import { MobilePagination } from "../../../../../components/AdminPanel/Mobile/MobilePagination";
import { StatusPopover } from "../../../DesktopView/components/RetailerChangesTable/components/StatusPopover";

const { TextArea } = Input;

export const MobileRetailerSMSTable = ({
  data,
  location,
  paginationConfig,
  fetchData,
  onOpenModal,
  onChangePagination,
  handleToggleOne,
  isSelected,
  getOneRetailerData,
  statusFilter,
  setStatusFilter,
  getSMSChangesNotifications,
  approveSMSChangesNotifications,
  rejectSMSChangesNotifications,
  onRevokeSMSRights,
  getSMSChangesGroupNotifications,
}) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState({});
  const [lang, setLang] = useState("en");

  useEffect(() => {
    fetchData(getSMSChangesNotifications, false, statusFilter);
  }, [getSMSChangesNotifications, fetchData, location.search, statusFilter]);

  const onApproveStatus = (id) => async () => {
    try {
      await approveSMSChangesNotifications({ groupId: id });
      await fetchData(getSMSChangesNotifications, false, statusFilter);
    } catch (e) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Something went wrong.",
      });
    }
  };

  const onRejectStatus = (id) => async () => {
    try {
      await rejectSMSChangesNotifications({ groupId: id });
      await fetchData(getSMSChangesNotifications, false, statusFilter);
    } catch (e) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Something went wrong.",
      });
    }
  };

  const onOpenChanges = (id) => () => {
    getSMSChangesGroupNotifications({ groupId: id }).then((res) => {
      setDrawerOpen(true);
      setSelectedGroup(res.body);
    });
  };

  const dataSource = data?.map(({ id, retailer, status, time, updatePayload }) => {
    return {
      id,
      retailer,
      retailerId: retailer?.id,
      name: retailer?.name,
      email: retailer?.email,
      phoneNumber: retailer?.phoneNumber,
      updatePayload,
      status,
      isCustomMessagingTemplatesAllowed: retailer?.isCustomMessagingTemplatesAllowed,
      // time: moment(time).format("DD.MM.YYYY HH:mm"),
    };
  });
  const onChangeLang = (e) => {
    setLang(e.target.value);
  };

  const convertMessageType = (str) => {
    return str[0].toUpperCase().concat(str.slice(1)).replace(/_/g, " ");
  };

  const handleRightsRevoke = () => {
    onRevokeSMSRights(selectedGroup.retailerId).then((res) => {
      fetchData(getSMSChangesNotifications, false, statusFilter);
      setDrawerOpen(false);
    });
  };

  return (
    <>
      <Radio.Group value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
        <Radio.Button value=""> All</Radio.Button>
        <Radio.Button value="pending"> Pending</Radio.Button>
        <Radio.Button value="approved"> Approved</Radio.Button>
        <Radio.Button value="declined"> Declined</Radio.Button>
      </Radio.Group>
      <MobileTable>
        {dataSource?.map(
          ({
            id,
            retailer,
            retailerId,
            name,
            email,
            phoneNumber,
            status,
            updatePayload,
            isCustomMessagingTemplatesAllowed,
          }) => (
            <div className="table-item" key={id}>
              {/* <div style={{ margin: "10px" }} onClick={(e) => handleToggleOne(e, requests_id)}>
              <Checkbox checked={isSelected(requests_id)} />
            </div> */}
              <MobileTableRows
                rows={[
                  { label: "Request ID", value: id || NO_INFO },
                  { label: "Retailer ID", value: retailerId || NO_INFO },
                  { label: "Retailer name", value: name || NO_INFO },
                  // {
                  //   label: "Reason",
                  //   value: description ? (
                  //     <Popover placement="topRight" content={<div>{description}</div>} title={null}>
                  //       <span>{reason}</span>
                  //     </Popover>
                  //   ) : (
                  //     <div>{reason}</div>
                  //   ),
                  // },
                  { label: "Email", value: email },
                  { label: "Phone number", value: phoneNumber },
                  { label: "Has rights", value: isCustomMessagingTemplatesAllowed ? "True" : "False" },
                  { label: "Status", value: status },
                ]}
              />
              {status === "pending" && (
                <>
                  {/* <BtnGroup>
                  <Button
                    onClick={onOpenModal({ type: "wait", requestId: requests_id })}
                    className="mobile-btn mobile-btn--blue"
                  >
                    Wait
                  </Button>
                </BtnGroup> */}
                  <BtnGroup isThree>
                    <Button
                      onClick={onOpenModal({ type: "approve", requestId: id })}
                      className="mobile-btn mobile-btn--blue"
                    >
                      Approve
                    </Button>
                    <Button onClick={onOpenChanges(id)} className="mobile-btn mobile-btn--blue">
                      View
                    </Button>
                    <Button
                      onClick={onOpenModal({ type: "reject", requestId: id })}
                      className="mobile-btn mobile-btn--purple"
                    >
                      Reject
                    </Button>
                  </BtnGroup>
                </>
              )}
              {status === "waiting" && (
                <BtnGroup three>
                  <Button
                    onClick={onOpenModal({ type: "approve", requestId: id })}
                    className="mobile-btn mobile-btn--blue"
                  >
                    Approve
                  </Button>
                  <Button
                    onClick={onOpenModal({ type: "reject", requestId: id })}
                    className="mobile-btn mobile-btn--purple"
                  >
                    Reject
                  </Button>
                </BtnGroup>
              )}
            </div>
          ),
        )}
      </MobileTable>
      <MobilePagination onPageChange={onChangePagination} {...paginationConfig} />
      <Drawer
        width="80vw"
        className="retailerSMS-drawer"
        placement="right"
        closable
        onClose={() => setDrawerOpen(false)}
        visible={isDrawerOpen}
      >
        <List
          size="large"
          header={
            <div className="retailerSMS-drawer-title-wrap">
              <h3>
                <Input
                  value={selectedGroup?.name || "No name"}
                  style={{ width: "30vw", minWidth: "200px" }}
                  // disabled={selectedGroup.status === "pending"}
                />
                <Radio.Group
                  style={{ float: "right", marginRight: "3vw" }}
                  value={lang}
                  onChange={onChangeLang}
                  defaultValue="en"
                >
                  <Radio.Button value="en">ENG</Radio.Button>
                  <Radio.Button disabled value="ar">
                    ARB
                  </Radio.Button>
                </Radio.Group>
              </h3>
              <div className="retailerSMS-drawer-title">
                <div className="retailerSMS-drawer-title-colname">Event name</div>{" "}
                <div className="retailerSMS-drawer-title-colname">SMS text</div>
              </div>
            </div>
          }
          footer={
            <div className="retailerSMS-drawer-footer">
              <StatusPopover
                record={selectedGroup}
                onApproveStatus={onApproveStatus}
                onRejectStatus={onRejectStatus}
                // onWaitStatus={onWaitStatus}
              />
              <Button danger onClick={handleRightsRevoke}>
                Revoke SMS rights
              </Button>
            </div>
          }
          dataSource={selectedGroup?.templates?.filter((e) => e.messageType === "order_created")}
          renderItem={(item) => {
            if (item?.language === lang) {
              return (
                <List.Item className="retailerSMS-drawer-list-item">
                  <span className="retailerSMS-drawer-list-item-message">
                    {convertMessageType(item.messageType)}({item.language?.toUpperCase()})
                  </span>
                  <div className="retailerSMS-drawer-list-item-template-wrapper">
                    <TextArea className="retailerSMS-drawer-list-item-template" rows={4} value={item.template} />
                  </div>
                </List.Item>
              );
            }
            return null;
          }}
        />
      </Drawer>
    </>
  );
};
