import { DownOutlined } from "@ant-design/icons";
import { Button, Col, InputNumber, notification, Row, Tree } from "antd";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/redux";
import "./styles.less";

const JobsCheckup: FC<any> = ({ checkCertitudeJobsMap }) => {
  const [job, setJob] = useState<number>();
  const jobValidation: {
    correct: boolean;
    orderInfo: {
      pickupPoints: number[];
      deliveryPoints: number[];
    };
    routesInfo: {
      pickupPoints: number[];
      deliveryPoints: number[];
    };
  } = useSelector((state: RootState) => {
    return state.jobsMap.jobValidation;
  });

  const handleCheckJob = async (id: number) => {
    try {
      await checkCertitudeJobsMap({ jobId: id });
    } catch (error: any) {
      notification.warn({ message: "There was an expected error, please try again !" });
    }
  };

  const displayInconsistency = (
    treeKey: "orderInfos" | "routeInfos",
    info: {
      pickupPoints: number[];
      deliveryPoints: number[];
    },
  ) => {
    const treeData = [
      {
        title: treeKey,
        key: treeKey,
        children: [
          {
            title: "Pickups",
            key: `${treeKey}-pickup`,
            children: info.pickupPoints.map((el) => ({
              title: el,
              key: el,
            })),
          },
          {
            title: "Deliveries",
            key: `${treeKey}-delivery`,
            children: info.deliveryPoints.map((el) => ({
              title: el,
              key: el,
            })),
          },
        ],
      },
    ];
    return (
      <Col span={11}>
        <Tree showLine switcherIcon={<DownOutlined />} treeData={treeData} />
      </Col>
    );
  };
  return (
    <>
      <Row>
        <label className="subItem">Please enter a valid Job ID </label>
        <InputNumber className="subItem" value={job} onChange={(value) => setJob(value!)} />
        <Button className="subItem" onClick={() => handleCheckJob(job!)} disabled={!job}>
          Check validation
        </Button>
      </Row>
      <Row>
        {jobValidation && (
          <p className={jobValidation.correct ? "response" : "response-wrong"}>
            This job is {jobValidation.correct ? "correct" : "incorrect"}
          </p>
        )}
      </Row>
      <h2>Jobs points</h2>

      {jobValidation?.correct === false && (
        <Row justify={"space-between"}>
          {displayInconsistency("orderInfos", jobValidation.orderInfo)}
          {displayInconsistency("routeInfos", jobValidation.routesInfo)}
        </Row>
      )}
    </>
  );
};

export default JobsCheckup;
