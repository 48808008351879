import React, { useState } from "react";
import { Button } from "antd";
import moment from "moment";

import { MobileLoader } from "../../../components/AdminPanel/Mobile/MobileLoader";
import { MobileRangePicker } from "../../../components/AdminPanel/Mobile/MobileRangePicker";
import { MobileSelect } from "../../../components/AdminPanel/Mobile/MobileSelect";
import { MobileTitle } from "../../../components/AdminPanel/Mobile/MobileTitle";
import { isStage } from "../../../constants/config";
import { ReportModal } from "./components/ReportModal";
import { TopStats } from "./components/TopStats";
import { OrdersTable } from "./components/OrdersTable";
import { JobsTable } from "./components/JobsTable";
import { MobilePagination } from "../../../components/AdminPanel/Mobile/MobilePagination";

export const MobileView = ({
  isLoading,
  env,
  envList,
  retailersList,
  generalStats,
  orderStats,
  ordersData,
  chartData,
  jobsData,
  dateRange,
  ordersPaginationConfig,
  jobsPaginationConfig,
  disabledTomorrowAndFuture,
  setEnv,
  setDateRange,
  downloadReport,
  onOrderPaginatonChange,
  onJobPaginationChange,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const onDateRangeChange = (dates) => {
    if (!dates) return setDateRange({});

    const [from, to] = dates.map((date) => date.format("YYYY-MM-DD"));
    setDateRange({ from, to });
  };

  const formatedList = Object.entries(envList || {}).map(([key, title]) => ({ value: key, text: title }));

  const envOptions = isStage ? [...formatedList, { value: "local", text: "Staging" }] : formatedList;

  const getRangeDates = Object.keys(dateRange).length ? [moment(dateRange.from), moment(dateRange.to)] : [];

  const resetRangeDates = () => setDateRange({});

  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  return (
    <>
      {isLoading && <MobileLoader />}
      <div className="mobile-page-wrapper mobile-page-wrapper--env-stats">
        <MobileTitle title="Environment Stats" />
        <MobileSelect options={envOptions} value={env} onSelect={setEnv} />
        <MobileRangePicker
          format="DD.MM.YYYY"
          value={getRangeDates}
          onChange={onDateRangeChange}
          disabledDate={disabledTomorrowAndFuture}
        />
        <Button className="mobile-btn mobile-btn--white mb-16 mt-12" onClick={resetRangeDates}>
          Reset Date
        </Button>
        <Button className="mobile-btn mobile-btn--gray mb-16" onClick={openModal}>
          Download Report
        </Button>
        <TopStats generalStats={generalStats} orderStats={orderStats} chartData={chartData} />
        {!!jobsData?.length && (
          <div className="mb-16">
            <MobileTitle title="Jobs List" />
            <JobsTable data={jobsData} />
            <MobilePagination {...jobsPaginationConfig} onPageChange={onJobPaginationChange} />
          </div>
        )}
        {!!ordersData?.length && (
          <>
            <MobileTitle title="Orders List" />
            <OrdersTable data={ordersData} />
            <MobilePagination {...ordersPaginationConfig} onPageChange={onOrderPaginatonChange} />
          </>
        )}
        <ReportModal
          visible={modalVisible}
          retailersList={retailersList}
          generateReport={downloadReport}
          onClose={closeModal}
        />
      </div>
    </>
  );
};
