import { Col, Row, Typography } from "antd";
import React, { FC } from "react";
import RetailerStatus from "../../RetailerDetails/components/RetailerStatusTag";
import "./style.less";
const MobileViewRow: FC<any> = ({ intl, record, action }) => {
  return (
    <Col>
      <Row justify="space-between" className="row-single-info--wrapper">
        <Typography.Text className="row-title">
          {intl.formatMessage({ id: "tabPane.retailerName", defaultMessage: "Name" })}
        </Typography.Text>
        <Typography.Text>{record.name}</Typography.Text>
      </Row>
      <Row className="row-single-info--wrapper" justify="space-between">
        <Typography.Text className="row-title">
          {intl.formatMessage({ id: "input.officeAddress", defaultMessage: "Office address" })}
        </Typography.Text>
        <Typography.Text>{record.officeAddress}</Typography.Text>
      </Row>
      <Row className="row-single-info--wrapper" justify="space-between">
        <Typography.Text className="row-title">
          {intl.formatMessage({ id: "phoneNumber", defaultMessage: "Phone number" })}
        </Typography.Text>
        <Typography.Text>{record.phoneNumber}</Typography.Text>
      </Row>
      <Row className="row-single-info--wrapper" justify="space-between">
        <Typography.Text className="row-title">
          {intl.formatMessage({ id: "status", defaultMessage: "Status" })}
        </Typography.Text>
        <RetailerStatus status={record.status} toUpdate={false} />{" "}
      </Row>
      <Row className="row-single-info--wrapper">{action}</Row>
    </Col>
  );
};

export default MobileViewRow;
