import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { Layout as AntLayout, Row, Col, Typography, List } from "antd";

import socialLinks from "./socialLinks";
import footerSections from "./footerSections";
import PrivacyPolicyModal from "../PrivacyPolicyModal";
import TermsConditionsModal from "../TermsConditionsModal";
import MainLogo from "../../../../assets/newLanding/MainLogo";
import AppStoreEng from "../../../../assets/newLanding/footer/AppStoreEng";
import GooglePlayEng from "../../../../assets/newLanding/footer/GooglePlayEng";
import { ReactComponent as ToTopIcon } from "./assets/toTop.svg";
import styles from "./footer.module.less";
import { appStoreLink, googlePlayLink } from "../../../../constants/config";

import { ReactComponent as GooglePlayMobile } from "./assets/store2.svg";
import { ReactComponent as AppStoreMobile } from "./assets/store1.svg";

const Footer = ({ isMobile }) => {
  const [isTermsModalVisible, setIsTermsModalVisible] = useState(false);
  const handleToggleTermsModal = () => setIsTermsModalVisible((prevState) => !prevState);

  const [isPrivacyModalVisible, setIsPrivacyModalVisible] = useState(false);
  const handleTogglePrivacyModal = () => setIsPrivacyModalVisible((prevState) => !prevState);

  return isMobile ? (
    <>
      <div style={{ background: "rgba(22, 22, 22, 0.4)", borderTop: "1px solid rgba(43, 43, 43, 1)", paddingTop: 40 }}>
        <Row justify="start">
          <Col offset={4} span={10}>
            <Link to="/">
              <MainLogo style={{ width: 65 }} width="65" />
            </Link>
            <div style={{ display: "flex", flexDirection: "column", gap: 24, marginTop: 24 }}>
              <a className={styles.mobileFooterLink} href="mailto:contact@futeric.com">
                Careers
              </a>
              <Link
                className={styles.mobileFooterLink}
                to="/terms-conditions"
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                Terms & Conditions
              </Link>
              <Link
                className={styles.mobileFooterLink}
                to="/privacy-policy"
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                Privacy & Cookie Policy
              </Link>
            </div>
          </Col>
          <Col offset={3} span={3}>
            <ToTopIcon onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} />
          </Col>
        </Row>
        <Row justify="start" style={{ marginTop: 24 }}>
          <Col offset={4} span={20}>
            {/* <StorePageIcon /> */}
            <div>
              <Typography style={{ fontSize: 14, color: "#01cdfb", marginBottom: 8 }}>Download our app</Typography>
              <div>
                <a href={appStoreLink} style={{ marginRight: 14 }}>
                  <AppStoreMobile />{" "}
                </a>
                <a href={googlePlayLink}>
                  <GooglePlayMobile />
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <div style={{ background: "#0E0E0E" }}>
          <div
            className={cn(styles.socialLinksWrap, { [styles.socialLinksWrapMobile]: isMobile })}
            style={{
              width: "100%",
              display: "flex",
              marginTop: 50,
              marginBottom: 30,
              background: "#0E0E0E",
              paddingTop: 24,
            }}
          >
            {socialLinks.map(({ id, link, isActive, icon: SocialIcon }) => (
              <a
                key={id}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className={cn(styles.socialLink, { [styles.socialLinkActive]: isActive })}
              >
                <SocialIcon />
              </a>
            ))}
          </div>
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
              background: "#0E0E0E",
              color: "rgba(43, 43, 43, 1)",
            }}
          >
            Copyright © 2022. All rights reserved
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <AntLayout.Footer className={styles.footer} style={{ flexDirection: "column", marginBottom: 0 }}>
        <Row justify="space-between" className={styles.footerFirstLevel} style={{ width: "100%" }}>
          <Col span={20} lg={20} offset={2}>
            <Row justify="space-between">
              <Col span={5}>
                <Link to="/">
                  <MainLogo />
                </Link>
              </Col>

              <Col span={4}>
                <Typography.Paragraph className={styles.footerSectionTitle}>Business solutions</Typography.Paragraph>

                <List
                  dataSource={footerSections.businessSolutions}
                  renderItem={({ id, label, path, isActive }) => (
                    <List.Item key={id} className={cn(styles.footerItem, { [styles.footerActiveItem]: isActive })}>
                      <Link to={path} onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
                        {label}
                      </Link>
                    </List.Item>
                  )}
                />
              </Col>

              <Col span={4}>
                <Typography.Paragraph className={styles.footerSectionTitle}>Industries</Typography.Paragraph>

                <List
                  dataSource={footerSections.industries}
                  renderItem={({ id, label, path, isActive }) => (
                    <List.Item key={id} className={cn(styles.footerItem, { [styles.footerActiveItem]: isActive })}>
                      <Link to={path} onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
                        {label}
                      </Link>
                    </List.Item>
                  )}
                />
              </Col>

              <Col span={4}>
                <Typography.Paragraph className={cn(styles.footerSectionTitle, styles.footerActiveItem)}>
                  About us
                </Typography.Paragraph>

                <List>
                  <List.Item className={cn(styles.footerItem, styles.footerActiveItem)}>
                    <Link to="/about-us" onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
                      Company
                    </Link>
                  </List.Item>
                  <List.Item className={cn(styles.footerItem, styles.footerActiveItem)}>
                    <a href="mailto:contact@futeric.com">Careers</a>
                  </List.Item>
                  <List.Item className={cn(styles.footerItem, styles.footerActiveItem)}>
                    <Typography.Paragraph onClick={handleTogglePrivacyModal}>Privacy</Typography.Paragraph>
                  </List.Item>
                  <List.Item className={cn(styles.footerItem, styles.footerActiveItem)}>
                    <Typography.Paragraph onClick={handleToggleTermsModal}>Terms</Typography.Paragraph>
                  </List.Item>
                </List>
              </Col>

              <Col>
                <Typography.Paragraph className={styles.mobileAppTitle}>Download our app:</Typography.Paragraph>

                <div className={styles.mobileAppLinksWrap}>
                  <a href={googlePlayLink} className={styles.mobileAppLink}>
                    <GooglePlayEng />
                  </a>

                  <a href={appStoreLink} className={styles.mobileAppLink}>
                    <AppStoreEng />
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify="center" className={styles.footerSecondLevel} style={{ width: "100%" }}>
          <Col span={20} offset={2} style={{ marginLeft: 0 }} className={styles.footerSecondLevelRow}>
            <Row justify="space-between">
              <Col>
                <Typography.Text className={styles.copyright}>
                  Copyright &copy; {new Date().getFullYear()}. All rights reserved
                </Typography.Text>
              </Col>

              <Col>
                <div className={styles.socialLinksWrap}>
                  {socialLinks.map(({ id, link, isActive, icon: SocialIcon }) => (
                    <a
                      key={id}
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={cn(styles.socialLink, { [styles.socialLinkActive]: isActive })}
                    >
                      <SocialIcon />
                    </a>
                  ))}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </AntLayout.Footer>

      {isTermsModalVisible && (
        <TermsConditionsModal isModalVisible={isTermsModalVisible} onToggleModal={handleToggleTermsModal} />
      )}

      {isPrivacyModalVisible && (
        <PrivacyPolicyModal isModalVisible={isPrivacyModalVisible} onToggleModal={handleTogglePrivacyModal} />
      )}
    </>
  );
};

export default Footer;
