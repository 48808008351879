import React from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import { GoogleApiWrapper } from "google-maps-react";

import { FormattedMessage } from "react-intl";
import StepActions from "../../../../components/DataEntry/StepActions";
import { API_KEY } from "../../../../constants/config";
import "./addresAndInstructions.less";
import RetailerPoints from "../../../RetailerSettings/RetailerPoints";

const AddressAndInstructions = ({
  pickUpPointsRetailerWithErrorNotification,
  currentStep,
  onPrevStep,
  stepsLength,
  retailerInfo,
}) => {
  // const [address, setAddress] = useState(
  //   retailerInfo?.pickupPoints?.[retailerInfo.pickupPoints?.length - 1].address ||
  //     JSON.parse(localStorage.getItem("addressAndInstructionsInfo"))?.address ||
  //     "",
  // );
  // const [coordinates, setCoordinates] = useState(
  //   JSON.parse(localStorage.getItem("addressAndInstructionsInfo"))?.coordinates || { lat: "", long: "" },
  // );
  const [form] = Form.useForm();
  // const intl = useIntl();

  // useEffect(() => {
  //   const addressAndInstructionsInfo = JSON.parse(localStorage.getItem("addressAndInstructionsInfo"));
  //   getDataLocalStorageAndSetData(addressAndInstructionsInfo, "instructions", form);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const pickupPoints = useSelector(({ points }) => points?.item);

  const onFinish = (values) => {
    return pickUpPointsRetailerWithErrorNotification(pickupPoints);
  };

  return (
    <>
      <h3 className="driverInfoTitle">
        <FormattedMessage
          id="page.step.address&Instructions.title"
          defaultMessage="Detailed address and instructions on where to pick up"
        />
      </h3>
      <p className="driverInfoDescription">
        <FormattedMessage
          id="page.step.address&Instructions.subtitle"
          defaultMessage="When our drivers arrive to your location, they need to know exactly where to go and what to do. This will allow them to successfully collect their packages."
        />
      </p>
      <p className="driverInfoDescription">Please add at least one pickup point.</p>
      <Form
        form={form}
        name="addressAndInstructions"
        layout="vertical"
        onFinish={onFinish}
        // onValuesChange={(changedValues, allValues) => {
        //   localStorage.setItem("addressAndInstructionsInfo", JSON.stringify(allValues));
        // }}
        hideRequiredMark
      >
        <RetailerPoints shortened isRegistration />
        <StepActions currentStep={currentStep} onPrevStep={onPrevStep} stepsLength={stepsLength} />
      </Form>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: API_KEY,
})(AddressAndInstructions);
