import React, { useState, useRef, useCallback } from "react";
import { Select, Button, Divider, Spin, List, Popconfirm, Popover } from "antd";
import { useJsApiLoader, DrawingManager, GoogleMap, Polygon, Marker } from "@react-google-maps/api";
import { DeleteOutlined, EditOutlined, AimOutlined } from "@ant-design/icons";
import WarehouseIcon from "../assets/Warehouse.svg";
import EditZoneName from "./editZoneName.tsx";
import { API_KEY, mapCenters } from "../../../../constants/config";

const { Option } = Select;
const libraries = ["drawing"];

const MapButton = ({ zoneName, button, after, onEdit }) => {
  const [showEditPopover, setShowEditPopover] = useState(false);

  const handleOpenChange = (newOpen) => {
    setShowEditPopover(newOpen);
  };

  const handleOnHide = () => {
    setShowEditPopover(false);
  };

  const handleOnSave = (zoneName) => {
    onEdit(zoneName);
    setShowEditPopover(false);
  };

  return (
    <div className="zone-button-container">
      <Button {...button}>
        <AimOutlined />
        {zoneName || ""}
      </Button>
      <Popover
        content={<EditZoneName zoneName={zoneName} onSave={handleOnSave} onHide={handleOnHide} />}
        trigger="click"
        visible={showEditPopover}
        onVisibleChange={handleOpenChange}
      >
        <Button default>
          <EditOutlined />
        </Button>
      </Popover>
      <Popconfirm title="Are you sure to delete zone?" onConfirm={after} okText="Yes" cancelText="No">
        <Button>
          <DeleteOutlined style={{ color: "red" }} />
        </Button>
      </Popconfirm>
    </div>
  );
};

const Map = ({
  zones,
  handleAddZones,
  handleUpdateZones,
  handleZoneWarehouseChange,
  handleDeleteZones,
  warehouses,
  selectedZoneIndex,
  setSelectedZoneIndex,
  handleUpdateZoneName,
  getUserTenant,
}) => {
  const polygonRef = useRef([]);
  const listenersRef = useRef([[]]);
  const [map, setMap] = useState();
  const tenant = getUserTenant();
  const defaultCenter = tenant ? { lat: mapCenters[tenant].lat, lng: mapCenters[tenant].long } : { lat: 0, lng: 0 };
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: API_KEY,
    libraries,
  });
  const [state, setState] = useState({
    drawingControlEnabled: true,
    visible: true,
    polygonData: zones,
    zoneIndex: null,
    center: defaultCenter,
    addNewZone: null,
  });

  const setCustomLocation = () => {
    const tenant = getUserTenant();
    if (tenant) {
      const mapCenter = mapCenters[tenant];
      setState((state) => ({ ...state, center: { lat: mapCenter.lat, lng: mapCenter.long } }));
    }
  };

  // eslint-disable-next-line
  const onEdit = (i) => (event) => {
    if (polygonRef.current[i]) {
      const nextPath = polygonRef.current[i]
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), long: latLng.lng() };
        });
      handleUpdateZones({
        id: zones[i].id,
        name: zones[i].name,
        polygon: nextPath,
      });
    }
  };

  const getPolyCenter = (zone) => {
    const bounds = new window.google.maps.LatLngBounds();
    const polygonCoords = [];
    zone.polygon.forEach((p) => {
      polygonCoords.push(new window.google.maps.LatLng(p.lat, p.long));
    });
    for (let i = 0; i < polygonCoords.length; i++) {
      bounds.extend(polygonCoords[i]);
    }

    return { lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() };
  };

  const getPolyBounds = (zone) => {
    const bounds = new window.google.maps.LatLngBounds();
    const polygonCoords = [];
    zone.polygon.forEach((p) => {
      polygonCoords.push(new window.google.maps.LatLng(p.lat, p.long));
    });
    for (let i = 0; i < polygonCoords.length; i++) {
      bounds.extend(polygonCoords[i]);
    }
    return bounds;
  };
  const onLoad = useCallback(
    (i) => (polygon) => {
      polygonRef.current[i] = polygon;
      const path = polygon.getPath();
      listenersRef.current[i] = [];
      listenersRef.current[i].push(
        path.addListener("set_at", () => onEdit(i)),
        path.addListener("insert_at", () => onEdit(i)),
        path.addListener("remove_at", () => onEdit(i)),
      );
    },
    [onEdit],
  );

  const onUnmount = useCallback(
    (i) => (event) => {
      listenersRef.current[i].forEach((lis) => lis.remove());
      polygonRef.current[i] = null;
    },
    [],
  );

  const setMapCenter =
    ({ lat, long }) =>
      (event) => {
        setState((state) => ({ ...state, center: { lat, lng: long } }));
      };

  const createPolygon = (pol) => {
    const array = pol.getPath().getArray();
    const output = [];
    array.forEach((e) => {
      output.push({
        lat: e.lat(),
        long: e.lng(),
      });
    });
    handleAddZones({ polygon: output });
    pol.setMap(null);
  };

  const zoneBtnClicked = (zone, index) => (event) => {
    setSelectedZoneIndex(index)();
    map.fitBounds(getPolyBounds(zone));
    setState((state) => {
      return {
        ...state,
        polygon: zone.polygon,
        center: getPolyCenter(zone),
        zoneIndex: index,
      };
    });
  };

  const newZoneBtnClicked = () => {
    setState((state) => {
      return {
        ...state,
        addNewZone: !state.addNewZone,
      };
    });
  };

  const containerStyle = {
    width: "100%",
    height: "600px",
  };

  const onLoadMap = React.useCallback(function callback(map) {
    setCustomLocation();
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const zoneNameOnEdit = useCallback(
    (newName, index) =>
      handleUpdateZoneName({
        id: zones[index].id,
        name: newName,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [zones],
  );

  const renderItem = (zone, index) => (
    <>
      <List.Item>
        <MapButton
          button={{ type: selectedZoneIndex === index ? "primary" : "", onClick: zoneBtnClicked(zone, index) }}
          after={handleDeleteZones(zone.id)}
          zoneName={zone.name || `Zone id: ${zone.id}`}
          onEdit={(newName) => zoneNameOnEdit(newName, index)}
        />
      </List.Item>
    </>
  );

  return (
    <div>
      <List size="small" grid={{ column: "auto", gutter: "1px" }} dataSource={zones} renderItem={renderItem} />
      <Divider />
      <span>Selected zone warehouse: </span>
      <Select
        disabled={!zones[selectedZoneIndex]?.id}
        style={{ width: "150px" }}
        value={zones[selectedZoneIndex]?.warehouse?.name || null}
        onChange={handleZoneWarehouseChange(zones[selectedZoneIndex]?.id)}
      >
        <Option value={null}>Auto</Option>

        {warehouses.map((warehouse) => {
          return (
            <Option key={warehouse.id} onMouseEnter={setMapCenter(warehouse)} value={warehouse.id}>
              {warehouse.name || `Warehouse id:${warehouse.id}`}
            </Option>
          );
        })}
      </Select>
      <Button onClick={newZoneBtnClicked} type={state?.addNewZone ? "primary" : ""}>
        Toggle zone editor
      </Button>
      <Divider />
      {isLoaded ? (
        <GoogleMap
          defaultZoom={0}
          on
          mapContainerStyle={containerStyle}
          center={{ lat: state.center.lat, lng: state.center.lng }}
          onLoad={onLoadMap}
        >
          {state.addNewZone === true && (
            <DrawingManager
              drawingMode={window.google.maps.drawing.OverlayType.POLYGON}
              options={{
                drawingControl: true,
                drawingControlOptions: {
                  position: window.google.maps.ControlPosition.TOP_CENTER,
                  drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
                },
                polygonOptions: { editable: true, draggable: true },
              }}
              onPolygonComplete={createPolygon}
            />
          )}

          {zones?.length &&
            zones.map((zone, index) => (
              <Polygon
                key={zone.id}
                options={{
                  fillColor: "#01cdfb",
                  fillOpacity: selectedZoneIndex === index ? 0.7 : 0.4,
                  strokeColor: "black",
                  strokeWeight: state.addNewZone ? 2 : 0,
                }}
                // onRightClick={handleDeleteZones(zone.id)}
                onMouseDown={setSelectedZoneIndex(index)}
                onMouseUp={onEdit(index)}
                onDragEnd={onEdit(index)}
                onLoad={onLoad(index)}
                onUnmount={onUnmount(index)}
                paths={zone.polygon}
                editable={state.addNewZone && selectedZoneIndex === index}
              />
            ))}

          {warehouses?.length > 0
            ? warehouses.map((e, i) => {
              return <Marker key={e.id} position={{ lat: e.lat, lng: e.long }} icon={WarehouseIcon} />;
            })
            : null}
        </GoogleMap>
      ) : (
        <Spin />
      )}
    </div>
  );
};

export default Map;
