import React , { FC } from "react";
import { Button, Popover, Row, Typography } from "antd";

import { SettingActionsProps } from "../types";
import { DeleteOutlined ,EditOutlined, SaveOutlined , CloseOutlined} from "@ant-design/icons";
import "../styles/components.less";



const { Paragraph } = Typography;

const SettingActions : FC<SettingActionsProps> = ({
    id, 
    handleDeleteSetting, 
    setEditedSetting, 
    IsEditing,
    data, 
    handleSavePriceSetting
    }) => {
        const onMainActionClick = () => {
            if(isEditing) {
                handleSavePriceSetting();
            }
            else {
                handleDeleteSetting(id);
            }
        }
        const onSecondaryActionClick = () => {
            if(isEditing) {
                setEditedSetting(null);
            }
            else {
                setEditedSetting({...data,id});
            }
        }
        const isEditing = IsEditing(id);
        const content = (
            <>
                <Paragraph>Are you sure you want to <br /> {isEditing ? "save" : "delete" } the setting?</Paragraph>
                <Button className={isEditing ? "save-setting-btn" : "delete-setting-btn"} onClick={onMainActionClick}>
                    {isEditing ? "Save" : "Delete Price Setting"}
                </Button>
            </>
        );
        return (
            (
            <>
                <Row>
                    <Popover content={content} trigger="click" placement="bottom">
                        <Button className="no-border-style">
                            {isEditing ? <SaveOutlined /> : <DeleteOutlined />}
                        </Button>
                    </Popover>
                    <Button className="no-border-style">
                        { isEditing ? 
                            <CloseOutlined onClick={onSecondaryActionClick} />  : 
                            <EditOutlined onClick={onSecondaryActionClick} />
                        }
                    </Button>
                </Row>
            </>
            ) || "No actions"
        );
}

export default SettingActions;
