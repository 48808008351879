import React, { useState } from "react";
import { Button, Modal } from "antd";

import RetailerSelect from "../../../../components/Select";

function ReportsModal({ isOpen, onClose, retailersList, generateReport, isLoading = false }) {
  const [selectedRetailer, setRetailer] = useState(null);

  const generateEnvReport = () => {
    generateReport(selectedRetailer);
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="Cancel" onClick={onClose} loading={isLoading}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={generateEnvReport} loading={isLoading}>
          Generate
        </Button>,
      ]}
    >
      <div>
        <p>Choose retailer to generate report</p>
        <RetailerSelect
          className="reportModalSelect"
          onChange={setRetailer}
          defaultLabel="All retailers"
          list={retailersList}
        />
      </div>
    </Modal>
  );
}

export default ReportsModal;
