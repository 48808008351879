import React, { useEffect, useCallback, useState } from "react";
import { Button, Drawer, notification, List, Table, Image, Badge, Radio } from "antd";
// import moment from "moment";
import { RightSquareOutlined } from "@ant-design/icons";

import { StatusPopover } from "./components/StatusPopover";

export const RetailerChangesTable = ({
  location,
  data,
  pagination,
  selection,
  getQueryParams,
  onChangePagination,
  handleToggle,

  getOneRetailerData,
  fetchRetailerChangeNotifications,
  fetchRetailerChangeNumberNotifications,
  approveRetailerChangeNotifications,
  rejectRetailerChangeNotifications,
}) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [openedRecord, setOpenedRecord] = useState(false);

  const [statusFilter, setStatusFilter] = useState("");

  const [updatesSummary, setUpdatesSummary] = useState([]);
  const fetchData = useCallback(() => {
    const queryParams = getQueryParams();
    const queryWithPage = queryParams?.page ? queryParams : { page: 1, limit: 10 };

    fetchRetailerChangeNotifications(
      {},
      {
        query: statusFilter ? { ...queryWithPage, status: statusFilter } : queryWithPage,
      },
    );
  }, [fetchRetailerChangeNotifications, getQueryParams, statusFilter]);

  useEffect(() => {
    fetchData();
  }, [fetchData, location.search, statusFilter]);

  //   useEffect(() => {

  //   }, []);

  const onApproveStatus = (id) => async () => {
    try {
      await approveRetailerChangeNotifications({ id });
      await fetchData();
    } catch (e) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Something went wrong.",
      });
    }
  };

  const onRejectStatus = (id) => async () => {
    try {
      await rejectRetailerChangeNotifications({ id });
      await fetchData();
    } catch (e) {
      notification.error({
        message: "Error",
        description: e?.body?.message || "Something went wrong.",
      });
    }
  };

  //   const onWaitStatus = (requestId) => async () => {
  //     try {
  //       await waitDriversPoints({ requestId });
  //       await fetchData();
  //     } catch (e) {
  //       notification.error({
  //         message: "Error",
  //         description: e?.body?.message || "Something went wrong.",
  //       });
  //     }
  //   };

  const onOpenChanges = (retailer, updates, record) => () => {
    getOneRetailerData(retailer).then((res) => {
      setDrawerOpen(true);
      setOpenedRecord(record);
      let newUpdates = Object.keys(updates).map((key) => {
        if (key === "commercialRegistration") {
          return {
            index: key.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`),
            old: res.body[key],
            new: updates[key],
          }; // eslint-disable-next-line
        } else if (key === "pickupPoint") {
          return {
            index: key.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`),
            // eslint-disable-next-line
            old: res.body[key + "s"]?.filter((e) => e.id === updates[key].id)?.[0],
            new: updates[key],
          };
        } else
          return {
            index: key.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`),
            old: retailer[key],
            new: updates[key],
          };
      });
      setUpdatesSummary(newUpdates);
    });
  };

  const columns = [
    {
      title: "Request ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Retailer ID",
      dataIndex: "retailerId",
      key: "retailerId",
    },
    {
      title: "Retailer name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Retailer email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Retailer phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    // {
    //   title: "Time",
    //   dataIndex: "time",
    //   key: "time",
    // },
    {
      title: "Changes",
      dataIndex: "retailer",
      key: "retailer",
      render: (retailer, record) => {
        return record?.status === "pending" ? (
          <Button onClick={onOpenChanges(retailer, record?.updatePayload, record)}>Review</Button>
        ) : (
          "request closed"
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "85px",
      render: (status, record) => {
        return (
          <>
            {/* eslint-disable-next-line */}
            {status === "pending" ? (
              <StatusPopover
                record={record}
                onApproveStatus={onApproveStatus}
                onRejectStatus={onRejectStatus}
                // onWaitStatus={onWaitStatus}
              />
            ) : status !== "waiting" ? (
              <span>{status}</span>
            ) : (
              <StatusPopover
                record={record}
                isWaiting
                onApproveStatus={onApproveStatus}
                onRejectStatus={onRejectStatus}
                // onWaitStatus={onWaitStatus}
              />
            )}
          </>
        );
      },
    },
  ];

  const dataSource = data?.map(({ id, retailer, status, time, updatePayload }) => {
    return {
      id,
      retailer,
      retailerId: retailer?.id,
      name: retailer?.name,
      email: retailer?.email,
      phoneNumber: retailer?.phoneNumber,
      updatePayload,
      status,
      // time: moment(time).format("DD.MM.YYYY HH:mm"),
    };
  });

  const currentQueryParams = getQueryParams();

  const paginationConfig = {
    pageSize: Number(currentQueryParams?.limit) || 10,
    total: pagination?.totalCount || 10,
    current: Number(currentQueryParams?.page) || 1,
    pageSizeOptions: [10, 20, 30, 50],
    showSizeChanger: true,
    position: ["bottomRight"],
  };

  // const rowSelection = {
  //   onChange: handleToggle,
  // };

  return (
    <>
      <Radio.Group value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
        <Radio.Button value=""> All</Radio.Button>
        <Radio.Button value="pending"> Pending</Radio.Button>
        <Radio.Button value="approved"> Approved</Radio.Button>
        <Radio.Button value="declined"> Declined</Radio.Button>
      </Radio.Group>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        // rowSelection={{
        //   selectedRowKeys: selection,
        //   type: "checkbox",
        //   ...rowSelection,
        // }}
        dataSource={dataSource}
        pagination={paginationConfig}
        onChange={onChangePagination}
      />
      <Drawer width="80vw" placement="right" closable onClose={() => setDrawerOpen(false)} visible={isDrawerOpen}>
        <List
          header={<div>Review changes:</div>}
          footer={
            openedRecord?.status === "pending" &&
            isDrawerOpen && (
              <StatusPopover
                record={openedRecord}
                onApproveStatus={onApproveStatus}
                onRejectStatus={onRejectStatus}
                onAction={() => {
                  fetchRetailerChangeNotifications();
                  setDrawerOpen(false);
                }}
                // onWaitStatus={onWaitStatus}
              />
            )
          }
          bordered
          dataSource={updatesSummary}
          renderItem={(item) => {
            if (item.new instanceof Object && item.old) {
              return Object.keys(item.new).map((key) => (
                <>
                  {!["id", "path", "createdAt"].includes(key) &&
                    item.old[key] !== item.new[key] &&
                    String(item.old[key]) !== String(item.new[key]) && (
                      <List.Item>
                        {key !== "url" ? (
                          <>
                            <strong> {key}:</strong> {item.old[key]}
                            <span style={{ margin: "0 10px", color: "#01cdfb" }}>
                              <RightSquareOutlined />
                            </span>
                            {item.new[key]}
                          </>
                        ) : (
                          <>
                            <List.Item.Meta
                              title={<strong>Changed document:</strong>}
                              description={
                                <>
                                  <div style={{ width: "40%" }}>
                                    <Badge.Ribbon text="Old">
                                      <Image src={item.old[key]} />
                                    </Badge.Ribbon>
                                  </div>
                                  <div style={{ width: "40%" }}>
                                    <Badge.Ribbon text="New">
                                      {" "}
                                      <Image src={item.new[key]} />
                                    </Badge.Ribbon>
                                  </div>
                                </>
                              }
                            />
                            {/* <strong style={{ width: "100%" }}> Changed document :</strong> */}
                          </>
                        )}
                      </List.Item>
                    )}
                </>
              ));
              // eslint-disable-next-line
            } else if (!item.old && item.new instanceof Object) {
              return Object.keys(item.new).map((key) => (
                <>
                  {!["id", "path", "createdAt"].includes(key) && (
                    <List.Item>
                      {key !== "url" ? (
                        <>
                          <strong> {key}:</strong> Unset
                          <span style={{ margin: "0 10px", color: "#01cdfb" }}>
                            <RightSquareOutlined />
                          </span>
                          {item.new[key]}
                        </>
                      ) : (
                        <>
                          <List.Item.Meta
                            title={<strong>Changed document:</strong>}
                            description={
                              <>
                                <div style={{ width: "40%" }}>
                                  <Badge.Ribbon text="Old">Unset</Badge.Ribbon>
                                </div>
                                <div style={{ width: "40%" }}>
                                  <Badge.Ribbon text="New">
                                    <Image src={item.new[key]} />
                                  </Badge.Ribbon>
                                </div>
                              </>
                            }
                          />
                        </>
                      )}
                    </List.Item>
                  )}
                </>
              ));
            } else {
              // eslint-disable-next-line
              return (
                <>
                  {item.old !== item.new && (
                    <List.Item>
                      <strong> Changed {item.index} :</strong> <br />
                      {item.old}
                      <span style={{ margin: "0 10px", color: "#01cdfb" }}>
                        <RightSquareOutlined />
                      </span>
                      {item.new}
                    </List.Item>
                  )}
                </>
              );
            }
          }}
        />
      </Drawer>
    </>
  );
};
