import React from "react";
import { Menu } from "antd";
import { FormattedMessage } from "react-intl";

export const deleteItem = {
  messageId: "tabPane.actions.delete",
  type: "delete",
  buttonId: "order-delete",
  defaultMessage: "Delete",
};

export const returnItem = {
  messageId: "tabPane.actions.return",
  type: "return",
  defaultMessage: "Return",
};

export const restoreItem = {
  messageId: "tabPane.actions.restore",
  type: "restore",
  defaultMessage: "Restore",
};

export const restoreFailedItem = {
  messageId: "tabPane.actions.restore",
  type: "restoreFailed",
  defaultMessage: "New",
};
export const journeyView = {
  messageId: "tabPane.actions.history",
  type: "journeyView",
  defaultMessage: "Check history",
};
// export const returnFormView = {
//   messageId: "tabPane.actions.returnFormView",
//   type: "returnFormView",
//   defaultMessage: "Download Form",
// };

export const returnToShipper = {
  messageId: "tabPane.actions.returnToShipper",
  type: "returnToShipper",
  defaultMessage: "Return to shipper",
};

export const cancelCompleted = {
  messageId: "tabPane.actions.cancelCompleted",
  type: "cancelCompleted",
  defaultMessage: "Cancel Completed Order",
};
export const menuItems = [
  {
    messageId: "tabPane.actions.cancel",
    type: "cancel",
    defaultMessage: "Cancel",
  },
  {
    messageId: "tabPane.actions.postpone",
    type: "postpone",
    defaultMessage: "Postpone",
  },
  deleteItem,
];

export const adminItems = [
  {
    messageId: "tabPane.actions.removeLocation",
    type: "removeLocation",
    defaultMessage: "Remove location",
  },
  {
    messageId: "tabPane.actions.completeOrder",
    type: "completeOrder",
    defaultMessage: "Complete order",
  },
  journeyView,
];

function DropdownMenu({ id, onSetModal, isAdminOrders, providedItems, isReturn }) {
  const itemsToRender = providedItems || [...menuItems, ...(isAdminOrders ? adminItems : [])];

  // if (isReturn) {
  //   itemsToRender.push(returnFormView);
  // }
  return (
    <Menu>
      {itemsToRender.map(({ messageId, type, buttonId, defaultMessage }) => (
        <Menu.Item
          id={buttonId}
          onClick={(e) => {
            onSetModal({ type, id, open: ["journeyView"].includes(type) ? false : true });
          }}
          key={messageId + id}
        >
          <FormattedMessage id={messageId} defaultMessage={defaultMessage} />
        </Menu.Item>
      ))}
    </Menu>
  );
}

export default DropdownMenu;
