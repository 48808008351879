import React, { useState, useEffect } from "react";
import { DatePicker, Card, Row, Col, Pagination, Input, Select, Button, Spin } from "antd";
import moment from "moment";
import { API_URL } from "src/constants/config";

const { RangePicker } = DatePicker;
const { Option } = Select;

const CashToBeCollected = () => {
  const [drivers, setDrivers] = useState([]);
  const [cashData, setCashData] = useState({});
  const [selectedDateRange, setSelectedDateRange] = useState([moment(), moment()]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 16,
    totalPages: 0,
    currentPage: 1,
  });
  const [searchType, setSearchType] = useState("email");
  const [searchValue, setSearchValue] = useState("");

  let isMounted = true;

  const handleSearchTypeChange = (value) => {
    setSearchType(value);
  };

  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = () => {
    fetchDrivers(1, { [searchType]: searchValue });
  };

  const fetchDrivers = async (page, searchParams) => {
    const getAuthToken = JSON.parse(localStorage.getItem("authData"));
    const token = getAuthToken.accessToken;
    try {
      setLoading(true);

      let apiUrl = `${API_URL}/admin/drivers?page=${page}&limit=${pagination.itemsPerPage}&status=accepted`;

      if (searchParams) {
        apiUrl += `&${Object.keys(searchParams)[0]}=${searchParams[Object.keys(searchParams)[0]]}`;
      }

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          Tenant: localStorage.getItem("tenantSelected"),
        },
      });
      const data = await response.json();

      if (isMounted) {
        setDrivers(data?.items);
        setPagination({
          totalItems: data.meta.totalItems,
          itemCount: data.meta.itemCount,
          itemsPerPage: data.meta.itemsPerPage,
          totalPages: data.meta.totalPages,
          currentPage: data.meta.currentPage,
        });
      }
    } catch (error) {
      console.error("Error fetching drivers data:", error);
    } finally {
      if (isMounted) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    isMounted = true;
    fetchDrivers(1);

    return () => {
      isMounted = false;
    };
  }, []);

  const fetchCashData = async (driverId) => {
    const getAuthToken = JSON.parse(localStorage.getItem("authData"));
    const token = getAuthToken.accessToken;
    try {
      setLoading(true);

      const response = await fetch(
        `${API_URL}/admin/drivers/${driverId}/stats/cash?from=${selectedDateRange[0].format(
          "YYYY-MM-DD",
        )}&to=${selectedDateRange[1].format("YYYY-MM-DD")}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Tenant: localStorage.getItem("tenantSelected"),
          },
        },
      );
      const data = await response.json();

      if (isMounted) {
        setCashData((prevData) => ({
          ...prevData,
          [driverId]: data.cash,
        }));
      }
    } catch (error) {
      console.error("Error fetching cash data:", error);
    } finally {
      if (isMounted) {
        setLoading(false);
      }
    }
  };

  const handleDateRangeChange = (dateRange) => {
    setSelectedDateRange(dateRange);
  };

  const handlePageChange = (page) => {
    fetchDrivers(page);
  };

  useEffect(() => {
    drivers.forEach((driver) => {
      fetchCashData(driver.id);
    });
  }, [selectedDateRange, drivers]);

  const currency = localStorage.getItem("tenantSelected");

  return (
    <div>
      <Row gutter={[16, 16]} justify="center" style={{ marginBottom: "4%" }}>
        <Col xs={24} sm={12} md={8} lg={6}>
          <RangePicker value={selectedDateRange} onChange={handleDateRangeChange} />
        </Col>
        <Col xs={24} sm={12} md={8} lg={2}>
          <Select defaultValue="email" style={{ width: "100%" }} onChange={handleSearchTypeChange}>
            <Option value="email">Email</Option>
            <Option value="name">Name</Option>
            <Option value="plateNumber">Plate Number</Option>
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Input
            placeholder={`Enter ${searchType === "plateNumber" ? "Plate Number" : "Search"}`}
            onChange={handleSearchInputChange}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Button type="primary" onClick={handleSearch}>
            Search
          </Button>
        </Col>
      </Row>

      <Spin spinning={loading}>
        <Row gutter={[16, 16]}>
          {drivers?.map((driver) => (
            <Col key={driver.id} xs={24} sm={12} md={8} lg={6}>
              <Card title={`${driver.profile?.firstName || "No Name"} ${driver.profile?.lastName || ""}`}>
                <p>Driver Id: {driver.id}</p>
                <p>
                  Cash Collected: {cashData[driver.id] || 0}{" "}
                  {currency === "Qatar"
                    ? "QAR"
                    : currency === "Jordan"
                    ? "JD"
                    : currency === "Saudi Arabia"
                    ? "SAR"
                    : currency === "Iraq"
                    ? "IQD"
                    : currency === "UAE"
                    ? "AED"
                    : ""}
                </p>
              </Card>
            </Col>
          ))}
        </Row>

        <Pagination
          current={pagination.currentPage}
          total={pagination.totalItems}
          pageSize={pagination.itemsPerPage}
          onChange={handlePageChange}
          showSizeChanger={false}
          style={{ marginTop: "16px", textAlign: "center" }}
        />
      </Spin>
    </div>
  );
};

export default CashToBeCollected;
