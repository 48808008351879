import React, { useEffect, useState } from "react";
import { Button, Form, Input, InputNumber, notification, Popconfirm, Popover } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, CompassOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import GoogleMapReact from "google-map-react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { FormattedMessage, useIntl } from "react-intl";
import { GoogleApiWrapper } from "google-maps-react";

import { useForm } from "antd/lib/form/Form";
import { useMarker } from "./useMarker";
import { GEOCODE_KEY, API_KEY } from "../../../../../../constants/config";
import { ReactComponent as Target } from "../../../../../../assets/map/target.svg";
import "./shareLocationMap.less";
import { customFetch } from "../../../../../../utils/customFetch";

const handleMapReady = (mapProps, map) => {
  map.setOptions({
    draggableCursor: "default",
    draggingCursor: "pointer",
  });
};

function ShareLocationMap({
  onToggleCustomLocationModal,
  openNotification,
  shareLocationCustomer,
  shareLocationBuildingCustomer,
  selectedOrderId,
  onFetchOrdersCustomer,
  disabled,
  buildingNumNeeded,
  isMobile,
}) {
  const intl = useIntl();
  const [{ lat, lng }, setCustomLocation] = useState({ lat: "", lng: "" });
  const [{ currentLat, currentLng }, setViewCenter] = useState({ currentLat: "", currentLng: "" });
  const [displayedAddress, setDisplayedAddress] = useState("");
  const [locationShared, setLocationShared] = useState(false);
  // const locationShared = false;
  // const setLocationShared = () => {};
  const [locationValid, setLocationValid] = useState(false);
  const [buildingNum, setBuildingNum] = useState("");
  const [plusCode, setPlusCode] = useState("");
  const [addressSearch, setAddressSearch] = useState("");

  const handleSelect = async (value) => {
    setAddressSearch(value);
    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);
    setCustomLocation({ lat: latLng.lat, lng: latLng.lng });
    setDisplayedAddress(value);
  };

  useEffect(() => {
    setAddressSearch(displayedAddress);
  }, [displayedAddress]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocationShared(true);
          setCustomLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
          setViewCenter({ currentLat: position.coords.latitude, currentLng: position.coords.longitude });
        },
        () => {
          setLocationShared(false);
          notification.open({
            message: `${intl.formatMessage({
              id: "page.client.home.map.noGps",
              defaultMessage: "Location services are turned off.",
            })}`,
            description: `${intl.formatMessage({
              id: "page.client.home.map.startGps",
              defaultMessage: "Please turn on location services from your settings and reopen this link.",
            })}`,
          });
        },
      );
    } else {
      setLocationShared(false);
      notification.open({
        message: `${intl.formatMessage({
          id: "page.client.home.map.noGeo",
          defaultMessage: "Geolocation is not supported",
        })}`,
        description: `${intl.formatMessage({
          id: "page.client.home.map.noGeoBrowser",
          defaultMessage: "Geolocation is not supported by this browser",
        })}`,
      });
    }
    return () => {
      setLocationShared(false);
      setCustomLocation({ lat: "", lng: "" });
      setViewCenter({ currentLat: "", currentLng: "" });
      setDisplayedAddress("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lng) {
      customFetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=true&APPROXIMATE=false&key=${GEOCODE_KEY}`,
      )
        .then((res) => res.json())
        .then((res) => {
          if (res?.results[0]) {
            if (
              res?.results[0].formatted_address === res?.plus_code?.global_code ||
              res?.plus_code?.global_code?.includes(res?.results[0].formatted_address)
            ) {
              setLocationValid(false);
              notification.close("1");
              notification.warn({
                duration: 0,
                key: "1",
                placement: "bottomLeft",
                message: `${intl.formatMessage({
                  id: "shared.youSure",
                  defaultMessage: "Are you sure?",
                })}`,
                description: `${intl.formatMessage({
                  id: "page.client.home.map.strangeLoc",
                  defaultMessage: "Location seems strange!",
                })}`,
              });
            } else {
              notification.close("1");
              setLocationValid(true);
            }
            if (res.results[0]?.address_components[0]?.types?.includes("street_number")) {
              setBuildingNum(res.results[0]?.address_components[0]?.long_name);
            } else {
              setBuildingNum("");
            }
            setDisplayedAddress(res.results[0]?.formatted_address);
            setPlusCode(res?.plus_code?.global_code);
          }
        });
    }
    form.setFieldsValue({ lat, lng });
    // eslint-disable-next-line
  }, [lat, lng]);

  const getCurrentLocation = (e) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCustomLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
      });
    } else {
      openNotification(
        `${intl.formatMessage({
          id: "page.client.home.map.noGeoBrowser",
          defaultMessage: "Geolocation is not supported by this browser",
        })}`,
      );
    }
  };

  const shareLocation = () => {
    if (buildingNum || !buildingNumNeeded) {
      const notificationDescription = (
        <div>
          <p>Latitude: + ${lat} </p>
          <p>Longitude: + ${lng}</p>
        </div>
      );

      shareLocationCustomer({ lat, long: lng, orderId: selectedOrderId, address: displayedAddress })
        .then(() => {
          if (buildingNum) {
            shareLocationBuildingCustomer({ orderId: selectedOrderId, building: buildingNum });
          }
        })
        .then(() => {
          onFetchOrdersCustomer();
          openNotification(notificationDescription);
        })
        .catch((error) => openNotification(error.body.message, "error"));
      setTimeout(() => {
        onToggleCustomLocationModal()();
        setCustomLocation({ lat: "", lng: "" });
        setDisplayedAddress("");
      }, 1000);
    } else {
      notification.error({
        message: `${intl.formatMessage({
          id: "error.required",
          defaultMessage: "Field is required!",
        })}`,
        description: `${intl.formatMessage({
          id: "page.client.home.order.noBuilding",
          defaultMessage: "No building provided",
        })}`,
      });
    }
  };

  const handleClose = () => {
    onToggleCustomLocationModal()();
    setCustomLocation({ lat: "", lng: "" });
    setDisplayedAddress("");
  };
  const onManualCoordsSubmit = (values) => {
    setCustomLocation(values);
  };

  const { handleGoogleApiLoaded } = useMarker({ lat, lng });
  const [form] = useForm();
  const coordinateInput = () => (
    <div>
      <Form
        form={form}
        style={{ display: "flex", gap: 10 }}
        layout="horizontal"
        initialValues={{ lat, lng }}
        onFinish={onManualCoordsSubmit}
      >
        <Form.Item label="Lat" name="lat" rules={[{ required: true, message: "Input Lat" }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item label="Lng" name="lng" rules={[{ required: true, message: "Input Long" }]}>
          <InputNumber />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enter
          </Button>
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <div className="mapWrapper">
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        cursor="pointer"
        bootstrapURLKeys={{ key: API_KEY }}
        defaultCenter={{ lat: 42.86032, lng: 19.53049 }}
        center={{
          lat: lat && Number(lat),
          lng: lng && Number(lng),
        }}
        zoom={currentLat || lat ? 10 : 0}
        onReady={handleMapReady}
        onGoogleApiLoaded={handleGoogleApiLoaded}
        distanceToMouse={() => {}}
        onClick={(e) => {
          const ariaLabel = e?.event?.target.ariaLabel;
          if (!ariaLabel || !ariaLabel === "Zoom out" || !ariaLabel === "Zoom in") {
            setCustomLocation({ lat: e.lat, lng: e.lng });
          }
        }}
        defaultZoom={0}
        options={{ gestureHandling: "greedy", clickableIcons: false, fullscreenControl: false }}
      >
        {currentLat && <div className="currentLocation" lat={currentLat} lng={currentLng} />}
      </GoogleMapReact>
      {displayedAddress && (
        <div className="chosenAddressClient">
          <span style={{ minWidth: "260px", flexGrow: 1, display: "flex", alignItems: "center" }}>
            {locationShared ? (
              locationValid ? (
                <CheckCircleOutlined className="chosenAddressClient-green" />
              ) : (
                <QuestionCircleOutlined className="chosenAddressClient-gray" />
              )
            ) : (
              <CloseCircleOutlined className="chosenAddressClient-red" />
            )}
            {/* <Input
              // onChange={(e) => setBuildingNum(e.target.value)}
              value={
                displayedAddress
                  ?.replace(
                    /(^|\s)([23456789C][23456789CFGHJMPQRV][23456789CFGHJMPQRVWX]{6}\+[23456789CFGHJMPQRVWX]{2,3})(\s|$)?/,

                    "",
                  )
                  .replace(/(^|\s)([23456789CFGHJMPQRVWX]{4,6}\+[23456789CFGHJMPQRVWX]{2,3})(\s|$)?/, "") || ""
              }
              // placeholder="Please enter building number"
            /> */}
            <PlacesAutocomplete
              value={addressSearch}
              onChange={(address) => setAddressSearch(address)}
              onSelect={handleSelect}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div style={{ flexGrow: 1 }}>
                  <Input.Group compact style={{ display: "flex" }}>
                    <Input.Search
                      {...getInputProps({
                        placeholder: `${intl.formatMessage({ id: "input.input.search", defaultMessage: "Search" })}`,
                      })}
                      value={addressSearch}
                    />
                    <Popover content={coordinateInput} title="Input coordinates:" trigger="click">
                      <Button style={{ padding: "0 8px" }}>
                        <CompassOutlined />
                      </Button>
                    </Popover>
                  </Input.Group>
                  <div className="autocomplete-dropdown-container">
                    {loading && (
                      <div>
                        <FormattedMessage id="loading" defaultMessage="Loading..." />
                      </div>
                    )}
                    {suggestions.map((suggestion) => {
                      const style = { backgroundColor: suggestion.active ? "#fafafa" : "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            style,
                          })}
                          key={suggestion.description}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </span>
          <span>
            {`${intl.formatMessage({
              id: "input.building",
              defaultMessage: "Building",
            })}`}
            :
            <Input
              onChange={(e) => setBuildingNum(e.target.value)}
              value={buildingNum}
              placeholder={`${intl.formatMessage({
                id: "input.building",
                defaultMessage: "Building",
              })}`}
              style={{ width: "44px", marginLeft: "8px" }}
            />
          </span>
          <span>
            {`${intl.formatMessage({
              id: "page.client.home.map.plusCode",
              defaultMessage: "Plus code",
            })}`}
            : {plusCode}
          </span>
          {!isMobile && (
            <div style={{ float: "right" }}>
              {!locationValid && locationShared ? (
                <Popconfirm
                  title={`${intl.formatMessage({
                    id: "page.client.home.map.strangeAddress",
                    defaultMessage: "Address seems invalid, are you sure?",
                  })}`}
                  onConfirm={shareLocation}
                  // onCancel={}
                  okText={`${intl.formatMessage({
                    id: "yes",
                    defaultMessage: "Yes",
                  })}`}
                  cancelText={`${intl.formatMessage({
                    id: "no",
                    defaultMessage: "No",
                  })}`}
                >
                  <Button
                    disabled={!lng || !locationShared}
                    key="submit"
                    // style={{ width: !locationShared && "400px" }}
                    style={{ width: "fit-content", color: "black", borderRadius: "5px" }}
                    type="primary"
                  >
                    {locationShared
                      ? `${intl.formatMessage({
                          id: "modal.save",
                          defaultMessage: "Save",
                        })}`
                      : `${intl.formatMessage({
                          id: "page.client.home.map.shareToSave",
                          defaultMessage: "You need to share location to save",
                        })}`}
                  </Button>
                </Popconfirm>
              ) : (
                <Button
                  disabled={!lng || !locationShared}
                  key="submit"
                  // style={{ width: !locationShared && "400px" }}
                  style={{ width: "fit-content", color: "black", borderRadius: "5px" }}
                  type="primary"
                  onClick={shareLocation}
                >
                  {locationShared
                    ? `${intl.formatMessage({
                        id: "modal.save",
                        defaultMessage: "Save",
                      })}`
                    : `${intl.formatMessage({
                        id: "page.client.home.map.shareToSave",
                        defaultMessage: "You need to share location to save",
                      })}`}
                </Button>
              )}
              <Button
                key="back"
                style={{ width: "fit-content", color: "black", borderRadius: "5px", outline: "none", border: "none" }}
                onClick={handleClose}
              >
                {`${intl.formatMessage({
                  id: "tabPane.actions.cancel",
                  defaultMessage: "Cancel",
                })}`}
              </Button>
            </div>
          )}
        </div>
      )}
      <div className="map-actions" />
      {isMobile && (
        <>
          {!locationValid && locationShared ? (
            <Popconfirm
              title={`${intl.formatMessage({
                id: "page.client.home.map.strangeAddress",
                defaultMessage: "Address seems invalid, are you sure?",
              })}`}
              onConfirm={shareLocation}
              // onCancel={}
              okText={`${intl.formatMessage({
                id: "yes",
                defaultMessage: "Yes",
              })}`}
              cancelText={`${intl.formatMessage({
                id: "no",
                defaultMessage: "No",
              })}`}
            >
              <Button
                disabled={!lng || !locationShared}
                key="submit"
                // style={{ width: !locationShared && "400px" }}
                style={{
                  width: "fit-content",
                  color: "black",
                  borderRadius: "5px",
                  bottom: "10px",
                  left: `calc(50% - ${locationShared ? "62px" : "151px"} )`,
                  position: "absolute",
                }}
                type="primary"
              >
                {locationShared
                  ? `${intl.formatMessage({
                      id: "modal.save",
                      defaultMessage: "Save",
                    })}`
                  : `${intl.formatMessage({
                      id: "page.client.home.map.shareToSave",
                      defaultMessage: "You need to share location to save",
                    })}`}
              </Button>
            </Popconfirm>
          ) : (
            <Button
              disabled={!lng || !locationShared}
              key="submit"
              // style={{ width: !locationShared && "400px" }}
              style={{
                width: "fit-content",
                color: "black",
                borderRadius: "5px",
                bottom: "10px",
                left: `calc(50% - ${locationShared ? "62px" : "151px"} )`,
                position: "absolute",
              }}
              type="primary"
              onClick={shareLocation}
            >
              {locationShared
                ? `${intl.formatMessage({
                    id: "modal.save",
                    defaultMessage: "Save",
                  })}`
                : `${intl.formatMessage({
                    id: "page.client.home.map.shareToSave",
                    defaultMessage: "You need to share location to save",
                  })}`}
            </Button>
          )}
          <Button
            key="back"
            style={{
              width: "fit-content",
              color: "black",
              borderRadius: "5px",
              outline: "none",
              border: "none",
              bottom: "10px",
              // right: "calc(50% - 72px)",
              right: `calc(50% - ${locationShared ? "72px" : "151px"} )`,
              position: "absolute",
            }}
            onClick={handleClose}
          >
            {`${intl.formatMessage({
              id: "tabPane.actions.cancel",
              defaultMessage: "Cancel",
            })}`}
          </Button>
        </>
      )}
      {/*  )} */}
      <div className="findMe" onClick={getCurrentLocation}>
        <Button>
          <Target />
        </Button>
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: API_KEY,
})(ShareLocationMap);
