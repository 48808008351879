import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { notification, Table, Select, Button, Space } from "antd";
import { useIntl } from "react-intl";

import { getStatusBage } from "../../../../../helpers/general";
import JobsActionsDropdown from "./components/JobsActions";
import useOrderOperations from "./hooks/useOrderOperations";
import ExpandedJobOrdersList from "./components/ExpandedJobOrders";
import "./jobList.less";
import { connect, RootStateOrAny } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as deliveryJobActions } from "../../../../../redux/resources/deliveryJobs";
import { actions as retailerJobActions } from "../../../../../redux/resources/retailerJob";
import { IJobListMSTP } from "src/types/JobManagement";
import { BASE_ROUTE } from "src/routes";
import { JobStatusEnum } from "src/types/CombineJobs";

const { Option } = Select;

const JobsList: FC<any> = ({
  isAdmin,
  deliveryJobs,
  isFetchingDeliveryJobs,

  setSelectedOrder,
  handlePublishJob,
  setExpandedRowKeys,
  expandedRowKeys,
  downloadJobOrdersBarcodes,
  withErrors,
  selectedJobs,
  onJobsSelected,
  cleanJob,

  // actions
  cancelCompletedAdminOrder,

  // Admin Job
  fetchDeliveryJobs,
  deleteOrderDeliveryJob,
  getOneDeliveryJob,
  getRoutesDeliveryJob,
  changeOrderPositionDeliveryJob,
  forceNextOrderDeliveryJob,
  returnToShipperDeliveryJob,
  completeOrderDeliveryJob,
  assignJobDeliveryJob,
  cancelAssignJobDeliveryJob,
  fetchAvailableDriversDeliveryJob,
  postponeOrderDeliveryJob,
  cancelOrderDeliveryJob,
}) => {
  const intl = useIntl();
  const [availableDrivers, setAvailableDrivers] = useState([{ id: 0, name: "none" }]);
  const [selectedJob, setSelectedJob] = useState();
  const navigate = useHistory();

  const ordersOperations = useOrderOperations({
    fetchDeliveryJobs,
    deleteOrderDeliveryJob,
    getOneDeliveryJob,
    getRoutesDeliveryJob,
    postponeOrderDeliveryJob,
    forceNextOrderDeliveryJob,
    completeOrderDeliveryJob,
    returnToShipperDeliveryJob,
    cancelOrderDeliveryJob,
    cancelCompletedAdminOrder,
    withErrors,
  });

  useEffect(() => {
    updateAvailableDrivers();
    // eslint-disable-next-line
  }, [selectedJob]);

  const updateAvailableDrivers = async (jobId = selectedJob) => {
    try {
      if (jobId) {
        const res = await fetchAvailableDriversDeliveryJob(jobId);
        setAvailableDrivers(res.body);
      }
    } catch (error) {
      notification.warn({
        message: "Warning!",
        description: "There was an unexpected problem while fetcing drivers !",
      });
    }
  };
  const assignJobDriver = (jobId: any, driver: any) => {
    assignJobDeliveryJob(jobId, { query: { driverId: driver } })
      .then(() => {
        fetchDeliveryJobs({}, { query: { withError: withErrors } });
        updateAvailableDrivers(jobId);
      })
      .catch(() => {
        fetchDeliveryJobs({}, { query: { withError: withErrors } });
        notification.error({
          message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
          description: "Driver hasn't shared his location!",
        });
      });
  };
  const cancelJobDriver = (jobId: any, driver: any) => {
    cancelAssignJobDeliveryJob(jobId, { query: { driverId: driver } }).then(
      () => {
        fetchDeliveryJobs({}, { query: { withError: withErrors } });
        updateAvailableDrivers(jobId);
      },
      () => {
        notification.error({
          message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
          description: "Can't unassign driver now!",
        });
        fetchDeliveryJobs();
      },
    );
  };

  // TODO: Removing is for retailers
  // useSockets({ withErrors, fetchDeliveryJobs, expandedRowKeys, getRoutesDeliveryJob });

  const onExpandRow = async (expanded: any, record: any) => {
    if (expanded) {
      setExpandedRowKeys(record.id);
    } else {
      setExpandedRowKeys(null);
    }
    if (expanded && !record.deliveryOrders) {
      await getOneDeliveryJob({ id: record.id });
      await getRoutesDeliveryJob({ jobId: record.id });
    }
  };

  const onHandlePublishJob = async (jobId: any, bool: any) => {
    await handlePublishJob(jobId, bool)();
    setExpandedRowKeys(null);
  };

  const expandedRowRender = (record: any, index: any, indent: any, expanded: any) => {
    return (
      <ExpandedJobOrdersList
        isAdmin={isAdmin}
        record={record}
        changeOrderPositionDeliveryJob={changeOrderPositionDeliveryJob} // TODO turn these into function encapsulated inside orders hooks
        fetchDeliveryJobs={fetchDeliveryJobs}
        getRoutesDeliveryJob={getRoutesDeliveryJob}
        isFetchingDeliveryJobs={isFetchingDeliveryJobs}
        setSelectedOrder={setSelectedOrder}
        withErrors={withErrors}
        {...ordersOperations}
      />
    );
  };

  const navigateToConfigureJob = (id: number) => navigate.push(`/${BASE_ROUTE}/jobs/${id}/configure`);

  const navigateToJobDetails = (id: number) => navigate.push(`/${BASE_ROUTE}/jobs/${id}/details`);

  const data = deliveryJobs.map((job: any) => {
    return { ...job, jobId: job.id, key: job.id };
  });

  const columns = [
    {
      title: "Driver",
      dataIndex: "driver",
      key: "driver",
      render: (driver: any, row: any) => {
        return driver?.profile?.firstName ? (
          <DriverFakeSelector
            name={`${driver?.profile?.firstName} ${driver?.profile?.lastName}`}
            driver={driver}
            job={row?.jobId}
            disabled={row?.status === "completed"}
          />
        ) : (
          <DriverSelector disabled={row?.status === "completed"} availableDrivers={availableDrivers} job={row?.jobId} />
        );
      },
    },
    { title: "ID", dataIndex: "jobId", key: "jobId" },
    { title: "Packages count", dataIndex: "packagesCount", key: "packagesCount" },
    { title: "Pickup points", dataIndex: "pickupPointsCount", key: "pickupPointsCount" },
    { title: "Delivery points", dataIndex: "deliveryPointsCount", key: "deliveryPointsCount" },
    { title: "Overall delivery time", dataIndex: "totalTime", key: "totalTime" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: any) => getStatusBage(status.split("_").join(" ")),
    },
    {
      title: "",
      key: "view-details",
      render: (data: any) => {
        const isEditDisabled = !!(
          data?.status === JobStatusEnum.COMPLETED ||
          data?.status === JobStatusEnum.PICKUP_COMPLETED ||
          data?.status === JobStatusEnum.IN_TRANSIT
        );
        return (
          <Space>
            <Button type="link" onClick={() => navigateToJobDetails(data.id)}>
              Details
            </Button>
            <Button type="link" disabled={isEditDisabled} onClick={() => navigateToConfigureJob(data.id)}>
              Configure
            </Button>
          </Space>
        );
      },
    },
    // TODO: Temporarily hiding this action
    // {
    //   title: "Published",
    //   key: "published",
    //   render: (data: any) => {
    //     const disabled = !!data.driver;
    //     return data.published ? (
    //       <Popconfirm
    //         disabled={disabled}
    //         title={!disabled ? "Are you sure you want to unpublish this job?" : ""}
    //         onConfirm={() => onHandlePublishJob(data.id, false)}
    //         okText="Yes"
    //         cancelText="No"
    //       >
    //         <Button disabled={disabled}>Unpublish</Button>
    //       </Popconfirm>
    //     ) : (
    //       <Popconfirm
    //         disabled={disabled}
    //         title={!disabled ? "Are you sure you want to publish?" : ""}
    //         onConfirm={() => onHandlePublishJob(data.id, true)}
    //         okText="Yes"
    //         cancelText="No"
    //       >
    //         <Button disabled={disabled}>Publish</Button>
    //       </Popconfirm>
    //     );
    //   },
    // },
    // {
    //   title: "Actions",
    //   key: "jobAction",
    //   render: (data: any) => {
    //     return (
    //       <JobsActionsDropdown
    //         downloadJobOrdersBarcodes={downloadJobOrdersBarcodes}
    //         cleanJob={cleanJob}
    //         record={{ id: data.id }}
    //         navigateToConfigure={navigateToConfigureJob}
    //       />
    //     );
    //   },
    // },
  ];

  const DriverSelector = ({ availableDrivers, job, disabled }: any) => {
    return (
      <Select
        data-testid="jobs-list-drivers-selector"
        showSearch
        onMouseEnter={() => setSelectedJob(job)}
        onSelect={(id: any) => {
          assignJobDriver(job, id);
        }}
        style={{ width: 200 }}
        defaultValue="Unassigned"
        optionFilterProp="children"
        filterOption={(input: any, option: any) => option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        filterSort={(optionA: any, optionB: any) => {
          return optionA?.children?.toLowerCase().localeCompare(optionB?.children?.toLowerCase());
        }}
        disabled={disabled}
      >
        {availableDrivers.map((e: any) => (
          <Option key={e.id} value={e.id}>
            {e.driver}
          </Option>
        ))}
      </Select>
    );
  };

  const DriverFakeSelector = ({ name, driver, job, disabled }: any) => {
    return (
      <Select
        onSelect={(id: any) => {
          cancelJobDriver(job, id);
        }}
        style={{ width: 200 }}
        defaultValue={name}
        disabled={disabled}
      >
        <Option value={driver.id}>Unassign</Option>
      </Select>
    );
  };

  return (
    <>
      <Table
        data-testid="jobs-managment-main-table"
        columns={columns}
        // expandable={{ expandedRowRender }}
        dataSource={data}
        expandedRowKeys={[expandedRowKeys]}
        onExpand={onExpandRow}
        loading={isFetchingDeliveryJobs}
        rowKey={(record) => record.jobId}
        rowSelection={{
          selectedRowKeys: selectedJobs,
          onChange: (keys, rows) => {
            onJobsSelected(keys);
          },
        }}
      />
    </>
  );
};

export default connect(
  (state: RootStateOrAny, ownProps: IJobListMSTP) => {
    const {
      isAdmin,
      setSelectedOrder,
      handlePublishJob,
      setExpandedRowKeys,
      expandedRowKeys,
      downloadJobOrdersBarcodes,
      withErrors,
      selectedJobs,
      onJobsSelected,
      cleanJob,
    } = ownProps;

    return {
      isAdmin,
      setSelectedOrder,
      handlePublishJob,
      setExpandedRowKeys,
      expandedRowKeys,
      downloadJobOrdersBarcodes,
      withErrors,
      selectedJobs,
      onJobsSelected,
      cleanJob,

      deliveryJobs: isAdmin ? state.deliveryJobs.items : state.retailerJob.items,
      isFetchingDeliveryJobs: isAdmin ? state.deliveryJobs.isFetching : state.retailerJob.isFetching,
      // freeOrders: state.adminOrders?.items[0]?.items,
    };
  },

  (dispatch: any, ownProps: IJobListMSTP) => {
    const {
      fetchDeliveryJobs,
      deleteOrderDeliveryJob,
      getOneDeliveryJob,
      getRoutesDeliveryJob,
      changeOrderPositionDeliveryJob,
      forceNextOrderDeliveryJob,
      returnToShipperDeliveryJob,
      completeOrderDeliveryJob,
      assignJobDeliveryJob,
      cancelAssignJobDeliveryJob,
      fetchAvailableDriversDeliveryJob,
      postponeOrderDeliveryJob,
      cancelOrderDeliveryJob,
    } = bindActionCreators(deliveryJobActions, dispatch);

    const {
      fetchRetailerJobs,
      deleteOrderRetailerJob,
      getOneRetailerJob,
      getRoutesRetailerJob,
      changeOrderPositionRetailerJob,
      forceNextOrderRetailerJob,
      returnToShipperRetailerJob,
      completeOrderRetailerJob,
      assignJobRetailerJob,
      cancelAssignJobRetailerJob,
      fetchAvailableDriversRetailerJob,
      postponeOrderRetailerJob,
      cancelOrderRetailerJob,
    } = bindActionCreators(retailerJobActions, dispatch);

    return ownProps.isAdmin
      ? {
          // Admin Job
          fetchDeliveryJobs,
          deleteOrderDeliveryJob,
          getOneDeliveryJob,
          getRoutesDeliveryJob,
          changeOrderPositionDeliveryJob,
          forceNextOrderDeliveryJob,
          returnToShipperDeliveryJob,
          completeOrderDeliveryJob,
          assignJobDeliveryJob,
          cancelAssignJobDeliveryJob,
          fetchAvailableDriversDeliveryJob,
          postponeOrderDeliveryJob,
          cancelOrderDeliveryJob,

          // Retailer Jobs
        }
      : {
          fetchDeliveryJobs: fetchRetailerJobs,
          deleteOrderDeliveryJob: deleteOrderRetailerJob,
          getOneDeliveryJob: getOneRetailerJob,
          getRoutesDeliveryJob: getRoutesRetailerJob,
          changeOrderPositionDeliveryJob: changeOrderPositionRetailerJob,
          forceNextOrderDeliveryJob: forceNextOrderRetailerJob,
          returnToShipperDeliveryJob: returnToShipperRetailerJob,
          completeOrderDeliveryJob: completeOrderRetailerJob,
          assignJobDeliveryJob: assignJobRetailerJob,
          cancelAssignJobDeliveryJob: cancelAssignJobRetailerJob,
          fetchAvailableDriversDeliveryJob: fetchAvailableDriversRetailerJob,
          postponeOrderDeliveryJob: postponeOrderRetailerJob,
          cancelOrderDeliveryJob: cancelOrderRetailerJob,
        };
    // ({
    //   ...bindActionCreators(deliveryJobActions, dispatch),
    //   ...bindActionCreators(retailerJobActions, dispatch),
    // })
  },
)(JobsList);
