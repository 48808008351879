import React, { FC, useEffect, useState } from "react";
import { DesktopView } from "./DesktopView";
import { useJobHandlers } from "./hooks/useJobHandlers";
import { IJobManagement } from "../../types/JobManagement";

import "./jobsManagement.less";
import MobileView from "./MobileView";
import { Link,useHistory } from "react-router-dom";
import { Modal, Button } from "antd";
import { WhatsAppOutlined, LogoutOutlined } from "@ant-design/icons";
const JobsManagement: FC<IJobManagement> = ({
  isMobile,
  isAdmin,
  // actions
  fetchDeliveryJobs,
  getOneDeliveryJob,
  getRoutesDeliveryJob,
  updateCashDeliveryJob,
  togglePublishDeliveryJob,
  deleteIncompleteDeliveryJob,
  getBadDeliveryJob,
}) => {
  const {
    selectedOrder,
    centerCoordinates,
    setSelectedOrder,
    handlePublishJob,
    downloadJobOrdersBarcodes,
    updateOrderCash,
    withErrors,
    setWithErrors,
    badDeliveryJobCount,
    cleanAllJobs,
    cleanJob,
    printAllJobs,
    selectedJobs,
    onJobsSelected,
  } = useJobHandlers({
    getBadDeliveryJob,
    fetchDeliveryJobs,
    togglePublishDeliveryJob,
    updateCashDeliveryJob,
    deleteIncompleteDeliveryJob,
    isAdmin,
  });

  
  const [isAccountDisabledModalVisible, setAccountDisabledModalVisible] = useState(false);
  const history = useHistory();

  const isAdminActive = localStorage.getItem("isCompanyActive");

  useEffect(() => {
    if (!isAdminActive) {
      setAccountDisabledModalVisible(true);
    } else {
      setAccountDisabledModalVisible(false);
    }
  }, [isAdminActive]);

  const handleLogout = () => {
    localStorage.clear();

    history.push("/admin_login");
  };

  const handleWhatsAppContact = () => {
    window.location.href = "https://wa.me/+962779985433";
  };

  console.log(isAdminActive);

  return (
    <>
          <Modal title="Your Account Is Disabled" visible={isAccountDisabledModalVisible} closable={false} footer={null}>
        <p>
          You have an invoice that must be paid in order to be able to use Active your account. Please contact: +962
          779985433.
        </p>
        <Button type="primary" icon={<WhatsAppOutlined />} onClick={handleWhatsAppContact}>
          Contact via WhatsApp
        </Button>
        <Button icon={<LogoutOutlined />} onClick={handleLogout} style={{ marginLeft: 10 }}>
          Logout
        </Button>
      </Modal>
      {isMobile ? (
        <MobileView
          badDeliveryJobCount={badDeliveryJobCount}
          withErrors={withErrors}
          setWithErrors={setWithErrors}
          centerCoordinates={centerCoordinates}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          fetchDeliveryJobs={fetchDeliveryJobs}
          getOneDeliveryJob={getOneDeliveryJob}
          getRoutesDeliveryJob={getRoutesDeliveryJob}
          handlePublishJob={handlePublishJob}
          downloadJobOrdersBarcodes={downloadJobOrdersBarcodes}
          updateOrderCash={updateOrderCash}
          cleanAllJobs={cleanAllJobs}
          printAllJobs={printAllJobs}
          selectedJobs={selectedJobs}
          onJobsSelected={onJobsSelected}
          cleanJob={cleanJob}
          isMobile={isMobile}
        />
      ) : (
        <DesktopView
          isAdmin={isAdmin}
          badDeliveryJobCount={badDeliveryJobCount}
          withErrors={withErrors}
          setWithErrors={setWithErrors}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          centerCoordinates={centerCoordinates}
          fetchDeliveryJobs={fetchDeliveryJobs}
          getOneDeliveryJob={getOneDeliveryJob}
          getRoutesDeliveryJob={getRoutesDeliveryJob}
          handlePublishJob={handlePublishJob}
          downloadJobOrdersBarcodes={downloadJobOrdersBarcodes}
          updateOrderCash={updateOrderCash}
          cleanAllJobs={cleanAllJobs}
          cleanJob={cleanJob}
          printAllJobs={printAllJobs}
          selectedJobs={selectedJobs}
          onJobsSelected={onJobsSelected}
        />
      )}
    </>
  );
};

export default JobsManagement;
