import React, { useEffect, useState } from "react";
import { notification } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions as reportsActions } from "../../redux/resources/reports";
import ReportsPage from "./ReportsPage";
import { API_URL } from "../../constants/config";
import { getAuthData, getUserTenant } from "../../helpers/authStorage";
import { customFetch } from "../../utils/customFetch";

const handleDownload = async (id) => {
  try {
    const response = await customFetch(`${API_URL}/admin/reports/${id}`, {
      method: "GET",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${getAuthData().accessToken}`,
        Tenant: getUserTenant(),

      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });

    if (!response.ok) {
      const data = await response.json();
      return notification.error({
        message: "Error",
        description: data?.message || "Error wile generating report",
      });
    }
    const blob = await response.blob();
    const newBlob = new Blob([blob]);

    const blobUrl = window.URL.createObjectURL(newBlob);

    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", `report.zip`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

    window.URL.revokeObjectURL(blob);
  } catch (error) {
    notification.error({
      message: "Error",
      description: error?.message || "Error while generating report",
    });
  }
};

const Reports = ({ fetchReports, isMobile }) => {
  const [reports, setReports] = useState([]);
  const [pagination, setPagination] = useState({
    daily: { pageSize: 8, current: 1 },
    weekly: { pageSize: 8, current: 1 },
    monthly: { pageSize: 8, current: 1 },
  });

  useEffect(() => {
    handleReportRequest();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    //eslint-disable-next-line
  }, [pagination]);
  useEffect(() => {
    handleReportRequest()
    //eslint-disable-next-line
  }, [pagination.daily.current,pagination.weekly.current,pagination.monthly.current]);

  function handleReportRequest(value) {
    const newReports = [];
    fetchReports(
      {},
      {
        query: value
          ? {
              day: value.date(),
              month: value.month() + 1,
              year: value.year(),
              type: "daily",
              page: pagination?.daily?.current,
              limit: 8,
            }
          : {
              type: "daily",
              limit: 8,
              page: pagination?.daily?.current,
            },
      },
    )
      .then((res) => {
        newReports.push(res.body.items);
        // setReports(res.body);
        setPagination((old) => ({
          ...old,
          daily: { current: res.body?.meta?.currentPage, pageSize: 8, total: res.body?.meta?.totalItems },
        }));
        return fetchReports(
          {},
          {
            query: value
              ? {
                  type: "weekly",
                  week: value.week(),
                  month: value.month() + 1,
                  year: value.year(),
                  page: pagination?.weekly?.current,
                  limit: 8,
                }
              : {
                  type: "weekly",
                  limit: 8,
                  page: pagination?.weekly?.current,
                },
          },
        );
      })
      .then((res) => {
        newReports.push(res.body.items);
        // setReports(res.body);
        setPagination((old) => ({
          ...old,
          weekly: { current: res.body?.meta?.currentPage, pageSize: 8, total: res.body?.meta?.totalItems },
        }));
        return fetchReports(
          {},
          {
            query: value
              ? {
                  type: "monthly",
                  month: value.month() + 1,
                  year: value.year(),
                  page: pagination?.monthly?.current,
                  limit: 8,
                }
              : {
                  type: "monthly",
                  limit: 8,
                  page: pagination?.monthly?.current,
                },
          },
        );
      })
      .then((res) => {
        newReports.push(res.body.items);
        // setReports(res.body);
        setPagination((old) => ({
          ...old,
          monthly: { current: res.body?.meta?.currentPage, pageSize: 8, total: res.body?.meta?.totalItems },
        }));
        setReports(newReports);
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: error?.message || "Error while fetching this date reports",
        });
      });
  }

  return (
    <>
      <ReportsPage
        isMobile={isMobile}
        setPagination={setPagination}
        pagination={pagination}
        reports={reports}
        handleDownload={handleDownload}
        handleReportRequest={handleReportRequest}
      />
    </>
  );
};

const MSTP = (state) => {
  return {
    isMobile: state.admin.isMobile,
  };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(reportsActions, dispatch),
}))(Reports);
