export default (orderInfo) => [
  {
    label: "City",
    value: orderInfo.customerInfo?.initialAddress?.city,
  },
  {
    label: "Zone",
    value: orderInfo.customerInfo?.initialAddress?.zone,
  },
  {
    label: "Street",
    value: orderInfo.customerInfo?.initialAddress?.street,
  },
  {
    label: "Customer adress",
    value: orderInfo.customerInfo?.initialAddress?.address,
  },
  {
    label: "Building",
    value: orderInfo.customerInfo?.initialAddress?.building,
  },
];
