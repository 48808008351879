import React from "react";
import { BarcodeOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
function DownloadIcon({ pdfLink }) {
  return pdfLink ? (
    <Tooltip title="Download the barcode">
      <a href={pdfLink} target="_blank" rel="noopener noreferrer">
        <BarcodeOutlined />
      </a>
    </Tooltip>
  ) : null;
}

export default DownloadIcon;
