import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUserTenant, setUserTenant, TENANT_STORAGE_KEY } from "../../../helpers/authStorage";
import { actions as adminTenantsActions } from "../../../redux/resources/adminTenants";
import { actions as tenantsActions } from "../../../redux/resources/tenants";
import { formatCountry } from "src/helpers/formatCountry";
import ReactCountryFlag from "react-country-flag";

const { Option } = Select;

const TenantSelector = ({ isAdmin, isMobile, onChange, defaultTenant, isCustom = false }) => {
  const { getOwnAdminTenants } = adminTenantsActions;
  const { getTenants } = tenantsActions;
  const dispatch = useDispatch();

  const [selectedTenant, setSelectedTenant] = useState(defaultTenant ?? localStorage.getItem(TENANT_STORAGE_KEY));
  const [tenants, setTenants] = useState([]);
  const onTenantChange = (value) => {
    setSelectedTenant(value);
    if (isAdmin) {
      setUserTenant(value);
      window.location.reload();
    }

    if (onChange) {
      onChange(value);
    }
  };

  const fetchTenants = () => {
    if (isAdmin) {
      dispatch(getOwnAdminTenants()).then(({ body }) => {
        if (!body.includes(getUserTenant())) {
          onTenantChange(body[0]);
        }
        setTenants(body);
      });
    } else {
      dispatch(getTenants()).then(({ body }) => {
        if (!selectedTenant && body?.[0]) onTenantChange(body[0]);
        setTenants(body);
      });
    }
  };

  useEffect(() => {
    fetchTenants();
    // eslint-disable-next-line
  }, []);
  const selectStyle = isMobile
    ? {
        width: "100%",
        fontWeight: 700,
        textAlign: "center",
      }
    : isCustom
    ? {}
    : {
        width: "100%",
        marginTop: -10,
        fontWeight: 700,
      };

  return (
    <Select
      style={selectStyle}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      bordered={isMobile}
      value={selectedTenant}
      onChange={onTenantChange}
      dropdownMatchSelectWidth={false}
    >
      {tenants.map((item) => (
        <Option value={item} key={item}>
          <>
            <ReactCountryFlag
              svg
              countryCode={formatCountry(item, true)}
              style={{
                width: "2em",
                height: "2em",
                marginRight: 5,
              }}
            />
            {!isMobile && formatCountry(item, isMobile)}
          </>
        </Option>
      ))}
    </Select>
  );
};

export default TenantSelector;
