import React from "react";
import { DatePicker, Divider, Input, Table, Select, Rate } from "antd";

import CustomDivider from "../../../components/Divider";
import OrderDetails from "./components/OrderDetails";

const { RangePicker } = DatePicker;
const { Option } = Select;

export const DesktopView = ({
  data,
  dates,
  columns,
  searchFilter,
  isFetchingStatistics,
  selectedOrder,
  selectedFilter,
  expandedRowRender,
  driversStatisticsPagination,
  disabledTomorrowAndFuture,
  centerCoordinates,
  onChangePagination,
  changeFilter,
  onRatingChange,
  setInputValue,
  handleSetDates,
  handleCloseDrawer,
}) => {
  const searchPlaceholder = {
    driverName: "Driver name ...",
    trackingId: "Tracking id ...",
  };

  return (
    <div className="driversStatisticsWrapper">
      <div>
        <h2 className="pageTitle">Driver statistics list</h2>
        <CustomDivider />
      </div>
      <Divider />
      <div className="filtersWrapper">
        <div className="searchWrapper">
          <Select value={selectedFilter} onChange={changeFilter}>
            {searchFilter.map(({ text, value }) => (
              <Option value={value} key={value}>
                {text}
              </Option>
            ))}
          </Select>
          {selectedFilter === "orderRating" || selectedFilter === "driverRating" ? (
            <Rate
              onChange={(e) => {
                onRatingChange(e, selectedFilter);
                onChangePagination({ current: 1, pageSize: driversStatisticsPagination.pageSize });
              }}
            />
          ) : (
            <Input
              placeholder={searchPlaceholder[selectedFilter]}
              onChange={(e) => {
                e.persist();
                setInputValue(e);
                onChangePagination({ current: 1, pageSize: driversStatisticsPagination.pageSize });
              }}
            />
          )}
        </div>
        <div>
          <RangePicker value={dates} onChange={handleSetDates} disabledDate={disabledTomorrowAndFuture} />
        </div>
      </div>
      <Divider />
      <Table
        columns={columns}
        className="driversStatisticsTableWrapper"
        dataSource={data}
        loading={isFetchingStatistics}
        expandable={{ expandedRowRender }}
        pagination={driversStatisticsPagination}
        onChange={onChangePagination}
        rowKey={(record) => record.jobId}
      />
      <OrderDetails selectedOrder={selectedOrder} centerCoordinates={centerCoordinates} onClose={handleCloseDrawer} />
    </div>
  );
};
