import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Manager } from "socket.io-client";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";

import { notification } from "antd";
import { getAuthData, getUserTenant } from "../../helpers/authStorage";
import { API_URL, isLocalhost, isStage } from "../../constants/config";
import { actions as notificationsActions } from "../../redux/resources/notifications";
import { MAPPED_NOTIFICATIONS } from "./constants";

import { registerPushServiceWorker } from "../../registerPushServiceWorker";
import errorLogger from "../../utils/errorLogging/error-logger";
const isLocal = isLocalhost || isStage;

export const useNotifications = () => {
  const socketRef = useRef();
  const token = encodeURIComponent(getAuthData().accessToken);

  const dispatch = useDispatch();

  const {
    setNotifications,
    //  fetchNotifications, fetchSMSNotifications, fetchRetailerNotifications
  } = notificationsActions;

  // const fetchAllNotifications = () => {
  //   dispatch(fetchNotifications());
  //   dispatch(fetchSMSNotifications());
  //   dispatch(fetchRetailerNotifications());
  // };
  const onConnect = () => {
    console.log("connected");
  };

  const onNotifications = (other) => {
    const newNotifications = other.reduce((acc, { data: { count }, event }) => {
      acc[MAPPED_NOTIFICATIONS[event]] = Number(count);
      return acc;
    }, {});
    dispatch(setNotifications(newNotifications));
  };

  const onException = (data) => {
    notification.error({
      message: "Error",
      description: "Error with websockets",
    });
  };

  const onDisconnect = () => {
    console.log("Disconnected");
  };

  useEffect(() => {
    // fetchAllNotifications();

    const manager = new Manager(`${API_URL}/`, {
      reconnectionDelayMax: 10000,
      transports: ["websocket"],
      path: "/socket.io",
      query: {
        token,
        tenant: getUserTenant(),
      },
    });
    socketRef.current = manager.socket("/admin-notifications");

    socketRef.current.on("connect", onConnect);
    socketRef.current.on("exception", onException);
    socketRef.current.on("disconnect", onDisconnect);
    socketRef.current.on("notifications", onNotifications);

    return () => {
      socketRef.current.off("connect", onConnect);
      socketRef.current.off("exception", onException);
      socketRef.current.off("disconnect", onDisconnect);
      socketRef.current.off("notifications", onNotifications);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const firebaseConfig = isLocal
  ? {
    apiKey: "AIzaSyAG3rY29rd4-ySnHiruGEO8QC_AwgqW8fk",
    authDomain: "beebolt-staging.firebaseapp.com",
    projectId: "beebolt-staging",
    storageBucket: "beebolt-staging.appspot.com",
    messagingSenderId: "76644883938",
    appId: "1:76644883938:web:dab7e0a21be8aefde8edbb",
    measurementId: "G-NGL1H0M4VJ",
  }
  : {
    apiKey: "AIzaSyAAvjFzLEQ2IAiK1kJmzK5HEu78Bbr7meE",
    authDomain: "beebolt-280114.firebaseapp.com",
    databaseURL: "https://beebolt-280114.firebaseio.com",
    projectId: "beebolt-280114",
    storageBucket: "beebolt-280114.appspot.com",
    messagingSenderId: "27207299621",
    appId: "1:27207299621:web:a2015870dd7186a34b85cc",
    measurementId: "G-W1SJG8JRTT",
  };

export const useMessages = ({ subscribeToPushAdmin, unsubscribeFromPushAdmin }) => {
  const [token, setToken] = useState();
  const bbIcon = "/mstile-144x144.png";

  useEffect(() => {
    async function handleMessages() {
      registerPushServiceWorker();
      const app = initializeApp(firebaseConfig);
      const isSupportedBrowser = await isSupported();
      if (isSupportedBrowser) {
        const messaging = getMessaging(app);

        onMessage(messaging, (payload) => {
          notification.open({
            message: payload?.notification?.title || "Notification",
            description: payload?.notification?.body || " ",
            icon: <img style={{ maxWidth: "30px" }} alt="icon" src={payload?.notification?.image || bbIcon} />,
          });
        });

        try {
          const messagingToken = await getToken(messaging, {
            vapidKey: isLocal
              ? "BCdtjs1WWI_vqTCbEY_M78qiZAL4m3BPPvmd0PrGvJUobUbYZqBjcbyvDQ6ttB_rxNIvhoL3cpv_FPfa7uqjxK8"
              : "BBbgsmE1no7lQ5B36d-6jaQnVwfTCJz5bZ7nDcg1zik54YLPucAJ5_EOrjJ0fXFhVi6t1fDMLCUEqB8qoX-0i9Y",
          });
          setToken(messagingToken);
          subscribeToPushAdmin({ token: messagingToken, tenant: getUserTenant() }).catch((e) => {
            unsubscribeFromPushAdmin({ token: messagingToken, tenant: getUserTenant() }).then(
              () => {
                subscribeToPushAdmin({ token: messagingToken, tenant: getUserTenant() }).catch((e) => {
                  //errorLogger.report(`critical, notifications not activated after reset \n ${e}`);
                });
              },
              () => {
                //errorLogger.report(`critical, cannot reset notifications \n ${e}`);
              },
            );
          });
        } catch (e) {
          errorLogger.report(`unknown error \n ${e}`);
        }
      }
    }
    handleMessages();
    // eslint-disable-next-line
  }, []);

  return { token };
};

export default useNotifications;
