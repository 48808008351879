import React from "react";
import { Table, Popconfirm, Select, Input, Divider, DatePicker, Radio } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { getStatusBage } from "../../../helpers/general";
import CustomDivider from "../../../components/Divider";
import DeliveryReportsDetails from "./components/DeliveryReports/ReportDetails";
import { isMainServer } from "../../../constants/config";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const DesktopView = ({
  isDeliveryReportFetching,
  reports,
  searchedValue,
  searchFilter,
  paginationConfig,
  disabledTomorrowAndFuture,
  setSearchedValue,
  onChangeSearchFilter,
  onChangePagination,
  handleSearchDelivery,
  handleSetDates,
  deleteReport,
  reportStatusQuery,
  setReportStatusQuery,
  reportTypeQuery,
  setReportTypeQuery,
}) => {
  const mainServerColums = [
    {
      title: "Delete",
      key: "key",
      render: (data) => {
        return (
          <Popconfirm
            title="Are you sure delete this report?"
            onConfirm={deleteReport(data.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ color: "red", textAlign: "center" }} />
          </Popconfirm>
        );
      },
    },
  ];

  const columns = [
    ["pickup_report", "all"].includes(reportTypeQuery)
      ? {
          title: "Order ID",
          key: "orderId",
          render: ({ orderId }) => {
            if (!orderId) {
              return "No order";
            }
            return orderId;
          },
        }
      : {
          title: "Report message",
          key: "message",
          render: ({ message }) => {
            if (!message || !message.trim()) {
              return "No message";
            }

            if (message?.length > 50) {
              return `${message.slice(0, 50)}...`;
            }
            return message;
          },
        },

    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => getStatusBage(status),
    },
    {
      title: "Created at",
      key: "createdAt",
      render: ({ createdAt }) => {
        const milisec = new Date(createdAt);
        return milisec.toLocaleDateString();
      },
    },
    {
      title: "Driver",
      key: "driver",
      render: (data) => {
        const driverFullName =
          data.driver?.profile && `${data.driver.profile?.firstName} ${data.driver.profile?.lastName}`;
        return driverFullName;
      },
    },
    ...(isMainServer ? mainServerColums : []),
  ];

  const selectSearchFilter = (
    <Select defaultValue="driverName" className="select-before" onChange={onChangeSearchFilter}>
      {searchFilter.map(({ value, text }) => (
        <Option value={value} key={value}>
          {text}
        </Option>
      ))}
    </Select>
  );

  const formatReports = (reports) => {
    if (reports?.length) {
      return reports.map((e) => {
        return { ...e, orderId: e?.order?.id };
      });
    } else return [];
  };

  return (
    <div className="pageWrapper">
      <div>
        <h2 className="pageTitle">Delivery Reports</h2>
        <CustomDivider />
      </div>
      <Divider />
      <h3>Order type:</h3>
      <Radio.Group value={reportTypeQuery} onChange={(e) => setReportTypeQuery(e.target.value)}>
        {/* <Radio.Button value="all"> All</Radio.Button> */}
        <Radio.Button value="delivery_report"> Delivery report</Radio.Button>
        {/* <Radio.Button value="driver_report"> Pick up report</Radio.Button> */}
        {/* <Radio.Button value="pickup_delivery_report"> Pick up report</Radio.Button> */}
        <Radio.Button value="pickup_report"> Unable to pick up</Radio.Button>
      </Radio.Group>
      <Divider />
      <h3>Order status:</h3>
      <Radio.Group value={reportStatusQuery} onChange={(e) => setReportStatusQuery(e.target.value)}>
        <Radio.Button value="all"> All</Radio.Button>
        <Radio.Button value="new"> New</Radio.Button>
        <Radio.Button value="processing"> Pending</Radio.Button>
        <Radio.Button value="resolved"> Resolved</Radio.Button>
      </Radio.Group>
      <Divider />
      <div className="filtersWrapper">
        <div className="searchWrapper">
          <Input
            value={searchedValue}
            onChange={(e) => setSearchedValue(e.target.value)}
            placeholder="Search"
            addonBefore={selectSearchFilter}
            onPressEnter={handleSearchDelivery}
            className="searchReports"
          />
          <div className="datePickerWrapper">
            <RangePicker format="YYYY-MM-DD" onChange={handleSetDates} disabledDate={disabledTomorrowAndFuture} />
          </div>
        </div>
      </div>
      <Divider />

      {!!reports?.length && (
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={formatReports(reports)}
          loading={isDeliveryReportFetching}
          onChange={onChangePagination}
          expandable={{
            expandedRowRender: (record) => <DeliveryReportsDetails data={record} />,
          }}
          pagination={paginationConfig}
        />
      )}
    </div>
  );
};
