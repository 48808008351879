import { InfoCircleOutlined, CheckCircleOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import React from "react";

export const getTimeLineColor = (status: string) => {
  switch (status) {
    case "info":
      return "gray";
    case "new":
      return "green";
    case "deleted":
    case "canceled":
      return "red";
    default:
      return "gray";
  }
};
export const getDegreeIcon = (degree: string) => {
  switch (degree) {
    case "new":
      return <InfoCircleOutlined className="changes_wrapper--important" />;
    case "completed":
      return <CheckCircleOutlined className="changes_wrapper--important" />;
    case "deleted":
    case "canceled":
      return <WarningOutlined className="changes_wrapper--warn" />;
    default:
      return <QuestionCircleOutlined className="changes_wrapper--info" />;
  }
};

export const displayChanges: (status: string) => JSX.Element | String = (status: string) => {
  switch (status) {
    case "new":
      return "New order has been uploaded";
    case "completed":
      return "Order has been delivered successfully";
    case "deleted":
    case "canceled":
      return `Order has been ${status}`;
    default:
      return `Order  ${status.split("_").join(" ")}`;
  }
};
