import React from "react";
import { Row, Col } from "antd";

import { ChartPie } from "../ChartPie";

function StatsTable({ generalStats, ordersStats, chartData }) {
  return (
    <section>
      <Row>
        {generalStats?.map((stat) => (
          <Col span={6}>
            <div className="generalStats">
              <p>{stat?.title}</p>
              <div>{stat?.value}</div>
            </div>
          </Col>
        ))}
      </Row>
      <div className="orderStatsWrapper">
        <div className="orderStatsSection">
          <p>Orders statuses graph</p>
          <ChartPie chartData={chartData} />
        </div>
        <div className="orderStatsSection">
          <div className="orderStatsTable">
            {ordersStats?.map((stat) => (
              <div className="orderStatsCol">
                {stat?.title}: {stat?.value}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default StatsTable;
