import { notification } from "antd";
import { ICreateTicketPayload } from "./types";

export const useCreatetTicket = (
  createTicket: any,
  updateOrderNewTicketState: any,
) => {
  const onOrderNewTicketState = async (ticket: Record<any, any>, orderId: number, jobId: number | null) => {
    try {
      await updateOrderNewTicketState({ ticket, orderId, jobId });
    }
    catch (error: any) {
      notification.error({
        message: 'Error',
        description: error?.body?.message || 'OOPS! Unable to create ticket state'
      });
    }
  }


  const onCreateTicket = async (payload: ICreateTicketPayload) => {
    try {
      const { body } = await createTicket(payload);
      onOrderNewTicketState(body, payload.orderId, payload.jobId);
      notification.success({
        message: 'Success',
        description: 'Ticket created successfully.'
      });
    }
    catch (error: any) {
      notification.error({
        message: 'Error',
        description: error?.body?.message || 'OOPS! Unable to create ticket'
      });
    }
  }

  return {
    onCreateTicket
  }
}