import React, { useState } from "react";
import { Button, Modal, Radio, Input, Space, Row } from "antd";

const ChangeDriverStatus = ({ driver, onChangeStatus }) => {
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState(driver.status);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [issueText, setIssueText] = useState("");

  const toggleModal = () => {
    setVisible(!visible);
  };

  const handleChangeIssue = (e) => {
    setIssueText(e.target.value);
  };

  const onOk = async () => {
    try {
      const query = {
        status,
        id: driver.id,
        ...(status === "has_issue" && { issueText }),
      };
      await setConfirmLoading(true);
      await onChangeStatus(query);
      setConfirmLoading(false);
      setVisible(false);
    } catch (e) {
      setConfirmLoading(false);
      setVisible(false);
    }
  };

  const onRadioCheck = (e) => {
    setStatus(e.target.value);
  };

  return (
    <div>
      <Button type="primary" onClick={toggleModal}>
        Change status
      </Button>
      <Modal title="Driver status" confirmLoading={confirmLoading} visible={visible} onOk={onOk} onCancel={toggleModal}>
        <Space direction="vertical" size="small">
          <Radio.Group onChange={onRadioCheck} value={status}>
            <Radio value="pending">Pending</Radio>
            <Radio value="accepted">Accepted</Radio>
            <Radio value="declined">Declined</Radio>
            <Radio value="has_issue">Has issue</Radio>
          </Radio.Group>
          {status === "has_issue" && (
            <Row>
              <Input.TextArea onChange={handleChangeIssue} value={issueText} placeholder="Issue text" />
            </Row>
          )}
        </Space>
      </Modal>
    </div>
  );
};

export default ChangeDriverStatus;
