import React from "react";
import { Dropdown, Button, Menu, Row, Spin, Checkbox} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { addLeadingZeros } from "src/helpers/formatUserData";
import {useTenantSelect} from "../hooks/useTenantSelect";
import { TenantsSelectProps } from "../types";

const TenantsSelect : React.FC<TenantsSelectProps> = ({ defaultTenants, id, accessType }) => {
    const {
        tenants,
        onPressCheckBox,
        isFetching
      } = useTenantSelect(id, accessType);

        const menu = (
            <Menu className="tenants-select-style">
                {
                    isFetching ? 
                    (
                        <Row className="tenants-spin-container-style" justify="center" align="middle">
                            <Spin />
                        </Row> 
                    )
                    :
                    (   
                        defaultTenants.map((tenant:any) => {
                            return (
                                <Menu.Item key={tenant}>
                                    <Checkbox onClick={(event) => {
                                        onPressCheckBox(event, tenant);
                                    }} checked={!!tenants.find((elem:any) => elem === tenant)} >{tenant}</Checkbox>
                                </Menu.Item>
                            )
                        })
                    )
                }
            </Menu>
        );

    return (
        <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
            <Button className="no-border-style">
                {addLeadingZeros(Math.min(tenants?.length, defaultTenants.length), 2)}<DownOutlined />
            </Button>
        </Dropdown>
    );
}

export default TenantsSelect;
