import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import GenericDashboardBox from "../Assets/Components/GenericDashboardBox";
import ChartDashboardBox from "../Assets/Components/ChartDashboardBox";
import SpinModal from "../Assets/Components/SpinModal";
import "./style.less";

function disabledDate(current) {
  return current > moment().endOf("day");
}

export default function DesktopView({ isDashboardDataFetching, dashboardStats, date, setDate }) {
  return (
    <div className="pageWrapper">
      <div className="stats-header">
        <h2 className="pageTitle">Dashboard</h2>

        <DatePicker
          format={"YYYY-MM-DD"}
          className="DashboardDatePicker"
          defaultValue={date}
          value={date}
          disabledDate={disabledDate}
          onChange={(date) => {
            setDate(date);
          }}
          allowClear={false}
        />
      </div>

      {isDashboardDataFetching && <SpinModal>Loading Dashboard Data</SpinModal>}

      <div className="stats-row-1">
        <GenericDashboardBox color="rgba(252, 220, 62, 0.2)">
          <div className="stats-wrapper">
            <b className="stats-label">Today's Orders</b>
            <p className="stats-count">{dashboardStats?.totalOrders}</p>
          </div>
        </GenericDashboardBox>

        <GenericDashboardBox color="rgba(252, 220, 62, 0.2)">
          <div className="stats-wrapper">
            <b className="stats-label">New Orders</b>
            <p className="stats-count">{dashboardStats?.totalNewOrders}</p>
          </div>
        </GenericDashboardBox>

        <GenericDashboardBox color="rgba(39, 174, 96, 0.2)">
          <div className="stats-wrapper">
            <b className="stats-label">Delivered Orders </b>
            <p className="stats-count">{dashboardStats?.totalDelivered}</p>
          </div>
        </GenericDashboardBox>

        <GenericDashboardBox color="rgba(227, 75, 75, 0.2)">
          <div className="stats-wrapper">
            <b className="stats-label">Canceled Orders </b>
            <p className="stats-count"> {dashboardStats?.totalCanceled}</p>
          </div>
        </GenericDashboardBox>

        <GenericDashboardBox color="rgba(131, 131, 131, 0.2)">
          <div className="stats-wrapper">
            <b className="stats-label">Returned Orders</b>
            <p className="stats-count">{dashboardStats?.totalReturned}</p>
          </div>
        </GenericDashboardBox>

        <GenericDashboardBox color="rgba(131, 131, 131, 0.2)">
          <div className="stats-wrapper">
            <b className="stats-label">Postponed Orders </b>
            <p className="stats-count">{dashboardStats?.totalPostponed}</p>
          </div>
        </GenericDashboardBox>

        <GenericDashboardBox color="rgba(39, 174, 96, 0.2)">
          <div className="stats-wrapper">
            <b className="stats-label">Cash Collected </b>
            <p className="stats-count">
              {dashboardStats?.cashOnDelivery} / {dashboardStats?.totalCashOnDelivery}
            </p>
          </div>
        </GenericDashboardBox>
        <GenericDashboardBox color="rgba(39, 174, 96, 0.2)">
          <div className="stats-wrapper">
            <b className="stats-label">Total Active Drivers</b>
            <p className="stats-count">{dashboardStats?.totalActiveDrivers}</p>
          </div>
        </GenericDashboardBox>
      </div>
      <div className="stats-row-2">
        <ChartDashboardBox dashboardStats={dashboardStats}></ChartDashboardBox>
      </div>
    </div>
  );
}
