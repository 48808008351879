import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Modal, Form, Spin, notification } from "antd";
import GoogleMapReact from "google-map-react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Geocode from "react-geocode";

// import LocationInputForm from "./LocationInputForm";
import { API_KEY } from "../../../constants/config";
import Customer from "../../markers/Customer";
import LocationInputForm from "../../OrdersWidget/UploadedOrdersTable/components/LocationInputForm";
import errorLogger from "../../../utils/errorLogging/error-logger";

function SimpleLocationModal({
  location = { lat: 0, long: 0 },
  isLocationModalOpen,
  setIsLocationModalOpen,
  submitLocation,
}) {
  const [customLocation, setCustomLocation] = useState(location);
  const [geolocationResponse, setGeolocationResponse] = useState({ address: null, isError: false });
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const intl = useIntl();
  useEffect(() => {
    if (isLocationModalOpen) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            notification.success({
              key: "key1",
              message: "Location shared",
              description: "Please click here to set set your location",
              duration: 0,
              onClick: () => {
                notification.close("key1");
                getMapData({ lat: position.coords.latitude, long: position.coords.longitude }, "coords").then(
                  (res) => {
                    setCustomLocation((old) => ({
                      ...old,
                      lat: parseFloat(res.lat),
                      long: parseFloat(res.long),
                    }));
                    setGeolocationResponse({ address: res.address, isError: false });
                  },
                  (err) => {
                    errorLogger.report(`Map Error ${err}`);
                  },
                );
              },
            });
          },
          () => {
            notification.open({
              message: "Location services are turned off.",
              description: "Please turn on location services if you want to use your location.",
            });
          },
        );
      } else {
        notification.open({
          message: "Geolocation is not supported",
          description: "Geolocation is not supported by this browser",
        });
      }
      return () => {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocationModalOpen]);

  useEffect(() => {
    form.resetFields();
    setGeolocationResponse({ address: null, isError: false });
  }, [isLocationModalOpen, form]);

  useEffect(() => {
    if (isLocationModalOpen) setCustomLocation(location);
  }, [isLocationModalOpen, location]);

  const saveNewPosition = async () => {
    const { lat, long } = form.getFieldsValue(true);
    await submitLocation({
      lat: parseFloat(lat),
      long: parseFloat(long),
      address: geolocationResponse.address,
    });
    setIsLocationModalOpen(null);
  };

  const getMapData = (query, type) => {
    switch (type) {
      case "address":
        return geocodeByAddress(query.address).then(
          (result) => {
            return getLatLng(result[0]).then(
              (latLng) => {
                return {
                  address: query.address,
                  lat: latLng.lat,
                  long: latLng.lng,
                  formatted_address: result[0].formatted_address,
                };
              },
              (error) => {
                notification.error({
                  message: "Error",
                  description: "no coords match this address",
                });
                return null;
              },
            );
          },
          (error) => {
            notification.error({
              message: "Error",
              description: "no such address",
            });
            return null;
          },
        );
      case "coords":
        return Geocode.fromLatLng(query.lat, query.long).then(
          (response) => {
            return { address: response.results[0].formatted_address, lat: query.lat, long: query.long };
          },
          (error) => {
            return { address: "unknown place", lat: query.lat, long: query.long };
          },
        );
      default:
        return null;
    }
  };

  const closeModal = () => {
    setIsLocationModalOpen(null);
  };

  return (
    <Modal
      width="70vw"
      visible={isLocationModalOpen}
      title={`${intl.formatMessage({ id: "modal.addLocation", defaultMessage: "Add location" })}`}
      onCancel={closeModal}
      onOk={saveNewPosition}
      footer={[
        <Button key="Cancel" onClick={closeModal}>
          <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!customLocation.lat || !customLocation.long || geolocationResponse.isError}
          onClick={saveNewPosition}
          loading={isLoading}
        >
          <FormattedMessage id="tabPane.actions.save" defaultMessage="Save" />
        </Button>,
      ]}
    >
      <h1>
        <FormattedMessage id="input.input.address" defaultMessage="Address" />
      </h1>
      <div style={{ height: "500px", width: "100%" }}>
        <GoogleMapReact
          options={{ clickableIcons: false, fullscreenControl: false }}
          bootstrapURLKeys={{ key: API_KEY }}
          center={{ lat: Number(customLocation.lat || "0"), lng: Number(customLocation.long || "0") }}
          defaultZoom={10}
          onClick={({ x, y, lat, lng, event }) => {
            getMapData({ lat, long: lng }, "coords").then(
              (res) => {
                setCustomLocation((old) => ({
                  ...old,
                  lat: parseFloat(res.lat),
                  long: parseFloat(res.long),
                }));
                setGeolocationResponse({ address: res.address, isError: false });
              },
              (err) => {
                errorLogger.report(`Map error ${err}`);
              },
            );
          }}
        >
          <Customer lat={customLocation.lat} lng={customLocation.long} />
        </GoogleMapReact>
      </div>
      <div
        style={{
          width: "50%",
          margin: "10px auto 0px auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LocationInputForm
          form={form}
          customLocation={customLocation}
          setCustomLocation={setCustomLocation}
          setIsLoading={setIsLoading}
          setGeolocationResponse={setGeolocationResponse}
          getMapData={getMapData}
        />
      </div>
      <div className="inputsLatLongWrapper">
        {geolocationResponse.isError && (
          <p className="locationModal__error">
            <FormattedMessage
              id="modal.checkLatitude"
              defaultMessage="Check your latitude and longitude probably they are wrong"
            />
          </p>
        )}
        {geolocationResponse.address && !isLoading && (
          <p className="locationModal__address">
            <FormattedMessage id="input.input.address" defaultMessage="Address" />: {geolocationResponse.address}
          </p>
        )}
        {isLoading && (
          <p className="locationModal__loading">
            <Spin size="small" />
          </p>
        )}
      </div>
    </Modal>
  );
}

export default SimpleLocationModal;
