import { AimOutlined, CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Timeline, Tag, Typography } from "antd";
import React, { FC, useState } from "react";
import { connect, RootStateOrAny } from "react-redux";
import { bindActionCreators } from "redux";
import { IOrder } from "../../../types/orderEntity";
import ActionsDropdown from "../OrderActionsDropdown";
import useOrderOperations from "./useOrdersAction";
import { actions as deliveryJobDetailsActions } from "../../../redux/resources/deliveryJobDetails";
import ConfirmOrderActionsModal from "../ConfirmModal";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DragableOrderList from "../DragableOrderList";
import { JobRoute } from "src/types/JobsEntity";
import { PointTypeEnum, AccordionProps, IOrderListProps } from "src/types/JobDetails";
import OrderDetails from "../OrderDetails";

const { Paragraph } = Typography;

const sortedOrderList = (orders: IOrder[]) =>
  orders.sort((a: IOrder, b: IOrder) => (a.pointOrder > b.pointOrder ? 1 : b.pointOrder > a.pointOrder ? -1 : 0));

export const sortPointsByRoute = (jobRoutes: JobRoute[], orders: IOrder[]) => {
  const pickupPoints: JobRoute[] = [];
  const deliveryPoints: IOrder[] = [];

  jobRoutes.forEach((route) => {
    if (route.pointType === PointTypeEnum.PICKUP) {
      pickupPoints.push(route);
    } else {
      const filteredOrder = orders.find((o) => o.id === route.orderId);
      if (filteredOrder) {
        deliveryPoints.push({ ...filteredOrder, pointOrder: route.pointOrder });
      }
    }
  });

  return {
    pickupPoints,
    deliveryPoints: sortedOrderList(deliveryPoints),
  };
};

export const Accordion: FC<AccordionProps> = ({ title, onToggle, isActive, key, children }) => {
  return (
    <div className="accordion-wrapper">
      <div className="accordion-title-wrapper" onClick={() => onToggle(key)}>
        {title}
      </div>
      <div className={`accordion-panel ${isActive && "active"}`}>{children}</div>
    </div>
  );
};

const OrdersList: FC<IOrderListProps> = ({
  jobDetails,
  onRouteChange,
  fetchDeliveryJobDetails,
  deleteOrderDeliveryJobDetails,
  getRoutesDeliveryJobDetails,
  postponeOrderDeliveryJobDetails,
  forceNextOrderDeliveryJobDetails,
  completeOrderDeliveryJobDetails,
  returnToShipperDeliveryJobDetails,
  cancelOrderDeliveryJobDetails,
  cancelCompletedDeliveryJobDetails,
  changeOrderPositionDeliveryJobDetails,
  updateCashDeliveryJobDetails,
  getPolylineDeliveryJobDetails,
}) => {
  const [activeAcc, setActiveAcc] = useState<string>("");
  const {
    forceNextOrder,
    postponeOrder,
    completeOrder,
    returnToShipper,
    cancelOrder,
    deleteOrder,
    cancelCompletedOrder,
    setConfirmOrdersAction,
    operationLoading,
    confirmOrdersAction,
    fetchJobDetailsById,
    changeOrderPosition,
    onUpdateCashAmount,
  } = useOrderOperations({
    fetchDeliveryJobDetails,
    deleteOrderDeliveryJobDetails,
    getRoutesDeliveryJobDetails,
    postponeOrderDeliveryJobDetails,
    forceNextOrderDeliveryJobDetails,
    completeOrderDeliveryJobDetails,
    returnToShipperDeliveryJobDetails,
    cancelOrderDeliveryJobDetails,
    cancelCompletedDeliveryJobDetails,
    changeOrderPositionDeliveryJobDetails,
    updateCashDeliveryJobDetails,
    getPolylineDeliveryJobDetails,
    onRouteChange,
  });

  const sortedPoints = jobDetails && sortPointsByRoute(jobDetails?.jobRoutes, jobDetails?.orders);

  const onOrderPostionChange = (pointOrder: number[]) => {
    changeOrderPosition(jobDetails.id, pointOrder);
    fetchJobDetailsById(jobDetails.id);
  };

  const toggleAccordion = (key: string) => {
    if (key === activeAcc) {
      setActiveAcc("");
      return;
    }
    setActiveAcc(key);
  };

  // For return order pickupPoint = deliveryPoint
  const computePickupAddress = (point: JobRoute) =>
    point?.pickupPoint !== null ? point.pickupPoint?.address : point?.deliveryPoint?.address;

  // For return order pickupPoint = deliveryPoint
  const computeDeliveryAddress = (order: IOrder) =>
    order?.warehousePoint ? order?.warehousePoint?.address : order?.deliveryPoint?.address;

  const renderOrderAccTitle = (order: IOrder, isActive: boolean) => (
    <div className="acc-title">
      <Paragraph>
        <strong>#{order.id}</strong> - {computeDeliveryAddress(order)}
      </Paragraph>
      <div className="actions-wrapper">
        <Tag>{order.status}</Tag>
        {isActive ? <CaretUpOutlined /> : <CaretDownOutlined />}
      </div>
    </div>
  );

  const renderOrderAccBody = (order: IOrder, isActive: boolean) => (
    <>
      <div className="action-wrapper">
        <ActionsDropdown
          data={{ jobId: jobDetails.id, orderId: order.id, status: order?.status || "" }}
          deleteOrder={deleteOrder}
          returnToShipper={returnToShipper}
          postponeOrder={() =>
            setConfirmOrdersAction({
              open: true,
              id: order.id,
              job: jobDetails.id,
              type: "postpone",
            })
          }
          cancelOrder={cancelOrder}
          completeOrder={completeOrder}
          cancelCompletedOrder={() =>
            setConfirmOrdersAction({
              open: true,
              id: order.id,
              job: jobDetails.id,
              type: "cancelCompleted",
            })
          }
        />
      </div>
      <OrderDetails
        order={order}
        jobId={jobDetails.id}
        forceNextOrder={forceNextOrder}
        onUpdateCashAmount={onUpdateCashAmount}
      />
    </>
  );

  return (
    <div className="order-list-wrapper">
      <Timeline>
        {sortedPoints?.pickupPoints?.length > 0 &&
          sortedPoints.pickupPoints.map((point) => {
            const key = `key-${point.pickupPointId}`;
            return (
              <Timeline.Item dot={<AimOutlined />}>
                <Accordion
                  isActive={false}
                  key={key}
                  onToggle={() => toggleAccordion(key)}
                  title={
                    <>
                      <Paragraph className="acc-sub-title">PICKUP LOCATION</Paragraph>
                      <div className="acc-title">
                        <Paragraph>
                          <strong>#{point?.pickupPointId}</strong> - {computePickupAddress(point)}
                        </Paragraph>
                      </div>
                    </>
                  }
                />
              </Timeline.Item>
            );
          })}
        {sortedPoints?.deliveryPoints?.length > 0 && (
          <DndProvider backend={HTML5Backend}>
            <DragableOrderList
              orders={sortedPoints?.deliveryPoints}
              renderOrderAccTitle={renderOrderAccTitle}
              renderOrderAccBody={renderOrderAccBody}
              onOrderPostionChange={onOrderPostionChange}
            />
          </DndProvider>
        )}
      </Timeline>
      <ConfirmOrderActionsModal
        openModal={confirmOrdersAction}
        handleModalSubmit={confirmOrdersAction.type === "postpone" ? postponeOrder : cancelCompletedOrder}
        setOpenModal={setConfirmOrdersAction}
        loading={operationLoading}
      />
    </div>
  );
};

export default connect(
  (state: RootStateOrAny, props: any) => {
    const { onRouteChange } = props;
    return {
      jobDetails: state.deliveryJobDetails.items[0],
      onRouteChange,
    };
  },
  (dispatch: any) => ({
    ...bindActionCreators(deliveryJobDetailsActions, dispatch),
  }),
)(OrdersList);
