import React, { useEffect, useState } from "react";
import { Button, Modal, Radio, Select, Form, DatePicker, Row, Input } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import { CheckCircleTwoTone } from "@ant-design/icons";

function ConfirmModal({
  openModal,
  modalTitle,
  handleModalSubmit,
  setOpenModal,
  loading,
  isAdminOrders,
  orders,
  selfStoresSelection,
  selfTemplateSelection,
  retailer,
  onSaveOrderCancel,
}) {
  const [openForAll, setOpenForAll] = useState(false);
  const [pointId, setPointId] = useState(selfStoresSelection?.find((e) => !e.deletedAt)?.id || "None selected");
  const [templateId, setTemplateId] = useState(
    selfTemplateSelection?.find((e) => e.status === "approved")?.id || "None selected",
  );
  const [postponeDate, setPostponeDate] = useState(moment().add(1, "days"));
  const [orderIdConfirm, setorderIdConfirm] = useState();
  const [ticketData, setTicketData] = useState({
    label: "",
    description: "",
  });

  useEffect(() => {
    if (["journeyView", "returnFormView"].includes(openModal?.type)) {
      handleModalSubmit(openModal.type, openModal?.id, openForAll, pointId, isAdminOrders, templateId);
    }
    // eslint-disable-next-line
  }, [openModal.type]);

  const intl = useIntl();

  const handleOpenForAllChange = (e) => {
    setOpenForAll(e.target.value);
  };
  const postponeDisabledDate = (postponeDate) => {
    return postponeDate && postponeDate < moment().startOf("day");
  };

  const isCancelGranted = () => {
    return orderIdConfirm && Number(orderIdConfirm) === openModal?.id;
  };
  const isConfirmDisabled = () => {
    if (openModal?.type === "saveOrders" && pointId === "None selected" && !isAdminOrders) return true;
    if (openModal?.type === "postpone" && !postponeDate) return true;
    if (openModal?.type === "cancelCompleted" && !isCancelGranted()) return true;
    return false;
  };

  const renderModalContent = () => {
    const order = orders.find(({ id }) => +id === +openModal?.id);
    const wasRestricted = order ? order.retailer?.restricted : false;
    const type = openModal?.type;

    switch (type) {
      case "return":
        if (isAdminOrders && wasRestricted) {
          return (
            <p>
              <Radio.Group onChange={handleOpenForAllChange} value={openForAll}>
                <Radio value>
                  <FormattedMessage id="page.ordersManagment.openForDrivers" defaultMessage="Open for all drivers" />
                </Radio>
                <Radio value={false}>
                  <FormattedMessage
                    id="page.ordersManagment.onlyForRetailers"
                    defaultMessage="Only for retailer's drivers"
                  />
                </Radio>
              </Radio.Group>
            </p>
          );
        }
        break;

      case "saveOrders":
        return (
          <>
            <br />
            <Form.Item label="Pickup Point">
              <Select
                placeholder={`${intl.formatMessage({
                  id: "input.pickupPoint",
                  defaultMessage: "Pickup Point",
                })}`}
                value={pointId}
                onChange={setPointId}
              >
                {selfStoresSelection?.length > 0
                  ? selfStoresSelection
                      .filter((e) => !e.deletedAt)
                      .map((store) => (
                        <Select.Option key={store.id} value={store.id}>
                          {"(" + store.id + ") "}
                          {store.name}
                        </Select.Option>
                      ))
                  : null}
              </Select>
            </Form.Item>
            {!isAdminOrders && (
              <Form.Item label="Template">
                <Select
                  placeholder={`${intl.formatMessage({
                    id: "input.template",
                    defaultMessage: "Template",
                  })}`}
                  value={templateId}
                  onChange={setTemplateId}
                  style={{ width: "156px" }}
                >
                  <Select.Option value="None selected">None selected</Select.Option>

                  {selfTemplateSelection?.length > 0 && retailer?.isCustomMessagingTemplatesAllowed ? (
                    <>
                      {" "}
                      {selfTemplateSelection
                        .filter((e) => e.status === "approved")
                        .map((template) => (
                          // eslint-disable-next-line
                          <Select.Option key={template.id} value={template.id}>
                            {/* eslint-disable-next-line */}
                            {"(" + template.id + ") "}
                            {template.name}
                          </Select.Option>
                        ))}{" "}
                    </>
                  ) : null}
                </Select>
              </Form.Item>
            )}
          </>
        );

        break;

      case "postpone":
        return (
          <Row className="options__row">
            <label className="options__row--label"> Postponed delivery date </label>
            <DatePicker
              value={postponeDate}
              disabledDate={postponeDisabledDate}
              allowClear={false}
              onChange={(date, dateString) => {
                setPostponeDate(date);
              }}
              format={"DD.MM.YYYY"}
            />
          </Row>
        );
      case "cancelCompleted":
        return (
          <Row className="options__row">
            <label className="options__row--label"> Please confirm the order ID </label>
            <Input
              style={{ width: "50%", borderColor: isCancelGranted() ? "#27ae60" : "" }}
              value={orderIdConfirm}
              status={!isCancelGranted() ? "warning" : ""}
              onChange={(e) => setorderIdConfirm(e.target.value)}
              suffix={isCancelGranted() ? <CheckCircleTwoTone twoToneColor="#27ae60" /> : null}
            />
          </Row>
        );
      case "createTicket":
        return (
          <Form labelCol={{ span: 4 }}>
            <Form.Item label="Label">
              <Input
                value={ticketData.label}
                onChange={(e) => setTicketData((s) => ({ ...s, label: e.target.value }))}
              />
            </Form.Item>
          </Form>
        );
      default:
        return <></>;
    }
  };

  return (
    <Modal
      visible={openModal.open && openModal?.type !== "journeyView"}
      title={
        <>
          {modalTitle[openModal.type]} {intl.formatMessage({ id: "order", defaultMessage: "order" })}
        </>
      }
      onOk={() => handleModalSubmit(openModal?.type, openModal?.id, openForAll, isAdminOrders)}
      onCancel={() => {
        setOpenModal((prevState) => ({
          ...prevState,
          open: false,
        }));
      }}
      afterClose={() => {
        setTimeout(() => {
          setOpenModal((prevState) => ({
            open: false,
            id: "",
            type: "",
          }));
        }, 500);
        if (onSaveOrderCancel) {
          onSaveOrderCancel(false);
        }
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setOpenModal((prevState) => ({
              ...prevState,
              open: false,
            }));
          }}
        >
          <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="submit"
          type="primary"
          id="confirm-modal-ok"
          loading={loading}
          onClick={() =>
            handleModalSubmit(openModal.type, openModal?.id, openForAll, pointId, isAdminOrders, templateId, {
              postponeDate: postponeDate,
              ticket: ticketData,
            })
          }
          disabled={isConfirmDisabled()}
        >
          <FormattedMessage id="tabPane.actions.confirm" defaultMessage="Confirm" />
        </Button>,
      ]}
    >
      <Row>
        <p>
          {intl.formatMessage(
            {
              id: "modal.areYouSureWantTo",
              defaultMessage: "Are you sure you want to",
            },
            {
              operation: modalTitle[openModal?.type],
              concern: `${intl.formatMessage({ id: "order", defaultMessage: "order" })}`,
            },
          )}
        </p>
      </Row>
      {renderModalContent()}
    </Modal>
  );
}

export default ConfirmModal;
