export const markersColors: string[] = [
  "#fa6161",
  "#0d6e44",
  "#dbde21",
  "#a635db",
  "#18ccc3",
  "#f578cd",
  "#f5cd78",
  "#cdf578",
  "#78cff5",
  "#807d7d",
];
