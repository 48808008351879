import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Form, notification, Button } from "antd";
import GoogleMapReact from "google-map-react";

// import { MobileGoogleMapWrapper } from "../MobileGoogleMapWrapper";
import Customer from "../../../../../components/markers/Customer";
import { MobileModalWrapper } from "../../../../../components/AdminPanel/Mobile/MobileModalWrapper";

import "./styles.less";
import LocationInputForm from "../../../../../components/OrdersWidget/UploadedOrdersTable/components/LocationInputForm";
import { API_KEY } from "../../../../../constants/config";

export const SharedOrderLocationModal = ({
  disabled,
  updateOrders,
  updateLocation,
  getMapData,
  selectedSharedLocation,
  // setSelectedSharedLocation,
  onClose,
}) => {
  const lat = selectedSharedLocation?.deliveryPoint?.lat && Number(selectedSharedLocation.deliveryPoint.lat);
  const lng = selectedSharedLocation?.deliveryPoint?.long && Number(selectedSharedLocation.deliveryPoint.long);
  const [form] = Form.useForm();
  const [customLocation, setCustomLocation] = useState({
    lat: parseFloat(selectedSharedLocation?.deliveryPoint?.lat) || 0,
    long: parseFloat(selectedSharedLocation?.deliveryPoint?.long) || 0,
  });
  const [geolocationResponse, setGeolocationResponse] = useState({ address: null, isError: false });

  const saveNewPosition = async () => {
    const { lat, long } = form.getFieldsValue(true);

    const geocode = await getMapData({ lat, long }, "coords");
    if (geocode) {
      try {
        await updateLocation({
          selectedId: selectedSharedLocation.id,
          lat: parseFloat(lat),
          long: parseFloat(long),
          old: true,
          address: geocode.address,
        });
      } catch (e) {
        notification.error({
          message: "Error",
          description: "Can't change location of this order!",
        });
      }
      await updateOrders();
      onClose();
    } else {
      notification.error({
        message: "Error",
        description: "Location is invalid",
      });
    }
  };

  return (
    <MobileModalWrapper
      visible={!!selectedSharedLocation}
      title={
        <div className="shared-location-modal-header">
          <div>
            <FormattedMessage id="map.orderInfo" defaultMessage="Order info for tracking number" />
          </div>
          <span>№{selectedSharedLocation?.trackingId}</span>
        </div>
      }
      onClose={onClose}
      footer={null}
    >
      <div style={{ height: "500px", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: API_KEY }}
          // defaultCenter={{ lat: customLocation.lat, lng: customLocation.long }}
          center={{ lat: customLocation.lat, lng: customLocation.long }}
          defaultZoom={10}
          onClick={({ x, y, lat, lng, event }) => {
            if (!disabled) {
              setCustomLocation((old) => ({
                ...old,
                lat: parseFloat(lat),
                long: parseFloat(lng),
              }));
            }
          }}
          distanceToMouse={() => {}}
        >
          <Customer orderInfo={selectedSharedLocation} lat={lat} lng={lng} />
          {customLocation?.lat !== lat || customLocation?.long !== lng ? (
            <Customer
              orderInfo={selectedSharedLocation}
              color="blue"
              lat={customLocation.lat}
              lng={customLocation.long}
            />
          ) : null}
        </GoogleMapReact>
      </div>
      {/* <MobileGoogleMapWrapper lat={lat} lng={lng}>
        <Customer orderInfo={selectedSharedLocation} lat={lat} lng={lng} />
      </MobileGoogleMapWrapper> */}
      <LocationInputForm
        disabled={disabled}
        form={form}
        customLocation={customLocation}
        setCustomLocation={setCustomLocation}
        setIsLoading={() => {}}
        setGeolocationResponse={setGeolocationResponse}
        getMapData={getMapData}
      />
      <Button
        onClick={saveNewPosition}
        disabled={!customLocation.lat || !customLocation.long || geolocationResponse.isError}
      >
        <FormattedMessage id="map.orderInfo.updateLocation.button" defaultMessage="Save" />
      </Button>
    </MobileModalWrapper>
  );
};
