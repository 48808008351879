import moment from "moment";
import React from "react";
import { AreaChart, XAxis, Tooltip, Area, ResponsiveContainer, linearGradient } from "recharts";

const groupByMonth = (data) => {
  const { dates } = data.reduce(
    ({ dates }, { date, value }) => {
      const month = moment(date).format("MM.YYYY");

      const prevVal = dates[month] || 0;

      return {
        dates: {
          ...dates,
          [month]: prevVal + +value,
        },
      };
    },
    { dates: {} },
  );

  return Object.entries(dates).map(([date, value]) => ({ date, value }));
};

const formatDate = (data) => {
  return data.map(({ date, value }) => ({ date: moment(date).format("DD.MM.YYYY"), value }));
};

const mapData = (data, isOneMonth) => {
  const actualData = isOneMonth ? formatDate(data) : groupByMonth(data);

  return actualData.map(({ value, date }) => ({ date, value: +value }));
};

function Chart({ data, isOneMonth }) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart data={mapData(data, isOneMonth)}>
        <defs>
          <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
            <stop offset="25%" stopColor="#fddc37" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#fddc37" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="date" padding={{ left: 50, right: 50 }} />
        <Tooltip />
        <Area type="monotone" dataKey="value" stroke="#ffd400" fillOpacity={1} fill="url(#colorValue)" />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default Chart;
