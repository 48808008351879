export const REPORT_TYPE_ENUM = {
  driver: "Driver",
  retailer: "Retailer",
  overall: "Overall",
};

export const chartValueList = [
  {
    value: "total_cash",
    title: "cash",
  },
  {
    value: "total_orders",
    title: "orders",
  },
  {
    value: "total_jobs",
    title: "jobs",
  },
  {
    value: "total_postponed",
    title: "postponed",
  },
  {
    value: "total_canceled",
    title: "canceled",
  },
];

export const radioButtons = [
  {
    value: REPORT_TYPE_ENUM.driver,
    title: "Driver",
  },
  {
    value: REPORT_TYPE_ENUM.retailer,
    title: "Retailer",
  },
  {
    value: REPORT_TYPE_ENUM.overall,
    title: "Overall",
  },
];

export const reportTableRows = [
  {
    title: "Cash on delivery",
    key: "cash",
  },
  {
    title: "Quantity of orders",
    key: "totalorders",
  },
  {
    title: "Quantity of jobs",
    key: "totaljobs",
  },
  {
    title: "Postpones",
    key: "postponed",
  },
  {
    title: "Cancellations",
    key: "canceled",
  },
  {
    title: "Late delivery percentage",
    key: "lateDeliveryPercentage",
  },
  {
    title: "On time delivery percentage",
    key: "ontimeDeliveryPercentage",
  },
];
