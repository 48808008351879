import React from "react";

const MainLogoMobile = () => (
  <svg
  id="Layer_2"
  data-name="Layer 2"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 200 53.19"
  className=""
  style={{width:"140px"}}
>
  <defs>
    <linearGradient id="linear-gradient" x1="-4.08" y1="17.39" x2="54.01" y2="17.39" gradientUnits="userSpaceOnUse">
      <stop offset=".38" stopColor="#01cdfb" />
      <stop offset="1" stopColor="#01acec" />
    </linearGradient>
    <linearGradient id="linear-gradient-2" y1="26.53" x2="54.01" y2="26.53" xlinkHref="#linear-gradient" />
    <linearGradient id="linear-gradient-3" x1="-4.08" y1="26.53" x2="54.01" y2="26.53" xlinkHref="#linear-gradient" />
    <linearGradient id="linear-gradient-4" y1="35.5" x2="54.01" y2="35.5" xlinkHref="#linear-gradient" />
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <g>
      <g>
        <path
          className="cls-mobile"
          d="m94.25,53.19h0c-3.37,0-5.9-3.1-5.21-6.4l8.79-42.54c.51-2.47,2.69-4.25,5.21-4.25h0c3.37,0,5.9,3.1,5.21,6.4l-8.79,42.54c-.51,2.47-2.69,4.25-5.21,4.25Z"
        />
        <path
          className="cls-mobile"
          d="m109.42,53.19h0c-3.35,0-5.85-3.07-5.18-6.35l6.05-29.26c.51-2.45,2.67-4.22,5.18-4.22h0c3.35,0,5.85,3.07,5.18,6.35l-6.05,29.26c-.51,2.45-2.67,4.22-5.18,4.22Z"
        />
        <path
          className="cls-mobile"
          d="m141.09,53.19h-7.01c-2.53,0-4.66-1.89-4.96-4.4l-3.55-29.84c-.35-2.97,1.97-5.58,4.96-5.58h1.59c2.59,0,4.76,1.99,4.98,4.57l2.12,25.01,13.28-26.81c.84-1.7,2.58-2.78,4.47-2.78h0c3.77,0,6.18,4.01,4.41,7.34l-15.88,29.84c-.87,1.63-2.56,2.65-4.41,2.65Z"
        />
      </g>
      <g>
        <path
          className="cls-mobile"
          d="m85.43,7.53C81.44,2.51,75.44,0,67.42,0h-17.25C47.55,0,45.3,1.84,44.77,4.4h0c-.71,3.42,1.91,6.63,5.4,6.63h5.27s9.08,0,9.08,0c4.5,0,7.93,1.46,10.27,4.39,2.35,2.92,3.06,6.61,2.14,11.06-.93,4.5-3.15,8.24-6.67,11.22-3.48,2.94-7.36,4.43-11.65,4.46-.02,0-.04,0-.06,0h-17.1c-2.61,0-4.87,1.84-5.4,4.4h0c-.71,3.42,1.91,6.63,5.4,6.63h14.35c8.13,0,15.31-2.57,21.55-7.73,6.24-5.15,10.15-11.53,11.72-19.14,1.55-7.51.33-13.77-3.65-18.79Z"
        />
        <path className="cls-mobile" d="m58.55,42.16s.04,0,.06,0c-.05,0-.09,0-.14,0h.08Z" />
      </g>
      <path
        className="cls-4"
        d="m50.47,19.77H13.8c-1.31,0-2.38-1.07-2.38-2.38s1.07-2.38,2.38-2.38h36.67c1.31,0,2.38,1.07,2.38,2.38s-1.07,2.38-2.38,2.38Z"
      />
      <path
        className="cls-1"
        d="m9.44,28.91H2.38c-1.31,0-2.38-1.07-2.38-2.38s1.07-2.38,2.38-2.38h7.06c1.31,0,2.38,1.07,2.38,2.38s-1.07,2.38-2.38,2.38Z"
      />
      <path
        className="cls-3"
        d="m39.05,28.91h-20.05c-1.31,0-2.38-1.07-2.38-2.38s1.07-2.38,2.38-2.38h20.05c1.31,0,2.38,1.07,2.38,2.38s-1.07,2.38-2.38,2.38Z"
      />
      <path
        className="cls-5"
        d="m46.98,37.88H10.08c-1.31,0-2.38-1.07-2.38-2.38s1.07-2.38,2.38-2.38h36.9c1.31,0,2.38,1.07,2.38,2.38s-1.07,2.38-2.38,2.38Z"
      />

    </g>
  </g>
</svg>
);

export default MainLogoMobile;
