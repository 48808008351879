import axios from "axios";
import { defaultHeaders } from "redux-rest-resource";

import { API_URL } from "../../constants/config";
import { saveAuthData, getAuthData, clearAuthData, TENANT_STORAGE_KEY } from "../../helpers/authStorage";

let refreshTokenPromise;

export function refreshToken() {
  const refreshKey = getAuthData().refreshToken;
  const userRole = getAuthData().role;
  const currentURL = window.location.href;

  const loginHref = () => {
    if (userRole === "driver") {
      return "/driver_login";
    }
    if (userRole === "retailer") {
      return "/retailer_login";
    }
    if (userRole === "customer") {
      return "/tracking_info";
    }
    if (currentURL.includes("retailer_registration")) {
      return "/retailer_signup";
    }
    if (currentURL.includes("retailer_cabinet")) {
      return "/retailer_login";
    }
    return "/admin_login";

    // userRole ==='driver' ? '/driver_login' : '/retailer_login';
  };

  const refreshTokenPath = () => {
    // if (userRole === "driver") {
    //   return `${API_URL}/auth/drivers/refresh-access-token`;
    // }
    // if (userRole === "retailer") {
    //   return `${API_URL}/auth/retailer/refresh-access-token`;
    // }
    // if (userRole === "customer") {
    //   return `${API_URL}/customers/auth/refresh-access-token`;
    // }
    // return `${API_URL}/admin/auth/refresh-access-token`;

    // userRole ==='driver' ? `${API_URL}/auth/drivers/refresh-access-token` : `${API_URL}/auth/retailer/refresh-access-token`;

    if (currentURL.includes("driver_signup")) {
      return `${API_URL}/auth/drivers/refresh-access-token`;
    }
    if (currentURL.includes("retailer_cabinet")) {
      return `${API_URL}/auth/retailers/refresh-access-token`;
    }
    if (currentURL.includes("tracking_info")) {
      return `${API_URL}/customers/auth/refresh-access-token`;
    }
    return `${API_URL}/admin/auth/refresh-access-token`;
  };

  if (!refreshKey) {
    document.location.href = loginHref();
    return Promise.reject();
  }
  if (!refreshTokenPromise) {
    // const currentUser =
    refreshTokenPromise = axios
      .post(refreshTokenPath(), {
        refreshToken: refreshKey,
      })
      .then((res) => {
        // console.log("refreshTokenPromise", res, "data", JSON.stringify(res.data));
        const tokens = res?.data?.tokens ? res.data.tokens : res.data;
        saveAuthData(tokens, userRole);
        Object.assign(defaultHeaders, {
          Authorization: `Bearer ${tokens.accessToken}`,
          Tenant: localStorage.getItem(TENANT_STORAGE_KEY),
        });
      })
      .then((res) => {
        refreshTokenPromise = null;
        return res;
      })
      .catch((errorResponse) => {
        clearAuthData();
        document.location.href = loginHref();
        return Promise.reject(errorResponse);
      });
  }

  return refreshTokenPromise;
}
