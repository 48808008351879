import React from "react";
import { CarFilled } from "@ant-design/icons";

import "./style.less";

const DriverMarker = ({ onMarkerClick }) => (
  <div className="driverMarker" onClick={onMarkerClick}>
    <CarFilled className="icon" />
  </div>
);

export default DriverMarker;
