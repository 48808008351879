import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Typography, Button, Layout, notification } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { SelectLanguage } from "../../../../components/SelectLanguage";
import { actions as driverActions } from "../../../../redux/resources/driver";
import { actions as retailerActions } from "../../../../redux/resources/retailer";
import MainLogo from "../../../../assets/newLanding/MainLogo";
import MainLogoMobile from "../../../../assets/newLanding/MainLogoMobile";
import RetailerTablet from "../../../../assets/newLanding/auth/RetailerTablet.png";
import { redirectOnLogin } from "../../../../helpers/authStorage";
import { SignInForm } from "../../components";
import SocialAuth from "../../components/SocialAuth";
import styles from "./signInRetailerPage.module.less";

const SignInRetailerPage = ({ loginDriver, loginRetailer, isMobile }) => {
  const [isDriver, setIsDriver] = useState(false);

  const history = useHistory();
  const intl = useIntl();

  useEffect(() => {
    document.location.href.includes("driver_login") && !isDriver && setIsDriver(true);
  }, [isDriver]);

  const openNotification = (description) => {
    notification.error({
      message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
      description,
    });
  };

  const handleSubmit = (values) => {
    const formattedFields = { ...values };
    formattedFields.email = formattedFields.email.toLowerCase();

    const loginUser = isDriver ? loginDriver : loginRetailer;

    loginUser({ ...formattedFields })
      .then(redirectOnLogin(isDriver, history))
      .catch((error) => {
        typeof error.body?.message === "object"
          ? openNotification(error.body?.message[0])
          : openNotification(error.body?.message);
      });
  };

  const title = isDriver
    ? `${intl.formatMessage({ id: "driver", defaultMessage: "Driver" })}`
    : `${intl.formatMessage({ id: "retailer", defaultMessage: "Retailer" })}`;
  const loginSignInReferencePath = isDriver ? "/driver_signup" : "/retailer_signup";
  const forgotPasswordReferencePath = isDriver ? "/driver_forgot_password" : "/password_recovery";

  return isMobile ? (
    <Layout className={styles.signUpWrap}>
      <Row style={{ flex: "auto" }}>
        <Col span={24} className={styles.mainSection}>
          <Row align="middle" justify="space-between">
            <Col span={6} offset={2}>
              <Link to="/">
                <MainLogoMobile />
              </Link>
            </Col>
            <Col span={16}>
              <Row align="top" justify="end">
                <Col span={8} offset={15}>
                  <SelectLanguage />{" "}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row align="middle" style={{ flex: "auto" }}>
            <Col
              span={20}
              offset={2}
              style={{ height: "100%", display: "flex", flexDirection: "column", paddingTop: 60, paddingBottom: 35 }}
            >
              <SignInForm isMobile recoverPasswordPath={forgotPasswordReferencePath} onSubmit={handleSubmit} />
              {/* <SocialAuth isDriver={isDriver} /> */}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ height: 80, borderTop: "#E9E9E9 1px solid", paddingBottom: 36 }}>
        <Col
          offset={2}
          span={14}
          style={{ display: "flex", alignItems: "end", fontSize: 14, fontWeight: 300, lineHeight: "18px" }}
        >
          Don’t have an account?
        </Col>
        <Col
          span={6}
          style={{
            display: "flex",
            alignItems: "end",
            fontSize: 16,
            fontWeight: 700,
            lineHeight: "24px",
            color: "#0A0A0B",
            justifyContent: "end",
            paddingRight: 10,
          }}
        >
          <Link
            to={loginSignInReferencePath}
            style={{
              color: "#0A0A0B",
            }}
          >
            <FormattedMessage id="page.login.signup" defaultMessage="Sign Up" />
          </Link>
        </Col>
      </Row>
    </Layout>
  ) : (
    <Layout className={styles.signUpWrap}>
      <Row>
        <Col span={12} md={24} lg={12} className={styles.promoSection}>
          <Row gutter={[0, 56]}>
            <Col span={23} offset={1}>
              <Link to="/">
                <MainLogo />
              </Link>
            </Col>

            <Col span={21} md={24} offset={3}>
              <Typography.Title level={2} className={styles.promoSectionTitle}>
                {title} <br />
                <Typography.Text className={styles.promoSectionSubtitle}>
                  <FormattedMessage id="page.login.login" defaultMessage="Log In" />
                </Typography.Text>
              </Typography.Title>
              <div className={styles.tablet_img}>
                <img src={RetailerTablet} style={{ width: 106, height: 207, zIndex: 1 }} alt="imageForTablet" />
              </div>{" "}
            </Col>
          </Row>
        </Col>

        <Col span={12} md={24} lg={12} className={styles.mainSection}>
          <Row align="middle">
            <Col span={3} offset={17}>
              <SelectLanguage />
            </Col>

            <Col span={3}>
              <Button size="large" type="link" className={styles.signUpButton}>
                <Link to={loginSignInReferencePath}>
                  <FormattedMessage id="page.login.signup" defaultMessage="Sign Up" />
                </Link>
              </Button>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={12} offset={6}>
              <div style={{ marginTop: 250, transform: "translate(-12%, -50%)" }}>
                <SignInForm recoverPasswordPath={forgotPasswordReferencePath} onSubmit={handleSubmit} />
                {/* <SocialAuth isDriver={isDriver} /> */}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default connect(null, (dispatch) => ({
  ...bindActionCreators(driverActions, dispatch),
  ...bindActionCreators(retailerActions, dispatch),
}))(SignInRetailerPage);
