import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "zones",
  actions: {
    get: {
      method: "GET",
      url: `${API_URL}/admin/zones`,
      transformResponse: ({ body }) => ({ body: body.sort((a, b) => a?.id - b?.id) }),
    },
    post: {
      method: "POST",
      url: `${API_URL}/admin/zones`,
    },

    del: {
      method: "DELETE",
      url: `${API_URL}/admin/zones/:id`,
    },

    patch: {
      method: "PATCH",
      url: `${API_URL}/admin/zones/:id/polygon`,
    },

    updateZoneName: {
      method: "PATCH",
      url: `${API_URL}/admin/zones/:id/name`,
    },

    getWarehouse: {
      method: "GET",
      url: `${API_URL}/admin/warehouses`,
      transformResponse: ({ body }) => ({ body }),
    },
    postWarehouse: {
      method: "POST",
      url: `${API_URL}/admin/warehouses`,
    },

    delWarehouse: {
      method: "DELETE",
      url: `${API_URL}/admin/warehouses/:id`,
    },

    putWarehouse: {
      method: "PUT",
      url: `${API_URL}/admin/warehouses/:id`,
    },
    zoneWarehouse: {
      method: "PATCH",
      url: `${API_URL}/admin/zones/:id/warehouse`,
    },

    // Pure Actions for State Manipulation
    updateZoneState: {
      isPure: true,
      reduce: (state, action) => {
        const { id: zoneId, name: zoneName } = action.context;

        return {
          ...state,
          item: state.item.map((item) => {
            if (item.id === zoneId) {
              return {
                ...item,
                name: zoneName,
              };
            }
            return item;
          }),
        };
      },
    },
  },
});

setAuthHeader(getAuthData().accessToken);
