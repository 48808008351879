import React, { useEffect, useCallback } from "react";
import { Button, Popconfirm, Table } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

export const LocationsTable = ({
  location,
  data,
  selection,
  pagination,
  fetchLocationsChangeAdminCustomers,
  fetchCountUncheckedAdminCustomers,
  checkRecordAdminCustomers,
  archiveRecordAdminCustomers,
  getQueryParams,
  onChangePagination,
  handleToggle,
  isBadLocation = false,
  tab,
}) => {
  const fetchData = useCallback(() => {
    const queryParams = getQueryParams();
    const queryWithPage = queryParams?.page ? queryParams : { page: 1, limit: 10 };

    fetchLocationsChangeAdminCustomers(
      {},
      {
        query: { ...queryWithPage, failed: tab === "1" ? false : true },
      },
    );
  }, [fetchLocationsChangeAdminCustomers, getQueryParams, tab]);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, location.search]);

  const onChangeStatus = (recordId) => async () => {
    await checkRecordAdminCustomers({ recordId });
  };

  const onArchive = (recordId) => async () => {
    await archiveRecordAdminCustomers({ recordId });
    await fetchData();
  };

  const columns = [
    {
      title: "Job ID",
      dataIndex: "jobId",
      key: "jobId",
    },
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Client name",
      dataIndex: "clientName",
      key: "clientName",
    },
    {
      title: "Client number",
      dataIndex: "clientNumber",
      key: "clientNumber",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <>
          {status === "pending" ? (
            <Popconfirm
              title="Are you sure to change status?"
              onConfirm={onChangeStatus(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <span style={{ cursor: "pointer" }}>{status}</span>
            </Popconfirm>
          ) : (
            <span>{status}</span>
          )}
        </>
      ),
    },
    {
      title: "",
      key: "archive",
      dataIndex: "archived",
      width: "65px",
      render: (_, record) => (
        <Popconfirm
          title="Are you sure to archive status?"
          onConfirm={onArchive(record.id)}
          okText="Yes"
          cancelText="No"
          okType="danger"
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        >
          <Button danger>Archive</Button>
        </Popconfirm>
      ),
    },
  ];

  const dataSource = data?.map(({ id, order, status, archived }) => {
    return {
      id,
      jobId: order?.job?.id,
      orderId: order?.id,
      clientName: order?.customerInfo?.name,
      clientNumber: order?.customer?.phoneNumber,
      status,
      archived,
    };
  });

  const currentQueryParams = getQueryParams();

  const paginationConfig = {
    pageSize: Number(currentQueryParams?.limit) || 10,
    total: pagination?.totalCount || 10,
    current: Number(currentQueryParams?.page) || 1,
    pageSizeOptions: [10, 20, 30, 50],
    showSizeChanger: true,
    position: ["bottomRight"],
  };

  const rowSelection = {
    onChange: handleToggle,
  };

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      rowKey={(record) => record.id}
      rowSelection={{
        selectedRowKeys: selection,
        type: "checkbox",
        ...rowSelection,
      }}
      pagination={paginationConfig}
      onChange={(p, s) => {
        onChangePagination(p, s, isBadLocation);
      }}
    />
  );
};
