export default (data) => {
  if (!(data.length > 0)) {
    return false;
  }

  const numCoords = data.length;

  let X = 0.0;
  let Y = 0.0;
  let Z = 0.0;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < data.length; i++) {
    const lat = (data[i][0] * Math.PI) / 180;
    const lon = (data[i][1] * Math.PI) / 180;

    const a = Math.cos(lat) * Math.cos(lon);
    const b = Math.cos(lat) * Math.sin(lon);
    const c = Math.sin(lat);

    X += a;
    Y += b;
    Z += c;
  }

  X /= numCoords;
  Y /= numCoords;
  Z /= numCoords;

  const lon = Math.atan2(Y, X);
  const hyp = Math.sqrt(X * X + Y * Y);
  const lat = Math.atan2(Z, hyp);

  const newX = (lat * 180) / Math.PI;
  const newY = (lon * 180) / Math.PI;

  return { lat: newX, long: newY };
};
