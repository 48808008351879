import { notification } from "antd";
import { useState } from "react";
import { IUseOrderOperations, IOrderActionsProps } from "src/types/JobDetails";
import { ICashAmountModalVisible } from "src/types/JobManagement";

const useOrderOperations: IUseOrderOperations = (props) => {
  const {
    fetchDeliveryJobDetails,
    deleteOrderDeliveryJobDetails,
    getRoutesDeliveryJobDetails,
    postponeOrderDeliveryJobDetails,
    forceNextOrderDeliveryJobDetails,
    completeOrderDeliveryJobDetails,
    returnToShipperDeliveryJobDetails,
    cancelOrderDeliveryJobDetails,
    cancelCompletedDeliveryJobDetails,
    changeOrderPositionDeliveryJobDetails,
    updateCashDeliveryJobDetails,
    getPolylineDeliveryJobDetails,
    onRouteChange,
  } = props;

  const [confirmOrdersAction, setConfirmOrdersAction] = useState<ICashAmountModalVisible>({
    open: false,
    id: null,
    job: null,
    type: null,
  });
  const [operationLoading, setOperationLoading] = useState<boolean>(false);

  const deleteOrder = async (param: IOrderActionsProps) => {
    try {
      await deleteOrderDeliveryJobDetails(param);

      await updateDeliveryRoute(param.jobId);

      notification.success({
        message: "Message",
        description: `Order ${param.orderId} was deleted successfully.`,
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to delete order.",
      });
    }
  };
  const postponeOrder = async (jobId: number, orderId: number, postponedTime: string) => {
    setOperationLoading(true);
    try {
      await postponeOrderDeliveryJobDetails({ jobId, orderId, postponedTime });
      await updateDeliveryRoute(jobId);

      notification.success({
        message: "Message",
        description: `Order ${orderId} was postponed successfully.`,
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to postpone order.",
      });
    } finally {
      setOperationLoading(false);
      setConfirmOrdersAction({ open: false, id: null, job: null, type: null });
    }
  };

  const cancelOrder = async (param: IOrderActionsProps) => {
    try {
      await cancelOrderDeliveryJobDetails(param);
      await updateDeliveryRoute(param.jobId);

      notification.success({
        message: "Message",
        description: `Order ${param.orderId} was canceled successfully.`,
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to cancel order.",
      });
    }
  };

  const cancelCompletedOrder = async (jobId: number, orderId: number) => {
    try {
      await cancelCompletedDeliveryJobDetails({ orderId });
      await updateDeliveryRoute(jobId);

      notification.success({
        message: "Message",
        description: `Order ${orderId} was canceled successfully.`,
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to cancel order.",
      });
    } finally {
      setOperationLoading(false);
      setConfirmOrdersAction({ open: false, id: null, job: null, type: null });
    }
  };

  const returnToShipper = async (param: IOrderActionsProps) => {
    try {
      await returnToShipperDeliveryJobDetails(param);
      await updateDeliveryRoute(param.jobId);

      notification.success({
        message: "Message",
        description: `Order ${param.orderId} was returned to shipper successfully.`,
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to create return to shipper.",
      });
    }
  };

  const completeOrder = async (param: IOrderActionsProps) => {
    try {
      await completeOrderDeliveryJobDetails(param).then(() => {
        notification.success({
          message: "Message",
          description: `Order ${param.orderId} is completed successfully.`,
        });
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to complete order.",
      });
    }
  };

  const fetchJobDetailsById = async (jobId: number) => {
    try {
      await fetchDeliveryJobDetails({ id: jobId }, { query: { attachOrders: true } });
      await getRoutesDeliveryJobDetails({ jobId: jobId });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to fetch orders",
      });
    }
  };

  const forceNextOrder = async (jobId: number) => {
    try {
      await forceNextOrderDeliveryJobDetails({ jobId });
      await fetchDeliveryJobDetails({ id: jobId }, { query: { attachOrders: true, withError: true } });

      await getRoutesDeliveryJobDetails({ jobId: jobId });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to move order force change next order",
      });
    }
  };

  const changeOrderPosition = async (jobId: number, pointsOrder: number[]) => {
    try {
      await changeOrderPositionDeliveryJobDetails({ jobId }, { query: { save: true }, body: { pointsOrder } });

      notification.success({
        message: "Order of delivery points has been updated.",
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to change order position",
      });
    }
  };

  const onUpdateCashAmount = async (orderId: number, jobId: number, cashAmount: number) => {
    try {
      await updateCashDeliveryJobDetails({ orderId, jobId }, { body: { cashAmount } });

      notification.success({
        message: `Order ${orderId} COD Amount updated successfully.`,
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to update o COD Amount",
      });
    }
  };

  const updateDeliveryRoute = async (jobId: number) => {
    try {
      await getPolylineDeliveryJobDetails({ jobId });
      await getRoutesDeliveryJobDetails({ jobId });
      onRouteChange(true);
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message || "Failed to update delivery route",
      });
    } finally {
      onRouteChange(false);
    }
  };

  return {
    forceNextOrder,
    postponeOrder,
    completeOrder,
    returnToShipper,
    cancelOrder,
    deleteOrder,
    cancelCompletedOrder,
    setConfirmOrdersAction,
    setOperationLoading,
    changeOrderPosition,
    fetchJobDetailsById,
    onUpdateCashAmount,
    updateDeliveryRoute,
    operationLoading,
    confirmOrdersAction,
  };
};

export default useOrderOperations;
