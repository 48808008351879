import React from "react";
import { Form, Button, Row, Col } from "antd";
import { useIntl } from "react-intl";

import arrowLeft from "../../../assets/arrow-left.png";

import "./stepActions.less";

const StepActions = ({ currentStep, onPrevStep, stepsLength }) => {
  const intl = useIntl();

  return (
    <div className="stepActionsButtons">
      <Row gutter={24} className="stepActionsRow">
        <Col span={12} key="onprevious">
          <Form.Item>
            <div className="previousStepWrapper">
              <img src={arrowLeft} alt="Left arrow icon" />
              <Button
                className="previousStepButton"
                type="text"
                disabled={currentStep === 0}
                onClick={() => onPrevStep()}
              >
                {`${intl.formatMessage({ id: "steps.previousStep", defaultMessage: "Previous Step" })}`}
              </Button>
            </div>
          </Form.Item>
        </Col>
        <Col span={12} key="onnext" align="right">
          <Form.Item>
            {currentStep < stepsLength - 1 && (
              <Button type="primary" htmlType="submit" className="nextStepButton">
                {`${intl.formatMessage({ id: "steps.next", defaultMessage: "Next" })}`}
              </Button>
            )}
            {currentStep === stepsLength - 1 && (
              <Button type="primary" htmlType="submit" className="nextStepButton">
                {`${intl.formatMessage({ id: "steps.finish", defaultMessage: "> Finish" })}`}
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default StepActions;
