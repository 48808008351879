import { useState } from "react";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import { CreateSettingFormBody, PriceManagementActionsProps, PriceSettingTenant } from "../types";
import { actions as adminTablesViewActions } from "../../../redux/resources/adminTablesView";
import { IPriceSetting } from "src/entities/price-setting";

export function usePriceManagementActions({
  getAdminPriceManagement,
  createOneAdminPriceManagement,
  deleteOneAdminPriceManagement,
}: PriceManagementActionsProps) {
  const [companyPricesList, setCompanyPricesList] = useState<IPriceSetting[]>([]);
  const [tenants, setTenants] = useState<PriceSettingTenant[]>([]);
  const [companyId, setCompanyId] = useState<number | null>(null);
  const [createSettingModalVisible, setCreateSettingModalVisible] = useState<boolean>(false);
  const { getSelfAdminTablesView: getCurrentUserProfile } = adminTablesViewActions;
  const dispatch = useDispatch();

  const handleFetchCompanyPrices = async () => {
    try {
      // @ts-ignore
      const { body }: any = await getAdminPriceManagement();
      setCompanyPricesList(
        body.map((setting: IPriceSetting) => {
          const currency =
            tenants.find((tenant: PriceSettingTenant) => tenant?.tenant === setting?.tenant?.name)?.currency || "$";
          return { ...setting, currency };
        }),
      );
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Something went wrong while fetching data",
      });
    }
  };

  const handleInit = async () => {
    try {
      // @ts-ignore
      const { body }: any = await dispatch(getCurrentUserProfile());
      setCompanyId(body?.companyId);
      setTenants(
        body?.tenants?.map((userTenant: any) => {
          return {
            tenant: userTenant?.name,
            currency: userTenant?.currency,
          };
        }),
      );
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Something went wrong! please try refreshing the page",
      });
    }
  };

  const handleCreateSetting = async (data: CreateSettingFormBody) => {
    try {
      setCreateSettingModalVisible(false);
      let res = await createOneAdminPriceManagement({
        ...data,
        companyId: companyId,
        zoneId: 0,
      });
      notification.success({
        message: "Succes",
        description: "Price setting created successfully!",
      });
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: error?.body?.message ?? "Setting Not Created",
      });
    }

    await handleFetchCompanyPrices();
  };

  const onOpenCreateSettingModal = () => {
    setCreateSettingModalVisible(true);
  };

  const onCloseCreateSettingModal = () => {
    setCreateSettingModalVisible(false);
  };

  const handleDeleteSetting = (id: number) => {
    deleteOneAdminPriceManagement({ priceId: id }).then(
      () => {
        notification.success({
          message: "Success",
          description: "Price Setting deleted",
        });
        handleFetchCompanyPrices();
      },
      () => {
        notification.error({
          message: "Error",
          description: "Setting not deleted!",
        });
      },
    );
  };

  return {
    // states
    companyPricesList,
    tenants,
    createSettingModalVisible,
    // functions
    handleFetchCompanyPrices,
    handleInit,
    handleCreateSetting,
    onOpenCreateSettingModal,
    onCloseCreateSettingModal,
    handleDeleteSetting,
  };
}
