import { Modal } from "antd";
import React, { FC } from "react";
import CommentBox from "src/components/Ticket/CommentBox";
import { ITicketDetailsModalProps } from "./types";

const TicketDetailsModal: FC<ITicketDetailsModalProps> = ({
  ticketId,
  orderId,
  users,
  currentRole,
  sendMessage,
  getMessages,
  isModalOpen,
  handleCancel,
}) => {

  return <>
    {
      isModalOpen &&
      <Modal
        title={`Ticket Id: ${ticketId} / Order Id: ${orderId}`}
        visible={isModalOpen}
        footer={null}
        onOk={handleCancel}
        onCancel={handleCancel}
      >
        <CommentBox
          ticketId={ticketId}
          users={users}
          currentRole={currentRole}
          sendMessage={sendMessage}
          getMessages={getMessages}
        />
      </Modal>
    }
  </>
}

export default TicketDetailsModal;
