// status
export const optionsCompletedOrders = [
  { label: "Completed", value: "completed" },
  // { label: "Return completed", value: "return_completed" },
  { label: "Completed manually return", value: "completed_manually_return" },
];
export const optionsCanceledOrders = [
  { label: "Canceled", value: "canceled" },
  { label: "Canceled after pickup", value: "canceled_after_pickup" },
];
export const optionsPostponedOrders = [
  { label: "Postponed", value: "postponed" },
  { label: "Postponed after pickup", value: "postponed_after_pickup" },
];

export const optionsFailedOrders = [
  { label: "Failed Delivery", value: "failed_to_deliver" },
  { label: "Failed Pickup", value: "pickup_failed" },
];

export const optionsCanBeAssigned = [{ label: "Can be assigned", value: "can_be_assigned" }];
export const others = [
  { label: "New Orders", value: "new" },
  { label: "In transit", value: "in_transit" },
  { label: "In delivery", value: "in_delivery" },
  { label: "Pickup Done", value: "pickup_completed" },
  { label: "Arrived", value: "arrived" },
  { label: "Returned to Shipper", value: "return_to_shipper" },
  { label: "Deleted", value: "deleted" },
];

export const allOrdersStatus = [
  ...others,
  ...optionsCompletedOrders,
  ...optionsPostponedOrders,
  ...optionsCanceledOrders,
  ...optionsFailedOrders,
];
export const rangeDateOptions = [
  { label: "Uploaded At", value: "created_at" },
  { label: "Last Update Date", value: "updated_at" },
  { label: "Delivery Date", value: "delivery_time" },
];
