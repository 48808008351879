import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";

import registrationFinished from "../../../../assets/registrationFinished.png";
import "./retailerRegistrationFinished.less";

const RetailerRegistrationFinished = () => (
  <>
    <h3 className="title">Thank you! You have successfully completed your registration.</h3>
    <img className="finishImage" src={registrationFinished} alt="Registration finished" />
    <p className="registrationFinishedText">
      Thank you for choosing Dliv! A member of our team will be in touch with you by the end of the day!
    </p>
    <Link to="/retailer_login">
      <Button className="personalCabinetButton">Sign in to your personal cabinet</Button>
    </Link>
  </>
);

export default RetailerRegistrationFinished;
