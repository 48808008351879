import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "driversPoints",
  url: `${API_URL}/admin/driver/next-point/request`,

  actions: {
    fetch: {
      method: "GET",
      transformResponse: ({ body }) => {
        return {
          items: body.items,
          pagination: {
            currentPageNumber: body.meta.currentPage,
            numItemsPerPage: body.meta.itemsPerPage,
            totalCount: body.meta.totalItems,
          },
        };
      },
      reduce: (state, { items, pagination, status }) => ({
        ...state,
        items,
        pagination,
        loading: status === "pending",
      }),
    },
    approve: {
      method: "POST",
      url: `${API_URL}/admin/driver/next-point/request/:requestId/approve`,
    },
    reject: {
      method: "POST",
      url: `${API_URL}/admin/driver/next-point/request/:requestId/reject`,
    },
    wait: {
      method: "POST",
      url: `${API_URL}/admin/driver/next-point/request/:requestId/wait`,
    },
    deletePoint: {
      method: "DELETE",
      url: `${API_URL}/admin/driver/next-point/request`,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
