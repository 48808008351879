import React from "react";
import { Collapse } from "antd";
import { DownOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

export const MobileTableExpand = ({ children, expanded, id, handleSetExpandedId, setIsPanelCollapsed }) => {
  const onCollapse = (bool) => {
    if (bool === "1") {
      handleSetExpandedId(id);
    } else {
      handleSetExpandedId(false);
    }
    if (setIsPanelCollapsed) {
      setIsPanelCollapsed(bool);
    }
  };

  return (
    <div className="expand">
      <Collapse
        expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
        accordion
        bordered={false}
        expandIconPosition="right"
        onChange={onCollapse}
        className="expand-collapse"
        activeKey={expanded ? "1" : "0"}
      >
        <Panel header={expanded ? "Hide" : "Expand"} key="1">
          <div className="expand-body">{children}</div>
        </Panel>
      </Collapse>
    </div>
  );
};
