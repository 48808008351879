import React, { useEffect, useState } from "react";
import { Collapse, Result, Select } from "antd";
import { useIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions as customerActions } from "../../../../redux/resources/customer";
import { getQueryParam } from "../../../../helpers/general";

import Divider from "../../../../components/Divider";

import "./trackingInfoFAQ.less";

const { Panel } = Collapse;
const { Option } = Select;

const TrackingInfoFAQ = ({
  getFAQRetailerDataCustomer,
  fetchOrdersCustomer,
  logInCustomer,
  location,
  selectedTab,
  setSelectedTab,
}) => {
  const intl = useIntl();

  const [loginError, setLoginError] = useState("");
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    async function handleLogin(customerToken) {
      try {
        await logInCustomer({ token: customerToken });
      } catch (e) {
        setLoginError(e.body);
      }
    }

    const queryParams = getQueryParam(location.search);
    const customerToken = queryParams?.token;
    setLoginError("");
    if (customerToken) {
      handleLogin(customerToken);
    }
  }, [logInCustomer, location]);

  const [lastRetailer, setLastRetailer] = useState({ name: "loading...", supportNumber: "loading..." });
  const [displayedRetailer, setDisplayedRetailer] = useState({ name: "loading...", supportNumber: "loading..." });
  const [customerSupport, setCustomerSupport] = useState({ number: "loading..." });

  const updateSupportNumbersByOrderId = (orderId, orders) => {
    const selectedOrder = orders?.find((e) => e?.id === Number(orderId));

    setDisplayedRetailer(selectedOrder?.retailer);
    setCustomerSupport({ number: selectedOrder.tenant.supportNumber });
  };

  useEffect(() => {
    getFAQRetailerDataCustomer().then((res) => {
      setLastRetailer({ ...res.body[0], supportNumber: res.body[0].number });
    });

    fetchOrdersCustomer().then((res) => {
      setOrders(res.body.items);
      if (orders?.length > 0) {
        if (selectedTab) {
          updateSupportNumbersByOrderId(selectedTab, orders);
        }
      }
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (orders?.length > 0) {
      if (selectedTab) {
        updateSupportNumbersByOrderId(selectedTab, orders);
      } else {
        setCustomerSupport({ number: orders[0].tenant.supportNumber });
      }
    } else {
      setDisplayedRetailer(lastRetailer);
    }
    // eslint-disable-next-line
  }, [selectedTab, lastRetailer, orders]);

  const faqData = [
    {
      header: `${intl.formatMessage({
        id: "page.trackingInfo.faqQuestions.deliveryTime.header",
        defaultMessage: "No translation",
      })}`,
      sections: [
        {
          title: `${intl.formatMessage({
            id: "page.trackingInfo.faqQuestions.deliveryTime.when.title",
            defaultMessage: "No translation",
          })}`,
          text: `${intl.formatMessage({
            id: "page.trackingInfo.faqQuestions.deliveryTime.when.text",
            defaultMessage: "No translation",
          })}`,
          attachments: [{ title: "title", img: "img" }],
        },
        {
          title: `${intl.formatMessage({
            id: "page.trackingInfo.faqQuestions.deliveryTime.later.title",
            defaultMessage: "No translation",
          })}`,
          text: `${intl.formatMessage({
            id: "page.trackingInfo.faqQuestions.deliveryTime.later.text",
            defaultMessage: "No translation",
          })}${customerSupport.number}`,
          attachments: [{ title: "title", img: "img" }],
        },
        {
          title: `${intl.formatMessage({
            id: "page.trackingInfo.faqQuestions.deliveryTime.fewDays.title",
            defaultMessage: "No translation",
          })}`,
          text: `${intl.formatMessage({
            id: "page.trackingInfo.faqQuestions.deliveryTime.fewDays.text",
            defaultMessage: "No translation",
          })}${displayedRetailer.name} ${intl.formatMessage({
            id: "page.trackingInfo.faqQuestions.helpers.at",
            defaultMessage: "No translation",
          })} <span> ☎ </span>${displayedRetailer.supportNumber}`,
          attachments: [{ title: "title", img: "img" }],
        },
      ],
    },
    {
      header: `${intl.formatMessage({
        id: "page.trackingInfo.faqQuestions.deliveryLocations.header",
        defaultMessage: "No translation",
      })}`,
      sections: [
        {
          title: `${intl.formatMessage({
            id: "page.trackingInfo.faqQuestions.deliveryLocations.change.title",
            defaultMessage: "No translation",
          })}`,
          text: `${intl.formatMessage({
            id: "page.trackingInfo.faqQuestions.deliveryLocations.change.text",
            defaultMessage: "No translation",
          })}`,
          attachments: [{ title: "title", img: "img" }],
        },
      ],
    },
    {
      header: `${intl.formatMessage({
        id: "page.trackingInfo.faqQuestions.orders.header",
        defaultMessage: "No translation",
      })}`,
      sections: [
        {
          dynamic: true,
          title: `${intl.formatMessage({
            id: "page.trackingInfo.faqQuestions.orders.what.title",
            defaultMessage: "No translation",
          })}`,
          text: `${intl.formatMessage({
            id: "page.trackingInfo.faqQuestions.orders.what.text",
            defaultMessage: "No translation",
          })}${displayedRetailer.name} ${intl.formatMessage({
            id: "page.trackingInfo.faqQuestions.helpers.at",
            defaultMessage: "No translation",
          })} <span> ☎ </span>${displayedRetailer.supportNumber}`,
          attachments: [{ title: "title", img: "img" }],
        },
        {
          dynamic: true,
          title: `${intl.formatMessage({
            id: "page.trackingInfo.faqQuestions.orders.didNot.title",
            defaultMessage: "No translation",
          })}`,
          text: `${intl.formatMessage({
            id: "page.trackingInfo.faqQuestions.orders.didNot.text",
            defaultMessage: "No translation",
          })}${displayedRetailer.name} ${intl.formatMessage({
            id: "page.trackingInfo.faqQuestions.helpers.at",
            defaultMessage: "No translation",
          })} <span> ☎ </span>${displayedRetailer.supportNumber}`,
          attachments: [{ title: "title", img: "img" }],
        },
        {
          dynamic: true,
          title: `${intl.formatMessage({
            id: "page.trackingInfo.faqQuestions.orders.cancel.title",
            defaultMessage: "No translation",
          })}`,
          text: `${intl.formatMessage({
            id: "page.trackingInfo.faqQuestions.orders.didNot.text",
            defaultMessage: "No translation",
          })}${displayedRetailer.name} ${intl.formatMessage({
            id: "page.trackingInfo.faqQuestions.helpers.at",
            defaultMessage: "No translation",
          })} <span> ☎ </span>${displayedRetailer.supportNumber}`,
          attachments: [{ title: "title", img: "img" }],
        },
      ],
    },
  ];

  if (loginError) {
    return <Result status="403" title={loginError.error} subTitle={loginError.message} />;
  }

  return (
    <div className="trackingInfoFAQWrapper">
      <div className="faqHeader">
        <h1>
          {`${intl.formatMessage({
            id: "page.trackingInfo.faqQuestions.pageHeader",
            defaultMessage: "Need help?",
          })}`}
        </h1>
        <Divider />
        {/* <SelectLanguage /> */}
        <span className="trackingInfoFAQ-orderSelector">
          Please choose your order:{" "}
          <Select
            value={selectedTab}
            style={{ width: 120, borderRadius: "7px", overflow: "hidden" }}
            onChange={setSelectedTab}
            dropdownClassName="trackingInfoFAQ-orderSelector-dropdown"
          >
            <Option value="">My last order</Option>
            {orders?.map((e) => (
              <Option key={e.id} value={e.id}>
                #{e.id}
              </Option>
            ))}
          </Select>
        </span>
      </div>
      <div className="faqAccordionWrap">
        <Collapse accordion>
          {faqData.map((question, i) => (
            <Panel header={question.header || `Question ${i + 1}`} key={i}>
              {question.sections.map((section, i) => (
                <React.Fragment key={i}>
                  <h4>
                    <b>{section.title}</b>
                  </h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: section.text,
                    }}
                  />
                </React.Fragment>
              ))}
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default connect(
  () => ({}),
  (dispatch) => ({
    ...bindActionCreators(customerActions, dispatch),
  }),
)(TrackingInfoFAQ);
