import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Layout as AntLayout, Row, Col, Button, Menu, Typography } from "antd";

import classNames from "classnames";
import MainLogo from "../../../../assets/newLanding/MainLogo";
import DropdownArrow from "../../../../assets/newLanding/DropdownArrow";
import styles from "./header.module.less";
import MobileMenu from "../../../../assets/newLanding/MobileMenu";
import MobileClose from "../../../../assets/newLanding/MobileClose";
import LoginButton from "../LoginButton";

const Header = ({ isMobile, setIsModalVisible, isModalVisible, handleToggleModal }) => {
  const { pathname } = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const [currentKey, setCurrentKey] = useState(pathname);
  const handleClickMenuItem = ({ key }) => {
    key === "SubMenu" && setIsSubMenuOpen((old) => !old);
    key === "get-in-touch" && handleToggleModal();
    setCurrentKey(key);
    key !== "SubMenu" && setIsMenuOpen(false);
  };

  return (
    <>
      <AntLayout.Header
        className={classNames(styles.header, isMenuOpen && isMobile && styles["mobileHeader-Expanded"])}
      >
        {isMobile ? (
          <>
            <Row align="middle" justify="space-between">
              <Col span={2}>
                <Link to="/" onClick={() => handleClickMenuItem({ key: "/" })}>
                  <MainLogo />
                </Link>
              </Col>
              <Col span={2}>
                <Link to="/" onClick={() => setIsMenuOpen((old) => !old)}>
                  {isMenuOpen ? <MobileClose /> : <MobileMenu />}
                </Link>
              </Col>
            </Row>
            <Row align="middle" justify="space-between">
              <div className={classNames(styles.mobileMenu, isMenuOpen && styles["mobileMenu-Expanded"])}>
                <Menu
                  theme="light"
                  mode="vertical"
                  selectedKeys={[pathname]}
                  defaultSelectedKeys={[currentKey]}
                  onClick={handleClickMenuItem}
                  className={styles.navMenu}
                  style={{
                    gap: 14,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    flexGrow: 1,
                    transition: "height 500ms ease-in-out",
                    height: isSubMenuOpen ? "600px" : "304px",
                  }}
                  items={[
                    {
                      label: (
                        <span style={{ display: "flex", alignItems: "center" }}>
                          Business solutions <DropdownArrow size={16} style={{ fill: "white" }} />
                        </span>
                      ),
                      key: "SubMenu",
                      // icon:,
                      // popupClassName: styles.navMenuPopupMobile,
                      // children: [
                      //   { label: <Link to="/same-day-delivery">Same day delivery</Link>, key: "/same-day-delivery" },
                      //   { label: <Link to="/store-to-store">Store to store</Link>, key: "/store-to-store" },
                      //   { label: <Link to="/client-returns">Clients returns</Link>, key: "/client-returns" },
                      // ],
                    },
                    ...(isSubMenuOpen
                      ? [
                          {
                            label: (
                              <Link to="/same-day-delivery" className={styles.dropdownLink}>
                                Same day delivery
                              </Link>
                            ),
                            key: "/same-day-delivery",
                          },
                          {
                            label: (
                              <Link to="/store-to-store" className={styles.dropdownLink}>
                                Store to store
                              </Link>
                            ),
                            key: "/store-to-store",
                          },
                          {
                            label: (
                              <Link to="/client-returns" className={styles.dropdownLink}>
                                Clients returns
                              </Link>
                            ),
                            key: "/client-returns",
                          },
                        ]
                      : []),
                    // { label: <Link to="/booths">Booths</Link>, key: "/booths" },
                    { label: <Link to="/about-us">About Us</Link>, key: "/about-us" },
                    { label: "Get in Touch", key: "get-in-touch", className: styles.getInTouch },
                  ]}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 75,
                  }}
                >
                  <LoginButton />
                </div>
              </div>
            </Row>
          </>
        ) : (
          <Row align="middle" justify="space-between">
            <Col span={2}>
              <Link to="/" onClick={() => handleClickMenuItem({ key: "/" })}>
                <MainLogo />
              </Link>
            </Col>

            <Col flex={1}>
              <Menu
                theme="light"
                mode="horizontal"
                selectedKeys={[pathname]}
                defaultSelectedKeys={[currentKey]}
                onClick={handleClickMenuItem}
                className={styles.navMenu}
                items={[
                  {
                    label: "Business solutions",
                    key: "SubMenu",
                    icon: <DropdownArrow size={16} />,
                    popupClassName: styles.navMenuPopup,
                    children: [
                      { label: <Link to="/same-day-delivery">Same day delivery</Link>, key: "/same-day-delivery" },
                      { label: <Link to="/store-to-store">Store to store</Link>, key: "/store-to-store" },
                      { label: <Link to="/client-returns">Clients returns</Link>, key: "/client-returns" },
                    ],
                  },
                  // { label: <Link to="/booths">Booths</Link>, key: "/booths" },
                  { label: <Link to="/about-us">About Us</Link>, key: "/about-us" },
                  { label: "Get in Touch", key: "get-in-touch", className: styles.getInTouch },
                ]}
              />
            </Col>

            <Col>
              <Typography.Text className={styles.loginHint}>Retailer?</Typography.Text>
              <Button size="large" type="link" className={styles.logInBtn}>
                <Link to="/retailer_login">Log In</Link>
              </Button>
            </Col>
          </Row>
        )}
      </AntLayout.Header>
    </>
  );
};

export default Header;
