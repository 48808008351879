import React, { useState } from "react";
import { Button, Modal, DatePicker, Row, Input } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import type { RangePickerProps } from "antd/es/date-picker";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { IJobDetailsConfirmModal } from "src/types/JobDetails";

function ConfirmOrderActionsModal({ openModal, handleModalSubmit, setOpenModal, loading }: IJobDetailsConfirmModal) {
  const [postponeDate, setPostponeDate] = useState(moment().add(1, "days"));
  const [orderIdConfirm, setorderIdConfirm] = useState<string>();
  const intl = useIntl();
  const isCancelGranted = () => {
    return orderIdConfirm && Number(orderIdConfirm) === openModal?.id;
  };
  const postponeDisabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };

  const isConfirmDisabled = () => {
    if (openModal?.type === "postpone" && !postponeDate) return true;
    if (openModal?.type === "cancelCompleted" && !isCancelGranted()) return true;
    return false;
  };

  const renderModalContent = (type: string) => {
    switch (type) {
      case "postpone":
        return (
          <Row className="options__row">
            <label className="options__row--label"> Postponed delivery date </label>
            <DatePicker
              value={postponeDate}
              disabledDate={postponeDisabledDate}
              allowClear={false}
              onChange={(date, dateString) => {
                setPostponeDate(date!);
              }}
              format={"DD.MM.YYYY"}
            />
          </Row>
        );
      case "cancelCompleted":
        return (
          <Row className="options__row">
            <label className="options__row--label"> Please confirm the order ID </label>
            <Input
              style={{ width: "50%", borderColor: isCancelGranted() ? "#27ae60" : "" }}
              value={orderIdConfirm}
              status={!isCancelGranted() ? "warning" : ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setorderIdConfirm(e.target.value)}
              suffix={isCancelGranted() ? <CheckCircleTwoTone twoToneColor="#27ae60" /> : null}
            />
          </Row>
        );
    }
  };

  return (
    <Modal
      visible={openModal.open}
      title={
        <>
          {intl.formatMessage({
            id: `tabPane.actions.${openModal.type ?? "postpone"}`,
            defaultMessage: "Operate changes on",
          })}
          {intl.formatMessage({ id: "order", defaultMessage: "order" })}
        </>
      }
      onOk={() => handleModalSubmit(openModal?.job!, openModal?.id!, postponeDate.format("DD.MM.YYYY"))}
      onCancel={() => {
        setOpenModal((prevState: any) => ({
          ...prevState,
          open: false,
        }));
      }}
      afterClose={() => {
        setTimeout(() => {
          setOpenModal({
            open: false,
            id: undefined,
            job: undefined,
          });
        }, 500);
      }}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setOpenModal((prevState: any) => ({
              ...prevState,
              open: false,
            }));
          }}
        >
          <FormattedMessage id="tabPane.actions.cancel" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="submit"
          type="primary"
          id="confirm-modal-ok"
          loading={loading}
          onClick={() => handleModalSubmit(openModal?.job!, openModal?.id!, postponeDate.format("DD.MM.YYYY"))}
          disabled={isConfirmDisabled()}
        >
          <FormattedMessage id="tabPane.actions.confirm" defaultMessage="Confirm" />
        </Button>,
      ]}
    >
      <Row>
        <p>
          {intl.formatMessage(
            {
              id: "modal.areYouSureWantTo",
              defaultMessage: "Are you sure you want to",
            },
            {
              operation: `${intl.formatMessage({
                id: `tabPane.actions.${openModal.type ?? "postpone"}`,
                defaultMessage: "Operate changes",
              })}`,

              concern: `${intl.formatMessage({ id: "order", defaultMessage: "order" })}`,
            },
          )}
        </p>
      </Row>
      {renderModalContent(openModal.type!)}
    </Modal>
  );
}

export default ConfirmOrderActionsModal;
