import React from "react";
import { FormattedMessage } from "react-intl";

import { MobileGoogleMapWrapper } from "../MobileGoogleMapWrapper";
import { MobileModalWrapper } from "../../../../../components/AdminPanel/Mobile/MobileModalWrapper";
import PickUpPoint from "../../../DesktopView/components/PickupPoint";

export const OrderDetailsModal = ({ selectedRow, onClose }) => {
  const lat = Number(selectedRow?.pickupPoint?.lat);
  const lng = Number(selectedRow?.pickupPoint?.long);

  return (
    <MobileModalWrapper
      visible={selectedRow}
      title={<FormattedMessage id="map.orderInfo--2" defaultMessage="Order info" />}
      onClose={onClose}
      footer={null}
    >
      <MobileGoogleMapWrapper lat={lat} lng={lng}>
        <PickUpPoint retailer={selectedRow?.retailer} orderInfo={selectedRow?.pickupPoint} lat={lat} lng={lng} />
      </MobileGoogleMapWrapper>
    </MobileModalWrapper>
  );
};
