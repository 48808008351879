const registerPushServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    // console.log(navigator?.serviceWorker);
    if (!navigator?.serviceWorker?.controller?.scriptURL?.includes("/firebase-messaging-sw.js")) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then(function (registration) {
          console.log("Registration successful, scope is:", registration.scope);
          // navigator.serviceWorker.onmessage = function(messageevent) {
          //   console.log(`received data: ${messageevent.data}`);
          // }
          // window.location.reload();
        })
        .catch(function (err) {
          console.log("Service worker registration failed, error:", err);
        });
    }
  }
};

export { registerPushServiceWorker };
