import React, { useEffect, useState } from "react";
import { Menu, Layout, Button } from "antd";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  FileTextOutlined,
  IdcardOutlined,
  CodeSandboxOutlined,
  BarChartOutlined,
  EnvironmentOutlined,
  SolutionOutlined,
  ProfileOutlined,
  TeamOutlined,
  SettingOutlined,
  ApiOutlined,
  MessageOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import RetailerCabinetHeader from "../../components/OrdersWidget/OrdersWidgetHeader";
import "./RetailerLayout.less";
import { useSelector } from "react-redux";
import { actions as retailerActions } from "../../redux/resources/retailer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const { Content, Sider } = Layout;

// helper
// TODO() : separate the logic, move to a helper
if (!Array.prototype.last) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.last = function () {
    return this[this.length - 1];
  };
}

const RetailerLayout = ({ children, getSelfInfoRetailer }) => {
  const intl = useIntl();
  const history = useHistory();
  const isRestricted =
    useSelector((state) => {
      return state?.retailer?.retailerData?.restricted;
    }) || false;
  // state
  const [collapsed, setCollapsed] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(history ? history.location.pathname.split("/").last() : "home");

  // functions
  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    getSelfInfoRetailer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selectedMenu = history.location.pathname.split("/").last();

    setCurrentMenu(selectedMenu);
  }, [setCurrentMenu, history.location.pathname]);

  // menu items
  const menuItems = [
    {
      key: "home",
      icon: <CodeSandboxOutlined />,
      label: (
        <Link to="/retailer_cabinet/home" className="menuItemStyle">
          <FormattedMessage id="page.ordersManagment.tabs.command" defaultMessage="Command Center" />
        </Link>
      ),
    },
    // TODO: Temporarily hiding job feature for reatilers
    // ...[
    //   isRestricted && {
    //     key: "job-management",
    //     icon: <SettingOutlined />,
    //     label: (
    //       <Link to="/retailer_cabinet/jobs" className="menuItemStyle">
    //         <FormattedMessage id="page.jobManagement.title" defaultMessage="Job Management" />
    //       </Link>
    //     ),
    //   },
    // ],
    {
      key: "reports",
      icon: <FileTextOutlined />,
      label: (
        <Link to="/retailer_cabinet/reports" className="menuItemStyle">
          <FormattedMessage id="page.ordersManagment.tabs.reports" defaultMessage="Reports" />
        </Link>
      ),
    },

    {
      key: "insights",
      icon: <BarChartOutlined />,
      label: (
        <Link to="/retailer_cabinet/insights" className="menuItemStyle">
          <FormattedMessage id="page.home.header.insights" defaultMessage="Insights" />
        </Link>
      ),
    },

    {
      key: "tickets",
      icon: <NotificationOutlined />,
      label: (
        <Link to="/retailer_cabinet/tickets" className="menuItemStyle">
          Tickets
        </Link>
      ),
    },

    {
      label: `${intl.formatMessage({ id: "page.home.header.profile", defaultMessage: "Profile" })}`,
      key: "group-profile",
      icon: <IdcardOutlined />,
      children: [
        {
          key: "account",
          icon: <UserOutlined />,
          label: (
            <Link to="/retailer_cabinet/account">
              <FormattedMessage id="page.home.settings.tabs.account" defaultMessage="Account" />
            </Link>
          ),
        },
        {
          key: "pickup-points",
          icon: <EnvironmentOutlined />,
          label: (
            <Link to="/retailer_cabinet/pickup_points">
              <FormattedMessage id="page.pickupPoints.title" defaultMessage="Pickup points" />
            </Link>
          ),
        },

        {
          key: "users_management",
          icon: <TeamOutlined />,
          label: (
            <Link to="/retailer_cabinet/users_management">
              <FormattedMessage id="page.home.settings.tabs.users" defaultMessage="User Management" />
            </Link>
          ),
        },
      ],
    },
    {
      key: "settings",
      icon: <SettingOutlined />,
      label: `${intl.formatMessage({ id: "page.home.settings.tabs.settings", defaultMessage: "Settings" })}`,

      children: [
        {
          key: "sms",
          icon: <MessageOutlined />,
          label: (
            <Link to="/retailer_cabinet/settings/sms">
              <FormattedMessage id="page.home.settings.preferences.heading" defaultMessage="SMS Types" />
            </Link>
          ),
        },
        {
          key: "templates",
          icon: <ProfileOutlined />,
          label: (
            <Link to="/retailer_cabinet/settings/templates">
              <FormattedMessage id="page.home.settings.tabs.sms" defaultMessage="SMS Templates" />
            </Link>
          ),
        },
        {
          key: "api-tokens",
          icon: <ApiOutlined />,
          label: (
            <Link to="/retailer_cabinet/settings/api-tokens">
              <FormattedMessage id="page.home.settings.tabs.api" defaultMessage="API token" />
            </Link>
          ),
        },
      ],
    },
    {
      key: "activity",
      icon: <SolutionOutlined />,
      label: (
        <Link to="/retailer_cabinet/activity_logs" className="menuItemStyle">
          <FormattedMessage id="page.activityLogs.title" defaultMessage="Activity logs" />
        </Link>
      ),
    },
  ];
  const menu = <Menu mode="inline" theme="light" defaultSelectedKeys={[currentMenu]} items={menuItems}></Menu>;

  return (
    <Layout style={{ minHeight: "100vh", maxWidth: "100vw" }}>
      <RetailerCabinetHeader />

      <Layout className="layout" trigger={null} style={{ minHeight: "100vh", maxWidth: "100vw" }}>
        <Sider
          trigger={null}
          className="siderMenuHolderStyle"
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
        >
          {
            <Button
              type="primary"
              onClick={onCollapse}
              className="siderCollapseTrigger"
              style={{ width: collapsed ? "4em" : "12em" }}
            >
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
          }
          {menu}
        </Sider>
        <Content
          className="retailerContentWrapper"
          style={{
            marginLeft: collapsed ? "100px" : "220px",
            overflow: "initial",
            backgroundColor: "transparent !important",
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    ...bindActionCreators(retailerActions, dispatch),
  }),
)(RetailerLayout);
