import { useEffect, useCallback, useState } from "react";
import { omit, isEmpty } from "lodash";
import { notification } from "antd";
import moment from "moment";

import { getQueryParam, updatePageUrl } from "../../../helpers/general";

export const useHooks = ({ location, history, isMobile, paginationData, fetchDeliveryReports, deleteOneReports }) => {
  const getDeliveryReportsQueryParams = useCallback(() => getQueryParam(location.search), [location.search]);
  const [deliverySearchQuery, setDeliverySearchQuery] = useState("driverName");
  const [searchedValue, setSearchedValue] = useState(undefined);
  const [reportStatusQuery, setReportStatusQuery] = useState("all");
  const [reportTypeQuery, setReportTypeQuery] = useState("delivery_report");

  const fetchAllDeliveryReports = useCallback(() => {
    const queryParams = getDeliveryReportsQueryParams();
    const queryWithPage = queryParams.page ? queryParams : { ...queryParams, page: 1, limit: 10 };
    const queryFilters = {
      [deliverySearchQuery]: searchedValue || "",
    };
    if (reportTypeQuery !== "all") {
      if (reportTypeQuery === "pickup_delivery_report") {
        // queryFilters.type = "pickup_report"
        // queryFilters.someKey = "someValue" //TODO add parameter
      } else {
        queryFilters.type = reportTypeQuery;
      }
    }
    if (reportStatusQuery !== "all") {
      queryFilters.status = reportStatusQuery;
    }
    fetchDeliveryReports(
      {},
      {
        query: { ...queryFilters, ...queryWithPage },
      },
    ).catch((error) =>
      notification.error({
        message: "Error",
        description: error.body && error.body.message && error.body.message,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDeliveryReports, getDeliveryReportsQueryParams, reportStatusQuery, reportTypeQuery]);

  useEffect(() => {
    fetchAllDeliveryReports();
  }, [fetchAllDeliveryReports]);

  useEffect(() => {
    updateDeliveryReportsPageUrl({});
    fetchAllDeliveryReports();
    // eslint-disable-next-line
  }, [reportStatusQuery, reportTypeQuery]);

  const updateDeliveryReportsPageUrl = (newQueryParams) => {
    const previousQueryParams = getDeliveryReportsQueryParams();
    updatePageUrl(newQueryParams, previousQueryParams, history, "admin/delivery_reports");
  };

  const onChangePagination = (pagination) => {
    const search = omit(pagination, ["pageSizeOptions", "position", "showSizeChanger", "total", "pageSize", "current"]);
    if (!isEmpty(search)) {
      updateDeliveryReportsPageUrl({
        ...omit(pagination, ["pageSizeOptions", "position", "showSizeChanger", "total", "pageSize", "current"]),
        page: pagination.current,
        limit: pagination.pageSize,
      });
    } else {
      updateDeliveryReportsPageUrl({ page: pagination.current, limit: pagination.pageSize });
    }
  };

  const currentQueryParams = getDeliveryReportsQueryParams();

  const deleteReport = (id) => () => {
    deleteOneReports({ reportId: id })
      .then(() => fetchAllDeliveryReports())
      .catch((error) =>
        notification.error({
          message: "Error",
          description: error?.body?.message,
        }),
      );
  };

  const handleSearchDelivery = async (e) => {
    if (e.target.value) {
      const queryFilters = {};
      if (reportTypeQuery !== "all") {
        queryFilters.type = reportTypeQuery;
      }
      if (reportStatusQuery !== "all") {
        queryFilters.status = reportStatusQuery;
      }
      onChangePagination({
        ...queryFilters,
        [deliverySearchQuery]: e.target.value,
        page: 1,
      });
    } else {
      onChangePagination({ [deliverySearchQuery]: "" });
    }
  };

  const onChangeSearchFilter = (value) => {
    onChangePagination({});
    setSearchedValue("");
    setDeliverySearchQuery(value);
  };

  const handleSetDates = (dates, momentDates) => {
    if (dates) {
      const fromDate = moment(momentDates[0]).startOf("day").toISOString(true).substring(0, 23);
      const toDate = moment(momentDates[1]).endOf("day").toISOString(true).substring(0, 23);
      // toDate.setUTCHours(23, 59, 59, 999);
      // toDate = toDate.toISOString();
      onChangePagination({ from: fromDate, to: toDate });
    } else {
      onChangePagination({ from: "", to: "" });
    }
  };

  const disabledTomorrowAndFuture = (current) => current > new Date();

  const searchFilter = [
    { text: "Driver name", value: "driverName" },
    { text: "Order Id", value: "orderId" },
    { text: "Tracking Id", value: "trackingId" },
  ];

  const paginationConfig = {
    pageSize: currentQueryParams?.limit || 10,
    total: paginationData?.totalItems || 50,
    current: paginationData?.currentPage || 1,
    pageSizeOptions: isMobile ? [5, 10, 20] : [10, 20, 30, 50],
    showSizeChanger: true,
    position: ["bottomRight"],
  };

  return {
    searchedValue,
    searchFilter,
    paginationConfig,
    disabledTomorrowAndFuture,
    setSearchedValue,
    onChangeSearchFilter,
    onChangePagination,
    handleSearchDelivery,
    handleSetDates,
    deleteReport,
    reportStatusQuery,
    setReportStatusQuery,
    reportTypeQuery,
    setReportTypeQuery,
  };
};
