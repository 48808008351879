import React, { useLayoutEffect, useState } from "react";
import { Steps } from "antd";

import bbLogo from "../../../assets/bbboltLogo.png";
import "./registrationSteps.less";
import HelpLink from "../../HelpLink";
import { SelectLanguage } from "../../SelectLanguage";
import { Link } from "react-router-dom";

const { Step } = Steps;

const useWindowSize = () => {
  const [windowWidth, setWindowWidth] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return windowWidth;
};

const RegistrationSteps = ({ steps, currentStep }) => {
  const windowWidth = useWindowSize();

  return (
    <div className="registrationWrapper">
      <div className="stepsContainerWrapper">
        <div className="bblogoHelplinkWrapper">
          <Link to="/">
            {" "}
            <img src={bbLogo} alt="BB logo" className="registrationBBLogo" />
          </Link>
          {windowWidth < 1024 ? <HelpLink /> : <SelectLanguage />}
        </div>
        <Steps
          className="stepsWrapper"
          current={currentStep}
          direction={windowWidth < 1024 ? "gorizontal" : "vertical"}
          size={windowWidth < 1024 && "small"}
        >
          {steps.map((item) => (
            <Step key={item.title} className="stepStyle" title={item.title} />
          ))}
        </Steps>
        {windowWidth < 1024 && (
          <p style={{ textAlign: "center", marginTop: "15px", marginBottom: "0", fontWeight: "bold" }}>
            {steps[currentStep].additionalTitle}
          </p>
        )}
      </div>
      <div className="stepsContentWrapper">
        {windowWidth >= 1024 && <HelpLink />}
        <div className="stepsContent">{steps[currentStep].content}</div>
      </div>
    </div>
  );
};

export default RegistrationSteps;
