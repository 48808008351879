import React from "react";

import "./customerDetails.less";

const CustomerDetails = ({ orderInfo }) => (
  <div className="customerInfoWrapper">
    <p className="customerInfoTitle"> Customer: </p>
    <p className="customerInfoItem">
      Name:{" "}
      <span>{orderInfo.customerInfo.name ? orderInfo.customerInfo.name : <span className="noData">No name</span>}</span>
    </p>
    <p className="customerInfoItem">
      Email:{" "}
      <span>
        {orderInfo.customerInfo.email ? orderInfo.customerInfo.email : <span className="noData">No email</span>}
      </span>
    </p>
    <p className="customerInfoItem">
      Phone number:{" "}
      <span>
        {orderInfo.customer.phoneNumber ? (
          orderInfo.customer.phoneNumber
        ) : (
          <span className="noData">No phone number</span>
        )}
      </span>
    </p>
    <p className="customerInfoItem">
      Lat and lng:{" "}
      <span>
        {orderInfo.deliveryPoint.lat ? (
          `lat: ${orderInfo.deliveryPoint.lat} long: ${orderInfo.deliveryPoint.long}`
        ) : (
          <span className="noData">No lat and long found</span>
        )}
      </span>
    </p>
    {[
      {
        title: "City",
        value: orderInfo.customerInfo?.initialAddress?.city,
      },
      {
        title: "Zone",
        value: orderInfo.customerInfo?.initialAddress?.zone,
      },
      {
        title: "Street",
        value: orderInfo.customerInfo?.initialAddress?.street,
      },
      {
        title: "Customer adress",
        value: orderInfo.customerInfo?.initialAddress?.address,
      },
      {
        title: "Building",
        value: orderInfo.customerInfo?.initialAddress?.building,
      },
    ]
      .filter(({ value }) => value)
      .map(({ title, value }) => (
        <p className="customerInfoItem">
          {title}: <span>{value}</span>
        </p>
      ))}
    <p className="customerInfoItem">
      Amount Cash On Delivery:{" "}
      <span>{orderInfo.cashAmount ? orderInfo.cashAmount : <span className="noData">No cash amount</span>}</span>
    </p>
  </div>
);

export default CustomerDetails;
