import { notification } from "antd";

export const useTicketDetails = (
  updateTicket: any,
  updateOrderTicketState: any,
) => {

  const onTicketUpdate = async ({ tickePayload, orderId, jobId }: Record<any, any>) => {
    try {
      await updateTicket({ ticketId: tickePayload.id, status: tickePayload.status });
      onUpdateOrderTicketState({ ticket: tickePayload, orderId, jobId });
      notification.success({ message: "Ticket updated successfully" });
    }
    catch {
      notification.error({ message: "OOPs! Unable to updated tickets" });
    }
  }

  const onUpdateOrderTicketState = async (payload: Record<any, any>) => {
    try {
      await updateOrderTicketState(payload);
    }
    catch {
      notification.error({ message: "OOPs! Unable to updated state" });
    }
  }


  return {
    onTicketUpdate,
    onUpdateOrderTicketState
  }
}