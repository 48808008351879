import { Button, Row } from "antd";
import React from "react";
import CustomDivider from "../../../components/Divider";

export default function ErrorPage() {
  return (
    <div
      className="pageWrapper"
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "30%",
      }}
    >
      <h2 className="pageTitle">Page broken</h2>
      <CustomDivider />
      Something went wrong, please refresh !
      <br />
      If this error is consistent, please report it !
      <br />
      <Row style={{ justifyContent: "space-around" }}>
        <Button href="/" type="primary">
          Return Home
        </Button>
        <Button
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh page
        </Button>
      </Row>
    </div>
  );
}
