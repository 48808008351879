import React,{useState} from "react";
import { FormattedMessage } from "react-intl";

import { MobileTable } from "../../../../../components/AdminPanel/Mobile/MobileTable";
import { MobileTableExpand } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableExpand";
import { MobileTableRows } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableRows";
import { MobileTableCollapseRows } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableCollapseRows";
import { getMobileBadgeStatus, NO_INFO } from "../../../../../components/AdminPanel/Mobile/helpers";

export const OrdersTable = ({ data }) => {
  const [expandedId, setExpandedId] = useState(-1);
  const handleSetExpandedId = (id) => {
    setExpandedId(id);
  };
  return (
    <MobileTable>
      {data?.map((order) => (
        <div className="table-item" key={order.id}>
          <MobileTableRows
            rows={[
              { label: "Order ID", value: order.id },
              { label: "Customer Name", value: order.customerInfo?.name || NO_INFO },
              { label: "Customer Phone", value: order.customer?.phoneNumber || NO_INFO },
            ]}
          />
          <MobileTableExpand setIsPanelCollapsed={() => {}} id={order.id} expanded={expandedId===order.id} handleSetExpandedId={handleSetExpandedId}>
            <MobileTableCollapseRows
              rows={[
                { label: "Package", value: order.packages[0]?.no || NO_INFO },
                { label: "Tracking ID", value: order.trackingId || NO_INFO },
                { label: "Retailer Name", value: order.retailer?.name || NO_INFO },
                {
                  label: "Location Shared",
                  value: order?.locationShared ? (
                    <FormattedMessage id="YES" defaultMessage="YES" />
                  ) : (
                    <FormattedMessage id="NO" defaultMessage="NO" />
                  ),
                },
                { label: "Status", value: getMobileBadgeStatus(order?.status) || NO_INFO },
              ]}
            />
          </MobileTableExpand>
        </div>
      ))}
    </MobileTable>
  );
};
