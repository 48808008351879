import React, { useState } from "react";
import { Button } from "antd";

import { MobileSelect } from "../../../../../components/AdminPanel/Mobile/MobileSelect";
import { MobileModalWrapper } from "../../../../../components/AdminPanel/Mobile/MobileModalWrapper";

export const ReportModal = ({ visible, retailersList, generateReport, onClose }) => {
  const [selectedRetailer, setRetailer] = useState(null);

  const generateEnvReport = () => {
    generateReport(selectedRetailer);
  };

  const retailerOptions = [{ text: "All", value: null }].concat(
    retailersList?.map(({ id, name }) => ({ text: name, value: id })),
  );

  return (
    <MobileModalWrapper title="Generate Report" visible={visible} onClose={onClose} footer={null}>
      <div className="env-stats-report-modal-body">
        <MobileSelect options={retailerOptions} defaultValue={null} onSelect={setRetailer} />
        <Button className="mobile-btn mobile-btn--yellow" onClick={generateEnvReport}>
          Generate
        </Button>
        <Button className="mobile-btn mobile-btn--purple" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </MobileModalWrapper>
  );
};
