import React, { useState } from "react";
import { useIntl } from "react-intl";
import { InputNumber, Form, Input } from "antd";

const { Item } = Form;
const FORBIDDEN_VALUES = [null, undefined, ""];

function LocationInputForm({
  form,
  disabled,
  customLocation,
  setCustomLocation,
  setIsLoading,
  setGeolocationResponse,
  getMapData,
}) {
  const intl = useIntl();
  const [addressSearch, setAddressSearch] = useState("");

  const fields = [
    {
      name: ["lat"],
      value: customLocation.lat,
    },
    {
      name: ["long"],
      value: customLocation.long,
    },
  ];

  const handleSelect = () => {
    getMapData({ address: addressSearch }, "address").then((data) => {
      if (data) {
        setAddressSearch(data.formatted_address);
        setCustomLocation({ lat: data.lat, long: data.long });
        setGeolocationResponse({
          address: data.formatted_address,
          isError: false,
        });
      }
    });
  };

  const changeLocation = (coord) => (value) => {
    setCustomLocation((prevState) => ({
      ...prevState,
      [coord]: value,
    }));
  };

  const handleSetAddressSearch = (e) => {
    setAddressSearch(e.target.value);
  };

  const validateForm = async () => {
    const { lat, long } = form.getFieldsValue(true);
    if (FORBIDDEN_VALUES.includes(lat) || FORBIDDEN_VALUES.includes(long)) {
      return;
    }
    setIsLoading(true);

    const res = await getMapData({ lat, long }, "coords");

    if (!res) {
      setGeolocationResponse({
        address: "",
        isError: true,
      });
    } else {
      setGeolocationResponse({
        address: res.address,
        isError: false,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Input.Search value={addressSearch} onChange={handleSetAddressSearch} onSearch={handleSelect} />

      <Form
        form={form}
        fields={fields}
        layout="vertical"
        name="customLocation"
        requiredMark
        onValuesChange={validateForm}
      >
        <div className="inputsLatLongWrapper">
          <Item
            label={`${intl.formatMessage({ id: "modal.latitude", defaultMessage: "Latitude" })}`}
            name="lat"
            requiredMark
            rules={[
              {
                required: true,
                message: `${intl.formatMessage({
                  id: "error.requiredLatitude",
                  defaultMessage: "Latitude is required",
                })}`,
              },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              disabled={disabled}
              size="large"
              style={{ width: "140px" }}
              min={-90}
              max={90}
              value={customLocation.lat}
              placeholder={`${intl.formatMessage({ id: "modal.latitude", defaultMessage: "Latitude" })}`}
              onChange={changeLocation("lat")}
            />
          </Item>
          <Item
            label={`${intl.formatMessage({ id: "modal.longitude", defaultMessage: "Longitude" })}`}
            name="long"
            requiredMark
            rules={[
              {
                required: true,
                message: `${intl.formatMessage({
                  id: "error.requiredLongitude",
                  defaultMessage: "Longitude is required",
                })}`,
              },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              disabled={disabled}
              size="large"
              style={{ width: "140px" }}
              min={-180}
              max={180}
              value={customLocation.long}
              placeholder={`${intl.formatMessage({ id: "modal.longitude", defaultMessage: "Longitude" })}`}
              onChange={changeLocation("long")}
            />
          </Item>
        </div>
      </Form>
    </>
  );
}

export default LocationInputForm;
