import React from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, Form, Input, notification, Row, Layout, Col, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";
import styles from "../NewLanding/pages/SignInRetailerPage/signInRetailerPage.module.less";
import RetailerTablet from "../../assets/newLanding/auth/RetailerTablet.png";

import { actions as adminActions } from "../../redux/resources/admin";
import "./AdminLogin.less";
import { getUserTenant, setUserTenant } from "../../helpers/authStorage";
import MainLogo from "../../assets/newLanding/MainLogo";

import { actions as adminTenantsActions } from "../../redux/resources/adminTenants";

const AdminLogin = ({ loginAdmin, history }) => {
  const intl = useIntl();

  const { getOwnAdminTenants } = adminTenantsActions;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const openErrorNotification = (error) => {
    notification.error({
      message: "Error",
      description: error.body && error.body.message && error.body.message,
    });
  };
  const checkTenantSettings = async () => {
    if (!getUserTenant()) {
      let { body } = await dispatch(getOwnAdminTenants());
      setUserTenant(body?.[0]?.name);
      window.location.href = "/admin";
    } else {
      history.push("/admin");
    }
  };
  const onFinish = (values) => {
    const formattedFields = { ...values };
    formattedFields.email = formattedFields.email.toLowerCase();
    loginAdmin({ ...formattedFields })
      // .then(response=>
      // setloginMessage(response.body.message))
      .then(checkTenantSettings)
      .catch((error) => openErrorNotification(error));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const title = `${intl.formatMessage({ id: "delivery", defaultMessage: "Delivery company" })}`;
  return (
    <Layout className={styles.signUpWrap}>
      <Row>
        <Col span={12} md={24} lg={12} className={styles.promoSection}>
          <Row gutter={[0, 56]}>
            <Col span={23} offset={1}>
              <Link to="/">
                <MainLogo />
              </Link>
            </Col>

            <Col span={21} md={24} offset={3}>
              <Typography.Title level={2} className={styles.promoSectionTitle}>
                {title} <br />
                <Typography.Text className={styles.promoSectionSubtitle}>
                  <FormattedMessage id="page.login.login" defaultMessage="Log In" />
                </Typography.Text>
              </Typography.Title>
              <div className={styles.tablet_img}>
                <img src={RetailerTablet} style={{ width: 106, height: 207, zIndex: 1 }} alt="imageForTablet" />
              </div>{" "}
            </Col>
          </Row>
        </Col>

        <Col span={12} md={24} lg={12} className={styles.mainSection}>
          <Row style={{ height: 80, paddingBottom: 36 }} justify="end">
            <Col
              span={8}
              style={{ display: "flex", alignItems: "end", fontSize: 14, fontWeight: 300, lineHeight: "18px" }}
            >
              {/* You don't have a company account ? */}
            </Col>
            <Col
              span={4}
              style={{
                display: "flex",
                alignItems: "end",
                fontSize: 16,
                fontWeight: 700,
                lineHeight: "24px",
                color: "#0A0A0B",
                justifyContent: "end",
                paddingRight: 50,
              }}
            >
              {/* <Link
                to="/signup"
                style={{
                  color: "#0A0A0B",
                }}
              >
                <FormattedMessage id="page.login.signup" defaultMessage="Sign up" />
              </Link> */}
            </Col>
          </Row>
          <Row align="middle">
            <Col span={12} offset={6}>
              <div style={{ marginTop: 250, transform: "translate(-12%, -50%)" }}>
                <Form
                  name="adminLogin"
                  form={form}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  {/* <TenantSelector /> */}
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Email is not valid!",
                      },
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Admin email"
                      className="formItem"
                      prefix={<span className="prefixIconYellow">@</span>}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      className="formItem "
                      prefix={<LockOutlined className="prefixIconYellow" />}
                      placeholder="Password"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button className="loginButton" type="primary" htmlType="submit">
                      Log In
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default connect(null, (dispatch) => ({
  ...bindActionCreators(adminActions, dispatch),
}))(AdminLogin);
