import React, { useState, useEffect, useCallback } from "react";
import { message, notification } from "antd";
import { validPhoneNumber, validTrackingID, validOrderID } from "src/constants/searchRegex.tsx";

import { customFetch } from "../../../../utils/customFetch";
import { API_URL } from "../../../../constants/config";
import {
  ALL_ORDERS_TAB_QUERY,
  CAN_BE_ASSIGNED_STATUSES,
  COMPLETED_STATUSES,
  WITHOUT_COMPLETED_STATUSES,
  POSTPONED_STATUSES,
  CANCELED_STATUSES,
  RETURN_TO_SHIPPER_STATUSES,
  DELETED_STATUSES,
  CAN_BE_ASSIGNED_TAB_QUERY,
} from "../../../../constants/query";

import { map, uniqueId } from "lodash";
import { useIntl } from "react-intl";
import { getAuthData, getUserTenant, updateRefreshToken } from "../../../../helpers/authStorage";
import { getJobStatusQuery } from "../../../../helpers/formatStatus";
import { allowedMoveToJobStatus } from "src/constants/jobsStatus";

import errorLogger from "../../../../utils/errorLogging/error-logger";

export const useHooks = ({
  fetchStoreSelection,
  fetchOrderHistoryAdminOrder,
  onFetchAllOrders,
  onChangeLocationAdminOrder,
  onChangeQueryParams,
  saveOrders,
  isAdminOrders,
  userURL,
  tablesView,
  setCurrentTableViews,
  filteredRetailer,
  getJobsByStatusAdminOrder,
  fetchRetailerStats,
  ordersUploading,
}) => {
  const [downloadedOrders, setDownloadedOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedTables, setSelectedTables] = useState(["1"]);
  const [orderSearchQuery, setOrderSearchQuery] = useState("orderId");
  const [searchedValue, setSearchedValue] = useState(undefined);
  const [isReportsModalOpen, setIsReportsModalOpen] = useState(false);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

  const [isOrdersModaOpen, setIsOrdersModalOpen] = useState(false);
  const [isOrdersDraggerOpen, setIsOrdersDraggerOpen] = useState(false);
  const [isOrdersActionModalOpen, setOrdersActionModalOpen] = useState({ id: "", open: false });

  // if undefined we won't launch a request
  const [statusOrdersFilter, setStatusOrdersFilter] = useState(undefined);

  const [historyArray, setHistoryArray] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState();
  const [ViewSettingOpen, setOpenViewSetting] = useState(false);
  const [searchInputErrors, setSearchInputErrors] = useState({ type: "", err: "" });

  const [vueColumns, setVueColumns] = useState({
    all: false,
    ticket: false,
    tracking: false,
    phone: false,
    updatedAt: false,
    uploadedAt: false,
    postponedTo: false,
    retailer: false,
    zone: true,
    address: true,
    reports: false,
  });
  const intl = useIntl();

  const filterOrders = (ids, orders) => {
    if (isOrdersActionModalOpen.id === "add") {
      const res = orders.filter(
        ({ id, jobId, status }) => ids.includes(id) && !jobId && CAN_BE_ASSIGNED_STATUSES.includes(status),
      );
      return res.map((el) => el.id);
    }
  };
  const onSelectRows = (rows) => {
    setSelectedOrders(rows);
  };

  const resetModalState = () => {
    setOrdersActionModalOpen({ id: "", open: false }); // close modal
  };

  useEffect(() => {
    if (isOrdersActionModalOpen.open === false) {
      setSelectedOrders([]); // clear the selection
    } else {
      fetchJobsByStatus(allowedMoveToJobStatus);
    }
    // eslint-disable-next-line
  }, [isOrdersActionModalOpen]);

  const fetchJobsByStatus = async (status) => {
    try {
      await getJobsByStatusAdminOrder({}, { query: getJobStatusQuery(status) });
    } catch (error) {
      notification.error({
        message: "Error",
        content: "An error has occured while fetch Incomplete Jobs",
      });
    }
  };

  useEffect(() => {
    if (tablesView?.ordersView) {
      let currentView = vueColumns;
      currentView = { ...currentView, ...tablesView.ordersView };
      setVueColumns(currentView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablesView]);

  // restore the saved filters
  useEffect(() => {
    try {
      const items = JSON.parse(localStorage.getItem("status_orders"));
      if (items) {
        setStatusOrdersFilter(items);
      } else {
        setStatusOrdersFilter([]);
      }
    } catch (e) {
      setStatusOrdersFilter([]);
    }
  }, []);

  // save current filters on change
  useEffect(() => {
    localStorage.setItem("status_orders", JSON.stringify(statusOrdersFilter));
  }, [statusOrdersFilter]);

  const saveRetailerView = () => {
    setCurrentTableViews({ tablesView: { ordersView: vueColumns } })
      .then(() => {})
      .catch((err) => {
        console.error(err);
      });
  };
  //
  const getData = async (url = "") => {
    const response = await customFetch(url, {
      method: "GET",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${getAuthData().accessToken}`,
        Tenant: getUserTenant(),
        contentType: "application/pdf",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    return response.blob();
  };

  const downloadOrdersBarcodes = async () => {
    try {
      const endPoint = isAdminOrders ? "admin/orders/receipts" : "retailers/self/orders/receipts";
      const blob = await getData(`${API_URL}/${endPoint}?${generateBarcodeLink()}`);

      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob], { type: "application/pdf" }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `receipts_${Date.now()}`);
      document.body.appendChild(link);
      // Force download from link
      link.click();
      // Clean up to remove the link
      link.parentNode.removeChild(link);
    } catch (error) {
      notification.error({
        message: "Error",
        content: "Something went wrong while downloading the receipts file.",
      });
    }
  };

  const onFetchAllOrdersWrap = (args) => {
    onFetchAllOrders(args);
    setSelectedStatuses(args?.status);
  };

  const handleStatusChoices = (event) => {
    setStatusOrdersFilter(event);
    // reset the predefined view to empty if no status exist
    if (!event.length) handleSelectedTables(undefined);
  };

  const removeStatus = (status) => {
    const newStatus = statusOrdersFilter.filter((el) => el !== status);
    setStatusOrdersFilter(newStatus);
    // reset the predefined view to empty if no status exist
    if (!newStatus.length) handleSelectedTables(undefined);
  };

  const handleVueColumns = (key) => {
    let currentState = vueColumns;

    if (key === "all") {
      const isAll = currentState[key];
      currentState = {
        all: !isAll,
        // notes: !isAll,
        tracking: !isAll,
        phone: !isAll,
        updatedAt: !isAll,
        postponedTo: !isAll,
        uploadedAt: !isAll,
        retailer: !isAll,
        reports: !isAll,
      };
    } else if (key !== "save") {
      currentState[key] = !currentState[key];
    }
    setVueColumns({ ...currentState });
  };

  const fetchAccordingToTab = useCallback(() => {
    if (!isOrdersDraggerOpen && statusOrdersFilter) {
      let statusReq;
      if (!statusOrdersFilter?.length) {
        statusReq = ALL_ORDERS_TAB_QUERY;
        onFetchAllOrdersWrap({
          status: statusReq,
          fetchAll: true,
        });
      } else if (statusOrdersFilter?.includes("return_completed")) {
        const filteredStatus = [
          ...statusOrdersFilter.filter((status) => status !== "return_completed" && status !== "can_be_assigned"),
          "completed",
        ];
        statusReq = filteredStatus.join("&status=");
        onFetchAllOrdersWrap({
          status: statusReq,
          fetchAll: true,
          isReturn: statusOrdersFilter?.includes("return_completed"),
        });
      } else if (statusOrdersFilter?.includes("can_be_assigned")) {
        statusReq = statusOrdersFilter.filter((status) => status !== "can_be_assigned").join("&status=");
        onFetchAllOrdersWrap({
          status: statusReq === "" ? CAN_BE_ASSIGNED_TAB_QUERY : statusReq,
          withoutJobs: statusOrdersFilter?.includes("can_be_assigned"),
          canBeAssigned: statusOrdersFilter?.includes("can_be_assigned"),
        });
      } else {
        statusReq = statusOrdersFilter.filter((status) => status !== "can_be_assigned").join("&status=");
        onFetchAllOrdersWrap({
          status: statusReq,
          fetchAll: true,
        });
      }

      if (!isAdminOrders) fetchRetailerStats(); // to refresh dashboard stats , for retailers only
    }
    // eslint-disable-next-line
  }, [selectedTab, onFetchAllOrders, statusOrdersFilter, ordersUploading]);

  useEffect(() => {
    fetchAccordingToTab();
  }, [fetchAccordingToTab]);

  useEffect(() => {
    if ((isOrdersModaOpen || isOrdersDraggerOpen) && !isAdminOrders) fetchStoreSelection();
    // eslint-disable-next-line
  }, [isOrdersModaOpen, isOrdersDraggerOpen]);

  useEffect(() => {
    if (fetchStoreSelection) {
      fetchStoreSelection();
    }
    // eslint-disable-next-line
  }, []);

  const draggerProps = {
    name: "orders",
    multiple: false,
    method: "post",
    headers: {
      Authorization: `Bearer ${getAuthData().accessToken}`,
      Tenant: getUserTenant(),
    },
    /* 
    By uncommenting this function, 
    The processing of errors in the `onChange` function wont be lanched
    onError: (err) => {
      fetchAccordingToTab();
      notification.error({
        message: "Error",
        description: "Please try again",
      });
    },*/
    action: `${API_URL}${userURL}`,
    showUploadList: false,
    beforeUpload(file, filelist) {
      console.log("beforeUpload", file);
    },
    onChange(info) {
      const { status } = info.file;
      // eslint-disable-next-line no-empty
      if (status !== "uploading") {
      }
      if (status === "done") {
        const downloadedOrders = info.file.response.length;
        setDownloadedOrders(map(info.file.response, (el) => ({ ...el, id: uniqueId() })));
        setSelectedTab(2);
        message.success(
          `${downloadedOrders} ${intl.formatMessage({
            id: "page.ordersManagment.ordersUploaded",
            defaultMessage: "orders uploaded successfully.",
          })}`,
        );
      } else if (status === "error") {
        if (info.file?.response && Array.isArray(info.file?.response)) {
          message.error(
            <>
              <p className="errorMessageHolderTitle">
                {intl.formatMessage({
                  id: "page.ordersManagment.ordersRejected",
                  defaultMessage: "orders are not uploaded.",
                })}
              </p>

              {info.file.response?.map((err) => {
                if (err?.errors?.length > 0) {
                  return (
                    <div className="errorMessageContainer" key={`${err.row}`}>
                      {err.errors.map((error) => {
                        return (
                          <p className="errorMessageLine" key={`${err.row}-${error.property}`}>
                            {`${intl.formatMessage({
                              id: "row",
                              defaultMessage: "Row",
                            })} ${err.row}. ${intl.formatMessage({
                              id: "wrong",
                              defaultMessage: "Wrong",
                            })} ${error.property}.`}
                          </p>
                        );
                      })}
                    </div>
                  );
                }
                return (
                  <p className="errorMessageLine" key={`${err.row}`}>
                    {`${intl.formatMessage({
                      id: "row",
                      defaultMessage: "Row",
                    })} ${err.row}. ${intl.formatMessage({
                      id: "error",
                      defaultMessage: "Error",
                    })} : ${err.error}.`}
                  </p>
                );
              })}
            </>,
          );
        } else {
          // in case there is not a clear response from the server
          notification.error({
            message: "Error",
            description: "Please try again",
          });
        }

        fetchAccordingToTab();
      }
    },
  };
  const fetchOrderHistory = ({ orderId }) => {
    fetchOrderHistoryAdminOrder({ orderId }).then(({ body }) => {
      setHistoryArray(body);
    });
  };
  const deleteLocal = (...ids) => {
    setDownloadedOrders((prevState) => {
      const orders = [...prevState];
      return orders.filter((el) => !ids.includes(el.id) && el);
    });
  };

  const addLocationToLocal = async ({ selectedId, lat, long, old, address }) => {
    if (!old) {
      setDownloadedOrders((prevState) => {
        const copied = [...prevState];
        const searchedOrder = copied.find(({ id }) => id === selectedId);
        searchedOrder.deliveryPoint.lat = lat;
        searchedOrder.deliveryPoint.long = long;
        return copied;
      });
    } else {
      await onChangeLocationAdminOrder({ orderId: selectedId, lat, long, address }).catch((err) => {
        errorLogger.report(`changing location for ${selectedId} ${err} `);
      });
      fetchAccordingToTab();
    }
  };

  const searchRetailer = async (e) => {
    if (e?.target?.value) {
      onChangeQueryParams({ [orderSearchQuery]: e.target.value, page: 1 });
    } else {
      onChangeQueryParams({});
    }
  };

  const onChangeSearchFilter = (value) => {
    onChangeQueryParams({});
    setSearchedValue("");
    setOrderSearchQuery(value);
  };

  const onUploadOrdersManually = async (orders, type, templateId, retailerId) => {
    try {
      if (isAdminOrders) {
        await saveOrders(orders, retailerId);
      } else {
        await saveOrders(orders, type, templateId);
      }
    } catch (e) {
      notification.warn({ message: "There was an error while uploading orders, re-trying !" });
      await updateRefreshToken();
      if (isAdminOrders) {
        await saveOrders(orders, retailerId);
      } else {
        await saveOrders(orders, type, templateId);
      }
    }
  };

  const onUploadOrders = async (...args) => {
    const [orders, type, template, retailerId] = args;

    try {
      if (isAdminOrders) {
        await saveOrders(orders, retailerId);
      } else {
        await saveOrders(orders, type, template);
      }
    } catch (e) {
      notification.warn({ message: "There was an error while uploading orders, re-trying !" });
      await updateRefreshToken();
      if (isAdminOrders) {
        await saveOrders(orders, retailerId);
      } else {
        await saveOrders(orders, type, template);
      }
    }
  };

  const onOpenOrdersModal = (bool) => {
    setIsOrdersModalOpen(bool);
  };

  const onOpenOrdersDragger = (bool) => {
    setIsOrdersDraggerOpen(bool);
  };
  const downloadTemplate = async () => {
    try {
      const response = await customFetch(
        isAdminOrders ? `${API_URL}/admin/orders/template` : `${API_URL}/retailers/orders/template`,
        {
          method: "GET",
          cache: "no-cache",
          headers: {
            Authorization: `Bearer ${getAuthData().accessToken}`,
            Tenant: getUserTenant(),
          },
          redirect: "follow",
          referrerPolicy: "no-referrer",
        },
      );
      if (!response.ok) {
        const data = await response.json();

        errorLogger.report(`downloadTemplate \n ${data.message ? data.message : "Error while generating template"}`);

        notification.error({
          message: "Error",
          description: data.message ? data.message : "Error while generating template",
        });
        return;
      }
      const blob = await response.blob();
      const newBlob = new Blob([blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `order-sheet-template.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(blob);
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.message ? error.message : "Error wile generating template",
      });
    }
  };

  const clearOrderParameters = () => {
    onChangeQueryParams({});
    setSearchedValue("");
    setOrderSearchQuery("");
  };
  /*  */
  const handleSelectedTables = (values) => {
    setSelectedTables(values);
    switch (values) {
      case "1":
        setStatusOrdersFilter(WITHOUT_COMPLETED_STATUSES);
        break;
      case "3":
        setStatusOrdersFilter(POSTPONED_STATUSES);
        break;
      case "5":
        setStatusOrdersFilter(COMPLETED_STATUSES);
        break;
      case "6":
        setStatusOrdersFilter([...CAN_BE_ASSIGNED_STATUSES, "can_be_assigned"]);
        break;
      case "7":
        setStatusOrdersFilter(CANCELED_STATUSES);
        break;
      case "8":
        setStatusOrdersFilter(RETURN_TO_SHIPPER_STATUSES);
        break;
      case "9":
        setStatusOrdersFilter(DELETED_STATUSES);
        break;
      case "10":
        setStatusOrdersFilter(["pickup_failed"]);
        break;
      case "11":
        setStatusOrdersFilter(["new"]);
        break;
      default:
        setStatusOrdersFilter([]);
        break;
    }

    setOrderSearchQuery("orderId");
    onChangeQueryParams({});
    setSearchedValue("");
    setSelectedTab(Number(String(values)));
  };
  /* validate the search value format after each change */
  const handleChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    let message = "";

    switch (orderSearchQuery) {
      case "trackingId":
        message = validTrackingID.test(value)
          ? ""
          : ` ${value} : ${intl.formatMessage(
              {
                id: "input.searchOrder.error",
                defaultMessage: "is not a valid tracking ID",
              },
              { searchOption: `${intl.formatMessage({ id: "tabPane.trackingID", defaultMessage: "tracking ID" })}` },
            )}`;
        break;
      case "orderId":
        message = validOrderID.test(value)
          ? ""
          : ` ${value} : ${intl.formatMessage(
              {
                id: "input.searchOrder.error",
                defaultMessage: "is not a valid order ID",
              },

              { searchOption: `${intl.formatMessage({ id: "tabPane.orderID", defaultMessage: "order ID" })}` },
            )}`;
        break;
      case "customerPhone":
        //TODO : add validation later
        message = validPhoneNumber.test(value)
          ? ""
          : ` ${value} : ${intl.formatMessage(
              {
                id: "input.searchOrder.error",
                defaultMessage: "is not a valid phone number",
              },
              {
                searchOption: `${intl.formatMessage({
                  id: "tabPane.customerPhone",
                  defaultMessage: "phone number",
                })}`,
              },
            )}`;
        break;
      case "customerName":
      case "address":
        //TODO : add validation later
        message = "";
        break;
      default:
        break;
    }

    setSearchInputErrors({ name: orderSearchQuery, err: message });
  };

  const generateBarcodeLink = () => {
    if (selectedOrders?.length) {
      return `orderIds=${selectedOrders}`;
    }
    if (orderSearchQuery && searchedValue) {
      return `${orderSearchQuery}=${searchedValue}${`&status=${selectedStatuses}`}`;
    }
    if (isAdminOrders && filteredRetailer) {
      return `retailerName=${filteredRetailer}${`&status=${selectedStatuses}`}`;
    }
    if (selectedStatuses) {
      return `${`status=${selectedStatuses}`}`;
    }
    return `status=new`;
  };

  const handleOpenViewSetting = (flag) => {
    setOpenViewSetting(flag);
  };

  return {
    handleSelectedTables,
    handleChange,
    intl,
    fetchOrderHistory,
    addLocationToLocal,
    selectedTab,
    clearOrderParameters,
    searchedValue,
    selectedStatuses,
    selectedTables,
    searchInputErrors,
    fetchAccordingToTab,
    onOpenOrdersModal,
    setIsOrdersModalOpen,

    historyArray,
    orderSearchQuery,
    downloadTemplate,
    draggerProps,
    searchRetailer,
    setIsReportsModalOpen,
    setIsFiltersModalOpen,
    setSearchedValue,

    onChangeSearchFilter,
    setOrderSearchQuery,
    downloadedOrders,
    onUploadOrders,
    deleteLocal,
    setSelectedTab,
    isReportsModalOpen,
    isOrdersModaOpen,
    isFiltersModalOpen,
    onUploadOrdersManually,

    setDownloadedOrders,

    downloadOrdersBarcodes,
    onSelectRows,
    selectedOrders,
    handleStatusChoices,
    statusOrdersFilter,
    removeStatus,
    vueColumns,
    handleVueColumns,
    handleOpenViewSetting,
    ViewSettingOpen,
    isOrdersDraggerOpen,
    onOpenOrdersDragger,
    saveRetailerView,
    isOrdersActionModalOpen,
    setOrdersActionModalOpen,
    resetModalState,
    fetchJobsByStatus,
    filterOrders,
  };
};
