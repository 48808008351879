import React , { FC } from 'react';
import { Button, Card, Divider, List, notification } from 'antd';
import { NotificationListItem } from './components/NotificationListItem';
import { NotificationHeader } from './components/NotificationHeader';
import useNotifications from './hooks/useNotifications';
import { INotification } from './types';
import "./style.less";

const { Meta } = Card;

const cardStyle = {
  width: '350px',
  height: '500px',
};

const contentStyle = {
  height: '100%',
};

const NotificationList: FC = () => {

  const {
    initLoading,
    loading,
    list,
    canFetch,
    filterByUnread,
    onLoadMore,
    readNotification,
    switchNotificationsFilter
  } = useNotifications();

  const loadMore =
    !initLoading && !loading && canFetch ? (
      <div
        className='load-more-btn'
      >
        <Button onClick={onLoadMore}>loading more</Button>
      </div>
    ) : null;
  
  const currentList = !initLoading && !loading && filterByUnread ? list?.filter((item) => !item?.IsRead) : list;

  return (
    <Card className='card-style-override' style={{ overflowY: 'auto', ...cardStyle }}>
      <Meta title={<NotificationHeader filterByUnread={filterByUnread} setFilterByUnread={switchNotificationsFilter} />} />
      <Divider />
      <div style={contentStyle}>
        <List
          loading={initLoading}
          itemLayout="horizontal"
          loadMore={loadMore}
          dataSource={currentList}
          renderItem={(item: INotification) => {
            return <NotificationListItem notification={item} readNotifications={readNotification} />
          }}
        />
      </div>
    </Card>
  );
};

export default NotificationList;