import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Typography, Layout, notification } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { SelectLanguage } from "../../../../components/SelectLanguage";
import { actions as driverActions } from "../../../../redux/resources/driver";
import MainLogo from "../../../../assets/newLanding/MainLogo";
import MainLogoMobile from "../../../../assets/newLanding/MainLogoMobile";
import MobileBottomToolbar from "../../components/MobileBottomToolbar";
import { GetStartedButton, SignUpForm, SuccessAuth } from "../../components";
import SocialAuth from "../../components/SocialAuth";
import styles from "./signUpDriverPage.module.less";

const SignUpDriverPage = ({ signupDriver, isMobile }) => {
  const [isSignUpSuccess, setIsSignUpSuccess] = useState(false);

  const intl = useIntl();

  const openNotification = (description) => {
    notification.error({
      message: `${intl.formatMessage({ id: "error", defaultMessage: "Error" })}`,
      description,
    });
  };

  const handleSubmit = (values) => {
    const formattedFields = { ...values };
    formattedFields.email = formattedFields.email.toLowerCase();

    signupDriver({ ...formattedFields })
      .then(() => setIsSignUpSuccess(true))
      .catch((error) => {
        typeof error.body.message === "object"
          ? openNotification(error.body.message[0])
          : openNotification(error.body.message);
      });
  };

  return isMobile ? (
    <Layout className={styles.signUpWrap}>
      <Row style={{ flex: "auto" }}>
        <Col span={24} className={styles.mainSection}>
          <Row align="middle" justify="space-between">
            <Col span={6} offset={2}>
              <Link to="/">
                <MainLogoMobile />
              </Link>
            </Col>
            <Col span={16}>
              <Row align="top" justify="end">
                <Col span={8} offset={15}>
                  <SelectLanguage />{" "}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row align="middle" style={{ flex: "auto" }}>
            <Col
              span={20}
              offset={2}
              style={{ height: "100%", display: "flex", flexDirection: "column", paddingTop: 60, paddingBottom: 35 }}
            >
              <SignUpForm isMobile onSubmit={handleSubmit} />
              {/* <SocialAuth isDriver={true} /> */}
              <MobileBottomToolbar
                isVisible={isSignUpSuccess}
                onToggleModal={() => {
                  window.location.href = "/";
                }}
              >
                <Typography.Title level={3} className={styles.modalTitle}>
                  Success! <br />
                  <Typography.Text className={styles.modalSubTitle}>Link on your mailbox</Typography.Text>
                </Typography.Title>

                <Typography.Paragraph className={styles.modalDescription}>
                  Follow the link in the email you received to register in the system.
                </Typography.Paragraph>
                <Link to="/">
                  <GetStartedButton
                    htmlType="submit"
                    className={styles.signUpButton}
                    style={isMobile ? { marginTop: 16, width: "100%" } : {}}
                  >
                    <ArrowLeftOutlined style={{ paddingRight: 20 }} /> Back to Main page
                  </GetStartedButton>
                </Link>
              </MobileBottomToolbar>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  ) : (
    <Layout className={styles.signUpWrap}>
      <Row style={{ flex: "auto" }}>
        <Col span={12} className={styles.promoSection}>
          <Row gutter={[0, 60]}>
            <Col span={23} offset={1}>
              <Link to="/">
                <MainLogo />
              </Link>
            </Col>

            <Col span={21} offset={3}>
              <Typography.Title level={2} className={styles.promoSectionTitle}>
                <FormattedMessage id="page.signup.driver.driver" defaultMessage="Driver" /> <br />
                <Typography.Text className={styles.promoSectionSubtitle}>
                  <FormattedMessage id="page.signup.driver.registration" defaultMessage="Registration" />
                </Typography.Text>
              </Typography.Title>
            </Col>
          </Row>
        </Col>

        <Col span={12} className={styles.mainSection}>
          <Row align="middle" justify="center">
            <Col span={2} offset={21}>
              <SelectLanguage />
            </Col>
          </Row>

          <Row align="middle" style={{ flex: "auto" }}>
            <Col span={11} offset={6}>
              {!isSignUpSuccess ? (
                <>
                  <SignUpForm onSubmit={handleSubmit} />
                  {/* <SocialAuth isDriver={true} /> */}
                </>
              ) : (
                <SuccessAuth
                  title="page.signup.linkOnMailbox"
                  subtitle="page.signup.success"
                  message="page.signup.successMessage"
                  goToBtnLink="/"
                  goToBtnLabel="page.signup.backToMainPage"
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default connect(null, (dispatch) => ({
  ...bindActionCreators(driverActions, dispatch),
}))(SignUpDriverPage);
