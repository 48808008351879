import React from "react";
import { DatePicker } from "antd";

export const SystemDatePicker = ({ onSelect, placeholder }) => (
  <DatePicker
    picker="month"
    placeholder={placeholder}
    onChange={onSelect}
    format="MM.YYYY"
    className="mobile-system-datepicker"
    dropdownClassName="mobile-system-datepicker__dropdown"
  />
);
