import React from "react";
import { Table, Button, Popconfirm } from "antd";

import Select from "../../../../../components/Select";

export default ({
  intl,
  query,
  loading,
  retailers,
  pagination,
  groups,
  expandedGroup,
  selectedGroupRetailers,
  selectedRetailer,
  setQuery,
  createNewGroup,
  setExpandedGroup,
  loadSelectedGroupRetailers,
  deleteGroupById,
  selectRetailer,
  removeGroupMember,
}) => {
  const expandedRowRender = () => {
    const columns = [
      {
        title: "Retailer name",
        dataIndex: "name",
        key: "retailer-name",
      },
      {
        title: <Select defaultLabel="None" list={retailers} onChange={selectRetailer} value={selectedRetailer} />,
        dataIndex: "id",
        render: (id) => (
          <Popconfirm title="Confirm remove action" onConfirm={removeGroupMember(id)}>
            <Button>Remove from the group</Button>
          </Popconfirm>
        ),
      },
    ];

    return (
      <div>
        <Table columns={columns} dataSource={selectedGroupRetailers} pagination={false} />
      </div>
    );
  };

  const onExpandRow = (expanded, record) => {
    if (expanded) {
      setExpandedGroup(record.id);
      loadSelectedGroupRetailers(record.id);
    } else {
      setExpandedGroup(null);
    }
  };

  const onChange = async ({ current, pageSize }) => {
    await setQuery({
      ...query,
      page: current,
      limit: pageSize,
    });
  };

  const columns = [
    {
      title: "Group name",
      dataIndex: "id",
      key: "group-name",
      render: (id) => {
        return `Group-${id}`;
      },
    },
    {
      title: <Button onClick={createNewGroup}>Create new group</Button>,
      dataIndex: "id",
      key: "action-delete",
      render: (id) => (
        <Popconfirm title="Confirm the delete" onConfirm={deleteGroupById(id)}>
          <Button>Delete group</Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      loading={loading}
      rowKey={(record) => record.id}
      dataSource={groups}
      pagination={pagination}
      expandable={{ expandedRowRender }}
      onExpand={onExpandRow}
      onChange={onChange}
      expandedRowKeys={[expandedGroup]}
    />
  );
};
