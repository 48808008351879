import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "tasks",
  url: `${API_URL}/lmfs/tasks`,
  actions: {
    get: {
      method: "GET",
      url: `${API_URL}/lmfs/tasks/:trackingId`,
      reduce: (state, action) => {
        if (action.status === "resolved") {
          const jobs = state.jobs.map((job) =>{
            const deliveryVehicleId = `${job.id}-${job.driverId}`
           return deliveryVehicleId === action.context.trackingId ? { ...job, tasks: action.body.tasks, hasTasks: true } : job;

          }
          );
          return {
            ...state,
            jobs: jobs,
          };
        }
        return state;
      },
    },
    jobsFetch: {
      url: `${API_URL}/admin/jobs?fetchAll=true`,
      name: "fetchJobs",
      reduce: (state, action) => {
        if (action.status === "resolved") {
          return {
            ...state,
            jobs: action.body.filter((job) => job.driverId),
          };
        }
        return state;
      },
    },
  },
});

setAuthHeader(getAuthData().accessToken);
