import React from "react";
import { Select, Input, Popover, Button } from "antd";
import { FormattedMessage } from "react-intl";
import { ReactComponent as Settings } from "./assets/Settings.svg";


const { Option } = Select;

export default function RetailerEmails({ intl, data, sendEmails, setData, setDrawerVisible }) {
  const SelectAfter = (index, disabled) => {
    const values = [];
    if (data[index]?.options?.daily) {
      values.push("daily");
    }
    if (data[index]?.options?.weekly) {
      values.push("weekly");
    }
    if (data[index]?.options?.monthly) {
      values.push("monthly");
    }
    return (
      <Select
        disabled={disabled}
        placeholder="Please select"
        allowClear
        mode="multiple"
        onChange={handleOptionsChange(index)}
        value={values}
        className="retailerInsights__mail__select-after"
        style={{ width: "100%" }}
      >
        <Option value="daily">
          <FormattedMessage id="page.home.insights.mailing.daily" defaultMessage="Daily" />
        </Option>
        <Option value="weekly">
          <FormattedMessage id="page.home.insights.mailing.weekly" defaultMessage="Weekly" />
        </Option>
        <Option value="monthly">
          <FormattedMessage id="page.home.insights.mailing.monthly" defaultMessage="Monthly" />
        </Option>
      </Select>
    );
  };

  const handleEmailChange = (index) => (rawEvent) => {
    const value = rawEvent.target.value.toLowerCase();
    setData((old) => {
      const newData = [...old];
      newData[index] = { ...old[index], email: value };
      return newData;
    });
  };

  const handleOptionsChange = (index) => (value) => {
    setData((old) => {
      const newData = [...old];
      newData[index] = {
        ...old[index],
        options: {
          daily: value.includes("daily"),
          weekly: value.includes("weekly"),
          monthly: value.includes("monthly"),
        },
      };
      return newData;
    });
  };

  const openDrawer = () => {
    setDrawerVisible(true);
  };

  return (
    <div className="retailerInsights__mail">
      <div className="retailerInsights__mail__header">
        <h3>
          {`${intl.formatMessage({ id: "page.home.insights.setEmails", defaultMessage: "Set automatic emails" })}`}
        </h3>
        <Settings onClick={openDrawer} />
      </div>
      <div className="retailerInsights__mail__list">
        {data.map((e, i) => (
          <Input.Group compact className="retailerInsights__mail__input">
            <Input disabled value={e?.email} onChange={handleEmailChange(i)} />
            <Popover
              content={SelectAfter(i, true)}
              title={`${intl.formatMessage({
                id: "page.home.insights.mailing.setmailing",
                defaultMessage: "Set mailing:",
              })}`}
              trigger="click"
            >
              <Button style={{ width: "25%" }}>...</Button>
            </Popover>
          </Input.Group>
        ))}
      </div>
      {/*      
      <Input.Group compact className="retailerInsights__mail__input">
        <Input value={data[1]?.email} onChange={handleEmailChange(1)} />
        <Popover content={SelectAfter(1)} title="Set mailing" trigger="click">
          <Button style={{ width: "25%" }}>...</Button>
        </Popover>
      </Input.Group>
      <Input.Group compact className="retailerInsights__mail__input">
        <Input value={data[2]?.email} onChange={handleEmailChange(2)} />
        <Popover content={SelectAfter(2)} title="Set mailing" trigger="click">
          <Button style={{ width: "25%" }}>...</Button>
        </Popover>
      </Input.Group> */}
      {/* <div className="retailerInsights__mail__buttons">
        <Button>{`${intl.formatMessage({ id: "modal.clear", defaultMessage: "Clear" })}`}</Button>
        <Button onClick={sendEmails}>{`${intl.formatMessage({
          id: "modal.set",
          defaultMessage: "Set",
        })}`}</Button>
      </div> */}
    </div>
  );
}
