import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Input, notification, Layout } from "antd";
import { useSelector, connect } from "react-redux";
import { API_URL } from "../../../constants/config";
import { CopyOutlined } from "@ant-design/icons";
import "./RetailerTokens.less";
import CustomDivider from "../../../components/Divider";
import { bindActionCreators } from "redux";
import { actions as RetailerActions } from "../../../redux/resources/retailer";

const { Content } = Layout;
const RetailerTokens = ({ getSelfInfoRetailer }) => {
  const intl = useIntl();

  const tokens = useSelector((state) => {
    const retailerState = state.retailer;
    return {
      apiToken: retailerState.apiToken,
    };
  });

  useEffect(() => {
    getSelfInfoRetailer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openOpenApiDocumentation = () => {
    window.open(`${API_URL}/retailer-api/docs/`, "_blank");
  };

  const copyToClipboard = (token) => {
    if (token === "apiToken") {
      navigator.clipboard.writeText(tokens.apiToken);
      notification.success({
        message: `${intl.formatMessage({
          id: "page.ordersManagment.token",
          defaultMessage: "Token copied successfully",
        })}`,
      });
      return;
    }
  };
  return (
    <Content className="retailerCabinetContent retailerSettingsContent">
      <h2 className="pageTitle">
        <FormattedMessage id="page.home.settings.tabs.api" defaultMessage="API tokens" />
      </h2>
      <CustomDivider />
      {Object.values(tokens).every(Boolean) && (
        <div className="openApi">
          <div>
            <p className="openApi__row">
              <span className="openApi__label">
                <FormattedMessage id="token.production" defaultMessage="API Token" />:
              </span>
              <Input className="openApi__token" value={tokens.apiToken} />
              <CopyOutlined className="openApi__copy" onClick={() => copyToClipboard("apiToken")} />
            </p>
          </div>
          <Button className="openApi__DocBtn" onClick={openOpenApiDocumentation}>
            <FormattedMessage id="button.review.api" defaultMessage="Review the API documentation" />
          </Button>
        </div>
      )}
    </Content>
  );
};

const MSTP = (state) => {
  return { retailer: state.retailer };
};

export default connect(MSTP, (dispatch) => ({
  ...bindActionCreators(RetailerActions, dispatch),
}))(RetailerTokens);
