import React, { useEffect, useState } from "react";
import { Col, Row, Button, Dropdown, Space, Popconfirm, Radio, notification, DatePicker } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { filter, map } from "lodash";
import moment from "moment";

import ChangeDriverStatus from "../ChangeDriverStatus";
import DropdownMenu from "./DropdownMenu";
import DriverDocuments from "../../../../../components/DriverDocuments/DocumensList";
import DriverVideoCard from "../DriverVideoCard";
import RetailerSelect from "../../../../../components/Select";
import { replaceAt } from "../../../../../helpers/general";

import "./style.less";
import { API_URL } from "src/constants/config";
import { useHistory } from "react-router-dom";

const DriverDetails = ({
  selectedRetailer,
  driver,
  driverStats,
  driverAdditionalStats,
  loadDriverStatsCashSectionDriversManager,
  loadDriverStatsKMSectionDriversManager,
  loadDriverStatsJobCompletionSectionDriversManager,
  getJobsAndRetailers,
  handleAssignJob,
  handleCancelAssignJob,
  handleCompanyChange,
  onDriverStatusChange,
  trustDriverDriversManager,
  handleDriverDelete,
  setDrawerVisible
}) => {
  const availableJobs = useSelector(({ driversManager }) => filter(driversManager.adminJobs, ["status", "ready"]));
  const retailers = useSelector(({ adminRetailers }) => adminRetailers.list);

  const [datePeriod, setDatePeriod] = useState({
    from: moment().startOf("day"),
    to: moment(),
  });

  useEffect(() => {
    loadDriverStatsCashSectionDriversManager(driver.id, {
      query: {
        from: datePeriod.from.toISOString(true).substring(0, 23),
        to: datePeriod.to.toISOString(true).substring(0, 23),
      },
    });
    loadDriverStatsJobCompletionSectionDriversManager(driver.id, {
      query: {
        from: datePeriod.from.toISOString(true).substring(0, 23),
        to: datePeriod.to.toISOString(true).substring(0, 23),
      },
    });
    loadDriverStatsKMSectionDriversManager(driver.id, {
      query: {
        from: datePeriod.from.toISOString(true).substring(0, 23),
        to: datePeriod.to.toISOString(true).substring(0, 23),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datePeriod.from, datePeriod.to]);

  useEffect(() => {
    getJobsAndRetailers(driver.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentJob = driver?.currentJob;

  const onTrustDriver = async (e) => {
    const trusted = e.target.value === "true";

    await trustDriverDriversManager({ driverId: driver.id, trusted });
    notification.success({
      message: trusted
        ? `You trust ${driver.profile?.firstName} ${driver.profile?.lastName}`
        : `You don't trust ${driver.profile?.firstName} ${driver.profile?.lastName}`,
    });
  };


 
  const token = JSON.parse(localStorage.getItem("authData"));
  const tenant = localStorage.getItem("tenantSelected");
  const history = useHistory();

  const completeCurrentJobAndRemoveJobFromDriver = async (currentJob) => {
    try {
      const data = await fetch(`${API_URL}/admin/drivers/complete-job/${driver.id}/${currentJob.id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token.accessToken}`,
          Tenant: tenant,
        },
      });
  
      if (data) {
         notification.success({
          message: "Job Completed",
          description: "Job Is Successfully Completed",
        });
        setDrawerVisible(false)
      } else {
         const errorData = await data.json();
         console.error("Failed to complete job:", errorData);
      }
    } catch (error) {
       console.error("Error completing job:", error);
       notification.error({
        message: "Error",
        description: "An error occurred while completing the job. Please try again.",
      });
    }
  };
  


 

  return (
    <div>
      <Row className="toggleJob">
        {currentJob !== null ? (
          <Col style={{ marginBottom: "2%" }}>
            <span>Driver is working on job with id {currentJob.id}</span> &nbsp; &nbsp; &nbsp; &nbsp;
            <Popconfirm
              title="Are You sure, You want to unassign job from driver?"
              onConfirm={() => handleCancelAssignJob(currentJob)}
            >
              <Button>Cancel</Button>
            </Popconfirm>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <Popconfirm
              title="Make Job As Completed, And Remove  From Driver Assignment ?"
              onConfirm={() => completeCurrentJobAndRemoveJobFromDriver(currentJob)}
            >
              <Button type="danger">Complete Current Job </Button>
            </Popconfirm>
          </Col>
        ) : (
          <Col>
            <Space>
              <span>This driver has no current job.</span>
              {availableJobs.length ? (
                <Dropdown
                  trigger={["click"]}
                  placement="bottom"
                  overlay={<DropdownMenu ids={map(availableJobs, "id")} onHandleAssignJob={handleAssignJob} />}
                >
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    Assign Job <DownOutlined />
                  </a>
                </Dropdown>
              ) : (
                "No available jobs to assign currently."
              )}
            </Space>
          </Col>
        )}
      </Row>
      {driver && (
        <>
          <Row>
            <Col span={12}>
              {driver && (
                <ul className="driverDataList">
                  User:
                  <li>
                    <strong>id:</strong> {driver.id}
                  </li>
                  <li>
                    <strong>email:</strong> {driver.email}
                  </li>
                  <li>
                    <strong>status:</strong> {replaceAt(driver.status, driver.status.indexOf("_"), " ")}
                  </li>
                  <li>
                    <strong>created at:</strong> {moment(driver.createdAt).format("DD.MM.YYYY HH:mm")}
                  </li>
                  <li style={{ display: "flex", justifyContent: "space-between" }}>
                    <ChangeDriverStatus driver={driver} onChangeStatus={onDriverStatusChange} />
                    <Popconfirm
                      title="Are you sure to remove driver?"
                      onConfirm={handleDriverDelete}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button>Remove driver</Button>
                    </Popconfirm>
                  </li>
                </ul>
              )}
            </Col>
            <Col span={12}>
              {driver.profile && (
                <ul className="driverDataList">
                  Profile:
                  <li>
                    <strong>first name:</strong> {driver.profile.firstName}
                  </li>
                  <li>
                    <strong>last name:</strong> {driver.profile.lastName}
                  </li>
                  <li>
                    <strong>phone number:</strong> {driver.profile.phoneNumber}
                  </li>
                  <li>
                    <strong>nationality:</strong> {driver.profile.nationality}
                  </li>
                  <li>
                    <strong>address:</strong> {driver.profile.address}
                  </li>
                  <li>
                    <strong>birth date:</strong> {driver.profile.birthDate}
                  </li>
                </ul>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              {driver.car && (
                <ul className="driverDataList">
                  Car:
                  <li>
                    <strong>plate number:</strong> {driver.car.plateNumber}
                  </li>
                  <li>
                    <strong>manufactured at:</strong> {driver.car.manufacturedAt}
                  </li>
                  <li>
                    <strong>model:</strong> {driver.car.model}
                  </li>
                  <li>
                    <strong>color:</strong> {driver.car.color}
                  </li>
                  {driver.car.type && (
                    <li>
                      <strong>type:</strong> {driver.car.type}
                    </li>
                  )}
                  <li>
                    <strong>first issue date:</strong> {driver.car.firstIssueDate}
                  </li>
                </ul>
              )}
            </Col>
            <Col span={12}>
              {driver.bankDetails && (
                <ul className="driverDataList">
                  Bank details:
                  <li>
                    <strong>beneficiary name:</strong> {driver.bankDetails.beneficiaryName}
                  </li>
                  <li>
                    <strong>beneficiary country:</strong> {driver.bankDetails.beneficiaryCountry}
                  </li>
                  <li>
                    <strong>beneficiary city:</strong> {driver.bankDetails.beneficiaryCity}
                  </li>
                  <li>
                    <strong>beneficiary bank country:</strong> {driver.bankDetails.beneficiaryBankCountry}
                  </li>
                  <li>
                    <strong>beneficiary bank name:</strong> {driver.bankDetails.beneficiaryBankName}
                  </li>
                  <li>
                    <strong>IBAN:</strong> {driver.bankDetails.IBAN}
                  </li>
                  <li>
                    <strong>swift code:</strong> {driver.bankDetails.swiftCode}
                  </li>
                  <li>
                    <strong>beneficiary address one:</strong> {driver.bankDetails.beneficiaryAddressOne}
                  </li>
                  <li>
                    <strong>beneficiary bank address one:</strong> {driver.bankDetails.beneficiaryBankAddressOne}
                  </li>
                  <li>
                    <strong>beneficiary address two:</strong> {driver.bankDetails.beneficiaryAddressTwo}
                  </li>
                  <li>
                    <strong>beneficiary bank address two:</strong> {driver.bankDetails.beneficiaryBankAddressTwo}
                  </li>
                </ul>
              )}
              {driverStats && (
                <ul className="driverDataList">
                  Driver monthly stats:
                  <li>
                    <strong>General distance traveled:</strong>{" "}
                    {Math.round((driverStats.distance + Number.EPSILON) * 1000) / 1000} km
                  </li>
                  <li>
                    <strong>Orders completed:</strong>{" "}
                    {Math.round((driverStats.ordersCompleted + Number.EPSILON) * 1000) / 1000}
                  </li>
                  <li>
                    <strong>Early visited delivery point:</strong>{" "}
                    {Math.round((driverStats.earlyDeliveryPercentage + Number.EPSILON) * 100) / 100} %
                  </li>
                  <li>
                    <strong>Late visited delivery point:</strong>{" "}
                    {Math.round((driverStats.lateDeliveryPercentage + Number.EPSILON) * 100) / 100} %
                  </li>
                </ul>
              )}
            </Col>
          </Row>
          <Row align="middle">
            <Col>
              <ul className="driverDataList">
                Choose company:
                <li>
                  <RetailerSelect
                    value={selectedRetailer}
                    defaultLabel="No company"
                    style={{ width: 120 }}
                    onChange={handleCompanyChange}
                    list={retailers}
                  />
                </li>
              </ul>
            </Col>
            <Col>
              <span style={{ marginLeft: 34, marginRight: 10 }}>Trust driver:</span>
              <Radio.Group onChange={onTrustDriver} defaultValue={driver.trustedDriver ? "true" : "false"}>
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <ul className="driverDataList">
                Check Driver:
                <li>
                  <strong>Orders completed:</strong>{" "}
                  {!driverAdditionalStats?.ordersCompleted ? "0" : driverAdditionalStats?.ordersCompleted || "0"}
                  {/* {driverAdditionalStats?.jobCompletionStats?.completedOrders || "0"}{" "} */}
                  {/* /{" "}
                  {driverAdditionalStats?.jobCompletionStats?.completedOrders +
                    driverAdditionalStats?.jobCompletionStats?.notCompletedOrders || "0"} */}
                </li>
                <li>
                  <strong>General cash collected:</strong> ${" "}
                  {!driverAdditionalStats?.cashStats?.cash ? "0" : driverAdditionalStats?.cashStats?.cash || "0"}
                </li>
                <li>
                  <strong>Km traveled:</strong>{" "}
                  {!driverAdditionalStats?.kmStats ? "0" : Math.floor(driverAdditionalStats?.kmStats) || "0"}
                </li>
              </ul>
            </Col>
            <Col span={12}>
              <br />
              From:{" "}
              <DatePicker
                format="YYYY-MM-DD"
                className="DatePicker"
                defaultValue={datePeriod.from}
                value={datePeriod.from}
                showNow={false}
                showToday={false}
                onChange={(date) => {
                  setDatePeriod((prev) => {
                    if (date < prev.to) return { from: date, to: prev.to };
                    return prev;
                  });
                }}
                allowClear={false}
              />{" "}
              To:{" "}
              <DatePicker
                format="YYYY-MM-DD"
                className="DatePicker"
                defaultValue={datePeriod.to}
                value={datePeriod.to}
                showNow={false}
                showToday={false}
                onChange={(date) => {
                  setDatePeriod((prev) => {
                    if (date > prev.from) return { from: prev.from, to: date };
                    return prev;
                  });
                }}
                allowClear={false}
              />
            </Col>
          </Row>
          {driver.video?.url && <DriverVideoCard videoUrl={driver.video.url} />}
          <DriverDocuments driver={driver} />
        </>
      )}
    </div>
  );
};

export default React.memo(DriverDetails);
