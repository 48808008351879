import { TablePaginationConfig } from "antd";
import { Moment } from "moment";
import React from "react";
import { IOrder } from "src/entities";

export enum CFRScreenEnum {
  ORDERS_SCREEN = "orders_screen",
  CFR_SETTINGS_SCREEN = "cfr_settings_screen",
  CFR_SUCCESS_SCREEN = "cfr_success_screen",
  CFR_PARTIAL_SUCCESS_SCREEN = "cfr_partial_success_screen",
  CFR_ERROR_SCREEN = "cfr_error_screen",
  CFR_RESULT_SCREEN = "cfr_result_screen",
  CFR_STATUS_LOADING = "cfr_status_loading",
  CFR_RUNNING = "cfr_running",
}

export enum ResultScreenTypeEnum {
  EMPTY = "empty",
  ERROR = "error",
  SUCCESS = "success",
  PARTIAL_SUCCESS = "partial_success",
}

export interface ICFRWidgetProps {
  selectedOrders: React.Key[];
  selectedRetailer: string | undefined;
  onReset: (refreshOrders: boolean) => void;
}

export interface IAvailableOrdersTable {
  handleSelectAllOrders: () => void;
  data: any[];
  pagination?: TablePaginationConfig;
  selectedOrders: React.Key[];
  onOrdersSelected: (orders: React.Key[], type: "orders") => void;
  filteredRetailer: any;
  handleChange: any;
  setSelectedRetailer: React.Dispatch<React.SetStateAction<number | null>>;
  selectedRetailer: number | null;
  retailers: any[];
  isAllSelected: () => boolean;
  searchedAddress: string;
  setSearchedAddress: React.Dispatch<React.SetStateAction<string>>;
  isOrdersLoading: boolean;
  handlePassToNextStep: any;
}

export interface IScreenData {
  selectedOrders?: React.Key[];
  selectedRetailer?: string;
  resultScreenType?: ResultScreenTypeEnum;
  clearCFRState?: boolean;
}

export type TOnScreenChange = (screen: CFRScreenEnum, screenData: IScreenData) => void;

export interface IAvailableOrdersTableProps {}

export interface IUseAvailableOrdersReturn {
  isOrdersLoading: boolean;
  fetchAvailabeOrders: (props: IFetchAvailableOrders) => void;
  fetchRestrictedRetailer: () => void;
  clearCFRState: () => void;
}

export interface IUseAvailableOrdersProps {
  orders: IOrder[];
  getAvailableOrdersJobsMap: any;
  fetchRestrictedListAdminRetailers: any;
  clearCFRStateJobsMap: any;
}

export type IUseAvailableOrders = (props: IUseAvailableOrdersProps) => IUseAvailableOrdersReturn;

export interface IFetchAvailableOrders {
  selectedRetailer: any;
  searchedAddress: any;
}

export interface ICFRSettingsProps {
  selectedOrders: React.Key[];
  selectedRetailer: string | undefined;
  onScreenChange: TOnScreenChange;
}

export interface ICFRParameters {
  driverLimit: number; //package
  travelLimit: number; //hours
  deliveryTime?: number; //hours
  deliveryStartTime: Moment | number; //time HH:mm
  deliveryEndTime: Moment | number; //time HH:mm
  pickUpStartTime: Moment | number; //time HH:mm
  pickUpEndTime: Moment | number; //time HH:mm
  driverCostPerHour: number;
  driverCostPerKm: number;
  averagePickUpTime: number; //seconds
  averageDeliveryTime: number; // minutes
  timeout: number; // seconds
  numberOfDrivers?: number;
  withZones: Boolean;
}

export interface IUseCFRSettingProps {
  fetchJobsMaps: any;
  cfrStateJobsMap: any;
  cfrJobsMap: any;
  isCFRRunning: boolean;
  isCFRInstance: any;
  selectedRetailer: any;
  estimatedTime: any;
  selectedOrders: React.Key[];
}

export type TUseCFRSetting = (props: IUseCFRSettingProps) => {
  cfrParams: ICFRParameters;
  isCFRLoading: boolean;
  CFRResponse: any;
  getCFRStatus: () => Promise<void>;
  generateJobsByCfr: () => Promise<void>;
  handleChangeCfrParams: (value: string | number | any, key: any) => void;
};

// CFR Results Screen
export interface ICFRResultsProps {
  onScreenChange: TOnScreenChange;
  type: ResultScreenTypeEnum;
  // cfrResults: ICFRResults;
}

export interface ICFRResults {
  createdJobs: any[];
  numberOfProcessedOrders: number;
  skippedOrdersInZones: number;
  ordersOutsideOfZones: number;
}

export interface IMessageOutputProps {
  cfrResults: ICFRResults;
}

export interface IUseCFRWidgetProps {
  cfrStateJobsMap: () => Promise<any>;
}

export type TUseCFRWidget = (props: IUseCFRWidgetProps) => {
  getCFRStatus: () => Promise<void>;
};
