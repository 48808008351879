import React from "react";
import GoogleMapReact from "google-map-react";

import { API_KEY } from "../../../../../constants/config";

import "./styles.less";

export const MobileGoogleMapWrapper = ({ children, lat, lng }) => {
  return (
    <div className="mobile-google-map-wrapper">
      <GoogleMapReact
        bootstrapURLKeys={{ key: API_KEY }}
        defaultCenter={{ lat, lng }}
        defaultZoom={10}
        distanceToMouse={() => {}}
      >
        {children}
      </GoogleMapReact>
    </div>
  );
};
