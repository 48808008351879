export const defaultTransform = ({ body }) => ({
  response: body,
});

export const defaultReduce = (key) => (state, action) => ({
  ...state,
  [key]: action.response,
});

export const makeDefaultRequest = (method, url, stateName) => ({
  method,
  url,
  transformResponse: defaultTransform,
  reduce: defaultReduce(stateName),
});
