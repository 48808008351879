import React, { useEffect } from "react";
import { Form } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import StepActions from "../../../../components/DataEntry/StepActions";
import { getDataLocalStorageAndSetData, getPhoneInputCountryCode } from "../../../../helpers/general";
import "./customSupport.less";

const CustomSupportNumber = ({
  currentStep,
  putSelfInfoRetailerWithErrorNotification,
  onPrevStep,
  stepsLength,
  retailerInfo,
}) => {
  const [form] = Form.useForm();
  const intl = useIntl();

  useEffect(() => {
    const customerSupportInfo = JSON.parse(localStorage.getItem("customerSupportInfo"));
    getDataLocalStorageAndSetData(customerSupportInfo, "supportNumber", form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    // const updatedRetailerInfo = { ...values};
    putSelfInfoRetailerWithErrorNotification(values);
  };

  const handleChangePhone = (value) => {
    form.setFieldsValue({
      supportNumber: value,
    });
  };

  return (
    <>
      <h3 className="driverInfoTitle">
        <FormattedMessage
          id="page.step.customerSupport.title"
          defaultMessage="Customer support number in case the customer has any issues with their order"
        />
      </h3>
      <p className="driverInfoDescription">
        <FormattedMessage
          id="page.step.customerSupport.subtitle"
          defaultMessage="We sometimes receive inquires or comments about the products we deliver, in which case, we forward them directly to you."
        />
      </p>
      <Form
        form={form}
        name="customSupportNumber"
        layout="vertical"
        onFinish={onFinish}
        hideRequiredMark
        className="formStyle"
        onValuesChange={(changedValues, allValues) => {
          localStorage.setItem("customerSupportInfo", JSON.stringify(allValues));
        }}
      >
        <Form.Item
          className="personalInfoItem"
          name="supportNumber"
          label={`${intl.formatMessage({ id: "phoneNumber", defaultMessage: "Phone number" })}`}
          key="phone_number"
          rules={[
            {
              required: true,
              message: `${intl.formatMessage({ id: "error.inputPhone", defaultMessage: "Input phone number!" })}`,
            },
            {
              min: 4,
              message: `${intl.formatMessage({
                id: "error.minLength",
                defaultMessage: "Minimum length is 4 charters",
              })}`,
            },
            {
              max: 255,
              message: `${intl.formatMessage({
                id: "error.maxLength",
                defaultMessage: "Maximum length is 255 characters",
              })}`,
            },
          ]}
          initialValue={retailerInfo ? retailerInfo.supportNumber : ""}
        >
          <ReactPhoneInput
            defaultMask={".... .... ...."}
            country={getPhoneInputCountryCode()}
            placeholder={`${intl.formatMessage({ id: "input.phone", defaultMessage: "Phone" })}`}
            value={form.getFieldValue("supportNumber")}
            onChange={handleChangePhone}
          />
        </Form.Item>
        <StepActions currentStep={currentStep} onPrevStep={onPrevStep} stepsLength={stepsLength} />
      </Form>
    </>
  );
};

export default CustomSupportNumber;
