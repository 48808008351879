import React, { FC } from "react";
import { IMessageOutputProps } from "src/types/CloudFleetRouting";
import "./cfr-results.less";

const MessageOutput: FC<IMessageOutputProps> = ({ cfrResults }) => {
  return (
    <div className="messages-output">
      <div className="messages-output-row">
        <p>Jobs Created</p>
        <b>{cfrResults?.createdJobs?.length} </b>
      </div>
      <div className="messages-output-row">
        <p>Orders processed</p>
        <b>{cfrResults?.numberOfProcessedOrders} </b>
      </div>
      <div className="messages-output-row">
        <p>Orders were skipped</p>
        <b>{cfrResults?.skippedOrdersInZones} </b>
      </div>
      <div className="messages-output-row">
        <p>Orders were outside the zones</p>
        <b>{cfrResults?.ordersOutsideOfZones} </b>
      </div>
    </div>
  );
};

export default MessageOutput;
