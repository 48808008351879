import React from "react";
import { Table } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import "../../../../components/OrdersWidget/UploadedOrdersTable/uploadedOrdersTable.less";

const OrdersTable = ({ ordersList, ordersPaginationConfig }) => {
  const intl = useIntl();

  const columns = [
    {
      title: `${intl.formatMessage({ id: "tabPane.customer", defaultMessage: "Customer" })}`,
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: `${intl.formatMessage({ id: "tabPane.orderID", defaultMessage: "Order ID" })}`,
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: `${intl.formatMessage({ id: "tabPane.customerPhone", defaultMessage: "Customer Phone" })}`,
      dataIndex: "customerPhoneNumber",
      key: "customerPhoneNumber",
    },
    {
      title: `${intl.formatMessage({ id: "tabPane.packages", defaultMessage: "Packages" })}`,
      dataIndex: "packages",
      key: "packages",
    },
    {
      title: `${intl.formatMessage({ id: "tabPane.pickupTime", defaultMessage: "Pick Up Time" })}`,
      dataIndex: "deliveryTime",
      key: "deliveryTime",
    },
    {
      title: `${intl.formatMessage({ id: "tabPane.locationShared", defaultMessage: "Location Shared" })}`,
      dataIndex: "locationShared",
      key: "locationShared",
      render: (isShared) => {
        return <span>{isShared}</span>;
      },
    },
  ];

  const data = ordersList?.map((order) => {
    const isLocationShared = order?.locationShared;
    return {
      ...order,
      orderId: order.id,
      package: order.packages[0]?.no,
      customerName: order.customerInfo?.name,
      trackingID: order.trackingId,
      customerPhoneNumber: order.customer?.phoneNumber,
      packages: order.packages?.length,
      retailerName: order.retailer?.name,
      locationShared: isLocationShared ? (
        <FormattedMessage id="YES" defaultMessage="YES" />
      ) : (
        <FormattedMessage id="NO" defaultMessage="NO" />
      ),
      orderStatus: order?.status,
      key: order?.id,
    };
  });

  return <Table columns={columns} pagination={ordersPaginationConfig} dataSource={data} />;
};

export default OrdersTable;
