import React from "react";
import { Divider } from "antd";

import CustomDivider from "../Divider";

export default (props) => (
  <>
    <h2 className="pageTitle">{props.children}</h2>
    <CustomDivider />
    <Divider />
  </>
);
