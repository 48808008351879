import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Divider, Radio, Select, Table, Input } from "antd";

import CustomDivider from "../../components/Divider";
import tableColumns from "./components/columns";

import { actions as accountingActions } from "../../redux/resources/accounting";
import { useHooks } from "./hooks/useHooks";
import Drawer from "./components/Drawer/Drawer";

const { Option } = Select;

function Accounting({
  isMobile,
  loadAllRetailersAccounting,
  loadAllDriversAccounting,
  loadRetailerAccounting,
  loadDriverAccounting,
  removeBracketAccounting,
  addBracketAccounting,
  setCostDriverAccounting,
  setCostRetailerAccounting,
  changeBracketAccounting,
  getStatsRetailerAccounting,
  getStatsDriverAccounting,
}) {
  const [queryDrivers, setQueryDrivers] = useState({
    page: 1,
    limit: localStorage.getItem("driversOnPage") || 5,
    status: "",
  });
  const [queryRetailers, setQueryRetailers] = useState({
    page: 1,
    limit: localStorage.getItem("retailersOnPage") || 5,
    status: "",
  });
  const [loading, setLoading] = useState(false);
  const [datepickerRange, setDatepickerRange] = useState();

  const {
    query,
    stats,
    profile,
    accounting,
    setProfile,
    paginationData,
    drawerVisible,
    setDrawerVisible,
    fetchData,
    onChangeTable,
    onChangeSearchFilter,
    handleSearch,
    currentList,
    loadedList,
    onChangeList,
    loadAccounting,
    handleAddBracket,
    handleRemoveBracket,
    handleSetCost,
    changeAccountingType,
    handleChangeBracket,
    loadStatsWithDate,
  } = useHooks({
    getStatsRetailerAccounting,
    getStatsDriverAccounting,
    setLoading,
    queryDrivers,
    queryRetailers,
    setQueryDrivers,
    setQueryRetailers,
    loadAllRetailersAccounting,
    loadAllDriversAccounting,
    loadRetailerAccounting,
    loadDriverAccounting,
    removeBracketAccounting,
    addBracketAccounting,
    setCostDriverAccounting,
    setCostRetailerAccounting,
    changeBracketAccounting,
  });

  const paginationConfig = {
    pageSize: query.limit,
    total: paginationData?.totalCount || 5,
    current: query.page,
    pageSizeOptions: [5, 10, 25, 50],
    showSizeChanger: true,
    position: ["bottomRight"],
  };

  const selectSearchFilter = (
    <Select defaultValue="email" className="select-before" onChange={onChangeSearchFilter}>
      <Option value="email">Email</Option>
      <Option value="name">Name</Option>
    </Select>
  );

  useEffect(() => {
    fetchData("retailers");
    //eslint-disable-next-line
  }, []);

  return (
    <div className="pageWrapper">
      <h2 className="pageTitle">Accounting</h2>
      <CustomDivider />
      <Divider />
      <Input
        placeholder="Search"
        addonBefore={selectSearchFilter}
        onPressEnter={handleSearch}
        className="searchDriver"
      />
      <Divider />
      <Radio.Group value={currentList} onChange={(e) => onChangeList(e.target.value)} buttonStyle="solid">
        <Radio.Button value="retailers">Retailers</Radio.Button>
        <Radio.Button value="drivers">Drivers</Radio.Button>
      </Radio.Group>
      <Divider />
      <Table
        rowKey={(record) => record.id}
        columns={tableColumns({ isMobile: isMobile })}
        dataSource={loadedList}
        loading={loading}
        onChange={onChangeTable}
        pagination={paginationConfig}
        onRow={(record) => {
          return {
            onClick: async () => {
              setDatepickerRange();
              setDrawerVisible(true);
              setProfile(record);
              loadAccounting(record.id);
            },
          };
        }}
        rowClassName="driverTableItem"
      />
      <Drawer
        profile={profile}
        stats={stats}
        accounting={accounting}
        handleAddBracket={handleAddBracket}
        handleRemoveBracket={handleRemoveBracket}
        handleSetCost={handleSetCost}
        setDrawerVisible={setDrawerVisible}
        drawerVisible={drawerVisible}
        isRetailer={currentList === "retailers"}
        isMobile={isMobile}
        changeAccountingType={changeAccountingType}
        handleChangeBracket={handleChangeBracket}
        loadStatsWithDate={loadStatsWithDate}
        datepickerRange={datepickerRange}
        setDatepickerRange={setDatepickerRange}
      />
    </div>
  );
}

export default connect(
  (state) => ({
    isMobile: state.admin.isMobile,
  }),
  (dispatch) => ({
    ...bindActionCreators(accountingActions, dispatch),
  }),
)(Accounting);
