import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Checkbox, Collapse, Empty, Drawer, List, Image, Divider } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";

import { MobileSearch } from "../../../../../components/AdminPanel/Mobile/MobileSearch";
import { MobileCustomSelect } from "../../../../../components/AdminPanel/Mobile/MobileCustomSelect";
import { PanelHeader } from "../../../../../components/AdminPanel/Mobile/MobileCollapseList/PanelHeader";
import { MobileCollapseList } from "../../../../../components/AdminPanel/Mobile/MobileCollapseList";
import { MobileTableRows } from "../../../../../components/AdminPanel/Mobile/MobileTable/components/MobileTableRows";
import { NO_INFO } from "../../../../../components/AdminPanel/Mobile/helpers";
import { formatCompleted } from "../../../../../helpers/formatStatus";
import { ReactComponent as OpenCollapse } from "../../../../../assets/adminPanel/mobile-collapse.svg";
import { ReactComponent as OpenedCollapse } from "../../../../../assets/adminPanel/mobile-opened-collase.svg";
import { LocationModal } from "../LocationModal";
import { ConfirmModal } from "../ConfirmModal";
import { useOrderTable } from "../../hooks/useOrderTable";
import { ActionButtons } from "./ActionButtons";
import { MobilePagination } from "../../../../../components/AdminPanel/Mobile/MobilePagination";
import { PhoneModal } from "../PhoneModal";
import OrdersActionConfirmModal from "./ConfirmActionsModal/OrdersActionConfirmModal";
import Ticket from "../../../../../components/Ticket";

const { Panel } = Collapse;

export const OrdersTable = ({
  old,
  orders,
  retailers,
  searchedValue,
  selectedOrders,
  orderSearchQuery,
  openModal,

  phoneModalVisible,
  selectedTab,
  filteredRetailer,
  filteredLocationShared,
  paginationConfig,
  handleModalSubmit,
  onOpenSharedLocation,
  onOpenPhoneModal,
  handleSelectOrder,
  changeQueryParams,
  setSelectedOrders,
  setSearchedValue,
  setOrderSearchQuery,
  setDownloadedOrders,
  setOpenModal,
  fetchAccordingToTab,
  changeLocationAdminOrder,
  onClosePhoneModal,
  deleteLocal,
  editPhoneAdminOrder,
  getMapData,
  pickupFailed,
  restoreFailedAdminOrder,
  getReportsAdminOrder,
  isCompleted,
  isPostponed,
  isCanceled,
  completedOrdersFilter,
  setCompletedOrdersFilter,
  setCanceledOrdersFilter,
  setPostponedOrdersFilter,

  removeOrdersFromJob,
  addOrdersToJob,
  getJobsByStatusAdminOrder,
  incompleteJobs,
  cancelCompletedAdminOrder,
}) => {
  const {
    intl,
    isRetailer,
    orderList,
    modalTitle,
    searchFilter,
    retailerNameFilter,
    locationSharedFilter,
    collapsedOrder,
    isLocationModalOpen,
    isAllOrdersChecked,
    setIsLocationModalOpen,
    onCollapseOrder,
    onHandleSelectOrder,
    onChangeSearchFilter,
    onChangeSearchValue,
    isOrderChecked,
    onSelectOrder,
    onSelectAllOrders,
    onPageChange,
    onLocationShare,
    onActionBtnClick,
    onChangeRetailerNameFilter,
    onChangeLocationSharedFilter,
    searchRetailer,
    addLocationToLocal,
    handleDeleteManyOrders,
    completedFilter,
    postponedFilter,
    canceledFilter,

    isOrdersActionModalOpen,
    setOrdersActionModalOpen,
    filterOrders,
  } = useOrderTable({
    old,
    orders,
    retailers,
    filteredRetailer,
    filteredLocationShared,
    orderSearchQuery,
    selectedOrders,
    fetchAccordingToTab,
    handleSelectOrder,
    changeQueryParams,
    changeLocationAdminOrder,
    setSearchedValue,
    setSelectedOrders,
    setOrderSearchQuery,
    setOpenModal,
    setDownloadedOrders,
    getJobsByStatusAdminOrder,
    cancelCompletedAdminOrder,
  });
  const [reportsDrawer, setReportsDrawer] = useState(false);
  const [reports, setReports] = useState([]);

  const setReportsDrawerOpen = (id) => (event) => {
    getReportsAdminOrder({ id }).then((results) => {
      setReports(results.body);
      setReportsDrawer(true);
    });
  };

  const tabNav = (
    <>
      <MobileSearch
        placeholder={`${intl.formatMessage({ id: "search", defaultMessage: "Search" })}`}
        defaultSearchFilterValue="trackingId"
        selectedFilterValue={orderSearchQuery}
        searchFilter={searchFilter}
        onChangeSearchFilter={onChangeSearchFilter}
        inputValue={searchedValue}
        onChangeSearchValue={onChangeSearchValue}
        onSearch={searchRetailer}
        intl={intl}
      />
      {!!(selectedOrders.length > 0) && (
        <>
          <Button onClick={handleDeleteManyOrders} className="mobile-btn mobile-btn--purple mb-16">
            <FormattedMessage id="page.ordersManagment.deleteOrders" defaultMessage="Delete selected orders" />
          </Button>
          <Button
            onClick={() => {
              setOrdersActionModalOpen({ id: "add", open: true });
            }}
            className="mobile-btn mobile-btn--blue mb-16"
          >
            <FormattedMessage id="page.ordersManagment.addToJob" defaultMessage="Add orders to job" />
          </Button>
          {/* TODO once API is ready
          <Button
            onClick={() => {
              setOrdersActionModalOpen({ id: "remove", open: true });
            }}
            className="mobile-btn mobile-btn--red mb-16"
          >
            <FormattedMessage id="page.ordersManagment.removeFromJob" defaultMessage="Remove orders from job" />
          </Button> */}
        </>
      )}
      {!old && !!orderList?.length && (
        <Button
          onClick={() => setOpenModal({ type: "saveOrders", open: true })}
          className="mobile-btn mobile-btn--gray mb-16"
        >
          <FormattedMessage id="button.tab.confirm&UploadOrders" defaultMessage="Confirm and upload ALL orders" />
        </Button>
      )}
      <div className="table-filters">
        <div className="table-filters__top">
          <div />
          <MobileCustomSelect
            isFromOrders
            label="Location Shared"
            options={locationSharedFilter}
            selectedTab={selectedTab}
            onFilterStatus={onChangeLocationSharedFilter}
          />
        </div>
        {isCompleted ? (
          <div className="table-filters__middle">
            <MobileCustomSelect
              isOrdersCompleted
              isFromOrders
              label="Type"
              options={completedFilter}
              selectedTab={selectedTab}
              onFilterStatus={setCompletedOrdersFilter}
            />
          </div>
        ) : null}
        {isPostponed ? (
          <div className="table-filters__middle">
            <MobileCustomSelect
              isOrdersCompleted
              isFromOrders
              label="Type"
              options={postponedFilter}
              selectedTab={selectedTab}
              onFilterStatus={setPostponedOrdersFilter}
            />
          </div>
        ) : null}
        {isCanceled ? (
          <div className="table-filters__middle">
            <MobileCustomSelect
              isOrdersCompleted
              isFromOrders
              label="Type"
              options={canceledFilter}
              selectedTab={selectedTab}
              onFilterStatus={setCanceledOrdersFilter}
            />
          </div>
        ) : null}
        <div className="table-filters__bottom">
          <Checkbox checked={isAllOrdersChecked} onChange={onSelectAllOrders}>
            Select All
          </Checkbox>
          <MobileCustomSelect
            label="Retailer Name"
            options={retailerNameFilter}
            selectedTab={selectedTab}
            onFilterStatus={onChangeRetailerNameFilter}
          />
        </div>
      </div>
    </>
  );

  return (
    <>
      {tabNav}
      {orderList?.length ? (
        <MobileCollapseList activeKey={collapsedOrder} onCollapse={onCollapseOrder} ac>
          {orderList?.map((order) => {
            const isLocationShared = old ? order.locationShared : order.deliveryPoint?.lat && order.deliveryPoint?.long;
            return (
              <Panel
                header={
                  <PanelHeader>
                    <div className="left">
                      <div onClick={(e) => onSelectOrder(e, order.id)}>
                        <Checkbox checked={isOrderChecked(order.id)} />
                      </div>
                      <span className="jobId">{order.customerInfo?.name || "Package"}</span>
                    </div>
                    <div className="right">
                      <div>
                        {/* {order.packages[0]?.no} */}
                        {order.id}
                      </div>
                      {collapsedOrder === order.id ? <OpenedCollapse /> : <OpenCollapse />}
                    </div>
                  </PanelHeader>
                }
                key={order.id}
              >
                <MobileTableRows
                  rows={[
                    {
                      label: `${intl.formatMessage({ id: "tabPane.customer", defaultMessage: "Customer" })}`,
                      value: order.customerInfo?.name || NO_INFO,
                    },
                    {
                      label: `${intl.formatMessage({ id: "tabPane.orderID", defaultMessage: "Order ID" })}`,
                      value: order.id || NO_INFO,
                    },
                    old && {
                      label: `${intl.formatMessage({ id: "tabPane.trackingID", defaultMessage: "Tracking ID" })}`,
                      value: order.trackingId || NO_INFO,
                    },
                    {
                      label: `${intl.formatMessage({ id: "tabPane.customerPhone", defaultMessage: "Customer Phone" })}`,
                      value: order.customer?.phoneNumber || NO_INFO,
                    },
                    {
                      label: `${intl.formatMessage({ id: "tabPane.packages", defaultMessage: "Packages" })}`,
                      value: order.packages?.length || NO_INFO,
                    },
                    old && {
                      label: `${intl.formatMessage({ id: "tabPane.retailerName", defaultMessage: "Retailer Name" })}`,
                      value:
                        (
                          <div onClick={onHandleSelectOrder(order.trackingId)} className="link">
                            {order.retailer?.name}
                          </div>
                        ) || NO_INFO,
                    },
                    {
                      label: `${intl.formatMessage({
                        id: "tabPane.locationShared",
                        defaultMessage: "Location Shared",
                      })}`,
                      value: (
                        <>
                          {isLocationShared ? (
                            <span onClick={onOpenSharedLocation(order, isCompleted)} className="link">
                              <FormattedMessage id="YES" defaultMessage="YES" />
                            </span>
                          ) : (
                            <span onClick={onLocationShare(order)} className="link">
                              <FormattedMessage id="NO" defaultMessage="NO" />
                            </span>
                          )}
                        </>
                      ),
                    },
                    old && {
                      label: `${intl.formatMessage({ id: "tabPane.orderStatus", defaultMessage: "Order Status" })}`,
                      value: formatCompleted(order) || NO_INFO,
                    },
                    old && {
                      label: `${intl.formatMessage({
                        id: "tabPane.downloadBarcode",
                        defaultMessage: "Download barcode",
                      })}`,
                      value: (
                        <a
                          href={order.packages[0]?.receipt?.url || "!#"}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="download-barcode"
                        >
                          <DownloadOutlined />
                        </a>
                      ),
                    },
                    {
                      label: `${intl.formatMessage({ id: "tabPane.uploadedAt", defaultMessage: "Uploaded at" })}`,
                      value: moment(order.createdAt).format("DD.MM.YYYY HH:mm") || NO_INFO,
                    },
                    {
                      label: `${intl.formatMessage({ id: "tabPane.updatedAt", defaultMessage: "Updated at" })}`,
                      value: moment(order.updatedAt).format("DD.MM.YYYY HH:mm") || NO_INFO,
                    },
                    {
                      label: `${intl.formatMessage({ id: "tabPane.postponedTo", defaultMessage: "Postponed to" })}`,
                      value: moment(order.postponedTo).format("DD.MM.YYYY HH:mm") || NO_INFO,
                    },
                    pickupFailed && {
                      label: (
                        <Button
                          onClick={() => {
                            fetchAccordingToTab();
                            restoreFailedAdminOrder({ orderId: order.id });
                          }}
                        >
                          Restore
                        </Button>
                      ),
                      value: <Button onClick={setReportsDrawerOpen(order.id)}>Reports</Button>,
                    },
                    old && {
                      label: `${intl.formatMessage({ id: "tabPane.ticket", defaultMessage: "Ticket" })}`,
                      value: (
                        <Ticket
                          buttonType="primary"
                          orderId={order.id}
                          jobId={null}
                          ticketDetails={order.ticket}
                          users={{
                            retailer: order.retailerName,
                          }}
                        />
                      ),
                    },
                  ]}
                />
                <ActionButtons
                  old={old}
                  orderId={order.id}
                  phone={order.customer?.phoneNumber}
                  isRetailer={isRetailer}
                  onOpenPhoneModal={onOpenPhoneModal}
                  onActionBtnClick={onActionBtnClick}
                  deleteLocal={deleteLocal}
                  orderStatus={order.status}
                />
              </Panel>
            );
          })}
        </MobileCollapseList>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
      <MobilePagination onPageChange={onPageChange} {...paginationConfig} />
      <LocationModal
        isLocationModalOpen={isLocationModalOpen}
        setIsLocationModalOpen={setIsLocationModalOpen}
        addLocationToLocal={addLocationToLocal}
        old={old}
        getMapData={getMapData}
      />
      <ConfirmModal
        openModal={openModal}
        modalTitle={modalTitle}
        handleModalSubmit={handleModalSubmit}
        setOpenModal={setOpenModal}
        orders={orderList}
        old={old}
      />
      <PhoneModal
        visible={!!phoneModalVisible}
        oldPhone={phoneModalVisible?.phone}
        orderId={phoneModalVisible?.orderId}
        editPhone={editPhoneAdminOrder}
        fetchAccordingToTab={fetchAccordingToTab}
        onClose={onClosePhoneModal}
      />
      <OrdersActionConfirmModal
        isAdminOrders={true}
        onActionDone={() => setOrdersActionModalOpen({ id: "", open: false })}
        onCancel={() => setOrdersActionModalOpen({ id: "", open: false })}
        visible={isOrdersActionModalOpen}
        orders={filterOrders(selectedOrders, orderList)}
        jobs={incompleteJobs}
        removeOrdersFromJob={removeOrdersFromJob}
        addOrdersToJob={addOrdersToJob}
        afterClose={() => setSelectedOrders([])}
      />
      {reportsDrawer && (
        <Drawer
          width={"80vw"}
          placement="right"
          closable
          onClose={() => setReportsDrawer(false)}
          visible={reportsDrawer}
        >
          <h3>Order #{reports[0]?.order}</h3>
          <List
            itemLayout="vertical"
            size="large"
            dataSource={reports}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                extra={
                  <div className="reports-image-container">
                    <Image.PreviewGroup>
                      {item.attachments.map((e) => (
                        <>
                          <Image key={e.id} width={200} alt="img" src={e.attachment.url} />
                        </>
                      ))}
                    </Image.PreviewGroup>
                  </div>
                }
              >
                <List.Item.Meta title={item.reason} description={item.message} />
                <div>
                  <b>ReportId:</b> <br /> {item.id}
                </div>
                <div>
                  <b>DriverId:</b> <br /> {item.driver}
                </div>
                <div>
                  <b>Created at:</b>
                  <br /> {item.createdAt}
                </div>
                <div>
                  <b>Updated at:</b>
                  <br /> {item.updatedAt}
                </div>
                <div>
                  <b>Postponed to:</b>
                  <br /> {item.postponedTo}
                </div>
                <Divider />
              </List.Item>
            )}
          />
        </Drawer>
      )}
    </>
  );
};
