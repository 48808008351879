import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";

import { API_URL } from "../../constants/config";

export const { types, actions, rootReducer } = createResource({
  name: "driversManager",

  actions: {
    createOne: {
      method: "POST",
      url: `${API_URL}/admin/drivers`,
      transformResponse: ({ body }) => ({
        ...body,
      }),
    },
    loadAll: {
      method: "GET",
      url: `${API_URL}/admin/drivers?page=:page&limit=:limit`,
      transformResponse: ({ body }) => {
        return {
          items: body.items,
          pagination: {
            currentPageNumber: body.meta.currentPage,
            numItemsPerPage: body.meta.itemsPerPage,
            totalCount: body.meta.totalItems,
            totalPages: body.meta.totalPages,
          },
        };
      },
      reduce: (state, action) => {
        return {
          ...state,
          items: action.items,
          pagination: action.pagination,
        };
      },
    },

    loadFiltered: {
      method: "GET",
      url: `${API_URL}/admin/drivers?page=:page&limit=:limit&status=:status`,
      transformResponse: ({ body }) => {
        return {
          items: body.items,
          pagination: {
            currentPageNumber: body.meta.currentPage,
            numItemsPerPage: body.meta.itemsPerPage,
            totalCount: body.meta.totalItems,
            totalPages: body.meta.totalPages,
          },
        };
      },
      reduce: (state, action) => {
        return {
          ...state,
          items: action.items,
          pagination: action.pagination,
        };
      },
    },

    loadOne: {
      method: "GET",
      url: `${API_URL}/admin/drivers/:id`,
      transformResponse: ({ body }) => ({
        item: body,
      }),
      reduce: (state, action) => ({
        ...state,
        item: action.item,
      }),
    },

    loadDriverStats: {
      method: "GET",
      url: `${API_URL}/admin/drivers/:id/stats/general`,
      transformResponse: ({ body }) => ({
        item: body,
      }),
      reduce: (state, action) => ({
        ...state,
        stats: action.item,
      }),
    },

    loadDriverStatsCashSection: {
      method: "GET",
      url: `${API_URL}/admin/drivers/:id/stats/cash`,
      transformResponse: ({ body }) => ({
        cashStats: body,
      }),
      reduce: (state, action) => ({
        ...state,
        additionalStats: { ...state.additionalStats, cashStats: action.cashStats },
      }),
    },

    loadDriverStatsKMSection: {
      method: "GET",
      url: `${API_URL}/admin/drivers/:id/stats/general`,
      transformResponse: ({ body }) => ({
        kmStats: body.distance,
      }),
      reduce: (state, action) => ({
        ...state,
        additionalStats: { ...state.additionalStats, kmStats: action.kmStats },
      }),
    },

    // loadDriverStatsJobCompletionSection: {
    //   method: "GET",
    //   url: `${API_URL}/admin/drivers/:id/stats/current-job/completion`,
    //   transformResponse: ({ body }) => ({
    //     ordersCompleted: body,
    //   }),
    //   reduce: (state, action) => ({
    //     ...state,
    //     additionalStats: { ...state.additionalStats, ordersCompleted: action.ordersCompleted },
    //   }),
    // },

    loadDriverStatsJobCompletionSection: {
      method: "GET",
      url: `${API_URL}/admin/drivers/:id/stats/general`,
      transformResponse: ({ body }) => ({
        ordersCompleted: body?.ordersCompleted,
      }),
      reduce: (state, action) => ({
        ...state,
        additionalStats: { ...state.additionalStats, ordersCompleted: action.ordersCompleted },
      }),
    },

    changeStatus: {
      method: "PUT",
      url: `${API_URL}/admin/drivers/:id`,
      reduce: (state, action) => {
        if (action.status === "resolved") {
          const updatedItems = () => {
            const idx = state.items.findIndex((item) => item.id === action.body.id);
            const updatedDriver = state.items[idx];
            updatedDriver.status = action.body.status;
            return [...state.items.slice(0, idx), updatedDriver, ...state.items.slice(idx + 1)];
          };
          return {
            ...state,
            item: { ...state.item, status: action.context.status },
            items: updatedItems(),
          };
        }
        return {
          ...state,
          item: state.item,
        };
      },
    },

    changeCompany: {
      method: "PUT",
      url: `${API_URL}/admin/drivers/:id`,
      reduce: (state, action) => {
        if (action.status === "resolved") {
          const updatedItems = () => {
            const idx = state.items.findIndex((item) => item.id === action.body.id);
            const updatedDriver = state.items[idx];
            updatedDriver.connectedRetailer = action.body.connectedRetailer;
            return [...state.items.slice(0, idx), updatedDriver, ...state.items.slice(idx + 1)];
          };
          return {
            ...state,
            item: { ...state.item, connectedRetailer: action.context.connectedRetailer },
            items: updatedItems(),
          };
        }
        return {
          ...state,
          item: state.item,
        };
      },
    },

    getCurrentJob: {
      method: "GET",
      url: `${API_URL}/admin/drivers/:id/current-job`,
      transformResponse: ({ body }) => ({ body }),
      reduce: (state, action) => {
        if (action.code === 400) {
          return { ...state, currentJob: action.body.message };
        }
        return { ...state, currentJob: action?.body };
      },
    },

    getJobs: {
      method: "GET",
      url: `${API_URL}/admin/drivers/:id/assign/jobs-list`,
      transformResponse: ({ body }) => ({ body }),
      reduce: (state, action) => {
        return { ...state, adminJobs: action?.body };
      },
    },

    assignJob: {
      method: "GET",
      url: `${API_URL}/admin/jobs/:id/assign`,
    },

    cancelAssignJob: {
      method: "GET",
      url: `${API_URL}/admin/jobs/:id/cancel-assign`,
    },

    trustDriver: {
      method: "PATCH",
      url: `${API_URL}/admin/drivers/:driverId/trusted-driver`,
      reduce: (state, action) => {
        if (action.status === "resolved") {
          const updatedItems = () => {
            const idx = state.items.findIndex((item) => item.id === action.body.id);
            const updatedDriver = state.items[idx];
            updatedDriver.trustedDriver = action.body.trustedDriver;
            return [...state.items.slice(0, idx), updatedDriver, ...state.items.slice(idx + 1)];
          };
          return {
            ...state,
            item: { ...state.item, trustedDriver: action.body.trustedDriver },
            items: updatedItems(),
          };
        }
        return {
          ...state,
          item: state.item,
        };
      },
    },

    deleteDriver: {
      method: "DELETE",
      url: `${API_URL}/admin/drivers/:driverId`,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
