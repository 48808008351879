import React from "react";
import Loader from "../../../components/Loader";

import { ReactComponent as FailedPackage } from "../../../assets/packages/failed-package.svg";
import { ReactComponent as SuccessfulPackage } from "../../../assets/packages/successful-package.svg";
import "../styles.less";

const SuccessfulPayment = () => {
  return (
    <div>
      <SuccessfulPackage />
      <div className="successful result-title">Payment succesfull!</div>
      <div className="successful result-text">Your payment was successfully processed</div>
    </div>
  );
};

const FailedPayment = () => {
  return (
    <div>
      <FailedPackage />
      <div className="failed result-title">Payment Failure!</div>
      <div className="failed result-text">Your payment wasn`t processed</div>
    </div>
  );
};

const PaymentInfo = ({ cash, orderId, currency, error }) => {
  return !error ? (
    <div style={{ marginBottom: "54px" }}>
      <div className="order-id-field">Order ID {orderId}</div>
      <div className="cash-field">
        {currency} {cash}
      </div>
    </div>
  ) : null;
};

const PaymentResult = ({ data }) => {
  return data ? (
    <div className="result-container">
      <PaymentInfo cash={data.cash} orderId={data.orderId} currency={data.currency} error={data.error} />
      {data.status === "succeeded" ? <SuccessfulPayment /> : <FailedPayment />}
    </div>
  ) : (
    <Loader />
  );
};

export default PaymentResult;
