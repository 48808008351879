import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { API_URL } from "src/constants/config";
import moment from "moment";

const CompanyLogs = () => {
  const [lastActiveCompanies, setLastActiveCompanies] = useState([]);

  useEffect(() => {
    fetch(`${API_URL}/superadmin/companies/last-active-within-hour`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("authTokenSuper")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setLastActiveCompanies(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const renderLastActiveCompanies = () => {
    if (!lastActiveCompanies) {
      return <p>Loading...</p>;
    }
    if (lastActiveCompanies?.length === 0) {
      return <div style={{margin:"20%"}}></div>
    }
    return (
      <table>
        <tbody>
          {lastActiveCompanies?.map((data) => (
            <tr key={data.id} style={{ fontSize: "12px" }}>
              <td>Id:</td>
              <td>{data.id}</td>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <td>{data.name}</td>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <td>{moment(data.lastLogin).format("YYYY-MM-DD HH:mm")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
       <Card
        style={{
          width: "30%",
          borderRadius: "10px",
          backgroundColor: "#f6ffed",
          margin: "1%",
          boxSizing: "border-box",
        }}
        title="Last Active Companies"
      >
        {renderLastActiveCompanies()}
      </Card>
 
  );
};

export default React.memo(CompanyLogs);
