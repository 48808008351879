import React, { FC, useEffect, useState } from "react";
import { IPagination, ITicketsTableData, ITicketListViewProps, TicketStatusEnum, UsersRolesEnum } from "./type";
import { Badge, Button, Select, Space, Table, Typography } from "antd";
import "./ticket-list-view.less";
import { ticketsTableColumns } from "./constant";
import { OrderDetails } from "./OrderDetails";
import { ColumnsType } from "antd/lib/table";
import { useAdminTicket } from "./useTicket";
import { IOrderDetailsDef } from "./OrderDetails/type";
import { userRole } from "src/helpers/authStorage";
import TicketDetailsModal from "./TicketDetailsModal";

const { Title } = Typography;
const { Option } = Select;

export const computeTableData = (data: any, filter: string, isAdmin: boolean): ITicketsTableData[] => {
  const newData = filter === "all" ? data : filterTicketByStatus(data, filter);

  return newData?.map((i: any) => ({
    key: `ticket-${i.ticketId}`,
    id: i.ticketId,
    orderId: i.orderId,
    labels: i.labels,
    status: i.status,
    isRead: isAdmin ? i.isReadAdmin : i.isReadRetailer,
  }));
};

export const filterTicketByStatus = (ticket: any, filter: string) => ticket.filter((d: any) => d.status === filter);

export const filterOrderDetail = (tickets: Record<any, any>, orderId: number): IOrderDetailsDef => {
  const { order = null } = tickets?.find((i: any) => i.orderId === orderId);
  return order;
};

export const filterUsers = (tickets: Record<any, any>, ticketId: number): any => {
  const { users = null } = tickets?.find((i: any) => i.ticketId === ticketId);
  return {
    driver: users[UsersRolesEnum.DRIVER].name,
    retailer: users[UsersRolesEnum.RETAILER].name,
    admin: UsersRolesEnum.ADMIN,
    super_admin: UsersRolesEnum.SUPER_ADMIN,
    operations_team: UsersRolesEnum.OPERATIONS_TEAM,
    driver_manager: UsersRolesEnum.DRIVER_MANAGER,
    customer_service: UsersRolesEnum.CUSTOMER_SERVICE,
    event: UsersRolesEnum.EVENT,
  };
};

const TicketListView: FC<ITicketListViewProps> = ({
  getTicketAction,
  updateTicketAction,
  sendMessageAction,
  getAllMessagesAction,
  updateTicketState,
  tickets,
  isLoading,
  isAdmin,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);
  const [selectedTicketId, setSelectedTickerId] = useState<number | null>(null);
  const [filter, setFilter] = useState<string>("all");
  const [tableData, setTableData] = useState<ITicketsTableData[] | null>(null);
  const [pagination, setPagination] = useState<IPagination>({
    pageSize: 10,
    current: 1,
  });

  const { fetchTickets, updateTicket } = useAdminTicket(getTicketAction, updateTicketAction);

  useEffect(() => {
    fetchTickets({
      page: pagination.current,
      limit: pagination.pageSize,
      ...(filter !== "all" && { status: filter }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, filter]);

  useEffect(() => {
    if (tickets?.items?.length > 0) {
      setTableData(computeTableData(tickets?.items, filter, isAdmin));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickets?.items]);

  const showOrderDetails = (orderId: number) => {
    setIsModalOpen(true);
    setSelectedOrderId(orderId);
  };

  const closeOrderDetails = () => {
    setIsModalOpen(false);
  };

  const showChat = (orderId: number, ticketId: number) => {
    setSelectedOrderId(orderId);
    setSelectedTickerId(ticketId);
    setIsChatOpen(true);
  };

  const closeChat = () => {
    setSelectedOrderId(null);
    setSelectedTickerId(null);
    setIsChatOpen(false);
    updateTicketState({
      ticketId: selectedTicketId,
      ticket: isAdmin ? { isReadAdmin: true } : { isReadRetailer: true },
    });
  };

  const handleStatusChange = (ticketId: number, status: string) => {
    updateTicket({ ticketId, status });
  };

  const handlePaginationChange = ({ current, pageSize }: any) => {
    setPagination({ current, pageSize });
    setSelectedOrderId(null);
  };

  const ticketsTable: ColumnsType<ITicketsTableData> = [
    ...ticketsTableColumns,
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        return (
          <>
            <Select
              size="middle"
              value={record.status}
              defaultValue={record.status}
              style={{ width: 100 }}
              onChange={(value: string) => handleStatusChange(record.id, value)}
            >
              <Option value={TicketStatusEnum.CLOSE}>Close</Option>
              <Option value={TicketStatusEnum.OPEN}>Open</Option>
              <Option value={TicketStatusEnum.ON_HOLD}>On Hold</Option>
            </Select>
          </>
        );
      },
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => showChat(record.orderId, record.id)}>
            Open Ticket
            {!record.isRead && <Badge status="error" className="ticket-badge" />}
          </Button>
          <Button type="link" onClick={() => showOrderDetails(record.orderId)}>
            View Order
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="admin-container">
        <Title level={3} data-testid="title">
          Tickets
        </Title>
        <div className="filter-wrapper">
          <span>Filter By Status</span>
          <Select
            data-testid="filter-select"
            className="ticket-filter-select"
            value={filter}
            showSearch
            placeholder="Filter Tickets"
            optionFilterProp="children"
            onChange={(value: string) => setFilter(value)}
            filterOption={(input, option) =>
              (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option value="all">All Tickets</Option>
            <Option value={TicketStatusEnum.CLOSE}>Close</Option>
            <Option value={TicketStatusEnum.OPEN}>Open</Option>
            <Option value={TicketStatusEnum.ON_HOLD}>On Hold</Option>
          </Select>
        </div>
        {!isLoading ? (
          <Table
            data-testid="tickets-table"
            columns={ticketsTable}
            dataSource={tableData ?? []}
            pagination={{
              pageSize: tickets?.meta.itemsPerPage,
              total: tickets?.meta.totalItems,
              current: tickets?.meta.currentPage,
              showSizeChanger: true,
            }}
            onChange={handlePaginationChange}
          />
        ) : (
          <p>Loading...</p>
        )}
        {tickets && selectedOrderId && (
          <OrderDetails
            order={filterOrderDetail(tickets?.items, selectedOrderId)}
            isModalOpen={isModalOpen}
            handleCancel={closeOrderDetails}
          />
        )}
        {tickets && selectedTicketId && selectedOrderId && (
          <TicketDetailsModal
            ticketId={selectedTicketId}
            orderId={selectedOrderId}
            users={filterUsers(tickets?.items, selectedTicketId)}
            currentRole={userRole}
            getMessages={getAllMessagesAction}
            sendMessage={sendMessageAction}
            isModalOpen={isChatOpen}
            handleCancel={closeChat}
          />
        )}
      </div>
    </>
  );
};

export default TicketListView;
