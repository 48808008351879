import { createResource } from "redux-rest-resource";
import { setAuthHeader, getAuthData } from "../../helpers/authStorage";
import { getDeliveryOrders } from "../../helpers/maps/maps.helpers";
import { API_URL } from "../../constants/config";
import { API_ROLE } from "../../helpers/authStorage";

const updateJobNewTicketStateReduce = (state, action) => {
  if (action.status === "resolved") {
    return {
      ...state,
      items: state.items.map((item) => {
        if (item.id === action.context.jobId) {
          return {
            ...item,
            orders: item.orders.map((o) => {
              if (o.id === action.context.orderId) {
                return {
                  ...o,
                  ticket: { ...action.context.ticket },
                };
              } else {
                return o;
              }
            }),
          };
        } else {
          return item;
        }
      }),
    };
  }
  return { ...state };
};

const jobReduce = (state, action) => {
  let deliveryJobsWithOutOrder = null;

  if (action.status === "resolved") {
    deliveryJobsWithOutOrder = state.items.map((job) => {
      if (job.id === action.context.jobId) {
        const updatedOrders = job.orders.filter((order) => order.id !== action.context.orderId);

        return {
          ...job,
          orders: updatedOrders,
        };
      }
      return { ...job };
    });
  }
  return { ...state, items: deliveryJobsWithOutOrder || state.items };
};

const updateOrderStatus = (orders, orderId, status) => {
  return orders.map((order) =>
    order.id === orderId
      ? {
          ...order,
          status,
          deliveryTime: null,
        }
      : order,
  );
};

const completeJobReduce = (state, action) => {
  const {
    context: { jobId, orderId },
  } = action;
  if (action.status === "resolved") {
    const updatedJobs = state.items.map((job) =>
      job.id === jobId
        ? {
            ...job,
            orders: updateOrderStatus(job.orders, orderId, "completed"),
          }
        : job,
    );
    return { ...state, items: updatedJobs };
  }
  return state;
};

const updateCashAmountReduce = (state, action) => {
  let newState = { ...state };
  if (action.status === "resolved") {
    newState = {
      ...state,
      items: state.items.map((job) => {
        if (job.id === action.context.jobId) {
          return {
            ...job,
            orders: job.orders.map((order) => {
              if (order.id === action.context.orderId) {
                return {
                  ...order,
                  cashAmount: action.body.cashAmount,
                };
              }
              return { ...order };
            }),
          };
        }
        return { ...job };
      }),
    };
  }
  return newState;
};

export const { types, actions, rootReducer } = createResource({
  name: "deliveryJobDetails",
  url: `${API_URL}/${API_ROLE}/jobs/:id`,

  actions: {
    fetch: {
      method: "GET",
      url: `${API_URL}/${API_ROLE}/jobs/:id`,
    },

    getRoutes: {
      method: "GET",
      url: `${API_URL}/${API_ROLE}/jobs/routes/:jobId`,
      reduce: (state, action) => {
        let deliveryJobs;
        if (action.status === "resolved") {
          deliveryJobs = state.items.map((job) => {
            if (job.id === action.context.jobId) {
              return {
                ...job,
                jobRoutes: action.body,
              };
            }
            return { ...job };
          });
        }
        return { ...state, items: deliveryJobs || state.items };
      },
    },

    getPolyline: {
      method: "GET",
      url: `${API_URL}/${API_ROLE}/jobs/polyline/:jobId`,
      reduce: (state, action) => {
        let deliveryJobs;
        if (action.status === "resolved") {
          deliveryJobs = state.items.map((job) => {
            if (job.id === action.context.jobId) {
              return {
                ...job,
                polyline: action.body.polyline,
              };
            }
            return { ...job };
          });
        }
        return { ...state, items: deliveryJobs || state.items };
      },
    },
    saveOrders: {
      method: "PUT",
      url: `${API_URL}/${API_ROLE}/jobs/:jobId/orders`,
    },
    deleteOrder: {
      method: "DELETE",
      url: `${API_URL}/${API_ROLE}/jobs/:jobId/orders/:orderId`,
      reduce: jobReduce,
    },
    returnToShipper: {
      method: "PUT",
      url: `${API_URL}/${API_ROLE}/orders/:orderId/return-to-shipper`,
      reduce: jobReduce,
    },
    postponeOrder: {
      method: "PUT",
      url: `${API_URL}/${API_ROLE}/orders/:orderId/postpone`,
      reduce: jobReduce,
    },
    cancelOrder: {
      method: "PUT",
      url: `${API_URL}/${API_ROLE}/orders/:orderId/cancel`,
      reduce: jobReduce,
    },
    completeOrder: {
      method: "PUT",
      url: `${API_URL}/${API_ROLE}/orders/:orderId/complete`,
      reduce: completeJobReduce,
    },
    cancelCompleted: {
      method: "PUT",
      url: `${API_URL}/admin/orders/:orderId/cancel-completed`,
    },
    togglePublish: {
      method: "PUT",
      url: `${API_URL}/${API_ROLE}/jobs/:jobId`,
    },
    addOrders: {
      method: "PUT",
      url: `${API_URL}/${API_ROLE}/jobs/:jobId/orders/add`,
    },
    removeOrder: {
      method: "DELETE",
      url: `${API_URL}/${API_ROLE}/jobs/:jobId/orders/:orderId`,
    },
    removeOrders: {
      method: "DELETE",
      url: `${API_URL}/${API_ROLE}/jobs/:jobId/orders`,
    },
    moveOrders: {
      method: "PUT",
      url: `${API_URL}/${API_ROLE}/jobs/:jobId/orders`,
    },
    changeOrderPosition: {
      method: "PUT",
      url: `${API_URL}/${API_ROLE}/jobs/:jobId/delivery-order`,
    },
    changePickupPoistion: {
      method: "PUT",
      url: `${API_URL}/${API_ROLE}/jobs/:jobId/pickup-order`,
    },
    forceNextOrder: {
      method: "PUT",
      url: `${API_URL}/${API_ROLE}/jobs/:jobId/force-next`,
    },
    editNote: {
      method: "PUT",
      url: `${API_URL}/${API_ROLE}/orders/:orderId/notes`,
    },
    assignJob: {
      method: "GET",
      url: `${API_URL}/${API_ROLE}/jobs/:id/assign`, // assignJobDriversManager(id, { query: { driverId: driver.id } })
    },

    cancelAssignJob: {
      method: "GET",
      url: `${API_URL}/${API_ROLE}/jobs/:id/cancel-assign`, // cancelAssignJobDriversManager(id, { query: { driverId: driver.id } });
    },
    fetchAvailableDrivers: {
      method: "GET",
      url: `${API_URL}/${API_ROLE}/jobs/:id/drivers`, //
    },
    updateCash: {
      method: "PATCH",
      url: `${API_URL}/${API_ROLE}/orders/:orderId/cash`,
      reduce: updateCashAmountReduce,
    },
    getBad: {
      method: "GET",
      url: `${API_URL}/${API_ROLE}/jobs/failed`, // TODO bad location endpoint
    },
    clean: {
      method: "PUT",
      url: `${API_URL}/${API_ROLE}/jobs/clean-jobs`,
    },

    // pure Actions

    updateNewTicketState: {
      isPure: true,
      reduce: updateJobNewTicketStateReduce,
    },
    updateTicketState: {
      isPure: true,
      reduce: updateJobNewTicketStateReduce,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
