import React, { useState } from "react";
import { Drawer, Table, Input, Select, Row, Button } from "antd";
import tableColumns from "./components/columns";
import DetailsSkeleton from "./components/Skeleton";
import DriverDetails from "./components/Details";
import moment from "moment";
import { CustomCard } from "../../../utils/common";
import { UserAddOutlined } from "@ant-design/icons";
import AddDriverModal from "./components/AddDriverModal";

const { Option } = Select;

export const DesktopView = ({
  loading,
  drivers,
  driver,
  driverStats,
  driverAdditionalStats,
  selectedRetailer,
  paginationConfig,
  getJobsAndRetailers,
  changeStatusDriversManager,
  loadOneDriversManager,
  loadDriverStatsDriversManager,
  loadDriverStatsJobCompletionSectionDriversManager,
  loadDriverStatsCashSectionDriversManager,
  loadDriverStatsKMSectionDriversManager,
  onChangeTable,
  onChangeSearchFilter,
  handleSearchDriver,
  handleAssignJob,
  handleCancelAssignJob,
  handleCompanyChange,
  trustDriverDriversManager,
  handleDriverDelete,
  handleCreateDriver,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const selectSearchFilter = (
    <Select defaultValue="email" className="select-before" onChange={onChangeSearchFilter}>
      <Option value="email">Email</Option>
      <Option value="name">Name</Option>
      <Option value="plateNumber">Plate number</Option>
    </Select>
  );

  return (
    <div className="pageWrapper">
      <CustomCard
        title="Drivers Management"
        toolbar={
          <Row>
            <Input
              placeholder="Search"
              addonBefore={selectSearchFilter}
              onPressEnter={handleSearchDriver}
              className="searchDriver"
            />

            <Button icon={<UserAddOutlined />} type="primary" onClick={() => setModalVisible(true)}>
              Create Driver
            </Button>
          </Row>
        }
      >
        <Table
          rowKey={(record) => record.id}
          columns={tableColumns()}
          dataSource={drivers}
          loading={loading}
          onChange={onChangeTable}
          pagination={paginationConfig}
          onRow={(record) => {
            return {
              onClick: async () => {
                setDrawerVisible(true);
                await loadOneDriversManager(record.id);
                await loadDriverStatsDriversManager(record.id);
                await loadDriverStatsJobCompletionSectionDriversManager(record.id, {
                  query: {
                    from: moment().startOf("day").toISOString(true).substring(0, 23),
                    to: new Date().toISOString(true).substring(0, 23),
                  },
                });
                await loadDriverStatsKMSectionDriversManager(record.id, {
                  query: {
                    from: moment().startOf("day").toISOString(true).substring(0, 23),
                    to: new Date().toISOString(true).substring(0, 23),
                  },
                });
                await loadDriverStatsCashSectionDriversManager(record.id, {
                  query: {
                    from: moment().startOf("day").toISOString(true).substring(0, 23),
                    to: new Date().toISOString(true).substring(0, 23),
                  },
                });
              },
            };
          }}
          rowClassName="driverTableItem"
        />
        <Drawer width={800} placement="right" closable onClose={() => setDrawerVisible(false)} visible={drawerVisible}>
          {!driver ? (
            <DetailsSkeleton />
          ) : (
            <DriverDetails
              selectedRetailer={selectedRetailer}
              driver={driver}
              driverStats={driverStats}
              driverAdditionalStats={driverAdditionalStats}
              getJobsAndRetailers={getJobsAndRetailers}
              onDriverStatusChange={changeStatusDriversManager}
              handleAssignJob={handleAssignJob}
              handleCancelAssignJob={handleCancelAssignJob}
              handleCompanyChange={handleCompanyChange}
              trustDriverDriversManager={trustDriverDriversManager}
              loadDriverStatsCashSectionDriversManager={loadDriverStatsCashSectionDriversManager}
              loadDriverStatsKMSectionDriversManager={loadDriverStatsKMSectionDriversManager}
              loadDriverStatsJobCompletionSectionDriversManager={loadDriverStatsJobCompletionSectionDriversManager}
              handleDriverDelete={handleDriverDelete(setDrawerVisible)}
              setDrawerVisible={setDrawerVisible}
            />
          )}
        </Drawer>
        <AddDriverModal
          visible={modalVisible}
          handleCloseModal={() => setModalVisible(false)}
          handleRegisterDriver={handleCreateDriver}
        />
      </CustomCard>
    </div>
  );
};
